import { useEffect, useState } from 'react'
import moment from 'moment'

const useDirections = (
  dep,
  arr,
  enabledSameDateOpt = false,
  earliestDate,
  latestDate
) => {
  const [departure, setDeparture] = useState(null)
  const [arrival, setArrival] = useState(null)

  const depChange = (date) => {
    const momentDate = moment(date)
    if (!departure && arrival && momentDate.isValid()) {
      momentDate.set({
        hour: 8,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    }
    setDeparture(momentDate.isValid() ? momentDate : null)

    if (
      date &&
      momentDate.isValid() &&
      momentDate.isAfter(moment(arrival), 'day')
    ) {
      setArrival(null)
    }

    if (
      !enabledSameDateOpt &&
      date &&
      momentDate.isSame(moment(arrival), 'day')
    ) {
      setArrival(moment(arrival).add(1, 'day'))
    }
  }

  const arrOnChange = (date) => {
    const momentDate = moment(date)
    if (!arrival && departure && momentDate.isValid()) {
      momentDate.set({
        hour: 16,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    }
    setArrival(momentDate.isValid() ? momentDate : null)

    if (date && momentDate.isBefore(moment(departure), 'day')) {
      setDeparture(null)
    }

    if (
      !enabledSameDateOpt &&
      date &&
      momentDate.isSame(moment(departure), 'day')
    ) {
      setArrival(momentDate.add(1, 'day'))
    }
  }

  useEffect(() => {
    const defaultDepHour = enabledSameDateOpt ? 8 : 10
    const defaultArrHour = enabledSameDateOpt ? 16 : 10
    let nDep = moment(new Date())
      .add(1, 'day')
      .set({ hour: defaultDepHour, minute: 0, second: 0, millisecond: 0 })
    if (dep && moment(dep).isSameOrAfter(new Date(), 'day')) {
      nDep = moment(dep).set({
        hour: defaultDepHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      nDep = moment(dep)
    } else {
      nDep = nDep.clone()
    }

    if (earliestDate && nDep.isBefore(moment(earliestDate))) {
      nDep = moment(earliestDate).set('hour', defaultDepHour)
    }

    setDeparture(nDep)

    if (arr === '0' || moment(arr).isBefore(nDep, 'day')) {
      setArrival(null)
    } else {
      let nArr = arr
        ? moment(arr)
        : nDep
            .clone()
            .add(1, 'day')
            .set({ hour: defaultArrHour, minute: 0, second: 0, millisecond: 0 })

      if (nArr.isBefore(nDep)) {
        nArr = enabledSameDateOpt
          ? moment(arr).isSameOrAfter(nDep, 'day')
          : moment(arr).isAfter(nDep, 'day')
      }

      if (latestDate && nArr.isAfter(moment(latestDate))) {
        nArr = moment(latestDate)
      }
      setArrival(nArr)
    }
  }, [dep, arr])

  return {
    departure,
    setDeparture,
    arrival,
    setArrival,
    depChange,
    arrOnChange,
  }
}

export default useDirections
