import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setActiveSearchType,
  setIsIframe,
} from '../../store/customization/customizationAction'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import MultiSearch from '../../containers/search/multiSearch'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
  },
}))

const Iframe = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { type } = useParams()

  useEffect(() => {
    const paramType = type ?? 'flights'
    dispatch(setActiveSearchType(paramType))
    dispatch(setIsIframe(true))
  }, [type])
  return (
    <Box className={classes.root}>
      <Container maxWidth={false} disableGutters>
        <MultiSearch />
      </Container>
    </Box>
  )
}

export default memo(Iframe)
