const FETCH_RENTAL_CARS = 'FETCH_OUTGOING_SEARCH'
const IS_CARS_SEARCH_LOADING = 'IS_CARS_SEARCH_LOADING'
const RESET_CARS_STATE = 'RESET_CARS_STATE'
const CLEAR_CARS_SEARCH = 'CLEAR_CARS_SEARCH'
const SET_SELECTED_CAR = 'SET_SELECTED_CAR'
const SET_CAR_EXTRAS = 'SET_CAR_EXTRAS'
const REMOVE_CAR_EXTRA = 'REMOVE_CAR_EXTRA'
const SET_CAR_FILTERS = 'SET_CAR_FILTERS'
const RESET_CAR_FILTERS = 'RESET_CAR_FILTERS'
const SET_ADD_ONS = 'SET_ADD_ONS'
const REMOVE_ADD_ONS = 'REMOVE_ADD_ONS'

export default {
  IS_CARS_SEARCH_LOADING,
  FETCH_RENTAL_CARS,
  RESET_CARS_STATE,
  CLEAR_CARS_SEARCH,
  SET_SELECTED_CAR,
  SET_CAR_EXTRAS,
  REMOVE_CAR_EXTRA,
  SET_CAR_FILTERS,
  RESET_CAR_FILTERS,
  SET_ADD_ONS,
  REMOVE_ADD_ONS
}
