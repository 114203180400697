import React, { useEffect, memo, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import HotelRoomCard from '../../components/hotels/hotelRoomCard'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { addToCheckoutAction } from '../../store/checkout/checkoutActions'
import HotelInfo from '../../containers/hotels/singleHotel/hotelInfo'
import SmallGoogleMaps from '../../components/googleMaps/smallGoogleMaps'
import SingleHotelimages from '../../containers/hotels/singleHotel/singleHoteImages'
import HotelDetails from '../../containers/hotels/singleHotel/hotelDetails'
import HotelAmenitiesCard from '../../components/hotels/hotelAmenities'
import {
  fetchHotelRatingsAction,
  fetchSingleHotelAction,
} from '../../store/hotels/hotelsAction'
import HotelInfoMobile from '../../containers/hotels/singleHotel/hotelInfoMobile'
import RegularLoader from '../../components/reusable/loaders/regularLoader'
import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import NoTripsFound from '../../components/general/noTripsFound'
import DesktopDetailsHeader from '../../containers/header/desktopDetailsHeader/desktopDetailsHeader'
import { singleHotelBreadCrumbAction } from '../../store/breadcrumbs/breadcrumbsAction'
import { Card, CircularProgress } from '@material-ui/core'
import _ from 'lodash'
import { fetchCityByIata } from '../../repositories/flights'
import UnobtrusiveLoader from '../../components/reusable/loaders/unobtrusiveLoader'
import isIframed from '../../utils/isIframed'
import { AMENITIES } from '../../constants/hotelRoomCodes'
import { POLICY_RESULT } from '../../constants/policy'
import { openPolicyExceededModal } from '../../store/modals/modalAction'

const useStyles = makeStyles((theme) => ({
  wrapperCard: ({ isMobile }) => ({
    borderRadius: isMobile ? 0 : '12px',
    margin: 0,
    padding: isMobile ? theme.spacing(3, 1) : '24px',
    border: 'solid 2px transparent',
    marginTop: isMobile ? 0 : '36px',
    backgroundColor: '#F7F7F7',
  }),
  card: { borderRadius: theme.spacing(1) },
  align: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
      '&:first-child': { marginLeft: 0 },
    },
  },
  alignItems: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  top: {
    //    marginTop: theme.spacing(4),
  },
  ratingContainer: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(1),
  },
  checkInCheckout: {
    padding: 5,
    borderRadius: theme.spacing(1),
  },
  adress: {
    display: 'flex',
    '& > *': {
      margin: '0 5px',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  choosebtn: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 4),
    letterSpacing: 1.1,
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
  },
  checkoutCheckinContainer: {
    fontWeight: 600,
    letterSpacing: '1.2',
    textTransform: 'capitalize',
  },
}))

const SingleHotel = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const searchData = useSelector((state) => state.search)
  const bedsCount = useMemo(() => {
    return searchData.hotels?.travelerCounts?.beds?.count || 1
  }, [searchData.hotels])
  const params = useParams()
  const classes = useStyles({ isMobile })
  const { guests: hotelGuests, single: hotel } = useSelector(
    (state) => state.hotels
  )
  const { items } = useSelector((state) => state.checkout)
  const {
    singleLoading,
    singleError,
    guests: travellersData,
  } = useSelector((state) => state.hotels)
  const { currency } = useSelector((state) => state.auth)
  const { guests, roomsCount } = params
  const parsedHotel = hotel?.parsedHotel
  const amenities = parsedHotel?.amenities || []
  const hotelImages = parsedHotel?.additional_images || []
  // Initialize with empty (null) images if needed.
  while (hotelImages.length < 5) {
    hotelImages.push(null)
  }

  const LONGITUDE = parsedHotel?.CachedData?.LONGITUDE
  const LATITUDE = parsedHotel?.CachedData?.LATITUDE

  useEffect(() => {
    dispatch(singleHotelBreadCrumbAction(pathname))
  }, [dispatch, pathname])

  useEffect(() => {
    let minAge = false
    travellersData?.forEach((t) => {
      let tmpAge = !t?.age || parseInt(t.age) <= 0 ? 0 : t.age
      if (minAge === false || minAge > tmpAge) minAge = tmpAge
    })
    dispatch(fetchSingleHotelAction({ ...params, guests: bedsCount, minAge }))
  }, [dispatch, params, bedsCount, travellersData])

  useEffect(() => {
    const coordianates = { lat: LATITUDE, lng: LONGITUDE }
    if (!_.isEqual(params, hotel?.params)) {
      dispatch(fetchHotelRatingsAction(coordianates))
    }
  }, [params, hotel, dispatch, LATITUDE, LONGITUDE])

  const renderHotelRooms = hotel?.rooms.map((room, key) => (
    <Box mt={key === 0 ? 0 : 2} key={key}>
      <HotelRoomCard room={room} hotel={hotel} />
    </Box>
  ))

  const translatedAmenities = AMENITIES()

  const uniqueAmenityLabels = new Set()
  const renderHotelAmenitie = amenities
    .map((amenity) => ({
      code: amenity,
      label: translatedAmenities[amenity],
    }))
    .filter((amenityObj) => translatedAmenities[amenityObj.code])
    .filter((amenityObj) => {
      if (uniqueAmenityLabels.has(amenityObj.label)) {
        return false
      }
      uniqueAmenityLabels.add(amenityObj.label)
      return true
    })
    .map((amenityObj) => (
      <Grid item key={amenityObj.code}>
        <HotelAmenitiesCard
          RoomAmenityCode={amenityObj.code}
          RoomAmenityLabel={amenityObj.label}
          hotel={true}
        />
      </Grid>
    ))

  const stars = parsedHotel?.CachedData?.LOCALRATING
    ? parseInt(parsedHotel?.CachedData?.LOCALRATING)
    : parsedHotel?.CachedData?.SELFRATING

  const handleAddToCheckout = () => {
    const { exceedsPolicy, allowReasons } = hotel.rooms[0]

    if (exceedsPolicy === POLICY_RESULT.BLOCK) {
      return dispatch(
        openPolicyExceededModal({
          allowReasons: allowReasons,
          clickHandler: addToCheckout,
        })
      )
    }

    addToCheckout()
  }

  const addToCheckout = async () => {
    const city = await fetchCityByIata(parsedHotel?.cityCode)

    let payload = {
      type: 'Hotel',
      room: hotel.rooms[0],
      hotel: hotel,
      usersPerRoom: bedsCount,
      city: [city['City ENG'], city['City SWE']],
      totalPrice: parseInt(hotel.rooms[0].totalPrice),
      localCurrency: hotel.rooms[0]?.localCurrency || currency,
      localTotalPrice: hotel.rooms[0]?.localTotalPrice || 0,
      verifyPrice: hotel.rooms[0]?.originalTotalprice,
      roomsCount,
      passengers: hotelGuests,
    }

    if (searchData?.hotels?.multistep) {
      const payloadHotel = {
        ...payload,
        passengers: undefined,
        type: undefined,
      }
      const checkoutItem = items.find((i) => i.type === payload.type)
      let checkoutHotels = []
      if (!!checkoutItem && !checkoutItem.trips?.length) {
        checkoutHotels.push({
          room: checkoutItem.room,
          hotel: checkoutItem.hotel,
          usersPerRoom: checkoutItem.usersPerRoom,
          city: checkoutItem.city,
          totalPrice: checkoutItem.totalPrice,
          localCurrency: checkoutItem.localCurrency,
          localTotalPrice: checkoutItem.localTotalPrice,
          verifyPrice: checkoutItem.verifyPrice,
          roomsCount: checkoutItem.roomsCount,
        })
      } else if (!!checkoutItem && !!checkoutItem.trips?.length) {
        checkoutHotels = [...checkoutItem.trips]
      }
      payload = {
        type: payload.type,
        passengers: payload.passengers,
        totalPrice: payload.totalPrice + (checkoutItem?.totalPrice || 0),
        isMultistep: true,
        trips: [...checkoutHotels, payloadHotel],
      }
    }
    dispatch(addToCheckoutAction(payload))
    history.push('/checkout')
  }

  const mainImage = parsedHotel?.image

  const MyLoader = isIframed ? UnobtrusiveLoader : RegularLoader

  // fills an array with mock images
  //const fakeArrayOfPictures = mainImage ? new Array(10).fill(mainImage) : []

  return (
    <>
      {isMobile ? <MobileHeader /> : <DesktopDetailsHeader />}
      {singleLoading ? (
        <MyLoader open={singleLoading} />
      ) : (
        <Container
          style={{ padding: isMobile && 0 }}
          maxWidth="lg"
          disableGutters={!isMobile}
        >
          <>
            {singleError ? (
              <NoTripsFound isSingleHotel />
            ) : (
              <>
                {isMobile ? (
                  <Box display="flex">
                    {mainImage?.split('.')[mainImage?.split('.').length - 1] !==
                    'png' ? (
                      <img
                        src={mainImage}
                        alt="placeholder img"
                        style={{
                          height: '320px',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          backgroundColor: '#f3f3f3',
                          height: '320px',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                      >
                        <Typography>Image missing</Typography>
                      </Box>
                    )}
                  </Box>
                ) : (
                  ''
                )}
                {/* {isMobile ? (
                  <SingleHotelImageSlider images={fakeArrayOfPictures} />
                ) : (
                  ''
                )} */}
                <Card elevation={0} className={classes.wrapperCard}>
                  <Box className={classes.top} p={1}>
                    {hotel && (
                      <>
                        {isMobile ? (
                          <HotelInfoMobile
                            stars={stars}
                            info={parsedHotel}
                            room={hotel.rooms[0]}
                            addToCheckout={handleAddToCheckout}
                          />
                        ) : (
                          <HotelInfo
                            stars={stars}
                            info={parsedHotel}
                            room={hotel.rooms[0]}
                            addToCheckout={handleAddToCheckout}
                          />
                        )}
                      </>
                    )}

                    {isMobile ? (
                      ''
                    ) : (
                      <>
                        <Divider />
                        <Box mb={4} mt={4}>
                          <SingleHotelimages hotelImages={hotelImages} />
                        </Box>
                      </>
                    )}
                    {isMobile ? (
                      ''
                    ) : (
                      <>
                        <Divider />
                        <Box mt={2} mb={2}>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item>
                              <Typography
                                className={classes.weight}
                                variant="h5"
                              >
                                {t('select the room')}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                {roomsCount}{' '}
                                {roomsCount > 1 ? t('rooms') : t('room')},{' '}
                                {guests}{' '}
                                {guests > 1 ? t('travelers') : t('one travel')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )}

                    <Box mt={2} mb={2}>
                      <HotelDetails
                        info={parsedHotel}
                        LONGITUDE={LONGITUDE}
                        LATITUDE={LATITUDE}
                      />
                      <Box mt={2}>
                        <Grid
                          container
                          direction={isMobile ? 'column' : 'row'}
                          spacing={2}
                        >
                          <Grid item xs={12} md={8} sm={12}>
                            <Box>{renderHotelRooms}</Box>

                            {hotel && (
                              <Paper elevation={0} className={classes.card}>
                                <Box
                                  mt={2}
                                  p={2}
                                  pb={3}
                                  pt={3}
                                  mb={isMobile ? 10 : 0}
                                >
                                  <Box mb={2}>
                                    <Typography
                                      className={classes.weight}
                                      variant="h5"
                                      gutterBottom
                                    >
                                      {t('amenities')}
                                    </Typography>
                                  </Box>
                                  {renderHotelAmenitie}
                                </Box>
                              </Paper>
                            )}
                          </Grid>
                          {isMobile ? (
                            ''
                          ) : (
                            <Grid item xs={12} sm={4}>
                              <Paper elevation={0} className={classes.card}>
                                <Box p={2} mb={10}>
                                  <SmallGoogleMaps
                                    lat={LATITUDE}
                                    lng={LONGITUDE}
                                  />
                                </Box>
                              </Paper>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </>
            )}
          </>
        </Container>
      )}
    </>
  )
}

export default memo(SingleHotel)
