import {
  RESET_SEARCH_PARAMETERS,
  SET_CAR_SEARCH_CACHE,
  SET_CAR_SEARCH_PARAMETERS,
  SET_FLIGHT_SEARCH_PARAMETERS,
  SET_HOTEL_SEARCH_PARAMETERS,
  SET_TRAIN_SEARCH_PARAMETERS,
  RESET_SEARCH_TRAVELLERS,
  SET_SEARCH_TRAVELLERS,
  DISABLE_EXTEND_CHECKOUT,
} from './constants'

const initialState = {
  hotels: {
    travellers: [],
  },
  train: {
    travellers: [],
  },
  flights: {
    travellers: [],
  },
  cars: {
    travellers: [],
  },
}

const setTravellers = (modifiedState, payload) => {
  for (let key in initialState) {
    const { travellers, travelerCounts } = payload
    modifiedState[key].travellers = travellers
    modifiedState[key].travelerCounts = travelerCounts
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_TRAIN_SEARCH_PARAMETERS: {
      let modifiedState = { ...state }
      setTravellers(modifiedState, payload)

      return {
        ...modifiedState,
        train: payload,
      }
    }

    case SET_FLIGHT_SEARCH_PARAMETERS: {
      let modifiedState = { ...state }
      setTravellers(modifiedState, payload)

      return {
        ...modifiedState,
        flights: payload,
      }
    }

    case SET_HOTEL_SEARCH_PARAMETERS: {
      let modifiedState = { ...state }
      setTravellers(modifiedState, payload)

      return {
        ...modifiedState,
        hotels: payload,
      }
    }

    case SET_SEARCH_TRAVELLERS:
      const modifiedState = { ...state }
      for (let key in initialState) {
        if (!!modifiedState?.[key]?.travellers) {
          modifiedState[key].travellers = payload
        }
      }
      return {
        ...modifiedState,
      }

    case RESET_SEARCH_TRAVELLERS:
      const resState = { ...state }
      for (let key in initialState) {
        if (!!resState?.[key]?.travellers) {
          resState[key].travellers = []
        }
      }
      return {
        ...resState,
      }
    case SET_CAR_SEARCH_PARAMETERS: {
      let modifiedState = { ...state }
      setTravellers(modifiedState, payload)

      return {
        ...modifiedState,
        cars: payload,
      }
    }

    case SET_CAR_SEARCH_CACHE: {
      const currCar = state.cars || { travellers: [] }
      currCar.searchCache = payload
      return {
        ...state,
        cars: currCar,
      }
    }

    case DISABLE_EXTEND_CHECKOUT: {
      const newState = {
        ...state,
      }
      const keysToCheck = ['hotels', 'train', 'flights', 'cars']

      keysToCheck.forEach((key) => {
        if (!!newState?.[key]?.extendCheckout) {
          newState[key].extendCheckout = false
        }

        if (!!newState?.[key]?.multistep && key === 'hotels') {
          newState[key].multistep = false
        }
      })
      return newState
    }

    case RESET_SEARCH_PARAMETERS:
      return initialState

    default:
      return state
  }
}
