import React, { memo, Fragment } from 'react'
import { Card, Box, Typography, IconButton, Divider } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CancelIcon from '@material-ui/icons/Cancel'
import capLetters from '../../utils/capitalizeFirstLetter'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { removeCheckoutItemAction } from '../../store/checkout/checkoutActions'
import Moment from 'react-moment'
import GetCompanyIcon from '../../components/reusable/getCompanyIcon'
import { formatTrainDuration } from '../../utils/formatDurations'
import { useTranslation } from 'react-i18next'
import Policy from '../../components/reusable/tags/policy'

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '12px',
  },
  justify: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '0 3px',
      // marginRight: theme.spacing(2),
    },
  },
  forwardBtn: {
    borderRadius: theme.spacing(3),
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const TrainsItemCard = ({ model, index, disableEdit = false, contentOnly = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { outboundTrip, returnTrip } = model

  const remove = () => dispatch(removeCheckoutItemAction(index))

  const renderRoutes = Object.keys({ outboundTrip, returnTrip }).map(
    (key, i) => {
      const train = model[key]
      if (!train?.train?.scheduleSolution) return null

      const railStart = train.train.scheduleSolution.railstart.locationName
      const railEnd = train.train.scheduleSolution.railend.locationName

      const departure = train.train.scheduleSolution.railstart.dateTime
      const arrival = train.train.scheduleSolution.railend.dateTime
      const duration = formatTrainDuration(
        train.train.scheduleSolution.Duration
      )
      const solutions = train.train.scheduleSolution

      const isDirect = solutions.segments <= 0

      const renderDivider = i === 0 && returnTrip
      const exceedsPolicy = train.pricing.exceedsPolicy

      return (
        <Fragment key={i}>
          <Box p={2} pb={1}>
            <Box className={classes.justify} pt={1} pb={1.5}>
              <Box className={classes.align}>
                {i === 0 ? <ArrowForwardIcon /> : <ArrowBackIcon />}
                <Typography variant="body2" className={classes.weight}>
                  {i === 0 ? t('chosen trip') : t('chosen returntrip')}
                </Typography>
              </Box>
              {i === 0 && !disableEdit && (
                <IconButton onClick={remove} style={{ padding: '0px' }}>
                  <CancelIcon color="disabled" />
                </IconButton>
              )}
            </Box>
            <Box>
              <Box pb={1.5}>
                <Typography>
                  <Moment format="ddd D MMM">{departure}</Moment>
                  <span
                    className={classes.weight}
                    style={{ marginLeft: '4px' }}
                  >
                    <Moment format="HH:mm">{departure}</Moment>–
                    <Moment format="HH:mm">{arrival}</Moment>
                  </span>
                </Typography>
              </Box>
              <Box className={classes.align} pb={1.5}>
                <Typography>
                  {capLetters(railStart)} – {capLetters(railEnd)}
                </Typography>
              </Box>
              <Box className={classes.align} pb={2}>
                <GetCompanyIcon
                  company={train.train.scheduleSolution.serverProviderCode}
                />
                <Typography>
                  <span className={classes.weight}>{duration}</span>
                  <span style={{ marginLeft: '8px' }}>
                    {isDirect
                      ? t('filter directly small')
                      : `${solutions?.segments} ${t('flter exchanges')}`}{' '}
                  </span>
                </Typography>
              </Box>
            </Box>

            <Policy policy={exceedsPolicy} />
          </Box>
          {renderDivider ? <Divider /> : null}
        </Fragment>
      )
    }
  )

  return (
    <Fragment>
      {contentOnly ? renderRoutes : (
        <Card elevation={0} className={classes.card}>
          {renderRoutes}
        </Card>
      )}
    </Fragment>
  )
}

export default memo(TrainsItemCard)
