import constants from './constants'
import {
  fetchRailCity,
  fetchOutboundTrains,
  fetchReturningTrains,
  fetchFethTrainAccommodation,
  fetchTrainSeatMap,
  fetchGetMiddleStepSettings,
} from '../../repositories/trains'
import moment from 'moment'
import { getFirstAndLastTime } from '../../utils/trainHelpers'
import storage from 'local-storage-fallback'

export const fetchTrainSearchAction = (data) => async (dispatch, getState) => {
  // dispatch({ type: constants.REMOVE_TRAIN_SEARCH_RESULTS })
  dispatch({ type: constants.FETCH_TRAIN_ERROR, payload: '' })

  const oneWay = data.arr !== '0'
  const payload = {
    schedule: {
      isTwoWay: oneWay,
      outbound: {
        from: data.from,
        to: data.to,
        date: data.dep,
        depTime: data.depTimeOutbound,
      },
      return: oneWay
        ? {
            from: data.to,
            to: data.from,
            date: data.arr,
            depTime: data.depTimeInbound,
          }
        : {},
    },
    users: data.users,
  }

  dispatch(isLoading(true))
  const trains = await fetchOutboundTrains(payload)

  if (!trains?.schedules?.length) {
    dispatch({ type: constants.FETCH_TRAIN_ERROR, payload: 'no trains found' })
  }

  dispatch(isLoading(false))

  const trainsData = getTrainsData(trains, getState)
  dispatch(setTrainSearch(trainsData))
}

const getTrainsData = (trains, getState) => {
  const {
    isEarlierTrips,
    firstTime: stateFirstTime,
    isLaterTrips,
    latestTime: stateLatestTime,
    results,
    showNext: stateShowNext,
    showPrevious: stateShowPrevious,
  } = getState().train

  let latestTime = getFirstAndLastTime(trains?.schedules).latestTime
  let firstTime = getFirstAndLastTime(trains?.schedules).firstTime

  let schedules = trains?.schedules || []
  let showNext = stateShowNext
  let showPrevious = stateShowPrevious

  if (isEarlierTrips && stateFirstTime) {
    const filteredSchedules = trains?.schedules?.filter((schedule) =>
      moment(schedule.schedule.scheduleSolution.railstart.dateTime).isBefore(
        moment(stateFirstTime)
      )
    )
    schedules = [].concat(filteredSchedules, ...results)

    firstTime = getFirstAndLastTime(schedules).firstTime
    latestTime = getFirstAndLastTime(schedules).latestTime

    showPrevious = !moment(stateFirstTime).isSame(moment(firstTime))
  } else if (isLaterTrips && stateLatestTime) {
    const filteredSchedules = trains?.schedules?.filter((schedule) =>
      moment(schedule.schedule.scheduleSolution.railstart.dateTime).isAfter(
        moment(stateLatestTime)
      )
    )
    schedules = [].concat(...results, filteredSchedules)

    firstTime = getFirstAndLastTime(schedules).firstTime
    latestTime = getFirstAndLastTime(schedules).latestTime

    showNext = !moment(stateLatestTime).isSame(moment(latestTime))
  }

  return { results: schedules, latestTime, firstTime, showPrevious, showNext }
}

export const removeTrainSearchResults = () => ({
  type: constants.REMOVE_TRAIN_SEARCH_RESULTS,
})

export const fetchReturningTrainSearchAction =
  (data) => async (dispatch, getState) => {
    // dispatch(removeTrainSearchResults())
    dispatch({ type: constants.FETCH_TRAIN_ERROR, payload: '' })

    const { correlationID, selectedOutGoingTrip } = getState().train

    const payload = {
      schedule: {
        correlationID: correlationID,
        outboundBookingToken: selectedOutGoingTrip?.pricing?.bookingToken,
        isTwoWay: true,
        outbound: {
          from: data.from,
          to: data.to,
          date: data.dep,
          depTime: data.depTimeOutbound,
        },
        return: {
          date: data.arr,
          to: data.from,
          from: data.to,
          depTime: data.depTimeInbound,
        },
        selectedOutGoingTrip,
      },
      users: data.users,
    }
    dispatch(isLoading(true))

    const trains = await fetchReturningTrains(payload)
    if (!trains.schedules.length) {
      dispatch({
        type: constants.FETCH_TRAIN_ERROR,
        payload: 'no trains found',
      })
    }

    const trainsData = getTrainsData(trains, getState)
    dispatch(setTrainSearch(trainsData))
    dispatch(isLoading(false))
  }

export const removeAccommodations = () => ({
  type: constants.REMOVE_TRAIN_ACCOMMODATIONS,
})

export const fetchOutgoingCities = (string) => async (dispatch) => {
  const cities = await fetchRailCity(string)
  dispatch(setOutgoingCities(cities))
}

export const fetchIncomingCities = (string) => async (dispatch) => {
  const cities = await fetchRailCity(string)
  dispatch(setIncomingCities(cities))
}

export const fetchTrainAccommodations =
  ({ selectedOutGoingTrip, selectedReturnTrip }) =>
  async (dispatch, getState) => {
    const { correlationID } = getState().train

    const outgoingPayload = {
      correlationID,
      solutionID: selectedOutGoingTrip?.pricing.bookingToken,
    }
    dispatch({ type: constants.IS_TRAIN_ACCOMMODATIONS_LOADING, payload: true })

    const outgoing = await fetchFethTrainAccommodation(outgoingPayload)
    dispatch({
      type: constants.SET_TRAIN_ACCOMMODATIONS_OUTGOING,
      payload: outgoing?.accommodations,
    })
    if (selectedReturnTrip) {
      const returningPayload = {
        correlationID,
        solutionID: selectedReturnTrip.pricing.bookingToken,
      }
      const returning = await fetchFethTrainAccommodation(returningPayload)
      dispatch({
        type: constants.SET_TRAIN_ACCOMMODATIONS_RETURNING,
        payload: returning?.accommodations,
      })
    }
    dispatch({
      type: constants.IS_TRAIN_ACCOMMODATIONS_LOADING,
      payload: false,
    })
  }

const setTrainSearch = (trains) => ({
  type: constants.FETCH_TRAIN_RESULTS,
  payload: trains,
})

// Sets Outgoing and return  trip
export const setOutgoingTrain = (train) => (dispatch, getState) => {
  const { correlationID } = getState().train

  if (train?.train?.correlationID) {
    train.train.correlationCreateTime = moment().unix()
  }

  storage.setItem(
    'outgoingSelectedTrain',
    JSON.stringify({ ...train, correlationID })
  )

  dispatch({
    type: constants.SET_SELECTED_OUTGOING_TRAIN_TRIP,
    payload: { ...train, correlationID },
  })
}

export const setReturningTrain = (train) => (dispatch) => {
  storage.setItem('returningSelectedTrain', JSON.stringify(train))
  dispatch({ type: constants.SET_SELECTED_RETURN_TRIP, payload: train })
}

export const removeSelectedOutgoingTrain = () => (dispatch) => {
  storage.removeItem('outgoingSelectedTrain')
  dispatch({ type: constants.SET_SELECTED_OUTGOING_TRAIN_TRIP, payload: null })
}

export const removeSelectedReturningTrain = () => (dispatch) => {
  storage.removeItem('returningSelectedTrain')
  dispatch({ type: constants.SET_SELECTED_RETURN_TRIP, payload: null })
}
// Sets Cities for Search

export const setOutgoingCities = (cities) => ({
  type: constants.FETCH_OUTGOING_CITIES,
  payload: cities,
})

const setIncomingCities = (cities) => ({
  type: constants.FETCH_INCOMING_CITIES,
  payload: cities,
})

// IsFetchingbool
export const isLoading = (loading) => ({
  type: constants.IS_TRAIN_LOADING,
  payload: loading,
})

export const removeTrainSearchResultAction = () => ({
  type: constants.REMOVE_TRAIN_SEARCH_RESULTS,
})

export const removeAllTrainStates = () => ({
  type: constants.REMOVE_TRAIN_STATE,
})

export const setTrainSeatAction = (seat) => ({
  type: constants.SET_TRAIN_SEATS,
  payload: seat,
})

export const emptyTrainSeatsAction = () => ({
  type: constants.EMPTY_TRAIN_SEATS,
})

export const selectSpecificAccomm = (railindentifier) => ({
  type: constants.SPECIFIC_ACCOMMODATION_SELECT,
  payload: { railindentifier },
})

export const unselectSpecificAccomm = (railindentifier) => ({
  type: constants.SPECIFIC_ACCOMMODATION_UNSELECT,
  payload: { railindentifier },
})

//fetch seat map for single segment
const requestTripSeats = async (params, dispatch) => {
  const data = await fetchTrainSeatMap(params)
  if (!params) return false

  dispatch({
    type: constants.SET_TRAIN_SEATS_MAP,
    payload: {
      railInd: params?.segment?.railidentifier,
      seats: data?.result?.coachList?.length > 0 ? data?.result : null,
    },
  })
  return true
}

const fetchTripSeats = (trip, dispatch) => {
  if (trip?.train?.segments?.length > 0 && trip?.pricing?.classBySegm) {
    return trip.train.segments.map((s) => {
      const params = {
        correlationId: trip.train.correlationID,
        segment: {
          bookingClass: trip.pricing.classBySegm[s.ID],
          bookingCode: trip.pricing.bookingCodes[s.ID],
          accomTypes: trip.pricing.accomTypes[s.ID],
          railstart: { ...s.railstart },
          railend: { ...s.railend },
          railserviceProvider: { ...s.railserviceProvider },
          railvehicle: { ...s.railvehicle },
          railidentifier: s.railidentifier,
          Inventory: s.Inventory,
          Type: s.Type,
        },
      }
      return requestTripSeats(params, dispatch)
    })
  }

  return []
}

export const fetchTrainSeatsMap =
  (trip, returnTrip = null) =>
  async (dispatch) => {
    const promises = []
    promises.push(...fetchTripSeats(trip, dispatch))
    promises.push(...fetchTripSeats(returnTrip, dispatch))
    dispatch({ type: constants.TRAIN_SEATS_MAP_LOADING, payload: true })
    if (promises.length > 0) {
      Promise.allSettled(promises).then((res) => {
        dispatch({ type: constants.TRAIN_SEATS_MAP_LOADING, payload: false })
      })
    } else {
      dispatch({ type: constants.TRAIN_SEATS_MAP_LOADING, payload: false })
    }
  }

export const getTrainMiddleStepSettingsAction = () => async (dispatch) => {
  const fetchMiddleStep = await fetchGetMiddleStepSettings()

  dispatch({
    type: constants.SET_TRAIN_MIDDLESET_SETTINGS,
    payload: fetchMiddleStep,
  })
}

export const isEarlierTrip = (earlierTrip) => ({
  type: constants.IS_EARLIER_TRIPS,
  payload: earlierTrip,
})

export const isLaterTrip = (laterTrip) => ({
  type: constants.IS_LATER_TRIPS,
  payload: laterTrip,
})
