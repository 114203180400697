import React, { memo } from 'react'
// Redux
import { Provider } from 'react-redux'
import store from './store/store'

// Materials
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import theme from './theme/theme'
//i18n
import { I18nextProvider } from 'react-i18next'
import i18n from './utils/i18n'

import { create } from 'jss'
import rtl from 'jss-rtl'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

export default memo(({ initialState = {}, children }) => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store(initialState)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </Provider>
  </I18nextProvider>
))
