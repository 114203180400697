import React, { memo, useCallback, useMemo } from 'react'
import Flights from '../../pages/flights/flights'
import Hotels from '../../pages/hotels/hotels'
import Trains from '../../pages/trains/trains'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveSearchType } from '../../store/customization/customizationAction'
import Cars from '../../pages/cars/cars'

const MultiSearch = () => {
  const dispatch = useDispatch()
  const { activeSearchType: activeSearch, isIframe } = useSelector(
    (state) => state.customization
  )
  const defaultActiveSearchType = isIframe ? 'trains' : 'flights'
  const activeSearchType = activeSearch ?? defaultActiveSearchType

  const setSearchMode = useCallback(
    (type) => dispatch(setActiveSearchType(type)),
    [dispatch]
  )
  const searchMode = useMemo(
    () => activeSearchType || defaultActiveSearchType,
    [activeSearchType]
  )

  switch (searchMode) {
    case 'hotels':
      return (
        <Hotels
          showSearchModes={true}
          currentSearchMode={searchMode}
          setSearchMode={setSearchMode}
        />
      )
    case 'trains':
      return (
        <Trains
          showSearchModes={true}
          currentSearchMode={searchMode}
          setSearchMode={setSearchMode}
        />
      )
    case 'cars':
      return (
        <Cars
          showSearchModes={true}
          currentSearchMode={searchMode}
          setSearchMode={setSearchMode}
        />
      )
    case 'flights':
    default:
      return (
        <Flights
          showSearchModes={true}
          currentSearchMode={searchMode}
          setSearchMode={setSearchMode}
        />
      )
  }
}

export default memo(MultiSearch)
