import React, { memo, useCallback, Fragment } from 'react'
import {
  openBookingLinkInfoModalAction,
  toggleAgentAuthUser,
} from '../../store/modals/modalAction'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, useTheme } from '@material-ui/core'
import { setAgentAuthUser, setAuthType } from '../../store/auth/authAction'
import numberWithCommas from '../../utils/numberWithCommas'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  infoTopRow: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#fff0a9',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    height: '49px',
    borderBottom: `1px solid ${theme.palette.border}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',

    '&.errorLine': {
      backgroundColor: '#ffe1d6',
    },

    '&.darkErrorLine': {
      backgroundColor: '#ff5600',
    },

    '& .semibold': {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  emailTitle: {
    display: 'flex',
    maxWidth: '70%',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  emailTitleText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  priceError: {
    color: 'white',
  },
  showLinkModal: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.blueLink,
    padding: '0 0 0 12px',
    cursor: 'pointer',
  },
  separator: {
    backgroundColor: 'black',
    width: '2px',
    margin: '0 13px',
    height: '22px',
  },
  agentBtn: {
    fontSize: '14px',
    lineHeight: 'normal',
    fontWeight: theme.typography.fontWeightSemiBold,
    background: 'white',
    paddingTop: '7px',
    paddingBottom: '7px',
    textTransform: 'none',
    borderRadius: '5px',
    marginLeft: '10px',

    '&:hover': {
      background: 'white',
    },
  },
}))

const BookingLinkRow = ({ tripPrice = 0 }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const { user, isAuthenticated, authType } = useSelector((state) => state.auth)
  const {
    bookingLink,
    isBookingLink,
    isBooking,
    authTypes,
    disableActingAsUser,
  } = user || {}
  const { emailTitle, amount, unlimitedAmount } = bookingLink || {}
  const isBlPriceError =
    !!isBookingLink && tripPrice > amount && !unlimitedAmount
  const isBLError = !!isBookingLink && !!isBooking
  const { t } = useTranslation()
  const ATypesForAgent = ['agent', 'viaAgent']
  // const isAgent =
  //   !isBookingLink && !!isAuthenticated && ATypesForAgent.includes(authType)
  const isAgent =
    !isBookingLink &&
    !!isAuthenticated &&
    (authTypes?.includes('agent') || authTypes?.includes('viaAgent'))
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = currency === 'EUR' ? 'eur' : 'kr'

  let agentType = ''
  let aCustomerType = t(`customer`)
  if (isAgent && !!user.agentType) {
    agentType = t(`auth type ${user.agentType}`)
    if (user.agentType === 'group') aCustomerType = t(`co-worker`)
  }

  let agentMame = null
  if (ATypesForAgent.includes(authType)) agentMame = user?.agentData?.name
  if (!agentMame && authType === 'agent')
    agentMame = `${user.firstName} ${user.lastName}`

  const agentBtnClick = async () => {
    if (authType === 'agent') {
      dispatch(toggleAgentAuthUser(true))
    } else if (authType === 'viaAgent') {
      await dispatch(setAgentAuthUser(null, 'agent'))
      history.push('/')
    }
  }

  const toggleAgent = () => {
    const type = authType === 'default' ? 'agent' : 'default'
    dispatch(setAuthType(type))
  }

  const buildContent = () => {
    if (isBlPriceError) return buildPriceError()
    return buildMainContent()
  }

  const buildPriceError = () => {
    const price = numberWithCommas(tripPrice)
    const maxPrice = numberWithCommas(amount)
    return (
      <span className={classes.emailTitle}>
        <span className={classes.priceError}>
          {t('bl price error', { price, maxPrice, currencyCode })}
        </span>
      </span>
    )
  }

  const buildMainContent = () => (
    <Fragment>
      <span className={classes.emailTitle}>
        <span className={classes.emailTitleText}>&nbsp;{emailTitle}</span>
      </span>
      <span onClick={onShowLinkModal} className={classes.showLinkModal}>
        {t('see details')}
      </span>
    </Fragment>
  )

  const getAuthTypeLabel = () => {
    if (authType === 'default') return t('logged in as user')
    return t('logged-in as agent', { type: agentType.toUpperCase() })
  }

  const errorClasses = []

  if (!!isBlPriceError) {
    errorClasses.push('darkErrorLine')
  } else if (!!isBLError) {
    errorClasses.push('errorLine')
  }

  const onShowLinkModal = useCallback(() => {
    dispatch(openBookingLinkInfoModalAction())
  }, [dispatch])

  return (
    <Fragment>
      {!!isBookingLink && (
        <Box className={`${classes.infoTopRow} ${errorClasses.join(' ')}`}>
          {buildContent()}
        </Box>
      )}
      {isAgent && (
        <Box className={`${classes.infoTopRow}`}>
          <span className={'semibold'}> {getAuthTypeLabel()} </span>
          {!!agentMame && !isMobile && (
            <Fragment>
              <Box className={`${classes.separator} semibold`} />
              <span className={'semibold'}>{agentMame}</span>
            </Fragment>
          )}
          {authType !== 'default' && !disableActingAsUser && (
            <Button
              variant="outlined"
              color={authType === 'agent' ? 'primary' : 'secondary'}
              className={classes.agentBtn}
              onClick={agentBtnClick}
            >
              {authType === 'agent'
                ? t('act as customer', { type: aCustomerType })
                : t('logout user via agent', { type: agentType })}
            </Button>
          )}
          <Button
            variant="outlined"
            color="secondary"
            className={classes.agentBtn}
            onClick={toggleAgent}
          >
            {authType === 'default'
              ? t('login as group user', { type: agentType })
              : t('login as user')}
          </Button>
        </Box>
      )}
    </Fragment>
  )
}

export default memo(BookingLinkRow)
