import React, {
  memo,
  Fragment,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react'
import TripDetails from './cards/tripDetails'
import { Drawer, Box, Button, Avatar, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import MyTripFooter from './cards/shared/myTripFooter'
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter'
import prepareTransferData from '../../utils/booking/logic/transfer'
import { useTranslation } from 'react-i18next'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway'
import LocalHotelIcon from '@material-ui/icons/LocalHotel'
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import FetchMyTrips from '../../repositories/my-trips.js'
import { cancelRqByKey } from '../../repositories/agent'
import { setBookingPartCancelModalData } from '../../store/modals/modalAction'
import { fetchBookingsAction } from '../../store/myTrips/myTripsAction'
import AddNewItem from './addNewItem'
import CheckoutFetch from '../../repositories/checkout'
import { showSnackbarAction } from '../../store/snackbars/snackbarAction'
import { getPnrHotelData, getPnrCarData } from '../../pages/my-trips/my-trips'
import moment from 'moment'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook.js'

export const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  drawerBody: {
    padding: theme.spacing(0, 3),
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  drawerElement: {
    '& .MuiDrawer-paper': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  bodyOuter: {
    minWidth: '428px',
    maxWidth: '428px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
  headerIcon: {
    marginRight: '6px',
    height: '22px',
    width: 'auto',
  },
  travelerBlock: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    lineHeight: 'normal',
    padding: '22px 0',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  headerTextOuter: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    textAlign: 'center',
    color: theme.palette.common.black,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  headerUnderText: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    textAlign: 'center',
    marginTop: '2px',
    color: theme.palette.common.black,
  },
  footer: {
    padding: theme.spacing(0, 3, 2, 3),
  },
  headerBlock: {
    display: 'flex',
    padding: '10px 0',
    zIndex: '5',
    boxShadow: 'rgba(0, 0, 0, 0.10) 0px 5px 5px 0px',
  },
  headerClose: {
    height: '24px',
    width: '24px',
    marginRight: '14px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  usersEditBtn: {
    padding: '4px 6px',
    marginLeft: '8px',
    fontSize: '13px',
    lineHeight: 'normal',
    borderRadius: '4px',
    textTransform: 'none',
  },
  offlineMarker: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '2px 8px',
    borderRadius: '16px',
    textAlign: 'center',
    fontSize: '12px',
    width: '140px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  firstAvatar: {
    display: 'inline-flex',
    fontSize: '12px',
    backgroundColor: theme.palette.primary.main,
    width: '32px',
    height: '32px',
  },
  secondAvatar: {
    display: 'inline-flex',
    fontSize: '12px',
    backgroundColor: '#ffa634',
    position: 'relative',
    left: '-10px',
    width: '32px',
    height: '32px',
  },
  dot: {
    height: '4px',
    width: '4px',
    verticalAlign: 'middle',
    backgroundColor: theme.palette.grey[600],
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '8px',
    marginRight: '8px',
  },
  travelers: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1.3em',
    marginRight: '8px',
    color: '#666',
    marginLeft: '16px',
    display: 'flex',
  },
}))

const IconHandler = {
  flights: FlightTakeoffIcon,
  rails: DirectionsRailwayIcon,
  hotel: LocalHotelIcon,
  data: LocalHotelIcon,
  transfer: LocalTaxiIcon,
  car: DirectionsCarIcon,
}
const fetchExtKey = 'fetch_ext_trip_TripSideDrawer'

const TripSideDrawer = ({ model, onChangeActive, enableCancel = true }) => {
  const [dataLoading, setDataLoading] = useState(false)
  const [tripChanging, setTripChanging] = useState(false)
  const [tripData, setTripData] = useState(null)
  const [waitMessage, setWaitMessage] = useState(null)
  const [addNewItemDrawer, setAddNewItemDrawer] = useState(false)
  const { isTransfersEnabled } = useEnabledBookingTypesHook()
  const globalState = useSelector((state) => state)
  const { user } = globalState.auth
  const dispatch = useDispatch()
  const { rails, flights, transfers, misc } = tripData || {}
  const hotelList = getPnrHotelData(model)
  const rentalCar = getPnrCarData(model)
  const [expandedPart, setExpandedPart] = useState(null)
  const expandedRef = useRef()
  const mainRef = useRef(null)
  const hotel = hotelList?.[0] || null

  const payload = {
    rails: !!rails,
    flights: !!flights,
    hotel: !!hotelList,
    transfer: !!transfers,
    car: !!rentalCar,
    misc: !!misc,
  }
  const tripIsset = Object.values(payload).includes(true)
  const classes = useStyles()
  const { t } = useTranslation()
  const passengers = tripData?.passengers
  let itmKey = 1
  const { isLoading: tripsFetching } = globalState.myTrips
  const isLoading = !!tripsFetching || !!tripChanging

  useEffect(() => {
    if (!!waitMessage && !isLoading && !dataLoading) {
      setTimeout(() => {
        dispatch(
          showSnackbarAction(waitMessage.type, waitMessage.message, {
            x: 'right',
            y: 'top',
          })
        )
      }, 300)
      setWaitMessage(null)
    }
  }, [waitMessage, isLoading, dataLoading, dispatch, setWaitMessage])

  const isOffline = model?.parsed === true

  const getHeaderTxt = () => {
    if (!!misc) {
      const tmpTypes = []
      misc.forEach((segment) => {
        const tmpText = t(segment.type)
        if (!tmpTypes.includes(tmpText)) tmpTypes.push(tmpText)
      })
      return tmpTypes.join(', ')
    } else if (!!flights) {
      const fl = flights.filter((flight) => flight.type === 'trip')
      const txt = fl?.[fl.length - 1]?.toCity || fl?.to?.displayName || ''
      return _.startCase(txt.toLowerCase())
    } else if (!!rails) {
      const txt = rails.outbound.railend.locationName || ''
      return _.startCase(txt.toLowerCase())
    } else if (!!hotel) {
      return capitalizeFirstLetter(hotel?.title || '')
    } else if (!!rentalCar) {
      return capitalizeFirstLetter(rentalCar?.pickLocation?.city || '')
    } else {
      return t('error')
    }
  }

  const cancellable = useMemo(() => {
    const isPassenger =
      model?.passengerIds?.length && model.passengerIds.includes(user.uniqueId)
    const isOnlyUser =
      !model?.userId &&
      model?.passengerIds?.length === 1 &&
      model.passengerIds.includes(user.uniqueId)
    const isEnabled = !!model?.isUpcoming && !model?.cancelled && enableCancel
    const isOwner = model?.userId === user.uniqueId || isOnlyUser
    const isNotExternalPayment =
      !model?.netsPaymentId && !model?.paiwiseCheckoutId
    const bookingPolicy = user?.bookingPolicy
    const bookingCalendar = user?.bookingCalendar

    return {
      isOnlyUser,
      isEnabled,
      isOwner,
      isPassenger,
      isNotExternalPayment,
      allowCancel: isOwner || bookingCalendar || (bookingPolicy != 'none' && isPassenger),
    }
  }, [model, user, enableCancel])

  const onCancelPassengers = async () => {
    dispatch(
      setBookingPartCancelModalData({
        type: 'passengers',
        pnr: model?.pnr,
        model: model,
        passengers: passengers,
      })
    )
  }

  const onClose = () => {
    setDataLoading(false)
    cancelRqByKey(fetchExtKey)
    onChangeActive(null)
    setExpandedPart(null)
  }

  useEffect(() => {
    setExpandedPart(null)
  }, [model])

  const prepareTripData = useCallback(async (modelData) => {
    cancelRqByKey(fetchExtKey)
    if (!modelData?.external) {
      setTripData(
        !!modelData && typeof modelData === 'object'
          ? { ...modelData }
          : modelData
      )
      setDataLoading(false)
    } else {
      const fetchMyTrips = new FetchMyTrips()
      setDataLoading(true)
      const errTripData = {
        type: 'error',
        message: 'failed to fetch external Booking',
      }
      try {
        const fetchedData = await fetchMyTrips.getExternalTrip(
          modelData.pnr,
          fetchExtKey
        )
        setTripData(!!fetchedData?.pnr ? fetchedData : errTripData)
      } catch (e) {
        setTripData(errTripData)
      }
      setDataLoading(false)
    }
  }, [])

  useEffect(() => {
    prepareTripData(model)
  }, [model, prepareTripData])

  const renderPassengers = () => {
    if (!model) return null
    if (!tripData) return null
    let first = passengers[0]
    let second = passengers[1]
    let count = passengers.length

    let fromDate = moment(tripData.date)
    let toDate = moment(tripData.endDate)
    let isSameDay = tripData.date === tripData.endDate

    const getLetters = (passenger) =>
      (
        (passenger?.firstName?.charAt(0) || '') +
        (passenger?.lastName?.charAt(0) || '')
      ).toUpperCase()

    let names = passengers
      ?.map(({ firstName, lastName, cancelled }, key, array) => {
        const preg = /^([A-z]+)\s+(mrs|mr|ms|miss)\s+([A-z]+|)$/gi
        const pName = `${firstName} ${lastName}`
          .trim()
          .replace(
            preg,
            (match, p1, p2, p3) => `${_.capitalize(p2)} ${p1} ${p3}`
          )
        return (
          <span
            key={`itm-${itmKey++}`}
            style={{ textDecoration: cancelled ? 'line-through' : 'none' }}
          >
            {pName}
            {key < array.length - 1 ? ', ' : ''}
          </span>
        )
      })
      .filter((x) => x)

    const timeText = isSameDay
      ? toDate.format('D MMM')
      : toDate.month() === fromDate.month()
        ? `${fromDate.date()}-${toDate.format('D MMM')}`
        : `${fromDate.format('D MMM')}-${toDate.date()} ${toDate.format('MMM')}`

    return (
      <Box display="flex">
        <Box component="span">
          <Avatar className={classes.firstAvatar}>{getLetters(first)}</Avatar>
          {count === 2 && (
            <Avatar className={classes.secondAvatar}>
              {getLetters(second)}
            </Avatar>
          )}
          {count > 2 && (
            <Avatar className={classes.secondAvatar}>+{count - 1}</Avatar>
          )}
        </Box>

        <Box
          className={classes.travelers}
          component="span"
          flex={1}
          alignItems="center"
        >
          <span>
            {names}
            <span className={classes.dot} />
            {timeText}
          </span>
        </Box>
      </Box>
    )
  }

  const renderIcons = Object.keys(payload)
    .map((key, i) => {
      const type = payload[key]
      if (type === undefined || !type || type.length === 0) return null
      const Icon = IconHandler[key]
      if (!Icon) return null
      return (
        <Icon
          key={`itm-${itmKey++}`}
          className={classes.headerIcon}
          color="primary"
        />
      )
    })
    .filter((x) => x)

  const renderHeader = () => {
    const txt = getHeaderTxt()

    return (
      <Box key={`itm-${itmKey++}`} className={classes.headerBlock}>
        <Box key={`itm-${itmKey++}`} minWidth={'50px'} flex={1} />
        <Box key={`itm-${itmKey++}`} flex={4}>
          <Box
            key={`itm-${itmKey++}`}
            className={classes.headerTextOuter}
            textAlign={'center'}
          >
            {renderIcons}{' '}
            <span key={`itm-${itmKey++}`} className={classes.headerText}>
              {txt}
            </span>
          </Box>
          {!!tripData?.pnr && (
            <Box key={`itm-${itmKey++}`} textAlign={'center'}>
              <span key={`itm-${itmKey++}`} className={classes.headerUnderText}>
                {t('booking reference')}: {tripData.pnr}
              </span>
            </Box>
          )}

          {tripData?.parsed && (
            <Box key={`itm-${itmKey++}`} className={classes.offlineMarker}>
              {t('offline booking')}
            </Box>
          )}
        </Box>
        <Box
          key={`itm-${itmKey++}`}
          minWidth={'50px'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          display={'flex'}
          flex={1}
        >
          <CloseIcon
            key={`itm-${itmKey++}`}
            onClick={() => onChangeActive(null)}
            className={classes.headerClose}
          />
        </Box>
      </Box>
    )
  }

  const onSelectTransfer = async (transfers, tUsers) => {
    const fetchCheckout = new CheckoutFetch()
    const costfields = await fetchCheckout.fetchCostFields()
    const fop = costfields?.paymentMethods?.primaryPayment || 'profileCC'
    setAddNewItemDrawer(false)
    try {
      setTripChanging(true)
      const fetchMyTrips = new FetchMyTrips()
      const data = prepareTransferData(transfers, globalState, {
        users: tUsers,
        fop,
      })
      const rsData = await fetchMyTrips.addTransfersToPnr(data, model.pnr)
      if (!rsData?.result) throw Error('add request failed')
      const warnings = rsData?.warnings?.length
        ? rsData.warnings.map((w) => t(w))
        : null
      const message = warnings || t('transfers have been added')
      setWaitMessage({ type: warnings ? 'warning' : 'success', message })
      dispatch(fetchBookingsAction({}, true))
      setTripChanging(false)
    } catch (e) {
      dispatch(
        showSnackbarAction('error', t('failed to add transfers'), {
          x: 'right',
          y: 'top',
        })
      )
      setTripChanging(false)
    }
  }

  const handleExpand = (component) => {
    setExpandedPart(component)
  }

  // debugger
  return (
    <>
      {!!model && !!user?.allowAddItemsToPnr && isTransfersEnabled && (
        <AddNewItem
          model={model}
          onSelect={onSelectTransfer}
          isOpen={addNewItemDrawer}
          onClose={() => setAddNewItemDrawer(false)}
        />
      )}
      <Drawer
        className={classes.drawerElement}
        anchor={'right'}
        open={!!tripIsset || !!dataLoading}
        onClose={() => onClose()}
        ref={mainRef}
      >
        <Box
          key={`itm-${itmKey++}`}
          display={'flex'}
          height={'100%'}
          className={classes.bodyOuter}
          flexDirection={'column'}
        >
          <Slide
            direction="left"
            in={expandedPart != null}
            container={expandedRef.current}
          >
            <Box ref={expandedRef}>{expandedPart ? expandedPart : null}</Box>
          </Slide>
          {!expandedPart && (
            <Fragment>
              {!!tripIsset && renderHeader()}
              {!!isLoading || !!dataLoading ? (
                <Box
                  key={`itm-${itmKey++}`}
                  flex={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={classes.drawerBody}
                >
                  <CircularProgress color="primary" size={50} />
                </Box>
              ) : (
                <Fragment>
                  <Box
                    key={`itm-${itmKey++}`}
                    flex={1}
                    className={classes.drawerBody}
                  >
                    <Box key={`itm-${itmKey++}`} pb={3}>
                      {passengers?.length && (
                        <Box
                          key={`itm-${itmKey++}`}
                          className={classes.travelerBlock}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                          >
                            <Box>{renderPassengers}</Box>
                            {cancellable.isEnabled &&
                              cancellable.isNotExternalPayment &&
                              cancellable.allowCancel && (
                                <Button
                                  className={classes.usersEditBtn}
                                  onClick={onCancelPassengers}
                                  variant="outlined"
                                  color="primary"
                                >
                                  {t('edit btn')}
                                </Button>
                              )}
                          </Box>
                        </Box>
                      )}
                      {!!tripIsset && (
                        <TripDetails
                          key={`itm-${itmKey++}`}
                          model={tripData}
                          enableCancel={
                            cancellable.isEnabled && cancellable.allowCancel
                          }
                          onExpand={(component, type) =>
                            handleExpand(component, type)
                          }
                          onClose={() => setExpandedPart(null)}
                          mainRef={mainRef}
                        />
                      )}
                      {!!tripIsset && (
                        <MyTripFooter
                          key={`itm-${itmKey++}`}
                          showOnly={['bookData']}
                          model={tripData}
                          enableCancel={
                            cancellable.isEnabled && cancellable.allowCancel
                          }
                        />
                      )}
                    </Box>
                  </Box>
                  <Box key={`itm-${itmKey++}`} className={classes.footer}>
                    {!!tripIsset && (
                      <MyTripFooter
                        key={`itm-${itmKey++}`}
                        showOnly={['buttons']}
                        model={tripData}
                        onExtend={() => setAddNewItemDrawer(true)}
                        enableExtend={
                          cancellable.isEnabled &&
                          !!user?.allowAddItemsToPnr &&
                          !isOffline &&
                          isTransfersEnabled
                        }
                        enableCancel={
                          cancellable.isEnabled && cancellable.allowCancel
                        }
                      />
                    )}
                  </Box>
                </Fragment>
              )}
            </Fragment>
          )}
        </Box>
      </Drawer>
    </>
  )
}

export default memo(TripSideDrawer)
