import React, { memo, useCallback, useEffect, useMemo, Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Box,
  AppBar,
  Tabs,
  Tab,
  Paper,
  useTheme,
  useMediaQuery,
  CircularProgress,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBookingsAction } from '../../store/myTrips/myTripsAction'
import { useTranslation } from 'react-i18next'
import ViewHandler from '../../containers/myTrips/viewHandler'
import TripSideDrawer from '../../containers/myTrips/tripSideDrawer'
import { useFormik } from 'formik'
import SearchIcon from '@material-ui/icons/Search'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { storage } from 'local-storage-fallback'
import { getApiUrl } from '../../repositories/agent'
import { showSnackbarAction } from '../../store/snackbars/snackbarAction'

// this component is being wrongly rendered twice with full unmounting
// this causes PNR sync to be called twice
// this is a workaround to prevent the second call
let tsPNRSyncStarted = 0

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const getPnrHotelData = (model) => {
  if (
    !model?.hotel ||
    (Array.isArray(model.hotel) && !model.hotel.length)
  ) {
    return null;
  }
  if (!model.hotel.length) return [model.hotel];
  return model.hotel;
}

export const getPnrCarData = (model) => {
  if (model?.rentalCar?.car?.dropLocation) return model.rentalCar.car
  if (model?.rentalCar?.dropLocation) return model.rentalCar
  if (model?.car?.dropLocation) return model.car
  return null
}
export const getPnrProductTypes = (model) => {
  const { flights, transfers, rails, hotel } = model
  const products = []
  const rentalCar = getPnrCarData(model)

  if (!!flights) products.push('flight')
  if (!!rails) products.push('train')
  if (!!rentalCar) products.push('rental car')
  if (!!transfers) products.push('transfer')
  if (!!hotel) products.push('hotel')
  return products
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  progressOuter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '184px',

    [theme.breakpoints.down('sm')]: {
      paddingTop: '106px',
    },
  },
  filterField: {
    fontSize: theme.spacing(2),
    color: theme.palette.common.black,
    maxWidth: '120px',
    '& .MuiSelect-icon': {
      color: 'black',
    },
    '& .MuiInputBase-input::placeholder': {
      color: `${theme.palette.common.black} !important`,
      opacity: 1,
    },
    '& .MuiInput-underline, &.MuiInput-underline': {
      marginBottom: '22px',
      '&:hover': {
        '&:before': {
          borderColor: theme.palette.primary.main,
          borderWidth: '4px',
        },
      },
      '&:before': {
        borderColor: theme.palette.border,
      },
      '&:after': {
        borderWidth: '4px',
      },
      '&:before, &:after': {
        bottom: '-23px',
      },
    },

    [theme.breakpoints.down('sm')]: {
      flex: 1,
      maxWidth: '1000px',

      '& .MuiSelect-selectMenu': {
        textAlign: 'right',
      },
    },
  },
  filterSelectField: {
    maxWidth: '1000px',
    minWidth: '120px',
  },
  pageHeader: {
    color: theme.palette.common.black,
    fontSize: '32px',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 'normal',
    marginLeft: '20px',
    marginBottom: '32px',
  },
  appBar: {
    background: 0,
  },
  filterForm: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '100%',
    },
  },
  filterFormInner: {
    display: 'flex',
    flex: 1,
    borderBottom: `1px solid ${theme.palette.border}`,

    [theme.breakpoints.down('sm')]: {
      paddingTop: '25px',
    },
  },
  fieldBlock: {
    padding: '0 66px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  tabsOuter: {
    '& .MuiTabs-fixed': {
      width: 'auto',
      flex: 'none',
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      },
    },
    '& .MuiTabs-scroller .MuiTabs-flexContainer': {
      borderBottom: `1px solid ${theme.palette.border}`,
    },
  },
  outerBlock: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '820px',
    background: 'white',
    padding: '37px 25px',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
      padding: theme.spacing(3, 2),
      paddingTop: theme.spacing(2),
      marginTop: 0,
      borderRadius: 0,
    },
  },
  paper: {
    borderRadius: theme.spacing(1),
    flex: 1,
  },
  myTripNote: {
    marginTop: '30px',
    fontSize: '12px',
    fontStyle: 'italic',
    color: theme.palette.common.black,
  },
  tab: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.spacing(2),
    padding: '0 0 0 21px',
    margin: '0 45px',
    minWidth: '160px',
    background: 'white',
    opacity: 1,
    borderRadius: theme.spacing(0),
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    textTransform: 'capitalize',

    '& .MuiTab-wrapper': {
      minWidth: '100px',
      maxWidth: '160px',
      alignItems: 'flex-start',
    },
    '&.Mui-selected': {
      color: '#07c5b9',
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:first-child': {
      marginLeft: '0',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTab-wrapper': {
        maxWidth: '1000px',
        alignItems: 'center',
      },
      '&:last-child': {
        marginRight: '0',
      },
      maxWidth: '1000px',
      minWidth: '20px',
      flex: 1,
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
    },
  },
}))

let timeoutKey = null

const MyTrips = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [activeTrip, setActiveTrip] = useState([])
  const [bookingsScanStatus, setBookingsScanStatus] = useState({})
  const handleChange = (_event, newValue) => setValue(newValue)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { isLoading, bookings } = useSelector((state) => state.myTrips)
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(fetchBookingsAction())
  }, [dispatch])

  const renderLoading = () => (
    <Box className={classes.progressOuter}>
      <CircularProgress thickness={6} size={55} />
    </Box>
  )

  const onFilter = (values) => {
    dispatch(
      fetchBookingsAction({
        search: values?.search || null,
        users:
          values?.user && values.user !== '' && values.user !== 'none'
            ? [values?.user]
            : null,
      })
    )
  }

  const formik = useFormik({
    initialValues: {
      search: null,
      user: null,
    },
    onSubmit: onFilter,
  })

  const onChangeFilter = (e) => {
    if (timeoutKey) clearTimeout(timeoutKey)
    formik.handleChange(e)
    timeoutKey = setTimeout(() => {
      formik.handleSubmit()
    }, 400)
  }

  const blurActive = (element) => {
    document.activeElement.blur()
  }

  const handleChangeActive = useCallback((model) => {
    const value = typeof model?.pnr === 'string' ? model.pnr : null
    setActiveTrip(value)
  }, [])

  const { upcoming, past } = useMemo(() => {
    let upcoming = []
    let past = []
    if (bookings && bookings.length > 0) {
      bookings.forEach((item) => {
        if (!item.isUpcoming || !!item.cancelled) {
          past.push({ ...item })
        } else {
          upcoming.push({ ...item })
        }
      })

      upcoming = upcoming.sort((a, b) => new Date(a.date) - new Date(b.date))
      past = past.sort((a, b) => new Date(b.date) - new Date(a.date))
    }

    return { upcoming, past }
  }, [bookings])

  const activeTripData = useMemo(() => {
    if (!activeTrip || !bookings?.length) return null
    const res = bookings.find((t) => t.pnr === activeTrip)
    return res || null
  }, [bookings, activeTrip])

  const bookingsLength = bookings.length

  useEffect(() => {
    // see my comments at tsPNRSyncStarted variable init
    if (!bookings.length || (new Date() - tsPNRSyncStarted < 1000)) return

    tsPNRSyncStarted = new Date()

    // TODO: move the reader to some service later

    const existingPnrs = bookings.map((b) => b.pnr)
    const newBookingsScanStatus = Object.fromEntries(existingPnrs.map((pnr) => [pnr, 'synced']))

    const sse = new EventSource(getApiUrl('/v2/get-bookings-live') + '?t=' + storage.getItem('jwtToken') + '&userExternalId=' + user.uniqueId)

    sse.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)
        // console.log('SSE incoming message', data)

        switch (data.cmd) {
          case 'PNR_List':
            for (const pnr of data.pnrs) {
              newBookingsScanStatus[pnr] = existingPnrs.includes(pnr) ? 'sync-existing' : 'sync-new'
            }
            setBookingsScanStatus(newBookingsScanStatus)
            break

          case 'PNR_Retrieve':
            setBookingsScanStatus((prev) => ({
              ...prev,
              [data.pnr]: 'synced',
            }))
            break

          case 'synced':
            void dispatch(fetchBookingsAction())
            // fetch bookings from our DB
            break

          case 'error':
            // just reset them for now
            setBookingsScanStatus(Object.fromEntries(existingPnrs.map((pnr) => [pnr, 'synced'])))
            dispatch(showSnackbarAction('error', 'PNR sync failed', { x: 'right', y: 'top' }))
        }
      } catch {
        sse.close()
      }
    }
    sse.onerror = () => {
      sse.close()
    }
  }, [bookingsLength])

  const showNote =
    (value === 0 && upcoming.length > 0) || (value === 1 && past.length > 0)

  return (
    <Fragment>
      <Container maxWidth="lg" disableGutters>
        <Box className={classes.outerBlock}>
          <Box flex={1}>
            {!isMobile && (
              <Box className={classes.pageHeader}>{t('mth bookings')}</Box>
            )}
            <AppBar position="static" className={classes.appBar} elevation={0}>
              <Box display={'flex'} flexWrap={isMobile ? 'wrap' : null}>
                <Box flex={isMobile ? 1 : null}>
                  <Tabs
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#07c5b9',
                        height: '4px',
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    className={classes.tabsOuter}
                  >
                    <Tab
                      disableRipple
                      className={classes.tab}
                      label={`${t('upcoming')} ${
                        !isLoading || isLoading === 'silent'
                          ? '(' + upcoming.length + ')'
                          : ''
                      }`}
                      {...a11yProps(0)}
                    />
                    <Tab
                      disableRipple
                      className={classes.tab}
                      label={`${t('not active')} ${
                        !isLoading || isLoading === 'silent'
                          ? '(' + past.length + ')'
                          : ''
                      }`}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <Box className={classes.filterForm}>
                  <Box className={classes.filterFormInner}>
                    <Box className={classes.fieldBlock}>
                      <TextField
                        name="search"
                        placeholder={t('search')}
                        className={classes.filterField}
                        onChange={(e) => onChangeFilter(e)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box className={classes.fieldBlock}>
                      <Select
                        name="user"
                        autoWidth={isMobile}
                        defaultValue={'none'}
                        className={`${classes.filterField} ${classes.filterSelectField}`}
                        onChange={(e) => onChangeFilter(e)}
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{ onExited: blurActive }}
                      >
                        <MenuItem value={'none'}>
                          {t('all travellers')}
                        </MenuItem>
                        <MenuItem
                          value={user.uniqueId}
                        >{`${user.firstName} ${user.lastName}`}</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </AppBar>
            <Paper elevation={0} square className={classes.paper}>
              <Box flex={1}>
                <TabPanel flex={1} value={value} index={0}>
                  {!!isLoading && isLoading !== 'silent' ? (
                    renderLoading()
                  ) : (
                    <ViewHandler
                      onChangeActive={handleChangeActive}
                      bookings={upcoming}
                      bookingsScanStatus={bookingsScanStatus}
                    />
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {!!isLoading && isLoading !== 'silent' ? (
                    renderLoading()
                  ) : (
                    <ViewHandler
                      isPast={true}
                      onChangeActive={handleChangeActive}
                      bookings={past}
                      scanStatus={bookingsScanStatus}
                    />
                  )}
                </TabPanel>
              </Box>
            </Paper>
          </Box>
          {!isLoading && showNote && (
            <Box className={classes.myTripNote}>{t('my trip note')}</Box>
          )}
        </Box>
      </Container>
      <TripSideDrawer
        model={activeTripData}
        onChangeActive={handleChangeActive}
      />
    </Fragment>
  )
}

export default memo(MyTrips)
