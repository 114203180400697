import React, { createContext, memo, useRef, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Button,
  Box,
  AppBar,
  Toolbar,
  Grid,
  Container,
  Typography,
} from '@material-ui/core'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import AutoCompleteInput from '../../../components/reusable/autoCompleteInput'
import ReusableDatpicker from '../../../components/reusable/datePicker/reusableDatePicker_new'
// Hotel
import SelectCounts from '../../../components/reusable/selectCounts'
import AutocompleteInutHotel from '../../../components/reusable/autoCompleteInputHotel'
import { ReactComponent as Arrows } from '../../../assets/images/Arrows-WHT.svg'
import MuiPopover from '@material-ui/core/Popover'
import { useSelector } from 'react-redux'

import DesktopSearchNav from '../../../components/search/desktopSearchNav'
import isWidget from '../../../utils/isWidget'

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    fontSize: '32px',
    color: 'white',
    lineHeight: 'normal',
    marginBottom: '67px',
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  eventIconBlock: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    zIndex: 1,
    marginTop: '-7px',
  },
  eventIcon: {
    color: theme.palette.iframeSecondary.main,
  },
  container: {
    transform: 'translate(0px, -50%)',
  },
  inputContainer: {
    display: 'flex',
    position: 'relative',
  },
  dateDelim: {
    height: '70%',
    borderRight: `1px dashed ${theme.palette.border}`,
  },
  changeBtn: {
    top: '50%',
    marginTop: '-12px',
    right: '-12px',
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'white',
  },
  searchBtn: {
    width: '100%',
    fontWeight: 600,
    borderRadius: `0 8px 8px 0`,
    minHeight: '100%',
    padding: 0,
    margin: 0,
    fontSize: '16px',
    transform: 'scaleY(1.04)',
  },
  lowercase: {
    textTransform: 'capitalize',
  },
  border: {
    height: '100%',
    borderRight: '1px dashed ' + theme.palette.border,
  },
  weight: {
    fontWeight: 600,
    letterSpacing: 0.7,
  },
  addTravelerBtn: {
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'none',
  },
  arrows: {
    height: '18px',
    width: '18px',
  },

  popoverText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    fontSize: '12px',
  },

  destContainer: {
    paddingTop: '9px',
  },

  destOuterContainer: {
    width: '540px',
  },

  datesBlock: {
    // width: '248px',
  },

  countsBlock: {
    minWidth: '200px',
  },
  searchBtnTrain: {
    flexGrow: 'unset',
    flexBasis: 'unset',
    maxWidth: 200,
    minWidth: 200,
  },
  searchButtonIframe: {
    flexGrow: 'unset',
    flexBasis: 'unset',
    maxWidth: 166,
    minWidth: 166,
  },
  fromTo: {
    maxWidth: '28%',
    flexBasis: '28%',
  },
  fromToElse: {
    maxWidth: '35%',
    flexBasis: '35%',
  },
}))

const Popover = withStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: '#e02020',
    boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.15)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    marginTop: theme.spacing(1.1),
    overflow: 'visible',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 0,
      height: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderBottom: '15px solid #e02020',
      borderTop: '15px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      marginTop: '-35px',
    },
  },
}))(MuiPopover)

export const RefsContext = createContext([{}, () => {}])

const GuestExpandedSearch = ({
  from,
  setFrom,
  to,
  setTo,
  departure,
  setDeparture,
  arrival,
  setArrival,
  switchDestinations,
  isOneWay,
  onSubmit,
  getCities,
  optionLabel,
  array,
  renderOption,
  HOTEL,
  setHotel,
  setIsPopoverOpenFrom,
  isPopoverOpenFrom,
  setIsPopoverOpenTo,
  isPopoverOpenTo,
  setIsPopoverOpenTraveller,
  counts,
  modifyCounts,
  setTicketClass,
  ticketClass,
  ticketClasses,
  showSearchModes,
  currentSearchMode,
  setSearchMode,
  TRAIN,
  disableReturn,
}) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const handleClose = () => {
    if (HOTEL) {
      setIsPopoverOpenTraveller(false)
      setIsPopoverOpenFrom(false)
    } else {
      setIsPopoverOpenFrom(false)
      setIsPopoverOpenTo(false)
      setIsPopoverOpenTraveller(false)
    }
  }

  const { isAuthenticated, messages } = useSelector((state) => state.auth)
  const { headline } = useSelector((state) => state.customization)
  const openFrom = Boolean(isPopoverOpenFrom)
  const openTo = Boolean(isPopoverOpenTo)

  const divFromRef = useRef()
  const divToRef = useRef()
  const toRef = useRef(null)
  const btnRef = useRef(null)

  const isIframe = isWidget

  const [hotelDatePicker, setHotelDatePicker] = useState({
    from: false,
    to: false,
  })
  const [openRooms, setOpenRooms] = useState()

  const lang = (i18n.language?.split('-')[0] || 'sv').toLowerCase()

  const headlineText =
    headline?.[lang] || messages?.[`headline-${lang}`] || t('book easily')

  return (
    <Container
      className={!isIframe && classes.container}
      style={
        TRAIN && !isIframe
          ? {}
          : isIframe
          ? { maxWidth: '1280px' }
          : { maxWidth: '1145px' }
      }
      disableGutters
    >
      {!isIframe && (
        <Box className={classes.title} id="headline">
          {headlineText}
        </Box>
      )}

      {showSearchModes && (
        <DesktopSearchNav
          currentSearchMode={currentSearchMode}
          setSearchMode={setSearchMode}
        />
      )}

      <AppBar
        color="inherit"
        position="static"
        style={
          isIframe
            ? {
                borderRadius: 8,
                borderTopLeftRadius: 0,
                boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
              }
            : {
                borderRadius: 8,
                boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
              }
        }
      >
        <Toolbar disableGutters style={{ minHeight: '56px' }}>
          <Box p={isAuthenticated ? 1 : 0} width="100%">
            <Grid container>
              <Grid item xs>
                <Grid
                  container
                  style={{
                    border: '1px solid #e6e6e6',
                    borderRadius: '8px',
                    positon: 'relative',
                    minHeight: '56px',
                  }}
                >
                  {HOTEL ? (
                    <Grid
                      item
                      xs
                      sm={isIframe ? 3 : 4}
                      className={classes.destContainer}
                    >
                      <label>
                        <Box pl={1} ref={divFromRef}>
                          <AutocompleteInutHotel
                            value={from}
                            onChange={setHotel}
                            array={array}
                            fetch={getCities}
                            optionLabel={optionLabel}
                            label={'Destination'}
                            renderOption={renderOption}
                            hotelDatePicker={setHotelDatePicker}
                          />
                        </Box>
                      </label>
                      <Popover
                        className={classes.popoverText}
                        id="fromfield"
                        open={openFrom}
                        anchorEl={divFromRef.current}
                        onClose={handleClose}
                        style={{ top: '70px' }}
                      >
                        <Typography className={classes.popoverText}>
                          {t('enter place of departure')}
                        </Typography>
                      </Popover>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={TRAIN ? 4 : 6}
                      className={
                        TRAIN || HOTEL ? classes.fromTo : classes.fromToElse
                      }
                    >
                      <Grid container style={{ height: '100%' }}>
                        <Grid item xs sm={6}>
                          <Box
                            className={classes.border}
                            position="relative"
                            ref={divFromRef}
                            pt={'9px'}
                          >
                            <IconButton
                              className={classes.changeBtn}
                              onClick={switchDestinations}
                              color="primary"
                              tabIndex="-1"
                            >
                              <SwapHorizIcon
                                className={isIframe && classes.eventIcon}
                              />
                            </IconButton>
                            <label>
                              <Box pl={1}>
                                <AutoCompleteInput
                                  renderOption={renderOption}
                                  fetch={getCities}
                                  value={from}
                                  onChange={(_, value) => setFrom(value)}
                                  array={array}
                                  class={classes.searchDest}
                                  optionLabel={optionLabel}
                                  label={t('search from')}
                                  placeholder={t('search from')}
                                  toRef={toRef}
                                  desktop
                                  isFrom
                                />
                              </Box>
                            </label>
                          </Box>
                          <Popover
                            className={classes.popoverText}
                            id="fromfield"
                            open={openFrom}
                            anchorEl={divFromRef.current}
                            onClose={handleClose}
                            style={{ top: '70px' }}
                          >
                            <Typography className={classes.popoverText}>
                              {t('enter place of departure')}
                            </Typography>
                          </Popover>
                        </Grid>
                        <Grid
                          className={classes.destContainer}
                          item
                          xs={12}
                          sm={6}
                          ref={divToRef}
                        >
                          <label>
                            <Box pl={1}>
                              <AutoCompleteInput
                                forwardedRef={toRef}
                                renderOption={renderOption}
                                fetch={getCities}
                                value={to}
                                onChange={(_, value) => setTo(value)}
                                array={array}
                                optionLabel={optionLabel}
                                label={t('search to')}
                                placeholder={t('search to')}
                                // setOpenDatePicker={setOpenDatePicker}
                                desktop
                                isTo
                              />
                            </Box>
                          </label>
                          <Popover
                            id="tofield"
                            open={openTo}
                            anchorEl={divToRef.current}
                            onClose={handleClose}
                            style={{ top: '70px' }}
                          >
                            <Typography className={classes.popoverText}>
                              {t('enter destination')}
                            </Typography>
                          </Popover>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm className={classes.datesBlock}>
                    <Grid container style={{ height: '100%' }}>
                      <Grid item xs={6} sm={TRAIN ? 3 : 6}>
                        <Box
                          position={'relative'}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            borderLeft: '1px solid #e6e6e6',
                            height: '100%',
                          }}
                        >
                          <Box p={1} pt={0} minWidth={'120px'} display={'flex'}>
                            <ReusableDatpicker
                              textAlign={'left'}
                              disableLabel
                              value={departure}
                              onChange={setDeparture}
                              from={departure}
                              to={arrival}
                              isOutgoing
                              back
                              HOTEL={HOTEL}
                              btnRef={btnRef}
                            />
                          </Box>
                          <Box className={classes.dateDelim} />
                        </Box>
                      </Grid>
                      {TRAIN && (
                        <Grid item xs={6} sm={3}>
                          <Box
                            position={'relative'}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              borderLeft: '1px solid #e6e6e6',
                              height: '100%',
                            }}
                          >
                            <Box
                              p={1}
                              pt={0}
                              minWidth={'120px'}
                              display={'flex'}
                            >
                              <ReusableDatpicker
                                textAlign={'left'}
                                disableLabel
                                value={departure}
                                onChange={setDeparture}
                                from={departure}
                                to={arrival}
                                isOutgoing
                                back
                                HOTEL={HOTEL}
                                btnRef={btnRef}
                                timeOnly
                              />
                            </Box>
                            <Box className={classes.dateDelim} />
                          </Box>
                        </Grid>
                      )}
                      {!disableReturn && (
                        <Grid item xs={6} sm={TRAIN ? 3 : 6}>
                          <Box
                            position={'relative'}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              borderLeft: '1px solid #e6e6e6',
                              height: '100%',
                            }}
                          >
                            <Box
                              p={1}
                              pt={0}
                              minWidth={'120px'}
                              display={'flex'}
                            >
                              <ReusableDatpicker
                                textAlign={'left'}
                                pr={'10px'}
                                disableLabel
                                value={arrival}
                                onChange={setArrival}
                                isOneWay={isOneWay}
                                HOTEL={HOTEL}
                                from={departure}
                                to={arrival}
                                btnRef={btnRef}
                                hotelDatePicker={hotelDatePicker.to}
                                setHotelDatePicker={setHotelDatePicker}
                                setOpenRooms={setOpenRooms}
                              />
                            </Box>
                            <Box className={classes.dateDelim} />
                          </Box>
                        </Grid>
                      )}

                      {TRAIN && (
                        <Grid item xs={6} sm={3}>
                          <Box
                            position={'relative'}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              borderLeft: '1px solid #e6e6e6',
                              height: '100%',
                            }}
                          >
                            <Box
                              p={1}
                              pt={0}
                              minWidth={'120px'}
                              display={'flex'}
                            >
                              <ReusableDatpicker
                                textAlign={'left'}
                                disableLabel
                                value={arrival}
                                onChange={setArrival}
                                from={departure}
                                to={arrival}
                                isOutgoing
                                back
                                HOTEL={HOTEL}
                                btnRef={btnRef}
                                timeOnly
                              />
                            </Box>
                            <Box className={classes.dateDelim} />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {counts ? (
                    <Grid
                      className={classes.countsBlock}
                      item
                      sm={!!(!TRAIN && isIframe)}
                    >
                      <Box
                        borderLeft={1}
                        display={'flex'}
                        height="100%"
                        borderColor="grey.300"
                      >
                        <SelectCounts
                          isHotel={!!HOTEL}
                          isOpen={openRooms}
                          setIsOpen={setOpenRooms}
                          counts={counts}
                          modifyCounts={modifyCounts}
                          setTicketClass={setTicketClass}
                          ticketClass={ticketClass}
                          ticketClasses={ticketClasses}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    ''
                  )}

                  <Grid
                    item
                    xs
                    className={
                      TRAIN && !isIframe
                        ? classes.searchBtnTrain
                        : classes.searchButtonIframe
                    }
                  >
                    <Button
                      ref={btnRef}
                      disableElevation
                      onClick={onSubmit}
                      variant="contained"
                      color="primary"
                      className={classes.searchBtn}
                      endIcon={
                        document.dir === 'rtl' ? null : (
                          <Arrows className={classes.arrows} />
                        )
                      }
                      startIcon={
                        document.dir === 'rtl' ? (
                          <Arrows className={classes.arrows} />
                        ) : null
                      }
                    >
                      {t('search go')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
    </Container>
  )
}

export default memo(GuestExpandedSearch)
