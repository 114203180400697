import React, { useEffect, useRef, useState } from 'react'
import { TextField, Box, useMediaQuery } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import { Typography } from '@material-ui/core'

import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SelectDestinationModal from '../../containers/modals/forms/selectDestinationModal'
import { useTranslation } from 'react-i18next'
import Popper from '@material-ui/core/Popper/Popper'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: 0,
    border: 0,
    height: '100%',
  },
  input: {
    height: '100%',
    width: '100%',
    borderRadius: '0',
    border: 0,
    padding: '0',
    margin: '0',
    fontWeight: theme.typography.fontWeightMedium,
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.common.black
    },
  },
  align: {
    display: 'flex',
    height: '100%',
    position: 'relative',
  },

  inputError: {
    height: '100%',
    width: '100%',
    borderRadius: '0',
    border: 0,
    padding: '0',
    margin: '0',
    fontWeight: theme.typography.fontWeightMedium,
    '& .MuiInputBase-input::placeholder': {
      color: '#d0021b',
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: theme.spacing(2.4),
      opacity: 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2)
      }
    },
  },
  innerInput: ({inputStyles = {}}) => ({
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': { ...inputStyles },
  }),
  eventIcon: {
    color: theme.palette.iframeSecondary.main
  }
}))

const CssTextField = withStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightMedium,
    height: '100%',
    '& .MuiInput-underline:after': {
      border: 0,
    },
    '& .MuiOutlinedInput-root': {
      fontWeight: 600,
      height: '100% !important',

      padding: '0 !important',
      '& fieldset': {
        borderRadius: 0,
        border: 0,
      },
    },
  },
}))(TextField)

const MediumTextField = withStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightMedium,
    height: '100%',
    '& .MuiInput-underline:after': {
      border: 0,
    },
    '& .MuiOutlinedInput-root': {
      fontWeight: 500,
      height: '100% !important',

      padding: '0 !important',
      '& fieldset': {
        borderRadius: 0,
        border: 0,
      },
    },
  },
}))(TextField)

const AutoCompleteInput = ({
  value,
  onChange,
  array,
  fetch,
  optionLabel,
  icon,
  destinationModal = false,
  isPopoverOpenFrom,
  focus,
  fontWeight,
  inputStyles = {}
}) => {
  const classes = useStyles({inputStyles})
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isDisabled, setIsDisabled] = useState(false)
  const inputRef = useRef()
  const { isIframe } = useSelector((state) => state.customization)

  // Todo: refactor
  const handleOnFocus = () => {
    fetch('')
  }
  const [openModal, setOpenModal] = useState(false)
  const onFinishModal = (itm) => {
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    })
    setOpenModal(false)
    onChange(null, itm)
  }
  const openModalIfNeeded = () => {
    if (destinationModal) setOpenModal(true)
  }

  useEffect(() => {
    if (focus) {
      console.log('focus')
      inputRef.current.focus()
    }
  }, [focus])

  const renderAutocomplete = () => {
    return (
      <Box
        className={classes.align}
        style={{ height: '100%' }}
        alignItems="center"
        onClick={openModalIfNeeded}
      >
        {icon ? <LocationCityIcon color="primary" style={{ marginRight: '2px' }} className={isIframe && classes.eventIcon}/> : ''}
        <Autocomplete
          openOnFocus
          noOptionsText={''}
          blurOnSelect
          popupIcon={null}
          disabled={isDisabled}
          disableClearable
          fullWidth
          options={array || []}
          getOptionLabel={optionLabel || (() => (''))}
          value={value || ""}
          onChange={
            (e, val) => {
              onChange(e, val)
            }
          }
          style={{ height: '100%' }}
          renderOption={renderOption}
          renderInput={(params) => (
            fontWeight
              ? (
                <MediumTextField
                  inputRef={inputRef}
                  placeholder={t('hotel search travel where')}
                  style={{ height: '100%' }}
                  {...params}
                  variant="outlined"
                  className={
                    `${isPopoverOpenFrom && isMobile ? classes.inputError : classes.input} ${classes.innerInput}`
                  }
                  fullWidth
                  margin="normal"
                  onChange={(e) => fetch(e.target.value)}
                  onFocus={(e) => handleOnFocus(e.target.value)}
                />
              )
              : (
                <CssTextField
                  inputRef={inputRef}
                  placeholder={t('hotel search travel where')}
                  style={{ height: '100%' }}
                  {...params}
                  variant="outlined"
                  className={
                    `${isPopoverOpenFrom && isMobile ? classes.inputError : classes.input} ${classes.innerInput}`
                  }
                  fullWidth
                  margin="normal"
                  onChange={(e) => fetch(e.target.value)}
                  onFocus={(e) => handleOnFocus(e.target.value)}
                />
              )
          )}
          PopperComponent={(props) => (
            <Popper
              {...props}
              placement={isMobile ? 'bottom' : 'bottom-start'}
              style={{ width: isMobile ? 'calc(100% - 48px)' : '370px', height: '50px' }}
              modifiers={isMobile && {
                offset: {
                  enabled: true,
                  offset: '-12,10',
                },
              }}
            />
          )}
        />
      </Box>
    );
  }

  const renderOption = (option, { inputValue }) => {
    const matches = match(option.Name, inputValue)
    const parts = parse(option.Name, matches)

    return (
      <>
        <Box mr={1}>
          <LocationOnIcon color="disabled" fontSize="large" />
        </Box>
        <Typography>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </Typography>
      </>
    )
  }

  return (
    <Box
      style={{ height: '100%' }}
      alignItems="center"
    >
      {renderAutocomplete()}

      {destinationModal &&
        <SelectDestinationModal
          value={value}
          options={array || []}
          modalBoolean={openModal}
          handleClose={() => setOpenModal(false)}
          handleFinish={onFinishModal}
          fetchMethod={fetch}
          getDestText={optionLabel}
          placeholder={t('hotel search travel where')}
          header={t('destination')}
          setIsDisabled={setIsDisabled}
        />
      }
    </Box>
  )
}

export default AutoCompleteInput
