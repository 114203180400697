import React, { memo, useEffect, useRef } from 'react'
import { Box, Typography, TextField, Button, useTheme } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import EventIcon from '@material-ui/icons/Event'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'react-moment'

import {
  fetchTransferSearchLocationAction,
  clearSearchLocation,
  setTaxiError,
} from '../../../store/transfer/transferAction'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiAutocomplete-fullWidth .MuiTextField-root .MuiFormHelperText-contained.Mui-error':
      {
        position: 'absolute',
        bottom: '-19px',
      },
  },
  timeLineContent: {
    marginTop: '-5px',
    marginLeft: 0,
  },
  timeLineContentInput: {
    marginTop: '-20px',
    marginLeft: 0,
  },
  limelineItem: {
    flex: 0,
    '&:before': {
      flex: 0,
      padding: 0,
    },
  },
  content: {
    padding: '6px 16px',
  },
  align: {
    justifyContent: 'center',
    display: 'flex',
  },
  between: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  findTransferBtn: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(4),
    letterSpacing: 1.1,
    fontSize: '18px',
    width: '235px',
  },
  tail: {
    backgroundColor: theme.palette.primary.main,
  },
}))

const DropOffAdressStop = ({
  handleNext,
  setCurrentStreet,
  current,
  street,
}) => {
  const { t } = useTranslation()

  const currentLocationCode = current?.meta?.locationCode

  const delayedQuery = useRef(
    _.debounce((value) => {
      return dispatch(
        fetchTransferSearchLocationAction(value, currentLocationCode)
      )
    }, 700)
  ).current
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { locations = [], isLocationLoading } = useSelector(
    (state) => state.transfer
  )
  const error = useSelector((state) => state.transfer.allError)

  useEffect(() => {
    return () => {
      // Removes locations un unmount
      dispatch(clearSearchLocation())
    }
  }, [dispatch])

  const onInputChange = (_event, value) => {
    if (value?.length >= 3) {
      delayedQuery(value)
    }
  }

  const onNextSearch = () => {
    if (!street?.number && street?.numberRequired) {
      dispatch(setTaxiError(t('cant find address')))
      return false
    }
    handleNext()
  }

  const isFrom = !!current?.meta?.from

  const location = (
    <Typography>{_.startCase(current?.meta?.locationName)}</Typography>
  )

  const autoComplete = (
    <Autocomplete
      popupIcon={null}
      noOptionsText={t('no matching addresses found')}
      fullWidth
      filterOptions={(x) => x}
      options={locations}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) =>
        option
          ? `${option?.name} ${option.number ? option.number : ''}${
              option.letter ? `${option.letter}` : ''
            } ${option.location.name}`
          : ''
      }
      onInputChange={onInputChange}
      onChange={(_event, newValue) => {
        setCurrentStreet(newValue)
      }}
      renderInput={(params) => (
        <TextField
          ref={params.InputProps.ref}
          fullWidth
          autoFocus
          margin="none"
          {...params}
          placeholder={t('where to')}
          variant="outlined"
          error={error}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLocationLoading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            spellCheck: 'false',
            autoCorrect: 'off',
          }}
        />
      )}
    />
  )

  const autoCompleteFromWhere = (
    <Autocomplete
      popupIcon={null}
      noOptionsText={t('no matching addresses found')}
      fullWidth
      filterOptions={(x) => x}
      options={locations}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) =>
        option
          ? `${option?.name} ${option.number ? option.number : ''}${
              option.letter ? `${option.letter}` : ''
            } ${option.location.name}`
          : ''
      }
      onInputChange={onInputChange}
      onChange={(_event, newValue) => {
        setCurrentStreet(newValue)
      }}
      renderInput={(params) => (
        <TextField
          ref={params.InputProps.ref}
          fullWidth
          autoFocus
          margin="none"
          {...params}
          placeholder={t('from where')}
          variant="outlined"
          error={error}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLocationLoading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            spellCheck: 'false',
            autoCorrect: 'off',
          }}
        />
      )}
    />
  )

  return (
    <Box display="flex" flexDirection="column">
      {isMobile || (
        <Box mb={2} mt={4}>
          <Typography
            gutterBottom
            align="center"
            variant="h4"
            className={classes.weight}
          >
            {isFrom ? t('your drop-off address') : t('your pick-up address')}
          </Typography>
        </Box>
      )}
      <Box ml={0} mr={0} className={classes.root}>
        <Timeline>
          <TimelineItem className={classes.limelineItem}>
            <TimelineSeparator>
              <EventIcon color="primary" />
            </TimelineSeparator>
            <TimelineContent className={classes.timeLineContent}>
              <Typography>
                <Moment format="ddd D MMM">{current?.meta?.time}</Moment>
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem className={classes.limelineItem}>
            <TimelineSeparator>
              <RadioButtonUncheckedIcon color="primary" />
              <TimelineConnector className={classes.tail} />
            </TimelineSeparator>
            <TimelineContent
              className={
                isFrom ? classes.timeLineContent : classes.timeLineContentInput
              }
            >
              {isFrom ? location : autoCompleteFromWhere}
            </TimelineContent>
          </TimelineItem>
          <TimelineItem className={classes.limelineItem}>
            <TimelineSeparator>
              <LocationOnIcon color="primary" />
            </TimelineSeparator>
            <TimelineContent
              className={
                isFrom ? classes.timeLineContentInput : classes.timeLineContent
              }
            >
              {isFrom ? autoComplete : location}
            </TimelineContent>
          </TimelineItem>
        </Timeline>

        <Box className={classes.align} pt={isMobile ? 0 : 5}>
          <Button
            disableElevation
            disabled={!street}
            onClick={onNextSearch}
            variant="contained"
            color="primary"
            className={classes.findTransferBtn}
          >
            {t('find transfer')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(DropOffAdressStop)
