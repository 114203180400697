import React, { memo } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography, Divider, Button } from '@material-ui/core'
import { openSeatReservationAction } from '../../../store/modals/modalAction'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  modalBtn: {
    textTransform: 'none',
    fontSize: theme.spacing(2),
  },
  textCenter: {
    textAlign: 'center',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const SeatReservation = ({ isMultiTicket }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { seatsLoading, seats } = useSelector((state) => state.flightDetails)
  const available = seats && _.find(seats, { isFreeExists: true })
  const openModal = () => dispatch(openSeatReservationAction())

  return (
    <Box
      pt={isMultiTicket ? 2 : 4}
      paddingX={isMobile ? 2 : 4}
      mt={!!isMultiTicket ? 2 : 0}
      pb={4}
    >
      <Box pb={3} pt={2}>
        <Typography variant="h5" className={classes.weight}>
          {t('detail reservation')}
        </Typography>
      </Box>

      {seatsLoading ? (
        <Box className={classes.textCenter} py={3}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Divider />
          <Box
            pt={3}
            pb={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            onClick={available && openModal}
          >
            <Typography>
              {' '}
              {!available ? t('unavailable') : t('detail add place')}{' '}
            </Typography>
            {!!available && (
              <Button
                className={classes.modalBtn}
                onClick={openModal}
                endIcon={
                  <ArrowForwardIosIcon color="primary" fontSize="small" />
                }
              >
                {t('select')}
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default memo(SeatReservation)
