import React, {Fragment, useEffect, useMemo, useState} from 'react'
import {Box, Select} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TicketLabel from "../../flights/details/ticketLabel"
import _ from "lodash"

const useStyles = makeStyles((theme) => ({
  fareItem: {
    padding: '18px 0',
    borderTop: `solid 1px ${theme.palette.border}`,

    '& .font-weight-bold': {
      fontWeight: theme.typography.fontWeightBold
    },

    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.border}`
    },

    '& .fare-header-block': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',

      '& .radio-block': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& .radio-btn': {
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '24px',
          width: '24px',
          border: '2px solid #08c5b9',
          borderRadius: '50px',
          marginRight: '10px',

          '& .radio-btn-dot': {
            backgroundColor: '#08c5b9',
            height: '12px',
            width: '12px',
            borderRadius: '24px',
          },

          '&.selected': {
            borderColor: '#08c5b9',
            '.radio-btn-dot': {
              backgroundColor: '#08c5b9',
            }
          }
        }
      },
      '& .text-block': {
        display: 'inline-flex',
        alignItems: 'center',
        flex: '1',

        '& .fare-family-name': {
          flex: '1'
        }
      }
    },

    '& .mix-select-block': {
      marginTop: '25px',

      '& .mix-select-header': {
        marginBottom: '12px',
      },

      '& .mix-select': {
        height: '54px',
        width: '100%',
        borderRadius: '4px',
        border: 'solid 1px #4f4f4f !important',
        backgroundColor: 'white',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: 'black',
        padding: '4px',
        paddingLeft: '14px',
        backgroundPosition: 'calc(100% - 14px)'
      }
    },

    '& .fare-family-header': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#4f4f4f',
      marginBottom: '12px'
    },

    '& .services-block': {
      padding: '25px 30px',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      marginTop: '15px'
    }
  }
}))

const FareList = ({ fares, selected, select, stdPrice }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [mixChanged, setMixChanged] =  useState(false)
  const [mixOutbound, setMixOutbound] =  useState(null)
  const [mixReturn, setMixReturn] =  useState(null)
  const [tmpSelected, setTmpSelected] =  useState(null)

  const faresExists = !!fares?.length;
  const faresList = fares || [];
  const selectedId = useMemo(() => {
    return selected?.combineKey || null;
  }, [selected])

  const isMixedSelected = useMemo(() => {
    return !!selected?.isMixFly
  }, [selected])

  useEffect(() => {
    if (isMixedSelected) {
      setTmpSelected('mix');
      setMixReturn(selectedId);
      setMixOutbound(selected.oFFName);
    } else {
      setTmpSelected(null);
      setMixReturn(null);
      setMixOutbound(null);
    }
    setMixChanged(false)
  }, [selectedId, isMixedSelected]);

  const selectFare = (fare) => {
    select(fare)
  }

  const isSelected = (fare) => {
    return !tmpSelected && (
        (fare.uniqueId && fare.combineKey === selectedId) ||
        (!selectedId && !!fare.isStandard)
    )
  }

  const getFareName = (fare) => {
    if (!!fare.isStandard) return fare.text
    const ffLabels = []
    const uniqueFFs = []
    fare.ff.forEach(ff => {
      if (uniqueFFs.includes(ff.ffName)) return false
      uniqueFFs.push(ff.ffName)
      ffLabels.push(ff.label)
    })
    return ffLabels.join(' + ')
  }

  const getUniqueFF = (fare) => {
    const ffs = []
    const uniqueFFNames = []
    fare.ff.forEach(ff => {
      if (uniqueFFNames.includes(ff.ffName)) return false
      uniqueFFNames.push(ff.ffName)
      ffs.push(ff)
    })
    return ffs.map(ff => ({...ff, showHeader: ffs.length > 1}))
  }

  const computedFFList = useMemo(() => {
    const res = {
      general: [],
      mix: []
    }
    if (!selectedId) {
      res.general.push({
        isStandard: true,
        text: t('detail standart'),
        price: stdPrice
      })
    }
    faresList.forEach(f => {
      if (!!f.isMixFly) {
        res.mix.push({...f});
        return true;
      }
      res.general.push({...f});
    })

    return res
  }, [faresList, selectedId])

  const mixedOutboundOpts = useMemo(() => {
    const res = []
    computedFFList.mix.forEach(f => {
      if (!f?.oFFName) return false;
      const found = res.find(r => r.value === f.oFFName)
      if (!!found) return false
      res.push({value: f.oFFName, text: f.oFFName})
    })

    if (isMixedSelected && !mixChanged) {
      const found = res.find(r => !!r.value && r.value === selected.oFFName)

      if (!found) {
        res.push({value: selected.oFFName, text: selected.oFFName || 'Unknown'})
      }
    }
    return res
  }, [computedFFList, mixChanged, selected, isMixedSelected, t])


  const mixedReturnOpts = useMemo(() => {
    const res = []

    if (!!mixOutbound) {
      const tmpOpts = computedFFList.mix.filter(f => f.oFFName === mixOutbound)
      tmpOpts.forEach(f => {
        if (!f?.combineKey) return false;
        const found = res.find(r => r.value === f.combineKey)
        if (!!found) return false
        res.push({value: f.combineKey, text: f.rFFName})
      })
    }

    if (isMixedSelected && !mixChanged) {
      const found = res.find(r => !!r.value && r.value === selected.combineKey)
      if (!found) {
        res.push({value: selected.combineKey, text: selected.rFFName || 'Unknown'})
      }
    }

    return res
  }, [mixChanged, mixOutbound, t, computedFFList, isMixedSelected, selected])

  const showMixedFares = useMemo(() => {
    return tmpSelected === 'mix' && !mixChanged && isMixedSelected
  }, [tmpSelected, mixChanged, isMixedSelected])

  const selectFareById = (val) => {
    const found = faresList.find(f => f.combineKey === val)
    if (!!found && val !== selectedId) {
      selectFare({...found})
    }
  }

  const setMixVal = (value, type = 'outbound') => {
    setMixChanged(true)
    if (type === 'outbound') {
      setMixOutbound(value);
      setMixReturn(null);
    }

    if (type === 'return') {
      setMixReturn(null)
      selectFareById(value)
    }
  }

  const getServBlock = (fare, showNames = false) => {
    return (
      <Box key="fares-services" className="fare-services">
        {(getUniqueFF(fare)).map((ff, ffInd) => {
          if (!ff) return null
          return (
            <Box key={`services-block-${ffInd}`} className="services-block">
              {!!showNames && (
                <Box key={`fare-family-header`} className={'fare-family-header'}>
                  {ff.ffName}
                </Box>
              )}
              <TicketLabel labels={ff}/>
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <Box key="fares-list" className="fares-list">
      <Fragment>
        {
          computedFFList.general.map((fare, i) => (
            <Box
                key={`fare-item-${i}`}
                className={`${classes.fareItem} ${isSelected(fare) ? 'selected' : ''}`}
            >
              <Box key="fare-header-block" onClick={() => selectFare(fare)} className="fare-header-block">
                <Box key="radio-block" className="radio-block">
                  <Box key="radio-btn" className="radio-btn">
                    {isSelected(fare) && (
                        <Box key="radio-dot" className="radio-btn-dot"/>
                    )}
                  </Box>
                </Box>
                <Box key="text-block" className="text-block">
                  <Box key="fare-family-name" className="fare-family-name">
                    {getFareName(fare)}
                  </Box>
                  <Box key="fare-price" className="fare-price">
                    SEK <span className="font-weight-bold">{fare.price}</span> / {_.capitalize(t('one travel'))}
                  </Box>
                </Box>
              </Box>

              {(isSelected(fare) && !fare.isStandard) && getServBlock(fare)}
            </Box>
          ))
        }
        {!!computedFFList.mix.length && (
          <Box
              key={`fare-item-mixed`}
              className={`${classes.fareItem} ${tmpSelected === 'mix' ? 'selected' : ''}`}
          >
            <Box key="fare-header-block" onClick={() => setTmpSelected('mix')} className="fare-header-block">
              <Box key="radio-block" className="radio-block">
                <Box key="radio-btn" className="radio-btn">
                  {tmpSelected === 'mix' && (
                      <Box key="radio-dot" className="radio-btn-dot"/>
                  )}
                </Box>
              </Box>
              <Box key="text-block" className="text-block">
                <Box key="fare-family-name" className="fare-family-name">
                  {t('mixed classes')}
                </Box>
                {!!showMixedFares && (
                    <Box key="fare-price" className="fare-price">
                      SEK <span className="font-weight-bold">{selected.price}</span> / {t('traveller')}
                    </Box>
                )}
              </Box>
            </Box>

            {tmpSelected === 'mix' && (
              <Fragment>
                <Box>
                  <Box key="outbound-mix-select-block" className="mix-select-block">
                    <div key="mix-select-header" className="mix-select-header">{t('outbound')}</div>
                    <Select
                        native
                        value={mixOutbound}
                        variant="outlined"
                        className={'mix-select'}
                        InputLabelProps={{shrink: false}}
                        onChange={(e) => setMixVal(e.target.value)}
                    >
                      <option value={null}>{t('select')}...</option>
                      {mixedOutboundOpts.map(o => (
                          <option key={`o-opt-${o.value}`} value={o.value}>{o.text}</option>
                      ))}
                    </Select>
                  </Box>

                  {!!mixOutbound && (
                      <Box key="return-mix-select-block" className="mix-select-block">
                        <div key="mix-select-header" className="mix-select-header">{t('inbound')}</div>
                        <Select
                            native
                            value={mixReturn}
                            variant="outlined"
                            className={'mix-select'}
                            InputLabelProps={{shrink: false}}
                            onChange={(e) => setMixVal(e.target.value, 'return')}
                        >
                          <option value={null}>{t('select')}...</option>
                          {mixedReturnOpts.map(o => (
                              <option key={`r-opt-${o.value}`} value={o.value}>{o.text}</option>
                          ))}
                        </Select>
                      </Box>
                  )}
                </Box>
                {!!showMixedFares && getServBlock(selected, true)}
              </Fragment>
            )}
          </Box>
        )}
      </Fragment>
    </Box>
  )
}

export default FareList
