import React, { Suspense, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TrainSeatsMap from '../seatsMap/TrainSeatsMap'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import PassengerSelectSeats from './passengerSelectSeats'
import {
  selectSpecificAccomm,
  setTrainSeatAction,
  unselectSpecificAccomm,
} from '../../../store/train/trainAction'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  formMobile: {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  formDesktop: {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  },

  mainContainer: {
    width: '100%',
  },
  paper: ({ seatMapAvailable, isMobile }) => ({
    backgroundColor: !seatMapAvailable ? theme.palette.primary.light : 'white',
    padding: theme.spacing(2, isMobile ? 0 : 2),
    paddingTop: theme.spacing(!seatMapAvailable ? 2 : 1),
  }),
}))

const AccordiumDetails = ({ isShown, option, segment, index }) => {
  const { t } = useTranslation()
  const { railidentifier } = segment
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { travellers: passengers } = useSelector((state) => state.search.train)
  const {
    specificAccommodationSelected,
    seatsMap,
    seats: selectedSeats,
  } = useSelector((state) => state.train)
  const currSeatMap = seatsMap?.[railidentifier] || null
  const value = specificAccommodationSelected.includes(railidentifier)
    ? 'show'
    : 'hide'
  const seatMapAvailable = currSeatMap?.coachList?.length > 0
  const classes = useStyles({ seatMapAvailable, isMobile })
  const operator = segment.railserviceProvider.Code
  const handleChange = (event) => {
    if (!railidentifier) return

    if (event.target.value === 'hide') {
      dispatch(unselectSpecificAccomm(railidentifier))
    } else {
      dispatch(selectSpecificAccomm(railidentifier))
    }
  }
  const railSelectedSeats = useMemo(
    () => selectedSeats?.[railidentifier],
    [railidentifier, selectedSeats]
  )

  const isAffectedByNDS = moment(segment.railend.dateTime).isAfter(
    moment('2024-06-04T00:00:00')
  )

  useEffect(() => {
    const tmpSeatMap = seatsMap?.[railidentifier] || null

    if (operator !== 'ORS' && tmpSeatMap?.availableSeats && passengers) {
      const placedSeats = {}
      const shouldSetSeat = []

      passengers.forEach((p) => {
        const uSelSeat = railSelectedSeats?.[p.uniqueId]
        const selSeatKey = uSelSeat?.exactSeat
          ? uSelSeat.exactSeat.coach.toString() +
            '-' +
            uSelSeat.exactSeat.seatNum.toString()
          : null

        if (!tmpSeatMap.availableSeats?.[selSeatKey]) {
          shouldSetSeat.push(p.uniqueId)
        } else {
          placedSeats[selSeatKey] = p.uniqueId
        }
      })
      for (let skey in tmpSeatMap.availableSeats) {
        if (placedSeats[skey]) continue
        const uIdToPlace = shouldSetSeat.shift()
        if (!uIdToPlace) continue
        dispatch(
          setTrainSeatAction({
            accom: { exactSeat: { ...tmpSeatMap.availableSeats[skey] } },
            railindentifier: railidentifier,
            userId: uIdToPlace,
          })
        )
      }

      if (shouldSetSeat?.length) {
        shouldSetSeat.forEach((userId) => {
          dispatch(
            setTrainSeatAction({
              accom: false,
              railindentifier: railidentifier,
              userId: userId,
            })
          )
        })
      }
    }
  }, [dispatch, seatsMap, passengers, railidentifier, railSelectedSeats])

  if (value === 'hide' && (currSeatMap?.coachList?.length > 0 || option)) {
    dispatch(selectSpecificAccomm(railidentifier))
  }

  const renderPassengers = () =>
    passengers.map((passenger, i) => {
      const pax = i + 1
      const key = `PAX_${pax}`
      const opts = option ? option.opts[key] : ''

      return (
        <Grid xs={12} container item key={i}>
          <PassengerSelectSeats
            key={i}
            passenger={passenger}
            opts={opts}
            identifier={railidentifier}
          />
        </Grid>
      )
    })

  const renderSeatMap = () => {
    if (!seatMapAvailable) return null
    return (
      <Suspense maxDuration={300} fallback={<div>Wait...</div>}>
        <TrainSeatsMap
          seatsMap={currSeatMap}
          railidentifier={railidentifier}
          index={index}
        />
      </Suspense>
    )
  }

  // NDS - disable both seatmap and specific seat request
  if (isAffectedByNDS) {
    return (
      <Box className={classes.mainContainer}>
        <Box style={{ textAlign: 'center', padding: '20px' }}>
          {t('seat map disabled notice')}
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.mainContainer}>
      <Paper className={classes.paper} elevation={0}>
        {!seatMapAvailable && (
          <>
            <Box>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    className={
                      isMobile ? classes.formMobile : classes.formDesktop
                    }
                    value={'hide'}
                    control={<Radio color="primary" />}
                    label={t('best available seat')}
                  />
                  <FormControlLabel
                    className={
                      isMobile ? classes.formMobile : classes.formDesktop
                    }
                    value={'show'}
                    control={<Radio color="primary" />}
                    label={t('specific seat request')}
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            {value === 'show' ? (
              <Box mt={4}>
                <Grid container direction="column">
                  {renderPassengers()}
                </Grid>
              </Box>
            ) : (
              ''
            )}
          </>
        )}

        {seatMapAvailable && isShown && (
          <Box mx={isMobile ? -2 : 0}>
            <Grid container direction="column">
              {renderSeatMap()}
            </Grid>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default AccordiumDetails
