import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import SeatReservationModal from './seatReservationModal'
import BookingLinkInfoModal from './bookingLinkInfoModal'
import TransferModal from './transferModal'
import CancelBookingModal from './cancelBookingModal'
import HotelImageModal from './hoteImageModal'
import LogOutModal from './logoutModal'
import LoginModal from './loginModal'
import EditInfoModal from './editInfoModal'
import CancelTripPartConfirmModal from './cancelTripPartConfirmModal'
import SelectAuthTypeModal from './selectAuthTypeModal'

import EmployeesUserModal from './employeesUserModal'
import DeactivateUserModal from './deactivateUserModal'
import ActivateUserModal from './activateUserModal'

import PolicyExceededModal from './policyExceededModal'

import RegistrationModal from './registrationModal'
import SelectAgentAuthUserModal from './selectAgentAuthUserModal'
import SimpleInfoModal from './simpleInfoModal'
import LoginWithCompanyModal from './loginWithCompanyModal'
import CheckoutItemsDrawer from './checkoutItemsDrawer'

import AddMobileModal from './addMobileModal'

const ModalHandler = () => {
  const {
    flightSeatReservation,
    transferModal,
    cancelBooking,
    hotelImageModal,
    logoutModal,
    loginModal,
    editInfoModal,
    employeesUserModal,
    deactivateUserModal,
    activateUserModal,
    policyExceededModal,
    registrationModal,
    bookingPartCancel,
    bookingLinkInfoModal,
    selectAgentAuthUserModal,
    simpleInfoData,
    loginWithCompanyModal,
    addMobileModal,
    checkoutItemsSideDrawer,
  } = useSelector((state) => state.modal)
  const { user, isAuthenticated, authType, isLoading } = useSelector(
    (state) => state.auth
  )
  const tmpAuthTypes = user?.authTypes?.length > 0 ? user.authTypes : []
  const isAgentAuthUserModal =
    selectAgentAuthUserModal && authType === 'agent' && isAuthenticated

  const isSelectAuthType =
    !isLoading &&
    isAuthenticated &&
    tmpAuthTypes.length > 0 &&
    !tmpAuthTypes.includes(authType)

  return (
    <>
      <SeatReservationModal modalBoolean={flightSeatReservation} />
      <TransferModal modalBoolean={transferModal} />
      <CancelBookingModal modalData={cancelBooking} />
      <HotelImageModal modalBoolean={hotelImageModal} />
      <LogOutModal modalBoolean={logoutModal} />
      <LoginModal modalBoolean={loginModal} />
      <EditInfoModal
        modalBoolean={editInfoModal.state}
        rows={editInfoModal.rows}
      />
      <EmployeesUserModal
        modalBoolean={employeesUserModal.state}
        user={employeesUserModal.user}
      />
      <DeactivateUserModal
        modalBoolean={deactivateUserModal.state}
        user={deactivateUserModal.user}
      />
      <ActivateUserModal modalBoolean={activateUserModal} />
      <PolicyExceededModal
        modalBoolean={policyExceededModal.state}
        clickHandler={policyExceededModal.clickHandler}
        allowReasons={policyExceededModal.allowReasons}
      />
      <RegistrationModal modalBoolean={registrationModal} />
      <CancelTripPartConfirmModal cancelData={bookingPartCancel} />
      <BookingLinkInfoModal modalBoolean={bookingLinkInfoModal} />
      <SelectAuthTypeModal modalBoolean={isSelectAuthType} />
      <SelectAgentAuthUserModal modalBoolean={isAgentAuthUserModal} />
      <SimpleInfoModal modalData={simpleInfoData} />
      <LoginWithCompanyModal
        modalBoolean={loginWithCompanyModal.state}
        agency={loginWithCompanyModal.agency}
        companies={loginWithCompanyModal.companies}
        email={loginWithCompanyModal.email}
        password={loginWithCompanyModal.password}
      />
      <AddMobileModal modalBoolean={addMobileModal} />
      <CheckoutItemsDrawer modalBoolean={checkoutItemsSideDrawer} />
    </>
  )
}

export default memo(ModalHandler)
