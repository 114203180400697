import React, { Fragment, useRef, useCallback, useState, useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'
import {
  Box,
  Typography,
  useTheme,
  Divider,
  Select,
  MenuItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import AutoCompleteInput from "../../../components/reusable/autoCompleteInput";
import { fetchApprovers, fetchUserGroups } from "../../../repositories/users";
import { useTranslation } from "react-i18next";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { ExpandMore } from '@material-ui/icons'

const fieldBorderStyles = {
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  border: '1px solid',
}

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '32px 32px 72px 32px'
  },
  approverInput: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      cursor: 'pointer',
      '& .MuiAutocomplete-input': {
        cursor: 'pointer',
        borderRadius: '6px',
        height: '19px',
        fontWeight: 'normal',
        padding: '17.5px 14px',
      },
      borderRadius: '6px',
      border: `1px solid ${theme.palette.border}`,
      '&.Mui-focused': {
        borderColor: `${theme.palette.primary.main}`
      }
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    }
  },

  borderRadius: {
    marginTop: 0,
    '&:active': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
    '&:before, & .MuiInputBase-root:before': { display: 'none' },
    '& fieldset': { ...fieldBorderStyles },
    '& .MuiInputBase-input.Mui-disabled': {
      padding: '18.5px 14px',
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      color: '#333',
      borderRadius: '6px',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderColor: '#e6e6e6',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {},
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '&.Mui-disabled .MuiSelect-icon': {
      color: '#333',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
      marginRight: '4px'
    },
  },
  errorText: {
    color: `${theme.palette.secondary.main}`,
    margin: '3px 14px 0 14px',
    fontSize: '12px'
  },
  arrowDownIcon: {
    color: `${theme.palette.primary.main}`
  },
  divider: {
    marginBottom: '16px'
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  inputBox: {
    '&.error $approverInput .MuiAutocomplete-inputRoot, error $approverInput .MuiAutocomplete-inputRoot.Mui-focused': {
      borderColor: `${theme.palette.secondary.main}`
    }
  },
  medium: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '14px',
    marginBottom: theme.spacing(1),
    marginTop: '16px',
  },
}))

/**
 * component for choosing user who will approve booking
 *
 * @returns {JSX.Element}
 */
const chooseApprover = ({ }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const [approvers, setApprovers] = useState([])
  const [groups, setGroups] = useState([])
  const [groupsReady, setGroupsReady] = useState([])
  const [unspecifiedGroupExists, setUnspecifiedGroupExists] = useState(false)
  const { t } = useTranslation()
  const formik = useFormikContext()

  const [group, setGroup] = useState("");
  const approver = formik.values?.approver;
  const touched = formik.touched?.approver;
  const error = formik.errors?.approver;
  const inpRef = useRef()

  const optionLabel = (option) => {
    return `${option['firstName']} ${option['lastName']} (${option.email})`
  }

  const renderOption = (option, { inputValue }) => {
    const matches = match(
      `${option['firstName']} ${option['lastName']} (${option.email})`,
      inputValue
    )
    const parts = parse(
      `${option['firstName']} ${option['lastName']} (${option.email})`,
      matches
    )

    return (
      <Fragment>
        <Typography>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </Typography>
      </Fragment>
    )
  }

  useEffect(() => {
    const getGroups = async () => {
      try {
        const result = await fetchUserGroups()
        setGroups(result.groups)
        setUnspecifiedGroupExists(result.unspecifiedGroupExists)
      } finally {
        setGroupsReady(true)
      }
    }
    getGroups()
  }, [])

  const getApprovers = useCallback(async (value) => {
    if (!groupsReady) return null
    try {
      const rqData = { name: value }
      if (groups.length > 0) {
        rqData.group = group
      }

      const result = await fetchApprovers(rqData, 'fetch-approvers')
      setApprovers(result)
    } catch (e) { }
  }, [group, groupsReady]);
  const customFilterFunc = (option) => {
    return `${option['firstName']} ${option['lastName']} (${option.email})`
  }

  const handleSetGroup = (evt) => {
    setGroup(evt.target.value)
    setApprovers([])
    formik.setFieldValue('approver', null)
  }

  return (
    <Box mx={isMobile ? 2 : 4} m={2} mt={4} mb={4}>
      <Box key="header" mb={2} mt={0}>
        <Typography variant="h5" className={classes.bold}>{t('approval')}</Typography>
      </Box>
      <Divider className={classes.divider} />

      <Typography key={'above-field-text'} className={classes.medium}>
        {t('who shell approve your booking')}*
      </Typography>

      {groups.length > 0 && (
        <Box key={'group-box'} className={classes.inputBox} mb={2}>
          <Select
            value={group}
            onChange={handleSetGroup}
            displayEmpty
            className={classes.borderRadius}
            InputLabelProps={{ shrink: false }}
            variant={'outlined'}
            placeholder={t('select')}
            fullWidth
            IconComponent={ExpandMore}
          >
            <MenuItem style={{ color: 'silver' }} value="">
              <span style={{ color: '#999999', fontWeight: '500' }}>
                {t('select approver group')}...
              </span>
            </MenuItem>
            {groups.map((group) => (
              <MenuItem value={group}>{group}</MenuItem>
            ))}
            {unspecifiedGroupExists && (
              <MenuItem value="unspecified-group">
                {t('other')}
              </MenuItem>
            )}
          </Select>
        </Box>
      )}

      {(group || (groupsReady && !groups.length)) && (
        <Box key={'input-box'} className={`${classes.inputBox} ${touched && error ? 'error' : ''}`}>
          <AutoCompleteInput
            customFilterFunc={customFilterFunc}
            renderOption={renderOption}
            fetch={getApprovers}
            value={approver}
            forwardedRef={inpRef}
            endAdornment={(
              <KeyboardArrowDownIcon key={'arrow-down'} className={classes.arrowDownIcon} />
            )}
            onChange={(_, value) => {
              formik.setFieldValue('approver', value)
            }}
            array={approvers}
            optionLabel={optionLabel}
            label={t('select approver')}
            placeholder={t('select approver')}
            fullWidth={true}
            inputClass={classes.approverInput}
          />
          {!!touched && !!error && (
            <Box key={'error-text'} className={classes.errorText}>
              {error}
            </Box>
          )}

        </Box>
      )}
    </Box>
  )
}

export default chooseApprover
