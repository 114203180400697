const ADD_ITEM = 'ADD_ITEM'
const SET_CC_FEES = 'SET_CC_FEES'
const REMOVE_ITEM = 'REMOVE_ITEM'
const REMOVE_ALL_CHECKOUT = 'REMOVE_ALL_CHECKOUT'
const SET_CC_DATA = 'SET_CC_DATA'
const SET_FOP = 'SET_FOP'
const SET_PUBLIC_CONTACTS = 'SET_PUBLIC_CONTACTS'
const SET_HOTEL_GUARANTEE = 'SET_HOTEL_GUARANTEE'
const SET_BOOKING_RESULT = 'SET_BOOKING_RESULT'
const HOTEL_GUARANTEE_LOADING = 'HOTEL_GUARANTEE_LOADING'
const COSTFIELDS_LOADING = 'COSTFIELDS_LOADING'
const SET_COSTFIELDS_VALUE = 'SET_COSTFIELDS_VALUE'
const CORRECT_HOTEL_PRICE = 'CORRECT_HOTEL_PRICE'
const SET_CHECKOUT_ITEMS = 'SET_CHECKOUT_ITEMS'
const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA'
const SET_NETS_COMPLETED = 'SET_NETS_COMPLETED'
const SET_ADDRESS_DETAILS = 'SET_ADDRESS_DETAILS'
const SET_PASSENGERS_DATA = 'SET_PASSENGERS_DATA'
const SET_PASSENGERS_DATA_LOADING = 'SET_PASSENGERS_DATA_LOADING'
const SET_CHECKOUT_CHECKSUM = 'SET_CHECKOUT_CHECKSUM'

const SET_ADDITIONAL_RECIPIENT_EMAIL = 'SET_ADDITIONAL_RECIPIENT_EMAIL'

const SET_POLICY_EXCEEDED_REASON = 'SET_POLICY_EXCEEDED_REASON'

const SET_DLK = 'SET_DLK'

export const SET_COSTFIELDS = 'SET_COSTFIELDS'

export default {
  SET_CC_FEES,
  CORRECT_HOTEL_PRICE,
  HOTEL_GUARANTEE_LOADING,
  SET_COSTFIELDS_VALUE,
  ADD_ITEM,
  REMOVE_ITEM,
  REMOVE_ALL_CHECKOUT,
  SET_COSTFIELDS,
  SET_PUBLIC_CONTACTS,
  SET_CC_DATA,
  SET_FOP,
  SET_HOTEL_GUARANTEE,
  SET_BOOKING_RESULT,
  SET_CHECKOUT_ITEMS,
  SET_PAYMENT_DATA,
  SET_NETS_COMPLETED,
  COSTFIELDS_LOADING,
  SET_ADDRESS_DETAILS,
  SET_PASSENGERS_DATA,
  SET_PASSENGERS_DATA_LOADING,
  SET_CHECKOUT_CHECKSUM,
  SET_ADDITIONAL_RECIPIENT_EMAIL,
  SET_POLICY_EXCEEDED_REASON,
  SET_DLK,
}
