import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchReturningTrainSearchAction,
  fetchTrainSearchAction,
  removeTrainSearchResultAction,
} from '../../store/train/trainAction'
import MiminizedSearchbar from '../../containers/search/mimizedSearch'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import { trainOutgoingbreadCrumbAction } from '../../store/breadcrumbs/breadcrumbsAction'
import useDirections from '../../hooks/useDirectionsHook'
import useDestinations from '../../hooks/useSwitchDestinationsHook'
import useOneWay from '../../hooks/useOneWayHook'
import useTravellers from '../../hooks/useTravellersHook'
import { useTranslation } from 'react-i18next'
import useTrains from '../../hooks/useTrainsHook'
import usePopover from '../../hooks/usePopoverHook'
import { getModifiedDepTime } from '../../utils/general'
import storage from 'local-storage-fallback'
import { disableExtendCheckout } from '../../store/search/searchAction'
import checkoutInlineSearch from '../../containers/search/checkoutInlineSearch'
import { toggleCheckoutItemsDrawer } from '../../store/modals/modalAction'

const TrainSearchPageForm = ({
  searchParams = {},
  searchType = 'outbound',
  beforeSearch = null,
  isExtendingFromCheckout = false,
}) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { isAuthenticated, user, trainSearch } = useSelector(
    (state) => state.auth
  )

  const earliestDate = user?.bookingLink?.earliestDate
  const latestDate = user?.bookingLink?.latestDate
  const isToLocked = user?.bookingLink?.allowedTrainDestination?.length > 0

  const singlePnrBooking = user?.singlePnrBooking

  const outGoingCity = useSelector((state) => state.train.outGoingCity)
  const { travellers: passengers, extendCheckout } = useSelector(
    (state) => state.search.train
  )
  const {
    from = null,
    to = null,
    dep = null,
    arr = null,
    isTwoWay,
    depTimeOutbound = null,
    depTimeInbound = null,
  } = searchParams
  const { i18n } = useTranslation()

  const { items } = useSelector((state) => state.checkout)

  useEffect(() => {
    const train = items.find((i) => {
      return i.type === 'Train'
    })

    if (!items.length) {
      dispatch(disableExtendCheckout())
    } else if ((!!train || !user) && !!extendCheckout) {
      history.push('/checkout')
    }
  }, [items, extendCheckout, dispatch])

  let depModified = getModifiedDepTime(dep, depTimeOutbound, trainSearch)
  let arrModified = getModifiedDepTime(arr, depTimeInbound, trainSearch)

  const { departure, depChange, arrival, arrOnChange } = useDirections(
    depModified,
    arrModified,
    true,
    earliestDate,
    latestDate
  )
  const { fromDest, setFrom, toDest, setTo, switchDestinations } =
    useDestinations(from, to, i18n)
  const { isOneWayDest } = useOneWay(arrival)
  const { travellers, setTravellers } = useTravellers(passengers)
  const {
    isPopoverOpenFrom,
    setIsPopoverOpenFrom,
    isPopoverOpenTo,
    setIsPopoverOpenTo,
    isPopoverOpenTraveller,
    setIsPopoverOpenTraveller,
  } = usePopover()

  const {
    getCities,
    onSubmit,
    optionLabel,
    renderOption,
    modifyCounts,
    counts,
    saveSearchParams,
    isSearchDataValid,
  } = useTrains({
    from: fromDest,
    to: toDest,
    departure,
    arrival,
    travellers,
    isOneWay: isOneWayDest,
    dispatch,
    history,
    setIsPopoverOpenFrom,
    setIsPopoverOpenTo,
    setIsPopoverOpenTraveller,
    forceNewSearch: false,
    isExtendingFromCheckout,
  })

  const submitSearch = useCallback(() => {
    if (beforeSearch && typeof beforeSearch === 'function') {
      beforeSearch()
    }
    onSubmit()
  }, [beforeSearch, onSubmit])

  useEffect(() => {
    dispatch(trainOutgoingbreadCrumbAction(pathname))
  }, [dispatch, pathname])

  const isSearchValid = useMemo(
    () => isSearchDataValid(),
    [fromDest, toDest, departure, travellers]
  )

  useEffect(() => {
    if (isSearchValid) {
      const sParamsSaved = saveSearchParams()

      if (sParamsSaved) {
        storage.removeItem('transfer')
        storage.removeItem('transfer_phone')
        const payload = {
          from,
          to,
          dep,
          arr,
          users: passengers,
          isTwoWay,
          depTimeOutbound,
          depTimeInbound,
        }

        if (searchType === 'outbound') {
          dispatch(fetchTrainSearchAction(payload))
        } else if (searchType === 'return') {
          dispatch(fetchReturningTrainSearchAction(payload))
        }
      }
    }

    return () => {
      dispatch(removeTrainSearchResultAction())
    }
  }, [
    isSearchValid,
    dispatch,
    from,
    to,
    dep,
    arr,
    isTwoWay,
    passengers,
    depTimeOutbound,
    depTimeInbound,
  ])

  const SearchComponent = isExtendingFromCheckout
    ? checkoutInlineSearch
    : MiminizedSearchbar

  return (
    <>
      {isMobile && !isExtendingFromCheckout ? <MobileHeader /> : ''}
      <SearchComponent
        /* */
        entityType={'train'}
        from={fromDest}
        setFrom={setFrom}
        to={toDest}
        setTo={setTo}
        renderOption={renderOption}
        /* */
        departure={departure}
        setDeparture={depChange}
        arrival={arrival}
        setArrival={arrOnChange}
        /* */
        disableUsersSelect={!!extendCheckout && !singlePnrBooking}
        travellers={travellers}
        setTravellers={setTravellers}
        switchDestinations={switchDestinations}
        getCities={getCities}
        isOneWay={isOneWayDest}
        onSubmit={submitSearch}
        array={outGoingCity}
        optionLabel={optionLabel}
        setIsPopoverOpenFrom={setIsPopoverOpenFrom}
        isPopoverOpenFrom={isPopoverOpenFrom}
        setIsPopoverOpenTo={setIsPopoverOpenTo}
        isPopoverOpenTo={isPopoverOpenTo}
        setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
        isPopoverOpenTraveller={isPopoverOpenTraveller}
        counts={isAuthenticated ? null : counts}
        modifyCounts={modifyCounts}
        TRAIN={trainSearch === 'custom'}
        earliestDate={earliestDate}
        latestDate={latestDate}
        isToLocked={isToLocked}
      />
    </>
  )
}

export default TrainSearchPageForm
