import React, { memo } from 'react'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import PassengerDetails from '../../../../components/flights/details/seats/passengerDetails'
import SeatsContainer from '../../../../components/flights/details/seats/seatsContainer'
import NoSeatsContainer from '../../../../components/flights/details/seats/noSeatsOnPlane'

const SeatBody = ({
  flight,
  passengers,
  activePassengerId,
  setActivePassenger,
  selectSeat,
  passengerSeats,
  removeSeat,
  activeFlight,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (!flight?.cols || !flight?.isFreeExists) return <NoSeatsContainer />
  return (
    <Grid container>
      {isMobile ? (
        <>
          <Grid item xs={12} sm={6}>
            <PassengerDetails
              activePassengerId={activePassengerId}
              passengers={passengers}
              setActivePassenger={setActivePassenger}
              removeSeat={removeSeat}
              passengerSeats={passengerSeats}
              activeFlight={activeFlight}
              selectSeat={selectSeat}
              flight={flight}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <PassengerDetails
              activePassengerId={activePassengerId}
              passengers={passengers}
              setActivePassenger={setActivePassenger}
              removeSeat={removeSeat}
              passengerSeats={passengerSeats}
              activeFlight={activeFlight}
              flight={flight}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SeatsContainer
              flight={flight}
              passengers={passengers}
              activePassengerId={activePassengerId}
              selectSeat={selectSeat}
              passengerSeats={passengerSeats}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default memo(SeatBody)
