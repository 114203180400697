import moment from 'moment'
import agent from '../../repositories/agent'
import storage from 'local-storage-fallback'

const configs = {
  'bigtraveltest.bizpart.com': {
    base_url: 'https://identitytest.bizpart.com/realms/BigTravelTest',
    client_id: 'citycity',
    redirect_uri: 'https://bigtraveltest.bizpart.com/book-trip?oidc-callback',
  },
  'citycity.bigtraveltest.bizpart.com': {
    base_url: 'https://identitytest.bizpart.com/realms/BigTravelTest',
    client_id: 'citycity',
  },
  'citycity.bigtravel.se': {
    base_url: 'https://identity.bigtravel.se/realms/BigTravel',
    client_id: 'citycityprod',
  },
  'bigtravel.se': {
    base_url: 'https://identity.bigtravel.se/realms/BigTravel',
    client_id: 'citycityprod',
    redirect_uri: 'https://bigtravel.se/book-trip?oidc-callback',
  },
  'big-test.citycity.se': {
    base_url: 'https://identity.bigtravel.se/realms/BigTravel',
    client_id: 'citycityprod',
    redirect_uri: 'https://big-test.citycity.se/?oidc-callback',
  },
  'test.citycity.se': {
    base_url: 'https://identitytest.bizpart.com/realms/BigTravelTest',
    client_id: 'citycity',
    redirect_uri: 'https://test.citycity.se/?oidc-callback',
  },
  default: {
    base_url: 'https://identity.bigtravel.se/realms/BigTravel',
    client_id: 'citycityprod',
  },
}

const config = configs[window.location.hostname] || configs.default
const {
  client_id,
  base_url,
  redirect_uri = `https://${window.location.hostname}/?oidc-callback`,
} = config

const auth_url = `${base_url}/protocol/openid-connect/auth`
//const redirect_uri = 'http://localhost-bigtravelpublic.citycity.se:3000/oidc-callback'

/**
 * Perform initial redirect if given query param "oidc-login".
 */
export const initiateOidcLogin = async (
  prompt = 'none',
  overrideUrl = null
) => {
  const searchParams = new URLSearchParams(window.location.search)

  console.log('redirect to login')

  const promptParam = searchParams.has('prompt')
    ? searchParams.get('prompt')
    : prompt

  let params = {
    response_type: 'code',
    scope: 'profile email openid',
    client_id,
    redirect_uri,
    prompt: promptParam,
  }

  let queryString = Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    })
    .join('&')

  let original_url = overrideUrl
    ? overrideUrl
    : window.location.href.split('?')[0]
  storage.setItem('oidc-original', original_url)

  console.log('original url = ' + original_url)

  searchParams.delete('oidc-login')
  let original_query = searchParams.toString()
  storage.setItem('oidc-original-query', original_query)

  window.location = `${auth_url}?${queryString}`
  return true
}

/**
 * Authorization code callback from identity provider - ask backend to take it from here.
 */
export const handleOidcCallback = async () => {
  const searchParams = new URLSearchParams(window.location.search)

  console.log('handling callback')
  console.log('original url is now = ' + storage.getItem('oidc-original'))

  const code = searchParams.get('code')
  const session_state = searchParams.get('session_state')

  const redirectBack = () => {
    const original_url = storage.getItem('oidc-original')
    const original_query = storage.getItem('oidc-original-query')
    if (original_url && original_url !== window.location.href) {
      console.log(`returning to original url, ${original_url}`)
      storage.removeItem('oidc-original')
      storage.removeItem('oidc-original-query')
      window.location = `${original_url}?${original_query}`
    } else {
      window.location =
        window.location.protocol + '//' + window.location.hostname
    }
  }

  if (searchParams.has('error')) {
    // User was not logged on. Nothing further we can do.
    console.log('login status was error - setting isLoggedInDone')
    storage.setItem('isLoggedInDone', 'True')

    redirectBack()
    return true
  }

  try {
    let { data } = await agent.post('/v2/auth/with-oidc', {
      code,
      session_state,
      redirect_uri,
    })

    let {
      name,
      email,
      access_token: token,
      refresh_token,
      companyId,
      expiry,
      url,
      guestIdentifier,
    } = data

    // Anonymous virtual users - no token
    if (guestIdentifier) {
      storage.setItem('guestIdentifier', guestIdentifier)

      if (url) {
        storage.setItem('url', url)
      }

      redirectBack()
      return true
    }

    // Remove any saved guest identifier
    storage.removeItem('guestIdentifier')

    // Set admin data for shared login.
    storage.setItem(
      'store',
      JSON.stringify({
        name,
        token,
        refresh_token,
        companyId,
        expiry,
      })
    )

    storage.setItem('jwtToken', token)
    if (url) {
      storage.setItem('url', url)
    }

    let formattedExpiry = moment().add(expiry, 'minutes').format()
    storage.setItem('expiry', formattedExpiry)

    redirectBack()

    return true
  } catch (ex) {
    // Token negotiation failed. Perhaps user does not exist. Nothing further we can do.
    console.log(ex)
    console.dir(JSON.stringify(ex.response, null, 3))

    console.log('error trying to negotiate token - setting isLoggedInDone')
    storage.setItem('isLoggedInDone', 'True')

    return redirectBack()
  }
}
