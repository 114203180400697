import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Box, Button, CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { activateBookLink } from '../../repositories/auth'
import { fetchPublicity, relogin } from '../../store/auth/authAction'
import { openBookingLinkInfoModalAction } from '../../store/modals/modalAction'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '25px 10px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  btn: {
    borderRadius: '10px',
    boxShadow: 'none',
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '28px',
    lineHeight: '1.5',
    color: theme.palette.common.black,

    '&.error': {
      color: theme.palette.secondary.main,
    },
  },
  errorHead: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '32px',
    lineHeight: 'normal',
    color: theme.palette.common.black,
    textAlign: 'center',
    marginBottom: '20px',
  },
  errorDescr: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px',
    lineHeight: 'normal',
    color: theme.palette.common.black,
    textAlign: 'center',
    display: 'inline-block',
    marginBottom: '20px',
  },
}))
const errorTexts = {
  1100: { header: 'booking link activation failed' },
  1101: {
    header: 'expired bl header',
    descr: 'expired bl description',
  },
  1102: {
    header: 'used bl header',
    descr: 'used bl description',
  },
}

const ActivateBookingLink = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isSent: false,
    loading: true,
    error: false,
  })
  let { jwt } = useParams()

  const activateLink = useCallback(async () => {
    setState({ ...state, isSent: true, loading: true })
    const resultState = { ...state, isSent: true, loading: false }
    let loginData = null
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      loginData = await activateBookLink(jwt)
      if (!loginData?.access_token || !loginData?.refresh_token) {
        throw Error('activation failed')
      }
      await dispatch(relogin(loginData))
      await dispatch(fetchPublicity(true))
      dispatch(openBookingLinkInfoModalAction())
      history.push('/')
      return true
    } catch (e) {
      const errCode = e?.response?.data?.code || 1100
      resultState.error = !!errorTexts?.[errCode]
        ? { ...errorTexts[errCode] }
        : { ...errorTexts[1100] }
      setState(resultState)
      return false
    }
  }, [history, dispatch, t, state, jwt])

  useEffect(() => {
    if (!state.isSent) {
      activateLink()
    }
  }, [state, activateLink])

  return (
    <Box className={classes.root}>
      <Container maxWidth="md" disableGutters className={classes.container}>
        {!!state.loading && (
          <Fragment>
            <CircularProgress />
            <span className={classes.text}>{t('activating booking link')}</span>
          </Fragment>
        )}
        {!state.loading && state.error && (
          <Fragment>
            <span className={`${classes.errorHead}`}>
              {t(state.error.header)}
            </span>
            {!!state.error?.descr && (
              <Box className={classes.errorDescr}>{t(state.error.descr)}</Box>
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={() => history.push('/')}
            >
              {t('go home page')}
            </Button>
          </Fragment>
        )}
      </Container>
    </Box>
  )
}

export default ActivateBookingLink
