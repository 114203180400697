import React, {memo, useEffect, useMemo, useState} from 'react'
import ModalHook from '../modals/reusable/modalHook'
import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

import {useTranslation} from 'react-i18next'
import {GoogleMap, OverlayView, useLoadScript} from '@react-google-maps/api'
import _ from 'lodash'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 26px 66px 26px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  },
  closeIcon: {
    width: '24px',
    color: theme.palette.indicatorGrey,
    cursor: 'pointer'
  },
  suppliedByRow: {
    fontSize: '14px',
    lineHeight: 'normal',
    color: 'black',
    paddingBottom: '26px',
    borderBottom: `1px solid ${theme.palette.border}`,
    marginBottom: '22px'
  },
  hoursLine: {
    fontSize: '14px',
    lineHeight: 'normal',
    color: 'black',
    marginBottom: '15px',

    '&:last-child': {
      marginBottom: 0
    }
  },
  locationTitle: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightSemiBold,
    lineHeight: 'normal',
    marginBottom: '15px'
  },
  locationRow: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 'normal',
    marginBottom: '28px'
  },
  headerTitle: {
    flex: 1,
    fontSize: '34px',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 'normal',
    color: '#333'
  },
  marker: {
    backgroundColor: 'white',
    padding: '7px 9px',
    borderRight: '8px',
    borderRadius: '16px',
    boxShadow: '0 1px 3px -1px rgba(0, 0, 0, 0.2)',
    fontSize: '16px',
    minWidth: '87px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
  }
}))

const CarInstructionsModal = ({ isOpen, data, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [map, setMap] = useState(null)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries: ['places']
  })
  const loc = _.startCase(_.toLower(data?.location.addressLine)) + ', ' + _.startCase(_.toLower(data?.location.city))

  const memoCenter = useMemo(() => (
    {
      lat: Number(Number.parseFloat(data?.location?.coords?.lat || 0).toPrecision(10)),
      lng: Number(Number.parseFloat(data?.location?.coords?.lng || 0).toPrecision(10)),
    }
  ), [data])

  const buildOpenHours = useMemo(() => {
    if (!data?.location?.openHours?.length) return []
    const weekDays = [
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat",
      "Sun",
    ]
    const texts = []
    let pendingDay = null

    data.location.openHours.forEach((h, i) => {
      if (!weekDays?.[i]) return
      const text = `${h.open} - ${h.close}`
      if (!pendingDay) {
        pendingDay = {
          startDay: weekDays[i],
          endDay: null,
          text
        }
      } else if (text === pendingDay.text) {
        pendingDay.endDay = weekDays[i]
      } else {
        texts.push(`${pendingDay.startDay}${!!pendingDay.endDay ? ' - ' + pendingDay.endDay : '' } ${pendingDay.text}`)
        pendingDay = {
          startDay: weekDays[i],
          endDay: null,
          text
        }
      }
    })

    if (!!pendingDay) {
      texts.push(`${pendingDay.startDay}${!!pendingDay.endDay ? ' - ' + pendingDay.endDay : '' } ${pendingDay.text}`)
    }

    return texts
  }, [data])

  useEffect(() => {
    if (map && memoCenter) {
      map.panTo(memoCenter)
    }
  }, [memoCenter, map])

  const markerCorrectOffset = (width, height) => ({x: -(width / 2), y: -(height / 2)})

  const markers = useMemo(
    () => {
      const loc = data?.location
      if (!loc) return null

      return [(
        <OverlayView
          getPixelPositionOffset={markerCorrectOffset}
          key={'company-marker'}
          position={memoCenter}
          mapPaneName={OverlayView.MARKER_LAYER}
        >
          <div>
            <div className={classes.marker}>
              {_.startCase(_.toLower(loc.companyName))}
            </div>
          </div>
        </OverlayView>
      )]
    },
    [data, memoCenter, classes]
  )

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  return (
    <ModalHook
      minHeight={300}
      width={530}
      modalBoolean={isOpen}
      marginTop={250}
      onClose={onClose}
    >
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Box className={classes.headerTitle}>{data?.title || ''}</Box>
          <CloseIcon onClick={onClose} className={classes.closeIcon}/>
        </Box>
        <Box className={classes.suppliedByRow}> {t('supplied by')} {_.startCase(_.toLower(data?.location?.companyName || '')) }</Box>
        <Box className={classes.locationTitle}> { data?.locationTitle } </Box>
        <Box className={classes.locationRow}> { loc } </Box>

        {isLoaded &&
          <GoogleMap
            clickableIcons={false}
            options={{
              disableDefaultUI: true,
              gestureHandling: 'cooperative',
              zoomControl: false,
            }}
            mapContainerStyle={{
              width: '100%',
              height: '130px',
              padding: '0px',
            }}
            center={memoCenter}
            zoom={12}
            style={{
              width: '100%',
              height: '130px'
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {markers}
          </GoogleMap>
        }
        <Box mt={'28px'}>
          <Box className={classes.locationTitle}> { t('opening hours') } </Box>
          {
            buildOpenHours.map(text => (
              <Box className={classes.hoursLine}>{text}</Box>
            ))
          }
        </Box>
      </Box>
    </ModalHook>
  )
}

export default memo(CarInstructionsModal)
