import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography, Grid, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import InformationBlock from '../../containers/profile/reusable/informationBlock'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { useProfileStyles } from './profileStyles'
import CheckoutFetch from '../../repositories/checkout'
const checkoutFetch = new CheckoutFetch()

const PersonalInformation = () => {
  const classes = useProfileStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const { t, i18n } = useTranslation()
  const [costLoading, setCostLoading] = useState(false)
  const [costfields, setCostfields] = useState([])
  const { user } = useSelector((state) => state.auth)
  const {
    isUmbrella,
    hideAddress,
    hideBirthdate,
    costfields: umbrellaCostfields,
  } = user
  const langMatch = (i18n.language || '').match(/(\w{2,3})-(\w{2,3})/i)
  const lang = langMatch?.[1] || i18n.language

  const getCostfields = useCallback(async () => {
    setCostLoading(true)
    const cf = await checkoutFetch.fetchCostFields()
    setCostLoading(false)
    setCostfields([...(cf?.costfields || [])])
  }, [])

  useEffect(() => {
    getCostfields()
  }, [getCostfields])

  const buildMoreInfo = () => {
    const res = {
      title: t('more'),
      section: 'more',
      rows: null,
    }

    if (!costfields.length) return res
    res.rows = costfields.map((c) => {
      const customLabel = c?.[`label${_.upperCase(lang)}`]
      return {
        title: customLabel || t(c.defaultLabel) + ` ${c.costType}`,
        key: `remarks.${c.costType}`,
        fieldType: c.type,
        options: c?.options || [],
        disabled: c.readonlyInProfile,
      }
    })
    return res
  }

  const general = isUmbrella
    ? {
        title: '',
        rows: [
          { title: t('profile title'), key: 'title' },
          { title: t('profile name'), key: 'firstname' },
          { title: t('profile surname'), key: 'lastname' },
          { title: t('contact email'), key: 'email' },
        ],
      }
    : {
        title: '',
        rows: [
          { title: t('profile title'), key: 'title' },
          { title: t('profile name'), key: 'firstname' },
          { title: t('profile surname'), key: 'lastname' },
        ],
      }

  const birth = {
    title: t('profile date'),
    section: 'more',
    rows: [{ title: t('profile date'), key: 'birthdate', fieldType: 'date' }],
  }

  if (user?.docId && user?.docId?.length > 0) birth.passport = user?.docId[0]
  const contactDetails = isUmbrella
    ? {
        title: t('contact'),
        section: 'contacts',
        rows: [
          { title: t('contact mobiltelefon'), key: 'mobile', order: 1 },
          { title: t('contact phone1'), key: 'phone', order: 2 },
        ],
      }
    : {
        title: t('contact'),
        section: 'contacts',
        rows: [
          { title: t('contact mobiltelefon'), key: 'phone', order: 1 },
          { title: t('contact phone1'), key: 'mobile', order: 2 },
          { title: t('contact email'), key: 'emails.email', order: 1 },
          { title: t('contact email1'), key: 'emails.alt1', order: 2 },
        ],
      }

  const homeAddress = {
    title: t('address'),
    section: 'home',
    rows: [
      { title: t('address street'), key: 'street' },
      { title: t('address zip'), key: 'zip', order: 1 },
      { title: t('address city'), key: 'city', order: 2 },
      { title: t('address land'), key: 'country' },
    ],
  }

  const moreInfo = buildMoreInfo()

  const editable = user.profileEditing !== false

  return (
    <Box pl={isDesktop ? 2 : 7} pr={2} pb={6} pt={isDesktop ? 0 : 2}>
      {!isDesktop && (
        <Typography variant="h3" className={classes.weight} gutterBottom>
          {t('nav personal')}
        </Typography>
      )}
      <Box mt={6} mb={4}>
        <Grid>
          <InformationBlock
            data={user.general}
            name={general.title}
            rows={general.rows}
          />
          {!hideBirthdate && (
            <InformationBlock
              data={user.general}
              section={birth.section}
              name={birth.title}
              rows={birth.rows}
              passport={birth.passport}
              editable={editable}
            />
          )}
          <InformationBlock
            data={user.contacts}
            section={contactDetails.section}
            name={contactDetails.title}
            rows={contactDetails.rows}
            editable={editable}
          />
          {!hideAddress && (
            <InformationBlock
              data={user.home}
              section={homeAddress.section}
              name={homeAddress.title}
              rows={homeAddress.rows}
              editable={editable}
            />
          )}
          {(!!moreInfo?.rows?.length || costLoading) && (
            <InformationBlock
              data={user.more}
              section={moreInfo.section}
              name={moreInfo.title}
              rows={moreInfo.rows || []}
              editable={editable}
              isLoading={costLoading}
            />
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default PersonalInformation
