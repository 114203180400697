import React, { memo } from 'react'
import { Box } from '@material-ui/core'
import FlightTimeline from './flightTimeline'

const MaximizedCard = ({ flight }) => {
  return (
    <Box my={'10px'} width={'100%'}>
      <FlightTimeline flight={flight} smallPadding={true}/>
    </Box>
  )
}

export default memo(MaximizedCard)
