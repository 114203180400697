import React, { memo } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, CardMedia, useMediaQuery } from '@material-ui/core'
import { getBrandedUrl } from '../../utils/branding'
import LanguageChooser from '../reusable/languageChooser'

const useStyles = makeStyles((theme) => ({
  root: {},
  logo: {
    height: '40px',
    width: '140px',
  },
  logoWrapper: {
    position: 'absolute',
    padding: '20px',
  },
  langIconWrapper: {
    position: 'absolute',
    right: '32px',
    top: '20px',
  },
  langIconBtn: {
    backgroundColor: 'rgb(0,0,0,0.1)',
    fontWeight: 600,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    span: {
      '&:first-child': {
        marginTop: '6px',
        marginRight: theme.spacing(1),
      },
    },
  },
}))

const LandingHeader = () => {
  const classes = useStyles()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(960))

  const logoUrl = getBrandedUrl('logo_retina.png')

  const goToLanding = () => {
    window.location = '/'
  }
  const isMobileLogoHide =
    (location.pathname === '/forgot-password' ||
      location.pathname === '/forgot') &&
    isMobile
  const isLogoHide = location.pathname === '/log-in'
  return (
    <>
      <Box className={classes.logoWrapper} onClick={goToLanding}>
        {isMobileLogoHide || isLogoHide || (
          <CardMedia
            data-id="header-logo"
            image={logoUrl}
            className={classes.logo}
          />
        )}
      </Box>

      <Box className={classes.langIconWrapper}>
        <LanguageChooser />
      </Box>
    </>
  )
}

export default memo(LandingHeader)
