import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import FlightMiddlestepDetails from './middleStepcard/desktop/flightmiddleStepDetails'
import TrainMiddlestepDetails from './middleStepcard/desktop/trainMiddlestepDetails'

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: '12px',
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
  },
}))

const viewHandler = {
  flights: FlightMiddlestepDetails,
  trains: TrainMiddlestepDetails,
}

const MiddlestepHandler = ({ type }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const TagName = viewHandler[type]

  return (
    <Paper
      elevation={0}
      style={{ background: isDesktop ? 'white' : 'none' }}
      className={classes.paper}
    >
      {TagName && <TagName />}
    </Paper>
  )
}

export default memo(MiddlestepHandler)
