import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import storage from 'local-storage-fallback'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { fetchDlkAssociations, fetchDlkTeams } from '../../../repositories/dlk'
import { setDlk } from '../../../store/checkout/checkoutActions'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },

  borderRadius: {
    marginTop: 0,
    '&:active': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
    '&:before, & .MuiInputBase-root:before': { display: 'none' },
    '& fieldset': {
      borderRadius: '6px',
      border: '1px solid',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderColor: '#e6e6e6',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {},
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
  },

  medium: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '14px',
    marginBottom: theme.spacing(1),
    marginTop: '16px',
  },
}))

/**
 * Digitala Lagkassan
 */
const DlkBlock = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { assocId, teamId, error } = useSelector((state) => state.checkout.dlk)
  const [associations, setAssociations] = useState([])
  const [teams, setTeams] = useState([])
  const [associationsLoading, setAssociationsLoading] = useState(false)
  const [teamsLoading, setTeamsLoading] = useState(false)

  useEffect(() => {
    const forwardedParams = storage.getItem('forwardedParams')
    if (forwardedParams) {
      let params = JSON.parse(forwardedParams)
      dispatch(setDlk(params.dlk_assoc, params.dlk_team))
    }

    const loadAssociations = async () => {
      setAssociationsLoading(true)
      let list = await fetchDlkAssociations()
      setAssociationsLoading(false)
      setAssociations(list)
    }

    loadAssociations()
  }, [])

  useEffect(() => {
    if (!assocId) return

    const loadTeams = async () => {
      setTeamsLoading(true)
      let list = await fetchDlkTeams(assocId)
      setTeamsLoading(false)
      setTeams(list)
    }

    loadTeams()
  }, [assocId])

  const handleSetAssociation = (evt) => {
    dispatch(setDlk(evt.target.value, null))
  }

  const handleSetTeam = (evt) => {
    dispatch(setDlk(assocId, evt.target.value))
  }

  const isMissing = !!error

  return (
    <Box mx={isMobile ? 2 : 4} m={2} mt={4} mb={4}>
      <Box mb={2}>
        <Typography variant="h5" className={classes.bold}>
          Digitala Lagkassan
        </Typography>
      </Box>
      <Divider />

      {associationsLoading && (
        <Box mx={4} m={2} textAlign={'center'}>
          <CircularProgress />
        </Box>
      )}

      {associations.length > 0 && (
        <Box mt={2}>
          <Typography className={classes.medium}>Förening</Typography>
          <Select
            error={!!isMissing}
            value={assocId}
            onChange={handleSetAssociation}
            displayEmpty
            className={classes.borderRadius}
            InputLabelProps={{ shrink: false }}
            variant={'outlined'}
            placeholder={t('select')}
            fullWidth
            IconComponent={ExpandMoreIcon}
          >
            <MenuItem style={{ color: 'silver' }} value="">
              <span style={{ color: '#999999', fontWeight: '500' }}>
                {t('select')}...
              </span>
            </MenuItem>
            {associations.map((association) => (
              <MenuItem value={association.id}>{association.name}</MenuItem>
            ))}
          </Select>
        </Box>
      )}

      {teamsLoading && (
        <Box mx={4} m={2} textAlign={'center'}>
          <CircularProgress />
        </Box>
      )}

      {teams.length > 0 && (
        <Box mt={2} mb={8}>
          <Typography className={classes.medium}>Lag</Typography>
          <Select
            error={!!isMissing}
            value={teamId}
            onChange={handleSetTeam}
            displayEmpty
            className={classes.borderRadius}
            InputLabelProps={{ shrink: false }}
            variant={'outlined'}
            placeholder={t('select')}
            fullWidth
            IconComponent={ExpandMoreIcon}
          >
            <MenuItem style={{ color: 'silver' }} value="">
              <span style={{ color: '#999999', fontWeight: '500' }}>
                {t('select')}...
              </span>
            </MenuItem>
            {teams.map((team) => (
              <MenuItem value={team.id}>{team.name}</MenuItem>
            ))}
          </Select>
        </Box>
      )}
    </Box>
  )
}
export default DlkBlock
