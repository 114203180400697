import React, { memo } from 'react'
import {
  Paper,
  Box,
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', height: '208px' },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  center: {
    display: 'flex',
    justifyContent: 'center',
  },

  firstRowMargin: {
    marginBottom: theme.spacing(-2.6),
    padding: theme.spacing(3, 4, 0, 4),
  },

  secondRowMargin: {
    marginBottom: theme.spacing(4.5),
    padding: theme.spacing(0, 4, 0, 4),
  },

  thirdRowMargin: {
    marginTop: theme.spacing(-3),
    padding: theme.spacing(0, 4, 3, 4),
  },
}))

const FlightSkeleton = ({ arrayLength = 10 }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const filledSkeletons = new Array(arrayLength).fill(null).map((x, i) => (
    <Box key={i}>
      <Paper className={classes.root} elevation={0}>
        <Grid container className={classes.firstRowMargin}>
          <Grid item xs={3}>
            <Skeleton
              animation="wave"
              variant="text"
              height={50}
              width={!isMobile ? theme.spacing(8) : theme.spacing(4)}
            />
          </Grid>

          <Grid item xs={3}>
            <Box pl={!isMobile ? 4 : 3.3}>
              <Skeleton
                animation="wave"
                variant="text"
                height={50}
                width={!isMobile ? theme.spacing(8) : theme.spacing(5)}
              />
            </Box>
          </Grid>

          <Grid item xs={3} />
          <Grid item xs={3}>
            <Skeleton
              animation="wave"
              variant="text"
              height={50}
              width={!isMobile ? theme.spacing(5) : theme.spacing(5)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.secondRowMargin}>
          <Grid item xs={3}>
            <Skeleton
              animation="wave"
              variant="text"
              height={50}
              width={!isMobile ? theme.spacing(12) : theme.spacing(6)}
            />
          </Grid>

          <Grid item xs={3}>
            <Box pl={!isMobile ? 3.5 : 2.8}>
              <Skeleton
                animation="wave"
                variant="text"
                height={50}
                width={!isMobile ? theme.spacing(12) : theme.spacing(6)}
              />
            </Box>
          </Grid>

          <Grid item xs={3} />

          <Grid item xs={3} />
        </Grid>

        <Grid container className={classes.thirdRowMargin}>
          <Grid item xs={3}>
            <Skeleton
              animation="wave"
              variant="text"
              height={50}
              width={!isMobile ? theme.spacing(8) : theme.spacing(4)}
            />
          </Grid>

          <Grid item xs={3} />
          <Grid item xs={3} />

          <Grid item xs={3}>
            <Skeleton
              animation="wave"
              variant="text"
              height={50}
              width={!isMobile ? theme.spacing(12) : theme.spacing(7)}
            />
          </Grid>
        </Grid>
        <Divider />
      </Paper>
    </Box>
  ))

  return <Box>{filledSkeletons}</Box>
}

export default memo(FlightSkeleton)
