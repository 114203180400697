import React, {useCallback, useMemo} from 'react'
import moment from 'moment'
import {Box, Select} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const useStyles = makeStyles((theme) => ({
  tripInfoOuter: {
    '& .header-row': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px',

      '& .header-text': {
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },

      '& .icon-block': {
        width: '24px',
        display: 'flex',
        marginRight: '15px',
      }
    },
    '& .info-row': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      marginBottom: '15px',

      '& .provider-icon-block': {
        marginRight: '5px',
        width: '50px',

        '& .logo-img': {
          width: '100%'
        },
      }
    }
  }
}))

const ShortTripInfo = ({ trip, dir }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getDate = () => {
    const startDate = moment(trip?.departure)
    return moment(startDate).format('ddd D MMM')
  }

  const getTime = () => {
    const startDate = moment(trip?.departure)
    const endDate = moment(trip?.arrival)
    return `${startDate.format('HH:mm')}-${endDate.format('HH:mm')}`
  }

  const getDuration = () => {
    const startDate = trip?.departure
    const endDate = trip?.arrival
    const sMoment = moment(startDate)
    const eMoment = moment(endDate)
    if (!sMoment.isValid() || !eMoment.isValid()) return ''
    const diffHrs = eMoment.diff(sMoment, 'hours')
    const diffMin = eMoment.diff(sMoment, 'minutes') - (diffHrs*60)
    return `${diffHrs > 0 ? diffHrs +'h' : ''} ${diffMin > 0 ? diffMin +'m' : ''}`
  }

  const getLogoUrl = () => {
    const code = trip?.company || ''
    return `/assets/companies/${code}.png`
  }

  const changes = () =>  {
    const segments = trip?.origin?.[dir === 'outbound' ? 'trip' : 'returnTrip']
    const count = (trip?.via?.length > 0) ? trip.via.length - 1 : 0
    if (count > 0) return `${count} ${t('change', count)}`
    return t('direct')
  }

  return (
    <div key="trip-info-outer" className={classes.tripInfoOuter}>
      <div key="header-row" className="header-row">
        <div key="icon-block" className="icon-block">
          {dir === 'outbound' ? (
            <ArrowForwardIcon key="arrow-icon" className="arrow-icon"/>
          ) : (
            <ArrowBackIcon key="arrow-icon" className="arrow-icon"/>
          )}
        </div>
        <div key="header-text" className="header-text">
          {t(dir === 'outbound' ? 'chosen trip' : 'chosen returntrip')}
        </div>
      </div>

      <div key="trip-time-row" className="info-row">
        {getDate()}&nbsp;<span className="font-weight-bold">{getTime()}</span>
      </div>

      <div key="trip-dest-row" className="info-row">
        {`${trip.from.city} (${trip.from.shortName})`} - {`${trip.to.city} (${trip.to.shortName})`}
      </div>

      <div key="trip-durr-row" className="info-row">
        <div key="provider-icon" className="provider-icon-block">
          <img key="logo-img" src={getLogoUrl()} className="logo-img"/>
        </div>
        <div key="duration-block" className="duration-block">
          <span className="font-weight-bold">{getDuration()}</span> {changes()}
        </div>
      </div>
    </div>
  )
}

export default ShortTripInfo
