import { useMediaQuery, useTheme } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function useHotelAvropaFilterHook() {
  const { search } = useSelector((state) => state.hotels)
  const [city, setCity] = useState(null)
  const [zone, setZone] = useState(null)
  const [category, setCategory] = useState(null)
  const [filtered, setFiltered] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const zoneField = isMobile ? 'AliasMobile' : 'AliasDesktop'

  const handleChange = (selectedCity, selectedZone, selectedCategory) => {
    setCity(selectedCity)
    setZone(selectedZone)
    setCategory(selectedCategory)
  }

  useEffect(() => {
    filterHotels()
  }, [search, city, zone, category])

  const filterHotels = () => {
    if (!search) return setFiltered([])
    if (!city) return setFiltered([])

    //    console.log(`filter city ${city} zone ${zone} cat ${category}`)

    let avropaFilteredHotels = search?.filter((hotel) => {
      if (!hotel.avropaData) return false

      //      console.log(`-${hotel.avropaData['Ort']}- -${city}-`)
      if (!city.includes(hotel.avropaData['Ort'])) return false
      if (!zone.includes(hotel.avropaData[zoneField])) return false
      if (!category.includes(hotel.avropaData['Kategori'].toString()))
        return false

      return true
    })
    setFiltered(avropaFilteredHotels)
  }

  return {
    filtered,
    handleChange,
  }
}

export default useHotelAvropaFilterHook
