import constants from './constants'
import { fetchCustomization } from '../../repositories/customization'

const setPages = (pages) => ({
  type: constants.SET_PAGES,
  payload: pages,
})

const setMenu = (menu) => ({
  type: constants.SET_MENU,
  payload: menu,
})

export const setActiveSearchType = (searchType) => (dispatch) =>
  dispatch({
    type: constants.SET_ACTIVE_SEARCH_TYPE,
    payload: searchType,
  })

export const setSearchByEvents = (searchByEvents) => ({
  type: constants.SET_SEARCH_BY_EVENTS,
  payload: searchByEvents,
})

const setLandingPage = (page) => ({
  type: constants.SET_LANDINGPAGE,
  payload: page,
})

const setLoading = (loading) => ({
  type: constants.SET_LOADING,
  payload: loading,
})

const setSettings = (settings) => ({
  type: constants.SET_SETTINGS,
  payload: settings,
})

export const setIsIframe = (isIframe) => ({
  type: constants.SET_IS_IFRAME,
  payload: isIframe,
})

export const getCustomization = () => async (dispatch) => {
  dispatch(setLoading(true)) // It is true by default already to delay routes processing until we have/or don't have data. Set it to true anyway.
  try {
    const data = await fetchCustomization()

    dispatch(setPages(data.pages))
    dispatch(setLandingPage(data.landingPage))
    dispatch(setMenu(data.menu))
    dispatch(setSettings(data))
  } catch (ex) {
    //console.log(ex)
    // This is fine. No CRM integration.
  } finally {
    dispatch(setLoading(false))
  }
}
