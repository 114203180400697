import React, {
  useState,
  useMemo, useCallback
} from 'react'
import FlightTimeline from '../../components/flights/flightSearchCard/flightTimeline'
import flightProviders from '../../constants/flightCompanies'
import {Box, Grid, GridList, Hidden} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import parseFlightData from '../../store/flights/helpers/parseFlightData'
import {POLICY_RESULT} from "../../constants/policy";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {openPolicyExceededModal} from "../../store/modals/modalAction";

const useStyles = makeStyles((theme) => ({
  flexAlignCenter: {
    display: 'flex',
    alignItems: "center"
  },

  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center"
  },

  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center"
  },

  tripListItem: {
    border: `solid 1px ${theme.palette.border}`,
    borderBottom: 'none',

    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.border}`
    },

    '&.is-open': {
      borderTop: '8px solid',
      borderBottom: `solid 1px ${theme.palette.border}`,
      borderColor: `${theme.palette.border}`,

      '&:last-child': {
        borderBottom: '8px solid'
      },

      '&.same-item': {
        borderColor: '#0dc5b8',
      },
    },
  },

  topRow: {
    cursor: 'pointer',
    position: 'relative',

    '&.same-item:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-1px',
      right: '-1px',
      bottom: '-1px',
      left: '-1px',
      border: 'solid 1px #0dc5b8'
    },
  },

  logoImage: {
    height: 'auto',
    width: '80px',
    marginRight: '25px'
  },

  topRowLeft: {
    padding: '0 30px',
    zIndex: '1',

    '@media screen and (max-width: 1200px)': {
      padding: '0 18px'
    },
  },

  rowBlock: {
    padding: '24px 0',
    '@media screen and (max-width: 1200px)': {
      padding: '18px 0'
    },

    '&.bag-info': {
      paddingLeft: '8px',
      paddingRight: '8px',
      borderRight: `solid 1px ${theme.palette.border}`,
      borderLeft: `solid 1px ${theme.palette.border}`
    },

    '&.price-block': {
      paddingLeft: '24px',
    }
  },

  outOfPolicy: {
    fontSize: '14px',
    marginTop: '3px',
    fontWeight: 'bold',
    color: '#dd635b'
  },

  openIconBlock: {
    cursor: 'pointer',
    height: '35px',
    width: '35px',
    borderRadius: '5px',
    border: 'solid 2px #4f4f4f',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  chevronIcon: {
    color: 'black'
  },

  arrowIcon: {
    color: '#4f4f4f',
    fontSize: '24px'
  },

  bagIcon: {
    width: '39px',
    height: '34px',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  bagHandle: {
    width: '40%',
    height: '20%',
    border: 'solid 2px #4f4f4f',
    borderBottom: 'none',
    borderTopLeftRadius: '20%',
    borderTopRightRadius: '20%'
  },

  bagBlock: {
    width: '100%',
    height: '80%',
    border: 'solid 2px #4f4f4f',
    borderRadius: '20%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold'
  },

  generalBlock: {
    '@media screen and (max-width: 1200px)': {
      marginRight: '15px'
    },
  },

  subLine: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#212121'
  },

  generalBlockText: {
    fontSize: '22px',
    fontWeight: 'bold'
  },

  expandedRow: {
    padding: '20px',
    paddingTop: '0'
  },

  schemeOuter: {
    border: '1px solid #4f4f4f',
    borderRadius: '5px',
    width: '100%'
  },

  schemeHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '15px 20px',
    borderBottom: '1px solid #4f4f4f'
  },

  dotIcon: {
    display: 'inline-block',
    height: '4px',
    width: '4px',
    backgroundColor: '#4f4f4f',
    borderRadius: '8px',
    marginRight: '8px'
  },

  expandedRowLeft: {
    padding: '25px',
    paddingBottom: '0px',
    alignSelf: 'stretch'
  }
}))

const FlightTripSearchItem = ({ item, currTrip, outbound, onSelectTicket, lowestPrice }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isMounted: true,
    isOpen: false,
  })
  const itemForTimeline = parseFlightData([{...item}], null, !!outbound ? 'return' : 'outbound')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const getTimeText = (dir = null, type = 'time') => {
    const startDate = item?.departure
    const endDate = item?.arrival
    const sMoment = moment(startDate)
    const eMoment = moment(endDate)
    if (!sMoment.isValid() || !eMoment.isValid()) return ''

    const format = type === 'time' ? 'HH:mm' : 'ddd D MMMM'
    if (dir === 'dep') return `${sMoment.format(format)}`
    if (dir === 'dest') return `${eMoment.format(format)}`
    return `${sMoment.format(format)} - ${eMoment.format(format)}`
  }

  const policy = useMemo(() => {
    return parseInt(item?.exceedsPolicy || 0)
  }, [item]);

  const isBlocked = useMemo(() => {
    return [POLICY_RESULT.HIDE, POLICY_RESULT.BLOCK].includes(policy)
  }, [policy])

  const isSame = useMemo(() => {
    return !!item?.isSame
  }, [item])

  const toggle = (e) => {
    e.stopPropagation()
    setState({ ...state, isOpen: !state.isOpen })
  }

  const selectTicket = () => {
    if (isBlocked) {
      return dispatch(
        openPolicyExceededModal({
          allowReasons: false,
          clickHandler: () => onSelectTicket(res),
        })
      )
    }
    onSelectTicket({...item})
  }

  const getDuration = () => {
    const startDate = item?.departure
    const endDate = item?.arrival
    const sMoment = moment(startDate)
    const eMoment = moment(endDate)
    if (!sMoment.isValid() || !eMoment.isValid()) return ''
    const diffHrs = eMoment.diff(sMoment, 'hours')
    const diffMin = eMoment.diff(sMoment, 'minutes') - (diffHrs*60)
    return `${diffHrs > 0 ? diffHrs + t('h') : ''} ${diffMin > 0 ? diffMin + t('m') : ''}`
  }

  const dep = getTimeText('dep')
  const dest = getTimeText('dest')

  const from = `${item?.from?.city} (${item?.from?.shortName})`
  const to = `${item?.to?.city} (${item?.to?.shortName})`

  const trip = useMemo(() => {
    const trip = outbound ? item.original.trip : item.original.returnTrip
    return (trip || []).map(t => ({...t, ffName: item.original?.ffName}))
  }, [outbound, item]);

  const provider = useMemo(() => {
    let company = flightProviders?.[item?.company]?.label || item?.company

    if (item?.operatingCarrier && item?.company !== item.operatingCarrier) {
      const oc = flightProviders?.[item?.operatingCarrier]?.label || item?.operatingCarrier
      company += ` + ${oc}`
    }
    return company
  }, [item]);

  const changes = useMemo(() => {
    const dir = !!outbound ? 'return' : 'outbound'
    const flights = dir === 'outbound' ? item?.original?.trip || [] : item?.original?.returnTrip || []
    const count = (flights?.length > 0) ? flights.length - 1 : 0
    if (count > 0) return `${count} ${t('change', count)}`
    return t('filter directly')
  }, [t, item, outbound]);

  const bagsCnt = useMemo(() => {
    const units = {
      N: '',
      K: 'kg',
      L: 'lb'
    }
    const u = units?.[item?.FBA?.unit || '']
    if (typeof u === 'undefined' || !item?.FBA?.quantity) return '0'
    return `${item.FBA.quantity}${u}`
  }, [item]);

  const pasCount = currTrip?.passengers?.length || 1

  const isRound = useMemo(() => {
    const retDate = moment(currTrip?.parsedSourceData?.returnDate || '', 'YYYY-MM-DD HH:mm')
    return retDate.isValid()
  }, [currTrip]);

  const prices = useMemo(() => {
    const tmpOutbound = (!!outbound) ? outbound : item
    const inbound = (!!outbound) ? item : null
    const isMultiTicket = inbound?.original?.isMultiTicket

    if (isMultiTicket) {
      let oPrice = parseFloat(inbound.original.outboundPrice || 0)
      let rPrice = parseFloat(inbound.original.inboundPrice || 0)
      const currTotal = inbound.price

      return {
        isMTK: true,
        outbound: oPrice,
        return: rPrice,
        text: !!lowestPrice ? `+${currTotal - lowestPrice}` : currTotal
      }
    } else if (inbound) {
      const rPrice = parseFloat(inbound?.price || 0)
      const diff = !!lowestPrice ? `+${rPrice - lowestPrice}` : rPrice

      return {
        isMTK: false,
        text: diff
      }
    } else {
      let price = parseFloat(tmpOutbound.price || 0)

      return {
        isMTK: false,
        outbound: price,
        return: 0,
        text: price
      }
    }
  }, [outbound, item, lowestPrice])

  const logoUrl = `/assets/companies/${item?.company || ''}.png`

  const priceBlock = (
    <Box key="duration-block" className="general-block d-inline-block">
      <Box key="durr-text" className={`${classes.generalBlockText}`}>{prices.text} kr</Box>
      <Box key="bag-block" className="bag-block">{pasCount} pers, {t(isRound ? 'roundtrip' : 'trip')}</Box>
      {!!policy && (
        <Box key="out-of-policy" className={classes.outOfPolicy}> {t('out of policy')} </Box>
      )}
    </Box>
  )

  const bagIcon = (
    <Box key="bag-icon" className={`${classes.bagIcon}`}>
      <Box key="bag-handle" className={`${classes.bagHandle}`}></Box>
      <Box key="bag-block" className={`${classes.bagBlock}`}>{bagsCnt}</Box>
    </Box>
  )
  return (
    <Box
      key="trip-list-item"
      className={`${classes.tripListItem} ${state.isOpen ? 'is-open' : ''} ${isSame && state.isOpen ? 'same-item' : ''}`}
    >
      <Grid
        key="top-row"
        container
        onClick={selectTicket}
        spacing={0}
        className={`${classes.topRow} ${isSame && !state.isOpen ? 'same-item' : ''}`}
      >
        <Grid key="top-row-left" item xs={12} className={`${classes.topRowLeft}`}>
          <Grid key="top-row-left-row" container>

            <Hidden smDown>
              <Grid
                  key="inner-dep-time-block"
                  item
                  xs={'auto'}
                  className={`${classes.rowBlock} ${classes.flexAlignCenter}`}
              >
               <img key={`logo-image`} className={classes.logoImage} src={logoUrl}/>
              </Grid>
            </Hidden>

            <Grid
              key="inner-dep-time-block"
              item
              md={2}
              xs={3}
              className={`${classes.rowBlock} ${classes.flexAlignCenter}`}
            >
              <Box key="time-block-dep" className={`${classes.generalBlock}`}>
                <Box key="time-block-inner-container" style={{display:'inline-flex', flexDirection: 'column'}}>
                  <Box key="time-block-text" width={'100%'} className={`${classes.generalBlockText}`}>{dep}</Box>
                  <Box key="sub-line" width={'100%'} className={`${classes.subLine}`}>{from}</Box>
                </Box>
              </Box>
            </Grid>

            <Grid
              key="inner-dest-time-block"
              md={2}
              xs={3}
              item
              className={`${classes.rowBlock} ${classes.flexAlignCenter}`}
            >
              <Box key="time-block-dest" className={`${classes.generalBlock}`}>
                <Box key="time-block-inner-container" className="d-inline-block">
                  <Box key="time-block-text" className={`${classes.generalBlockText}`}>{dest}</Box>
                  <Box key="sub-line" className={`${classes.subLine}`}>{to}</Box>
                </Box>
              </Box>
            </Grid>


            <Grid
              xs={true}
              item
              key="inner-duration-block"
              className={`${classes.rowBlock} ${classes.flexBetween}`}
            >
              <Box key="duration-block" display={'inline-block'} className={`${classes.generalBlock}`}>
                <Box key="durr-text" className={`${classes.generalBlockText}`}>{getDuration()}</Box>
                <Box key="sub-line" className={`${classes.subLine}`}>{changes}</Box>
              </Box>

              <Hidden smDown>
                <Box key="provider-block" display={'inline-block'} marginRight={'12px'} className={`${classes.generalBlock}`}>
                  <Box key="sub-line" className={`${classes.subLine}`}>{provider}</Box>
                </Box>
              </Hidden>
            </Grid>

            <Hidden smDown>
              <Grid
                xs={'auto'}
                item
                key="inner-bag-block"
                className={`${classes.rowBlock} ${classes.flexCenter} bag-info`}
              >
                {bagIcon}
              </Grid>
            </Hidden>

            <Grid
              key="inner-price-block"
              xs={3}
              item
              className={`${classes.rowBlock} ${classes.flexBetween} price-block`}
            >
              <Box>
                <Hidden smDown>
                  {priceBlock}
                </Hidden>
              </Box>

              <Box key="open-icon-block" onClick={toggle} className={`${classes.openIconBlock}`}>
                {
                  state.isOpen ? (
                    <KeyboardArrowUpIcon className={classes.arrowIcon}/>
                  ) : (
                    <KeyboardArrowDownIcon className={classes.arrowIcon}/>
                  )
                }
              </Box>
            </Grid>
            <Hidden mdUp>
              <Grid xs={12}>
                <Grid key={'mobile-row-container'} container>
                  <Grid
                    key={'mobile-row-provider'}
                    xs={6}
                    item
                    className={`${classes.rowBlock} ${classes.flexAlignCenter}`}
                  >
                    <img key={`logo-image`} className={classes.logoImage} src={logoUrl}/>
                  </Grid>
                  <Grid key={'mobile-row-bag'} xs={3} className={`${classes.rowBlock} ${classes.flexAlignCenter}`}>
                    {bagIcon}
                  </Grid>
                  <Grid
                    key={'mobile-row-price'}
                    item
                    justifyContent={'flex-end'}
                    xs={true}
                    className={`${classes.rowBlock} ${classes.flexAlignCenter}`}
                  >
                    {priceBlock}
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>

      {!!state.isOpen && (
        <Box key="expanded-row" className={`${classes.expandedRow}`}>
          <FlightTimeline flight={itemForTimeline[0]} />
        </Box>
      )}

    </Box>
  )
}

export default FlightTripSearchItem
