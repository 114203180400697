const calculatePrice = (
  margins = [],
  price,
  country = null,
  dir = 'outbound',
  passengersCount = 1,
  isMultiTicket = false,
  isFlight = false
) => {
  if (!price) return price
  const nordic = ['SE', 'DK', 'NO', 'FI', 'IS']
  // Find margin for this country, or a general margin. If exists Nordic margin it has priority from all margins
  let nordicMargin = margins.find(
    (m) => m.country === 'nordic' && nordic.includes(country || '')
  )
  let margin = nordicMargin ?? margins.find((m) => m.country === country)
  if (!margin) margin = margins.find((m) => !m.country)

  let marginAmount = 0

  if (margin) {
    // Apply margin
    if (margin.percent) {
      marginAmount = Math.round(price * margin.percent)
    } else {
      if (
        isFlight &&
        margin?.type === 'flight' &&
        margin?.priceType === 'way'
      ) {
        if (dir === 'both') {
          marginAmount =
            (margin?.outboundAmount || 0) + (margin?.inboundAmount || 0)
        } else {
          marginAmount =
            dir === 'outbound'
              ? margin?.outboundAmount || 0
              : margin?.inboundAmount || 0
        }
      } else if (dir === 'both' && isMultiTicket) {
        marginAmount = margin.amount * 2
      } else {
        marginAmount = margin.amount
      }
    }
  }

  marginAmount = marginAmount * passengersCount

  return { price: price + marginAmount, markup: marginAmount }
}

export const calculateFlightPrice = (
  margins = [],
  price,
  country,
  isMultiTicket = false,
  passengersCount = 1,
  dir
) => {
  return calculatePrice(
    margins,
    price,
    country,
    dir,
    passengersCount,
    isMultiTicket,
    true
  )
}
export const calculateTrainPrice = (margins = [], price, country, dir) =>
  calculatePrice(margins, price, country, dir)
export const calculateHotelPrice = (margins = [], price, country) =>
  calculatePrice(margins, price, country)
export const calculateTransferPrice = (margins = [], price, country) =>
  calculatePrice(margins, price, country)
