import React from 'react'
import { Grid, Typography } from '@material-ui/core'

const BankIdTab = () => {
  return (
    <Grid container>
      <Typography>BankId</Typography>
    </Grid>
  )
}

export default BankIdTab
