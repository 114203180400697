import { useState } from 'react'

const usePopover = () => {
  const [isPopoverOpenFrom, setIsPopoverOpenFrom] = useState(false)
  const [isPopoverOpenTo, setIsPopoverOpenTo] = useState(false)
  const [isPopoverOpenTraveller, setIsPopoverOpenTraveller] = useState(false)
  const [isPopoverOpenCheckIn, setIsPopoverOpenCheckIn] = useState(false)
  const [isPopoverOpenCheckOut, setIsPopoverOpenCheckOut] = useState(false)

  return {
    setIsPopoverOpenFrom,
    isPopoverOpenFrom,
    setIsPopoverOpenTo,
    isPopoverOpenTo,
    setIsPopoverOpenTraveller,
    isPopoverOpenTraveller,
    setIsPopoverOpenCheckIn,
    isPopoverOpenCheckIn,
    setIsPopoverOpenCheckOut,
    isPopoverOpenCheckOut,
  }
}

export default usePopover
