import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { memo } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { closeAllModals } from '../../../store/modals/modalAction'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
}))

const ModalHeader = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const closeModal = () => dispatch(closeAllModals())

  return (
    <Box className={classes.header}>
      <IconButton
        onClick={closeModal}
        color="primary"
        className={classes.closeBtn}
      >
        <CloseIcon color="primary" />
      </IconButton>
    </Box>
  )
}

export default memo(ModalHeader)
