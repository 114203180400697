import React from 'react'
import Box from '@material-ui/core/Box'
import FlightTaxiCheckoutCard from './flightTaxiCheckoutCard'
import FlightBusCheckoutCard from './flightbusTaxiCheckoutCard'
import Divider from '@material-ui/core/Divider'

const viewHandler = {
  flightTaxi: FlightTaxiCheckoutCard,
  tagTaxi: FlightTaxiCheckoutCard,
  flightBus: FlightBusCheckoutCard,
}

const TransferViewnHandlerCard = ({ details }) => {
  const renderItems = [];
  details.forEach((component, key) => {
    // Returns false if item does not contain any transfer data
    if (!component.transfer?.length) return null

    component?.transfer.forEach(t => {
      const TagName = viewHandler[t?.type]
      if (!TagName) return false
      renderItems.push((
        <Box key={key} mt={2}>
          <TagName transfer={t} />
          {
            key === details.length - 1 || (
              <Box mt={2}>
                <Divider />
              </Box>
            )
          }
        </Box>
      ))
    })

  })

  return <Box>{renderItems}</Box>
}

export default TransferViewnHandlerCard
