import React, {Fragment, useCallback, useEffect, useState, useMemo} from 'react'
import _ from 'lodash'
import {Box, Button, CircularProgress, Grid, Container} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import { getBrandedUrl, getDomain } from '../../utils/branding'
import formatNumber from '../../utils/numberWithCommas'
import {useTranslation} from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FlightIcon from '@material-ui/icons/FlightTakeoff'
import RailIcon from '@material-ui/icons/DirectionsRailway'
import TransferIcon from '@material-ui/icons/LocalTaxi'
import HotelIcon from '@material-ui/icons/Hotel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import {editAndApproveTrips} from "../../repositories/suggestions";
import {BED_TYPE, parseRoomCode, ROOM_CATEGORY} from "../../constants/hotelRoomCodes";

const typeIcons = {
  flight: FlightIcon,
  rail: RailIcon,
  hotel: HotelIcon,
  transfer: TransferIcon
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.secondary.warning,
    padding: '25px 10px'
  },
  headerLine: {
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  textBlock: {
    fontSize: '15px',
    textAlign: 'center',
  }
}))

const TripChangedBanner = ({ texts }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box key="changes-banner" className={classes.root}>
      <Box key="header-line" class={classes.headerLine}>
        {t('offer changed')}
      </Box>
      <Box key="text-block" className={classes.textBlock}>
        {!!texts?.length && (
          <span>{texts.map(text => t(text)).join(', ')}.&nbsp;</span>
        )}
        {t('offer changed text')}
      </Box>
    </Box>
  )
}

export default TripChangedBanner
