import React, { Fragment } from 'react'
import _ from 'lodash'
import {
  Typography,
  Box,
  Button,
  useTheme,
  CircularProgress,
} from '@material-ui/core'
import RowWithDivider from '../../../components/reusable/rowWithDivider'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { openEditInfoModal } from '../../../store/modals/modalAction'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.spacing(3),
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  editBtn: {
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.spacing(3),
    marginLeft: theme.spacing(3),
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(11),
  },
}))

const InformationBlock = ({
  data,
  name,
  rows,
  section,
  editable,
  first,
  isLoading = false,
  passport,
  index,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { countriesList } = useSelector((state) => state.other)

  const getVal = (key) => {
    let res = _.get(data, key)
    if (
      res &&
      _.includes(['country', 'nationality', 'issueCountry'], key) &&
      countriesList?.length
    ) {
      const codeName = key === 'country' ? 'code' : 'alpha3Code'
      const found = countriesList.find((c) => c[codeName] === res.trim())
      if (found) return found?.name || ''
    }
    return res
  }

  const openEditModal = () => {
    dispatch(
      openEditInfoModal({ name, rows, section, values: data, passport, index })
    )
  }

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {name && (
          <Typography
            className={classes.heading}
            style={{
              marginTop:
                first && isDesktop ? theme.spacing(6) : theme.spacing(11),
            }}
          >
            {name}
          </Typography>
        )}
        {editable && (
          <Button className={classes.editBtn} onClick={openEditModal}>
            {t('edit btn')}
          </Button>
        )}
      </Box>
      {isLoading ? (
        <Box py={2} textAlign={'center'}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Fragment>
          {rows &&
            rows.map(
              (row) =>
                !row.notInRows && (
                  <RowWithDivider
                    name={row.title}
                    value={getVal(row.key)}
                    key={row.title}
                  />
                )
            )}
        </Fragment>
      )}
    </Box>
  )
}

export default InformationBlock
