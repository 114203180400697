export const loyaltyPrograms = {
  hotel: [
    {
      value: 'RT',
      name: 'Accor Hotels (All Brands)',
    },
    {
      value: 'RT',
      name: 'Accor Hotels (All Brands)',
    },
    {
      value: 'LK',
      name: 'Amadeus Linkhotel',
    },
    {
      value: 'EC',
      name: 'Exclusively Choice Hotels',
    },
    {
      value: 'EW',
      name: 'Excl World Hotels',
    },
    {
      value: 'FW',
      name: 'FRHI Hotels&amp;Resorts',
    },
    {
      value: 'GL',
      name: 'Global Hotel Alliance',
    },
    {
      value: 'GL',
      name: 'Global Hotel Alliance',
    },
    {
      value: 'EH',
      name: 'Hilton (All Brands)',
    },
    {
      value: '6C',
      name: 'Intercontinental (All Brands)',
    },
    {
      value: 'EM',
      name: 'Marriott (All Brands)',
    },
    {
      value: 'PV',
      name: 'Preferred Hotel Group',
    },
    {
      value: 'CW',
      name: 'Radisson Hotel Group',
    },
    {
      value: 'WR',
      name: 'Wyndham Hotel Group (All Brands)',
    },
    {
      value: 'AA',
      name: 'AADX Plus',
    },
    {
      value: 'AB',
      name: 'Abba Hoteles',
    },
    {
      value: 'RTB',
      name: 'Accor Hotels',
    },
    {
      value: '1D',
      name: 'ACHAT Hotels',
    },
    {
      value: 'AR',
      name: 'AC Hotels',
    },
    {
      value: '1B',
      name: 'Active Hotels',
    },
    {
      value: 'RAA',
      name: 'Adagio Access (Accor Group)',
    },
    {
      value: 'RTD',
      name: 'Adagio City Aparthotels (Accor Group)',
    },
    {
      value: 'AAP',
      name: 'Adagio Premium (Accor Group)',
    },
    {
      value: 'GO',
      name: 'aha Hotels &amp; Lodges',
    },
    {
      value: 'AE',
      name: 'Albatravel',
    },
    {
      value: 'OK',
      name: 'Alesia Distribution',
    },
    {
      value: 'AV',
      name: 'Allegiance Hospitality Services',
    },
    {
      value: 'RTA',
      name: 'All Seasons Hotels (Accor Group)',
    },
    {
      value: 'AL',
      name: 'Aloft Hotels',
    },
    {
      value: 'OI',
      name: 'Amadeus Linkhotel',
    },
    {
      value: 'AM',
      name: 'Aman',
    },
    {
      value: 'AHR',
      name: 'Amari Hotels &amp; Resorts',
    },
    {
      value: 'BV',
      name: 'Americas Best Value Inn',
    },
    {
      value: 'AN',
      name: 'Ana Hotels',
    },
    {
      value: 'QU',
      name: 'Aqua Hotels &amp; Resorts',
    },
    {
      value: 'AI',
      name: 'Armani Hotels &amp; Resorts',
    },
    {
      value: 'NZ',
      name: 'Ascend Collection',
    },
    {
      value: 'AZ',
      name: 'Ascott Limited',
    },
    {
      value: 'AH',
      name: 'Aston Hotels &amp; Resorts',
    },
    {
      value: 'AC',
      name: 'Atel Hotels',
    },
    {
      value: 'AO',
      name: 'Atlantis',
    },
    {
      value: 'AU',
      name: 'Auberge Resorts',
    },
    {
      value: 'AK',
      name: 'Autograph Collection (Marriott Group)',
    },
    {
      value: 'BI',
      name: 'Baccarat and 1 Hotel',
    },
    {
      value: 'BL',
      name: 'Balladins',
    },
    {
      value: 'BY',
      name: 'Banyan Tree Hotels &amp; Resorts (Accor Group)',
    },
    {
      value: 'BN',
      name: 'Barcel&oacute; Hotel Group',
    },
    {
      value: 'BU',
      name: 'Baymont Inn &amp; Suites',
    },
    {
      value: 'OE',
      name: 'Belmond',
    },
    {
      value: 'BE',
      name: 'Benchmark Hotels',
    },
    {
      value: 'BW',
      name: 'Best Western',
    },
    {
      value: 'LT',
      name: 'Brazil Hospitality Group',
    },
    {
      value: 'BG',
      name: 'Bulgari Hotels &amp; Resorts',
    },
    {
      value: 'BT',
      name: 'Business Travel Advantage',
    },
    {
      value: 'EZ',
      name: 'Cambria Suites',
    },
    {
      value: 'CM',
      name: 'Camino Real',
    },
    {
      value: 'YO',
      name: 'Candlewood Suites (Intercontinental Group)',
    },
    {
      value: 'PY',
      name: 'Canopy by Hilton',
    },
    {
      value: 'HC',
      name: 'Canvas Hospitality',
    },
    {
      value: 'CPL',
      name: 'Capella Hotels &amp; Resorts',
    },
    {
      value: 'EU',
      name: 'Chateaux &amp; Hotels Collection',
    },
    {
      value: 'FF',
      name: 'Chic Collection',
    },
    {
      value: 'XE',
      name: 'City Express Hotels',
    },
    {
      value: 'CG',
      name: 'City Lodge',
    },
    {
      value: 'CF',
      name: 'City Partner Hotels',
    },
    {
      value: 'CC',
      name: 'Clarion Hotels',
    },
    {
      value: 'CB',
      name: 'Classic British Hotels',
    },
    {
      value: 'CH',
      name: 'Classic International Hotels',
    },
    {
      value: 'KL',
      name: 'ClubHouse Inn and Suites',
    },
    {
      value: 'CQ',
      name: 'Club Quarters',
    },
    {
      value: 'BZ',
      name: 'CMNet Hotels',
    },
    {
      value: 'WX',
      name: 'Coast Hotels &amp; Resorts',
    },
    {
      value: 'BX',
      name: 'Columbus Reservation',
    },
    {
      value: 'CI',
      name: 'Comfort Inn',
    },
    {
      value: 'CZ',
      name: 'Comfort Suites',
    },
    {
      value: 'CV',
      name: 'Como Hotels and Resorts',
    },
    {
      value: 'CNH',
      name: 'Concorde Hotels',
    },
    {
      value: 'CN',
      name: 'Conrad Hotels',
    },
    {
      value: 'CIH',
      name: 'Coral International Hotels &amp; Resorts',
    },
    {
      value: 'IA',
      name: 'Corinthia Hotels',
    },
    {
      value: 'CX',
      name: 'Country Inn Suites',
    },
    {
      value: 'CY',
      name: 'Courtyard Hotels (Marriott Group)',
    },
    {
      value: 'CP',
      name: 'Crowne Plaza Hotels (Intercontinental Group)',
    },
    {
      value: 'CO',
      name: 'Cubanacan Hotels',
    },
    {
      value: 'QQ',
      name: 'Curio Collection (Hilton Group)',
    },
    {
      value: 'ZW',
      name: 'CWT Private Hotels',
    },
    {
      value: 'DI',
      name: 'Days Inn',
    },
    {
      value: 'FG',
      name: 'D-EDGE hotels',
    },
    {
      value: 'DE',
      name: 'Delta Hotels (EM Marriott International)',
    },
    {
      value: 'NY',
      name: 'Denihan Hospitality',
    },
    {
      value: 'DD',
      name: 'Derag Livinghotels',
    },
    {
      value: 'DS',
      name: 'Design Hotels',
    },
    {
      value: 'DN',
      name: 'Destination Hotels',
    },
    {
      value: 'SR',
      name: 'Deutsche Hospitality',
    },
    {
      value: 'DJ',
      name: 'Divan Hotels',
    },
    {
      value: 'DX',
      name: 'Dolce Hotels &amp; Resorts',
    },
    {
      value: 'DC',
      name: 'Dorchester Hotels',
    },
    {
      value: 'DO',
      name: 'Dorint Hotels and Resorts',
    },
    {
      value: '1I',
      name: 'Dormotels',
    },
    {
      value: 'DT',
      name: 'Doubletree (Hilton Group)',
    },
    {
      value: 'DZ',
      name: 'Dream Hotels',
    },
    {
      value: 'DR',
      name: 'Drury Inns',
    },
    {
      value: 'DHR',
      name: 'Dunas Hotels &amp; Resorts',
    },
    {
      value: 'DW',
      name: 'Dusit Hotels &amp; Resorts',
    },
    {
      value: 'EQ',
      name: 'Eaton Hotels',
    },
    {
      value: 'EO',
      name: 'Econo Lodge',
    },
    {
      value: 'ER',
      name: 'Eden Hotels',
    },
    {
      value: 'EB',
      name: 'Edition',
    },
    {
      value: 'EN',
      name: 'Elan Hotel (Accor Group)',
    },
    {
      value: 'EL',
      name: 'Element Hotels',
    },
    {
      value: 'ES',
      name: 'Embassy Suites (Hilton Group)',
    },
    {
      value: 'EP',
      name: 'Epoque Hotels',
    },
    {
      value: 'FE',
      name: 'Esplendor &amp; Dazzler',
    },
    {
      value: 'RTE',
      name: 'ETAP Hotel (Accor Group)',
    },
    {
      value: 'VN',
      name: 'EVEN Hotels',
    },
    {
      value: 'EXC',
      name: 'Exclusive Hotels',
    },
    {
      value: 'EI',
      name: 'Executive Hotels',
    },
    {
      value: 'EA',
      name: 'Extended Stay',
    },
    {
      value: 'FN',
      name: 'Fairfield Inn',
    },
    {
      value: 'FA',
      name: 'Fairmont Hotels (Accor Group)',
    },
    {
      value: 'FHR',
      name: 'Falkensteiner Hotels &amp; Residences',
    },
    {
      value: 'FH',
      name: 'Fiesta Americana',
    },
    {
      value: 'FHG',
      name: 'Fiesta Hotels',
    },
    {
      value: 'FX',
      name: 'First Hotels',
    },
    {
      value: '1H',
      name: 'Flair Hotel',
    },
    {
      value: 'FL',
      name: 'Flemings hotels',
    },
    {
      value: 'FB',
      name: 'Fontainebleau',
    },
    {
      value: 'RFBP',
      name: 'Formule 1 Blue Pillow (Accor Group) - RFBP',
    },
    {
      value: 'RTF',
      name: 'Formule 1 Hotels (Accor Group)',
    },
    {
      value: 'FPS',
      name: 'Four Points',
    },
    {
      value: 'FS',
      name: 'Four Seasons Hotels',
    },
    {
      value: 'FI',
      name: 'Frasers Hospitality',
    },
    {
      value: 'YP',
      name: 'Gamma Hotels',
    },
    {
      value: 'GV',
      name: 'Gansevoort Hotel Group',
    },
    {
      value: 'GE',
      name: 'Gaylord Hotels',
    },
    {
      value: 'GZ',
      name: 'Genares Worldwide',
    },
    {
      value: 'GQ',
      name: 'Genre Hotels',
    },
    {
      value: 'GX',
      name: 'Global Conextions',
    },
    {
      value: 'GF',
      name: 'Global Hospitality',
    },
    {
      value: 'GA',
      name: 'Global Hotel Alliance',
    },
    {
      value: 'GT',
      name: 'Golden Tulip',
    },
    {
      value: 'GP',
      name: 'Graduate Hotels',
    },
    {
      value: 'GH',
      name: 'Grand Heritage',
    },
    {
      value: 'GG',
      name: 'GrandLife Hotels',
    },
    {
      value: 'RTGM',
      name: 'Grand Mercure (Accor Group) - RTGM',
    },
    {
      value: 'GS',
      name: 'Grandstay Hospitality',
    },
    {
      value: 'GW',
      name: 'Great Hotels of the World',
    },
    {
      value: 'GC',
      name: 'GRNCONNECT.COM',
    },
    {
      value: 'GUO',
      name: 'Guoman Hotels',
    },
    {
      value: 'H10',
      name: 'H10 Hotels',
    },
    {
      value: 'HX',
      name: 'Hampton Inns (Hilton Group)',
    },
    {
      value: 'H1',
      name: 'Hanting Hotel (Accor Group)',
    },
    {
      value: 'HR',
      name: 'Harrahs Hotels',
    },
    {
      value: 'BH',
      name: 'Hawthorn Suites',
    },
    {
      value: 'H2',
      name: 'Hi Inn (Accor Group)',
    },
    {
      value: 'GI',
      name: 'Hilton Garden Inns (Hilton Group)',
    },
    {
      value: 'HH',
      name: 'Hilton Hotels Corporation (Hilton Group)',
    },
    {
      value: 'HL',
      name: 'Hilton International (Hilton Group)',
    },
    {
      value: 'HIP',
      name: 'Hipotels',
    },
    {
      value: 'HE',
      name: 'Historic Hotels of America',
    },
    {
      value: 'HI',
      name: 'Holiday Inn (Intercontinental Group)',
    },
    {
      value: 'HT',
      name: 'Home 2 Suites by Hilton',
    },
    {
      value: 'HG',
      name: 'Homewood Suites (Hilton Group)',
    },
    {
      value: 'HEO',
      name: 'Hotel 81',
    },
    {
      value: 'RT1',
      name: 'Hotel F1 (Accor Group)',
    },
    {
      value: 'IN',
      name: 'Hotel Indigo (Intercontinental Group)',
    },
    {
      value: '1F',
      name: 'Hotel Provider SRL',
    },
    {
      value: 'HS',
      name: 'Hotel Reservation Service Hotels',
    },
    {
      value: 'HO',
      name: 'Hotelrez',
    },
    {
      value: 'IW',
      name: 'H&ocirc;tels &amp; Pr&eacute;f&eacute;rence',
    },
    {
      value: 'HA',
      name: 'Hotusa Hotels',
    },
    {
      value: 'HJ',
      name: 'Howard Johnson',
    },
    {
      value: 'UL',
      name: 'HUALUXE Hotels and Resorts',
    },
    {
      value: 'HY',
      name: 'Hyatt Hotels',
    },
    {
      value: 'HYP',
      name: 'Hyatt Place',
    },
    {
      value: 'HU',
      name: 'Hyatt Vacation Club',
    },
    {
      value: 'IS',
      name: 'Ian Schrager Hotels',
    },
    {
      value: 'RTI',
      name: 'Ibis (Accor Group)',
    },
    {
      value: 'RBI',
      name: 'Ibis Budget (Accor Group)',
    },
    {
      value: 'IHN',
      name: 'Ibis Hotels (Accor Group)',
    },
    {
      value: 'RIS',
      name: 'Ibis Styles (Accor Group)',
    },
    {
      value: 'TY',
      name: 'Idiso Global distribution',
    },
    {
      value: 'PM',
      name: 'IHG Brand C',
    },
    {
      value: 'CA',
      name: 'ILUNION Hotels',
    },
    {
      value: 'IF',
      name: 'Infinity',
    },
    {
      value: 'IR',
      name: 'Inn Points Reserv',
    },
    {
      value: 'IV',
      name: 'Innvite Hotels',
    },
    {
      value: 'ICH',
      name: 'InterCity Hotels',
    },
    {
      value: 'IC',
      name: 'Intercontinental (Intercontinental Group)',
    },
    {
      value: 'SJ',
      name: 'Jameson Inns',
    },
    {
      value: 'JA',
      name: 'JA Resorts &amp; Hotels',
    },
    {
      value: 'J1',
      name: 'Ji Hotel (Accor Group)',
    },
    {
      value: 'JJ',
      name: 'Jin Jiang Hotels',
    },
    {
      value: 'JV',
      name: 'Joie de Vivre',
    },
    {
      value: 'JO',
      name: 'Joya Hotel (Accor Group)',
    },
    {
      value: 'JR',
      name: 'JR Hotel Group',
    },
    {
      value: 'JT',
      name: 'Jumeirah',
    },
    {
      value: 'JI',
      name: 'Jurys Inns',
    },
    {
      value: 'JWM',
      name: 'JW Marriott',
    },
    {
      value: 'KI',
      name: 'Kempinski Hotels &amp; Resorts',
    },
    {
      value: 'KY',
      name: 'Keytel',
    },
    {
      value: 'KC',
      name: 'Kimpton Hotels &amp; Restaurants',
    },
    {
      value: 'KO',
      name: 'KSL Resorts',
    },
    {
      value: 'LE',
      name: 'L.E. Hotels',
    },
    {
      value: 'LO',
      name: 'Langham Hotels Intl.',
    },
    {
      value: 'LQ',
      name: 'La Quinta Inns',
    },
    {
      value: 'LW',
      name: 'Leading Hotels of the World',
    },
    {
      value: 'LB',
      name: 'lebua Hotels &amp; Resorts',
    },
    {
      value: 'LI',
      name: 'Leisurelink',
    },
    {
      value: 'MD',
      name: 'Le Meridien',
    },
    {
      value: 'LD',
      name: 'Leonardo Hotels',
    },
    {
      value: 'LP',
      name: 'Lexington Collection',
    },
    {
      value: 'LG',
      name: 'Lindner Hotels &amp; Resorts',
    },
    {
      value: 'LA',
      name: 'Linkhotel Africa',
    },
    {
      value: 'LZ',
      name: 'Loews Hotels',
    },
    {
      value: 'NN',
      name: 'Louvre Hotels',
    },
    {
      value: 'HLB',
      name: 'Lucien Barri&egrave;re H&ocirc;tels &amp; Casinos',
    },
    {
      value: 'LC',
      name: 'Luxury Collection',
    },
    {
      value: 'XO',
      name: 'Luxury Resorts',
    },
    {
      value: 'GB',
      name: 'Macdonald Hotels',
    },
    {
      value: 'MG',
      name: 'Magnolia Hotels',
    },
    {
      value: 'MS',
      name: 'Magnuson Hotels',
    },
    {
      value: 'MZ',
      name: 'Mainstay Suites',
    },
    {
      value: 'AMS',
      name: 'Mama Shelter (Accor Group)',
    },
    {
      value: 'MO',
      name: 'Mandarin Oriental Hotel',
    },
    {
      value: 'MP',
      name: 'Mantra Group',
    },
    {
      value: 'MH',
      name: 'Marco Polo Hotels',
    },
    {
      value: 'MM',
      name: 'Maritim Hotels',
    },
    {
      value: 'ET',
      name: 'Marriott Conference Centers',
    },
    {
      value: 'EE',
      name: 'Marriott ExecuStay',
    },
    {
      value: 'MC',
      name: 'Marriott Hotels &amp; Resorts',
    },
    {
      value: 'VC',
      name: 'Marriott Vacation Club',
    },
    {
      value: 'VY',
      name: 'Maybourne Hotel Group',
    },
    {
      value: 'SM',
      name: 'Melia Hotels',
    },
    {
      value: 'RTM',
      name: 'Mercure Hotels (Accor Group)',
    },
    {
      value: 'GM',
      name: 'Meritus Hotels',
    },
    {
      value: 'RTMG',
      name: 'MGallery (Accor Group) - RTMG',
    },
    {
      value: 'MV',
      name: 'MGM Mirage Hotels',
    },
    {
      value: 'MT',
      name: 'Microtel Inns &amp; Suites',
    },
    {
      value: 'MU',
      name: 'Millennium Copthorne',
    },
    {
      value: 'OH',
      name: 'Minor Hotels',
    },
    {
      value: 'MN',
      name: 'Montage Hotels',
    },
    {
      value: 'MR',
      name: 'Morgans Hotel Group',
    },
    {
      value: 'MX',
      name: 'Motel 6 Hotels',
    },
    {
      value: 'MTO',
      name: 'Motel One',
    },
    {
      value: 'UA',
      name: 'Motto by Hilton',
    },
    {
      value: 'MK',
      name: 'M&ouml;venpick Hotels',
    },
    {
      value: 'OX',
      name: 'Moxy Hotels',
    },
    {
      value: 'IQ',
      name: 'MyFidelio.Net',
    },
    {
      value: 'NO',
      name: 'New Otani Hotels',
    },
    {
      value: 'NW',
      name: 'New World Hotels',
    },
    {
      value: 'SD',
      name: 'Next Hotels',
    },
    {
      value: 'NS',
      name: 'NH Hotel Group',
    },
    {
      value: 'NG',
      name: 'Night Hotels',
    },
    {
      value: 'NK',
      name: 'Nikko',
    },
    {
      value: 'NC',
      name: 'Noble House Hotels',
    },
    {
      value: 'NB',
      name: 'Nobu Hotels',
    },
    {
      value: 'NU',
      name: 'Northwood Hospitality',
    },
    {
      value: 'RTN',
      name: 'Novotel (Accor Group)',
    },
    {
      value: 'RTU',
      name: 'Novotel Suites (Accor Group)',
    },
    {
      value: 'OB',
      name: 'Oberoi',
    },
    {
      value: 'OT',
      name: 'Oetker Collection',
    },
    {
      value: 'OC',
      name: 'Okura Hotels',
    },
    {
      value: 'OD',
      name: 'Omnibees',
    },
    {
      value: 'OM',
      name: 'Omni Hotels',
    },
    {
      value: 'OO',
      name: 'One and Only',
    },
    {
      value: 'RTO',
      name: 'Orbis (Accor Group)',
    },
    {
      value: 'OR',
      name: 'Outrigger Hotels',
    },
    {
      value: 'PF',
      name: 'Pan Pacific',
    },
    {
      value: 'PG',
      name: 'Park Hotel Group',
    },
    {
      value: 'PD',
      name: 'Park Inn',
    },
    {
      value: 'PK',
      name: 'Park Plaza',
    },
    {
      value: 'PL',
      name: 'Parkroyal Hotels',
    },
    {
      value: 'UI',
      name: 'Pegasus Connect Plus',
    },
    {
      value: 'UZ',
      name: 'Pegasus Connect',
    },
    {
      value: 'XT',
      name: 'Pegasus',
    },
    {
      value: 'PT',
      name: 'pentahotels',
    },
    {
      value: 'MY',
      name: 'Personality Hotels',
    },
    {
      value: 'BC',
      name: 'Preferred Boutique',
    },
    {
      value: 'PH',
      name: 'Preferred Hotels &amp; Resorts',
    },
    {
      value: 'PI',
      name: 'Premier Inn',
    },
    {
      value: 'PA',
      name: 'Principal Hayley Hotels',
    },
    {
      value: 'PR',
      name: 'Protea Hotels',
    },
    {
      value: 'RTP',
      name: 'Pullman Hotels and Resorts (Accor Group)',
    },
    {
      value: 'QF',
      name: 'Qantas Airways Hotels',
    },
    {
      value: 'QH',
      name: 'QHotels',
    },
    {
      value: 'QT',
      name: 'QT Hotels &amp; Resorts',
    },
    {
      value: 'QI',
      name: 'Quality Inn',
    },
    {
      value: 'QR',
      name: 'Quality Reservations',
    },
    {
      value: 'QG',
      name: 'Quest Serviced Apartments',
    },
    {
      value: 'RD',
      name: 'Radisson Hotels',
    },
    {
      value: 'YR',
      name: 'Raffles Hotels (Accor Group)',
    },
    {
      value: 'RA',
      name: 'Ramada',
    },
    {
      value: 'RL',
      name: 'Red Lion Hotels',
    },
    {
      value: 'RF',
      name: 'Red Roof Inns',
    },
    {
      value: 'RE',
      name: 'Regent Hotels',
    },
    {
      value: 'WB',
      name: 'Relais et Chateaux',
    },
    {
      value: 'REH',
      name: 'Relexa Hotels',
    },
    {
      value: 'BR',
      name: 'Renaissance Hotels',
    },
    {
      value: 'TA',
      name: 'Reservhotel',
    },
    {
      value: 'RH',
      name: 'Reshub',
    },
    {
      value: 'RC',
      name: 'Residence Inn (Marriott Group)',
    },
    {
      value: 'RZ',
      name: 'Ritz-Carlton',
    },
    {
      value: 'RX',
      name: 'Rixos Hotels (Accor Group)',
    },
    {
      value: 'FC',
      name: 'Rocco Forte Hotels',
    },
    {
      value: 'RY',
      name: 'Rodeway Inn',
    },
    {
      value: 'RMH',
      name: 'Romantik Hotels',
    },
    {
      value: 'RW',
      name: 'Rosewood Hotels',
    },
    {
      value: 'RO',
      name: 'Rotana Hotels &amp; Resorts',
    },
    {
      value: 'RG',
      name: 'Rydges',
    },
    {
      value: 'PS',
      name: 'Sandman Hotels',
    },
    {
      value: 'SV',
      name: 'Sarova Hotels',
    },
    {
      value: 'SH',
      name: 'Scandic',
    },
    {
      value: 'SC',
      name: 'Sceptre Hospitality',
    },
    {
      value: '1E',
      name: 'Seminaris Hotels and Meeting Resorts',
    },
    {
      value: 'SE',
      name: 'Sercotel Hoteles',
    },
    {
      value: 'SG',
      name: 'Shangri-La Hotels',
    },
    {
      value: 'SI',
      name: 'Sheraton',
    },
    {
      value: 'RM',
      name: 'Shiji Group',
    },
    {
      value: 'GD',
      name: 'SiteMinder',
    },
    {
      value: 'SK',
      name: 'SIXTY Hotels',
    },
    {
      value: 'SZ',
      name: 'Sleep Inn',
    },
    {
      value: 'SDH',
      name: 'Small Danish Hotels',
    },
    {
      value: 'LX',
      name: 'Small Luxury Hotels',
    },
    {
      value: 'RTS',
      name: 'Sofitel Hotels (Accor Group)',
    },
    {
      value: 'RTSL',
      name: 'Sofitel Legend (Accor Group) - RTSL',
    },
    {
      value: 'SO',
      name: 'Sokos Hotels',
    },
    {
      value: 'SN',
      name: 'Sonesta',
    },
    {
      value: 'SRT',
      name: 'Sorat Hotels',
    },
    {
      value: 'RTSS',
      name: 'SO Sofitel (Accor Group) - RTSS',
    },
    {
      value: 'SP',
      name: 'Special Properties (InterContinental Hotels Group)',
    },
    {
      value: 'XV',
      name: 'Springhill Suites (Marriott Group)',
    },
    {
      value: 'XR',
      name: 'St. Regis',
    },
    {
      value: 'AP',
      name: 'Standard International',
    },
    {
      value: 'SY',
      name: 'Starhotels',
    },
    {
      value: 'ZZ',
      name: 'Star Inn',
    },
    {
      value: 'YZ',
      name: 'Staybridge Suites (Intercontinental Group)',
    },
    {
      value: 'SS',
      name: 'Studio 6',
    },
    {
      value: 'UB',
      name: 'Suburban Extended Stay Hotels',
    },
    {
      value: 'XL',
      name: 'Summit Hotels &amp; Resorts',
    },
    {
      value: 'SQ',
      name: 'Sun International',
    },
    {
      value: 'OZ',
      name: 'Super 8',
    },
    {
      value: 'SX',
      name: 'Supranational',
    },
    {
      value: 'SF',
      name: 'Sutton Place',
    },
    {
      value: 'SL',
      name: 'Swissotel (Accor Group)',
    },
    {
      value: 'QO',
      name: 'Swiss Quality',
    },
    {
      value: '1C',
      name: 'Switzerland Travel Centre',
    },
    {
      value: 'YX',
      name: 'SynXis Corporation',
    },
    {
      value: 'TJ',
      name: 'Taj Hotels',
    },
    {
      value: 'TD',
      name: 'TFE Hotels',
    },
    {
      value: 'AT',
      name: 'The Address',
    },
    {
      value: 'BA',
      name: 'The Dedica Anthology Hotels',
    },
    {
      value: 'JD',
      name: 'The Doyle Collection',
    },
    {
      value: 'LJ',
      name: 'The Lalit',
    },
    {
      value: 'LR',
      name: 'The Leela Palace Hotels',
    },
    {
      value: 'LU',
      name: 'The Montcalm London Hotels',
    },
    {
      value: 'OG',
      name: 'The Originals, Human Hotels &amp; Resorts',
    },
    {
      value: 'PN',
      name: 'The Peninsula Hotels',
    },
    {
      value: 'SEB',
      name: 'The Sebel (Accor Group)',
    },
    {
      value: 'TS',
      name: 'The Set Collection',
    },
    {
      value: 'TI',
      name: 'Thistle Hotels',
    },
    {
      value: 'TM',
      name: 'Thompson Hotels',
    },
    {
      value: 'TF',
      name: 'Thon Hotels',
    },
    {
      value: 'IE',
      name: 'tommie Hotels',
    },
    {
      value: 'TP',
      name: 'Top International Hotels',
    },
    {
      value: 'TO',
      name: 'Towneplace Suites (Marriott Group)',
    },
    {
      value: 'TL',
      name: 'Travelodge',
    },
    {
      value: 'TG',
      name: 'Travelodge UK',
    },
    {
      value: 'XZ',
      name: 'TravelportHotelzon',
    },
    {
      value: 'TX',
      name: 'Tribute Portfolio',
    },
    {
      value: 'TH',
      name: 'Trident Hotels',
    },
    {
      value: 'TW',
      name: 'Trump Hotel Collection',
    },
    {
      value: 'YH',
      name: 'Trust Booking Service',
    },
    {
      value: 'WT',
      name: 'Tryp by Wyndham',
    },
    {
      value: 'SU',
      name: 'Tsogo Sun Hotels',
    },
    {
      value: 'I7',
      name: 'Van der Valk Hotels',
    },
    {
      value: 'LM',
      name: 'Vantis Hotel Group',
    },
    {
      value: 'VE',
      name: 'Vantis Hotels',
    },
    {
      value: 'VG',
      name: 'Viceroy Hotel Group',
    },
    {
      value: 'VI',
      name: 'Vienna House',
    },
    {
      value: 'DV',
      name: 'Village Urban Resorts',
    },
    {
      value: 'VL',
      name: 'Virgin Hotels',
    },
    {
      value: 'WA',
      name: 'Waldorf Astoria (Hilton Group)',
    },
    {
      value: 'WK',
      name: 'Warwick Hotels and Resorts',
    },
    {
      value: 'WI',
      name: 'Westin Hotels',
    },
    {
      value: 'WH',
      name: 'W Hotels &amp; Resorts',
    },
    {
      value: 'WG',
      name: 'Wingate by Wyndham',
    },
    {
      value: 'WD',
      name: 'Woodfin Suite Hotels',
    },
    {
      value: 'XW',
      name: 'WorldHotels Collection',
    },
    {
      value: 'WW',
      name: 'Worldhotels',
    },
    {
      value: 'WO',
      name: 'Wyndham Extra Holidays',
    },
    {
      value: 'WY',
      name: 'Wyndham Hotels and Resorts',
    },
    {
      value: 'ST',
      name: 'Xenia Hotellerie',
    },
    {
      value: 'XN',
      name: 'Xn protel systems',
    },
    {
      value: 'YL',
      name: 'Yotel',
    },
    {
      value: 'ZA',
      name: 'ZaZa Hotels',
    },
    {
      value: 'PW',
      name: 'Elite Hotels',
    },
  ],
  train: [
    { name: 'SJ Prio', value: 'SJR' },
    { name: 'VR', value: 'MTR' },
  ],
  flight: [
    {
      name: 'AAdvantage',
      value: 'AA',
    },
    {
      name: 'ANA-Mileage Club',
      value: 'NH',
    },
    {
      name: 'AerClub',
      value: 'EI',
    },
    {
      name: 'Aerolineas Plus',
      value: 'AR',
    },
    {
      name: 'Aeroplan',
      value: 'AC',
    },
    {
      name: 'Air Baltic Club',
      value: 'BT',
    },
    {
      name: 'Air Rewards',
      value: '3O',
    },
    {
      name: 'Airpoints',
      value: 'NZ',
    },
    {
      name: 'Alfursan',
      value: 'SV',
    },
    {
      name: 'AngkorWards',
      value: 'K6',
    },
    {
      name: 'Asiana Club',
      value: 'OZ',
    },
    {
      name: 'Atlas MIles',
      value: 'KK',
    },
    {
      name: 'Awards Plus',
      value: 'PK',
    },
    {
      name: 'Azal Miles',
      value: 'J2',
    },
    {
      name: 'BIman Loyalty Club',
      value: 'BG',
    },
    {
      name: 'BRA Vänner',
      value: 'TF',
    },
    {
      name: 'Belavia Leader',
      value: 'B2',
    },
    {
      name: 'BinterMas',
      value: 'NT',
    },
    {
      name: 'Blue Sky',
      value: 'OM',
    },
    {
      name: 'Bolbol',
      value: 'PC',
    },
    {
      name: 'Bonus',
      value: 'SU',
    },
    {
      name: 'Carribian Plus',
      value: 'BW',
    },
    {
      name: 'Cedar Miles',
      value: 'ME',
    },
    {
      name: 'Champa Muang Lao',
      value: 'QV',
    },
    {
      name: 'Clan Loganair',
      value: 'LM',
    },
    {
      name: 'Club Flamingo',
      value: 'TM',
    },
    {
      name: 'Club Premier',
      value: 'AM',
    },
    {
      name: 'Club Tiare',
      value: 'VT',
    },
    {
      name: 'ConnectMiles',
      value: 'CM',
    },
    {
      name: 'Destinations Loyalty',
      value: 'PX',
    },
    {
      name: 'Dream Miles',
      value: 'WB',
    },
    {
      name: 'Eastern Miles',
      value: 'MU',
    },
    {
      name: 'Easy to Fly',
      value: 'Y7',
    },
    {
      name: 'Egret Miles',
      value: 'MF',
    },
    {
      name: 'EgyptAir Plus',
      value: 'MS',
    },
    {
      name: 'Enrich',
      value: 'MH',
    },
    {
      name: 'Etihad Guest',
      value: 'EY',
    },
    {
      name: 'Etihad Guest',
      value: 'HM',
    },
    {
      name: 'EuroBonus',
      value: 'SK',
    },
    {
      name: 'EuroBonus',
      value: 'WF',
    },
    {
      name: 'Executive Club',
      value: 'BA',
    },
    {
      name: 'Falconflyer',
      value: 'GF',
    },
    {
      name: 'Fidelys',
      value: 'TU',
    },
    {
      name: 'Fly More',
      value: 'FB',
    },
    {
      name: 'Fly Smiles',
      value: 'UL',
    },
    {
      name: 'Flyer Bonus',
      value: 'PG',
    },
    {
      name: 'Flying Blue',
      value: 'AF',
    },
    {
      name: 'Flying Blue',
      value: 'KL',
    },
    {
      name: 'Flying Blue',
      value: 'RO',
    },
    {
      name: 'Flying Blue',
      value: 'TY',
    },
    {
      name: 'Flying Club',
      value: 'VS',
    },
    {
      name: 'Flying Return',
      value: 'AI',
    },
    {
      name: 'Flypass',
      value: 'KM',
    },
    {
      name: 'Fortune Wings ',
      value: 'HU',
    },
    {
      name: 'Fortune Wings Club',
      value: 'HX',
    },
    {
      name: 'Garuda Miles',
      value: 'GA',
    },
    {
      name: 'Hawaiian Miles',
      value: 'HA',
    },
    {
      name: 'Iberia Plus',
      value: 'IB',
    },
    {
      name: 'Infinity MileageLands',
      value: 'BR',
    },
    {
      name: 'JAL Mileage Bank',
      value: 'JL',
    },
    {
      name: 'Jasmin',
      value: 'BJ',
    },
    {
      name: 'Kavkea',
      value: 'TN',
    },
    {
      name: 'Kesterflyer',
      value: 'MK',
    },
    {
      name: 'KrisFlyer',
      value: 'SQ',
    },
    {
      name: 'Latam Pass',
      value: 'JJ',
    },
    {
      name: 'Lifemiles',
      value: 'V5',
    },
    {
      name: 'Lotus Miles',
      value: 'VN',
    },
    {
      name: 'Mabuhay Miles',
      value: 'PR',
    },
    {
      name: 'MadaCamp',
      value: 'MD',
    },
    {
      name: 'Marco Polo',
      value: 'CX',
    },
    {
      name: 'Matmid Club',
      value: 'LY',
    },
    {
      name: 'MileagePlus',
      value: 'UA',
    },
    {
      name: 'Miles and Go',
      value: 'TP',
    },
    {
      name: 'Miles and More',
      value: 'DE',
    },
    {
      name: 'Miles and More',
      value: 'LG',
    },
    {
      name: 'Miles and More',
      value: 'LH',
    },
    {
      name: 'Miles and More',
      value: 'LO',
    },
    {
      name: 'Miles and More',
      value: 'LX',
    },
    {
      name: 'Miles and More',
      value: 'OS',
    },
    {
      name: 'Miles and More',
      value: 'OU',
    },
    {
      name: 'Miles and More',
      value: 'SN',
    },
    {
      name: 'Miles and Smiles',
      value: 'TK',
    },
    {
      name: 'Miles+Bonus',
      value: 'A3',
    },
    {
      name: 'Miles+Bonus',
      value: 'OA',
    },
    {
      name: 'MilleMiglia',
      value: 'AZ',
    },
    {
      name: 'MyAirItalyClub',
      value: 'IG',
    },
    {
      name: 'Ndege Plus',
      value: 'KQ',
    },
    {
      name: 'Nomad Club',
      value: 'KC',
    },
    {
      name: 'Norwegian Reward',
      value: 'DY',
    },
    {
      name: 'OK Plus',
      value: 'OK',
    },
    {
      name: 'Oasis Club',
      value: 'KU',
    },
    {
      name: 'OnurExtra',
      value: '8Q',
    },
    {
      name: 'Panorama Club',
      value: 'PS',
    },
    {
      name: 'Phoenix Miles',
      value: 'CA',
    },
    {
      name: 'Phoenix Miles',
      value: 'NX',
    },
    {
      name: 'Preference',
      value: 'TX',
    },
    {
      name: 'Privilege Club',
      value: 'QR',
    },
    {
      name: 'Qantas Points',
      value: 'QF',
    },
    {
      name: 'Reward$',
      value: 'SW',
    },
    {
      name: 'Royal Club',
      value: 'RJ',
    },
    {
      name: 'Royal Orchid Plus',
      value: 'TG',
    },
    {
      name: 'Royal Skies',
      value: 'BI',
    },
    {
      name: 'S7 Priority',
      value: 'S7',
    },
    {
      name: 'SCAL Golden Panda Club',
      value: '3U',
    },
    {
      name: 'Safar Flyer',
      value: 'AT',
    },
    {
      name: 'Saga Club',
      value: 'FI',
    },
    {
      name: 'Sata Imagine',
      value: 'S4',
    },
    {
      name: 'ShebaMiles',
      value: 'ET',
    },
    {
      name: 'Sinbad',
      value: 'WY',
    },
    {
      name: 'Sky Miles',
      value: 'DL',
    },
    {
      name: 'Sky Pearl Club',
      value: 'CZ',
    },
    {
      name: 'Skypass',
      value: 'KE',
    },
    {
      name: 'Skywards',
      value: 'EK',
    },
    {
      name: 'Skywards',
      value: 'FZ',
    },
    {
      name: 'Suma',
      value: 'UX',
    },
    {
      name: 'Tabuna Club',
      value: 'FJ',
    },
    {
      name: 'Teemane Club',
      value: 'BP',
    },
    {
      name: 'TudoAzul',
      value: 'AD',
    },
    {
      name: 'Umbi Umbi Club',
      value: 'DT',
    },
    {
      name: 'UzAirPlus',
      value: 'HY',
    },
    {
      name: 'Velocity',
      value: 'VA',
    },
    {
      name: 'Vision Team',
      value: 'YM',
    },
    {
      name: 'Voyager',
      value: '4Z',
    },
    {
      name: 'Voyager',
      value: 'SA',
    },
    {
      name: 'Vueling Club',
      value: 'VY',
    },
    {
      name: 'Wings',
      value: 'U6',
    },
    {
      name: 'Finnair plus',
      value: 'AY',
    },
  ],
  car: [
    { value: 'EP', name: 'Europcar' },
    { value: 'ZD', name: 'Budget' },
    { value: 'ZI', name: 'Avis' },
    { value: 'ZE', name: 'Hertz' },
    { value: 'SX', name: 'Sixt' },
    { value: 'ZL', name: 'National' },
    { value: 'MB', name: 'Mabi' },
  ],
}
export const loyaltySegmentsByCode = {
  1: 'flight',
  7: 'train',
  3: 'hotel',
  2: 'car',
}
