import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Box,
  Typography,
  makeStyles,
  useTheme,
  Button,
  Grid,
  Select,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ModalHook from './reusable/modalHook'
import { useTranslation } from 'react-i18next'
import { fetchUpdateUserInfo } from '../../repositories/auth'
import { allUsersAction } from '../../store/users/userAction'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  dateInput: {
    borderRadius: '4px',
    '& .MuiOutlinedInput-input': {
      padding: '17px 14px',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
  },
  headerBlock: {
    width: '100%',
    paddingBottom: ({ isOwnProfile }) =>
      isOwnProfile ? theme.spacing(3) : '27px',
    marginBottom: ({ isOwnProfile }) =>
      isOwnProfile ? '24px' : theme.spacing(4),
    borderBottom: 'solid 1px #e6e6e6',
    textAlign: 'left',
  },
  heading: {
    fontSize: theme.spacing(3),
    lineHeight: 'normal',
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
  },
  textBlock: {
    width: '100%',
    textAlign: 'left',
  },
  text: {
    lineHeight: 'normal',
    fontSize: theme.spacing(2),
    marginBottom: ({ isOwnProfile }) => (isOwnProfile ? '34px' : '12px'),
    width: '100%',
    textAlign: 'left',
    color: theme.palette.common.black,
  },
  errorText: {
    fontSize: '13px',
    color: '#e30f0f',
    lineHeight: 'normal',
    textAlign: 'left',
  },
  btn: ({ isOwnProfile }) => ({
    fontSize: isOwnProfile ? '14px' : theme.spacing(2),
    letterSpacing: '1.1px',
    width: isOwnProfile ? 'auto' : '148px',
    fontWeight: theme.typography.fontWeightSemiBold,
    marginRight: isOwnProfile ? '25px' : 0,
    '&.dob-save-btn': {
      width: '200px',
      fontSize: theme.spacing(2),
      borderRadius: '20px',
    },
  }),
  ageRadio: {
    padding: '15px 9px',
  },
  buttonsBlock: ({ isOwnProfile }) =>
    isOwnProfile
      ? {
          textAlign: 'right',
        }
      : {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
}))

const month = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

const SelectUserDOBModal = ({ user, onSave }) => {
  const { user: authUser } = useSelector((state) => state.auth)
  const isOwnProfile = user?.uniqueId && user.uniqueId === authUser.uniqueId
  const classes = useStyles({ isOwnProfile })
  const theme = useTheme()
  const { t } = useTranslation()
  const [date, setDate] = useState({ y: '1980', m: '01', d: '01' })
  const [error, setError] = useState(null)
  const [manualAge, setManualAge] = useState(20)
  const [ageType, setAgeType] = useState('adult')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const currYear = parseInt(moment().format('YYYY'))
  const minYear = currYear - 80
  const days = moment(`${date.y}-${date.m}`, 'YYYY-MM').daysInMonth()
  const dispatch = useDispatch()

  useEffect(() => {
    const age = parseInt(user?.age || 30)

    setError(null)
    setManualAge(!age || age > 25 ? 20 : age)
    setAgeType(age >= 65 ? 'senior' : age < 26 ? 'youth' : 'adult')
  }, [user])

  const blurActive = () => {
    document.activeElement.blur()
  }

  const onSelect = () => {
    const resUser = isOwnProfile
      ? prepareDobSelection()
      : prepareManualSelection()
    if (!resUser) return
    onSave(resUser)
  }

  const prepareManualSelection = () => {
    return {
      ...user,
      age: ageType === 'senior' ? 65 : ageType === 'adult' ? 30 : manualAge,
    }
  }

  const prepareDobSelection = () => {
    if (!date.y || !date.m || !date.d) {
      setError('Invalid date')
      return
    }
    const tmpDate = new moment(`${date.y}-${date.m}-${date.d}`, 'YYYY-MM-DD')
    const years = moment().diff(tmpDate, 'years')
    if (years < 18) {
      setError('Too yong')
      return
    }
    if (isOwnProfile) {
      updateProfileDob(tmpDate)
    }
    return { ...user, age: years, birthDate: tmpDate.format('YYYY-MM-DD') }
  }

  const updateProfileDob = async (date) => {
    try {
      await fetchUpdateUserInfo({
        section: 'more',
        updateData: {
          customerGeneral: {
            birthDate: date.format('YYYY-MM-DD'),
          },
        },
      })
      dispatch(allUsersAction(false))
    } catch (e) {
      console.log('Profile DOB update failed')
    }
  }

  const onCancel = () => {
    const resUser = { ...user }
    if (!resUser?.age) {
      resUser.age = 30
    }
    onSave(resUser)
  }

  const getDays = () => {
    const opts = []
    for (let d = 1; d <= days; d++) {
      const day = d < 10 ? `0${d}` : d.toString()
      opts.push(
        <option key={'day-' + day} value={day}>
          {day}
        </option>
      )
    }
    return opts
  }

  const getYouthAges = () => {
    const opts = []
    for (let age = 25; age >= 12; age--) {
      opts.push(
        <option key={'age-' + age} value={age}>
          {age}
        </option>
      )
    }
    return opts
  }

  const getMonth = () => {
    return month.map((m, i) => {
      const mKey = i < 9 ? `0${i + 1}` : (i + 1).toString()
      return (
        <option key={'month-' + mKey} value={mKey}>
          {t(m)}
        </option>
      )
    })
  }

  const getYears = () => {
    const opts = []
    for (let y = currYear - 14; y >= minYear; y--) {
      opts.push(
        <option key={'year-' + y} value={y.toString()}>
          {y.toString()}
        </option>
      )
    }
    return opts
  }

  const renderError = () => {
    return !error ? null : (
      <Grid item xs={12}>
        <Box className={classes.errorText}>{error}</Box>
      </Grid>
    )
  }

  const renderDobForm = () => (
    <Grid container spacing={0}>
      <Grid item xs={4}>
        <Box mr={0.5}>
          <Select
            native
            fullWidth
            value={date.y}
            variant={'outlined'}
            className={classes.dateInput}
            onChange={(e) => {
              blurActive()
              setDate({ ...date, y: e.target.value, d: '01' })
            }}
            IconComponent={KeyboardArrowDownIcon}
          >
            {getYears()}
          </Select>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box mx={0.5}>
          <Select
            native
            fullWidth
            value={date.m}
            variant={'outlined'}
            className={classes.dateInput}
            onChange={(e) => {
              blurActive()
              setDate({ ...date, m: e.target.value, d: '01' })
            }}
            IconComponent={KeyboardArrowDownIcon}
          >
            {getMonth()}
          </Select>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box ml={0.5}>
          <Select
            native
            fullWidth
            value={date.d}
            variant={'outlined'}
            className={classes.dateInput}
            onChange={(e) => {
              blurActive()
              setDate({ ...date, d: e.target.value })
            }}
            IconComponent={KeyboardArrowDownIcon}
          >
            {getDays()}
          </Select>
        </Box>
      </Grid>
      {renderError()}
    </Grid>
  )

  const renderAgeForm = () => (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box>
          <RadioGroup
            value={ageType}
            onChange={(e) => setAgeType(e.target.value)}
          >
            <FormControlLabel
              value="adult"
              control={<Radio className={classes.ageRadio} color={'primary'} />}
              label={t('adult')}
            />
            <FormControlLabel
              value="youth"
              control={<Radio className={classes.ageRadio} color={'primary'} />}
              label={t('youth') + ' (12 - 25)'}
            />

            {ageType === 'youth' && (
              <Select
                native
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                value={manualAge}
                variant={'outlined'}
                className={classes.dateInput}
                onChange={(e) => {
                  blurActive()
                  setManualAge(e.target.value)
                }}
              >
                {getYouthAges()}
              </Select>
            )}

            <FormControlLabel
              value="senior"
              control={<Radio className={classes.ageRadio} color={'primary'} />}
              label={t('senior')}
            />
          </RadioGroup>
        </Box>
      </Grid>
      {renderError()}
    </Grid>
  )

  const title = isOwnProfile
    ? t('enter dob')
    : `${user?.firstName || ''} ${user?.lastName || ''}`
  const text = isOwnProfile ? t('dob purpose') : t('ticket type')
  return (
    <ModalHook
      onClose={onCancel}
      mobileFullWidth={false}
      modalBoolean={!!user}
      width={isOwnProfile ? 600 : 350}
      noBackdrop
    >
      <Box
        px={isMobile ? 2 : '20px'}
        py={isOwnProfile ? '35px' : '40px'}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box className={classes.headerBlock}>
          <Typography className={classes.heading}>{title}</Typography>
        </Box>
        <Box className={classes.textBlock}>
          <Typography className={classes.text}>{text}</Typography>
        </Box>

        <Box mb={'25px'} width={'100%'} position={'relative'}>
          {isOwnProfile ? renderDobForm() : renderAgeForm()}
        </Box>

        <Box width={'100%'} className={classes.buttonsBlock}>
          <Button
            fontWeight={'bold'}
            onClick={onCancel}
            className={classes.btn}
            disableElevation
            color={isOwnProfile ? 'default' : 'primary'}
            variant={isOwnProfile ? 'default' : 'outlined'}
          >
            {t('skip')}
          </Button>
          <Button
            onClick={onSelect}
            className={`${classes.btn} ${isOwnProfile ? 'dob-save-btn' : ''}`}
            disableElevation
            color="primary"
            variant="contained"
          >
            {t('save btn')}
          </Button>
        </Box>
      </Box>
    </ModalHook>
  )
}

export default SelectUserDOBModal
