import React, { memo } from 'react'
import _ from 'lodash'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Seat from '../../../icons/Seat'
import OccupiedSeat from '../../../icons/occupiedSeat'
import CustomTooltip from '../../../reusable/tooltip'
import { useSelector } from 'react-redux'
import usePriceFormat from '../../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  seat: {
    cursor: 'pointer',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const CellSeat = ({ col, selectSeat, flightKey, passengerSeats, passengers }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { exists, busy, name, y, userKey } = col
  const { formatPrice } = usePriceFormat()

  const isSelected = passengerSeats.find(
    (x) => x.seat === col.seat && flightKey === x.flightKey
  )
  const usrInd = isSelected
    ? _.findIndex(passengers, { uniqueId: isSelected.userId })
    : -1

  let seat = busy ? (
    <OccupiedSeat />
  ) : isSelected ? (
    <Seat
      userIndex={usrInd}
      userKey={userKey}
      color={theme.palette.primary.main}
    />
  ) : (
    <Seat />
  )

  // Rows in middle
  if (name && !exists) {
    seat = ''
  } else if (!name) {
    seat = name ? seat : <Typography className={classes.weight}>{y}</Typography>
  }
  //let classItem = name || busy ? '' : 'zero'

  return (
    <CustomTooltip
      title={`${col.seat} ${formatPrice(col.price, { appendCurrency: true })}`}
      placement="top"
    >
      <Box
        onClick={() => selectSeat({ seat: col, flightKey })}
        className={classes.seat}
      >
        {seat}
      </Box>
    </CustomTooltip>
  )
}

export default memo(CellSeat)
