import React, { memo } from 'react';
import ModalHook from "./reusable/modalHook";
import ModalEditInfo from "../profile/modalEditInfo";

const EditInfoModal = ({modalBoolean, rows}) => (
  <ModalHook height={rows.height} width={840} modalBoolean={modalBoolean}>
    <ModalEditInfo rows={rows}/>
  </ModalHook>
)

export default memo(EditInfoModal);
