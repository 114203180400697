import React, { memo } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

const BookingConfirmation = () => {
  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={7}>
          Booking information
        </Grid>
        <Grid item xs={12} sm={5}>
          View Handler
        </Grid>
      </Grid>
    </Container>
  )
}

export default memo(BookingConfirmation)
