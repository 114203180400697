import React from 'react'
import { Box, Typography, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  hotelPayNotify: {
    backgroundColor: 'rgba(255,239,214,0.5)',
  },
  noteText: (props) => ({
    fontSize: '16px',
    lineHeight: '1.5',
    ...(props?.textOuter || {})
  }),
  outerPaper: (props) => ({
    ...(props?.outer || {})
  }),

  contentOuter: (props) => ({
    padding: '16px',
    ...(props?.inner || {})
  }),
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  boldest: {
    fontWeight: theme.typography.fontWeightBold,
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
  },
}))

const NotifyBlock = ({ noSubNote, noteType, noteText, customStyles }) => {
  const classes = useStyles(customStyles)
  const { t } = useTranslation()
  const textStyle = customStyles?.text
  return (
    <Box>
      {!!noteType && classes[noteType] && (
        <Paper elevation={0} className={`${classes[noteType]} ${classes.outerPaper}`}>
          <Box className={classes.contentOuter}>
            <Typography className={classes.noteText}>
              <span className={classes.boldest}>
                {!noSubNote ? `${t('please note')}:` : ''}{' '}
              </span>
              <span style={textStyle || {}} className={noteType === 'paper' && !textStyle ? classes.bold : null}>
                {noteText}
              </span>
            </Typography>
          </Box>
        </Paper>
      )}
    </Box>
  )
}
export default NotifyBlock
