import {
  RESET_SEARCH_FILTERS,
  RESET_SEARCH_PARAMETERS,
  RESET_SEARCH_TRAVELLERS,
  SET_CAR_SEARCH_CACHE,
  SET_CAR_SEARCH_PARAMETERS,
  SET_COMPANIES_FILTERS,
  SET_DURATION_FILTERS,
  SET_FILTERS,
  SET_FLIGHT_SEARCH_PARAMETERS,
  SET_HOTEL_SEARCH_PARAMETERS,
  SET_STOP_FILTERS,
  SET_TIME_FILTERS,
  SET_TRAIN_SEARCH_PARAMETERS,
  SET_SEARCH_TRAVELLERS, DISABLE_EXTEND_CHECKOUT,
} from './constants'

export const setSearchTravellers = (travellers) => ({
  type: SET_SEARCH_TRAVELLERS,
  payload: travellers,
})

export const setTrainSearchParameters = (
  from,
  to,
  departure,
  arrival,
  travellers,
  travelerCounts = {},
  extendCheckout = false
) => {
  return {
    type: SET_TRAIN_SEARCH_PARAMETERS,
    payload: {
      from,
      to,
      departure,
      arrival,
      travellers,
      travelerCounts,
      extendCheckout
    },
  }
}

export const setHotelSearchParameters = (
  cityValue,
  city,
  checkIn,
  checkOut,
  travellers,
  roomCount,
  travelerCounts = {},
  multistep = false
) => {
  return {
    type: SET_HOTEL_SEARCH_PARAMETERS,
    payload: {
      cityValue,
      city,
      checkIn,
      checkOut,
      travellers,
      roomCount,
      travelerCounts,
      multistep
    },
  }
}

/**
 *
 * @param from
 * @param to
 * @param departure
 * @param arrival
 * @param travellers
 * @param travelerCounts
 * @param ticketClass
 * @param extendCheckout is it a new search or adding to an existed checkout
 * @returns {{payload: {ticketClass: null, travellers, travelerCounts: {}, arrival, from, to, departure}, type: string}}
 */
export const setFlightSearchParameters = (
  from,
  to,
  departure,
  arrival,
  travellers,
  travelerCounts = {},
  ticketClass = null,
  extendCheckout = false
) => ({
  type: SET_FLIGHT_SEARCH_PARAMETERS,
  payload: {
    from,
    to,
    departure,
    arrival,
    travellers,
    travelerCounts,
    ticketClass,
    extendCheckout
  },
})

/**
 * Set cars search params
 * @param from
 * @param to
 * @param departure
 * @param arrival
 * @param travellers
 * @param difPlace use different drop-off location
 * @param searchCache
 * @returns {{payload: {travellers, arrival, from, to, departure}, type: string}}
 */
export const setCarSearchParameters = (
  from,
  to,
  departure,
  arrival,
  travellers,
  difPlace
) => ({
  type: SET_CAR_SEARCH_PARAMETERS,
  payload: {
    from,
    to,
    departure,
    arrival,
    travellers,
    difPlace,
  },
})

export const setCarSearchCache = (cache) => ({
  type: SET_CAR_SEARCH_CACHE,
  payload: cache,
})

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
})

export const setStopFilters = (filters) => ({
  type: SET_STOP_FILTERS,
  payload: filters,
})

export const setTimeFilters = (time) => ({
  type: SET_TIME_FILTERS,
  payload: time,
})

export const setCompaniesFilter = (companies) => ({
  type: SET_COMPANIES_FILTERS,
  payload: companies,
})

export const setDurationFilters = (time) => ({
  type: SET_DURATION_FILTERS,
  payload: time,
})

export const resetSearchFilters = () => ({
  type: RESET_SEARCH_FILTERS,
})

export const resetSearchTravellers = () => ({
  type: RESET_SEARCH_TRAVELLERS,
})

export const disableExtendCheckout = () => ({
  type: DISABLE_EXTEND_CHECKOUT,
})

export const resetSearchParameters = () => ({
  type: RESET_SEARCH_PARAMETERS,
})
