import React, { memo, useEffect, useMemo } from 'react'
import { Box, Hidden, useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import MobileBottomNavigation from '../../containers/footer/mobileBottomNavigation'
import MobileSearch from '../../containers/search/mobileSearch'

import CenterSearchbar from '../../hooks/centerSearchbar'
import useDirections from '../../hooks/useDirectionsHook'
import useDestinations from '../../hooks/useSwitchDestinationsHook'
import useOneWay from '../../hooks/useOneWayHook'
import { useTranslation } from 'react-i18next'
import useTravellers from '../../hooks/useTravellersHook'
import useTrains from '../../hooks/useTrainsHook'
import usePopover from '../../hooks/usePopoverHook'
import GuestMobileSearch from '../../containers/search/guest/guestMobileSearch'
import ExpandedSearchBar from '../../containers/search/expandedSearch'
import GuestExpandedSearchBar from '../../containers/search/guest/guestExpandedSearch'
import { useTheme } from '@material-ui/core/styles'
import BannerRow from '../../containers/header/bannerRow'
import { disableExtendCheckout } from '../../store/search/searchAction'
import ExtendCheckoutBanner from '../../containers/header/extendCheckoutBanner'

const Train = ({ showSearchModes, currentSearchMode, setSearchMode }) => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const history = useHistory()
  const { outGoingCity } = useSelector((state) => state.train)
  const { i18n } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { isAuthenticated, user, trainSearch } = useSelector(
    (state) => state.auth
  )

  const {
    earliestDate,
    latestDate,
    suggestedEarliestDate,
    suggestedLatestDate,
    allowedTrainDestination,
  } = user?.bookingLink || {}
  const isToLocked = allowedTrainDestination?.length > 0

  const {
    from: initialFrom,
    to: initialTo,
    departure: initialDeparture,
    arrival: initialArrival,
    travellers: initialTravellers,
    extendCheckout,
  } = useSelector((state) => state.search.train)

  const singlePnrBooking = user?.singlePnrBooking
  const { items } = useSelector((state) => state.checkout)

  useEffect(() => {
    const train = items.find((i) => {
      return i.type === 'Train'
    })

    if (!items.length) {
      dispatch(disableExtendCheckout())
    } else if ((!!train || !user) && !!extendCheckout) {
      history.push('/checkout')
    }
  }, [items, extendCheckout, dispatch])

  const MobSearchBar = isAuthenticated ? MobileSearch : GuestMobileSearch
  const SearchBar = isAuthenticated ? ExpandedSearchBar : GuestExpandedSearchBar

  const { departure, depChange, arrival, arrOnChange } = useDirections(
    suggestedEarliestDate || initialDeparture,
    suggestedLatestDate || initialArrival,
    true,
    earliestDate,
    latestDate
  )

  const {
    fromDest: from,
    setFrom,
    toDest: to,
    setTo,
    switchDestinations,
  } = useDestinations(initialFrom, initialTo, i18n)
  const { isOneWayDest: isOneWay } = useOneWay(arrival)
  const { travellers, setTravellers, setAgentCompany } =
    useTravellers(initialTravellers)
  const {
    isPopoverOpenFrom,
    setIsPopoverOpenFrom,
    isPopoverOpenTo,
    setIsPopoverOpenTo,
    isPopoverOpenTraveller,
    setIsPopoverOpenTraveller,
    setIsPopoverOpenCheckIn,
  } = usePopover()

  const {
    getCities,
    onSubmit,
    optionLabel,
    renderOption,
    modifyCounts,
    counts,
  } = useTrains({
    from,
    to,
    departure,
    arrival,
    travellers,
    isOneWay,
    url,
    dispatch,
    history,
    setIsPopoverOpenFrom,
    setIsPopoverOpenTo,
    setIsPopoverOpenTraveller,
    // isPopoverOpenCheckIn,
    setIsPopoverOpenCheckIn,
    forceNewSearch: !extendCheckout,
  })

  const checkoutTopBanner = useMemo(() => {
    return !!extendCheckout && !!isMobile ? (
      <ExtendCheckoutBanner type={'train'} />
    ) : (
      false
    )
  }, [extendCheckout, isMobile])

  return (
    <>
      {!extendCheckout && <BannerRow bannerKey="train"></BannerRow>}
      <CenterSearchbar topContent={checkoutTopBanner}>
        <Hidden mdUp>
          <MobSearchBar
            /* */
            entityType={'train'}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            setAgentCompany={setAgentCompany}
            /* */
            departure={departure}
            setDeparture={depChange}
            arrival={arrival}
            setArrival={arrOnChange}
            /* */
            travellers={travellers}
            setTravellers={setTravellers}
            switchDestinations={switchDestinations}
            getCities={getCities}
            isOneWay={isOneWay}
            onSubmit={onSubmit}
            array={outGoingCity}
            optionLabel={optionLabel}
            setIsPopoverOpenFrom={setIsPopoverOpenFrom}
            isPopoverOpenFrom={isPopoverOpenFrom}
            setIsPopoverOpenTo={setIsPopoverOpenTo}
            isPopoverOpenTo={isPopoverOpenTo}
            setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
            isPopoverOpenTraveller={isPopoverOpenTraveller}
            renderOption={renderOption}
            counts={isAuthenticated ? null : counts}
            modifyCounts={modifyCounts}
            TRAIN={trainSearch === 'custom'}
            earliestDate={earliestDate}
            latestDate={latestDate}
            isToLocked={isToLocked}
            usersDisabled={!!extendCheckout && !singlePnrBooking}
            disableTripTypeSelection={!!extendCheckout}
          />
        </Hidden>
        <Hidden smDown>
          <SearchBar
            /* */
            from={from}
            entityType={'train'}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            setAgentCompany={setAgentCompany}
            /* */
            departure={departure}
            setDeparture={depChange}
            arrival={arrival}
            setArrival={arrOnChange}
            /* */
            travellers={travellers}
            setTravellers={setTravellers}
            switchDestinations={switchDestinations}
            getCities={getCities}
            isOneWay={isOneWay}
            onSubmit={onSubmit}
            array={outGoingCity}
            optionLabel={optionLabel}
            setIsPopoverOpenFrom={setIsPopoverOpenFrom}
            isPopoverOpenFrom={isPopoverOpenFrom}
            setIsPopoverOpenTo={setIsPopoverOpenTo}
            isPopoverOpenTo={isPopoverOpenTo}
            setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
            isPopoverOpenTraveller={isPopoverOpenTraveller}
            renderOption={renderOption}
            counts={isAuthenticated ? null : counts}
            modifyCounts={modifyCounts}
            showSearchModes={showSearchModes}
            currentSearchMode={currentSearchMode}
            setSearchMode={setSearchMode}
            TRAIN={trainSearch === 'custom'}
            earliestDate={earliestDate}
            latestDate={latestDate}
            isToLocked={isToLocked}
            usersDisabled={!!extendCheckout && !singlePnrBooking}
            extendCheckoutType={!!extendCheckout ? 'train' : false}
          />
        </Hidden>
      </CenterSearchbar>

      <Hidden smUp>
        <MobileBottomNavigation />
      </Hidden>
    </>
  )
}

export default memo(Train)
