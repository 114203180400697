import React, { useCallback, useState } from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { useProfileStyles } from './profileStyles'
import { useDispatch, useSelector } from 'react-redux'
import RowWithDivider from '../../components/reusable/rowWithDivider'
import AddLoyaltyModal from '../../containers/modals/addLoyaltyModal'
import RmConfirm from '../../containers/modals/loyaltyRemoveConfirmModal'
import {
  loyaltyPrograms,
  loyaltySegmentsByCode,
} from '../../constants/loyalties'
import Button from '@material-ui/core/Button'
import {
  getUserAction,
  setLoyaltyPrograms,
  updateUserAction,
} from '../../store/auth/authAction'

const MyLoyaltyPrograms = () => {
  const classes = useProfileStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const [isAddModal, setIsAddModal] = useState(false)
  const [confirmData, setConfirmData] = useState(false)
  const { t } = useTranslation()
  const { user } = useSelector((state) => state.auth)

  const removeLoyalty = useCallback(
    async (data) => {
      // console.log(data)
      // console.log(newLoyalties)
      // return

      const rqData = {
        ...data,
        value: null,
        action: 'delete',
      }
      setConfirmData(false)
      await dispatch(
        updateUserAction({
          section: 'more',
          updateData: {
            loyalties: [rqData],
          },
        })
      )

      // Local optimistic state update to get around slow Umbrella updates
      let operatorValue = (program) =>
        program.sector == 7
          ? program.vendorCode[0].toUpperCase()
          : program.operator
      let newLoyalties = user.loyaltyProgram.filter(
        (program) =>
          loyaltySegmentsByCode[program.sector] != data.segment ||
          operatorValue(program) != data.type
      )
      dispatch(setLoyaltyPrograms(newLoyalties))
    },
    [dispatch, user]
  )

  const confirmRmLoyalty = useCallback((data) => {
    setConfirmData(data)
  }, [])

  return (
    <Box pl={isDesktop ? 2 : 7} pr={2} pb={6} pt={2}>
      <Box className={classes.loyaltyHeader} mb={9}>
        {!isDesktop && (
          <Typography variant="h3" className={classes.weight} gutterBottom>
            {t('loyalty programs')}
          </Typography>
        )}

        <Button
          onClick={() => setIsAddModal(true)}
          disableElevation
          variant="contained"
          color="primary"
          className={classes.loyaltyBtn}
          style={{
            width: !isDesktop ? 'auto' : '100%',
            padding: !isDesktop ? '7px 22px' : '12px 22px',
          }}
        >
          {!isDesktop ? t('add') : t('add program')}
        </Button>
      </Box>

      <Box>
        {user.loyaltyProgram.length > 0 ? (
          user.loyaltyProgram.map((program, index) => {
            let type =
              program?.sector === 7
                ? program?.vendorCode?.[0] || ''
                : program?.operator
            type = type.toUpperCase()
            const data = {
              segment: loyaltySegmentsByCode?.[program?.sector || ''] || null,
              type: type,
              value: program?.card || null,
              notice: program?.inactive ? t('expired') : null,
            }
            if (!data.segment || !data.type) return null
            const LOpt = loyaltyPrograms?.[data.segment]?.find(
              (o) => o.value === data.type
            )
            data['name'] = LOpt?.name || data.type

            return (
              <RowWithDivider
                name={LOpt?.name || data.type}
                value={data.value}
                notice={data.notice}
                key={index}
                onRemove={() => confirmRmLoyalty(data)}
              />
            )
          })
        ) : (
          <RowWithDivider name={t('no saved loyalty')} />
        )}
      </Box>
      <AddLoyaltyModal
        modalBoolean={isAddModal}
        closeModal={() => setIsAddModal(false)}
      />
      <RmConfirm
        rmData={confirmData}
        onConfirm={removeLoyalty}
        onClose={() => setConfirmData(false)}
      />
    </Box>
  )
}

export default MyLoyaltyPrograms
