import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrency } from '../../store/auth/authAction'

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    fontWeight: 600,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    span: {
      '&:first-child': {
        marginTop: '6px',
        marginRight: theme.spacing(1),
      },
    },
  },
}))

const CurrencyChooser = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    currencies,
    selectedCurrency,
    currency: defaultCurrency,
  } = useSelector((state) => state.auth)
  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeCurrency = (currency) => {
    dispatch(setCurrency(currency))
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        onClick={handleClick}
        className={classes.iconBtn}
        id="button"
        aria-controls={isMenuOpen ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
      >
        <span>{selectedCurrency || defaultCurrency}</span>
      </Button>

      <Menu
        id="locale-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => handleChangeCurrency(null)}
          key={'defaultcurrency'}
        >
          {defaultCurrency}
        </MenuItem>

        {Object.keys(currencies).map((currency) => (
          <MenuItem
            onClick={() => handleChangeCurrency(currency)}
            key={currency}
          >
            {currency}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default memo(CurrencyChooser)
