import { Backdrop, Box, CircularProgress, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  authenticateWithOidc,
  completeOidcRegistration,
} from '../../repositories/auth'
import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import AddMissingUserData from '../../containers/authentication/addMissingUserData'
import { useHistory } from 'react-router-dom'
import { isLogginIn, relogin } from '../../store/auth/authAction'
import SsoError from '../../components/general/ssoError'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    //padding: '25px 10px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '600px',
    height: 'min-content',
    display: 'flex',
    flex: 1,
    flexGrow: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4),
  },
  btn: {
    borderRadius: '10px',
    boxShadow: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
    textTransform: 'uppercase',
    letterSpacing: '1.1px',
    borderRadius: '22px',
    padding: '8px 16px 8px 16px',
  },
  loadingBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
}))

function OidcCallback() {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { companyId } = useParams()
  const searchParams = new URLSearchParams(window.location.search)
  const [error, setError] = useState(null)
  const [editingToken, setEditingToken] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [userDataMissing, setUserDataMissing] = useState(null)
  const history = useHistory()
  const { t } = useTranslation()

  // const [userDataMissing, setUserDataMissing] = useState({
  //   firstName: 'Test',
  //   lastName: 'User',
  //   email: 'WbqFP@example.com',
  // })
  const dispatch = useDispatch()

  const completeLogin = async (loginData) => {
    try {
      await dispatch(isLogginIn(true))
      await dispatch(relogin(loginData))
      await dispatch(isLogginIn(false))
      history.push('/')
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      setError(e.toString())
    }
  }
  const handleSaveUserData = (data) => {
    setIsLoading(true)
    completeOidcRegistration(companyId, data, editingToken)
      .then((result) => {
        completeLogin(result)
      })
      .catch((error) => {
        console.log(error)
        const errorMessage = error?.response?.data?.error || error
        setUserDataMissing(null)
        setError(errorMessage)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (searchParams.has('error')) {
      setError(searchParams.get('error'))
    } else {
      setIsLoading(true)
      authenticateWithOidc(companyId, searchParams.get('code'))
        .then((result) => {
          const { requiresMissingData } = result

          if (requiresMissingData) {
            // User data missing from IDP for creating a new user - request missing data from user
            const { user: userData, editingToken: newEditingToken } = result
            setEditingToken(newEditingToken)
            setUserDataMissing(userData)
            setIsLoading(false)
          } else {
            // Successful authentication
            completeLogin(result)
          }
        })
        .catch((error) => {
          console.log(error)
          const errorMessage = error?.response?.data?.error || error
          setError(errorMessage)
          setIsLoading(false)
        })
    }
  }, [])

  return (
    <Box className={classes.root}>
      <>
        {userDataMissing && (
          <Box className={classes.container}>
            <AddMissingUserData
              title={t('missing user data title')}
              description={t('missing user data description')}
              user={userDataMissing}
              editCompletedData={false}
              isLoading={isLoading}
              onSubmit={handleSaveUserData}
            />
          </Box>
        )}
        {error && (
          <Box className={classes.container}>
            <SsoError message={error} />
          </Box>
        )}
        {isLoading && (
          <Backdrop className={classes.loadingBackdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    </Box>
  )
}

export default OidcCallback
