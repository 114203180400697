import React, {
  Fragment,
  useState,
  useMemo,
  useEffect, useCallback
} from 'react'
import {Box, Button, CircularProgress, Divider, Grid, Typography} from '@material-ui/core'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { getBags, getFFList } from '../../../repositories/suggestions'
import { getFlightTripSeats } from '../../../repositories/flights'
import SeatReservationModal from '../../../containers/modals/seatReservationModal'
import FareList from "./FareList"
import axios from 'axios'
import SelectBags from "./SelectBags"
import ShortTripInfo from "./ShortTripInfo"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useDispatch} from "react-redux";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  tktTripEditOuter: {
    paddingTop: '8px',

    '& .back-button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '& .main-row-left': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingRight: '16px',

      [theme.breakpoints.down('sm')]: {
        paddingRight: '0'
      }
    },

    '& .cursor-pointer': {
      cursor: 'pointer'
    },

    '& .main-header-row': {
      marginBottom: '34px',

      '& .main-header-text': {
        fontSize: '25px',
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 'normal',
        color: '#4f4f4f'
      },

      '& .back-button-icon': {
        color: 'black',
        marginRight: '10px',
      },

      '& .back-btn-text': {
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: 'normal',
        color: '#4f4f4f'
      }
    },

    '& .title-block': {

      fontSize: '24px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      color: '#4f4f4f',
      marginBottom: '30px',

      '&.bags': {
        fontSize: '20px'
      },

      '&.bold': {
        fontWeight: theme.typography.fontWeightBold
      }
    },

    '& .trip-block': {
      borderRadius: '12px',
      border: 'solid 1px #ededed',
      backgroundColor: '#fff',
      marginBottom: '24px',

      '& .bags-block': {
        padding: '30px'
      },

      '& .fare-list-block': {
        padding: '30px',
        paddingBottom: '40px',
        borderBottom: 'solid 1px #ededed',
        marginBottom: '30px',
      }
    },

    '& .short-trip-info': {
      marginTop: '16px',
      borderRadius: '12px',
      border: 'solid 1px #ededed',
      backgroundColor: 'white',
      padding: '25px',

      '& .delimiter': {
        width: '100%',
        height: '1px',
        backgroundColor: theme.palette.border,
        margin: '26px 0',

        '&.prices': {
          margin: '12px 0',
        }
      },

      '& .price-line': {
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: 'normal',
        color: '#4f4f4f',
        marginBottom: '8px',

        '&.total-price': {
          marginBottom: '0',
          fontWeight: 'bold'
        }
      },

      '&:first-child': {
        marginTop: 0,
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: '30px'
      }
    },

    '& .right-info-outer': {
      position: 'sticky',
      top: '78px',
      width: '100%'
    },

    '& .button-row': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '136px',

      '& .submit-button': {
        cursor: 'pointer',
        width: '215px',
        height: '60px',
        borderRadius: '60px',
        backgroundColor: '#08c5b9',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: 'normal',
        letterSpacing: '1.1px',
        textAlign: 'center',
        color: 'white',
        textTransform: 'uppercase'
      }
    }

  }
}))

const SelectFlightFares = ({ trip, ticketData, token, select, cancel, passengers }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [FFLoading, setFFLoading] = useState(false)
  const [ffLoaded, setFfLoaded] = useState(false)
  const [seatsLoading, setSeatsLoading] = useState(false)
  const [selectedSeats, setSelectedSeats] = useState([])
  const [fetchedSeats, setFetchedSeats] = useState(null)
  const [seatsOpen, setSeatsOpen] = useState(false)
  const [outboundFFList, setOutboundFFList] = useState(null)
  const [returnFFList, setReturnFFList] = useState(null)
  const [oBagsLoading, setOBagsLoading] = useState(false)
  const [rBagsLoading, setRBagsLoading] = useState(false)
  const [selectedOutbound, setSelectedOutbound] = useState(null)
  const [selectedReturn, setSelectedReturn] = useState(null)
  const [outboundBagsList, setOutboundBagsList] = useState(null)
  const [returnBagsList, setReturnBagsList] = useState(null)
  const [selectedOBag, setSelectedOBag] = useState(null)
  const [selectedRBag, setSelectedRBag] = useState(null)

  const getSeats = useCallback(async () => {
    const { outboundTrip, returnTrip } = trip || {}
    const outgoing = outboundTrip.original.trip
    const returning = returnTrip ? returnTrip.original.returnTrip : []
    const isMultiTicket = returning?.original?.isMultiTicket
    setSelectedSeats(null)
    if (!outboundTrip) return false;
    setSeatsLoading(true)
    setFetchedSeats(null)
    const res = await getFlightTripSeats(outgoing, returning, isMultiTicket, {
      outboundFare: selectedOutbound,
      returnFare: selectedReturn
    })
    setFetchedSeats(res)
    setSeatsLoading(false)
  }, [selectedOutbound, selectedReturn, trip])


  const setSeats = useCallback(async (seats) => {
    setSelectedSeats(seats)
    setSeatsOpen(false)
  }, [setSelectedSeats])

  useEffect(() => {
    if (!!ffLoaded && !FFLoading) {
      getSeats()
    }
  }, [getSeats, ffLoaded, FFLoading]);

  const flightBags = useMemo(() => {
    return {
      outboundBags: ticketData.tripData?.flight?.outbound?.bags || null,
      returnBags: ticketData.tripData?.flight?.return?.bags || null
    }
  }, [])

  const isMTK = !!trip?.returnTrip?.original?.isMultiTicket || false
  const isRoundTrip = !!trip?.returnTrip
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const prices = useMemo(() => {
    const passengers = ticketData?.passengers
    const { outboundTrip, returnTrip } = trip || {}
    const isMultiTicket = returnTrip?.original?.isMultiTicket
    const usersCount = passengers?.length || 1
    let bagsTotal = 0
    let bagsTotalPerType = {carryOn: 0, general: 0}
    let bagsCount = {carryOn: 0, general: 0}

    const seatsPrice = selectedSeats
      ? selectedSeats.map((x) => x.price).reduce((a, b) => a + b, 0)
      : 0

    const ff = {
      outbound: selectedOutbound || null,
      return: selectedReturn || null,
    }

    const keys = ['carryOn', 'general']
    keys.forEach(k => {
      if (selectedOBag && typeof selectedOBag === 'object') {
        for (let uind in selectedOBag) {
          if (!selectedOBag[uind]?.[k]) continue
          bagsCount[k]++
          const tmpOPrice = parseFloat(selectedOBag[uind]?.[k]?.price || 0)
          bagsTotalPerType[k] += tmpOPrice
          bagsTotal += tmpOPrice
        }
      }

      if (selectedRBag && typeof selectedRBag === 'object') {
        for (let uind in selectedRBag) {
          if (!selectedRBag[uind]?.[k]) continue
          bagsCount[k]++
          const tmpRPrice = parseFloat(selectedRBag[uind]?.[k]?.price || 0)
          bagsTotalPerType[k] += tmpRPrice
          bagsTotal += tmpRPrice
        }
      }
    })


    if (isMultiTicket) {
      let oPrice = parseFloat(returnTrip.original.outboundPrice || 0)
      if (!!ff?.outbound?.price) oPrice = ff.outbound.price * usersCount

      let rPrice = parseFloat(ff?.return?.price || returnTrip.original.inboundPrice || 0)
      if (!!ff?.return?.price) rPrice = ff.return.price * usersCount

      return {
        bagsCount,
        usersCount,
        seats: seatsPrice,
        oStd: parseFloat(returnTrip.original.outboundPrice || 0),
        rStd: parseFloat(returnTrip.original.inboundPrice || 0),
        trip: oPrice + rPrice,
        bags: bagsTotalPerType,
        total: oPrice + rPrice + bagsTotal + seatsPrice,
      }
    } else if (returnTrip) {
      let price = parseFloat(returnTrip?.price || 0)
      if (!!ff?.outbound?.price) {
        price = parseFloat(ff?.outbound?.price) * usersCount
      }
      return {
        bagsCount,
        usersCount,
        seats: seatsPrice,
        oStd: parseFloat(returnTrip?.price || 0),
        trip: price,
        bags: bagsTotalPerType,
        total: price + bagsTotal
      }
    } else {
      let price = parseFloat(outboundTrip?.price || 0)
      if (!!ff?.outbound?.price) {
        price = parseFloat(ff?.outbound?.price) * usersCount
      }
      return {
        bagsCount,
        usersCount,
        seats: seatsPrice,
        oStd: parseFloat(outboundTrip?.price || 0),
        trip: price,
        bags: bagsTotalPerType,
        total: price + bagsTotal + seatsPrice
      }
    }
  }, [selectedReturn, selectedOutbound, selectedOBag, selectedRBag, selectedSeats])

  const getBagTypeText = (type) => {
    const texts = {carryOn: 'carry-on', general: 'baggage'}
    return t(texts?.[type] || texts['general'])
  }

  const setTripFareBasis = useCallback((ff, trips) => {
    if (!trips || !ff) return trips
    return trips.map(flight => {
      const resFlight = {...flight}
      if (ff?.byFlights?.[flight?.uniqueInd]) {
        resFlight.bookingclass = ff.byFlights[flight.uniqueInd].bookingClass
        resFlight.fareBasis = ff.byFlights[flight.uniqueInd].ffFareBasis
      }
      return resFlight;
    })
  }, [])

  const selectFare = (dir, fare) => {
    if (dir === 'outbound') {
      setSelectedOutbound( fare)
    } else if (dir === 'return') {
      setSelectedReturn(fare)
    }
  }

  const submit = () => {
    const outgoingTrip = trip.outboundTrip.original.trip
    const returningTrip = trip.returnTrip ? trip.returnTrip.original.returnTrip : []
    let seatsExists = false;
    const userSeats = {}

    let isError = false;
    (selectedSeats || []).forEach(s => {
      if (!s?.seat) return false

      const n = s.seat.slice(0, -1);
      const l = s.seat[s.seat.length - 1];
      const price = s?.price || 0;
      const flKey = s?.flightKey;
      let dir = null;
      const oTrip = outgoingTrip.find(t => t.uniqueInd === flKey)
      const rTrip = returningTrip.find(t => t.uniqueInd === flKey)
      const user = passengers.find(p => p.uniqueId === s.userId);
      const uKey = user?.uind || null;

      if (!!oTrip) dir = 'outbound';
      if (!!rTrip) dir = 'return';

      if (!n || !l || !flKey || !dir || !uKey) {
        isError = true
        return false;
      }

      if (!userSeats?.[uKey]) userSeats[uKey] = {};

      if (!userSeats[uKey]?.[dir]) {
        userSeats[uKey][dir] = {
          same: true,
          seats: {}
        }
      }

      userSeats[uKey][dir].seats[flKey] = {
        l,
        n,
        price,
        seatInfo: s?.seatInfo || null
      }
      seatsExists = true
    })

    if (!!isError) return false;

    const result = {
      ff: {
        outbound: selectedOutbound,
        return: selectedReturn,
      },
      bags: {
        outbound: selectedOBag,
        return: selectedRBag
      },
      seats: seatsExists ? userSeats : null
    }

    select(result)
  }

  const getOutboundBags = useCallback(async () => {
    try {
      setOutboundBagsList(null)
      setOBagsLoading(true)
      const {outboundTrip, returnTrip} = trip || {}
      const {outboundBags} = flightBags || {}
      const tripMapped = outboundTrip?.original?.trip || []
      const returnTripMapped = returnTrip?.original?.returnTrip || []
      let flights = returnTrip && !isMTK ? [...tripMapped, ...returnTripMapped] : tripMapped
      flights = setTripFareBasis(selectedOutbound, flights)
      if (!flights?.length) throw Error('failed')
      const rqData = {
        flights,
        tktToken: token
      }
      setOutboundBagsList(await getBags(rqData, 'oAbBags'))
      setOBagsLoading(false)

      const FFkey = ticketData?.tripData?.flight?.ff?.outbound?.combineKey || null
      const sameFare =  !!selectedOutbound?.combineKey && selectedOutbound.combineKey === FFkey
      if ((
          !!sameFare || (!selectedOutbound && !FFkey)) &&
        (!!outboundBags && typeof(outboundBags) === 'object' )
      ) {
        setSelectedOBag({...outboundBags})
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        setOBagsLoading(false)
      }
      setOutboundBagsList(null)
    }
  }, [flightBags, trip, selectedOutbound, isMTK, setTripFareBasis, selectedOutbound])

  const getReturnBags = useCallback(async () => {
    try {
      setReturnBagsList(null)
      setRBagsLoading(true)
      const {returnTrip} = trip || {}
      const {returnBags} = flightBags || {}
      let flights = returnTrip?.original?.returnTrip || null
      flights = setTripFareBasis(selectedReturn, flights)
      if (!flights?.length) throw Error('failed')
      const rqData = {
        flights,
        tktToken: token
      }
      setReturnBagsList(await getBags(rqData, 'rABags'))
      setRBagsLoading(false)

      const FFkey =  ticketData?.tripData?.flight?.ff?.return?.combineKey || null
      const sameFare =  !!selectedReturn?.combineKey && selectedReturn.combineKey === FFkey
      if ((
          !!sameFare || (!selectedReturn && !FFkey)) &&
        (!!returnBags && typeof(returnBags) === 'object' )
      ) {
        setSelectedRBag({...returnBags})
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        setRBagsLoading(false)
      }
      setReturnBagsList(null)
    }
  }, [flightBags, trip, selectedReturn, setTripFareBasis, selectedReturn])

  const prepareFareFamily = (fareData, flights) => {
    const tmpFares = fareData?.fares || []
    let resultOFares = tmpFares.map(f => {
      try {
        const ff = []
        let oFFName = null
        let rFFName = null

        flights.forEach(mf => {
          if (!oFFName && !mf?.isInbound) {
            oFFName = f.byFlights[mf.uniqueInd].ffName
          } else if (!rFFName && !!mf?.isInbound) {
            rFFName = f.byFlights[mf.uniqueInd].ffName
          }
          if (!f?.byFlights?.[mf.uniqueInd]) throw new Error('invalid fare')
          ff.push(f.byFlights[mf.uniqueInd])
        })
        return {...f, ff, oFFName, rFFName}
      } catch (e) {
        return null;
      }
    })
    return resultOFares.filter(rf => !!rf)
  }

  const openSeatsModal = () => {
    if (!!fetchedSeats?.length) {
      setSeatsOpen(true)
    }
  }

  const getTripFares = async () => {
    const cabins = ['economy', 'economyPremium', 'business']
    const currentClass = ticketData?.parsedSourceData?.flightClassKey || null
    let rqClasses = null
    cabins.forEach(c => {
      if (currentClass !== c && !rqClasses) return false
      if (!rqClasses) rqClasses = []
      rqClasses.push(c)
    })

    const {outboundTrip, returnTrip} = trip || {}
    const passengersCount = ticketData?.passengers?.length || null
    if (!outboundTrip || !passengersCount || !!FFLoading) return false
    setSelectedOutbound(null)
    setSelectedReturn(null)
    setFFLoading(true)

    try {
      const tripsData = {
        outbound: {
          originFrom: outboundTrip.from.shortName,
          originTo: outboundTrip.to.shortName,
          originDeparture: outboundTrip.departure,
        },
        return: {},
      }

      if (returnTrip) {
        tripsData['return'] = {
          originFrom: returnTrip.from.shortName,
          originTo: returnTrip.to.shortName,
          originDeparture: returnTrip.departure,
        }
      }

      const outboundOriginal = outboundTrip?.original?.trip || []
      const returningOriginal = returnTrip?.original?.returnTrip || []
      const tripMapped = outboundOriginal.map((t) => ({
        ...t,
        ...tripsData['outbound'],
        isOutbound: true,
      }))
      const returnTripMapped = returningOriginal.map((t) => ({
        ...t,
        ...tripsData['return'],
        isInbound: true,
      }))

      const merge = returnTrip && !isMTK ? [...tripMapped, ...returnTripMapped] : tripMapped
      const oFareList = await getFFList({
        flights: merge,
        passengersCount: { adult: passengersCount },
        cabin_class: rqClasses || 'all',
        tktToken: token,
        additionalData: {
          isMTK: isMTK,
          marginDir: !isMTK && !!returnTrip ? 'both' : 'outbound',
          marginCountry: outboundTrip?.to?.countryCode,
          ticketId: ticketData?.id
        },
      })

      const faresResult = prepareFareFamily(oFareList, merge)
      setOutboundFFList(!!faresResult?.length ? faresResult : null)
      const oFFkey = ticketData?.tripData?.flight?.ff?.outbound?.combineKey || null
      const sameOFare = (faresResult).find(f => !!f?.combineKey && f.combineKey === oFFkey)
      setSelectedOutbound(sameOFare || faresResult?.[0] || null)

      const rFareList = isMTK ? await getFFList({
        flights: returnTripMapped,
        passengersCount: { adult: passengersCount },
        cabin_class: rqClasses || 'all',
        tktToken: token,
        additionalData: {
          isMTK: isMTK,
          marginDir: 'inbound',
          marginCountry: returnTrip?.to?.countryCode,
          ticketId: ticketData?.id
        }
      }) : null
      const inboundFFresults = !!rFareList ? prepareFareFamily(rFareList, returnTripMapped) : null
      setReturnFFList(inboundFFresults)

      if (isMTK) {
        const rFFkey =  ticketData?.tripData?.flight?.ff?.return?.combineKey || null
        const sameRFare = (inboundFFresults || []).find(f => !!f?.combineKey && f.combineKey === rFFkey)
        setSelectedReturn(sameRFare || inboundFFresults?.[0] || null)
      }
    } catch (e) {
      setReturnFFList(null)
      setOutboundFFList(null)
    }
    setFfLoaded(true)
    setFFLoading(false)
  }

  const pricesBlock = (
    <Box key="prices-trip-info" className="short-trip-info">
      {!!FFLoading ? (
        <Grid container key="trip-price-loader" alignItems={"center"} justifyContent={"center"}>
          <Grid item xs={"auto"} key="outer-spinner">
            <CircularProgress key="tkt-spinner" className="tkt-spinner"/>
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          <Grid container key="trip-price-line" className="price-line">
            <Grid item xs={true} key="price-naming" className="price-naming">
              {t(isRoundTrip ? 'roundtrip' : 'detail mob outbound')} ({prices.usersCount} {t('adult', prices.usersCount || 1)})
            </Grid>
            <Grid item xs={"auto"} key="price-num" className="price-num"> SEK {prices.trip}</Grid>
          </Grid>

          {selectedSeats?.length ? (
            <Grid container key="seats-price-line" className="price-line">
              <Grid item xs={true} key="price-naming" className="price-naming">
                {selectedSeats.length} x {t('detail reservations')}
              </Grid>
              <Grid item xs={"auto"} key="price-num" className="price-num"> SEK {prices.seats}</Grid>
            </Grid>
          ) : null}

          {['carryOn', 'general'].map(key => !!prices?.bags?.[key] ? (
            <Grid container key={`bags-price-line-${key}`} className="price-line">
              <Grid item xs={true} key="price-naming" className="price-naming">
                {getBagTypeText(key)} x {prices.bagsCount[key]}
              </Grid>
              <Grid item xs={'auto'} key="price-num" className="price-num">
                SEK {prices.bags[key]}
              </Grid>
            </Grid>
          ) : null)}

          <Box key="delimiter" className="delimiter prices"/>
          <Grid container key="total-price-line" className="price-line total-price">
            <Grid xs={true} key="price-naming" className="price-naming"> {t('detail total')} </Grid>
            <Grid xs={'auto'} key="price-num" className="price-num"> SEK {prices.total} </Grid>
          </Grid>
        </Fragment>
      )}
    </Box>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    getTripFares()
  }, [])

  useEffect(() => {
    setSelectedOBag(null)
    getOutboundBags()
  }, [selectedOutbound, getOutboundBags])

  useEffect(() => {
    setSelectedRBag(null)
    if (!!isMTK) getReturnBags()
  }, [selectedReturn, isMTK, getReturnBags])


  return (
    <Box key="tkt-trip-fare-edit" className={classes.tktTripEditOuter}>
      <Grid container direction={isMobile ? 'column-reverse' : 'row'} key="main-row">
        <Grid item xs={12} md={7} key="main-row-left" className={`main-row-left`}>
          <Box key="outbound-trip-block" className="trip-block">
            <Box key="fare-list-block" className="fare-list-block">
              <Box key="title-block" className="title-block bold">
                {isMTK ?
                  `${t('outbound')} ${t('ticket options').toLowerCase()}` :
                  t('ticket options')
                }
              </Box>
              {!!FFLoading ? (
                <CircularProgress className="tkt-spinner"/>
              ) : (
                <Fragment>
                  <FareList
                    fares={outboundFFList}
                    stdPrice={prices.oStd || null}
                    selected={selectedOutbound}
                    select={(ff) => selectFare('outbound', ff)}
                  />
                </Fragment>
              )}

            </Box>
            <Box key="bags-block" className="bags-block">
              <Box key="title-block" className="title-block bags bold">{t('select baggage')}</Box>
              {!!oBagsLoading || !!FFLoading ? (
                <CircularProgress key="tkt-spinner" className="tkt-spinner"/>
              ) : (
                <Fragment>
                  <SelectBags
                    passengers={ticketData.passengers}
                    bags={outboundBagsList}
                    fare={selectedOutbound}
                    selected={selectedOBag}
                    select={(bg) => setSelectedOBag(bg)}
                  />
                </Fragment>
              )}
            </Box>
          </Box>

          {!!isMTK && (
            <Box key="return-trip-block" className="trip-block" v-if="isMTK">
              <Box key="fare-list-block" className="fare-list-block">
                <Box key="title-block" className="title-block bold">
                  {t('inbound')} {t('ticket options').toLowerCase()}
                </Box>
                {!!FFLoading ? (
                  <CircularProgress key="tkt-spinner" className="tkt-spinner"/>
                ) : (
                  <Fragment>
                    <FareList
                      fares={returnFFList}
                      stdPrice={prices.rStd || null}
                      selected={selectedReturn}
                      select={(ff) => selectFare('return', ff)}
                    />
                  </Fragment>
                )}

              </Box>
              <Box key="bags-block" className="bags-block">
                <Box key="title-block" className="title-block bags bold">{t('select baggage')}</Box>
                {!!rBagsLoading || !!FFLoading ? (
                  <CircularProgress className="tkt-spinner"/>
                ) : (
                  <Fragment>
                    <SelectBags
                      passengers={ticketData.passengers}
                      bags={returnBagsList}
                      fare={selectedReturn}
                      selected={selectedRBag}
                      select={(bg) => setSelectedRBag(bg)}
                    />
                  </Fragment>
                )}

              </Box>
            </Box>
          )}

          {!!isMobile && (
            <Box key="prices-outer-block" className={'prices-outer-block'}>
              {pricesBlock}
            </Box>
          )}
          <Box key="trip-seats-block" className="trip-block" p={'30px'} pb={0}>
            <Box key="title-block" className="title-block bold">
              {t('detail reservation')}
            </Box>
            <Divider />
            {seatsLoading || FFLoading ? (
              <Box className={classes.textCenter} py={3}>
                <CircularProgress className="tkt-spinner"/>
              </Box>
            ) : (
              <>
                <Box
                  pt={3}
                  pb={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={openSeatsModal}
                >
                  <Typography>
                    {' '}
                    {!fetchedSeats?.length ? t('unavailable') : t('detail add place')}{' '}
                  </Typography>
                  {!!fetchedSeats?.length && (
                    <Button
                      className={classes.modalBtn}
                      endIcon={
                        <ArrowForwardIosIcon color="primary" fontSize="small" />
                      }
                    >
                      {t('select')}
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Box>

          {!!seatsOpen && (
            <SeatReservationModal
              modalBoolean={seatsOpen}
              iniSelSeats={selectedSeats || []}
              passengersList={passengers}
              seatsList={fetchedSeats}
              onSelectSeats={setSeats}
              onClose={() => setSeatsOpen(false)}
            />
          )}

          <Box key="button-row" className="button-row">
            <Box key="submit-button" className="submit-button" onClick={submit}>{t('continue')}</Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={true} key="main-row-right">
          <Box key="right-info-outer" className="right-info-outer">
            <Box key="outbound-trip-info" className="short-trip-info">
              <ShortTripInfo dir="outbound" trip={trip.outboundTrip}/>
              {!!isRoundTrip && (
                <Fragment>
                  <Box key="delimiter" className="delimiter"/>
                  <ShortTripInfo dir="return" trip={trip.returnTrip}/>
                </Fragment>
              )}
            </Box>

            {!isMobile && pricesBlock}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SelectFlightFares
