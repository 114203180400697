import { useEffect, useState } from 'react'
import storage from 'local-storage-fallback'
import {
  initiateOidcLogin,
  handleOidcCallback,
} from '../utils/oidc/oidcHandler'

const useOpenIdConnectHook = () => {
  const [oidcLoading, setOidcLoading] = useState(true)

  useEffect(() => {
    const handleOidc = async () => {
      const searchParams = new URLSearchParams(window.location.search)

      // Initiate login by way of storage
      const isLoggedIn = storage.getItem('isLoggedIn') === 'True'

      /**
       * Perform initial redirect if given query param "oidc-login".
       */
      let handled = false

      if (searchParams.has('oidc-callback')) {
        console.log('oidc - handling callback')
        handled = await handleOidcCallback()
      } else if (searchParams.has('oidc-login') || isLoggedIn) {
        console.log('oidc - initiate login')
        handled = await initiateOidcLogin()
      }

      if (!handled) {
        setOidcLoading(false)
      }
    }

    handleOidc()
  }, [])

  return { oidcLoading }
}

export default useOpenIdConnectHook
