import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@material-ui/icons/Language'
import { useDispatch } from 'react-redux'
import { getCountriesList } from '../../store/other/otherAction'
import { supportedLanguages } from '../../utils/general'

const useStyles = makeStyles((theme) => ({
  langIconBtn: {
    fontWeight: 600,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    span: {
      '&:first-child': {
        marginTop: '6px',
        marginRight: theme.spacing(1),
      },
    },
  },
}))

const LanguageChooser = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [localeAnchorEl, setLocaleAnchorEl] = useState(null)
  const isLocaleMenuOpen = Boolean(localeAnchorEl)

  const handleLocaleClick = (event) => {
    setLocaleAnchorEl(event.currentTarget)
  }

  const handleLocaleClose = () => {
    setLocaleAnchorEl(null)
  }

  const handleChangeLocale = (locale) => {
    i18n.changeLanguage(locale)
    setLocaleAnchorEl(null)
    dispatch(getCountriesList())
  }

  return (
    <>
      <Button
        onClick={handleLocaleClick}
        endIcon={<LanguageIcon />}
        className={classes.langIconBtn}
        id="locale-button"
        aria-controls={isLocaleMenuOpen ? 'locale-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isLocaleMenuOpen ? 'true' : undefined}
      >
        <span>{i18n.languages[0].toUpperCase()}</span>
      </Button>

      <Menu
        id="locale-menu"
        anchorEl={localeAnchorEl}
        open={isLocaleMenuOpen}
        onClose={handleLocaleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Object.entries(supportedLanguages).map(([code, title]) => (
          <MenuItem key={code} onClick={() => handleChangeLocale(code)}>
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default memo(LanguageChooser)
