import React, { memo, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ModalHook from './reusable/modalHook'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { closeAllModals } from '../../store/modals/modalAction'
import FetchMyTrips from '../../repositories/my-trips'
import { fetchBookingsAction } from '../../store/myTrips/myTripsAction'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ModalInfoRowsBlock from './reusable/modalInfoRowsBlock'
import i18n from '../../utils/i18n'

const tripsRepository = new FetchMyTrips()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '40px',

    [theme.breakpoints.down('sm')]: {
      flex: 1,
      marginBottom: '63px',
    },
  },
  infoRow: {
    width: '100%',
    maxWidth: '400px',
    marginTop: '20px',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',

    '& .MuiIconButton-root': {
      padding: '28px 28px 11px 0',
    },
  },
  headerText: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '33px',
  },
  confirmText: {
    maxWidth: '380px',
  },
  btns: {
    borderRadius: theme.spacing(3),
    margin: '0 4px',
    padding: theme.spacing('10px', 4),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing('10px', 2),
    },
  },
}))

const CancelBookingModal = ({ modalData }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const textsArray = modalData?.infoTexts?.length ? modalData.infoTexts : []
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [cancelRequested, setCancelRequested] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const { bookingDetails } = useSelector((state) => state.myTrips)

  useEffect(() => {
    setLoading(false)
    setCancelRequested(false)
    setHasError(false)
  }, [bookingDetails])

  const closeModal = () => {
    setLoading(false)
    setCancelRequested(false)
    setHasError(false)

    return dispatch(closeAllModals())
  }

  const onRefund = async () => {
    let rq = null
    setLoading(true)
    setErrorMessage(null)
    setCancelRequested(true)
    rq = tripsRepository.cancelPnr(bookingDetails.pnr)

    if (!rq) {
      setLoading(false)
    } else {
      rq.then((data) => {
        setHasError(false)
        dispatch(fetchBookingsAction())
      })
        .catch((e) => {
          const msg = e.response?.data?.error || e.message || 'general error'

          const translationKey = `error ${msg.toLowerCase()}`
          if (i18n.exists(translationKey)) {
            setErrorMessage(t(translationKey))
          }
          setHasError(true)
        })
        .finally(() => setLoading(false))
    }
  }

  const buildDefaultContent = () => {
    return (
      <Box className={classes.root}>
        <Box>
          <Typography
            gutterBottom
            className={classes.headerText}
            variant="h4"
            align="center"
          >
            {t('confirm cancellation')}
          </Typography>
          <Typography align="center" className={classes.confirmText}>
            <span>
              {t('confirm text')} {bookingDetails.pnr}.
            </span>
            {!!textsArray?.length && (
              <span> {t('pay attention to rules')}</span>
            )}
          </Typography>
        </Box>
        {!!textsArray?.length && (
          <Box key={'rules-block'} className={classes.infoRow}>
            <ModalInfoRowsBlock textsArray={textsArray} />
          </Box>
        )}
        <Box mt={'31px'}>
          <Button
            className={classes.btns}
            color="primary"
            variant="outlined"
            onClick={closeModal}
          >
            {t('cancel btn')}
          </Button>
          <Button
            disableElevation
            className={classes.btns}
            color="primary"
            variant="contained"
            onClick={onRefund}
          >
            {t('cancel booking btn')}
          </Button>
        </Box>
      </Box>
    )
  }

  const isBIG = window.location.hostname.includes('bigtravel')

  const buildNoty = (text, header, isError = false) => {
    return (
      <Box className={classes.root}>
        <Box>
          {!!header && (
            <Typography
              gutterBottom
              className={classes.headerText}
              variant="h4"
              align="center"
            >
              {header}
            </Typography>
          )}
          {!!text && (
            <Typography align="center" className={classes.confirmText}>
              {text}

              {isError && isBIG && (
                <a
                  style={{ marginLeft: '8px' }}
                  href="https://bigtravel.se/changebooking"
                >
                  {t('click here')}
                </a>
              )}
            </Typography>
          )}
        </Box>
        <Box mt={6}>
          <Button
            className={classes.btns}
            color="primary"
            variant="outlined"
            onClick={closeModal}
          >
            {t('ok')}
          </Button>
        </Box>
      </Box>
    )
  }

  const buildLoading = () => {
    return (
      <Box className={classes.root}>
        <Box>
          <Typography
            gutterBottom
            className={classes.headerText}
            variant="h4"
            align="center"
          >
            {t('confirm wait title')}
          </Typography>
          <Box textAlign={'center'} py={3}>
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    )
  }

  const isDefault = !cancelRequested && !loading
  const isError = cancelRequested && !loading && hasError
  const isSuccess = cancelRequested && !loading && !hasError
  const successText =
    t('booking cancelled1') +
    ' ' +
    bookingDetails.pnr +
    ' ' +
    t('booking cancelled2')
  const failText =
    errorMessage || t('cancel booking fail') + ' ' + bookingDetails.pnr
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ModalHook
      minHeight={300}
      width={550}
      display={isMobile ? 'flex' : null}
      modalBoolean={!!modalData}
    >
      <Box className={classes.header}>
        <IconButton
          color="primary"
          className={classes.closeBtn}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {loading && buildLoading()}
      {isDefault && buildDefaultContent()}
      {isError && buildNoty(failText, t('cancellation failed'), true)}
      {isSuccess && buildNoty(successText, t('booking cancelled'))}
    </ModalHook>
  )
}

export default memo(CancelBookingModal)
