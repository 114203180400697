import React from 'react'
import { Box, Divider, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '24px',
    paddingBottom: '16px',
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  lineHeightNormal: {
    lineHeight: 'normal',
  },
  totalPriceBlock: {
    paddingTop: '10px',
  },
  between: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  priceUnderLine: {
    paddingTop: '7px',
  },
  priceLine: {},
  itemContainer: {
    backgroundColor: '#eef8f8',
    padding: '20px',
    borderRadius: '4px',
  },
}))
const Co2 = ({}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()
  const { items } = useSelector((state) => state.checkout)

  const totalCo2 = items
    .reduce(
      (acc, curr) => (acc += curr.type !== 'Car' && curr.co2 ? curr.co2 : 0),
      0
    )
    .toFixed(2)
  const itemsWithCo2 = items.filter((item) => item.co2 != null)

  return (
    <Box p={2}>
      <Box mb={2}>
        <Typography variant="h5" className={classes.bold}>
          {_.capitalize(t('co2'))}
        </Typography>
      </Box>
      <Box className={classes.itemContainer}>
        {itemsWithCo2.map((item) => (
          <Box key={item.type} className={classes.priceLine}>
            <Box className={classes.between}>
              <Typography>{t(item.type.toLowerCase())}</Typography>

              <Typography className={classes.lineHeightNormal} variant="h5">
                <span style={{ fontSize: '14px' }}>
                  {Number(item.co2).toFixed(2)}&nbsp;
                  {item.type == 'Car'
                    ? t('carbon dioxide per km')
                    : t('kilo') + ' ' + t('co2')}
                </span>
              </Typography>
            </Box>
          </Box>
        ))}

        {itemsWithCo2.length > 0 && (
          <>
            <Divider style={{ marginTop: '8px' }} />
            <Box className={classes.priceLine} mt={1}>
              <Box className={classes.between}>
                <Typography>{t('detail total')}</Typography>

                <Typography className={classes.lineHeightNormal} variant="h5">
                  <span style={{ fontSize: '14px' }}>
                    {totalCo2}&nbsp;
                    {t('kilo') + ' ' + t('co2')}
                  </span>
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default Co2
