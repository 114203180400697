import React, { memo, Fragment } from 'react'
import { Card, Box, Typography, IconButton, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CancelIcon from '@material-ui/icons/Cancel'
import GetCompanyIcon from '../../components/reusable/getCompanyIcon'
import { useDispatch } from 'react-redux'
import { removeCheckoutItemAction } from '../../store/checkout/checkoutActions'
import Moment from 'react-moment'
import Policy from '../../components/reusable/tags/policy'

import { useTranslation } from 'react-i18next'
import {flightIsSameDir} from "../../utils/general";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '12px',
  },
  invalidDName: {
    backgroundColor: '#fff5e0',
    padding: '4px 5px',
    borderRadius: '3px',
  },
  justify: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(0.5),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  forwardBtn: {
    borderRadius: theme.spacing(3),
  },
  closeBtn: {
    padding: 0,
  },
  priceText: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const FlightCard = ({ model, index, disableEdit = false, contentOnly = false}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { outgoing, returning } = model

  const remove = () => dispatch(removeCheckoutItemAction(index))

  const renderFlights = Object.keys({ outgoing, returning }).map((key, i) => {
    const flight = model[key]
    if (!flight) return null
    let isSameDir =  null

    if (key === 'returning') {
      isSameDir = flightIsSameDir(outgoing, flight)
    } else if (!!returning) {
      isSameDir = flightIsSameDir(returning, flight)
    }

    const from = `${flight.from.city} (${flight.from.shortName})`
    const to = `${flight.to.city} (${flight.to.shortName})`

    const { arriveTime, departTime } = flight
    const isDirect =
      flight.via.length <= 1
        ? t('filter directly small')
        : `${flight.via.length - 1} ${t('changes')}`

    return (
      <Fragment key={i}>
        <Box p={2} pb={1}>
          <Box className={classes.justify} pb={1.5} pt={1}>
            <Box className={classes.align}>
              {i === 0 ? <ArrowForwardIcon /> : <ArrowBackIcon />}
              <Typography variant="body2" className={classes.weight}>
                {i === 0 ? t('chosen trip') : t('chosen returntrip')}
              </Typography>
            </Box>
            {i === 0 && !disableEdit && (
              <IconButton onClick={remove} className={classes.closeBtn}>
                <CancelIcon color="disabled" />
              </IconButton>
            )}
          </Box>

          <Box>
            <Box className={classes.align} pb={1.5}>
              <Typography>
                <Moment format="ddd D MMM">{flight.date}</Moment>
              </Typography>
              <Typography className={classes.weight}>
                {departTime} - {arriveTime}
              </Typography>
            </Box>
            <Box pb={1.5}>
              <Typography>
                <span className={`${!!isSameDir && !isSameDir.isFrom ? classes.invalidDName : ''}`}>
                  {from}
                </span>{' '}
                –{' '}
                <span className={`${!!isSameDir && !isSameDir.isTo ? classes.invalidDName : ''}`}>
                  {to}
                </span>
              </Typography>
            </Box>
            <Box className={classes.align} pb={2}>
              <GetCompanyIcon company={flight.company} />
              <Typography>
                <span className={classes.weight}>
                  {flight.formattedTravelTime}
                </span>{' '}
                {isDirect}
              </Typography>
              <Policy
                policy={flight.exceedsPolicy}
                deal={flight.isDealFare}
                corporate={flight.isCorporateFare}
              />
            </Box>
          </Box>
        </Box>

        {i === 0 && returning ? (
          <Box px={2}>
            <Divider />
          </Box>
        ) : null}
      </Fragment>
    )
  })
  return (
    <Fragment>
      {contentOnly ? renderFlights : (
        <Card elevation={0} className={classes.card}>
          {renderFlights}
        </Card>
      )}
    </Fragment>
  )
}

export default memo(FlightCard)
