import {makeStyles} from "@material-ui/core/styles";

export const useProfileStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  loyaltyHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  loyaltyBtn: {
    lineHeight: 'normal',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    marginLeft: '18px',
  },
  links: {
    textDecoration: 'none',
    color: theme.palette.common.black,
    opacity: 0.6,
  },
  active: {
    fontWeight: theme.typography.fontWeightBold,
    opacity: 1,
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(5.25),
    margin: 0,
  },
  saveBtn: {
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: theme.spacing(3),
    marginLeft: theme.spacing(1),
    letterSpacing: '1.1px',
    boxShadow: 'none',
    padding: theme.spacing(1, 3),
    '&:hover': {
      boxShadow: 'none'
    }
  },
  cancelBtn: {
    borderRadius: theme.spacing(3),
    fontWeight: theme.typography.fontWeightSemiBold,
    letterSpacing: '1.1px',
    padding: theme.spacing(1, 3),
  },
}))
