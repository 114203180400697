import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, Grid, IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import RedirectifAuth from '../../hooks/redirectIfAuth'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LandingHeader from '../../components/header/landingHeader'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    maxWidth: '500px',
    width: '100%',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
  },
  mobile: {
    height: '100vh',
    width: '100%',
    borderRadius: '0',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  icon: {
    position: 'absolute',
    left: theme.spacing(3),
    top: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const AuthWrapper = ({ children, onBackClicked, isModal }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const history = useHistory()

  const location = useLocation()

  const goBack = () => {
    if (typeof(onBackClicked) !== 'function') {
      return history.goBack()
    }
    onBackClicked()
  }

  return (
    <RedirectifAuth skipPublicityCheck>

      {!isModal && <LandingHeader />}

      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: isModal ? '10px' : '100vh' }}
      >
        <Box
          maxWidth="lg"
          className={!isMobile ? classes.formContainer : classes.mobile}
        >
          {
            location.pathname === '/forgot' || (
              <Box>
                <IconButton onClick={goBack}>
                  <ArrowBackIcon color="primary" />
                </IconButton>
              </Box>
            )
          }
          {children}
        </Box>
      </Grid>
    </RedirectifAuth>
  )
}

export default AuthWrapper
