import React, { useEffect, useState } from 'react'
import MenuButton from '../reusable/menuButton'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import HotelZoneFilterMenu from './hotelZoneFilterMenu'
import avropaZones from '../../constants/avropa_zones.json'
import {
  setEnableZoneFilter,
  setSeenZoneFilter,
} from '../../store/hotels/hotelsAction'

const useStyles = makeStyles((theme) => ({
  helpButton: {
    textTransform: 'unset',
    marginLeft: 'auto',
  },

  helpContainer: {
    padding: '6px',
  },

  helpTitle: {
    marginTop: '10px',
    color: 'black',
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightMedium,
  },

  loadingBar: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    paddingTop: '4px',
    paddingBottom: '4px',
  },

  seeAllButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'unset',
    border: 'unset',
    marginTop: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },

  nothingFoundContainer: {
    paddingTop: '60px',
    paddingBottom: '60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

function HotelZoneFilter({ onChange }) {
  const {
    search,
    seenZoneFilter,
    enableZoneFilter,
    zoneFilteredSearch,
    isSearchLoading,
    city,
  } = useSelector((state) => state.hotels)
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()

  const dispatch = useDispatch()

  const [selectedCity, setSelectedCity] = useState([])
  const [selectedZone, setSelectedZone] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [cities, setCities] = useState([])
  const [zones, setZones] = useState([])
  const [categories, setCategories] = useState([])
  const [showHelp, setShowHelp] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [showCities, setShowCities] = useState(false)
  const [showCategories, setShowCategories] = useState(false)
  const [showZones, setShowZones] = useState(false)

  const zoneField = isMobile ? 'AliasMobile' : 'AliasDesktop'

  const extractZone = (str) => {
    // Special case "postort övrig" for sorting
    if (str.includes('postort övrig')) {
      str = str.replace('postort övrig', 'Zon 100')
    }

    const regex = /^(.*?) zon \d+/

    const match = str.match(regex)

    if (match) return match[0]
    else return str
  }

  const getItems = (hotelList, propertyName, prefix, addCount = false) => {
    let grouped = hotelList
      .map((hotel) => hotel.avropaData?.[propertyName])
      .filter((a) => a)
      .map((a) => '' + a) // Coerce to string
      .reduce(function (r, e) {
        r[e] = (r[e] || 0) + 1
        return r
      }, {})

    let items = Object.keys(grouped)
      .sort((rawA, rawB) => {
        if (propertyName == 'Kategori') {
          return rawA > rawB ? -1 : 1
        }

        let a = extractZone(rawA)
        let b = extractZone(rawB)

        if (propertyName == zoneField) {
          // Stockholm special case
          if (a.includes('Stockholm') && !b.includes('Stockholm')) return -1
          if (!a.includes('Stockholm') && b.includes('Stockholm')) return 1
        }

        // Extract the non-numeric part of the strings
        const aWithoutNumber = a.replace(/\d+$/, '')
        const bWithoutNumber = b.replace(/\d+$/, '')

        // Compare the non-numeric parts first
        const compareNonNumeric = aWithoutNumber.localeCompare(
          bWithoutNumber,
          'sv',
          { sensitivity: 'base' }
        )

        // If the non-numeric parts are the same, compare the numeric parts
        if (compareNonNumeric === 0) {
          const aNumber = parseInt(a.match(/\d+$/)[0])
          const bNumber = parseInt(b.match(/\d+$/)[0])
          return aNumber - bNumber
        }

        return compareNonNumeric
      })
      .map((key) => ({
        text:
          (prefix ? prefix + ' ' : '') +
          (addCount ? key + ' (' + grouped[key] + ')' : key),
        value: key,
      }))

    return items
  }

  const anyZoneHotelExists = search?.find((h) => h.avropaData)

  const applyZoneColors = (listOfZones) => {
    //    console.table(avropaZones)
    listOfZones.forEach((zone) => {
      let anyMatchingHotel = search.find(
        (hotel) => hotel?.avropaData?.[zoneField] === zone.value
      )

      if (anyMatchingHotel) {
        const key = anyMatchingHotel.avropaData['Ort/Zon']
        let zoneData = avropaZones.find((z) => z.name == key)

        if (zoneData) {
          zone.color = zoneData.color
        }
      }
    })
  }

  useEffect(() => {
    let newCities = getItems(search, 'Ort', null, true)
    let newZones = getItems(search, zoneField, null, true)
    let newCategories = getItems(search, 'Kategori', t('category'), true)

    applyZoneColors(newZones)

    setCities(newCities)
    setZones(newZones)
    setCategories(newCategories)

    setSelectedCity([...newCities.map((c) => c.value)])
    setSelectedZone([...newZones.map((z) => z.value)])
    setSelectedCategory([...newCategories.map((c) => c.value)])

    if (!seenZoneFilter && enableZoneFilter) {
      setShowCategories(false)
      setShowZones(false)
      setShowCities(true)

      dispatch(setSeenZoneFilter(true))
    }
  }, [search])

  const getMatchingCityHotels = (city) =>
    search.filter((h) => {
      if (!city.includes(h.avropaData?.['Ort'])) return false
      return true
    })

  useEffect(() => {
    let matchingHotels = getMatchingCityHotels(selectedCity)
    let newZones = getItems(matchingHotels, zoneField, null, true)
    let newCategories = getItems(
      matchingHotels,
      'Kategori',
      t('category'),
      true
    )

    applyZoneColors(newZones)

    setZones(newZones)
    setCategories(newCategories)

    setSelectedZone([...newZones.map((z) => z.value)])
    setSelectedCategory([...newCategories.map((c) => c.value)])

    onChange(selectedCity, selectedZone, selectedCategory)
  }, [selectedCity])

  const getMatchingCategoryHotels = (city, zone) =>
    search.filter((h) => {
      if (!zone.includes(h.avropaData?.[zoneField])) return false
      if (!city.includes(h.avropaData?.['Ort'])) return false
      return true
    })

  useEffect(() => {
    let matchingHotels = getMatchingCategoryHotels(selectedCity, selectedZone)
    let newCategories = getItems(
      matchingHotels,
      'Kategori',
      t('category'),
      true
    )

    setCategories(newCategories)

    setSelectedCategory([...newCategories.map((c) => c.value)])

    onChange(selectedCity, selectedZone, selectedCategory)
  }, [selectedZone])

  useEffect(() => {
    onChange(selectedCity, selectedZone, selectedCategory)
  }, [selectedCategory])

  const closeMenus = () => {
    setShowCategories(false)
    setShowCities(false)
    setShowZones(false)
  }

  const showMenu = (menuToShow) => {
    setShowCategories(menuToShow === 'category' && !showCategories)
    setShowCities(menuToShow === 'city' && !showCities)
    setShowZones(menuToShow === 'zone' && !showZones)
  }

  const formatMenuLabel = (items, available, type, prefix) => {
    if (items.length == available.length)
      return t('all') + ' ' + t(type + ' plural')
    if (items.length == 1) return (prefix ? prefix + ' ' : '') + items[0]
    if (items.length == 0) return t('no') + ' ' + t(type + ' plural')
    return t('multiple') + ' ' + t(type + ' plural') + ' (' + items.length + ')'
  }

  const formatMenuTitle = (type) =>
    t('select among') + ' ' + t(type + ' plural')

  const handleSeeAll = () => {
    dispatch(setEnableZoneFilter(false))
  }

  if (isSearchLoading) {
    return (
      <Box className={classes.loadingBar}>
        <CircularProgress style={{ marginRight: '10px' }} size={20} />{' '}
        {t('zone hotels loading')}
      </Box>
    )
  }

  if (!anyZoneHotelExists) {
    return (
      <Box className={classes.nothingFoundContainer}>
        <div>{t('no zone hotels found')}</div>

        <Button
          onClick={handleSeeAll}
          className={classes.seeAllButton}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          {t('see all hotels') + city?.Location}
        </Button>
      </Box>
    )
  }

  return (
    <>
      <Grid container>
        <Box mr={1} display="flex">
          <MenuButton
            label={formatMenuLabel(selectedCity, cities, 'city')}
            onClick={() => showMenu('city')}
            isOpen={showCities}
          />
        </Box>
        <Box mr={1} display="flex">
          <MenuButton
            label={formatMenuLabel(selectedZone, zones, 'zone')}
            onClick={() => showMenu('zone')}
            isOpen={showZones}
          />
        </Box>
        <Box display="flex">
          <MenuButton
            label={formatMenuLabel(
              selectedCategory,
              categories,
              'category',
              t('category')
            )}
            onClick={() => showMenu('category')}
            isOpen={showCategories}
          />
        </Box>
        <Button
          variant="text"
          onClick={() => setShowHelp(!showHelp)}
          className={classes.helpButton}
        >
          {t('explain hotel zones')}
        </Button>
      </Grid>

      {showCities && (
        <HotelZoneFilterMenu
          items={cities}
          selected={selectedCity}
          onChange={setSelectedCity}
          onClose={closeMenus}
          title={formatMenuTitle('city')}
          onNext={() => showMenu('zone')}
        />
      )}

      {showZones && (
        <HotelZoneFilterMenu
          items={zones}
          selected={selectedZone}
          onChange={setSelectedZone}
          onClose={closeMenus}
          title={formatMenuTitle('zone')}
          onPrevious={() => showMenu('city')}
          onNext={() => showMenu('category')}
        />
      )}

      {showCategories && (
        <HotelZoneFilterMenu
          items={categories}
          selected={selectedCategory}
          onChange={setSelectedCategory}
          onClose={closeMenus}
          title={formatMenuTitle('category')}
          onPrevious={() => showMenu('zone')}
        />
      )}

      {showHelp && (
        <Box className={classes.helpContainer}>
          <div className={classes.helpTitle}>
            Hur fungerar Avropa-filtreringen för hotell?
          </div>
          <ol type="1" style={{ padding: '12px' }}>
            <li style={{ marginBottom: '12px' }}>
              Du gör dina val i filtermenyerna ovanför för att enklare hitta ett
              lämpligt hotell.
              <ol type="a" style={{ marginTop: '12px' }}>
                <li>
                  För Stockholm och Göteborg kan man välja mellan närliggande
                  orter.
                </li>
                <li>
                  För övriga städer, t.ex Kalmar eller Karlstad, så är fältet
                  för stad låst.
                </li>
                <li>
                  Inom varje stad kan du sedan välja bland olika kategorier.
                </li>
                <li>
                  Det tredje filtret gör det möjligt att filtrera på olika
                  kvalitetskategorier.
                </li>
              </ol>
            </li>

            <li>
              Resultaten visas i sökresultatlistan nedan allt eftersom du
              förfinar din sökning.
              <ol type="a" style={{ marginTop: '12px' }}>
                <li>
                  Resultaten visas i rangordningen inom respektive
                  Kvalitetskategori.
                </li>
                <li>
                  Om flera filter kombineras så sorterar vi i första hand på
                  rangordningen.
                </li>
              </ol>
            </li>
          </ol>
        </Box>
      )}
    </>
  )
}

export default HotelZoneFilter
