import React, { memo, useMemo, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as SeatSvg } from '../../../assets/train_seat.svg'
import { Box, MenuItem, MenuList, ClickAwayListener, Paper, Grow, Popper} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setTrainSeatAction } from '../../../store/train/trainAction'
import { useTranslation } from 'react-i18next'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import PetsIcon from '@material-ui/icons/Pets'
import LocalDiningIcon from '@material-ui/icons/LocalDining'
import CustomTooltop from '../../reusable/tooltip'

const seatWindowClass = {
  content: '""',
  display: 'block',
  height: '4px',
  position: 'absolute',
  border: '1px solid #33bef5',
  backgroundColor: '#ffffff',
  left: 0,
  right: 0,
}

const bubbleArrow = {
  content: '""',
  position: 'absolute',
  right: '50%',
  marginRight: '-10px',
  borderBottom: '10px solid white',
  borderRight: '10px solid transparent',
  borderLeft: '10px solid transparent',
  borderTop: '10px solid transparent',
}

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    display: 'grid',
    backgroundColor: 'white',
    gridTemplateColumns: p => `repeat(${p.columns}, 71px)`,
    gridTemplateRows: p => {
      const rSizes = p?.rowSizes
      return (!rSizes?.length) ? `repeat(${p.rows}, 54px)` : rSizes.map(s => `${s}px`).join(' ')
    },
  },
  seatMapDivider: {
    borderBottom: '4px solid #d8d8d8',
    borderTop: '4px solid #d8d8d8',
    backgroundColor: '#f4f4ee',
    minWidth: '80px'
  },
  menuList: {
    maxHeight: "90px",
    overflowY: "auto",
  },
  zoneArea: {
    border: '2px dashed #008BAD',
    borderTop: 'none',
    borderBottom: 'none',
    backgroundColor: '#f2fcfe',

    '&.environment-locomotive, &.environment-bistro': {
      backgroundColor: '#f7f7f7',
      border: 'none',
    }
  },
  seatOuter: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    position: 'relative',

    '& .facility-wc, & .facility-table, & .facility-bag-space': {
      border: '2px solid #d8d8d8',
      borderRadius: '5px',
      flex: 1
    },

    '& .facility-block .fac-txt': {
      fontSize: '16px',
      fontWeight: 'bold'
    },

    '&.block-sub-type-envRow': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&.topBorder': {borderTop: '4px solid #d8d8d8', paddingTop: '6px'},
    '&.bottomBorder': {borderBottom: '4px solid #d8d8d8', paddingBottom: '6px'},
    '&.rightBorder': {borderRight: '4px solid #d8d8d8'},
    '&.leftBorder': {borderLeft: '4px solid #d8d8d8'},
    '&.topWindow': {
      borderTop: '4px solid #d8d8d8',
      paddingTop: '6px',
      '&::before':{...seatWindowClass, top: '-4px'}
    },
    '&.bottomWindow': {
      borderBottom: '4px solid #d8d8d8',
      paddingBottom: '6px',
      '&::after': {...seatWindowClass, bottom: '-4px'}
    },
    '&.bottomWindow.noLeftBorder::after, &.topWindow.noLeftBorder::before': {
      borderLeft: 'none'
    },
    '&.bottomWindow.noRightBorder::after, &.topWindow.noRightBorder::before': {
      borderRight: 'none'
    },
  },
  seat: {
    flex: 1,
    height: '100%',
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '& .seat-number': {
      fontSize: '12px',
      color: 'white',
      fontWeight: 'bold',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&.west': { paddingRight: '12px'},
      '&.east': { paddingLeft: '12px'},
    }
  },
  seatIcon: {
    height: 'auto',
    width: 'auto',
    maxHeight: '38px',
    maxWidth: '37px',
  },
  menuHeader: {
    color: 'black',
    fontSize: '16px',
    textAlign: 'center',
    padding: '14px 0',
    borderBottom: '1px solid #d8d8d8',
  },
  userMenuItem: {
    color: 'black',
    fontSize: '14px',
    '&.selected': {
      backgroundColor: '#08c5b9',
      color: 'white',
    }
  },
  envTextBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    textTransform: 'uppercase',
    color: theme.palette.common.black
  },
  envIconBlock: {
    display: 'flex',
    width: '30px',
    height: '30px',
    justifyContent: 'center',
    padding: '3px',
    alignItems: 'center',
    backgroundColor: '#008BAD',
    borderRadius: '20px',

    '& .MuiSvgIcon-root': {
      maxHeight: '20px',
      width: '20px',
      height: 'auto',
      color: 'white'
    }
  },
  bistroIcon: {
    maxHeight: '20px',
    width: '20px',
    height: 'auto',
    color: theme.palette.common.black
  },
  usersPopper: {
    '&[x-placement=bottom]': {
      marginTop: '-5px',
      '& .MuiPaper-rounded::before': {
        ...bubbleArrow,
        top: '-20px',
      },
    },
    '&[x-placement=top]': {
      marginBottom: '-5px',
      '& .MuiPaper-rounded::before': {
        ...bubbleArrow,
        bottom: '-20px',
      },
    },
    '&[x-placement=top] .MuiPaper-rounded::before': {
      ...bubbleArrow,
      bottom: '-20px',
      transform: 'rotate(180deg)',
    },
    '& > .MuiPaper-rounded': {
      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 25px 0px',
    },
  },
}))

const DeckSeatsMap = React.forwardRef(({ isLast, isFirst, map, railidentifier, index }, ref) => {
  const { t } = useTranslation()
  const {decks, displayName:coach} = map || {}
  const deck = decks?.['001'] || {}
  const dispatch = useDispatch()
  const {columns = 0, rows = 0, seats, spaces = {}, facilities = {}} = deck
  const { travellers:passengers } = useSelector((state) => state.search.train)
  const {
    seats:selectedSeats,
    selectedOutGoingTrip,
    selectedReturnTrip,
    accommodations
  } = useSelector((state) => state.train)
  const [menuOpened, setMenuOpened] = useState(false)
  const train = index === 0 ? selectedOutGoingTrip : selectedReturnTrip
  const segment = train?.train?.segments[0]
  const operator = segment?.railserviceProvider?.Code
  const accommodationKey = index === 0 ? 'outgoing' : 'returning'
  const chargeableSeatAmount =
      (
        accommodations && accommodations[accommodationKey])
        ? parseInt(accommodations[accommodationKey]?.[railidentifier]?.['opts']?.chargeablePrice)
        : 0
  const refs = useMemo( () => {
      const res = {}
      for (let uKey in seats) {
        res[uKey] = React.createRef()
      }
      return res
  }, [seats])
  const railSelectedSeats = useMemo(() => selectedSeats?.[railidentifier], [railidentifier, selectedSeats])
  const prepared = useMemo(() => ({...seats, ...spaces, ...facilities}), [seats, spaces, facilities])

  const rowSizes = useMemo(() => {
    const sizes = []
    for (let rInd = 0; rInd < rows; rInd++) {
      let rSize = 32

      for (let cInd = 0; cInd < columns; cInd++) {
        const key = `${cInd}X${rInd}`

        const emptyTypes = ['space', 'exit-hall']
        const tmpType = prepared?.[key]?.type

        if (!!tmpType && !emptyTypes.includes(tmpType)) {
          rSize = (rInd === 0 || rInd === rows - 1) ? 56 : 52
          break
        }
      }
      sizes.push(rSize)
    }
    return sizes
  }, [rows, columns, prepared])

  const classes = useStyles({columns, rows, rowSizes})

  const coachSeats = {}
  const seatsByUser = {}

  if (railSelectedSeats) {
    for (let uId in railSelectedSeats) {
      const tmp = railSelectedSeats[uId]
      const tmpSeatNum = tmp?.exactSeat?.seatNum
      const tmpCoach = tmp?.exactSeat?.coach

      if (tmpSeatNum) {
        seatsByUser[uId] = {coach: tmpCoach, num: tmpSeatNum}
      }
      // eslint-disable-next-line
      if (tmpCoach == coach && tmpSeatNum) {
        coachSeats[tmpSeatNum] = {...tmp.exactSeat, uId }
      }
    }
  }

  const handleClose = () => {
    setMenuOpened(false)
  }

  const handleSeatClick = (unitKey, available) => {
    if (menuOpened || !available  || !unitKey) return
    if (passengers.length === 1) {
      handleSelect(passengers[0].uniqueId, unitKey)
      setMenuOpened(false)
    } else {
      setMenuOpened(!!menuOpened ? false : unitKey)
    }
  }

  const handleSelect = (uId, forceSeat = null) => {
    const prevSeat = railSelectedSeats && railSelectedSeats?.[uId]?.exactSeat;
    const seat = seats?.[!!forceSeat ? forceSeat : menuOpened]
    if (!seat) return setMenuOpened(false)
    const {
      number:seatNum
    } = seat
    const selectedByU = coachSeats?.[seatNum]?.uId
    if (selectedByU) {
      dispatch(setTrainSeatAction({
        accom: false,
        railindentifier: railidentifier,
        userId: selectedByU,
      }))
    }

    let accom = {
      exactSeat: { coach, seatNum }
    }
    if (operator === 'ORS' && prevSeat?.coach === coach && prevSeat?.seatNum === seatNum) {
      accom = false
    }
    const payload = {
      accom,
      railindentifier: railidentifier,
      userId: uId,
      chargeableSeatAmount: accom ? chargeableSeatAmount : 0
    }
    dispatch(setTrainSeatAction(payload))
  }
  const selectedByUKey = {}

  const renderSeats = () => {
    const units = []

    if (spaces) {
      const zonesEnv = ['015', 'pet', 'locomotive', 'bistro']
      for (let currSpaceKey in spaces) {
        const currSpace = spaces[currSpaceKey]
        const env = currSpace?.environment || ''
        const subType = currSpace?.subType || ''
        if (subType !== 'envRow' || !zonesEnv.includes(env) || currSpace.length < 2) continue;

        const zoneCStart = currSpace.col + 1
        let zoneStyle = {
          gridColumn: `${zoneCStart} / ${zoneCStart + currSpace.length}`,
          gridRow: `1 / ${rows + 1}`,
        }
        units.push(<Box key={'zone' + currSpaceKey} style={zoneStyle} className={`${classes.zoneArea} environment-${env}`}/>)
      }
    }

    for (let unitKey in prepared) {
      const {
        col, row, position,
        width, length, number,
        type, dir, subType,
        available, environment
      } = prepared[unitKey]
      const colStart = col + 1
      const colEnd = colStart + (length - 1)
      const rowStart = row + 1
      const rowEnd = rowStart + (width - 1)
      let blockIcon = null

      const isSelected = coachSeats?.[number]
      let seatStyle = {}

      if (isSelected) {
        selectedByUKey[unitKey] = isSelected.uId
      }

      const isExit = (type === 'exit-hall')
      const nextInRow = prepared[(col+1) + 'X' + (row)] || {}
      const prevInRow = prepared[(col-1) + 'X' + (row)] || {}
      const {position:prevPos} = prevInRow
      const {position:nextPos} = nextInRow

      const styles = {
        gridColumn: `${colStart} / ${colEnd + 1}`,
        gridRow: `${rowStart} / ${rowEnd + 1}`,
        cursor: (available) ? 'pointer' : 'default',
      }

      const outerClasses = [classes.seatOuter]

      if (environment) {
        outerClasses.push('environment-'+environment)
      }

      if (subType) {
        outerClasses.push('block-sub-type-'+subType)
      }

      if (subType === 'envRow' && type === 'space') {
        switch (environment) {
          case '015':
            blockIcon = (
              <Box className={classes.envIconBlock}>
                <VolumeOffIcon/>
              </Box>
            )
          break;

          case 'pet':
            blockIcon = (
              <Box className={classes.envIconBlock}>
                <PetsIcon/>
              </Box>
            )
          break;

          case 'bistro':
            blockIcon = (
              <Fragment>
                <LocalDiningIcon className={classes.bistroIcon}/>
                <Box ml={1} className={classes.envTextBlock}>{t('bistro')}</Box>
              </Fragment>
            )
          break;

          case 'locomotive':
            blockIcon = (<Box className={classes.envTextBlock}>{t('locomotive')}</Box>)
          break;

          default:
            blockIcon = null
          break;
        }
      }

      if (((colStart === 1 && !isFirst) || (colEnd === columns && !isLast)) && !isExit) {
        const borderClass = (colStart === 1) ? 'leftBorder' : 'rightBorder'
        outerClasses.push(borderClass)
      }

      if (type === 'seat') {
        seatStyle = {
          color: (available) ? (isSelected ? '#08c5b9' : '#333333') : '#e2e2e3',
          transform: (dir === 'WEST') ? 'rotate(180deg)' : 'rotate(0deg)',
        };
      }

      if ((rowStart === 1 || rowEnd === rows) && !isExit) {
        const borderType = (position === '002') ? 'Window' : 'Border'
        const borderPos = (rowStart === 1) ? 'top' : 'bottom'
        outerClasses.push(borderPos+borderType)

        if (prevPos === position && position === '002') outerClasses.push('noLeftBorder')
        if (nextPos === position && position === '002') outerClasses.push('noRightBorder')
      }

      switch (type) {
        case 'seat':
          units.push(
            <Box key={'seat-' + unitKey} p={0.5} style={styles} className={outerClasses.join(' ')}>
              <CustomTooltop title={`${operator === 'ORS' && available && chargeableSeatAmount > 0 ? `${chargeableSeatAmount}kr` : ''}`} placement={'top'}>
                <Box
                  ref={refs[unitKey]}
                  className={classes.seat}
                  onClick={() => handleSeatClick(unitKey, available)}
                >
                  <Box className={`seat-number ${(dir || '').toLowerCase()}`}>{number}</Box>
                  <SeatSvg style={seatStyle}/>
                </Box>
              </CustomTooltop>
            </Box>
          );
        break;
        case 'space':
          units.push(
            <Box key={'space-' + unitKey} p={0.5} style={styles} className={outerClasses.join(' ')}>
              {blockIcon}
            </Box>
          );
        break;
        default:
          units.push(
            <Box key={`${type}-` + unitKey} p={0.5} style={styles} className={outerClasses.join(' ')}>
              <Box className={`facility-block facility-${type}`}>
                {type === 'wc' &&
                  <span className={'fac-txt'}>WC</span>
                }
                {type === 'bag-space' &&
                  <span className={'fac-txt'}>BG</span>
                }
              </Box>
            </Box>
          );
        break;
      }

    }

    return units;
  }

  return (
    <Box display={'flex'}>
      {!isFirst && <Box key={`divider-${railidentifier}`} className={classes.seatMapDivider}/>}
      <Box ref={ref} className={classes.rootGrid}>
        {!!seats &&
        <>
          {renderSeats()}
          <Popper className={classes.usersPopper} style={{ zIndex: 2}} open={!!menuOpened} anchorEl={refs?.[menuOpened]?.current || null} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box>
                    <Box className={classes.menuHeader}>
                      <span>{t('traveler to move')}?</span>
                    </Box>
                      <MenuList className={classes.menuList} autoFocusItem={!!menuOpened} id="menu-list-grow">
                        {passengers.map( p => {
                          const name = `${p.firstName} ${p.lastName}`
                          const key = 'passenger-' + p.uniqueId
                          const selectedUID = selectedByUKey?.[menuOpened]
                          const selectedByU = (selectedUID && p.uniqueId === selectedUID)
                          const menuClass = [classes.userMenuItem]
                          if (selectedByU) menuClass.push('selected')

                          return (
                            <MenuItem
                              className={menuClass.join(' ')}
                              key={key}
                              onClick={() => {
                                if (!selectedByU || operator === 'ORS') handleSelect(p.uniqueId)
                                setMenuOpened(false)
                              }}
                            >
                              <span>
                                <span style={{fontWeight: '600'}}>{name}{' '}</span>
                                <span>{!!seatsByUser[p.uniqueId]?.num && ` (${t('seat')} ${seatsByUser[p.uniqueId].num})`} </span>
                              </span>
                            </MenuItem>
                          )
                        })}
                      </MenuList>
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
        }
      </Box>
    </Box>

  )
})

export default memo(DeckSeatsMap)
