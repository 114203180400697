import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAgencyCompany } from '../store/auth/authAction'
import { setSearchTravellers } from '../store/search/searchAction'
import usePrevious from '../hooks/usePrevious'

const useTravellers = (passengersRedux) => {
  const { user, agentCompany } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const [travellers, setTravellers] = useState([])
  const [updCount, setUpdCount] = useState(0)

  const previousTravellers = usePrevious(travellers)

  const setAgentCompany = (cmp) => {
    const resCompany = !!cmp?.uniqueId && !!cmp?.name ? { ...cmp } : null
    if (
      (!agentCompany && !resCompany) ||
      agentCompany?.uniqueId === resCompany?.uniqueId
    )
      return
    dispatch(setAgencyCompany(resCompany))
    setTravellers([])
  }

  // Set travellers for all search if travellers changed.
  // The change check is needed to avoid endless re-render due to state changes.
  useEffect(() => {
    if (
      travellers &&
      previousTravellers &&
      travellers.length != previousTravellers.length
    ) {
      dispatch(setSearchTravellers(travellers))
    }
  }, [travellers])

  useEffect(() => {
    if (
      !!user?.isBookingLink &&
      updCount <= 0 &&
      (!passengersRedux || !passengersRedux.length)
    ) {
      setTravellers([
        {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          mobile: user?.contacts?.phone || null,
          uniqueId: 'link-user-id',
          isGuest: true,
        },
      ])
    } else {
      setTravellers(passengersRedux)
    }
    setUpdCount(updCount + 1)
    // eslint-disable-next-line
  }, [passengersRedux, setTravellers, setUpdCount])

  return { travellers, setTravellers, user, setAgentCompany }
}

export default useTravellers
