import React, {
  useState,
  useMemo,
  Fragment, useCallback, useEffect
} from 'react'
import {Box, Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import DoneIcon from '@material-ui/icons/Done'


const useStyles = makeStyles((theme) => ({
 filterButton: {
  border: `1px solid ${theme.palette.border}`,
  padding: '10px 15px',
  position: 'relative',
  borderRadius: '50px',
  color: 'black',
  minWidth: '140px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
 },

  buttonChevron: {
    display: 'inline-flex'
  },

  listBlock: {
    position: 'absolute',
    zIndex: 2,
    top: '-1px',
    left: '-1px',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '4px',
    backgroundColor: 'white',
    width: 'auto',
    minWidth: '100%',
    padding: '12px',
    cursor: 'default',
  },

  noOptionsText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  manageBlock: {
    display: 'flex',
    minWidth: '100%',
    justifyContent: 'center',
  },

  manageLine: {
    minHeight: '20px',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: '10px',
    flexWrap: 'nowrap',
  },

  manageLineBlock: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',

    '&.right': {
      justifyContent: 'start',
    }
  },

  manageLineDevider: {
    height: '20px',
    display: 'inline-block',
    width: '1px',
    margin: '0 10px',
    backgroundColor: 'black',
  },

  manageLineBtn: {
    whiteSpace: 'nowrap',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
  },

  filtersList: {
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: 0,
    }
  },

  filterItem: {
    cursor: 'pointer',
    display: 'flex',
    borderRadius: '4px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    minWidth: '100%',
    height: '23px',
    flexWrap: 'nowrap',

    '&:hover': {
      '& $onlyBtn': {
        visibility: 'visible'
      }
    }
  },

  checkmarkIcon: {
    lineHeight: '23px',
    height: 'auto',
    marginRight: '10px'
  },

  placeholder: {
    width: '34px'
  },

  filterText: {
    color: 'black',
    fontSize: '13px',
  },

  onlyBtn: {
    display: 'inline-block',
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: '5px',
    visibility: 'hidden'
  },

  submitBtn: {
    width: '100%',
    height: '27px',
    color: 'white',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '13px',
    backgroundColor: '#0ac5b8',
    borderRadius: '4px',
    fontWeight: '600',
    cursor: 'pointer'
  }

}))

const FilterButton = ({
  fullWidth,
  list,
  selected,
  onSelect,
  isMulti = false,
  nullText = null,
  allText = null,
  multiLocaleKey = null
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isInit, setIsInit] = useState(false)
  const [tmpSelected, setTmpSelected] = useState([])

  const closeFilter = useCallback(() => {
    setTmpSelected([]);
    setIsOpen(false);
  }, [setIsOpen, setTmpSelected])

  useEffect(() => {
    document.addEventListener('click', closeFilter)

    return () => {
      document.removeEventListener('click', closeFilter)
    }
  }, [closeFilter]);

  const open = (e) => {
    document.body.click() //safe global click
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setTimeout(() => {
      let newTmpSelected = []
      if (!!selected) {
        newTmpSelected = (!!Array.isArray(selected)) ? [...selected] : [selected]
      }
      setTmpSelected(newTmpSelected)
      setIsOpen(true)
    }, 1) //next tick
  }

  const selectOnly = (e, val) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation();
    if (!isMulti) return false;
    setTmpSelected([val])
  }
  const done = (e) => {
    if (!!e) {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation();
    }
    if (!isOpen) return false
    let resVal = null
    if (!!tmpSelected?.length) {
      resVal = isMulti ? [...tmpSelected] : tmpSelected[0]
    }
    setIsOpen(false)
    if (!resVal && !nullText) return false
    onSelect(resVal)
  }
  const toggle = (e, val) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation();
    if (!val || !isOpen) return false;
    const isDeselect = tmpSelected.includes(val)
    let newTmpSelected = [...tmpSelected]
    if (isDeselect) {
      if (!!nullText || newTmpSelected.length > 1) newTmpSelected = newTmpSelected.filter(s => s !== val)
    } else if (isMulti) {
      newTmpSelected.push(val)
    } else {
      setIsOpen(false)
      onSelect(val)
      return true
    }
    setTmpSelected(newTmpSelected)
  }

  const selectAll = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation();
    if (!isMulti) return false;
    const allValues = list.map(i => i.val).filter(v => !!v)
    if (!allValues.length) return false;
    setTmpSelected(allValues)
  }

  const deselectAll = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation();
    if (!isMulti || !tmpSelected.length) return false;
    if (!nullText) {
      setTmpSelected([tmpSelected[0]])
    } else {
      setTmpSelected([])
    }
  }

  const currSelected = useMemo(() => {
    if (isOpen) return tmpSelected
    let resSelected = []
    if (!!selected) {
      resSelected = (!!Array.isArray(selected)) ? [...selected] : [selected]
    }
    return resSelected
  }, [tmpSelected, selected])


  const selectedText = useMemo(() => {
    if (!selected) return nullText
    if (!isMulti) {
      const found = (list || []).find(f => !!f.val && selected === f.val)
      return found?.text || selected || ''
    } else {
      let tmpSelected = []
      if (selected) {
        tmpSelected = (Array.isArray(selected)) ? [...selected] : [selected]
        if (tmpSelected.length > 1) {
          const possibleValues = list.map(f => f.val)
          const notUsedValues = possibleValues.filter(v => !tmpSelected.includes(v))
          if (!notUsedValues.length && tmpSelected.length > 0 && allText) {
            return allText
          }
          return t(multiLocaleKey || 'options selected', {count: tmpSelected.length})
        }
        const tmpVal = tmpSelected[0]
        const found = list.find(f => f.val === tmpVal)
        return found?.text || tmpVal;
      }
    }
  },  [nullText, selected, isMulti, list, multiLocaleKey, allText])

  const options = useMemo(() => {
    return list.map(f => {
      return {
        selected: currSelected.includes(f.val),
        ...f
      }
    })
  }, [list, currSelected])

  const dynamicStyles = {}
  if (!!fullWidth) dynamicStyles.width = '100%';

  return (
    <Box key="filters-line-outer" onClick={open} style={dynamicStyles} className={classes.filterButton}>
      <Box key="left-btn-block" flexGrow={1}>{selectedText}</Box>
      <Box key="right-btn-block" className={classes.buttonChevron}>
        <KeyboardArrowDownIcon
            key="chevron-down"
            className={classes.chevronDown}
        />
      </Box>

      {!!isOpen && (
        <Box key="list-block" className={classes.listBlock}>
          {!options.length ? (
            <Box key="no-options-text" className={classes.noOptionsText}>
              {t('no options')}
            </Box>
          ) : (
            <Fragment>
              <Box key="manage-block" className={classes.manageBlock}>
                {!!isMulti && (
                  <Grid key="manage-line" container spacing={0} className={`${classes.manageLine}`}>
                    <Grid key="manage-line-left" xs={true} className={classes.manageLineBlock}>
                      <Box key="select-all-btn" onClick={selectAll} className={classes.manageLineBtn}>
                        {t('select all')}
                      </Box>
                    </Grid>
                    <Grid key="manage-line-devider" xs={'auto'} className={classes.manageLineDevider}/>
                    <Grid key="manage-line-right" xs={true} className={`${classes.manageLineBlock} right`}>
                      <Box key="select-all-btn" onClick={deselectAll} className={classes.manageLineBtn}>
                        {t('clear all')}
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box key="filters-list" className={classes.filtersList}>
                {options.map((s, index) => (
                  <Box
                      key={`filters-list-item-${index}`}
                      onClick={(e) => toggle(e, s.val)}
                      className={classes.filterItem}
                  >
                    {!!s.selected ? (
                      <DoneIcon
                        key="checkmark-icon"
                        className={classes.checkmarkIcon}
                      />
                    ) : (
                      <div key="placeholder" className={classes.placeholder}/>
                    )}

                    <span key="filter-text" className={classes.filterText}>{s.text}</span>
                    {!!isMulti && (
                      <span
                          key="only-btn"
                          className={classes.onlyBtn}
                          onClick={(e) => selectOnly(e, s.val)}
                      >
                        {t('only')}
                      </span>
                    )}
                  </Box>
                ))}
              </Box>
            </Fragment>
          )}

          {!!isMulti && (
              <Box key="submit-btn" onClick={done} className={classes.submitBtn}>
                {t('done')}
              </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default FilterButton
