import React, {useEffect} from 'react'
import {Box, Grid, TextField, Button} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import usePaymentInputs from 'react-payment-inputs/es/usePaymentInputs'
import { cardNames } from '../../utils/general'
import * as Yup from "yup";
import valid from "card-validator";
import NotifyBlock from "../../containers/checkout/details/notifyBlock";
import ModalHook from '../../containers/modals/reusable/modalHook'
import { useFormik } from 'formik'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },

  guaranteeNoteHead: {
    fontSize: '17px',
    marginBottom: '8px'
  },

  doneBtn: {
    padding: '9px 47px',
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: 'none',
    letterSpacing: '0.7px'
  },

  paymentField: {
    '& input': {
      fontWeight: '500',
    },
    '& .Mui-disabled input': {
      padding: '18px 0 18px',
    },
    '& .Mui-disabled .MuiInputAdornment-filled': {
      marginTop: '0',
    },
    '& .MuiFilledInput-underline': {
      borderRadius: 6,

      '&:before': {
        border: 'none',
      },
    },

    '& fieldset': {
      borderRadius: 6,
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}))

const guaranteeCardModal = ({ guarantee, onSelect, hotelPrice }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const dateRef = React.useRef()
  const cvvRef = React.useRef()
  const { getCardNumberProps, getExpiryDateProps, meta } = usePaymentInputs()

  const validationSchema = Yup.object().shape({
    cardNumber: Yup.string()
      .nullable()
      .required(t('required field'))
      .test(
        'test-number',
        t('invalid card'),
        (value) => valid.number(value).isValid
      ),
    ccCode: Yup.string()
      .nullable()
      .required(t('unknown card type')),
    date: Yup.string()
      .nullable()
      .required(t('required field'))
      .test(
        'test-date',
        t('invalid date'),
        (value) => valid.expirationDate(value).isValid
      ),
    cvv: guarantee
      ? Yup.string().nullable()
      : Yup.string()
        .nullable()
        .required(t('required field'))
        .test(
          'test-cvv',
          t('invalid cvc'),
          (value) => valid.cvv(value).isValid
        ),
  });

  const initialValues = {
    cardNumber: null,
    date: null,
    cvv: null,
    ccCode: null,
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => { onSelect(values) },
  });


  // !!! no needed execute when cc changed in store !!!
  useEffect(() => {
    let ccType = meta?.cardType?.type
    ccType = ccType && cardNames[ccType] && !meta?.erroredInputs?.cardNumber ? cardNames[ccType] : null;
    if (formik.values.ccCode !== ccType) formik.setFieldValue('ccCode', ccType);

  }, [meta, formik.values.ccCode])
  const replaces = {
    amount: hotelPrice,
    currencyCode: 'SEK',
  }
  const guaranteeNote = t(guarantee ? 'email booking guarantee notify' : 'hotel-only-case-4-non-free', replaces)

  const getCCForm = () => {
    return (
      <Box
        pb={2}
        display="flex"
        flexDirection="column"
      >
        <TextField
          name="cardNumber"
          value={formik.values.cardNumber}
          inputProps={{
            maxLength: 19,
            ...getCardNumberProps({
              onChange: (e) => {
                if (e.target.value.length > 18) {
                  dateRef.current.focus()
                }
                formik.handleChange(e)
              },
            }),
          }}
          error={formik.touched.cardNumber && (formik.errors.cardNumber || formik.errors.ccCode)}
          helperText={formik.touched.cardNumber && (formik.errors.cardNumber || formik.errors.ccCode)}
          className={`${classes.marginBottom} ${classes.paymentField}`}
          placeholder={
            guarantee
              ? t('guarantee card number')
              : t('enter card number')
          }
          variant="outlined"
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CreditCardIcon style={{ color: '#999999' }} />
              </InputAdornment>
            ),
          }}
        />
        <Box display="flex">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{
                  maxLength: 7,
                  ...getExpiryDateProps(),
                }}
                name="date"
                value={formik.values.date}
                onChange={(e) => {
                  if (e.target.value.length > 6) {
                    cvvRef.current.focus()
                  }
                  formik.setFieldValue('date', e.target.value)
                }}
                error={formik.touched.date && formik.errors.date}
                inputRef={dateRef}
                helperText={formik.touched.date && formik.errors.date}
                className={classes.paymentField}
                variant="outlined"
                fullWidth
                placeholder="MM/YY"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InsertInvitationIcon style={{ color: '#999999' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="cvv"
                inputProps={{
                  maxLength: 4,
                }}
                value={formik.values.cvv}
                onChange={formik.handleChange}
                className={classes.paymentField}
                inputRef={cvvRef}
                error={formik.touched.cvv && formik.errors.cvv}
                helperText={formik.touched.cvv && formik.errors.cvv}
                placeholder={
                  'CVC' + (guarantee ? ` (${t('not applicable')})` : '')
                }
                variant={guarantee ? 'filled' : 'outlined'}
                disabled={guarantee}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HttpsOutlinedIcon style={{ color: '#999999' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }
  const noteStyles = {
    text: {
      fontSize: '14px',
      fontWeight: 'normal'
    },
    textOuter: {
      lineHeight: '18px'
    },
    outer: {
      borderRadius: '8px',
    },
    inner: {
      padding: '20px'
    }
  }
  return (
    <ModalHook width={550} modalBoolean={true}>
      <Box key={'modal-inner'} p={'40px 60px'}>
        <Box key="hotel-guarantee-notify">
          <Box key="note-head" className={classes.guaranteeNoteHead}>
            <span className={classes.bold}>{t('payment')}</span> {t(guarantee ? 'hotel requires guarantee' : 'hotel requires deposit')}
          </Box>
          <NotifyBlock noSubNote={true} noteType={guarantee ? 'paper' : 'hotelPayNotify'} noteText={guaranteeNote} customStyles={noteStyles}/>
        </Box>
        {getCCForm()}
        <Box key={"button-block"} display={'flex'} justifyContent={'end'}>
          <Button variant={"contained"} color={"primary"} className={classes.doneBtn} onClick={formik.handleSubmit}>{t('done')}</Button>
        </Box>
      </Box>
    </ModalHook>
  )
}
export default guaranteeCardModal
