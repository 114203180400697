import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography, TextField } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { setCreditCardData } from '../../../store/checkout/checkoutActions'
import { useDispatch, useSelector } from 'react-redux'
import { getHotelsCheckoutInfo } from '../../../utils/general'
import HotelNotify from './hotelNotify'
import { useFormikContext } from 'formik'
import usePaymentInputs from 'react-payment-inputs/es/usePaymentInputs'
import { GuaranteeContext } from '../../../pages/checkout/checkout'
import { cardNames } from '../../../utils/general'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  normalText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  hotelPayNotify: {
    backgroundColor: theme.palette.secondary.light,
  },
  paymentField: {
    '& input': {
      fontWeight: '500',
    },
    '& .Mui-disabled input': {
      padding: '18px 12px 18px',
    },
    '& .Mui-disabled .MuiInputAdornment-filled': {
      marginTop: '0',
    },
    '& .MuiFilledInput-underline': {
      borderRadius: 6,

      '&:before': {
        border: 'none',
      },
    },

    '& fieldset': {
      borderRadius: 6,
    },
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  marginBotton: {
    marginBottom: theme.spacing(2),
  },
}))
const delayKeys = []

const WarrantCard = ({ fops }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { items, fop, creditCard, hotelGuarantee } = useSelector(
    (state) => state.checkout
  )
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const formik = useFormikContext()
  const dateRef = React.useRef()
  const cvvRef = React.useRef()
  const { getCardNumberProps, getExpiryDateProps, meta } = usePaymentInputs()

  const isHotelGuarantee = fops?.perFop?.['cc']?.includes('Hotel')
  const hotelHeaders = getHotelsCheckoutInfo(items)
  const isHotelPayment = (isHotelGuarantee && hotelHeaders && !hotelHeaders.isLeisure && !hotelHeaders.isGuarantee)
  const onlyCardGuarantee = fops?.perFop?.['cc']?.every(itmType => itmType === 'Hotel' || itmType === 'Car')
  const isGuaranteeCC = (onlyCardGuarantee && !isHotelPayment)
  const hideCcForm = isGuaranteeCC && !!hotelGuarantee && fop !== 'cc'
  const useOnlySavedCC = hideCcForm ? hotelGuarantee.type : false
  const [isChecked, setIsChecked] = useState(false)
  const handleChange = () => setIsChecked(!isChecked)
  const { setGuarantee } = useContext(GuaranteeContext)

  useEffect(() => {
    setGuarantee(isGuaranteeCC)
  }, [isGuaranteeCC, setGuarantee])

  const setCcField = useCallback(
    (type, value) => {
      if (delayKeys[type]) {
        clearTimeout(delayKeys[type])
        delayKeys[type] = null
      }
      delayKeys[type] = setTimeout(() => {
        dispatch(setCreditCardData({ ...creditCard, [type]: value }))
      }, 100)
    },
    [creditCard, dispatch]
  )

  // !!! no needed execute when cc changed in store !!!
  useEffect(() => {
    const currentVal = creditCard?.number || null
    const newVal = formik.values.cardNumber || null

    if (currentVal !== newVal) setCcField('number', newVal)
  }, [setCcField, formik.values.cardNumber])

  // !!! no needed execute when cc changed in store !!!
  useEffect(() => {
    const currentVal = creditCard?.expire || null
    const newVal = formik.values.date || null

    if (currentVal !== newVal) setCcField('expire', newVal)
  }, [setCcField, formik.values.date])

  // !!! no needed execute when cc changed in store !!!
  useEffect(() => {
    const currentVal = creditCard?.cvv || null
    const newVal = formik.values.cvv || null

    if (currentVal !== newVal) setCcField('cvv', newVal)
  }, [setCcField, formik.values.cvv])

  // !!! no needed execute when cc changed in store !!!
  useEffect(() => {
    let ccType = meta?.cardType?.type
    ccType =
      ccType && cardNames[ccType] && !meta?.erroredInputs?.cardNumber
        ? cardNames[ccType]
        : null

    const currentVal = creditCard?.ccCode || null
    if (currentVal !== ccType) {
      setCcField('ccCode', ccType)
    }
  }, [setCcField, meta])
  const pt = fop === 'cc' ? 0 : 4

  const getCCForm = () => {
    return (
      <>
        {fop !== 'cc' && (
          <Box py={0}>
            <FormControl component="fieldset" className={classes.checkbox}>
              <FormControlLabel
                className={classes.checkbox}
                value={'cc'}
                control={
                  <Radio
                    checked={true}
                    className={classes.radioInput}
                    color="primary"
                  />
                }
                label={<span>{t('payment card')}</span>}
              />
            </FormControl>
          </Box>
        )}
        <Box
          pt={0}
          pb={isDesktop ? 8 : 4}
          mt={1}
          display="flex"
          flexDirection="column"
        >
          <TextField
            name="cardNumber"
            value={formik.values.cardNumber}
            inputProps={{
              maxLength: 19,
              ...getCardNumberProps({
                onChange: (e) => {
                  if (e.target.value.length > 18) {
                    dateRef.current.focus()
                  }
                  formik.handleChange(e)
                },
              }),
            }}
            error={formik.touched.cardNumber && formik.errors.cardNumber}
            helperText={formik.touched.cardNumber && formik.errors.cardNumber}
            className={`${classes.marginBotton} ${classes.paymentField}`}
            placeholder={
              isGuaranteeCC
                ? t('guarantee card number')
                : t('enter card number')
            }
            variant="outlined"
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CreditCardIcon style={{ color: '#999999' }} />
                </InputAdornment>
              ),
            }}
          />
          <Box display="flex">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{
                    maxLength: 7,
                    ...getExpiryDateProps(),
                  }}
                  name="date"
                  value={formik.values.date}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      cvvRef.current.focus()
                    }
                    formik.setFieldValue('date', e.target.value)
                  }}
                  error={formik.touched.date && formik.errors.date}
                  inputRef={dateRef}
                  helperText={formik.touched.date && formik.errors.date}
                  className={classes.paymentField}
                  variant="outlined"
                  fullWidth
                  placeholder="MM/YY"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InsertInvitationIcon style={{ color: '#999999' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="cvv"
                  inputProps={{
                    maxLength: 4,
                  }}
                  value={formik.values.cvv}
                  onChange={formik.handleChange}
                  className={classes.paymentField}
                  inputRef={cvvRef}
                  error={formik.touched.cvv && formik.errors.cvv}
                  helperText={formik.touched.cvv && formik.errors.cvv}
                  placeholder={
                    'CVC' + (isGuaranteeCC ? ` (${t('not applicable')})` : '')
                  }
                  variant={isGuaranteeCC ? 'filled' : 'outlined'}
                  disabled={isGuaranteeCC}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HttpsOutlinedIcon style={{ color: '#999999' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          {isHotelGuarantee && !isHotelPayment && (
            <Box mt={2} ml={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleChange}
                      color="primary"
                      name={t('guarantee save credit card to user profile')}
                    />
                  }
                  label={t('guarantee save credit card to user profile')}
                />
              </FormGroup>
            </Box>
          )}
        </Box>
      </>
    )
  }

  return (
    <Box px={isDesktop ? 0 : 4} pt={isDesktop ? 0 : pt} pb={2}>
      {onlyCardGuarantee && fop !== 'cc' && (
        <Box mt={isDesktop ? 3 : 1} mb={isDesktop ? 1 : 2} p={isDesktop ? 2 : 0}>
          <Typography variant="h5" className={classes.bold}>
            <span>{t('payment')}</span>
            <span className={classes.normalText}>
              {' '}
              {(hotelHeaders?.paymentHeader || '').toLowerCase()}
            </span>
          </Typography>
        </Box>
      )}
      <Box px={isDesktop ? 2 : 0}>
        {!!isHotelGuarantee && fop !== 'cc' && (
          <HotelNotify fops={fops} useOnlySavedCC={useOnlySavedCC} />
        )}
        <Box mt={1}>{!hideCcForm && getCCForm()}</Box>
      </Box>
    </Box>
  )
}
export default WarrantCard
