import React, {memo, useContext} from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {useSelector} from "react-redux"
import {useTranslation} from "react-i18next"
import {CarSearchContext} from '../../../../../pages/cars/carsSearchResults'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cityText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dateText: {
    color: theme.palette.dimGray,
  },
  editBtn: {
    background: theme.palette.primary.medium,
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.primary.main,
    height: '24px',
    padding: 0,
    marginLeft: '4px'
  }
}))

const CarsType = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {cars: searchCars} = useSelector((state) => state.search)
  const { isMiddlestep, actionHandler } = useContext(CarSearchContext)

  return (
    <Box className={classes.root}>
      <Typography className={classes.cityText} align="center">
        {searchCars?.from?.description || t('cars')}
      </Typography>
      {!!isMiddlestep && !!actionHandler && (
        <Button className={classes.editBtn} onClick={() => actionHandler('searchDrawer', true)}>{t('edit btn')}</Button>
      )}
    </Box>
  )
}

export default memo(CarsType)
