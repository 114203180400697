import React, {useCallback, useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import { Typography, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: theme.spacing(1.75),
    marginBottom: '12px',
    fontWeight: 'bold'
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.indicatorGrey
    },

    '& .Mui-checked .MuiSvgIcon-root': {
      color: theme.palette.primary.main
    },

    '& .MuiCheckbox-root': {
      padding: '7px',
      color: theme.palette.common.black
    }
  }
}))

const CheckList = ({title = '', options, callBack}) => {
  const classes = useStyles()
  const onChange = (e, v) => {
    const { name } = e.target
    if (!name) return
    callBack({key: name, checked: !!v})
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography className={classes.headerText}>
          {title}
        </Typography>
      </Box>
      <Box>
        <FormGroup>
          {options?.length &&
            options.map(o => (
              <FormControlLabel
                className={classes.checkbox}
                control={<Checkbox checked={o.checked} onChange={onChange} name={o.key}/>}
                label={o.label}
              />
            ))
          }
        </FormGroup>
      </Box>
    </>
  )
}

export default CheckList
