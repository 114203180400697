import React, { Fragment, memo, useCallback, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileSearchNav from '../../components/search/mobileSearchNav'
import UserSearch from '../../components/reusable/usersSearch'
import ReusableDatePicker from '../../components/reusable/datePicker/reusableDatePicker_new'
import AutoCompleteInput from '../../components/reusable/autoCompleteInput'
import { useTranslation } from 'react-i18next'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { ReactComponent as DoubleArrow } from '../../assets/images/Arrows-WHT.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useDirections from '../../hooks/useDirectionsHook'
import useTravellers from '../../hooks/useTravellersHook'
import useDestinations from '../../hooks/useSwitchDestinationsHook'
import usePopover from '../../hooks/usePopoverHook'
import useCarsHook from '../../hooks/useCarsHook'
import { fetchGooglePlacesAutocomplete } from '../../repositories/data'

const isApp = window.location.hostname === 'app.citycity.se'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: isApp ? theme.spacing(5) : theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  checkboxLabel: {
    '& > .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  checkboxLabelIframe: {
    '& > .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& > .MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.iframeSecondary.main,
    },
  },
  difPlacesContainer: {
    paddingTop: '10px',
  },
  searchBtn: {
    fontWeight: 600,
    letterSpacing: 1.1,
    fontSize: '16px',
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2),
  },
  box: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(0.5),
  },
  fromInputBox: {
    padding: theme.spacing(1),
    borderBottom: `1px dashed ${theme.palette.border}`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  },
  toInputBox: {
    padding: theme.spacing(1),
    width: '100%',
    borderTop: 0,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
  },
  swapContainer: {
    position: 'absolute',
    right: 24,
    top: 42,
    background: theme.palette.common.white,
  },
  swapButton: {
    padding: 0,
    transform: 'rotate(90deg)',
  },

  errorBox: {
    border: `1px solid #e02020`,
    borderRadius: theme.spacing(0.5),
    width: '100%',
  },

  destinationBox: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(0.5),
    width: '100%',
  },
  eventIcon: {
    color: theme.palette.iframeSecondary.main,
  },
}))

const MainSearchMobile = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t, i18n } = useTranslation()
  const [places, setPlaces] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const search = useSelector((state) => state.search)
  const { isIframe } = useSelector((state) => state.customization)
  const emptySearchState = useMemo(() => ({ travellers: [] }), [])

  const {
    difPlace: initialDifPlace,
    from: initialFrom,
    to: initialTo,
    departure: initialDeparture,
    arrival: initialArrival,
    travellers: initialTravellers,
  } = search?.cars || emptySearchState

  const { user } = useSelector((state) => state.auth)

  const {
    earliestDate,
    latestDate,
    suggestedEarliestDate,
    suggestedLatestDate,
  } = user?.bookingLink || {}

  const {
    departure,
    depChange: setDeparture,
    arrival,
    arrOnChange: setArrival,
  } = useDirections(
    suggestedEarliestDate || initialDeparture,
    suggestedLatestDate || initialArrival,
    true,
    earliestDate,
    latestDate
  )
  const { travellers, setTravellers } = useTravellers(initialTravellers)
  //const MobSearchBar = isAuthenticated ? MobileSearch : GuestMobileSearch

  const {
    switchDestinations,
    setFrom,
    setTo,
    fromDest: from,
    toDest: to,
  } = useDestinations(initialFrom, initialTo, i18n, 'car')
  const {
    isPopoverOpenFrom,
    setIsPopoverOpenFrom,
    isPopoverOpenTo,
    setIsPopoverOpenTo,
    isPopoverOpenTraveller,
    setIsPopoverOpenTraveller,
  } = usePopover()

  const { diffPlace, setDiffPlace, onSubmit } = useCarsHook({
    fromDest: from,
    toDest: to,
    initialDifPlace,
    history,
    i18n,
    endDate: arrival,
    startDate: departure,
    dispatch,
    travellers,
    setIsPopoverOpenFrom,
    setIsPopoverOpenTo,
    setIsPopoverOpenTraveller,
  })

  const optionLabel = (option) => option?.description || ''
  const renderOption = (option) => {
    return (
      <Fragment>
        <Box mr={1}>
          <LocationOnIcon color="disabled" fontSize="large" />
        </Box>
        <Typography>
          <span>{option?.description}</span>
        </Typography>
      </Fragment>
    )
  }

  const getPlaces = useCallback(
    async (value) => {
      const data = await fetchGooglePlacesAutocomplete(
        value || null,
        i18n.language
      )
      setPlaces(data)
    },
    [i18n.language]
  )

  const ACFilterFunc = useCallback((option) => option?.description || '', [])

  return (
    <Paper className={classes.paper} elevation={0} paddingBottom={4}>
      <Box mb={0.5}>
        <MobileSearchNav />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            paddingLeft={1}
            paddingTop={1}
            paddingBottom={1.75}
            className={!isPopoverOpenTraveller ? classes.box : classes.errorBox}
          >
            <UserSearch
              entityType={'car'}
              value={travellers}
              setValue={setTravellers}
              noLabel
              withUserModal={true}
              userShadow={false}
              setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
              isPopoverOpenTraveller={isPopoverOpenTraveller}
            />
          </Box>
        </Grid>
        <Grid item xs={12} container>
          <Box
            className={
              !isPopoverOpenFrom && !isPopoverOpenTo
                ? classes.destinationBox
                : classes.errorBox
            }
          >
            <Grid item xs={12}>
              <Box className={classes.fromInputBox} position="relative">
                <AutoCompleteInput
                  icon={
                    <RadioButtonUncheckedIcon
                      color="primary"
                      className={isIframe && classes.eventIcon}
                    />
                  }
                  renderOption={renderOption}
                  fetch={getPlaces}
                  value={from}
                  onChange={(_, value) => {
                    setFrom(value)
                    setIsPopoverOpenFrom(false)
                  }}
                  array={places}
                  customFilterFunc={ACFilterFunc}
                  optionLabel={optionLabel}
                  destinationModal={true}
                  label={t('pick-up place')}
                  placeholder={t('pick-up place')}
                  isPopoverOpenFrom={isPopoverOpenFrom}
                />
                {!!diffPlace && (
                  <Box className={classes.swapContainer}>
                    <IconButton
                      onClick={switchDestinations}
                      className={classes.swapButton}
                    >
                      <SwapHorizIcon
                        color="primary"
                        className={isIframe && classes.eventIcon}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>
            {!!diffPlace && (
              <Grid item xs={12}>
                <Box className={classes.toInputBox}>
                  <AutoCompleteInput
                    icon={
                      <LocationOnIcon
                        color="primary"
                        className={isIframe && classes.eventIcon}
                      />
                    }
                    renderOption={renderOption}
                    fetch={getPlaces}
                    value={to}
                    onChange={(_, value) => {
                      setTo(value)
                      setIsPopoverOpenTo(false)
                    }}
                    array={places}
                    optionLabel={optionLabel}
                    destinationModal={true}
                    customFilterFunc={ACFilterFunc}
                    label={t('drop-off place')}
                    placeholder={t('drop-off place')}
                    isPopoverOpenTo={isPopoverOpenTo}
                  />
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <Box paddingX={1} className={classes.box}>
              <ReusableDatePicker
                value={departure}
                onChange={setDeparture}
                from={departure}
                to={arrival}
                placeHolder={t('pick-up search')}
                title={t('pick-up search')}
                isOutgoing
                back
                HOTEL={false}
                minDate={earliestDate}
                maxDate={latestDate}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box paddingX={1} className={classes.box}>
              <ReusableDatePicker
                value={departure}
                onChange={setDeparture}
                placeHolder={t('search time')}
                title={t('search time')}
                from={departure}
                to={arrival}
                isOutgoing
                back
                HOTEL={false}
                timeOnly
                minDate={earliestDate}
                maxDate={latestDate}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <Box paddingX={1} className={classes.box}>
              <ReusableDatePicker
                value={arrival}
                hideCancel
                onChange={setArrival}
                placeHolder={t('drop-off search')}
                title={t('drop-off search')}
                isOneWay={false}
                HOTEL={false}
                from={departure}
                to={arrival}
                minDate={earliestDate}
                maxDate={latestDate}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box paddingX={1} className={classes.box}>
              <ReusableDatePicker
                value={arrival}
                hideCancel
                onChange={setArrival}
                placeHolder={t('search time')}
                title={t('search time')}
                isOneWay={false}
                HOTEL={false}
                from={departure}
                to={arrival}
                timeOnly
                minDate={earliestDate}
                maxDate={latestDate}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.difPlacesContainer}>
            <FormControlLabel
              className={
                isIframe ? classes.checkboxLabelIframe : classes.checkboxLabel
              }
              key={'car-dif-places-block'}
              control={
                <Checkbox
                  checked={!!diffPlace}
                  color="primary"
                  name="car-dif-places-cb"
                  onChange={(e) => setDiffPlace(e.target.checked)}
                />
              }
              label={t('drop car off at different location')}
              labelPlacement="end"
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt="11px" mb={isMobile ? 0 : 7}>
            <Button
              disableElevation
              onClick={onSubmit}
              variant="contained"
              color="primary"
              fullWidth
              endIcon={
                <SvgIcon style={{ fontSize: '18px' }}>
                  <DoubleArrow />
                </SvgIcon>
              }
              className={classes.searchBtn}
            >
              {t('search go')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default memo(MainSearchMobile)
