import React, { memo, useEffect, useState, useMemo, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeckSeatsMap from './DeckSeatsMap.js'
import { Scrollbar } from "react-scrollbars-custom"
import { CircularProgress, Box, useTheme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {ArrowRightAlt, ArrowForwardIos, ArrowBackIos} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  seatsMapMain : {

  },
  carSingleHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'black',
    cursor: 'pointer',

    '&.active': {
      color: theme.palette.primary.main
    },
    '& .select-counter': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '6px',
      fontSize: '10px',
      lineHeight: '16px',
      height: '16px',
      minWidth: '16px',
      borderRadius: '16px',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    }
  },
  plannedDir: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '26px',

    '& .arrow-icon': {
      width: '31px',
      height: 'auto',
    }
  },
  rotateLeft: {
    '-webkit-transform': 'rotateY(180deg)',
    'transform': 'rotateY(180deg)'
  },
  seatMapOuter: {
    width: '100%',
    overflowY: 'visible !important',
    overflowX: 'auto !important',
    padding: '15px 0',
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    borderRight: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
      borderRight: 'none',
    },

    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbar-width': 'none',  /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .ScrollbarsCustom-Content': {
      display: 'flex',
      alignItems: 'stretch',
    }
  },
  scrollBlockOuter: {
    width: '100%',
    paddingTop: '38px',
    overflowY: 'visible !important',
    '& .ScrollbarsCustom': {
      overflowY: 'visible !important',
    } ,
    '& .ScrollbarsCustom-Wrapper': {
      overflowY: 'visible !important',
      position: 'relative !important'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '28px',
    }
  },
  outerSeatsSelector: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'stretch',
    margin: theme.spacing(0, -5),
    [theme.breakpoints.down('sm')]: {
      margin: '0'
    }
  },
  arrowBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '76px',
    minWidth: '76px',
    paddingTop: '75px',
    '&:first-child': {
      paddingRight: '3px'
    },
    '&:last-child': {
      paddingLeft: '3px',
    }
  },
  scrollArrow: {
    height: '30px',
    width: '30px',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: `solid 2px ${theme.palette.primary.main}`
  },
  scrollArrowIcon: {
    height: '12px',
    width: 'auto',
    color: `${theme.palette.primary.main}`
  },
  scrollThumb: {
    borderRadius: '0 !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  preloaderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    width: '100%',
  },
  seatMapDivider: {
    borderBottom: '4px solid #d8d8d8',
    borderTop: '4px solid #d8d8d8',
    backgroundColor: '#f4f4ee',
    minWidth: '80px'
  },
  mobileTrack: {
    height: '10px',
    backgroundColor: '#d8d8d8',
    display: 'flex',
  },
  mobileTrackInner: {
    height: '100%',
    backgroundColor: 'transparent',
    '&.active': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  scrollTrack:{
    height: '10px !important',
    backgroundColor: '#d8d8d8 !important',
    top: '-38px',
    left: '0px !important',
    right: '0px !important',
    borderRadius: '0 !important',
    width: 'auto !important',
  },
  carHeaders: {
    display: 'flex',
    marginBottom: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    scrollTrack:{
      display: 'none',
    },
    carHeaders: {
      padding: theme.spacing(0, 1)
    },
  },
}))

const TrainSeatsMap = ({ seatsMap, railidentifier, index }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({isMobile})
  const maps = []
  const { seats:selectedSeats } = useSelector((state) => state.train)
  const [loading, setLoading] = useState(true)
  const [activeCoach, setActiveCoach] = useState(0)
  const [clientWidth, setClientWidth] = useState(0)
  const [componentLoaded, setComponentLoaded] = useState(false)
  const { t } = useTranslation()

  const {notEmptyMaps, carWidths} = useMemo(() => {
    const notEmptyMaps = []
    let totalCols = 0
    const carsCol = []
    if (seatsMap?.coachList?.length) {
      seatsMap.coachList.forEach((sm, ind) => {
        if (!sm.decks?.['001']) return
        const tmpCols = sm.decks['001'].columns + (ind > 0 ? 1 : 0)
        totalCols += tmpCols
        carsCol.push(tmpCols)
        notEmptyMaps.push(sm)
      })
    }

    const carWidths = carsCol.map(c => (c/totalCols)*100)

    return {notEmptyMaps, carWidths}
  }, [seatsMap])
  const refs = useMemo( () => notEmptyMaps.map(() => React.createRef()), [notEmptyMaps])
  const scrollRef = useRef(null)
  useEffect(() => {
    setLoading(true);
    const timerId = setTimeout(() => setLoading(false), 1000)

    return () => {
      clearTimeout(timerId);
    };
  }, [])

  const railSelectedSeats = useMemo(() => {
    return selectedSeats?.[railidentifier]
  }, [railidentifier, selectedSeats])

  const {coachSeats, selectedCoaches} = useMemo(() => {
    const coachSeats = {}
    let selectedCoaches = []
    if (railSelectedSeats) {
      for (let uId in railSelectedSeats) {
        const tmp = railSelectedSeats[uId]
        const tmpSeatNum = tmp?.exactSeat?.seatNum
        const tmpCoach = tmp?.exactSeat?.coach

        if (tmpSeatNum) {
          if (!coachSeats[tmpCoach]) {
            coachSeats[tmpCoach] = []
            selectedCoaches.push(tmpCoach)
          }
          coachSeats[tmpCoach].push({...tmp.exactSeat, uId })
        }
      }
      selectedCoaches = selectedCoaches.sort((a, b) => {
        const intA = parseInt(a)
        const intB = parseInt(b)

        if (intA < intB) return -1
        if (intA > intB) return 1
        return 0
      })
    }

    return {coachSeats, selectedCoaches}
  },
    // eslint-disable-next-line
  [railSelectedSeats, selectedSeats])

  const onScrolled = (param, coachCount) => {

    const {clientWidth:newCW, contentScrollWidth, scrollLeft} = param
    const maxLeft = (contentScrollWidth > newCW) ? contentScrollWidth - newCW : 0;
    const left = (maxLeft <= 0 || scrollLeft > maxLeft) ? 1 : scrollLeft/maxLeft;

    const coachInd = 1/notEmptyMaps.length
    let tmpActiveCoach = Math.ceil(left/coachInd)
    if (tmpActiveCoach <= 0) tmpActiveCoach = 1

    if (tmpActiveCoach !== activeCoach) {
      setActiveCoach(tmpActiveCoach)
    }

    if (clientWidth !== newCW) {
      setClientWidth(newCW)
    }
  }

  const scrollToCoach = ind => {
    const scrollElement = scrollRef?.current?.contentElement?.parentElement
    if (refs?.[ind]?.current && scrollElement?.scrollTo) {
      let scrollLeft = refs[ind].current.offsetLeft || 0
      if (clientWidth > 0 && refs[ind].current.clientWidth > 0) {
        scrollLeft += Math.ceil((refs[ind].current.clientWidth - clientWidth) / 2)
      }
      scrollElement.scrollTo({
        behavior: 'smooth',
        left: scrollLeft,
        top: 0
      })
    }
  }

  useEffect(() => {
    if (componentLoaded) return
    if (activeCoach) {
      setComponentLoaded(true)
      if (!selectedCoaches?.[0]) return
      const coach = selectedCoaches[0]
      const ind = notEmptyMaps.findIndex(m => m.displayName === coach)
      if (ind < 0 || ind === false) return
      scrollToCoach(ind)
    }

  },
  // eslint-disable-next-line
  [activeCoach, selectedCoaches, componentLoaded, notEmptyMaps])

  const activeCoachData = notEmptyMaps?.[activeCoach - 1]
  const renderSeatMap = () => {
    const coachHeaders = [];
    const mobileInnerTrack = [];

    if (!notEmptyMaps.length) return null
    notEmptyMaps.forEach((sm, ind) => {
      const headerClasses = [classes.carSingleHeader]
      const trackClasses = [classes.mobileTrackInner]
      if (activeCoach === ind+1) {
        headerClasses.push('active')
        trackClasses.push('active')
      }

      const seatsInCoach = coachSeats?.[sm.displayName]?.length
      coachHeaders.push(
        <Box
          key={`head-${ind}`}
          onClick={() => scrollToCoach(ind)}
          style={{width:`${carWidths[ind]}%`}}
          className={headerClasses.join(' ')}
        >
          {seatsInCoach &&
            <Box className={'select-counter'}>{seatsInCoach}</Box>
          }
          <span>{!isMobile && t('vagn')} {sm.displayName}</span>
        </Box>
      )

      mobileInnerTrack.push(
        <Box
          key={`scroll-track-${ind}`}
          style={{width:`${carWidths[ind]}%`}}
          className={trackClasses.join(' ')}
        />
      )
      maps.push(
        <DeckSeatsMap
          ref={refs[ind]}
          key={`deck-seats-${ind}`}
          isFirst={ind === 0}
          isLast={ind === notEmptyMaps.length - 1}
          railidentifier={railidentifier}
          map={sm}
          index={index}
        />
      )
    })
    const availableDirs = ['002', '003'];
    return (
      <Box className={classes.seatsMapMain}>

        {availableDirs.includes(activeCoachData?.direction || '') &&
          <Box className={classes.plannedDir}>
            {activeCoachData.direction === '002' &&
              <ArrowRightAlt className={`${classes.rotateLeft} arrow-icon`}/>
            }
            <span>{t('planned direction')}</span>
            {activeCoachData.direction === '003' &&
              <ArrowRightAlt className={'arrow-icon'}/>
            }
          </Box>
        }
        <Box className={classes.outerSeatsSelector}>
          {!isMobile &&
            <Box className={classes.arrowBlock}>
              <Box
                className={classes.scrollArrow}
                onClick={() => {
                  let tmpInd = activeCoach - 2
                  if (!notEmptyMaps?.[tmpInd]) tmpInd = notEmptyMaps.length - 1
                  scrollToCoach(tmpInd)
                }}
              >
                <ArrowBackIos viewBox={'0 0 16 24'} className={classes.scrollArrowIcon}/>
              </Box>
            </Box>
          }
          <Box flex={1} overflow={'hidden visible'}>
            <Box className={classes.carHeaders}>
              {coachHeaders}
            </Box>
            {isMobile &&
              <Box mx={1} className={classes.mobileTrack}>
                {mobileInnerTrack}
              </Box>
            }
            <Box className={classes.scrollBlockOuter}>
              <Scrollbar
                ref={scrollRef}
                trackYProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return <div {...restProps} ref={elementRef} style={{display:'none'}} />
                  }
                }}
                thumbYProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return <div {...restProps} ref={elementRef} style={{display:'none'}} />
                  }
                }}
                trackXProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return <div {...restProps} ref={elementRef} className={classes.scrollTrack} />
                  }
                }}
                thumbXProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return <div {...restProps} ref={elementRef} className={classes.scrollThumb} />
                  }
                }}
                scrollerProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return <div {...restProps} ref={elementRef} style={{position: 'relative'}} className={classes.seatMapOuter} />
                  }
                }}
                onUpdate={(params) => onScrolled(params, coachHeaders.length)}
              >
                {maps}
              </Scrollbar>
            </Box>
          </Box>
          {!isMobile &&
            <Box className={classes.arrowBlock}>
              <Box
                className={classes.scrollArrow}
                onClick={() => {
                  let tmpInd = activeCoach
                  if (!notEmptyMaps?.[tmpInd]) tmpInd = 0
                  scrollToCoach(tmpInd)
                }}
              >
                <ArrowForwardIos className={classes.scrollArrowIcon}/>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    )
  }

  return (
    <Box width={'100%'}>
      {!!loading &&
        <Box className={classes.preloaderBox}>
          <CircularProgress />
        </Box>
      }
      {!loading && renderSeatMap()}
    </Box>
  )
}

export default memo(TrainSeatsMap)
