import React, { Fragment, memo } from 'react'
import Box from '@material-ui/core/Box'
import { Paper, Grid, useMediaQuery } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    marginTop: '0'
  },
  paper: {
    height: '100%',
    borderRadius: '12px',
  },

  topSkeletons: {
    marginBottom: theme.spacing(2),
    borderRadius: '4px',
  },

  bottomSkeletons: {
    borderRadius: '4px',
  },

  border: {
    borderRadius: '4px',
  },
}))

/**
 *
 * This componen takes outgoin and returning trains.
 * It returns null if either properties in object has no value,
 * aka, If one way ticket ==> only one Skeleton
 *
 */

const ChooseSeatsSkeleton = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const arrayLength = 1

  const renderRows = new Array(arrayLength).fill(null).map((x, i) => (
    <Fragment key={i}>
      <Grid container item xs={4}>
        <Grid item xs={2}>
          <Skeleton
            animation="wave"
            variant="rect"
            width="100px"
            height="16px"
            className={classes.bottomSkeletons}
          />
        </Grid>
      </Grid>
    </Fragment>
  ))
  return (
    <>
      {
        props.selectedOutGoingTrip || props.selectedReturnTrip ? (
          <Box className={classes.root} key={0} mt={2}>
            <Paper elevation={0} className={classes.paper}>
              {/* Do Styling */}

              <Grid container>
                {!isMobile ? (
                  <Grid item container className={classes.grid}>
                    {renderRows}
                  </Grid>
                ) : (
                  <Grid>
                    <Grid item xs={12} sm={3} className={classes.gridMobile}>
                      <Box display="flex" justifyContent="flex-start">
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="110px"
                          height="16px"
                          className={classes.border}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Box>
        ) : null
      }
    </>
  )
}

export default memo(ChooseSeatsSkeleton)
