import React, { memo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    objectFit: 'contain',
  },
}))

const GetCompanyIconElement = ({
  company,
  small = false,
  width = null,
  height = null,
}) => {
  const classes = useStyles()

  if (!company) return <div>no logo Props send down</div>

  const style = {
    height: height || small ? 20 : 30,
    width: width || small ? 50 : 75,
  }

  return (
    <img
      src={`/assets/companies/${company}.png`}
      alt={company}
      style={style}
      className={classes.root}
    />
  )
}

export default memo(GetCompanyIconElement)
