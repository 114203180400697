import { Button, Grid, Box } from '@material-ui/core'
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FlightIcon from '@material-ui/icons/Flight'
import HotelIcon from '@material-ui/icons/Hotel'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'
import useHandleClickOnDisabledLink from '../../hooks/useHandleClickOnDisabledLink'

const useStyles = makeStyles((theme) => ({
  btn: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    width: '100%',
    position: 'relative',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'initial',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      textTransform: 'capitalize',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& .MuiButton-startIcon': {
      marginRight: '5px',
    },
    '&:hover': {
      background: 'none',
    },
  },
  btnContainer: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: theme.spacing(0.5),
    borderBottom: 'none',
    marginLeft: '8px',
    width: '110px',
  },
  btnContainerIframe: {
    backgroundColor: 'rgba(255,255,255)',
    width: '110px',
    height: '100%',
  },
  btnDisabled: {
    color: 'rgb(0,0,0,0.3)',
  },
  active: {
    backgroundColor: 'rgba(255,255,255,1.0)',
    color: theme.palette.primary.main,
  },
  container: {
    //    width: '300px',
    justifyContent: 'flex-start',
    position: 'relative',
    top: '2px',
  },
  eventIcon: {
    color: theme.palette.iframeSecondary.main,
  },
  iframeLabel: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  navBar: {
    height: '100%',
    display: 'flex',
  },
  containerIframe: {
    justifyContent: 'flex-start',
    position: 'relative',
    top: '6px',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  },
  btnIframe: {
    padding: '15px 8px',
    '& .MuiButton-label': {
      padding: '8px 24px !important',
    },
  },
  activeIframe: {
    '& .MuiButton-label': {
      transition: '200ms ease',
      border: '3px solid #08c5b9',
      borderRadius: '9px',
      padding: '8px 24px',
      lineHeight: 'normal !important',
    },
  },
  iframeAlignLeft: {
    paddingLeft: '30px',
    background: 'rgba(255,255,255)',
    borderTopLeftRadius: '8px',
  },
  iframeAlignRight: {
    paddingRight: '30px',
    background: 'rgba(255,255,255)',
    borderTopRightRadius: '8px',
  },
}))

const DesktopSearchNav = ({ currentSearchMode, setSearchMode }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { isIframe } = useSelector((state) => state.customization)
  const { showDisabledLinks } = useSelector((state) => state.auth)
  const { isFlightsEnabled, isCarsEnabled, isHotelsEnabled, isTrainsEnabled } =
    useEnabledBookingTypesHook()
  const handleClickOnDisabledLink = useHandleClickOnDisabledLink()

  const links = []

  if (isFlightsEnabled) {
    links.push({
      searchMode: 'flights',
      label: `${t('nav flight')}`,
      icon: FlightIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      searchMode: 'flights',
      disabled: true,
      label: `${t('nav flight')}`,
      icon: FlightIcon,
    })
  }

  if (isTrainsEnabled) {
    links.push({
      searchMode: 'trains',
      label: `${t('nav train')}`,
      icon: DirectionsRailwayIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      searchMode: 'trains',
      disabled: true,
      label: `${t('nav train')}`,
      icon: DirectionsRailwayIcon,
    })
  }

  if (isCarsEnabled) {
    links.push({
      searchMode: 'cars',
      label: `${t('cars')}`,
      icon: DirectionsCarIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      disabled: true,
      label: `${t('cars')}`,
      searchMode: 'cars',
      icon: DirectionsCarIcon,
    })
  }

  if (isHotelsEnabled) {
    links.push({
      searchMode: 'hotels',
      label: `${t('nav hotel')}`,
      icon: HotelIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      searchMode: 'hotels',
      disabled: true,
      label: `${t('nav hotel')}`,
      icon: HotelIcon,
    })
  }

  const switchSearchMode = (searchMode) => {
    //console.log('new mode = ' + searchMode)
    setSearchMode(searchMode)
  }

  const renderNavs = links.map((nav, i) => (
    <Grid
      key={i}
      item
      className={
        isIframe && i === 0
          ? classes.iframeAlignLeft
          : isIframe && i === links.length - 1
          ? classes.iframeAlignRight
          : ''
      }
    >
      <Box
        className={
          isIframe
            ? classes.btnContainerIframe
            : classNames(
                classes.btnContainer,
                currentSearchMode === nav.searchMode ? classes.active : null
              )
        }
      >
        <Button
          className={classNames(
            classes.btnIframe,
            classes.btn,
            isIframe && currentSearchMode === nav.searchMode
              ? classes.activeIframe
              : null,
            nav.disabled ? classes.btnDisabled : null
          )}
          startIcon={!isIframe && <nav.icon color="primary" fontSize="large" />}
          onClick={() =>
            nav.disabled
              ? handleClickOnDisabledLink(nav.searchMode)
              : switchSearchMode(nav.searchMode)
          }
          disableRipple
        >
          <span className={isIframe && classes.iframeLabel}>{nav.label}</span>
        </Button>
      </Box>
    </Grid>
  ))

  return (
    <Grid
      container
      className={isIframe ? classes.containerIframe : classes.container}
    >
      {renderNavs}
    </Grid>
  )
}

export default memo(DesktopSearchNav)
