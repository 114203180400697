import _ from 'lodash'

export default function sortFlights(data, { sortBy, sortDirection }) {
  let sortedData = [...data]

  sortedData = _.sortBy(sortedData, (d) => {


    let priceStandard = d.price

    if (sortBy === 'standardPrice') {
      if (priceStandard) {
        if (sortDirection === 'desc') {
          return Math.min(priceStandard) * -1
        }
        return Math.min(priceStandard)
      }
      return 9999999
    } else if (sortBy === 'firstClassPrice') {
      // if (priceFirst) {
      //   if (sortDirection === 'desc') {
      //     return priceFirst * -1
      //   }
      //   return priceFirst
      // }
      return 9999999
    } else if (sortBy === 'arriveTime') {
      if (d.arriveTime < d.departTime) {
        return `24:00${d[sortBy]}`
      }

      if (sortDirection === 'desc') {
        return new Date(`1970/01/01 ${d[sortBy]}`).getTime() * -1
      }
      return new Date(`1970/01/01 ${d[sortBy]}`).getTime()
    } else if (sortBy === 'departTime') {
      if (sortDirection === 'desc') {
        return new Date(`1970/01/01 ${d[sortBy]}`).getTime() * -1
      }
      return new Date(`1970/01/01 ${d[sortBy]}`).getTime()
    }
    // console.log(d[sortBy]);
    if (sortDirection === 'desc') {
      return d[sortBy] * -1
    }
    return d[sortBy]
  })

  return sortedData
}
