const SET_PAGES = 'SET_PAGES'
const SET_MENU = 'SET_MENU'
const SET_LANDINGPAGE = 'SET_LANDINGPAGE'
const SET_LOADING = 'SET_LOADING'
const SET_SETTINGS = 'SET_SETTINGS'
const SET_ACTIVE_SEARCH_TYPE = 'SET_ACTIVE_SEARCH_TYPE'
const SET_IS_IFRAME = 'SET_IS_IFRAME'
const SET_SEARCH_BY_EVENTS = 'SET_SEARCH_BY_EVENTS'

export default {
  SET_PAGES,
  SET_MENU,
  SET_LOADING,
  SET_SETTINGS,
  SET_LANDINGPAGE,
  SET_ACTIVE_SEARCH_TYPE,
  SET_IS_IFRAME,
  SET_SEARCH_BY_EVENTS,
}
