import React, { createContext, useState } from "react";
import ModalHook from './reusable/modalHook'
import Box from "@material-ui/core/Box";
import RowWithDivider from "../../components/reusable/rowWithDivider";
import { Button, Typography, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { closeAllModals, openDeactivateUserModal } from "../../store/modals/modalAction";
import { useDispatch } from "react-redux";
import { allUsersAction, switchAdminAction } from "../../store/users/userAction";
import { showSnackbarAction } from "../../store/snackbars/snackbarAction";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.spacing(3),
    fontWeight: theme.typography.fontWeightSemiBold,
    marginTop: theme.spacing(5.5)
  },
  mobileHeading: {
    fontSize: theme.spacing(1.875),
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: 'solid 1px #e6e6e6',
    backgroundColor: 'white',
    zIndex: '1000',
  },
  deactivate: {
    marginRight: 'auto',
    padding: theme.spacing(1, 5),
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.spacing(3),
    letterSpacing: '1.1px',
  },
  cancelBtn: {
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: theme.spacing(3),
    letterSpacing: '1.1px',
  },
  saveBtn: {
    marginLeft: theme.spacing(6),
    padding: theme.spacing(1, 5),
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: theme.spacing(3),
    letterSpacing: '1.1px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  cancelBtnMobile: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    textTransform: 'capitalize',
  },
  saveBtnMobile: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.2, 2.8),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'capitalize',
  },
}));

export const UserContext = createContext([{}, () => { }])

const EmployeesUserModal = ({ modalBoolean, user }) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const [switcher, setSwitcher] = useState()

  const closeModal = () => {
    dispatch(closeAllModals())
  }

  const handleDone = async () => {
    if (user.admin === switcher) {
      dispatch(closeAllModals())
      return
    }
    dispatch(closeAllModals())

    try {
      await dispatch(switchAdminAction(user.uniqueId, switcher))
      await dispatch(allUsersAction())
      dispatch(showSnackbarAction('success', switcher ? t('admin assigned') : t('admin removed')))
    } catch (e) {
      dispatch(showSnackbarAction('error', t('error has occurred')))
      console.log(e)
    }
  }

  const openDeactivateWindow = () => {
    dispatch(closeAllModals())
    dispatch(openDeactivateUserModal(user))
  }

  return (
    <UserContext.Provider value={[switcher, setSwitcher]}>
      <ModalHook modalBoolean={modalBoolean}>
        <Box p={isDesktop ? 2 : 6} pt={isDesktop ? 12 : 0}>
          {isDesktop && (
            <Box display="flex" justifyContent="space-between" className={classes.header}>
              <Button
                className={classes.cancelBtnMobile}
                color="default"
                onClick={closeModal}
              >
                {t('cancel btn')}
              </Button>
              <Typography className={classes.mobileHeading}>{user.firstName} {user.lastName}</Typography>
              <Button variant="contained" color="primary" className={classes.saveBtnMobile} onClick={handleDone}>{t('done')}</Button>
            </Box>)}
          {!isDesktop && (
            <Box display="flex" alignItems="center">
              <Typography
                className={classes.heading}
              >
                {user.firstName} {user.lastName}
              </Typography>
            </Box>
          )}
          <RowWithDivider name={t('active')} value={user.activated ? 'Yes' : 'No'} />
          <RowWithDivider name={t('profile name')} value={user.firstName} />
          <RowWithDivider name={t('profile surname')} value={user.lastName} />
          <RowWithDivider name={t('email form')} value={user.email} />
          <RowWithDivider name={t('employee admin')} value={user.admin} />
          {
            isDesktop && (
              <Box display="flex" justifyContent="center">
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.deactivate}
                  onClick={openDeactivateWindow}
                  type="submit"
                  style={{ marginTop: theme.spacing(5), marginRight: 0 }}
                >
                  {t('deactivate users')}
                </Button>
              </Box>
            )
          }
          {
            !isDesktop && (
              <Box mt={4} display="flex" flexDirection="row-reverse">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.saveBtn}
                  type="submit"
                  onClick={handleDone}
                >
                  {t('done')}
                </Button>
                <Button
                  className={classes.cancelBtn}
                  color="default"
                  onClick={closeModal}
                >
                  {t('cancel btn')}
                </Button>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.deactivate}
                  onClick={openDeactivateWindow}
                  type="submit"
                >
                  {t('deactivate users')}
                </Button>
              </Box>
            )
          }
        </Box>
      </ModalHook>
    </UserContext.Provider>
  )
};

export default EmployeesUserModal
