import React from 'react'
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import { useStyles } from './flightfilters'
import { Box, Checkbox } from '@material-ui/core'
import {useTranslation} from 'react-i18next'

const Company = ({ el, handleChange, handleOnlyClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const clickedOnly = () => {
    if (typeof(handleOnlyClick) === 'function') {
      handleOnlyClick(el.slug)
    }
  }
  return (
    <Box className={classes.checkboxOuter}>
      <FormControlLabel
        key={el.slug}
        control={
          <Checkbox
            color="primary"
            name={el.slug}
            checked={el.state}
            onChange={handleChange}
          />
        }
        label={el.company.label}
        className={classes.text}
      />
      {typeof(handleOnlyClick) === 'function' &&
        <Box onClick={clickedOnly} className={'hoverLink'}>{t('only')}</Box>
      }
    </Box>
  )
}

export default Company
