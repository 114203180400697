const FETCH_TRAIN_RESULTS = 'FETCH_TRAIN_RESULTS'
const FETCH_TRAIN_ERROR = 'FETCH_TRAINE_ERROR'

const REMOVE_TRAIN_SEARCH_RESULTS = 'REMOVE_TRAIN_SEARCH_RESULTS'

const FETCH_OUTGOING_CITIES = 'FETCH_OUTGOING_CITIES'
const FETCH_INCOMING_CITIES = 'FETCH_INCOMING_CITIES'

const SET_SELECTED_OUTGOING_TRAIN_TRIP = 'SET_SELECTED_OUTGOING_TRAIN_TRIP'
const SET_SELECTED_RETURN_TRIP = 'SET_SELECTED_RETURN_TRIP'

const IS_TRAIN_ACCOMMODATIONS_LOADING = 'IS_TRAIN_ACCOMMODATIONS_LOADING'
const SET_TRAIN_ACCOMMODATIONS_OUTGOING = 'SET_TRAIN_ACCOMMODATIONS_OUTGOING'
const SET_TRAIN_ACCOMMODATIONS_RETURNING = 'SET_TRAIN_ACCOMMODATIONS_RETURNING'
const SPECIFIC_ACCOMMODATION_SELECT = 'SPECIFIC_ACCOMMODATION_SELECT'
const SPECIFIC_ACCOMMODATION_UNSELECT = 'SPECIFIC_ACCOMMODATION_UNSELECT'
const SET_TRAIN_SEATS_MAP = 'SET_TRAIN_SEATS_MAP'
const TRAIN_SEATS_MAP_LOADING = 'TRAIN_SEATS_MAP_LOADING'

const IS_TRAIN_LOADING = 'IS_TRAIN_LOADING'

const REMOVE_TRAIN_STATE = 'REMOVE_TRAIN_STATE'

const SET_TRAIN_SEATS = 'SET_TRAIN_SEATS'
const EMPTY_TRAIN_SEATS = 'EMPTY_TRAIN_SEATS'

const REMOVE_TRAIN_ACCOMMODATIONS = 'REMOVE_TRAIN_ACCOMODATIONS'

const SET_TRAIN_MIDDLESET_SETTINGS = 'SET_TRAIN_MIDDLESET_SETTINGS'

const IS_EARLIER_TRIPS = 'IS_EARLIER_TRIPS'
const IS_LATER_TRIPS = 'IS_LATER_TRIPS'

export default {
  SET_TRAIN_SEATS_MAP,
  TRAIN_SEATS_MAP_LOADING,
  FETCH_TRAIN_RESULTS,
  FETCH_TRAIN_ERROR,
  SPECIFIC_ACCOMMODATION_SELECT,
  SPECIFIC_ACCOMMODATION_UNSELECT,
  REMOVE_TRAIN_SEARCH_RESULTS,
  IS_TRAIN_LOADING,
  FETCH_OUTGOING_CITIES,
  FETCH_INCOMING_CITIES,
  SET_TRAIN_ACCOMMODATIONS_OUTGOING,
  SET_TRAIN_ACCOMMODATIONS_RETURNING,
  IS_TRAIN_ACCOMMODATIONS_LOADING,
  SET_SELECTED_OUTGOING_TRAIN_TRIP,
  SET_SELECTED_RETURN_TRIP,
  REMOVE_TRAIN_STATE,
  SET_TRAIN_SEATS,
  EMPTY_TRAIN_SEATS,
  REMOVE_TRAIN_ACCOMMODATIONS,
  SET_TRAIN_MIDDLESET_SETTINGS,
  IS_EARLIER_TRIPS,
  IS_LATER_TRIPS
}
