import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {checkSuggestion, editAndApproveTrips} from '../../repositories/suggestions'
import {makeStyles} from '@material-ui/core/styles'
import { getBrandedUrl } from '../../utils/branding'
import {useTranslation} from 'react-i18next'
import Container from '@material-ui/core/Container'
import SelectedTripsData from '../../components/automateBooking/SelectedTripsData'
import LanguageChooser from "../../components/reusable/languageChooser";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9'
  },

  header: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    zIndex: '2',
    padding: '0 20px',
    backgroundColor: 'white',
    height: '65px',
    width: '100%',
    borderBottom: 'solid 1px #ededed',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
  },

  headLogoImage: {
    height: '52px',
    width: 'auto'
  },

  contentRoot: {
    display: 'flex',
    flex: 1,
    padding: '25px 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width:'100%',
    minHeight: 'calc(100vh - 65px)',
  },

  progress: {
    marginBottom: '10px'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '680px',

    [theme.breakpoints.down('sm')]: {
      padding: '69px 27px',
      justifyContent: 'flex-start',
    }
  },
  logoImage: {
    height: '85px',
    width: 'auto',
    marginBottom: '70px',

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  btn: {
    borderRadius: '10px',
    boxShadow: 'none'
  },
  checkmarkBlock: {
    width: '45px',
    height: '45px',
    borderRadius: '25px',
    border: '2px solid #34c300',
    marginBottom: '25px',
    position: 'relative'
  },
  checkMark: {
    display: 'inline-block',
    position: 'absolute',
    top: '4px',
    left: '13px',
    height: '27px',
    width: '15px',
    borderRight: '2px solid #34c300',
    borderBottom: '2px solid #34c300',
    transform: 'rotate(45deg)'
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '16px',
    color: theme.palette.primary.main,
    textAlign: 'center',

    '&.error': {
      color: theme.palette.secondary.main
    },

    '&.short': {
      maxWidth: '267px'
    }
  },
}))
const errorTexts = {
  1100: { header: 'suggestion approve failed' },
}

const EmailBookingApprove = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isSent: false,
    loading: true,
    approveData: null,
    error: false,
  })
  let { jwt } = useParams()

  const goToSelect = () => {
    history.push(`/automate-booking/choose-trip/${jwt}`);
  }

  const approve = useCallback(async () => {
    setState({...state, isSent: true, loading: true})
    const resultState = {...state, isSent: true, loading: false}
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const rsData = await editAndApproveTrips(jwt, {})
      resultState.error = null
      resultState.approveData = {status: 'booking'}
      setState(resultState)
      return true
    } catch (e) {
      const errCode = 1100
      resultState.error = !!errorTexts?.[errCode] ? {...errorTexts[errCode]} : {...errorTexts[1100]}
      setState(resultState)
      return false
    }
  }, [history, dispatch, t, state, jwt])

  const approveStatus = useMemo(() => {
    const statuses = ['booking', 'confirm']
    const resStatus = state.approveData?.status || 'unknown'
    if (!!state.loading) return 'loading'
    if (!!state.error || !statuses.includes(resStatus)) return 'error'
    return resStatus
  }, [state])

  const buildMainContent = useCallback(() => {

    if (approveStatus === 'loading') {
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          <CircularProgress className={classes.progress}/>
          <Box key="header-block" className={classes.text} mb={'20px'}>{t('checking data')}...</Box>
          <Box key="text-block" className={`${classes.text} short`}>{t('checking suggestion head text')}</Box>
        </Container>
      )
    }

    if (approveStatus === 'error') {
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          <div key="header-block" className={`${classes.text} error`}>{t(state.error.header)}</div>
        </Container>
      )
    }

    if (approveStatus === 'booking') {
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          <div key="checkmark-block" className={classes.checkmarkBlock}>
            <div className={classes.checkMark}/>
          </div>
          <div key="header-block" className={classes.text}>{t('booking completed')}</div>
          <div key="text-block" className={classes.text}>{t('suggestion approve text')}</div>
        </Container>
      )
    }

    if (approveStatus === 'confirm') {
      const trips = {
        outbound: state.approveData.outboundTrip,
        return: state.approveData.returnTrip,
        seats: state.approveData.seats,
      }
      return (
        <SelectedTripsData compareState={true} trips={trips} suggestion={state.approveData.suggestion} jwt={jwt} onCancel={goToSelect}/>
      )
    }
  }, [approveStatus, classes, jwt])

  useEffect(() => {
    if (!state.isSent) approve()
  }, [state, approve])

  return (
    <Box className={classes.root}>
      <Box key={'header'} className={classes.header}>
        <Box key={'header-left'} flex={1}/>
        <LanguageChooser/>
      </Box>
      <Box key={'contentRoot'} className={classes.contentRoot}>
        {buildMainContent()}
      </Box>
    </Box>
  )
}

export default EmailBookingApprove
