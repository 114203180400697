import React from 'react'
import { Container, Box, Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import RedirectifAuth from '../../hooks/redirectIfAuth'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import LandingHeader from '../../components/header/landingHeader'
import { ReactComponent as Logo } from '../../assets/images/Logo-BLK-Horizontal.svg'
import { ReactComponent as Arrows } from '../../assets/images/Arrows-WHT.svg'
import { useSelector } from 'react-redux'
import RedirectIfCustomLanding from '../../hooks/redirectIfCustomLanding'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  container: {
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
  },
  text: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: -1,
    fontSize: '42px',
  },
  registerBtn: {
    borderRadius: theme.spacing(3),
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1,
    padding: theme.spacing(1, 4),
  },
  loginBtn: {
    borderRadius: theme.spacing(3),
    fontSize: theme.spacing(2),
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1,
    padding: theme.spacing(1, 4),
  },
  or: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: -1,
  },
  citycitytext: {
    color: theme.palette.common.white,
  },

  invertedLogo: {
    height: '20px',
    fill: 'white',
  },
  invertedLogoWrapper: {
    position: 'relative',
    top: '5px',
    left: '5px',
  },
  arrows: {
    height: '18px',
    width: '18px',
  },
}))

const Landing = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { publicity } = useSelector((state) => state.auth)
  const classes = useStyles()
  const { t } = useTranslation()

  const desktopString = (
    <Typography variant="h4" align="center" className={classes.text}>
      {t('hi')} <br />
      {t('self booking easy')}
      <br />
      {t('dont take our word for it')}
    </Typography>
  )

  const mobileString = (
    <Typography variant="h4" align="center" className={classes.text}>
      {t('hi')}
      <br />
      {t('self booking easy')}
    </Typography>
  )

  return (
    <RedirectIfCustomLanding>
      <RedirectifAuth>
        <LandingHeader />

        <Container classes={{ root: classes.root }}>
          <Box pt={!isDesktop ? 0 : 20} className={classes.container}>
            {/*
            {isDesktop ? desktopString : mobileString}
*/}
            <Box mt={6}>
              {/*

              {publicity !== 'public' && (
                <Button
                  disableElevation
                  fullWidth
                  className={classes.registerBtn}
                  color="primary"
                  variant="contained"
                  component={Link}
                  to="/log-in"
                  endIcon={<Arrows className={classes.arrows} />}
                >
                  {isDesktop ? t('Log in to your account') : t('login')}
                </Button>
              )}
              {isDesktop ? (
                <Box mt={2} mb={2}>
                  <Typography align="center" className={classes.or}>
                  </Typography>
                </Box>
              ) : (
                ''
              )}

*/}

              {/* <Box mt={isDesktop ? 0 : 4}>
                <Button
                  disableElevation
                  fullWidth
                  component={Link}
                  to="/register"
                  variant="outlined"
                  className={classes.loginBtn}
                  endIcon={<DoubleArrowIcon fontSize="large" />}
                >
                  {isDesktop ? 'Log in to your account' : 'Log in'}
                </Button>
              </Box> */}

              {/*
              <Box
                mt={10}
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography align="center" className={classes.citycitytext}>
                  Powered by
                  <Box display="inline" className={classes.invertedLogoWrapper}>
                    <Logo className={classes.invertedLogo} />
                  </Box>
                </Typography>
              </Box>
              */}
            </Box>
          </Box>
        </Container>
      </RedirectifAuth>
    </RedirectIfCustomLanding>
  )
}

export default Landing
