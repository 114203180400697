import constants from './constants'

const initialState = {
  bookings: [],
  external: [],
  isLoading: false,
  error: null,
  /* */
  bookingDetails: {
    pnr: 'JFBECT',
    type: 'flight',
  },
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.SET_MY_BOOKINGS:
      return {
        ...state,
        bookings: payload,
      }
    case constants.IS_MY_TRIP_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case constants.ERROR_MY_TRIP:
      return {
        ...state,
        error: payload,
      }

    case constants.SET_BOOKING_NUMBER:
      return {
        ...state,
        bookingDetails: payload,
      }

    default:
      return state
  }
}
