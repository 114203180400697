import React, { useCallback, useEffect, useState } from 'react'
import AutoCompleteInput from '../../../../components/reusable/autoCompleteInputHotel'
import { fetchHotelCitiesAction } from '../../../../store/hotels/hotelsAction'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCityCoordinates } from '../../../../repositories/hotel'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    padding: '10px',
    paddingTop: '9px',
    paddingLeft: '12px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
    marginBottom: '8px'
  }
}))

const SelectCity = ({ handleValueChange, changeCity, goBack }) => {
  const dispatch = useDispatch()
  const [city, setCity] = useState(null)
  const cities = useSelector((state) => state.hotels.cities)
  const classes = useStyles()

  const getCities = (value) => {
    dispatch(fetchHotelCitiesAction(value))
  }
  const optionLabel = (options) => {
    return options.Name ? options.Name : ''
  }

  const handleSubmit = useCallback(async () => {
    const data = await fetchCityCoordinates(city['Id'])
    handleValueChange(data['Coordinates'].lat, data['Coordinates'].lng, data['Country'])
    changeCity(city['Name'])
    goBack()
  },[changeCity, city, goBack, handleValueChange])

  useEffect(() => {
    if (city) {
      handleSubmit()
    }
  }, [city, handleSubmit])

  return (
    <Box className={classes.container}>
      <Box className={classes.input}>
        <AutoCompleteInput
          value={city}
          array={cities}
          fetch={getCities}
          optionLabel={optionLabel}
          onChange={(first,city) => setCity(city)}
          fontWeight
          icon
          focus
        />
      </Box>
    </Box>
  )
}

export default SelectCity
