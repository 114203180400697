import React, { useCallback } from 'react'
import _ from 'lodash'
import ModalHook from './reusable/modalHook'
import { useFormik } from 'formik'
import {
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  useTheme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { getPhoneNumberFormat, validatePhoneNumber } from '../../utils/general'
import { useSelector } from 'react-redux'

export const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ({ isMobile }) => (isMobile ? 'space-between' : 'center'),
    borderBottom: ({ isMobile }) =>
      isMobile ? `1px solid ${theme.palette.border}` : 'none',
    paddingBottom: ({ isMobile }) => (isMobile ? '12px' : '27px'),
  },
  fieldsWrapper: {
    padding: ({ isMobile }) => theme.spacing(0, isMobile ? 2 : 0),
  },
  boldTitle: {
    fontSize: ({ isMobile }) => (isMobile ? '16px' : '32px'),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 'normal',
  },
  headerBtn: {
    fontWeight: '500',
    fontSize: '16px',
    boxShadow: 'none',
    height: '40px',
    borderRadius: '20px',
    textTransform: 'capitalize',
    padding: `6px 20px`,
    letterSpacing: '1.1px',
  },
  input: {
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: theme.spacing(0.75),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(0.75),
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
  inputHeading: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 'normal',
    paddingBottom: '11px',
    color: theme.palette.common.black,
  },
  wrapper: {
    marginBottom: '24px',
  },
  modalWrapper: {
    position: 'relative',
  },
  divider: {
    marginTop: '27px',
    marginBottom: theme.spacing(4.5),
  },
  cancelBtn: {
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: theme.spacing(3),
    letterSpacing: '1.1px',
  },
  saveBtn: {
    marginLeft: theme.spacing(5),
    padding: theme.spacing(1, 10),
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: theme.spacing(3),
    letterSpacing: '1.1px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  cancelBtnMobile: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    textTransform: 'capitalize',
  },
  saveBtnMobile: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.2, 2.8),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'capitalize',
  },

  noticeText: {
    padding: '12px',
    borderRadius: '6px',
    color: '#333',
    backgroundColor: theme.palette.primary.light,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    width: '100%',
    marginBottom: '28px',
    textAlign: 'center',
  },
}))

const AddGuestUserModal = ({ isOpen, onAdd, onCancel }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const { t } = useTranslation()
  const autoCapitalFields = ['firstName', 'lastName']
  const { countryPhone } = useSelector((state) => state.auth)
  const phoneNumberFormat = getPhoneNumberFormat(countryPhone?.code)

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().nullable().required(t('first name required')),
    lastName: Yup.string().required(t('last name required')),
    email: Yup.string().email(t('invalid email')).required(t('email required')),
    mobile: Yup.string()
      .required(t('phone required'))
      .test(
        'valid-number',
        t('invalid phone number', { phoneNumberFormat }),
        (value) => validatePhoneNumber(value, countryPhone?.code)
      ),
  })

  const addGuest = (values) => {
    Object.keys(values).forEach(
      (val) => (values[val] = values[val].replace("'", ' '))
    )
    const uniqueId =
      Date.now().toString(36) + Math.random().toString(36).substr(2)
    formik.resetForm()
    onAdd({
      ...values,
      isGuest: true,
      uniqueId,
    })
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
    },
    validationSchema,
    onSubmit: addGuest,
  })

  const onBlur = (e) => {
    if (!autoCapitalFields.includes(e.target.name)) return false
    const newVal = e.target.value
      .split(' ')
      .map((w) => _.capitalize(w.trim()))
      .join(' ')
    formik.setFieldValue(e.target.name, newVal)
    return false
  }

  const phoneInputHandler = useCallback((event) => {
    if (!/[\+0-9]/.test(event.key)) {
      event.stopPropagation()
      event.preventDefault()
      return false
    }
  }, [])

  const phonePasteHandler = (event) => {
    let text = event.clipboardData.getData('Text')

    text = text.replace(/[^\+0-9]/gm, '')
    formik.setFieldValue('mobile', text, false)
    event.preventDefault()
  }

  const buildTextField = (name, title) => {
    return (
      <Box>
        <Typography className={classes.inputHeading}>{title} *</Typography>
        <Box>
          <TextField
            name={name}
            autoComplete={'off'}
            inputProps={{ autoCorrect: 'off', spellCheck: 'false', onBlur }}
            autoFocus={name === 'firstName'}
            type={name === 'email' ? 'email' : 'text'}
            fullWidth
            error={formik.errors?.[name] && formik.touched?.[name]}
            helperText={formik.touched?.[name] && formik.errors?.[name]}
            InputLabelProps={{ shrink: false }}
            onKeyPress={name === 'mobile' ? phoneInputHandler : null}
            variant="outlined"
            className={classes.input}
            value={formik.values[name]}
            InputProps={{ style: { fontWeight: 500 } }}
            onChange={formik.handleChange}
            onPaste={name === 'mobile' ? phonePasteHandler : null}
            style={{ borderRadius: theme.spacing(0.75) }}
          />
        </Box>
      </Box>
    )
  }

  return (
    <ModalHook width={500} modalBoolean={isOpen}>
      <Box
        className={classes.modalWrapper}
        pt={isMobile ? '12px' : '55px'}
        pb={5}
        px={isMobile ? 0 : 6}
      >
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container>
            <Box component="header" className={classes.header}>
              {isMobile && (
                <Button
                  onClick={onCancel}
                  className={classes.headerBtn}
                  color="primary"
                >
                  {t('cancel btn')}
                </Button>
              )}
              <Typography className={classes.boldTitle}>
                {t('add guest traveler')}
              </Typography>
              {isMobile && (
                <Button
                  className={classes.headerBtn}
                  style={{ fontWeight: theme.typography.fontWeightBold }}
                  variant={'text'}
                  type="submit"
                  color="primary"
                >
                  {t('save btn')}
                </Button>
              )}
            </Box>
            <Box className={classes.noticeText}>
              {t('add guest traveler text')}
            </Box>
            <Grid
              container
              className={classes.fieldsWrapper}
              px={isMobile ? 2 : 0}
            >
              <Grid
                item
                xs={isMobile ? 12 : 6}
                pr={isMobile ? 0 : '3px'}
                className={classes.wrapper}
                key={'first-name-field'}
              >
                <Box pr={isMobile ? 0 : '4px'}>
                  {buildTextField('firstName', t('profile name'))}
                </Box>
              </Grid>

              <Grid
                item
                xs={isMobile ? 12 : 6}
                className={classes.wrapper}
                key={'last-name-field'}
              >
                <Box pl={isMobile ? 0 : '4px'}>
                  {buildTextField('lastName', t('last name form'))}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.wrapper}
                key={'email-field'}
              >
                {buildTextField('email', t('email form'))}
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.wrapper}
                key={'phone-field'}
              >
                {buildTextField('mobile', t('phone mobile'))}
              </Grid>
            </Grid>
          </Grid>
          {!isMobile && <Divider className={classes.divider} />}
          {!isMobile && (
            <Box mt={4} display="flex" flexDirection="row-reverse">
              <Button
                disableElevation
                variant="contained"
                color="primary"
                className={classes.saveBtn}
                type="submit"
              >
                {t('save btn')}
              </Button>
              <Button
                className={classes.cancelBtn}
                color="default"
                onClick={onCancel}
                disableElevation
              >
                {t('cancel btn')}
              </Button>
            </Box>
          )}
        </form>
      </Box>
    </ModalHook>
  )
}

export default AddGuestUserModal
