const OPEN_SEAT_RESERVATION_MODAL = 'OPEN_SEAT_RESERVATION_MODAL'
const OPEN_TRANSFER_MODAL = 'OPEN_TRANSFER_MODAL'
const OPEN_CANCEL_BOOKING_MODAL = 'OPEN_CANCEL_BOOKING_MODAL'
const OPEN_SINGLE_HOTEL_IMAGE_MODAL = 'OPEN_SINGLE_HOTEL_IMAGE_MODAL'
const OPEN_LOGOUT_MODAL = 'OPEN_LOGOUT_MODAL'
const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL'
const OPEN_EDIT_INFO_MODAL = 'OPEN_EDIT_INFO_MODAL'
const OPEN_ADD_USERS_MODAL = 'OPEN_ADD_USERS_MODAL'
const OPEN_EMPLOYEES_USER_MODAL = 'OPEN_EMPLOYEES_USER_MODAL'
const OPEN_DEACTIVATE_USER_MODAL = 'OPEN_DEACTIVATE_USER_MODAL'
const OPEN_ACTIVATE_USER_MODAL = 'OPEN_ACTIVATE_USER_MODAL'
const OPEN_BOOKING_LINK_INFO_MODAL = 'OPEN_BOOKING_LINK_INFO_MODAL'
const TOGGLE_BOOKING_IN_PROCESS = 'TOGGLE_BOOKING_IN_PROCESS'
const OPEN_POLICY_EXCEEDED_MODAL = 'OPEN_POLICY_EXCEEDED_MODAL'
const OPEN_REGISTRATION_MODAL = 'OPEN_REGISTRATION_MODAL'
const OPEN_AGENT_AUTH_USER_MODAL = 'OPEN_AGENT_AUTH_USER_MODAL'
const SET_BOOKING_PART_CANCEL_MODAL_DATA = 'SET_BOOKING_PART_CANCEL_MODAL_DATA'
const OPEN_SIMPLE_INFO_MODAL = 'OPEN_SIMPLE_INFO_MODAL'
const OPEN_LOGIN_WITH_COMPANY_MODAL = 'OPEN_LOGIN_WITH_COMPANY_MODAL'
const OPEN_ADD_MOBILE_MODAL = 'OPEN_ADD_MOBILE_MODAL'
const TOGGLE_CHECKOUT_SIDE_DRAWER = 'TOGGLE_CHECKOUT_SIDE_DRAWER'

//
const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS'

export default {
  TOGGLE_BOOKING_IN_PROCESS,
  OPEN_SEAT_RESERVATION_MODAL,
  OPEN_TRANSFER_MODAL,
  OPEN_CANCEL_BOOKING_MODAL,
  OPEN_SINGLE_HOTEL_IMAGE_MODAL,
  OPEN_LOGOUT_MODAL,
  CLOSE_ALL_MODALS,
  OPEN_EDIT_INFO_MODAL,
  OPEN_ADD_USERS_MODAL,
  OPEN_EMPLOYEES_USER_MODAL,
  OPEN_DEACTIVATE_USER_MODAL,
  OPEN_ACTIVATE_USER_MODAL,
  OPEN_POLICY_EXCEEDED_MODAL,
  OPEN_LOGIN_MODAL,
  OPEN_REGISTRATION_MODAL,
  SET_BOOKING_PART_CANCEL_MODAL_DATA,
  OPEN_BOOKING_LINK_INFO_MODAL,
  OPEN_AGENT_AUTH_USER_MODAL,
  OPEN_SIMPLE_INFO_MODAL,
  OPEN_LOGIN_WITH_COMPANY_MODAL,
  OPEN_ADD_MOBILE_MODAL,
  TOGGLE_CHECKOUT_SIDE_DRAWER
}
