import React, { memo, useState } from 'react'
import ModalHook from './reusable/modalHook'
import { closeAllModals } from '../../store/modals/modalAction'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AuthWrapper from '../authWrapper/authWrapper'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    borderRadius: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '18px',
    lineHeight: '29px',
    padding: theme.spacing(2, 3),
    letterSpacing: 1.1,
    maxWidth: '227px',
    width: '100%',
    height: '55px',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
  },
  alignBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnText: {
    marginLeft: '10px',
    textTransform: 'none',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  medium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  input: {
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: '6px',
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: '6px',
    },
    '& .MuiInputBase-formControl': {
      borderRadius: '6px',
    },
    '& .Mui-error': {
      fontSize: '14px',
    },
  },

  regular: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const RegistrationModal = ({ modalBoolean }) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [isLoading] = useState('')

  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const closeModal = () => dispatch(closeAllModals())

  const onBackClicked = () => {
    closeModal()
  }

  const onSubmit = () => {}

  return (
    <ModalHook
      minHeight={300}
      width={500}
      modalBoolean={modalBoolean}
      marginTop={250}
    >
      <AuthWrapper isModal={true} onBackClicked={onBackClicked}>
        <Box mb={10} mt={5}>
          <Typography align="center" variant="h3">
            Registrera dig
          </Typography>
          <Box>
            <TextField
              id="outlined-basic"
              InputLabelProps={{ shrink: false }}
              placeholder={t('placeholder firstname')}
              label={t('firstname')}
              variant="outlined"
              value={firstName}
              className={classes.input}
              onChange={({ target }) => setFirstName(target.value)}
            />

            <TextField
              id="outlined-basic"
              InputLabelProps={{ shrink: false }}
              placeholder={t('placeholder lastname')}
              label={t('lastname')}
              variant="outlined"
              value={lastName}
              className={classes.input}
              onChange={({ target }) => setLastName(target.value)}
            />

            <TextField
              fullWidth
              InputLabelProps={{ shrink: false, inputMode: 'email' }}
              id="outlined-basic"
              label={t('email')}
              variant="outlined"
              value={email}
              className={classes.input}
              onChange={({ target }) => setEmail(target.value)}
            />

            <TextField
              fullWidth
              id="outlined-basic"
              InputLabelProps={{ shrink: false }}
              placeholder={t('placeholder phone')}
              label={t('phone')}
              variant="outlined"
              value={phone}
              className={classes.input}
              onChange={({ target }) => setPhone(target.value)}
            />
          </Box>

          <Box
            mt={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              disableElevation
              onClick={onSubmit}
              color="primary"
              variant="contained"
              className={classes.btn}
              tabIndex="3"
            >
              {isLoading ? (
                <CircularProgress size={30} color={'light'} />
              ) : (
                t('register')
              )}
            </Button>
          </Box>
        </Box>
      </AuthWrapper>
    </ModalHook>
  )
}

export default memo(RegistrationModal)
