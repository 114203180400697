import React, {Fragment} from 'react'
import StepHeader from './stepHeader'
import SelectCity from './citySelectStep'
import SelectDate from './dateSelectStep'
import SelectTime from './timeSelectStep'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const StepHandler = ({ filter, filters, goBack, toggleDrawer, handleValueChange }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Fragment>
      <StepHeader
        filter={filter}
        goBack={goBack}
        toggleDrawer={toggleDrawer}
      />
      {
        (filter === 'from' || filter === 'to') && (
          <SelectCity
            value={filters[filter]}
            onChange={(val) => handleValueChange(filter, val)}
            goBack={goBack}
          />
        )
      }

      {
        (filter === 'take_date' || filter === 'drop_date') && (
          <SelectDate
            from={filters.take_date}
            to={filters.drop_date}
            isOutgoing={filter === 'take_date'}
            onChange={(val) => handleValueChange(filter, val)}
            goBack={goBack}
          />
        )
      }

      {
        (filter === 'take_time' || filter === 'drop_time') && (
          <SelectTime
            from={filters.take_time}
            to={filters.drop_time}
            isOutgoing={filter === 'take_time'}
            onChange={(val) => handleValueChange(filter, val)}
            goBack={goBack}
          />
        )
      }
    </Fragment>
  )
}

export default StepHandler
