import React, { memo } from 'react'
import { useLocation } from 'react-router-dom'

import FlightType from './types/flightType'
import FlightDetailsType from './types/flightDetailsType'
import FlightAddTravellerType from './types/flightAddTravellerType'

import HotelType from './types/hotelType'
import SingleHotelType from './types/singleHotel'

import TrainType from './types/trainType'
import TrainChooseSeatsType from './types/trainChooseSeatsType'

import MiddleStepType from './types/middleStepType'

import CarsType from './types/carsType'

import CheckoutType from './types/checkoutType'

const Handler = ({ model }) => {
  const { pathname } = useLocation()

  const doesItContain = (string) => pathname.includes(string)

  if (doesItContain('middlestep')) {
    return <MiddleStepType pathname={pathname} />
  }
  if (doesItContain('flights/add-public-data')) {
    return <FlightAddTravellerType />
  }
  if (doesItContain('flights/details')) {
    return <FlightDetailsType />
  }
  if (doesItContain('flights')) {
    return <FlightType />
  }
  if (doesItContain('choose-seats')) {
    return <TrainChooseSeatsType />
  }
  if (doesItContain('trains/add-public-data')) {
    return <FlightAddTravellerType />
  }
  if (doesItContain('train')) {
    return <TrainType />
  }
  if (doesItContain('hotels/details')) {
    return <SingleHotelType />
  }
  if (doesItContain('/hotels/search')) {
    return <HotelType model={model} />
  }
  if (
    doesItContain('/cars/search') ||
    doesItContain('/cars/add-middlestep') ||
    doesItContain('/cars/details')
  ) {
    return <CarsType />
  }
  if (doesItContain('/checkout')) {
    return <CheckoutType />
  }
  return <p>no data</p>
}

export default memo(Handler)
