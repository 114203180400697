import React, {Fragment} from 'react'
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  useMediaQuery,
} from '@material-ui/core'
import HotelIcon from '@material-ui/icons/Hotel'
import CardHeaderComponent from '../../components/checkout/cardHeader'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import capFirstLetter from '../../utils/capitalizeFirstLetter'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'
import numberOfDaysBetween from '../../utils/daysBetween'
import Policy from '../../components/reusable/tags/policy'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '12px',
  },
  justify: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  align: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > *': {
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  hr: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.border
  },
  cover: {
    width: 80,
    height: '100%',
    objectFit: 'cover',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  weightSemiBold: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  cardContent: {
    padding: 0,
    margin: 0,
    paddingBottom: '0 !important',
  },

  coverMobile: {
    width: '100%',
    height: 80,
    objectFit: 'cover',
  },

  blob: {
    backgroundColor: '#dcf7d9',
    color: '#15c500',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
  },

  blobYellow: {
    backgroundColor: '#ffefd6',
    color: '#ff9900',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const HotelsCheckout = ({ model, index, disableEdit = false, contentOnly = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const hotelsToRender = []
  if (model.trips) {
    hotelsToRender.push(...model.trips)
  } else {
    hotelsToRender.push({...model})
  }
  const renderRoutes = []

  hotelsToRender.forEach((hotelModel, hotelInd) => {
    const { room, hotel, roomsCount } = hotelModel
    const { title: HotelName } = hotel?.parsedHotel
    const { timeSpan } = hotelModel.room

    const daysBetween = numberOfDaysBetween(timeSpan.End, timeSpan.Start)
    const { exceedsPolicy, isDealFare, isNegotiatedFare } = hotelModel.room

    const freeCancellationLabel =
      t('hotel popular free') +
      (hotelModel.room.freeCancellationDeadline
        ? ' ' +
        t('until').toLowerCase() +
        ' ' +
        moment(hotelModel.room.freeCancellationDeadline).format(
          `YYYY-MM-DD [${t('at')}] HH:mm`
        )
        : '')

    renderRoutes.push((
      <Box key={`hotel-${hotelInd}`} p={2}>

        {hotelInd === 0 ? (
          <CardHeaderComponent
            icon={<HotelIcon />}
            type={t('selected hotel').toUpperCase()}
            index={index}
            disableEdit={disableEdit}
          />
        ) : (
          <Box className={classes.hr}/>
        )}

        <Box pt={1} className={!isMobile && classes.align}>
          <Box pb={1} mt={isMobile ? 1 : 2}>
            <CardMedia
              className={isMobile ? classes.coverMobile : classes.cover}
              src={hotelModel?.hotel?.parsedHotel?.image}
              title="Live from space album cover"
              component="img"
            />
          </Box>
          <CardContent className={classes.cardContent}>
            <Box pl={isMobile ? 0 : 2} pb={1} pt={isMobile ? 2 : 1}>
              <Typography className={classes.title} gutterBottom>
                {capFirstLetter(HotelName)}
              </Typography>
              <Box className={classes.align}>
                <Typography variant="body2" gutterBottom>
                  {t('hotel check in placeholder')}{' '}
                  <Moment format="D MMM">{timeSpan.Start}</Moment>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {t('hotel check out placeholder')}{' '}
                  <Moment format="D MMM">{timeSpan.End}</Moment>
                </Typography>
              </Box>
              <Box
                display={!isMobile && 'flex'}
                alignItems={!isMobile && 'center'}
              >
                <Box pb={isMobile ? 1 : 0}>
                  {roomsCount} {roomsCount > 1 ? t('rooms') : t('room')},{' '}
                  {daysBetween} {daysBetween > 1 ? t('night') : t('one night')}
                </Box>

                <Box pl={isMobile ? 0 : 2}>
                  <Policy
                    policy={exceedsPolicy}
                    deal={isDealFare}
                    corporate={isNegotiatedFare}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Box>

        {room.cancellationType === 'free' ? (
          <Box
            className={classes.blob}
            display="table-cell"
            ml={!isMobile && 1}
          >
            <Typography variant="caption" className={classes.weightSemiBold}>
              {freeCancellationLabel}
            </Typography>
          </Box>
        ) : (
          <Box
            className={classes.blobYellow}
            display="table-cell"
            ml={!isMobile && 1}
          >
            <Typography variant="caption" className={classes.weightSemiBold}>
              {t('non refundable')}
            </Typography>
          </Box>
        )}
      </Box>
    ))
  })

  return (
    <Fragment>
      {contentOnly ? renderRoutes : (
        <Card elevation={0} className={classes.card}>
          {renderRoutes}
        </Card>
      )}
    </Fragment>
  )
}

export default HotelsCheckout
