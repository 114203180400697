import constants from './constants'

// set flight outgoing
export const flightOutgoingbreadCrumbAction = (url) => ({
  type: constants.BREAD_CRUMB_FLIGHT_SELECT,
  payload: url,
})

// set train outgoing url
export const trainOutgoingbreadCrumbAction = (url) => ({
  type: constants.BREAD_CRUMB_TRAIN_SELECT,
  payload: url,
})

// cars
export const carBreadCrumbAction = (searchUrl, middlestep = null) => ({
  type: constants.BREAD_CRUMB_CAR_SELECT,
  payload: {searchUrl, middlestep},
})

// Hotel

export const hotelBreadCrumbAction = (url) => ({
  type: constants.BREAD_CRUMB_HOTEL_SELECT,
  payload: url,
})

export const singleHotelBreadCrumbAction = (url) => ({
  type: constants.BREAD_CRUMB_SINGE_HOTEL_SELECT,
  payload: url,
})

export const setBreadcrumbType = (type) => ({
  type: constants.BREAD_CRUMB_SET_TYPE,
  payload: type,
})

// Middlestep
export const setMiddleStepAction = ({ url, type }) => ({
  type: constants.BREAD_CRUMB_MIDDLE_STEP,
  payload: { url, type },
})
