import {
  Card,
  Container,
  Grid,
  TextField,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import COMPANIES from '../../constants/flightCompanies'

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  box: {
    padding: '12px',
    margin: '6px',
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontWeight: theme.typography.fontWeightMedium,
    cursor: 'pointer',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
  },
  info: {
    fontSize: '14px',
  },
  logo: {
    maxWidth: '120px',
  },
  logoContainer: {
    height: '60px',
    alignItems: 'center',
    display: 'flex',
  },
  searchField: {
    width: '200px',

    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
}))
const CheckIn = ({}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()
  const [searchString, setSearchString] = useState(null)

  const companies = useMemo(() => {
    let airlines = Object.entries(COMPANIES)
      .map((a) => ({
        label: a[1].label,
        checkInUrl: a[1].checkInUrl,
        iata: a[0],
      }))
      .filter((a) => a.checkInUrl)
      .filter(
        (a) =>
          !searchString ||
          (searchString.length === 2 && a.iata === searchString) ||
          a.label.match(new RegExp(searchString, 'i'))
      )
      .sort((a, b) => a.label.localeCompare(b.label))

    return Object.values(
      airlines.reduce((acc, airline) => {
        acc[airline.label] = airline
        return acc
      }, {})
    )
  }, [searchString])

  return (
    <Container maxWidth="lg" disableGutters>
      {/*
      <Paper>
        <Typography variant="h3" className={classes.title}>
          {t('check-in')}
        </Typography>
        <Box className={classes.info}>Some info</Box>
      </Paper>
      */}

      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}
      >
        <TextField
          className={classes.searchField}
          placeholder={t('search')}
          variant="outlined"
          size="small"
          onChange={(e) => setSearchString(e.target.value)}
        />
      </div>

      <Grid container justifyContent="center">
        {companies.map((company) => (
          <Grid item xs={6} sm={4} md={2} key={company.iata}>
            <Card
              key={company.iata}
              className={classes.box}
              onClick={() => window.open(company.checkInUrl, '_new')}
            >
              <div className={classes.logoContainer}>
                <img
                  src={`/assets/companies/${company.iata}.png`}
                  alt={company.label}
                  className={classes.logo}
                />
              </div>
              <div className={classes.label}>{company.label}</div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default CheckIn
