import React, { memo, Fragment, useState, useMemo } from 'react'
import { Drawer, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import TransferModal from '../modals/transferModal'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'
import _ from 'lodash'

export const useStyles = makeStyles((theme) => ({
  drawerElement: {
    left: 'auto',
    right: 0,
    width: '428px',
    '& .MuiDrawer-paper': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  bodyOuter: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    minWidth: '428px',
    maxWidth: '428px',
    padding: '0 15px 32px 15px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
  headerTextOuter: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    textAlign: 'center',
    color: theme.palette.common.black,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  headerUnderText: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    textAlign: 'center',
    marginTop: '2px',
    color: theme.palette.common.black,
  },
  headerBlock: {
    display: 'flex',
    padding: '15px 0',
    marginBottom: '25px',
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  headerClose: {
    height: '24px',
    width: '24px',
    marginRight: '14px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  buttonsBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  btn: {
    fontSize: '14px',
    letterSpacing: '1.1px',
    flex: 1,
    fontWeight: '700',
    borderRadius: theme.spacing(3),
    lineHeight: '1.3',
    padding: theme.spacing(1, 2),
    marginBottom: '15px',
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
    },

    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

const AddNewItem = ({ model, isOpen, onSelect, onClose }) => {
  const [transferTrip, setTransferTrip] = useState(null)
  const availableElements = []
  const isShow =
    typeof model?.passengers === 'object' &&
    model?.passengers?.length > 0 &&
    isOpen
  const { isTransfersEnabled } = useEnabledBookingTypesHook()
  if ((!!model.rails || !!model.flights) && isTransfersEnabled) {
    availableElements.push('transfers')
  }

  const modelPassengers = useMemo(() => {
    return model?.passengers?.map((p) => {
      const newUniqueId =
        Date.now().toString(36) + Math.random().toString(36).substr(2)
      const locator = p.locator || newUniqueId
      return {
        ...p,
        uniqueId: p.uniqueId || locator,
        locator: p.locator || p.uniqueId || newUniqueId,
      }
    })
  }, [model])

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  let itmKey = 1

  const selectTransfers = async (transfers) => {
    setTransferTrip(null)
    onSelect(transfers, modelPassengers)
  }
  const openTransfersModal = () => {
    if (!availableElements.includes('transfers')) return false
    let tmpTransferTrip = null

    if (!!model?.rails) {
      tmpTransferTrip = {
        rail: { ...model.rails },
      }
    }
    if (!!model?.flights) {
      const tripData = {
        outbound: model.flights.filter(
          (f) => !!f && typeof f === 'object' && f?.type !== 'returnTrip'
        ),
        return: model.flights.filter(
          (f) => !!f && typeof f === 'object' && f?.type === 'returnTrip'
        ),
      }
      if (!tripData?.return?.length) tripData.return = null

      if (!tmpTransferTrip) tmpTransferTrip = {}
      tmpTransferTrip.flight = tripData
    }

    if (!!tmpTransferTrip) {
      setTransferTrip(tmpTransferTrip)
      return true
    }
    return false
  }

  return (
    <>
      <Drawer
        classes={{
          paper: `${classes.drawerElement} MuiDrawer-paper`,
        }}
        anchor={'bottom'}
        open={isShow}
        onClose={() => onClose()}
      >
        <Box key={`itm-${itmKey++}`} className={classes.bodyOuter}>
          <Box key={`itm-${itmKey++}`} className={classes.headerBlock}>
            <Box key={`itm-${itmKey++}`} minWidth={'50px'} flex={1} />
            <Box key={`itm-${itmKey++}`} flex={4}>
              <Box
                key={`itm-${itmKey++}`}
                className={classes.headerTextOuter}
                textAlign={'center'}
              >
                <span key={`itm-${itmKey++}`} className={classes.headerText}>
                  {t('add to trip')}
                </span>
              </Box>
            </Box>
            <Box
              key={`itm-${itmKey++}`}
              minWidth={'50px'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              display={'flex'}
              flex={1}
            >
              <CloseIcon
                key={`itm-${itmKey++}`}
                onClick={() => onClose()}
                className={classes.headerClose}
              />
            </Box>
          </Box>

          <Box className={classes.buttonsBlock}>
            <Button
              onClick={openTransfersModal}
              variant="contained"
              color="primary"
              disabled={!availableElements.includes('transfers')}
              className={`${classes.btn}`}
            >
              {t('transfer')}
            </Button>
          </Box>
        </Box>
      </Drawer>
      {transferTrip && (
        <TransferModal
          modalBoolean={true}
          trip={transferTrip}
          users={modelPassengers}
          skipLocalStorage={true}
          onSelect={selectTransfers}
          onClose={() => setTransferTrip(null)}
          addedTransfers={model.transfers || null}
        />
      )}
    </>
  )
}

export default memo(AddNewItem)
