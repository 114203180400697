import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const RedirectIfCustomLanding = ({ children }) => {
  const { i18n } = useTranslation()
  const { landingPage } = useSelector((state) => state.customization)
  const { isAuthenticated, publicity } = useSelector((state) => state.auth)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isMobile) return children

  if (isAuthenticated) return children

  if (!isAuthenticated && publicity === 'private')
    return <Redirect to={'/log-in'} />

  const lang = (i18n.language?.split('-')[0] || 'sv').toLowerCase()

  if (!!landingPage?.content[lang]) {
    let slug = landingPage?.content[lang].slug
    return <Redirect to={`/c/${slug}`} />
  }

  return children
}

export default RedirectIfCustomLanding
