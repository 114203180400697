import constants from './constants'

/*
 Seat Reservation Modal
 */
export const openSeatReservationAction = () => ({
  type: constants.OPEN_SEAT_RESERVATION_MODAL,
})

/*
 Booking Process Modal
 */
export const toggleBookingProcess = (open = false) => ({
  type: constants.TOGGLE_BOOKING_IN_PROCESS,
  payload: !!open,
})

/*
 Agent Auth User Modal
 */
export const toggleAgentAuthUser = (open = false) => ({
  type: constants.OPEN_AGENT_AUTH_USER_MODAL,
  payload: !!open,
})

/*
Transfer Modal
*/
export const openTransferModalAction = () => ({
  type: constants.OPEN_TRANSFER_MODAL,
})

/*
Cancel booking Modal
*/
export const openCancelBookingModalAction = (payload = null) => ({
  type: constants.OPEN_CANCEL_BOOKING_MODAL,
  payload: payload,
})

/*
Open Single Hotel modal
*/
export const openSingleHotelImageAction = () => ({
  type: constants.OPEN_SINGLE_HOTEL_IMAGE_MODAL,
})

/*
Open Logout Modal
*/
export const openLogoutModal = () => ({
  type: constants.OPEN_LOGOUT_MODAL,
})

/*
Open Login Modal
*/
export const openLoginModal = () => ({
  type: constants.OPEN_LOGIN_MODAL,
})

/*
Open Edit info modal
 */

export const openEditInfoModal = (rows) => ({
  type: constants.OPEN_EDIT_INFO_MODAL,
  payload: rows,
})

/*
Open employees user modal
 */

export const openEmployeesUserModal = (user) => ({
  type: constants.OPEN_EMPLOYEES_USER_MODAL,
  payload: user,
})

/*
Open deactivate user modal
 */

export const openDeactivateUserModal = (user) => ({
  type: constants.OPEN_DEACTIVATE_USER_MODAL,
  payload: user,
})

/*
Open Edit user Modal
*/
export const openEditUserModalAction = () => ({
  type: constants.OPEN_ADD_USERS_MODAL,
})

/*
Open Activate user modal
 */

export const openActivateUserModalAction = () => ({
  type: constants.OPEN_ACTIVATE_USER_MODAL,
})

/*
Open Booking Link Info modal
 */

export const openBookingLinkInfoModalAction = () => ({
  type: constants.OPEN_BOOKING_LINK_INFO_MODAL,
})

/*
Open Policy exceeded modal
 */

export const openPolicyExceededModal = (policyData) => ({
  type: constants.OPEN_POLICY_EXCEEDED_MODAL,
  payload: policyData,
})

/**
 * Set cancel data for partial booking cancel
 *
 * @param cancelData
 * @returns {{payload, type: string}}
 */
export const setBookingPartCancelModalData = (cancelData) => ({
  type: constants.SET_BOOKING_PART_CANCEL_MODAL_DATA,
  payload: cancelData,
})

/*
Close all modals
*/
export const closeAllModals = (excepts = []) => {
  // Send scroll-to-top in iframe mode.
  if (window.parent !== window) {
    window.parent.postMessage({ type: 'scroll-to-top' }, '*')
  }

  return {
    type: constants.CLOSE_ALL_MODALS,
    payload: { excepts },
  }
}

/*
Open Registration Modal
*/
export const openRegistrationModal = () => ({
  type: constants.OPEN_REGISTRATION_MODAL,
})

/*
open simple info modal
 */
export const openSimpleInfoModal = (data) => ({
  type: constants.OPEN_SIMPLE_INFO_MODAL,
  payload: data,
})

/*
Open Login with company modal
*/
export const openLoginWithCompanyModal = (data) => ({
  type: constants.OPEN_LOGIN_WITH_COMPANY_MODAL,
  payload: data,
})

/*
Open add required information modal
*/
export const openAddMobileModal = (data) => ({
  type: constants.OPEN_ADD_MOBILE_MODAL,
  payload: data,
})

/*
Open add required information modal
*/
export const toggleCheckoutItemsDrawer = (isOpen = true) => ({
  type: constants.TOGGLE_CHECKOUT_SIDE_DRAWER,
  payload: isOpen,
})
