import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { openActivateUserModalAction } from '../../store/modals/modalAction'

const ActivateUser = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    } else {
      dispatch(openActivateUserModalAction())
    }
    // eslint-disable-next-line
  }, [dispatch])

  return <></>
}

export default ActivateUser
