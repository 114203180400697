import React, { memo } from 'react'
import { Box, Typography, Button, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ModalHook from './reusable/modalHook'
import ModalInfoRowsBlock from './reusable/modalInfoRowsBlock'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { closeAllModals } from '../../store/modals/modalAction'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '40px',
  },
  infoRow: {
    width: '100%',
    marginTop: '20px',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  confirmText: {
    padding: '5px 15px',
  },
  btns: {
    borderRadius: theme.spacing(3),
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1, 8),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
  },
}))

const SimpleInfoModal = ({ modalData }) => {
  const modalBoolean = !!modalData?.title || !!modalData?.text
  const textsArray = modalData?.infoTexts?.length ? modalData.infoTexts : []
  const buttons = modalData?.buttons || []
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const closeModal = () => {
    return dispatch(closeAllModals())
  }

  const textLines = modalData?.text?.split('\n')

  return (
    <ModalHook
      display={'flex'}
      minHeight={400}
      width={650}
      modalBoolean={modalBoolean}
    >
      <Box className={classes.header}>
        <IconButton
          color="primary"
          className={classes.closeBtn}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.root}>
        {!!modalData?.title && (
          <Typography
            gutterBottom
            className={classes.weight}
            variant="h4"
            align="center"
          >
            {i18n.exists(modalData.title)
              ? i18n.t(modalData.title)
              : modalData.title}
          </Typography>
        )}

        {textLines?.map((line) => (
          <Typography align="center" className={classes.confirmText}>
            {line}
          </Typography>
        ))}

        {!!textsArray?.length && (
          <Box key={'info-rows-block'} className={classes.infoRow}>
            <ModalInfoRowsBlock textsArray={textsArray} />
          </Box>
        )}
        <Box mt={5}>
          {!!buttons?.length &&
            buttons.map((btn) => (
              <Button
                disableElevation
                className={classes.btns}
                color="primary"
                variant={btn.variant || 'contained'}
                onClick={btn.onClick}
              >
                {btn.text || t('ok')}
              </Button>
            ))}

          {!buttons && (
            <Button
              disableElevation
              className={classes.btns}
              color="primary"
              variant="contained"
              onClick={closeModal}
            >
              {modalData?.buttonText || t('ok')}
            </Button>
          )}
        </Box>
      </Box>
    </ModalHook>
  )
}

export default memo(SimpleInfoModal)
