import React, {Fragment, memo, useCallback, useEffect, useMemo, useState} from 'react'
import ModalHook from "./reusable/modalHook"
import ModalEditInfo from "../profile/modalEditInfo"
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Button} from "@material-ui/core";
import {Select} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    headerBlock: {
        fontSize: '25px',
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: '10px'
    },

    bobyText: {
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightRegular,
        marginBottom: '25px',
    },

    modalOuter: {
        backgroundColor: 'white',
        padding: '25px'
    },

    passengersOuter: {

    },

    passengerRow: {
        paddingLeft: '25px',
        marginBottom: '15px',

        '&:last-child': {
            marginBottom: 0
        }
    },

    nameBlock: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        paddingRight: '20px',
        fontWeight: theme.typography.fontWeightMedium
    },

    select: {
        borderRadius: theme.spacing(0.75),
        width: '150px',

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },

        '& > select': {
            paddingTop: '11px',
            paddingBottom: '11px',
        }
    },

    footerBlock: {
        marginTop: '25px',
        display: 'flex',
        justifyContent: 'flex-end'
    },

    saveBtn: {
        padding: theme.spacing(1, 2),
        fontWeight: theme.typography.fontWeightSemiBold,
        borderRadius: theme.spacing(3),
        letterSpacing: '1.1px',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none'
        }
    },
}))
const EditPassengersGender = ({modalBoolean, passengers, onSave}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [state, setState] = useState({
        genders: {},
        submitted: false
    })

    useEffect(() => {
        const newState = {...state}
        newState.genders = {}
        const pArray = passengers?.length ? passengers : []
        pArray.forEach(p => {
            if (!p.uniqueId) return false
            newState.genders[p.uniqueId] = possibleOpts?.[p?.gender || ''] || ''
        })
        setState(newState)
    }, [passengers])

    const possibleOpts = useMemo(() => ({'male': 'male', 'female': 'female'}), [])

    const errors = useMemo(() => {
        let res = null
        passengers.forEach(p =>  {
            const gender = state.genders?.[p?.uniqueId || ''] || null
            if (!!p && !gender) {
                if (!res) res = {}
                res[p.uniqueId] = true
            }
        })
        return res
    }, [state.genders, passengers, possibleOpts])

    const getOptions = useCallback(() => {
        const results = []
        for (let value in possibleOpts) {
            const text = possibleOpts[value]
            results.push(<option value={value}>{t(possibleOpts[value])}</option>)
        }
        return results
    }, [possibleOpts])

    const changeGender = (uId, event) => {
        setState({...state, genders: {...state.genders, [uId]: event.target.value}})
    }

    const saveGenders = () => {
        setState({...state, submitted: true})
        if (!errors) onSave(state.genders)
    }

    return (
        <ModalHook width={700} modalBoolean={modalBoolean}>
            <Box className={classes.modalOuter}>
                <Box key="headerBlock" width={'100%'} className={classes.headerBlock}>{t('select gender')}</Box>
                <Box key="bobyText" width={'100%'} className={classes.bobyText}>{t('gender selection text')}</Box>
                <Grid key={'passengers-outer'} container className={classes.passengersOuter}>
                    {passengers.map(p => {
                        const gender = state.genders?.[p?.uniqueId || ''] || null
                        const error = !!errors?.[p?.uniqueId || ''] && !!state.submitted
                        return (
                            <Fragment>
                                <Grid key={`passenger-${p?.uniqueId}`} container item xs={12} className={classes.passengerRow}>
                                    <Grid key="passenger-name" item xs={12} sm={4} className={classes.nameBlock}>
                                        {p.firstName} {p.lastName}
                                    </Grid>
                                    <Grid key="passenger-gender" item xs>
                                        <Select
                                            key="passenger-select"
                                            native
                                            value={!gender ? '' : p?.gender}
                                            variant="outlined"
                                            className={classes.select}
                                            InputLabelProps={{ shrink: false }}
                                            onChange={(e) => changeGender(p.uniqueId, e)}
                                            error={!!error}
                                        >
                                            <option value={''}>{t('select')}</option>
                                            {getOptions()}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )
                    })}
                </Grid>
                <Box key="footerBlock" width={'100%'} className={classes.footerBlock}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.saveBtn}
                        onClick={saveGenders}
                    >
                        {t('save genders')}
                    </Button>
                </Box>
            </Box>
        </ModalHook>
    )
}

export default memo(EditPassengersGender);
