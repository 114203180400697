import React, { memo } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { removeAddOns, setAddOns } from '../../store/cars/carAction'

const useStyles = makeStyles((theme) => ({
  subHeaderBlock: {
    fontSize: '24px',
    paddingBottom: '20px',
    borderBottom: `1px solid ${theme.palette.border}`,
    marginBottom: '28px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,

    '&.noBorder': {
      paddingBottom: 0,
      borderBottom: 0,
      marginBottom: '21px',
    }
  },
  extraText: {
    fontSize: '16px',
    color: theme.palette.common.black,
    marginBottom: '21px',
  },
  boxBorder: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px',
    padding: '25px 15px'
  },
  insuranceTitle: {
    fontWeight: 'bold'
  },
  included: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  basicCoverage: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  extrasBtn: {
    color: '#007e7e',
    backgroundColor: '#ceebea',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: 'none',
    padding: '8px 60px',
    marginTop: '20px',

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#ceebea',
    }
  },
  addOns: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px'
  }
}))

const InsuranceBlock = ({inclTexts, tariffInfo, currency, addedAddOns}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch();


  let basicCoverageInfo = ''
  inclTexts?.insuranceTexts?.map((inclText, index) => {
    basicCoverageInfo += (index === 0) ? inclText : ((index === inclTexts?.insuranceTexts.length - 1) ? ` ${t('and')} ${inclText}`: `, ${inclText}`)
  })
  basicCoverageInfo = !!basicCoverageInfo ? ` ${basicCoverageInfo}.` : '.'

  const getInsuranceName = (insurance) => {
    const textsByCovType = {
      'CDW': 'car cdw',
      'LDW': 'car ldw',
      'TP': 'car tp',
      'THW': 'car thw',
    }

    return insurance?.covType ? t(textsByCovType?.[insurance?.covType]) : insurance.name
  }

  const renderAddInsuranceBlocks = () => {
    return inclTexts?.covAmount > 0 ? (
      tariffInfo?.insurance?.opt?.map(insurance => (
        <Box className={[classes.boxBorder, classes.addOns]}>
          <Box>
            <Box className={classes.insuranceTitle}>{ getInsuranceName(insurance) }</Box>
            {
              addedAddOns.findIndex((addOn) => addOn.uniqueId === insurance.uniqueId) !== -1
                ? <Button
                  className={classes.extrasBtn}
                  variant="contained"
                  onClick={() => dispatch(removeAddOns(insurance.uniqueId))}
                >
                  {t('remove')}
                </Button>
                : <Button
                  className={classes.extrasBtn}
                  variant="contained"
                  onClick={() => dispatch(setAddOns(insurance))}
                >
                  {t('add')}
                </Button>
            }
          </Box>
          <Box>
            {currency} {''}
            <strong>{insurance.price}</strong>
          </Box>
        </Box>
      ))
    ) : ''
  }

  return (
    <>
      <Box className={classes.subHeaderBlock}>
        {t('add insurance')}
      </Box>
      <Box>
        <Box className={[classes.extraText, classes.boxBorder]}>
          <Box className={classes.basicCoverage}>
            <Box className={classes.insuranceTitle}>{t('basic coverage')}</Box>
            <Box className={classes.included}>{t('included')}</Box>
          </Box>
          <Box>{t('cov explanation')}{basicCoverageInfo}</Box>
        </Box>
        { renderAddInsuranceBlocks() }
      </Box>
    </>
  )
}

export default memo(InsuranceBlock)