import React, { useEffect, memo, useState, createContext, useMemo } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  fetchHotelSearchAction,
  fetchHotelRatingsAction,
} from '../../store/hotels/hotelsAction'
import MidddleStepHandler from '../../containers/middlestep/middleStepHandler'
import HotelSearchContent from '../../containers/hotels/result/hotelSearchContent'
import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import DesktopDetailsHeader from '../../containers/header/desktopDetailsHeader/desktopDetailsHeader'
import { setMiddleStepAction } from '../../store/breadcrumbs/breadcrumbsAction'

export const MiddlestepDrawerContext = createContext([{}, () => {}])

const Middlestep = () => {
  const params = useParams()
  const { pathname } = useLocation()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const searchData = useSelector((state) => state.search)
  const { multistep } = searchData?.hotels || {}
  const bedsCount = useMemo(() => {
    return searchData.hotels?.travelerCounts?.beds?.count || 1
  }, [searchData.hotels])
  const { showMap } = useSelector((store) => store.hotels)
  const { sum } = useSelector((store) => store.checkout)
  const dispatch = useDispatch()
  const [rightDrawer, setRightDrawer] = useState(false)

  useEffect(() => {
    const payload = {
      url: pathname,
      type: params.type,
    }
    dispatch(setMiddleStepAction(payload))
  }, [dispatch, params.type, pathname])

  useEffect(() => {
    const payload = {
      checkIn: params.checkIn,
      checkOut: params.checkOut,
      countryCode: params.countryCode,
      guests: bedsCount,
      roomCount: params.roomCount,
      type: params.type,
    }

    const coordinates = {
      lat: params.lat,
      lng: params.lng,
      countryCode: params.countryCode,
    }

    dispatch(fetchHotelRatingsAction(coordinates))
    dispatch(fetchHotelSearchAction({ ...payload, ...coordinates }))
    // fetch Ratings based on Params
  }, [
    params.checkIn,
    params.checkOut,
    params.countryCode,
    bedsCount,
    params.roomCount,
    params.type,
    params.lat,
    params.lng,
    dispatch,
  ])

  const desktopContentWidth = showMap ? 7 : 12

  const toggleRightDrawer = () => {
    setRightDrawer(!rightDrawer)
  }

  return (
    <MiddlestepDrawerContext.Provider
      value={{ rightDrawer, toggleRightDrawer }}
    >
      {isDesktop ? (
        <DesktopDetailsHeader tripPrice={sum} />
      ) : (
        <MobileHeader tripPrice={sum} />
      )}
      <Box pt={!isDesktop ? 4 : 4} p={isMobile ? 0 : 1} pb={40}>
        <Container maxWidth="lg" disableGutters>
          <Grid container>
            {!multistep && (
              <Grid item xs={12} style={{ zIndex: 10 }}>
                <MidddleStepHandler type={params.type} />
              </Grid>
            )}
            <Grid
              item
              xs={isMobile ? 12 : desktopContentWidth}
              style={{ zIndex: 1 }}
            >
              <HotelSearchContent
                lat={params.lat}
                lng={params.lng}
                isMiddleStep={true}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </MiddlestepDrawerContext.Provider>
  )
}

export default memo(Middlestep)
