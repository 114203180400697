import CONSTANTS from './constants'
import axios from 'axios'
import { fetchCars } from '../../repositories/cars'
import { cancelRqByKey } from '../../repositories/agent'
import moment from 'moment'
import { setCarSearchCache } from '../search/searchAction'

// fetch cars
export const fetchAndSaveCars =
  (newSearchCache = '') =>
  async (dispatch, getState) => {
    const { search, cars: carsState } = getState()
    const { cars = {} } = search || {}
    const { cars: carsList } = carsState || {}
    const { departure, arrival, from, to, travellers, difPlace, searchCache } =
      cars

    if (
      carsList?.rates?.length &&
      (!newSearchCache || newSearchCache === searchCache)
    ) {
      return
    }
    if (!!newSearchCache) dispatch(setCarSearchCache(newSearchCache))
    let exception = null
    dispatch(resetState())

    try {
      const isNotFrom = !from?.coords?.lat || !from?.coords?.lng
      const isNotTo = !to?.coords?.lat || !to?.coords?.lng

      if (
        !departure ||
        !arrival ||
        isNotFrom ||
        !travellers?.length ||
        (!!difPlace && isNotTo)
      )
        throw Error('invalid data')
      const payload = {
        startDate: moment(departure).format('YYYY-MM-DD HH:mm'),
        endDate: moment(arrival).format('YYYY-MM-DD HH:mm'),
        from: {
          lat: from?.coords?.lat,
          lng: from?.coords?.lng,
          type: 'coord',
          distance: from?.coords?.distance,
        },
      }
      if (difPlace) {
        payload.to = {
          lat: to?.coords?.lat,
          lng: to?.coords?.lng,
          type: 'coord',
          distance: to?.coords?.distance,
        }
      }
      dispatch(isLoading(true))
      const res = await fetchCars(payload, 'mainCarsSearch')
      if (!res?.rates?.length) throw Error('invalid data')

      // Pre-filter rates to remove cars we can not handle
      res.rates = res.rates.filter(
        (c) => c.vehicle?.model && c.estimatedTotal && !c.guaranteeRequired
      )

      dispatch(setCarsSearchResults(res))
    } catch (e) {
      exception = e
      dispatch(clearCarsSearch())
    } finally {
      if (!axios.isCancel(exception)) dispatch(isLoading(false))
    }
  }

export const setCarsSearchResults = (payload) => ({
  type: CONSTANTS.FETCH_RENTAL_CARS,
  payload,
})
export const clearCarsSearch = () => ({ type: CONSTANTS.CLEAR_CARS_SEARCH })

export const resetState = () => (dispatch) => {
  dispatch({ type: CONSTANTS.RESET_CARS_STATE })
}

export const setCarExtras = (extras = []) => ({
  type: CONSTANTS.SET_CAR_EXTRAS,
  payload: extras,
})

export const setCarFilters = (filters = {}) => ({
  type: CONSTANTS.SET_CAR_FILTERS,
  payload: filters,
})

export const resetCarFilters = () => ({
  type: CONSTANTS.RESET_CAR_FILTERS,
})

export const removeCarExtra = (uniqueId) => ({
  type: CONSTANTS.ADD_CAR_EXTRA,
  payload: uniqueId,
})

export const setSelectedCar = (car = {}) => ({
  type: CONSTANTS.SET_SELECTED_CAR,
  payload: car,
})

const isLoading = (loading) => ({
  type: CONSTANTS.IS_CARS_SEARCH_LOADING,
  payload: loading,
})

export const setAddOns = (addOns = []) => ({
  type: CONSTANTS.SET_ADD_ONS,
  payload: addOns,
})

export const removeAddOns = (uniqueId) => ({
  type: CONSTANTS.REMOVE_ADD_ONS,
  payload: uniqueId,
})
