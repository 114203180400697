import React, { memo, Fragment } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Grid, Container, Button, IconButton, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useSelector } from 'react-redux'
import { ReactComponent as Logo } from '../../assets/images/Logo-BLK-Horizontal.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '30px',
    paddingTop: '75px',
    paddingBottom: '80px',
    width: '100%',
    backgroundColor: '#f7f7f7'
  },
  container: {
    padding: 0
  },
  dividerBlock: {
    padding: '60px 0 70px 0',

    '& > hr': {
      color: "#cccccc",
      width: '100%'
    }
  },
  colHeader: {
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: '15px'
  },
  buttonBlock: {
    paddingTop: '5px',

  },
  colText: {
    fontSize: '14px',
    color: theme.palette.common.black,
    lineHeight: 'normal',

    '&.col-menu-text': {
      lineHeight: 2.14,
      padding: 0,
      textTransform: 'none',
      textAlign: 'left',
      justifyContent: 'left'
    }
  },
  btn: {
    color: theme.palette.common.black,
    textTransform: 'capitalize',
    padding: '10px 43px',
    borderRadius: '22px',
  },
  socialBtnsBlock: {
    marginTop: '7px'
  },
  logoBlock: {

  },
  poweredBy: {
    fontSize: '10px',
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.common.black,
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  col: {
    width: '100%',
    minHeight: '100px',
  }
}))

const Footer = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { isAuthenticated } = useSelector((state) => state.auth)

  return (
    <Fragment>
      {!isAuthenticated && !isMobile &&
        <Box className={classes.root}>
          <Container className={classes.container}>
            <Grid container spacing={0}>
              <Grid container item xs={2} spacing={0}>
                <Box className={classes.col}>
                  <Box className={`${classes.colHeader} ${classes.colText}`}>
                    {t('travel with us')}
                  </Box>
                  <Box>
                    <Button className={`col-menu-text ${classes.colText}`}>
                      {t('your bookings')}
                    </Button>
                  </Box>
                  <Box>
                    <Button className={`col-menu-text ${classes.colText}`}>
                      {t('create account')}
                    </Button>
                  </Box>
                  <Box>
                    <Button className={`col-menu-text ${classes.colText}`}>
                      {t('terms of use')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs={2} spacing={0}>
                <Box className={classes.col}>
                  <Box className={`${classes.colHeader} ${classes.colText}`}>
                    {t('about')}
                  </Box>
                  <Box>
                    <Button className={`col-menu-text ${classes.colText}`}>{t('about us')}</Button>
                  </Box>
                  <Box>
                    <Button className={`col-menu-text ${classes.colText}`}>
                      {t('become partner')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs={2} spacing={0}>
                <Box className={classes.col}>
                  <Box className={`${classes.colHeader} ${classes.colText}`}>
                    {t('need assistance')}
                  </Box>
                  <Box className={`${classes.buttonBlock}`}>
                    <Button className={classes.btn} variant="outlined" color="primary">
                      {t('help center')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs spacing={0}>
                <Box pl={'15px'} className={classes.col}>
                  <Box pl={'12px'} className={`${classes.colHeader} ${classes.colText}`}>
                    {t('follow us')}
                  </Box>
                  <Box className={classes.socialBtnsBlock}>
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <img alt={'Facebook'} src={`/assets/images/fbLogo.png`}/>
                    </IconButton>
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <img alt={'Instagram'} src={`/assets/images/instagramLogo.png`}/>
                    </IconButton>
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <img alt={'LinkedIn'} src={`/assets/images/linkedInLogo.png`}/>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid className={classes.dividerBlock} container item xs={12} spacing={0}>
                <Divider/>
              </Grid>
              <Grid className={classes.logoBlock} container item xs={12} spacing={0}>
                <Box width={'100%'}>
                  <Box width={'112px'}>
                    <Logo/>
                  </Box>
                </Box>
                <Box className={classes.poweredBy} width={'100%'}>
                  {t('powered by')}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
    </Fragment>
  )
}

export default memo(Footer)
