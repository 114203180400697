import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Box, useTheme, useMediaQuery } from '@material-ui/core'
import { useSelector } from 'react-redux'
import HotelSearchItem from '../../../components/hotels/hotelSearchItem'
import HotelSkeletons from '../../../components/reusable/skeletons/hotelSkeletons'
import useHotelSort from '../../../hooks/useHotelSortHook'
import { useCardRef } from '../../../hooks/useCardRef'

const SearchResultsContainer = ({
  sort,
  isMiddleStep,
  search,
  showZoneData = false,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const ratings = useSelector((state) => state?.hotels?.ratings)
  const isSearchLoading = useSelector((state) => state?.hotels?.isSearchLoading)
  const selectedHotel = useSelector((state) => state?.hotels?.selectedHotel)
  const [firstMount, setFirstMount] = useState(true)
  const [selected, setSelected] = useState(null)
  const sortedHotels = useHotelSort(search, sort)

  const cardRef = useCallback(
    (node) =>
      useCardRef(
        node,
        selectedHotel,
        setSelected,
        selected,
        firstMount,
        isMobile
      ),
    [selectedHotel, firstMount, selected, isMobile]
  )

  const selectRef = (hotel) => {
    if (hotel?.hotelCode === selectedHotel) {
      return cardRef
    } else {
      return null
    }
  }

  useEffect(() => {
    setFirstMount(false)
  }, [])

  const renderHotels = sortedHotels.map((hotel, i) => {
    const height = isMobile
      ? hotel.priority
        ? '440px'
        : '380px'
      : hotel.priority
      ? '300px'
      : '240px'
    const key = hotel?.hotelCode
    return (
      <Grid item xs key={key} ref={selectRef(hotel)} style={{ zIndex: 6 }}>
        <HotelSearchItem
          hotel={hotel}
          ratings={ratings}
          isMiddleStep={isMiddleStep}
          showZoneData={showZoneData}
        />
      </Grid>
    )
  })

  return (
    <Box>
      <Grid
        container
        direction="column"
        spacing={!isMobile ? 2 : 2}
        id="cards-container"
      >
        {isSearchLoading ? <HotelSkeletons /> : renderHotels}
      </Grid>
    </Box>
  )
}

export default SearchResultsContainer
