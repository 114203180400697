import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Popper from '@material-ui/core/Popper'
import {MainBGContext} from '../../hooks/backgroundHook';

const useStyles = makeStyles((theme) => ({
  input: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  companiesInner: {
    borderRadius: 8,
    border: '1px solid #e6e6e6',
  },
  startAdornment: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightSemiBold,
    letterSpacing: '0.7px',
    marginBottom: 5,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2),
  },
  searchBox: {
    backgroundColor: '#ffffff',

    '& .MuiOutlinedInput-root': {
      paddingLeft: theme.spacing(0.25),
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 8,
      fontWeight: theme.typography.fontWeightSemiBold,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
      },
    },
  },
  innerInput: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: 0,

    '& .MuiTextField-root .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
}))


const SearchFieldForAgent = ({
  autoFocus = false,
  value,
  setValue,
  fetchOptions,
  renderOption,
  getOptionLabel,
  checkSelectedOpt,
  placeholder,
  label,
  FieldIcon
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { clientWidth } = useContext(MainBGContext) // needed in order to update component when client width changes
  const [fWidth, setFWidth] = useState(null)
  const [fNode, setFNode] = useState(null)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [searchText, setSearchText] = useState('')
  const [openOnFocus, setOpenOnFocus] = useState(true)

  const calculateWidth = useCallback(node => {
    setFWidth(node?.clientWidth || null)
  }, [])
  useEffect(() => {
    calculateWidth(fNode)
  }, [fNode, clientWidth])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const getOptions = useCallback(async (name) => {
    const data = await fetchOptions(name)

    setOptions( data?.length > 0 ? data : [])
    setLoading(false)
  }, [fetchOptions])

  useEffect(() => {
    setLoading(true)
    const timerId = setTimeout(() => {
      getOptions(searchText)
    }, 1000)

    return () => {
      clearTimeout(timerId);
    };
  }, [searchText, getOptions])

  const renderGeneral = () => {
    return (
      <div ref={setFNode} className={classes.companiesInner}>
        <Autocomplete
          loading={loading}
          fullWidth
          filterSelectedOptions
          disableClearable
          openOnFocus={openOnFocus}
          popupIcon={null}
          onBlur={() => {
            setOpenOnFocus(true)
          }}
          options={options}
          noOptionsText={t('no results')}
          getOptionLabel={(option) => getOptionLabel(option)}
          getOptionDisabled={(option, value) => checkSelectedOpt(option, value) }
          value={value}
          onInputChange={(e,v) => setSearchText(v)}
          PopperComponent={(props) => (
            <Popper
              {...props}
              {...(!fWidth ? {} : {style: {width: `${fWidth}px`}})}
              placement="bottom"
              modifiers={{
                offset: {
                  enabled: true,
                  offset: '-12,6',
                },
              }}
            />
          )}
          onChange={(e, value) => {
            handleChange(e, value)
          }}
          renderOption={renderOption}
          renderInput={(params) => {
            return (
              <Box className={classes.searchBar}>
                <Typography
                  variant="body1"
                  classes={{ root: classes.startAdornment }}
                >
                  {label}
                </Typography>
                <Box
                  className={classes.innerInput}
                  display="flex"
                >
                  {!!FieldIcon && <FieldIcon color="primary" />}
                  <TextField
                    {...params}
                    autoFocus={!!autoFocus}
                    classes={{ root: classes.searchBox }}
                    className={classes.input}
                    variant="outlined"
                    placeholder={placeholder}
                  />
                </Box>
              </Box>
            )
          }}
        />
      </div>
    )
  }

  return (
    <Fragment>
      {renderGeneral()}
    </Fragment>
  )
}

export default SearchFieldForAgent
