import React, { memo, useEffect, useState } from 'react'
import ModalHook from './reusable/modalHook'
import { closeAllModals } from '../../store/modals/modalAction'
import { useDispatch } from 'react-redux'
import CompanySelection from '../../components/reusable/companySelection'
import AuthWrapper from '../authWrapper/authWrapper'
import { loginAction } from '../../store/auth/authAction'

const LoginWithCompanyModal = ({
  modalBoolean,
  companies,
  email,
  password,
  agency,
}) => {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(closeAllModals())
  const [isLoading, setIsLoading] = useState(false)

  const handleSelected = (companyId = null) => {
    setIsLoading(true)
    const payload = {
      email,
      password,
      selectedCompanyId: companyId,
      agency: agency,
    }
    dispatch(loginAction(payload))
  }

  const handleBack = () => {
    closeModal()
  }

  useEffect(() => {
    // Reset loading status
    if (modalBoolean) {
      setIsLoading(false)
    }
  }, [modalBoolean])

  return (
    <ModalHook width={500} modalBoolean={modalBoolean} marginTop={250}>
      <AuthWrapper isModal={true} onBackClicked={handleBack}>
        <CompanySelection
          companies={companies}
          isLoading={isLoading}
          onSelected={handleSelected}
        />
      </AuthWrapper>
    </ModalHook>
  )
}

export default memo(LoginWithCompanyModal)
