import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import Box from '@material-ui/core/Box'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import SearchIcon from '@material-ui/icons/Search'
import ListIcon from '@material-ui/icons/List'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
})

const MobileBottomNavigation = () => {
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const [value, setValue] = React.useState(null)

  const onChange = (event, newValue) => {
    setValue(newValue)

    switch (newValue) {
      case 0:
        return history.push('/flights')
      case 1:
        return history.push('/my-trips')

      case 2:
        return history.push('/profile')
      default:
        return null
    }
  }

  if (true) {
    return null
  }

  return (
    <>
      {matches ? (
        <Box className={classes.root}>
          <BottomNavigation
            value={value}
            onChange={onChange}
            showLabels
            className={classes.root}
          >
            <BottomNavigationAction label="Search" icon={<SearchIcon />} />
            <BottomNavigationAction label="My trips" icon={<ListIcon />} />
            <BottomNavigationAction
              label="Profile"
              icon={<AccountCircleIcon />}
            />
          </BottomNavigation>
        </Box>
      ) : (
        ''
      )}
    </>
  )
}

export default memo(MobileBottomNavigation)
