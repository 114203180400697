import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ModalHook from './reusable/modalHook'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  TextField,
  useTheme,
} from '@material-ui/core'
import { Field, Formik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import * as Yup from 'yup'
import i18n from 'i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {
  getUserAction,
  setLoyaltyPrograms,
  updateUserAction,
} from '../../store/auth/authAction'
import {
  loyaltyPrograms,
  loyaltySegmentsByCode,
} from '../../constants/loyalties'
import flightCompanies from '../../constants/flightCompanies'
import { TRAIN_COMPANIES } from '../../constants/trainCompanies'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { showSnackbarAction } from '../../store/snackbars/snackbarAction'

const useStyles = makeStyles((theme) => ({
  modalOuter: {
    padding: theme.spacing(4),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '430px',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  headerBlock: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.border}`,

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  headingText: {
    color: theme.palette.common.black,
    fontSize: '25px',
    lineHeight: 'normal',
    fontWeight: theme.typography.fontWeightBold,

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '16px',
    },
  },
  fieldLabel: {
    color: theme.palette.common.black,
    fontSize: '16px',
    lineHeight: 'normal',
    fontWeight: theme.typography.fontWeightMedium,
  },
  footerBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  generalBtn: {
    fontWeight: theme.typography.fontWeightSemiBold,
    minWidth: '130px',
    padding: '8px 16px',
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
    },
  },
  selectInput: {
    '& fieldset': {
      borderRadius: '6px',
    },
    '& .MuiSelect-select': {
      paddingTop: '15px',
      paddingBottom: '15px',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
  },
  textInput: {
    '& fieldset': {
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-root > input': {
      paddingTop: '15px',
      paddingBottom: '15px',
    },
  },
  form: {
    '& > fieldset': {
      marginBottom: '20px',
    },
  },
  headerBtn: {
    textTransform: 'capitalize',
    fontSize: '16px',
    letterSpacing: '1.1px',
    color: theme.palette.primary.main,
  },
}))

const AddLoyaltyModal = ({ modalBoolean, closeModal }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const segFormRef = useRef(null)
  const loyaltyFormRef = useRef(null)
  const dispatch = useDispatch()
  const [segment, setSegment] = useState(null)
  const { user } = useSelector((state) => state.auth)

  const segInitials = useMemo(() => ({ pendingSegment: 'flight' }), [])
  const loyaltyInitials = useMemo(
    () => ({
      type: '',
      value: '',
    }),
    []
  )

  const typeOpts = useMemo(() => {
    if (!loyaltyPrograms?.[segment]?.length) return null

    let programs = loyaltyPrograms[segment]
      .map((o) => {
        const val = o.value || ''

        switch (segment) {
          case 'train':
            return {
              name: `${TRAIN_COMPANIES?.[val] || val}, ${o.name}`,
              value: val,
            }

          case 'flight':
            return {
              name: `${flightCompanies?.[val]?.label || val}, ${o.name}`,
              value: val,
            }
          default:
            return {
              name: `${o.name}`,
              value: val,
            }
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))

    return programs.map((o, ind) => (
      <MenuItem key={`opt-${ind}`} value={o.value}>
        {o.name}
      </MenuItem>
    ))
  }, [segment])

  const loyaltyValidation = useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string().required(i18n.t('loyalty program required')),
        value: Yup.string()
          .when('type', (type, schema) => {
            if (type === 'SJR') {
              return schema.matches(
                /^[0-9]{16}$/,
                i18n.t('loyalty program min sj')
              )
            } else if (type === 'SK') {
              return schema.matches(
                /^([E]|[e])([B]|[b])([PBSGD]|[pbsgd])[0-9]{9}$/,
                i18n.t('loyalty program sas wrong format')
              )
            }
            return schema
          })
          .required(i18n.t('loyalty number required')),
      }),
    []
  )

  const resetForms = useCallback(() => {
    setSegment(null)
    if (!!segFormRef?.current?.resetForm) segFormRef.current.resetForm()
  }, [])

  const onNextClick = useCallback(() => {
    if (!segment && segFormRef?.current?.handleSubmit) {
      segFormRef.current.handleSubmit()
    } else if (segment && loyaltyFormRef?.current?.handleSubmit) {
      loyaltyFormRef.current.handleSubmit()
    }
  }, [segment])

  const sendLoyalty = useCallback(
    async (values) => {
      closeModal()
      try {
        const card = values.value.replace(/\s/gi, '')
        const sector = Object.keys(loyaltySegmentsByCode).find(
          (key) => loyaltySegmentsByCode[key] === segment
        )

        await dispatch(
          updateUserAction({
            section: 'more',
            updateData: {
              loyalties: [
                {
                  segment: segment,
                  type: values.type,
                  value: card,
                },
              ],
            },
          })
        )
        dispatch(showSnackbarAction('success', t('update success')))

        // Local optimistic state update to get around slow Umbrella updates
        let newLoyalties = [
          ...user.loyaltyProgram,
          {
            sector: sector,
            operator: values.type,
            card: card,
          },
        ]
        dispatch(setLoyaltyPrograms(newLoyalties))
      } catch (e) {
        console.log(e)
        dispatch(showSnackbarAction('error', t('update error')))
      }
    },
    [dispatch, segment, closeModal, t]
  )

  useEffect(() => {
    resetForms()
  }, [modalBoolean, resetForms])

  const segmentSelectorContent = () => {
    return (
      <Formik
        innerRef={segFormRef}
        enableReinitialize
        onSubmit={(values) => setSegment(values.pendingSegment)}
        initialValues={segInitials}
      >
        {(formik) => (
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <FormControl component="fieldset">
              <Box mb={'14px'} className={classes.fieldLabel}>
                {t('type of loyalty program')}
              </Box>
              <Field name={'pendingSegment'}>
                {({ field }) => {
                  return (
                    <RadioGroup
                      {...field}
                      row
                      name={'pendingSegment'}
                      value={formik?.values?.pendingSegment || ''}
                    >
                      <Box width={'100%'}>
                        <FormControlLabel
                          key={'pend-sef-fl'}
                          value="flight"
                          control={<Radio color="primary" />}
                          label={t('nav flight')}
                        />
                      </Box>
                      <Box width={'100%'}>
                        <FormControlLabel
                          key={'pend-sef-tr'}
                          value="train"
                          control={<Radio color="primary" />}
                          label={t('nav train')}
                        />
                      </Box>
                      <Box width={'100%'}>
                        <FormControlLabel
                          key={'pend-sef-ho'}
                          value="hotel"
                          control={<Radio color="primary" />}
                          label={t('nav hotel')}
                        />
                      </Box>
                      <Box width={'100%'}>
                        <FormControlLabel
                          key={'pend-sef-car'}
                          value="car"
                          control={<Radio color="primary" />}
                          label={t('car')}
                        />
                      </Box>{' '}
                    </RadioGroup>
                  )
                }}
              </Field>
            </FormControl>
          </form>
        )}
      </Formik>
    )
  }

  const loyaltyFormContent = () => {
    return (
      <Formik
        innerRef={loyaltyFormRef}
        enableReinitialize
        onSubmit={sendLoyalty}
        initialValues={loyaltyInitials}
        validationSchema={loyaltyValidation}
      >
        {(formik) => (
          <form
            className={classes.form}
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <FormControl component="fieldset" fullWidth>
              <Box mb={'10px'} className={classes.fieldLabel}>
                {t('select loyalty program')}
              </Box>
              <Box width={'100%'}>
                <Select
                  key={'field-type'}
                  displayEmpty
                  error={!!(formik.touched.type && formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                  className={classes.selectInput}
                  name={'type'}
                  variant={'outlined'}
                  placeholder={t('select')}
                  fullWidth
                  onChange={formik.handleChange}
                  IconComponent={ExpandMoreIcon}
                >
                  <MenuItem
                    style={{ color: 'silver' }}
                    key={`type-empty`}
                    value=""
                  >
                    <span style={{ color: '#999999', fontWeight: '500' }}>
                      {t('select')}...
                    </span>
                  </MenuItem>
                  {typeOpts}
                </Select>
              </Box>
            </FormControl>

            <FormControl component="fieldset" fullWidth>
              <Box mb={'10px'} className={classes.fieldLabel}>
                {t('program number')}
              </Box>
              <Box width={'100%'}>
                <TextField
                  key={'field-value'}
                  error={!!(formik.touched.value && formik.errors.value)}
                  helperText={formik.touched.value && formik.errors.value}
                  className={classes.textInput}
                  onChange={formik.handleChange}
                  name={'value'}
                  variant={'outlined'}
                  fullWidth
                />
              </Box>
            </FormControl>
          </form>
        )}
      </Formik>
    )
  }

  const getContent = () => {
    if (!segment) return segmentSelectorContent()
    return loyaltyFormContent()
  }

  return (
    <ModalHook
      minHeight={300}
      width={500}
      modalBoolean={modalBoolean}
      marginTop={250}
    >
      <Box className={classes.modalOuter}>
        <Box className={classes.headerBlock}>
          {isMobile && (
            <Button className={classes.headerBtn} onClick={() => closeModal()}>
              {t('cancel')}
            </Button>
          )}
          <Box flex={1} className={classes.headingText}>
            {t('add bonus number')}
          </Box>
          {isMobile && (
            <Button
              className={classes.headerBtn}
              style={{ fontWeight: theme.typography.fontWeightBold }}
              onClick={() => onNextClick()}
            >
              {!segment ? t('next') : t('save btn')}
            </Button>
          )}
        </Box>
        <Box flex={1}>{getContent()}</Box>
        {!isMobile && (
          <Box className={classes.footerBlock}>
            <Button className={classes.generalBtn} onClick={() => closeModal()}>
              {t('cancel')}
            </Button>
            <Button
              onClick={onNextClick}
              type="submit"
              variant="contained"
              color="primary"
              style={{
                letterSpacing: '0.7px',
                fontWeight: theme.typography.fontWeightBold,
              }}
              className={classes.generalBtn}
            >
              {!segment ? t('next') : t('save btn')}
            </Button>
          </Box>
        )}
      </Box>
    </ModalHook>
  )
}

export default memo(AddLoyaltyModal)
