import {
  Avatar,
  Box,
  IconButton,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    textAlign: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '24px',
    },
    '& .MuiIconButton-label': {
      flexDirection: 'column',
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: '10px',
      gap: '8px',
      fontFamily: theme.typography.fontFamily,
      textWrap: 'nowrap',
    },
  },

  iconAvatar: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },

  iconAvatarPrimary: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: 'white',
  },
}))

export default function CancelButton({ label, onClick, color, icon }) {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <IconButton
      onClick={onClick}
      color={color}
      className={classes.iconButton}
      style={{ backgroundColor: 'transparent' }}
      disableRipple
    >
      <Avatar
        className={
          color == 'primary' ? classes.iconAvatarPrimary : classes.iconAvatar
        }
      >
        {icon}
      </Avatar>
      <Box style={{ color }}>{label}</Box>
    </IconButton>
  )
}
