import React, { memo } from 'react'
//import Stepper from '@material-ui/core/Stepper'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import Box from '@material-ui/core/Box'
import { IconButton } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { closeAllModals } from '../../store/modals/modalAction'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  between: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: '64px',
      borderBottom: '1px solid #e6e6e6'
    }
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  closeBtn: {
    marginLeft: 'auto',
  },
  stepperContainer: {
    width: '100%',
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center'
  }
}))

const TransferModalHeader = ({ handleBack, activeStep, title, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const closeModal = () => {
    if (typeof(onClose) !== 'function') {
      dispatch(closeAllModals())
    } else {
      onClose()
    }
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box className={classes.between}>
      <Box width="64px" display="flex" justifyContent="flex-start">
        {activeStep > 0 ? (
          <IconButton onClick={handleBack}>
            <ArrowBackOutlinedIcon color="primary" />
          </IconButton>
        ) : (
          ''
        )}
      </Box>
      {/* Stepper */}
      {/* <Box className={classes.stepperContainer}>
        <Stepper activeStep={activeStep}>{renderSteps}</Stepper>
      </Box> */}
      <Box style={{ flex: 1 }}>
        <Typography className={classes.heading}>{isMobile && title}</Typography>
      </Box>
      <Box width="64px" display="flex" justifyContent="flex-end">
        <IconButton onClick={closeModal} className={classes.closeBtn}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default memo(TransferModalHeader)
