import React, { memo, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TicketLabel from './ticketLabel'
import InputBase from '@material-ui/core/InputBase'
import numberWithCommas from '../../../utils/numberWithCommas'
import { fieldBorderStyles } from './selectBagageItem'
import { useSelector } from 'react-redux'
import isIframed from '../../../utils/isIframed'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderColor: '#e6e6e6',
      borderRadius: '6px',
    },
    '& .MuiInputLabel-filled': {
      transform: 'translate(12px, 44px) scale(1)',
      color: theme.typography.gray,

      '&.MuiInputLabel-shrink': {
        transform: 'translate(12px, 5px) scale(1)',
      },
    },
  },
  menuItemFlex: {
    display: 'flex',

    '& .price-label': {
      fontWeight: '500',
    },
  },
  flex1: {
    flex: 1,
  },
  ffInfoBlock: {
    background: '#eef8f8',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: '20px 26px',
    width: '100%',
  },
  directionLabel: {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: '600',
    marginBottom: '10px',
  },
  benfitsList: {
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  ffLabel: {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStyle: 'italic',
    textTransform: 'capitalize',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const FareFamilyInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    'box-sizing': 'border-box',
    height: '55px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + theme.palette.border,
    fontSize: 16,
    color: theme.typography.gray,
    fontWeight: '500',
    padding: '18px 26px 18px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '& .price-label': {
      display: 'none',
    },
  },
  borderRadius: {
    marginTop: 0,
    borderRadius: '6px',
    '&:active': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      backgroundColor: 'white',
    },
    '&:before, & .MuiInputBase-root:before': { display: 'none' },
    '& fieldset': { ...fieldBorderStyles },
    '& .MuiInputBase-input.Mui-disabled': {
      padding: '18.5px 14px',
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      color: '#333',
      borderRadius: '6px',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderColor: '#e6e6e6',
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.Mui-disabled .MuiSelect-icon': {
      color: '#333',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
  },
}))(InputBase)

const MixedFareClasses = ({
  options,
  selected,
  returnFlKeys,
  outboundFlKeys,
  onClassSelected,
  flightsByNums,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [outboundKey, setOutboundKey] = React.useState('')
  const [returnKey, setReturnKey] = React.useState('')
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = currency === 'EUR' ? 'eur' : 'kr'

  useEffect(() => {
    if (!selected || typeof selected !== 'object') return
    const key = outboundFlKeys
      .map((fk) => selected?.byFlights?.[fk]?.['ffName'] || '')
      .join('::')
    if (!options[key]) return
    const opt = options[key]
    let retInd = null

    const retOpt = opt.returnFF.find((ff, ind) => {
      if (ff.uniqueId && ff.uniqueId === selected.uniqueId) {
        retInd = ind
        return true
      }
      return false
    })
    if (retInd !== null && retOpt) {
      setOutboundKey(key)
      setReturnKey(retInd)
    }
    //eslint-disable-next-line
  }, [selected, options])

  const getReturnOpts = () => {
    if (
      !options[outboundKey] ||
      options[outboundKey]['returnFF'].length <= 0 ||
      !returnFlKeys ||
      returnFlKeys.length <= 0
    ) {
      return []
    }
    return [...options[outboundKey]['returnFF']]
  }
  const returnOpts = getReturnOpts()

  const outboundChange = (event) => {
    setOutboundKey(event.target.value)
    setReturnKey('')
    onClassSelected('')
  }
  const returnChange = (event) => {
    setReturnKey(event.target.value)
    const flight = returnOpts[event.target.value]
      ? { ...returnOpts[event.target.value] }
      : ''
    onClassSelected(flight)
  }

  const buildOutboundOpts = () => {
    const components = []
    isIframed &&
      components.push(<option style={{ color: 'silver' }} value="" />)
    for (let key in options) {
      isIframed
        ? components.push(
            <option key={key} value={key}>
              {options[key]['label']}{' '}
              {`${t('fr.')} ${numberWithCommas(
                options[key]['minPrice']
              )}${currencyCode}`}
            </option>
          )
        : components.push(
            <MenuItem className={classes.menuItemFlex} key={key} value={key}>
              <span className={classes.flex1}>{options[key]['label']}</span>
              <span className={'price-label'}>
                {t('fr.') + ' ' + numberWithCommas(options[key]['minPrice'])}
                {currencyCode}
              </span>
            </MenuItem>
          )
    }
    return components
  }

  const buildReturnOpts = () => {
    const components = []
    isIframed &&
      components.push(<option style={{ color: 'silver' }} value="" />)
    returnOpts.map((ff, key) => {
      const ffLabels = []
      if (returnFlKeys) {
        returnFlKeys.forEach((fk) => {
          if (!ff.byFlights[fk]) return
          const tmpLabel = ff.byFlights[fk].label || ff.byFlights[fk].ffName
          if (!ffLabels.includes(tmpLabel)) ffLabels.push(tmpLabel)
        })
      }
      isIframed
        ? components.push(
            <option key={key} value={key}>
              {ffLabels.length > 0 ? ffLabels.join(', ') : 'Unknown'}{' '}
              {numberWithCommas(ff.price)}
              {currencyCode}
            </option>
          )
        : components.push(
            <MenuItem className={classes.menuItemFlex} key={key} value={key}>
              <span className={classes.flex1}>
                {ffLabels.length > 0 ? ffLabels.join(', ') : 'Unknown'}
              </span>
              <span className={'price-label'}>
                {numberWithCommas(ff.price)}
                {currencyCode}
              </span>
            </MenuItem>
          )
    })

    return components
  }
  const getFFByDir = (selectedFF, type = 'outbound') => {
    const result = []
    const keys = []
    const flNums = type === 'outbound' ? [...outboundFlKeys] : [...returnFlKeys]

    flNums.forEach((fNum) => {
      const ff = selectedFF.byFlights[fNum]
      if (!ff) return
      if (!keys.includes(ff.ffName)) {
        keys.push(ff.ffName)
        result.push({ ...ff })
      }
    })

    return result
  }

  const selectedFF =
    returnOpts[returnKey] && typeof returnOpts[returnKey] === 'object'
      ? returnOpts[returnKey]
      : null
  return (
    <Box>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel id="outgoing-select-label">{t('outbound')}</InputLabel>
        <Select
          native={isIframed}
          labelId="outgoing-select-label"
          id="outgoing-select"
          value={outboundKey}
          onChange={outboundChange}
          input={<FareFamilyInput />}
          className={classes.borderRadius}
          variant="outlined"
          displayEmpty
        >
          {buildOutboundOpts()}
        </Select>
      </FormControl>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel id="returning-select-label">{t('inbound')}</InputLabel>
        <Select
          native={isIframed}
          labelId="returning-select-label"
          id="returning-select"
          value={returnKey}
          onChange={returnChange}
          input={<FareFamilyInput />}
        >
          {buildReturnOpts()}
        </Select>
      </FormControl>
      <Box>
        {selectedFF !== null && (
          <>
            <Box key={'outbound-ff-info'} className={classes.ffInfoBlock}>
              <Typography className={classes.directionLabel} variant="h6">
                {t('outbound benefits')}
              </Typography>
              {getFFByDir(selectedFF).map((ff) => (
                <Box className={classes.benfitsList} key={ff.label}>
                  <Typography className={classes.ffLabel} variant="h6">
                    {(ff.label || ff.ffName).toLowerCase()}
                  </Typography>
                  <TicketLabel labels={ff} />
                </Box>
              ))}
            </Box>

            <Box key={'return-ff-info'} className={classes.ffInfoBlock}>
              <Typography className={classes.directionLabel} variant="h6">
                {t('return trip benefits')}
              </Typography>
              {getFFByDir(selectedFF, 'return').map((ff) => (
                <Box className={classes.benfitsList} key={ff.label}>
                  <Typography className={classes.ffLabel} variant="h6">
                    {(ff.label || ff.ffName).toLowerCase()}
                  </Typography>
                  <TicketLabel labels={ff} />
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default memo(MixedFareClasses)
