import React, { useCallback, useContext, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune'
import Flightfilter from '../filters/flightfilters'
import SortBy from '../../../components/reusable/sortBy'
import { useSelector } from 'react-redux'
import { CompaniesContext } from '../../../components/flights/filter/companiesContext'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    '& .MuiDrawer-paper	': {
      width: '100%',
    },
  },
  filterBtn: {
    padding: 0,
  },
}))

const MobileSortHeader = ({
  sort,
  sortTypes,
  anchorEl,
  handleClick,
  handleClose,
  isOutgoing
}) => {
  const filters = useSelector((state) => state.flights.filters)
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState(0)
  const [companies] = useContext(CompaniesContext)
  const searchOutgoing = useSelector((state) => state.flights.searchOutgoing)
  const searchReturning = useSelector((state) => state.flights.searchReturning)

  const maxValue = useCallback((arr) => {
    const max = _.maxBy(arr, 'travelTime')
    if (max) return max.travelTime
  }, [])

  const roundValue = useCallback((value) => {
    return Math.ceil(maxValue(value) / 60 * 2)/2
  }, [maxValue])

  useEffect(() => {
    let active = 0
    console.log(filters)
    if(!filters.stops[0] || !filters.stops[1] || !filters.stops[2]) {
      active++
    }
    if(
      isOutgoing
      && (
        filters.time.trip.from !== "00:00"
        || filters.time.trip.to !== "24:00"
      )
    ) {
      active++
    }

    if(
      !isOutgoing
      && (
        filters.time.returnTrip.from !== "00:00"
        || filters.time.returnTrip.to !== "24:00"
      )
    ) {
      active++
    }

    if(!companies.every(el => el.state)) {
      active++
    }

    if(isOutgoing && (filters.travelTime.trip !== 100000000
      && filters.travelTime.trip !== roundValue(searchOutgoing) * 60)
    ) {
      active++
    }

    if(!isOutgoing && (filters.travelTime.returnTrip !== 100000000
      && filters.travelTime.returnTrip !== roundValue(searchReturning) * 60)
    ) {
      active++
    }

    setActiveFilters(active)
  },[filters, companies, isOutgoing, roundValue, searchReturning, searchOutgoing])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box p={2}>
      <Grid container justify="space-between">
        <Grid item xs>
          <SortBy
            mobile
            sort={sort}
            sortTypes={sortTypes}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
          />
        </Grid>
        <Grid item>
          <Box height="100%" display="flex" alignItems="center">
            <Button
              className={classes.filterBtn}
              onClick={handleDrawerOpen}
              color="primary"
              startIcon={<TuneIcon color="primary" />}
            >
              {activeFilters > 0 && activeFilters}
            </Button>
          </Box>
          <Drawer
            elevation={0}
            className={classes.root}
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
          >
            <Box>
              <Flightfilter closeIfMobile={handleDrawerClose} isOutgoing={isOutgoing}/>
            </Box>
          </Drawer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MobileSortHeader
