import React, { memo, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button, Box } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter'
import { formatTrainDuration } from '../../../utils/formatDurations'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra'
import { useTranslation } from 'react-i18next'
import { TRAIN_COMPANIES } from '../../../constants/trainCompanies'
import { useSelector } from 'react-redux'
import { getChargeableSeatPrice, prepareTrainSeats } from '../../../utils/general'
import ChooseSeatSkeleton from '../../reusable/skeletons/choose-seatsSkeleton'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  weight: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  btn: {
    padding: 0,
    margin: 0,
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const AccordiumSummary = ({ segment, option, solution }) => {
  const classes = useStyles()
  const { railidentifier } = segment
  const {
    seatsMap,
    seats,
    selectedOutGoingTrip,
    selectedReturnTrip,
    seatsMapLoading,
    chargeableSeats
  } = useSelector((state) => state.train)
  const { travellers: searchTravellers } = useSelector(
    (state) => state.search.train
  )
  const seatsExists = seatsMap?.[railidentifier]?.coachList?.length || false
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const railStart = segment.railstart.locationName
  const railEnd = segment.railend.locationName
  const duration = formatTrainDuration(segment.Duration)
  const operator = segment.railserviceProvider.Code
  let itmKey = 1
  const preparedSeats = prepareTrainSeats(seats)
  const dataLoading = seatsMapLoading;
  const chargeablePrice = operator === 'ORS' ? getChargeableSeatPrice(chargeableSeats, railidentifier) : null
  const seatType = solution?.seatTypes?.[segment?.ID]
  const bestBerthTexts = {
    'SlCouchette': 'couchette',
    'SlShared': 'sleeper shared',
    'SlSingle': 'sleeper single',
  }

  const innerOpts = (!!option?.opts && typeof(option.opts) === 'object') ? option.opts : {};
  const notSeatSideOptions = ['chargeablePrice']
  let seatSideOptKeys = Object.keys(innerOpts).filter(k => !notSeatSideOptions.includes(k))

  const bestSeatText = bestBerthTexts?.[seatType] || 'best available'
  const selectAvailable = (seatSideOptKeys?.length > 0 || !!seatsExists) && !bestBerthTexts[seatType]

  const renderSeats = () => {
    const seatsToShow = []
    if (preparedSeats[railidentifier] && typeof (preparedSeats[railidentifier]) === 'object') {
      for (let coach in preparedSeats[railidentifier]) {
        if (!preparedSeats[railidentifier][coach]?.length) continue
        const seatNums = preparedSeats[railidentifier][coach].join(' & ')
        seatsToShow.push(
          <Box className={!isMobile ? classes.weight : ''} key={`itm-${itmKey++}`}>
            <span key={`itm-${itmKey++}`}>
              <span key={`itm-${itmKey++}`}>{t('vagn')} {coach}, </span>
              <span key={`itm-${itmKey++}`}>{t('seats')} {seatNums}</span>
              {
                (operator === 'ORS' && chargeablePrice) && (
                  <span key={`itm-${itmKey++}`}>{` (${chargeablePrice} kr${searchTravellers?.length > 1 ? '/pp' : '' })`}</span>
                )
              }
            </span>
          </Box>
        )
      }
    }

    return (
      <Fragment>
        {seatsToShow.length > 0 &&
          <Box>
            {seatsToShow}
          </Box>
        }
        {seatsToShow.length <= 0 &&
          (isMobile ? (
            <Typography>{t('best available')}</Typography>
          ) : (
              <Typography className={classes.weight} variant="body2">{t(bestSeatText)}</Typography>
          ))
        }
      </Fragment>
    )
  }

  return (
    <Grid container>
      {isMobile ? (
        <Grid container item spacing={1}>
          <Grid item xs={12}>
            <Typography>{TRAIN_COMPANIES[operator] || operator}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {capitalizeFirstLetter(railStart)} -{' '}
              {capitalizeFirstLetter(railEnd)}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Box className={classes.align}>
              {
                dataLoading ? (
                    <ChooseSeatSkeleton
                      selectedOutGoingTrip={selectedOutGoingTrip}
                      selectedReturnTrip={selectedReturnTrip}
                    />
                  ) : (
                    <>
                      <AirlineSeatReclineExtraIcon fontSize="small" />
                      {renderSeats()}
                    </>
                )
              }
            </Box>
          </Grid>{' '}
          <Grid item xs={5}>
            <Box display="flex">
              {
                dataLoading ? (
                  <ChooseSeatSkeleton
                    selectedOutGoingTrip={selectedOutGoingTrip}
                    selectedReturnTrip={selectedReturnTrip}
                  />
                ) : (
                  <Button
                    disabled={!selectAvailable}
                    style={{ marginLeft: 'auto' }}
                    align="right"
                    color="primary"
                    className={classes.btn}
                    endIcon={selectAvailable ? <ExpandMoreIcon color="primary" /> : ''}
                  >
                    {selectAvailable ? t('choose seat') : t('seats selection unavailable')}
                  </Button>
                )
              }
            </Box>
          </Grid>
        </Grid>
      ) : (
          <>
            <Grid container item xs={7}>
              <Grid item xs>
                {
                  dataLoading ? (
                      <ChooseSeatSkeleton
                        selectedOutGoingTrip={selectedOutGoingTrip}
                        selectedReturnTrip={selectedReturnTrip}
                      />
                    ) : (
                    renderSeats()
                  )
                }
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.weight} variant="body2">
                  {capitalizeFirstLetter(railStart)} -{' '}
                  {capitalizeFirstLetter(railEnd)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs>
              <Grid item xs>
                <Typography className={classes.weight} variant="body2">
                  {duration}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography className={classes.weight} variant="body2">
                  {TRAIN_COMPANIES[operator] || operator}
                </Typography>
              </Grid>
              <Grid item xs>
                {
                  dataLoading ? (
                    <ChooseSeatSkeleton
                      selectedOutGoingTrip={selectedOutGoingTrip}
                      selectedReturnTrip={selectedReturnTrip}
                    />
                  ) : (
                    <Button
                      disabled={!selectAvailable}
                      color="primary"
                      className={classes.btn}
                      endIcon={selectAvailable ? <ExpandMoreIcon color="primary" /> : ''}
                    >
                      {selectAvailable ? t('choose seat') : t('seats selection unavailable')}
                    </Button>
                  )
                }
              </Grid>
            </Grid>
          </>
        )}
    </Grid>
  )
}

export default memo(AccordiumSummary)
