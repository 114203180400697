import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cityText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dateText: {
    color: theme.palette.dimGray,
  },
}))

const SingleHotelType = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box>
      <Typography align="center" className={classes.cityText}>
        {t('select the room')}
      </Typography>
    </Box>
  )
}

export default memo(SingleHotelType)
