import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
import storage from 'local-storage-fallback'

// languages
import translationSv from '../locales/sv/translation.json'
import translationEn from '../locales/en/translation.json'
import translationFi from '../locales/fi/translation.json'
import translationAr from '../locales/ar/translation.json'
import translationDa from '../locales/da/translation.json'
import translationNo from '../locales/nb-NO/translation.json'

// Moment
import moment from 'moment'

if (storage.getItem('i18nextLng')) {
  i18n.languageWasSet = true
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    react: {
      useSuspense: false,
    },
    fallbackLng: 'sv',
    keySeparator: false,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      sv: {
        translations: translationSv,
      },
      en: {
        translations: translationEn,
      },
      fi: {
        translations: translationFi,
      },
      ar: {
        translations: translationAr,
      },
      da: {
        translations: translationDa,
      },
      nb: {
        translations: translationNo,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'i18nextLng',
    },
  })

// Set moment locale on language change
i18n.on('languageChanged', (lng) => {
  moment.locale(lng)
})

// Initial moment locale
if (storage.getItem('i18nextLng')) {
  setTimeout(
    () => {
      moment.locale(storage.getItem('i18nextLng'))
    },
    100,
    this
  )
}

export default i18n
