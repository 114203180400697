import { getPassengerCountsByType } from '../../../utils/general'

export const cleanFlightCataloguePayload = (flightData, fare) => {
  let flights = [...flightData]
  if (fare?.byFlights) {
    flights = flights.map((flight) => {
      const resFlight = { ...flight }
      if (fare.byFlights[flight.uniqueInd]) {
        resFlight.bookingclass = fare.byFlights[flight.uniqueInd].bookingClass
        resFlight.fareBasis = fare.byFlights[flight.uniqueInd].ffFareBasis
      }
      return resFlight
    })
  }
  const payload = {
    flights: flights,
    passengers: [
      {
        type: 'adult',
        ind: '1',
      },
    ],
    pricingOptions: [
      {
        optionKey: 'GRP',
        attrType: 'BG',
      },
      {
        optionKey: 'SCD',
      },
      {
        optionKey: 'SPD',
      },
      {
        optionKey: 'MIF',
      },
    ],
  }

  return payload
}

export const cleanFamiliesPricing = ({ flights, passengers }) => {
  let passengersCount = getPassengerCountsByType(passengers)
  const payload = {
    flights,
    passengersCount,
  }
  return payload
}
