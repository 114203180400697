import React, { useEffect } from 'react'
import { Box, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useNetsPaymentHook from '../../../hooks/netsPaymentHook'
import { useDispatch, useSelector } from 'react-redux'
import { setNetsCompleted } from '../../../store/checkout/checkoutActions'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.light,
  },
}))

const NetsPaymentBlock = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { netsPaymentId, netsCheckoutKey } = useSelector(
    (state) => state.checkout
  )
  const { isCompleted } = useNetsPaymentHook({
    paymentId: netsPaymentId,
    checkoutKey: netsCheckoutKey,
    elementId: 'nets-payment-iframe',
  })

  useEffect(() => {
    if (isCompleted) {
      //console.log('setting completed')
      dispatch(setNetsCompleted())
    }
  }, [isCompleted, dispatch])

  return (
    <Box>
      <Paper elevation={0} className={classes.paper}>
        <Box p={2} id="nets-payment-iframe" key={netsPaymentId}></Box>
      </Paper>
    </Box>
  )
}
export default NetsPaymentBlock
