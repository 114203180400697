import React, { memo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import RootWrapper from '../containers/route/rootWrapper'
import {useSelector} from "react-redux";

const PrivateRoute = ({ routeParams, isAuthenticated, ...rest }) => {
  const {user} = useSelector((state) => state.auth)
  let rolePass = true
  if (routeParams?.role === 'admin' && !user?.admin) rolePass = false

  return (
    <Route
      {...rest}
      key={routeParams.path+'-private-outer-wrapper'}
      render={(props) => {
        return isAuthenticated && rolePass ? (
          <RootWrapper
            key={routeParams.path+'-private-wrapper'}
            componentParams={{ ...props, ...(routeParams.compParams || {}) }}
            routeParams={{ ...routeParams }}
          />
        ) : (
          <Redirect to="/" />
        )
      }}
    />
  )
}

export default memo(PrivateRoute)
