import React, { memo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Box, Typography } from '@material-ui/core'
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp'
import PhoneIcon from '@material-ui/icons/Phone'
import MenuItems from '../../components/header/menuItems'
import GuestAuthBtns from '../../components/reusable/guestAuthBtns'
import { useDispatch, useSelector } from 'react-redux'

import { openLoginModal } from '../../store/modals/modalAction'
import LanguageChooser from '../../components/reusable/languageChooser'
import isIframed from '../../utils/isIframed'
import CompanyChooser from '../../components/reusable/companyChooser'
import { initiateOidcLogin } from '../../utils/oidc/oidcHandler'
import CurrencyChooser from '../../components/reusable/currencyChooser'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  align: { display: 'flex' },
  menu: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    paddingRight: 0,
  },

  phoneIcon: {
    marginRight: '12px',
  },

  supportPhone: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    fontWeight: 600,
    fontSize: theme.spacing(2),
    marginRight: '30px',
  },

  fontWeight: {
    fontWeight: 600,
  },
  link: {
    fontWeight: 600,
    textDecoration: 'none',
    textTransform: 'none',
    color: theme.palette.common.black,
    paddingRight: 0,
    fontSize: theme.spacing(2),
  },
  homeMadeDivider: {
    height: '26px',
    width: '1px',
    backgroundColor: theme.palette.common.black,

    '&:last-child': {
      display: 'none',
    },
  },

  navBar: {
    height: '100%',
    display: 'flex',
  },
  navLinks: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(3),
  },
}))
const NavLinkDesktop = ({ user, renderNavLinks }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    isAuthenticated,
    publicity,
    currencies,
    allowCurrencySelection,
    allowRegistration,
  } = useSelector((state) => state.auth)
  const { showSupportPhone, isIframe } = useSelector(
    (state) => state.customization
  )
  const classes = useStyles()
  const dispatch = useDispatch()
  const { onlyOidc } = useSelector((state) => state.other)
  const history = useHistory()

  const openLogin = () => {
    if (onlyOidc) {
      initiateOidcLogin('login')
    } else {
      dispatch(openLoginModal())
    }
  }

  const openRegistration = () => {
    history.push('/register')
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isWidget = window.parent !== window

  const showCompanySelection =
    user?.selectableCompanies?.length > 1 && !isIframe && !isWidget
  const showLanguageSelection = !isIframe && !isWidget
  const showCurrencySelection =
    !isIframe && !isWidget && currencies && allowCurrencySelection
  const showCompanyName =
    !isIframe && !isWidget && user && !showCompanySelection

  return (
    <>
      <Grid item xs>
        {renderNavLinks && (
          <Box className={classes.navBar}>
            <Grid
              container
              className={isIframed ? classes.iframeContainer : null}
            >
              {renderNavLinks}
            </Grid>
          </Box>
        )}
      </Grid>
      <Grid item>
        <Box className={classes.menu}>
          {showSupportPhone && (
            <Box className={classes.supportPhone}>
              <PhoneIcon className={classes.phoneIcon} />
              {showSupportPhone}
            </Box>
          )}

          {showLanguageSelection && (
            <>
              <LanguageChooser />
              <Box className={classes.homeMadeDivider} mr={1} />
            </>
          )}

          {showCurrencySelection && (
            <>
              <CurrencyChooser />
              <Box className={classes.homeMadeDivider} mr={1} />
            </>
          )}

          {showCompanySelection && (
            <>
              <CompanyChooser />
              <Box className={classes.homeMadeDivider} ml={1} mr={1} />
            </>
          )}

          {showCompanyName && (
            <>
              <Typography className={classes.fontWeight}>
                {user && user.companyName}
              </Typography>
              <Box className={classes.homeMadeDivider} ml={1} mr={1} />
            </>
          )}

          {!!user &&
            !isIframed &&
            publicity !== 'public' &&
            !user?.isBookingLink &&
            !isIframe && (
              <Button onClick={handleClick} className={classes.link}>
                {user && user.firstName} {user && user.lastName}
                <Box pl={2} height={35}>
                  <AccountCircleSharpIcon fontSize="large" color="primary" />
                </Box>
              </Button>
            )}
          {!!user?.isBookingLink && (
            <Button onClick={handleClick} className={classes.link}>
              {user?.companyName || ''}
            </Button>
          )}
          {!isAuthenticated &&
            !isIframe &&
            !isWidget &&
            publicity !== 'public' && (
              <GuestAuthBtns
                onLogin={openLogin}
                onRegister={allowRegistration && openRegistration}
              />
            )}
        </Box>

        <MenuItems
          className={classes.popper}
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      </Grid>
    </>
  )
}

export default memo(NavLinkDesktop)
