import React, { memo, useState } from 'react'
import AuthWrapper from '../../containers/authWrapper/authWrapper'
import {
  Box,
  Backdrop,
  CircularProgress,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AddMissingUserData from '../../containers/authentication/addMissingUserData'
import { fetchRegisterUser } from '../../repositories/auth'
import { useDispatch } from 'react-redux'
import { isLogginIn, relogin } from '../../store/auth/authAction'
import { openEditInfoModal } from '../../store/modals/modalAction'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  loadingBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  errorText: {
    marginTop: '20px',
    fontSize: '14px',
    color: theme.palette.error.main,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const Register = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()

  const completeLogin = async (loginData) => {
    await dispatch(isLogginIn(true))
    await dispatch(relogin(loginData))
    await dispatch(isLogginIn(false))
  }

  const openPasswordModal = async () => {
    await dispatch(
      openEditInfoModal({
        name: t('nav pass'),
        rows: [
          { title: t('new password'), key: 'password', order: 1 },
          { title: t('confirm password'), key: 'confirmpassword', order: 2 },
        ],
        section: 'password',
      })
    )
  }

  const handleSaveUserData = (data) => {
    setUserData(data)
    setIsLoading(true)

    fetchRegisterUser(data)
      .then((result) => completeLogin(result))
      .then(() => openPasswordModal())
      .catch((error) => {
        console.log(error)
        const errorCode = error.response?.data?.code
        const errorMessage = error.response?.data?.error

        if (errorCode) {
          setError(t(errorCode))
        } else {
          setError(errorMessage || error.toString())
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <AuthWrapper>
      <Box>
        <AddMissingUserData
          title={t('register user title')}
          description={t('register user description')}
          user={userData}
          editCompletedData={true}
          isLoading={isLoading}
          onSubmit={handleSaveUserData}
        />
      </Box>

      {error && <Box className={classes.errorText}>{error}</Box>}

      {isLoading && (
        <Backdrop className={classes.loadingBackdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </AuthWrapper>
  )
}

export default memo(Register)
