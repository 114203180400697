import React, { useEffect, memo, useMemo, useRef } from 'react'
import { Box, Hidden } from '@material-ui/core'
import AddPublicUsersInfo from '../../components/reusable/addPublicUsersInfo'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAddressDetails,
  setPublicContacts,
} from '../../store/checkout/checkoutActions'
import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import DesktopDetailsHeader from '../../containers/header/desktopDetailsHeader/desktopDetailsHeader'
import { setBreadcrumbType } from '../../store/breadcrumbs/breadcrumbsAction'
import { setTrainSearchParameters } from '../../store/search/searchAction'
import { useHistory } from 'react-router-dom'

const AddTrainPublicData = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const publicGuestRef = useRef(null)
  const trainParams = useSelector((state) => state.search.train)
  const { showAddressDetails } = useSelector((state) => state.auth)
  const { travellers: trainPassengers } = trainParams

  const publicGuests = useMemo(() => {
    return trainPassengers.filter((p) => p?.isPublic || false)
  }, [trainPassengers])

  useEffect(() => {
    dispatch(setBreadcrumbType('trains'))
  }, [dispatch])

  const updatePassengers = ({
    users: updatedUsers,
    email,
    phone,
    addressDetails,
  }) => {
    /*
    if (type === 'flight') {
      const { from, to, departure, arrival, travelerCounts, ticketClass } =
        flightParams
      dispatch(
        setFlightSearchParameters(
          from,
          to,
          departure,
          arrival,
          updatedUsers,
          travelerCounts,
          ticketClass
        )
      )
      dispatch(setFlightsPassengers(updatedUsers))
      dispatch(setPublicContacts({ email, phone }))
      history.push('/flights/details')
    }
    */

    const { from, to, departure, arrival, travelerCounts } = trainParams
    dispatch(
      setTrainSearchParameters(
        from,
        to,
        departure,
        arrival,
        updatedUsers,
        travelerCounts
      )
    )

    dispatch(setPublicContacts({ email, phone }))
    if (showAddressDetails) dispatch(setAddressDetails(addressDetails))
    history.push('/trains/choose-seats')
  }

  return (
    <Box>
      <Hidden smUp>
        <MobileHeader />
      </Hidden>
      <Hidden smDown>
        <DesktopDetailsHeader />
      </Hidden>

      {/* *** CONTENT  *** */}
      <Box p={1} pb={29} mt={0}>
        <AddPublicUsersInfo
          ref={publicGuestRef}
          users={publicGuests}
          type="trains"
          onSubmit={(updatedUsers) => updatePassengers(updatedUsers)}
        />
      </Box>
    </Box>
  )
}

export default memo(AddTrainPublicData)
