import {
  Box,
  Button,
  Divider,
  Dialog,
  Typography,
  Chip,
} from '@material-ui/core'
import React, {useEffect, useState, useRef, useCallback} from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import SvgIcon from '@material-ui/core/SvgIcon'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import moment from 'moment'
import SelectUserDOBModal from '../selectUserDOBModal'
import AddGuestUserModal from '../../../containers/modals/addGuestUserModal'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import isIframed from '../../../utils/isIframed'
import {debounce} from "lodash";
import {fetchAllUsers} from "../../../repositories/users";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  header: ({ isMobile }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isMobile ? theme.spacing(1.5) : theme.spacing(3),
    minHeight: theme.spacing(8),
  }),
  boldTitle: ({ isMobile }) => ({
    fontSize: isMobile ? '16px' : '32px',
    fontWeight: theme.typography.fontWeightBold,
  }),
  userOuter: {
    cursor: 'pointer',
    '&:last-child .user-block-divider': {
      display: 'none',
    },
  },
  headerBtn: ({ isMobile }) => ({
    fontWeight: isMobile ? '500' : '700',
    fontSize: isMobile ? '16px' : '14px',
    textTransform: isMobile ? 'capitalize' : 'uppercase',
    boxShadow: 'none',

    ...(isMobile
      ? {}
      : {
          height: '40px',
          borderRadius: '20px',
          padding: `6px ${theme.spacing(2)}px`,
          letterSpacing: '1.1px',
        }),
  }),
  userList: {
    overflowY: 'auto',
    minHeight: '400px',
  },
  userIcon: {
    color: '#cccccc',
    height: '34px',
    width: '34px',
  },
  userName: {
    fontSize: 16,
    color: theme.palette.common.black,
  },
  addGuestIcon: ({ isMobile }) => ({
    height: isMobile ? '20px' : '34px',
    width: isMobile ? '20px' : '34px',
    color: isMobile ? '#007979' : theme.palette.primary.main,
  }),
  addGuestText: ({ isMobile }) => ({
    fontSize: isMobile ? '14px' : '16px',
    color: isMobile ? '#007979' : theme.palette.common.black,
  }),
  userEmail: {
    fontSize: 12,
    color: '#007e7e',
  },
  startAdornment: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 0,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  ageChip: {
    borderRadius: '6px',
    padding: theme.spacing(0, 1),
    lineHeight: 'normal',
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: '4px',
    height: theme.spacing(3),

    '& .MuiChip-label': {
      padding: 0,
    },
  },
  searchBox: {
    backgroundColor: '#ffffff',
    paddingLeft: theme.spacing(1),

    '& .MuiAutocomplete-tag': {
      backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0 !important',
      borderRadius: 8,
      outline: 'none',

      '& input': {
        fontWeight: 'bold',
        '&::placeholder': {
          color: '#a1a2a3',
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
      },
    },
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: ({ isMobile }) => theme.spacing(1, 2, 1, isMobile ? 0 : 2),
  },
  guestBtn: {
    borderRadius: '4px',
    border: 'solid 1px #e6e6e6',
    fontSize: '14px',
    color: '#007979',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
}))

const AddUsersModal = ({
  value,
  entityType,
  modalBoolean,
  handleClose,
  handleFinish,
}) => {
  const { user: authUser } = useSelector((state) => state.auth)
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedUsers, setSelectedUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [filterText, setFilterText] = useState(null)
  const [userForDob, setUserForDob] = useState(null)
  const [isGuestModal, setIsGuestModal] = useState(false)
  const isGuestAvailable = authUser?.guestBooking || false

  const isAdultFunc = entityType === 'train' || entityType === 'car'
  const classes = useStyles({ isMobile })
  const textRef = useRef()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setSelectedUsers(!value || !value.length ? [] : [...value])
  }, [value])

  const searchUsers = useCallback(
    debounce(async name => {
      try {
        setLoading(true)
        const searchData = {name, limit: 30}
        const users = await fetchAllUsers(searchData, 'usersSearch')
        if (!users?.length) {
          setFilteredUsers([])
        } else {
          const selectedIds = !selectedUsers
              ? []
              : selectedUsers.map((su) => su.uniqueId)

          const cleanedFilterText = filterText
              ? filterText.normalize('NFD').replace(/\p{Diacritic}/gu, '')
              : null

          const res = users.filter((u) => {
            if (selectedIds.includes(u.uniqueId)) return false
            const filterName = u.firstName + ' ' + u.lastName
            const re = new RegExp(`${cleanedFilterText || ''}`, 'i')
            return !cleanedFilterText || cleanedFilterText === ''
                ? true
                : re.test(filterName)
          })
          setFilteredUsers(!res ? [] : res)
        }
        setLoading(false)
      } catch (e) {
        if (!axios.isCancel(e)) {
          setFilteredUsers([])
          setLoading(false)
        }
      }
    }, 400),
    [selectedUsers]
  );

  useEffect(() => {
    searchUsers(filterText || '')
  }, [filterText, searchUsers])

  const addUser = (newUser) => {
    if (!newUser) return
    setIsGuestModal(false)
    const dob = newUser?.birthDate || null
    const tmpUser = { ...newUser }

    if (dob) {
      const tmpDate = new moment(dob, 'YYYY-MM-DD')
      if (tmpDate.isValid()) tmpUser.age = moment().diff(tmpDate, 'years')
    }

    if (textRef?.current) textRef.current.focus()
    const selectedIds = !selectedUsers
      ? []
      : selectedUsers.map((su) => su.uniqueId)
    if (selectedIds.includes(tmpUser?.uniqueId || '')) return

    setSelectedUsers([...selectedUsers, tmpUser])
  }

  const onUserClick = (checkUser, e) => {
    e.stopPropagation()
    if (!isAdultFunc) return false
    if (checkUser && !checkUser.birthDate) {
      setUserForDob(checkUser)
    }
    return null
  }

  const userDobUpdated = (user) => {
    const updatedUsers = selectedUsers?.map((u) =>
      user.uniqueId === u.uniqueId ? { ...user } : { ...u }
    )
    setSelectedUsers(updatedUsers)
    setUserForDob(null)
  }

  const cancelSelection = () => {
    setSelectedUsers(!value || !value.length ? [] : [...value])
    handleClose()
  }

  let inputDelayId = null
  const inputChanged = (e, val) => {
    if (inputDelayId) clearTimeout(inputDelayId)
    inputDelayId = setTimeout(() => {
      setFilterText(val || null)
      setLoading(true)
      setFilteredUsers([])
    }, 100)
  }
  const paperProps = {
    style: { borderRadius: isMobile ? 0 : 16 },
  }

  const escapeHtml = (unsafe) => {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  }

  let regexTxt = ''
  if (filterText && filterText.trim() !== '') {
    regexTxt = filterText
      .replace(/\./g, '\\.')
      .replace(/\*/g, '\\*')
      .replace(/\(/g, '\\(')
      .replace(/\)/g, '\\)')
  }

  const ageToTag = (user) => {
    const { age } = user
    if (!age) return t('adult')
    if (age >= 65) return t('senior')
    if (age > 25) return t('adult')
    return t('youth')
  }

  return (
    <Box>
      <Dialog
        PaperProps={paperProps}
        fullScreen={isMobile}
        onClose={cancelSelection}
        fullWidth
        maxWidth={isMobile ? false : 'sm'}
        open={modalBoolean}
        hideBackdrop={isIframed ? true : false}
      >
        <Box component="header" className={classes.header}>
          {isMobile && (
            <Button
              onClick={cancelSelection}
              className={classes.headerBtn}
              color="primary"
            >
              {t('cancel btn')}
            </Button>
          )}
          <Typography className={classes.boldTitle}>
            {t('choose travelers')}
          </Typography>
          <Button
            className={classes.headerBtn}
            style={{ fontWeight: 700 }}
            variant={isMobile ? 'text' : 'contained'}
            disabled={selectedUsers.length === 0}
            onClick={() => handleFinish(selectedUsers)}
            color="primary"
          >
            {t('clear')}
          </Button>
        </Box>
        <Divider />
        <Box>
          <Autocomplete
            openOnFocus
            popupIcon={null}
            clearOnBlur={false}
            multiple
            disableClearable={true}
            options={[]}
            open={false}
            filterSelectedOptions
            noOptionsText={null}
            onChange={(e, val) => setSelectedUsers(val || [])}
            onInputChange={inputChanged}
            value={selectedUsers}
            getOptionLabel={(option) => {
              let aLabel = null
              if (isAdultFunc) aLabel = ageToTag(option)
              return (
                <span onClick={(e) => onUserClick(option, e)}>
                  {option.firstName
                    ? `${option.firstName} ${option.lastName}`
                    : ''}
                  {!!aLabel && (
                    <Chip
                      className={classes.ageChip}
                      label={aLabel}
                      variant={'outlined'}
                    />
                  )}
                </span>
              )
            }}
            renderInput={(params) => {
              return (
                <Box className={classes.searchBar}>
                  {!isMobile && (
                    <Typography
                      variant="body1"
                      classes={{ root: classes.startAdornment }}
                    >
                      {t('traveler')}
                    </Typography>
                  )}
                  <TextField
                    {...params}
                    classes={{ root: classes.searchBox }}
                    placeholder={
                      isMobile && selectedUsers.length <= 0
                        ? t('travelers')
                        : ''
                    }
                    autoFocus={true}
                    inputRef={textRef}
                    variant="outlined"
                    label=""
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                      spellCheck: 'false',
                      autoCorrect: 'off',
                    }}
                  />
                  {isMobile && isGuestAvailable && (
                    <Button
                      className={classes.guestBtn}
                      onClick={() => setIsGuestModal(true)}
                      variant="outlined"
                    >
                      + {t('guest')}
                    </Button>
                  )}
                </Box>
              )
            }}
          />
        </Box>
        <Divider />
        <Box className={classes.userList}>
          {isGuestAvailable && !isMobile && (
            <Box
              key={'user-list-guest'}
              onClick={() => setIsGuestModal(true)}
              className={classes.userOuter}
            >
              <Box display="flex" alignItems="center" pl={'20px'} py={2}>
                <AddCircleOutlineIcon
                  viewBox={'2 2 21 21'}
                  fontSize={'small'}
                  className={classes.addGuestIcon}
                />
                <Box ml={'20px'}>
                  <Typography className={classes.addGuestText}>
                    {t('add guest traveler')}
                  </Typography>
                </Box>
              </Box>
              <Divider className={'user-block-divider'} />
            </Box>
          )}
          {!!loading && (
            <Box key={'user-list-loading'} className={classes.userOuter}>
              <Box display="flex" alignItems="center" pl={'20px'} py={2}>
                <Typography className={classes.userName}>
                  {t('loading')}
                </Typography>
              </Box>
            </Box>
          )}
          {!loading && filteredUsers.map((fUser, key) => {
            if (!fUser) return null
            let text = `${fUser.firstName || ''} ${fUser.lastName || ''}`
            if (!text || text.trim() === '') return null

            if (regexTxt && regexTxt !== '') {
              text = escapeHtml(text)
              const regex = new RegExp(regexTxt.trim(), 'ig')
              text = text.replace(
                regex,
                (match) => `<span style="font-weight: bold;">${match}</span>`
              )
            }

            return (
              filterText && (
                <Box
                  key={'user-list-' + key}
                  onClick={() => addUser(fUser)}
                  className={classes.userOuter}
                >
                  <Box display="flex" alignItems="center" pl={'20px'} py={2}>
                    <SvgIcon viewBox="0 0 34 34" className={classes.userIcon}>
                      <path d="M17 .333C7.8.333.333 7.8.333 17 .333 26.2 7.8 33.667 17 33.667c9.2 0 16.667-7.467 16.667-16.667C33.667 7.8 26.2.333 17 .333zm0 5c2.767 0 5 2.234 5 5 0 2.767-2.233 5-5 5s-5-2.233-5-5c0-2.766 2.233-5 5-5zM17 29c-4.167 0-7.85-2.133-10-5.367.05-3.316 6.667-5.133 10-5.133 3.317 0 9.95 1.817 10 5.133C24.85 26.867 21.167 29 17 29z" />
                    </SvgIcon>
                    <Box ml={'20px'}>
                      <Typography className={classes.userName}>
                        <span dangerouslySetInnerHTML={{ __html: text }} />
                      </Typography>
                      <Typography className={classes.userEmail}>
                        {fUser.email}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider className={'user-block-divider'} />
                </Box>
              )
            )
          })}
        </Box>
      </Dialog>
      <SelectUserDOBModal user={userForDob} onSave={userDobUpdated} />
      <AddGuestUserModal
        isOpen={isGuestModal}
        onAdd={addUser}
        onCancel={() => setIsGuestModal(false)}
      />
    </Box>
  )
}

export default AddUsersModal
