import i18n from 'i18next'
import { GUARANTEE } from './booking/logic/hotel'
import moment from 'moment'
import * as Yup from 'yup'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'
import _ from 'lodash'

export const supportedLanguages = {
  sv: 'Svenska',
  en: 'English',
  fi: 'Suomalainen',
  da: 'Dansk',
  nb: 'Norsk',
  ar: 'عربي',
}

export const cardNames = {
  visa: 'VI',
  mastercard: 'CA',
  'american-express': 'AX',
  'diners-club': 'DN',
  discover: 'DS',
  jcb: 'JC',
}

/**
 * Validate a mobile number.
 *
 * @param {*} number
 * @returns boolean
 */
export const isValidMobileNumber = (number) =>
  number && isValidPhoneNumber(number) && !number.match(/^\+460/)

/**
 * detect card type by number
 *
 * @param number Credit card number
 * @returns {string|null}
 * @constructor
 */
export const GetCardType = (number) => {
  // visa
  var re = new RegExp('^4')
  if (number.match(re)) return 'VI'

  // Mastercard
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return 'CA'

  // AMEX
  re = new RegExp('^3[47]')
  if (number.match(re)) return 'AX'

  // Discover
  re = new RegExp(
    '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
  )
  if (number.match(re) != null) return 'DS'

  // Diners
  re = new RegExp('^36')
  if (number.match(re) != null) return 'DN'

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]')
  if (number.match(re)) return 'CB'

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])')
  if (number.match(re) != null) return 'JC'

  return null
}

export const getPassengerCountsByType = (passengers) => {
  const adult = passengers.filter((p) => p.type === 'adult' || !p.type).length
  const child = passengers.filter((p) => p.type === 'child').length
  const infant = passengers.filter((p) => p.type === 'infant').length
  const senior = passengers.filter((p) => p.type === 'senior').length
  return { adult, child, senior, infant }
}

/**
 * build basic user data (public booking) from users counts
 * @param count
 * @returns {*[]}
 */
export const buildUsersByCount = (count) => {
  const users = []

  const adults = count?.adult?.count || 0
  const children = count?.child?.count || 0
  const generalFields = {
    email: '',
    isPublic: true,
    isGuest: true,
    age: 30,
    firstName: '',
    lastName: '',
  }

  for (let i = 0; i < adults; i++) {
    const uniqueId =
      Date.now().toString(36) + Math.random().toString(36).substr(2)
    users.push({
      uniqueId,
      ...generalFields,
      type: 'adult',
    })
  }

  for (let c = 0; c < children; c++) {
    const uniqueId =
      Date.now().toString(36) + Math.random().toString(36).substr(2)
    users.push({
      ...generalFields,
      uniqueId,
      age: 11,
      type: 'child',
    })
  }

  return users
}

/**
 * get initial values of public users for Formik (to add users data)
 *
 * @param users
 */
export const getPublicUsersFormikInitials = (users = []) => {
  const tmpUsers = users || []
  const initUser = {
    firstName: null,
    lastName: null,
    middleName: null,
    email: null,
    isGuest: true,
    uniqueId: null,
    age: 30,
  }

  return {
    users: tmpUsers.map((u) => ({
      ...initUser,
      ...u,
      isUpdated: true,
      sex: 'male',
      nationality: 'SWE',
      birthDate: { y: '', m: '', d: '' },
    })),
    email: '',
    phone: '',
    addressDetails: {
      street: '',
      country: 'SE',
      city: '',
      postCode: '',
    },
  }
}

/**
 * get Formic validation for public users
 *
 * @returns {{phone, users, email}}
 */
export const getPublicUsersValidationSchema = (
  countryPhoneCode = 'SE',
  showBirthDate = false
) => {
  const phoneNumberFormat = getPhoneNumberFormat(countryPhoneCode)
  const birthDateValidation = showBirthDate
    ? {
        birthDate: Yup.object().shape({
          y: Yup.string().required(i18n.t('required field')),
          m: Yup.string().required(i18n.t('required field')),
          d: Yup.string().required(i18n.t('required field')),
        }),
      }
    : {}
  return {
    showAddressDetails: Yup.boolean(),
    users: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string()
          .nullable()
          .required(i18n.t('first name required')),
        lastName: Yup.string().required(i18n.t('last name required')),
        email: Yup.string()
          .email(i18n.t('invalid email'))
          .required(i18n.t('email required')),
        nationality: Yup.string().test(
          'valid-nationality',
          i18n.t('required field'),
          (val) => !showBirthDate || val
        ),
        ...birthDateValidation,
      })
    ),
    email: Yup.string()
      .email(i18n.t('invalid email'))
      .required(i18n.t('email required')),
    phone: Yup.string()
      .test(
        'valid-number',
        i18n.t('invalid phone number', { phoneNumberFormat }),
        (value) => validatePhoneNumber(value, countryPhoneCode)
      )
      .required(i18n.t('phone required')),
    addressDetails: Yup.object().shape({
      street: Yup.string().when('showAddressDetails', {
        is: true,
        then: (schema) => schema.required(i18n.t('required field')),
      }),
      country: Yup.string().when('showAddressDetails', {
        is: true,
        then: (schema) => schema.required(i18n.t('required field')),
      }),
      city: Yup.string().when('showAddressDetails', {
        is: true,
        then: (schema) => schema.required(i18n.t('required field')),
      }),
      postCode: Yup.string().when('showAddressDetails', {
        is: true,
        then: (schema) => schema.required(i18n.t('required field')),
      }),
    }),
  }
}

/**
 * Get hotel payment notifications (for checkout)
 *
 * @param checkout Checkout state
 * @param fops fop data
 * @param currencyCode
 * @param usedOnlySavedCC
 * @returns {null|{isGuarantee: boolean, isLeisure: boolean, paymentNote: string, type: string, paymentHeader: string}}
 */
export const getHotelsCheckoutNotify = (
  checkout,
  fops,
  currencyCode,
  usedOnlySavedCC = false
) => {
  const { items, fop: selectedFop, hotelGuarantee, costfields } = checkout
  const item = items.find((itm) => itm.type === 'Hotel')
  if (item?.type !== 'Hotel' || !item?.room) return null

  const { room } = item
  const {
    roomGuaranteeCode,
    isLeisure,
    cancellationType,
    freeCancellationDeadline,
    price,
  } = room
  const isGuarantee = roomGuaranteeCode === GUARANTEE
  const isFlightPayment = fops?.perFop?.['cc']?.includes('Flight')
  const flightPrefix = isFlightPayment
    ? i18n.t('flight-payment-note') + ' '
    : ''

  if (isGuarantee) {
    const payHotelKey = isLeisure ? 'leisureHotel' : 'gdsHotel'
    const hotelAgreement =
      costfields?.paymentMethods?.agreementMethods?.[payHotelKey]
    const isInvoice =
      selectedFop === 'invoice' ||
      (selectedFop === 'agreement' && hotelAgreement === 'invoice')
    const guaranteeKey =
      usedOnlySavedCC === 'iata'
        ? 'hotel-only-case-6-iata'
        : 'hotel-only-case-6'
    const notOnlyHotel =
      isFlightPayment ||
      selectedFop === 'cc' ||
      usedOnlySavedCC ||
      (isInvoice && !hotelGuarantee)
    const hotelKey = notOnlyHotel ? guaranteeKey : 'hotel-only-guarantee'
    return {
      type: 'paper',
      isGuarantee,
      isLeisure,
      paymentHeader: i18n.t('hotel guarantee card'),
      paymentNote: flightPrefix + i18n.t(hotelKey),
    }
  } else if (isLeisure) {
    return null
  } else {
    const deadline = freeCancellationDeadline
      ? new moment(freeCancellationDeadline, 'YYYY-MM-DD HH:mm').format(
          'YYYY-MM-DD \\a\\t HH:mm'
        )
      : ''
    const replaces = {
      amount: price || '',
      date: deadline || '',
      currencyCode,
    }
    return {
      type: 'hotelPayNotify',
      isGuarantee,
      isLeisure,
      paymentHeader: i18n.t('hotel'),
      paymentNote:
        flightPrefix +
        (cancellationType === 'free'
          ? i18n.t('hotel-only-case-4-free', replaces)
          : i18n.t('hotel-only-case-4-non-free', replaces)),
    }
  }
}

/**
 * Get hotel payment notifications info (for checkout)
 *
 * @param items Checkout cart items
 * @returns {null|{isGuarantee: boolean, isLeisure: boolean, paymentHeader: string}}
 */
export const getHotelsCheckoutInfo = (items) => {
  const fullItem = items.find((itm) => itm.type === 'Hotel')
  if (fullItem?.type !== 'Hotel') return null

  let isGuarantee = true
  let isLeisure = true
  try {
    const hotelItems = fullItem.trips ? fullItem.trips : [fullItem]
    hotelItems.forEach((item) => {
      const { room } = item || {}
      if (!room) throw Error('activation failed')
      const { roomGuaranteeCode, isLeisure: roomIsLeisure } = room
      if (roomGuaranteeCode !== GUARANTEE) isGuarantee = false
      if (!roomIsLeisure) isLeisure = false
    })
  } catch (e) {
    return null
  }

  const paymentHeader = isGuarantee
    ? i18n.t('hotel guarantee card')
    : i18n.t('hotel')
  return { isGuarantee, isLeisure, paymentHeader }
}

/**
 * sort by dir key
 * @param model
 * @returns {{}}
 */
export const prepareMyTripTransfers = (model) => {
  const transfers = model?.transfers
  if (!transfers?.length) return {}
  const results = {}
  transfers.forEach((t) => {
    if (!t.taxiType || !t.fromTime) return false
    if (!t.dirKey) t.dirKey = 'from_return' // Default for offline PNRs, show at bottom
    if (!results?.[t.dirKey]) results[t.dirKey] = []
    results[t.dirKey].push({ ...t })
  })

  return results
}

/**
 * prepare seats data to display
 * @param accommodations
 * @returns {{}}
 */
export const prepareTrainSeats = (accommodations) => {
  if (!accommodations || typeof accommodations !== 'object') return {}
  const res = {}

  for (let trainKey in accommodations) {
    if (
      !accommodations[trainKey] ||
      typeof accommodations[trainKey] !== 'object'
    )
      continue

    for (let uKey in accommodations[trainKey]) {
      const tmpAccom = accommodations[trainKey][uKey]

      if (!tmpAccom?.exactSeat?.seatNum || !tmpAccom?.exactSeat?.coach) continue
      const coachKey = tmpAccom.exactSeat.coach.toString()

      if (!res[trainKey]) {
        res[trainKey] = { [coachKey]: [] }
      } else if (!res[trainKey][coachKey]) {
        res[trainKey][coachKey] = []
      }
      res[trainKey][coachKey].push(tmpAccom.exactSeat.seatNum)
    }
  }

  return res
}

/**
 * check if trip destinations is same
 *
 * @param flight
 * @param flightCompare
 * @returns {{isTo: boolean, isFrom: boolean}}
 * @constructor
 */
export const flightIsSameDir = (flightCompare, flight) => {
  return {
    isFrom: flightCompare?.to?.shortName === flight?.from?.shortName,
    isTo: flightCompare?.from?.shortName === flight?.to?.shortName,
  }
}

/**
 * get passengers text (e.g. "2 Adults 1 youth")
 *
 * @param passengers
 * @returns {string}
 */
export const getPassengersText = (
  passengers = [],
  doNotSpecifyAges = false
) => {
  if (!passengers?.length) return ''
  let adults = 0
  let youth = 0
  let seniors = 0
  let children = 0

  if (doNotSpecifyAges) {
    adults = passengers.length
  } else {
    passengers.forEach((p) => {
      if (p.age >= 65) {
        seniors++
        return
      }
      if (p.type == 'adult' || !p.age || p.age > 25) {
        adults++
        return
      }
      if (p.type == 'child' || p.age <= 11) {
        children++
        return
      }
      youth++
    })
  }

  const results = []

  if (adults > 0)
    results.push(
      `${adults} ${i18n.t('adult', { count: adults }).toLowerCase()}`
    )
  if (youth > 0)
    results.push(`${youth} ${i18n.t('youth', { count: youth }).toLowerCase()}`)
  if (children > 0)
    results.push(
      `${children} ${i18n.t('child', { count: children }).toLowerCase()}`
    )
  if (seniors > 0)
    results.push(
      `${seniors} ${i18n.t('senior', { count: seniors }).toLowerCase()}`
    )

  return results.join(' & ')
}
/**
 *
 * @param number
 * @param countryPhoneCode
 * @returns {boolean}
 */
export const validatePhoneNumber = (number, countryPhoneCode) => {
  if (number) {
    return isValidPhoneNumber(number)
  }

  return false
}
/**
 *
 * @param countryPhoneCode
 * @returns {string}
 */
export const getPhoneNumberFormat = (countryPhoneCode = 'SE') => {
  return countryPhoneCode === 'FI' ? '+3584571234567' : '+46707123455'
}
/**
 *
 * @param currency
 * @returns {boolean|string}
 */
export const getCurrencyCodeBySign = (currency) => {
  return currency === 'EUR' ? ' eur' : ':-'
}
/**
 *
 * @param countries
 * @returns {*[]}
 */
export const getFilteredCountries = (countries) => {
  const filteredCountries = []

  countries?.map((country) =>
    filteredCountries.push({ name: country.name, value: country.alpha3Code })
  )

  return filteredCountries
}

/**
 * join array elements using callback to calculate glue
 *
 * @param callback
 * @param array
 * @returns string
 */
export const callbackJoin = (callback, array) => {
  if (!array?.length || typeof callback !== 'function') return ''
  let result = ''
  let prevInd = null
  array.forEach((v, i) => {
    if (prevInd === null) {
      result = v
      prevInd = i
      return false
    }
    let glue = callback(prevInd, i)
    if (!glue) glue = ' '
    result += `${glue}${v}`
    prevInd = i
  })

  return result
}
/**
 *
 * @param user
 * @param countries
 * @param t
 * @returns {[{extraSmall: boolean, notInRows: boolean, options: [{name, value: string}, {name, value: string}], title, fieldType: string, key: string, order: number}, {extraSmall: boolean, notInRows: boolean, disabled: boolean, title, key: string, order: number}, {extraSmall: boolean, notInRows: boolean, title, key: string, order: number}, {extraSmall: boolean, notInRows: boolean, disabled: boolean, title, key: string, order: number}, {notInModal: boolean, title, key: string}, null, null, null, null, null]}
 */
export const renderIdDocsRows = (user, countries, t) => {
  let rows
  if (user?.umbrellaEnabled) {
    rows = [
      {
        title: t('sex'),
        key: 'sex',
        fieldType: 'dropdownList',
        order: 1,
        notInRows: true,
        extraSmall: true,
        options: [
          { name: t('male'), value: 'm' },
          { name: t('female'), value: 'f' },
        ],
      },
      {
        title: t('profile name'),
        key: 'firstName',
        order: 2,
        small: true,
        notInRows: true,
        disabled: true,
      },
      {
        title: t('last name form'),
        key: 'lastName',
        order: 3,
        small: true,
        notInRows: true,
        disabled: true,
      },
      { title: t('full name'), key: 'fullName', notInModal: true },
      { title: t('profile date'), key: 'birthdate', fieldType: 'date' },
      { title: t('passport number'), key: 'passportNumber', order: 1 },
      {
        title: t('nationality'),
        key: 'nationality',
        order: 2,
        fieldType: 'dropdownList',
        options: countries,
      },
      {
        title: t('issue date'),
        key: 'issueDate',
        notInRows: true,
        order: 1,
        fieldType: 'date',
      },
      {
        title: t('expiration date'),
        key: 'expirationDate',
        order: 2,
        fieldType: 'date',
      },
      {
        title: t('issuing place'),
        key: 'issuePlace',
        notInRows: true,
        order: 1,
      },
      {
        title: t('page ashore'),
        key: 'issueCountry',
        order: 2,
        fieldType: 'dropdownList',
        options: countries,
      },
    ]
  } else {
    rows = [
      {
        title: t('sex'),
        key: 'sex',
        fieldType: 'dropdownList',
        order: 1,
        notInRows: true,
        extraSmall: true,
        options: [
          { name: t('male'), value: 'm' },
          { name: t('female'), value: 'f' },
        ],
      },
      {
        title: t('profile name'),
        key: 'firstName',
        order: 2,
        extraSmall: true,
        notInRows: true,
        disabled: true,
      },
      {
        title: t('middle name'),
        key: 'middleName',
        order: 3,
        extraSmall: true,
        notInRows: true,
      },
      {
        title: t('last name form'),
        key: 'lastName',
        order: 4,
        extraSmall: true,
        notInRows: true,
        disabled: true,
      },
      { title: t('full name'), key: 'fullName', notInModal: true },
      { title: t('profile date'), key: 'birthdate', fieldType: 'date' },
      { title: t('passport number'), key: 'passportNumber', order: 1 },
      {
        title: t('expiration date'),
        key: 'expirationDate',
        order: 2,
        fieldType: 'date',
      },
      {
        title: t('nationality'),
        key: 'nationality',
        order: 1,
        fieldType: 'dropdownList',
        options: countries,
      },
      {
        title: t('page ashore'),
        key: 'issueCountry',
        order: 2,
        fieldType: 'dropdownList',
        options: countries,
      },
    ]
  }

  return rows
}
/**
 *
 * @param date
 * @param depTime
 * @param trainSearch
 * @returns {string}
 */
export const getModifiedDepTime = (date, depTime, trainSearch) => {
  if (!date || date === '0') return date
  let depModified = date
  if (trainSearch === 'custom') {
    if (depTime) {
      const time = depTime.match(/.{1,2}/g)
      depModified = `${date} ${time[0]}:${time[1]}:00`
    } else {
      depModified = `${date} 10:00:00`
    }
  }

  return depModified
}
/**
 *
 * @param chargeableSeats
 * @param railIdentifier
 */
export const getChargeableSeatAmount = (chargeableSeats, railIdentifier) => {
  let amount = 0

  if (chargeableSeats[railIdentifier]) {
    Object.values(chargeableSeats[railIdentifier])?.forEach(
      (chaSeatAmount) => (amount += chaSeatAmount)
    )
  }

  return amount
}
/**
 *
 * @param chargeableSeats
 * @param railIdentifier
 * @returns {null}
 */
export const getChargeableSeatPrice = (chargeableSeats, railIdentifier) => {
  let price = null
  if (chargeableSeats[railIdentifier]) {
    for (let chaSeatAmount of Object.values(chargeableSeats[railIdentifier])) {
      if (chaSeatAmount > 0) {
        price = chaSeatAmount
        break
      }
    }
  }

  return price
}
/**
 *
 * @param items
 * @returns {boolean}
 */
export const getIsFlightRequireDocs = (items) => {
  let isFlightRequireDocs = false
  items.forEach((item) => {
    const { outgoing, returning } = item
    const outGoingCompany = outgoing?.company
    const returnCompany = returning?.company
    const fromCountry = outgoing?.from?.countryCode
    const toCountry = outgoing?.to?.countryCode
    const airlines = ['TK', 'QR', 'UA', 'EK', 'UL']
    const countries = ['US', 'MX', 'GB', 'TR', 'CA', 'AU']

    if (
      airlines.includes(outGoingCompany) ||
      airlines.includes(returnCompany) ||
      countries.includes(fromCountry) ||
      countries.includes(toCountry)
    ) {
      isFlightRequireDocs = true
    }
  })

  return isFlightRequireDocs
}

/**
 * Determines if the flight requires a valid name based on the countries of departure and arrival.
 *
 * @param {Array} items - The list of items containing flight details.
 * @return {boolean} Returns true if the flight requires a valid name, false otherwise.
 */
export const getIsFlightRequiresValidName = (items) => {
  let isOutsideEU = false
  items.forEach((item) => {
    const { outgoing } = item
    const fromCountry = outgoing?.from?.countryCode
    const toCountry = outgoing?.to?.countryCode
    const countries = [
      'AT',
      'BE',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'GR',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'ES',
      'SE',
    ]

    if (!countries.includes(fromCountry) || !countries.includes(toCountry)) {
      isOutsideEU = true
    }
  })

  return isOutsideEU ? true : false
}

export const getTripServiceTexts = (
  booking,
  tripTypesText = false,
  checkTypes = null
) => {
  let tripTypes = []
  const trainFlex = ['002', '003', '004', '005', '006', '007']
  const texts = {}

  if (!checkTypes?.length) {
    tripTypes.push(booking.type)
    if (!!booking.hotel || booking.type === 'hotel') tripTypes.push('hotel')
  } else {
    tripTypes = checkTypes
  }

  tripTypes.forEach((tripType) => {
    switch (tripType) {
      case 'rail':
        let oRailText = null
        let rRailText = null

        const oMainSeg = booking?.rails?.outbound?.mainSegment || null
        const osegm = booking?.rails?.outbound?.segments?.find(
          (s) => (s?.ID || '') === oMainSeg
        )
        if (!!trainFlex.includes(osegm?.flexibility || '')) {
          oRailText = i18n.t(`flexibility_${osegm.flexibility}`)
        }

        const rMainSeg = booking?.rails?.return?.mainSegment || null
        const rsegm = booking?.rails?.return?.segments?.find(
          (s) => (s?.ID || '') === rMainSeg
        )
        if (
          !!trainFlex.includes(rsegm?.flexibility || '') &&
          rsegm?.flexibility !== osegm?.flexibility
        ) {
          rRailText = i18n.t(`flexibility_${rsegm.flexibility}`)
        }

        texts.rail = { outbound: oRailText, return: rRailText }

        break

      case 'flight':
        if (!booking.fareServices) break
        const servs = booking.fareServices
        const checks = {
          outbound: { isREF: false, isRBK: false },
        }
        const rtTrip = booking?.flights?.find((t) => t.type === 'returnTrip')

        for (let dirKey in servs) {
          const dirSrv = servs[dirKey]
          if (!dirSrv || typeof dirSrv !== 'object') continue
          if (!checks[dirKey]) checks[dirKey] = { isREF: false, isRBK: false }

          for (let ffName in dirSrv) {
            const ffSrvs = dirSrv[ffName]
            if (!ffSrvs?.length) continue
            const rbkSrv = ffSrvs.find((s) => _.toLower(s.type || '') === 'rbk')
            const refSrv = ffSrvs.find((s) => _.toLower(s.type || '') === 'ref')

            if (!!rbkSrv) checks[dirKey].isRBK = true
            if (!!refSrv) checks[dirKey].isREF = true
          }
        }
        let oFlText = null
        let rFlText = null

        const oCh = checks.outbound
        oFlText = `${i18n.t(oCh.isRBK ? 'RBK' : 'NRBK')} & ${i18n.t(
          oCh.isREF ? 'REF' : 'NREF'
        )}`

        if (!!rtTrip && !!checks.return) {
          const rCh = checks.return
          const rTxt = `${i18n.t(rCh.isRBK ? 'RBK' : 'NRBK')} & ${i18n.t(
            rCh.isREF ? 'REF' : 'NREF'
          )}`
          if (oFlText !== rTxt) rFlText = rTxt
        }

        texts.flight = { outbound: oFlText, return: rFlText }
        break

      case 'hotel':
        const hotelData =
          booking.type === 'hotel' ? booking?.data : booking?.hotel?.data
        let hotelText = null
        if (hotelData?.cancellationType === 'free') {
          hotelText = i18n.t('hotel popular free')
          if (!!hotelData?.freeCancellation) {
            hotelText = `${hotelText} ${i18n.t('until').toLowerCase()} ${
              hotelData.freeCancellation
            }`
          }
        }

        if (hotelText) texts.hotel = hotelText
        break
    }
  })

  if (!tripTypesText) return texts

  const textRows = []
  for (let ttype in texts) {
    if (typeof texts[ttype] === 'string') {
      textRows.push(`${i18n.t(ttype)}: ${texts[ttype]}`)
      continue
    }
    if (!texts[ttype]?.outbound) continue

    if (!texts[ttype]?.return) {
      textRows.push(`${i18n.t(ttype)}: ${texts[ttype].outbound}`)
      continue
    }

    textRows.push(
      `${i18n.t(ttype)} ${i18n.t('outbound').toLowerCase()}: ${
        texts[ttype].outbound
      }`
    )
    textRows.push(
      `${i18n.t(ttype)} ${i18n.t('inbound').toLowerCase()}: ${
        texts[ttype].return
      }`
    )
  }
  return textRows
}

export const prepareCancellationProhibitedModal = (reason, model, user) => {
  const textKey = reason === 'rbk-nref' ? 'rbk-nref' : 'forbidden'
  const infoTexts = getTripServiceTexts(model, true)
  if (infoTexts.length)
    infoTexts.unshift({
      text: `${i18n.t('rules')}:`,
      styles: { fontWeight: 'bold' },
    })
  return {
    title: i18n.t('cancel prohibit title'),
    text: i18n.t(`cancel ${textKey} note`, {
      phone: user?.supportTelephone || '',
    }),
    buttonText: i18n.t('ok'),
    infoTexts,
  }
}

export const canCancelBooking = (user, booking, typesToCheck = null) => {
  let currTypes = []
  const bookTypes = []
  const noCancelSetting = user?.tripCancelForbidden
  const trainFlex = {
    '002': 'refundable',
    '003': 'rebookable',
    '004': 'non-rebookable',
    '005': 'refundable',
    '006': 'refundable',
    '007': 'refundable',
  }

  const reasons = [
    { result: 'std', realKeys: [], prior: 0 },
    { result: 'nrbk', realKeys: ['non-rebookable'], prior: 1 },
    { result: 'rbk', realKeys: ['refundable', 'rebookable'], prior: 2 },
    { result: 'rbk-nref', realKeys: ['rbk-nref'], prior: 3 },
  ]

  const getReason = (key) => {
    const tmpReason = reasons.find((r) => r.realKeys.includes(key))
    return tmpReason || reasons[0]
  }

  if (booking.parsed) {
    return { canCancel: false, reason: 'offline' }
  }

  try {
    if (!noCancelSetting) return { canCancel: true }
    if (!!noCancelSetting && typeof noCancelSetting === 'boolean')
      return { canCancel: false, reason: 'std' }

    bookTypes.push(booking.type)
    if (!!booking.hotel && !bookTypes.includes('hotel')) currTypes.push('hotel')

    currTypes = !typesToCheck ? [...bookTypes] : [...typesToCheck]

    let canCancel = true
    let reason = null

    for (let i in currTypes) {
      const tmpType = currTypes[i]
      if (
        !!noCancelSetting?.[tmpType] &&
        typeof noCancelSetting[tmpType] !== 'object'
      ) {
        canCancel = false
        if (!reason?.prior || reason.prior < reasons[0].prior)
          reason = reasons[0]
        continue
      }
      if (!noCancelSetting?.[tmpType]?.length || !bookTypes.includes(tmpType))
        continue
      const isBbkNref = noCancelSetting[tmpType].includes('rbk-nref')

      switch (tmpType) {
        case 'rail':
          const getSegmentReason = (segments) => {
            for (let segment of segments) {
              let oFlex = trainFlex?.[segment?.flexibility || ''] || null
              if (oFlex === 'rebookable' && isBbkNref) oFlex = 'rbk-nref'
              if (noCancelSetting[tmpType].includes(oFlex)) {
                return getReason(oFlex)
              }
            }
          }

          let oReason = getSegmentReason(booking?.rails?.outbound?.segments)

          // const oMainSeg = booking?.rails?.outbound?.mainSegment || null
          // const osegm = booking?.rails?.outbound?.segments?.find(
          //   (s) => (s?.ID || '') === oMainSeg
          // )
          // let oFlex = trainFlex?.[osegm?.flexibility || ''] || null
          // if (oFlex === 'rebookable' && isBbkNref) oFlex = 'rbk-nref'
          // let oReason = null
          // if (noCancelSetting[tmpType].includes(oFlex)) {
          //   oReason = getReason(oFlex)
          // }

          let rReason = booking?.rails?.return
            ? getSegmentReason(booking?.rails?.return?.segments)
            : null

          // const rMainSeg = booking?.rails?.return?.mainSegment || null
          // const rsegm = booking?.rails?.return?.segments?.find(
          //   (s) => (s?.ID || '') === rMainSeg
          // )
          // let rFlex = trainFlex?.[rsegm?.flexibility || ''] || null
          // if (rFlex === 'rebookable' && isBbkNref) rFlex = 'rbk-nref'
          // let rReason = null
          // if (noCancelSetting[tmpType].includes(rFlex)) {
          //   rReason = getReason(rFlex)
          // }

          // console.log(oReason)
          // console.log(rReason)

          const oPrior = oReason?.prior || -1
          const rPrior = rReason?.prior || -1
          let trReason = oPrior > rPrior ? oReason : rReason
          if (!!trReason) {
            canCancel = false
            if (!reason?.prior || reason.prior < trReason.prior)
              reason = trReason
            break
          }
          break

        case 'flight':
          if (!booking.fareServices) break
          const servs = booking.fareServices
          const checks = {
            outbound: { isREF: false, isRBK: false },
          }
          let isREF = false
          let isRBK = false
          const rtTrip = booking?.flights?.find((t) => t.type === 'returnTrip')

          for (let dirKey in servs) {
            const dirSrv = servs[dirKey]
            if (!dirSrv || typeof dirSrv !== 'object') continue
            if (!checks[dirKey]) checks[dirKey] = { isREF: false, isRBK: false }

            for (let ffName in dirSrv) {
              const ffSrvs = dirSrv[ffName]
              if (!ffSrvs?.length) continue
              const rbkSrv = ffSrvs.find(
                (s) => _.toLower(s.type || '') === 'rbk'
              )
              const refSrv = ffSrvs.find(
                (s) => _.toLower(s.type || '') === 'ref'
              )

              if (!!rbkSrv) checks[dirKey].isRBK = true
              if (!!refSrv) checks[dirKey].isREF = true
            }
          }

          let checkKey = false
          if (
            isBbkNref &&
            ((!!checks.outbound.isRBK && !checks.outbound.isREF) ||
              (!!rtTrip && !!checks?.return?.isRBK && !checks?.return?.isREF))
          ) {
            checkKey = 'rbk-nref'
          } else if (isRBK || (!!rtTrip && !!checks?.return?.isRBK)) {
            checkKey = 'rebookable'
          } else if (isREF || (!!rtTrip && !!checks?.return?.isREF)) {
            checkKey = 'refundable'
          } else {
            checkKey = 'non-rebookable'
          }

          if (noCancelSetting[tmpType].includes(checkKey)) {
            const flReason = getReason(checkKey)
            canCancel = false
            if (!reason?.prior || reason.prior < flReason.prior)
              reason = flReason
          }
          break
      }
    }
    if (!canCancel && !reason?.result) reason = reasons[0]
    return {
      canCancel,
      reason: !canCancel ? reason.result : null,
    }
  } catch (e) {
    return { canCancel: false, reason: 'std' }
  }
}
