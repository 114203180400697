import React, { createContext, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import { Typography, Box, Paper, useTheme } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

import FormGroup from '@material-ui/core/FormGroup'
import PopularFilters from './popularFilters'
import { useTranslation } from 'react-i18next'
import MaxPriceSlider from './maxPriceSlider'
import StarsFilter from './starsFilter'
import GuestRating from './guestRating'
import { useDispatch } from 'react-redux'
import { resetHotelFilters } from '../../../store/hotels/hotelsAction'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: theme.palette.dimWhite },
  weight: { fontWeight: 700 },
  drawer: {
    marginBottom: theme.spacing(10),
    '& .MuiDrawer-paper	': {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '400px',
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    height: '64px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 3),
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
    zIndex: 1000
  },
  boldTitle: {
    fontWeight: 700,
    fontSize: theme.spacing(2)
  },
  resetFilter: {
    fontSize: theme.spacing(1.75),
    textTransform: 'capitalize',
    lineHeight: '16px',
    fontWeight: 700
  },
  headerBtn: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.7px'
  }
}))

export const HotelFilterContext = createContext([{}, () => { }])

const HotelFilterDrawer = ({ drawer, toggleDrawer }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [reset, setReset] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const resetFilters = () => {
    dispatch(resetHotelFilters())
    setReset(!reset)
  }

  return (
    <HotelFilterContext.Provider value={reset}>
      <Drawer className={classes.drawer} style={{ position: 'relative' }} anchor={'left'} open={drawer['left']}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box flex={1}>
              <button
                style={{
                  background: 'transparent',
                  outline: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: '#007f7f',
                  fontWeight: 700,
                  textDecoration: 'none',
                  fontSize: '14px',
                  paddingLeft: 0,
                  fontFamily: 'Metropolis'
                }}
                onClick={resetFilters}
              >
                {t('reset filter')}
              </button>
            </Box>
            <Box flex={1} textAlign={'center'}>
              <Typography className={classes.boldTitle}>
                {t('filter')}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" flex={1}>
              <MuiButton
                variant="contained"
                disableElevation
                onClick={toggleDrawer('left', false)}
                className={classes.headerBtn}
                color="primary"
              >
                {t('done')}
              </MuiButton>
            </Box>
          </Box>
          <Box px={isMobile ? 1 : 3} pb={3} pt={0} className={classes.container}>

            <Paper className={classes.paper} elevation={0}>
              <Typography gutterBottom className={classes.weight}>
                {t('hotel results popular')}
              </Typography>
              <FormGroup>
                <PopularFilters />
              </FormGroup>
            </Paper>

            <Paper className={classes.paper} elevation={0}>
              <MaxPriceSlider />
            </Paper>

            <Paper className={classes.paper} elevation={0}>
              <StarsFilter />
            </Paper>

            <Paper className={classes.paper} elevation={0}>
              <GuestRating />
            </Paper>
          </Box>
        </Box>
      </Drawer>
    </HotelFilterContext.Provider>
  )
}

export default HotelFilterDrawer
