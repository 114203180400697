import React, { memo } from 'react'
import { Box, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import WifiIcon from '@material-ui/icons/Wifi'
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast'
import { ReactComponent as EcoLabel } from '../../assets/images/ecoLabelIcon.svg'
import { ReactComponent as HandshakeIcon } from '../../assets/images/HandshakeIcon.svg'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  alignRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: 0,
    padding: 0,
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },

  dim: ({ hotel }) => ({
    color: hotel ? theme.palette.common.black : theme.palette.success.main,
    fontSize: hotel ? theme.spacing(2) : theme.spacing(1.75),
  }),
  dimSmall: ({ hotel }) => ({
    color: hotel ? theme.palette.common.black : theme.palette.success.main,
    fontSize: hotel ? theme.spacing(2) : theme.spacing(1.75),
  }),
  dimMobile: ({ hotel }) => ({
    color: hotel ? theme.palette.common.black : theme.palette.success.main,
    fontSize: '13px',
  }),
  icon: {
    fontSize: '18px',
    margin: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.dimGray,
  },
}))

const icons = {
  900133: FreeBreakfastIcon,
  900126: WifiIcon,
  900134: EcoLabel,
  900135: HandshakeIcon,
}

const HotelAmenitiesCard = ({
  RoomAmenityCode,
  RoomAmenityLabel,
  small = false,
  rightAlign = false,
  hotel,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ hotel })

  const AmenityIcon = icons[RoomAmenityCode] || CheckIcon

  return (
    <Box
      className={rightAlign ? classes.alignRight : classes.align}
      m={0}
      mt={hotel && 1}
    >
      {isMobile || rightAlign ? (
        ''
      ) : (
        <AmenityIcon
          className={classes.icon}
          style={{
            color: hotel
              ? theme.palette.common.black
              : theme.palette.success.main,
          }}
        />
      )}
      <Typography
        style={{ fontWeight: hotel ? 400 : 500 }}
        className={
          isMobile ? classes.dimMobile : small ? classes.dimSmall : classes.dim
        }
      >
        {RoomAmenityLabel}
      </Typography>
      {!isMobile && rightAlign && (
        <AmenityIcon
          className={small ? classes.iconSmall : classes.icon}
          style={{
            color: hotel
              ? theme.palette.common.black
              : theme.palette.success.main,
          }}
        />
      )}
    </Box>
  )
}

export default memo(HotelAmenitiesCard)
