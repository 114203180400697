import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  alignItems: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '& > *': {
      marginLeft: theme.spacing(1),
      marginTop: '0',
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  weight: {
    color: theme.palette.dimGray,
    fontSize: '14px',
  },
  weightMobile: {
    color: theme.palette.dimGray,
    fontSize: '13px',
    textAlign: 'left',
  },
  blob: {
    borderRadius: theme.spacing(0.5),
    fontSize: '14px',
    padding: theme.spacing(0.5, 1),
  },
  rating: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '14px',
  },
  dimWeight: {
    color: theme.palette.dimGray,
  },
}))

export const optionUserRatingFilters = (t) => ({
  4.5: {
    short: `${t('guest rating1 title')}`,
    name: `${t('guest rating1')}`,
    slug: 'excellent',
    color: '#15c500',
  },
  4: {
    short: `${t('guest rating2 title')}`,
    name: `${t('guest rating2')}`,
    slug: 'veryGood',
    value: 4,
    color: '#15c500',
  },
  3.5: {
    short: `${t('guest rating3 title')}`,
    name: `${t('guest rating3')}`,
    slug: 'good',
    value: 3.5,
    color: '#71A340',
  },
  3: {
    short: `${t('guest rating4 title')}`,
    name: `${t('guest rating4')}`,
    slug: 'fair',
    value: 3,
    color: '#F48F00',
  },
  no: {
    short: '',
    name: `${t('guest rating5')}`,
    slug: 'no',
    value: 0,
    color: '#ff4700',
  },
})

const UsersRating = ({ rating, long, padding }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const classes = useStyles()

  const getRating = rating
    ? optionUserRatingFilters(t)[Math.floor(rating.rating * 2) / 2]
    : {}

  if (!getRating) return null

  return (
    <>
      {rating ? (
        <Box pl={0} pr={0} p={padding ? 2 : 0} className={classes.alignItems}>
          <Box
            className={classes.blob}
            style={{ backgroundColor: getRating.color }}
          >
            <Typography variant="body2" className={classes.rating}>
              {rating.rating}
            </Typography>
          </Box>

          {long ? (
            <Typography
              variant="body2"
              className={isMobile ? classes.weightMobile : classes.weight}
            >
              {getRating.short}
            </Typography>
          ) : (
            ''
          )}
          {!long ? (
            <Typography
              variant="body2"
              className={isMobile ? classes.weightMobile : classes.weight}
            >
              {getRating.short}{' '}
              {rating.numberOfRatings ? `(${rating.numberOfRatings})` : ''}{' '}
              {/* {t('rating')}) */}
            </Typography>
          ) : (
            ''
          )}

          {long && rating.numberOfRatings ? (
            <Box
              style={{ marginLeft: 'auto' }}
              display="flex"
              alignItems="center"
            >
              <Typography
                variant="body2"
                className={isMobile ? classes.weightMobile : classes.weight}
              >
                {rating.numberOfRatings} {t('rating')}
              </Typography>

              {/*
              <Box ml={0.5} mt={0.2}>
                <ArrowForwardIosIcon size="small" color="primary" />
              </Box>
              */}
            </Box>
          ) : (
            ''
          )}
        </Box>
      ) : (
        ''
      )}
    </>
  )
}

export default memo(UsersRating)
