import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  background: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '200px',
  },
}))

const UnobtrusiveLoader = ({ open }) => {
  const classes = useStyles()

  return (
    <Box className={classes.background}>
      <CircularProgress color="primary" />
    </Box>
  )
}

export default memo(UnobtrusiveLoader)
