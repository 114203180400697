import React, {memo} from 'react'
import _ from 'lodash'
import { Box, Typography, Button, IconButton, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  weight: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: '10px',
  },
  chooseBtn: {
    borderRadius: theme.spacing(4),
    fontWeight: 600,
    textTransform: 'none',
    fontSize: '14px',
    letterSpacing: '0.7px',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 3),
  },
  icon: {
    objectFit: 'contain',
    width: '80px'
  },
}))

const PreSearchTaxiCard = ({
  current,
  onClick
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const currentLocation = _.startCase(current?.meta?.locationName || '')
  const isCurrentFrom =  current?.meta?.from

  const from = isCurrentFrom ? currentLocation : t('any address');
  const to = !isCurrentFrom ? currentLocation : t('any address');

  const button = (
    <Grid item xs={6} key={'button-outer'}>
      <Button
        key={'book-taxi-button'}
        disableElevation
        onClick={onClick}
        fullWidth
        color="primary"
        variant="contained"
        className={classes.chooseBtn}
      >
        {t('select taxi')}
      </Button>
    </Grid>
  )

  const logo = (
    <img
      src={'/assets/companies/flygtaxi.png'}
      className={classes.icon}
      alt={'taxi'}
    />
  )

  return (
    <Box width={'100%'} p={2}>
      <Box
        display="flex"
        alignItems="center"
        pb={2}
      >
        <Box display="flex" flex="1">
          {logo}
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography display="inline" gutterBottom className={classes.weight}>
            {t('search from')}
          </Typography>
          <Typography display="inline" gutterBottom>
            {from}
          </Typography>
        </Box>
        <Box>
          <Typography display="inline" gutterBottom className={classes.weight}>
            {t('search to')}
          </Typography>
          <Typography display="inline" gutterBottom>
            {to}
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <Grid container spacing={1}>
          {button}
        </Grid>
      </Box>
    </Box>
  )
}

export default memo(PreSearchTaxiCard)
