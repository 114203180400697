import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import ReusableDatpicker from '../../../components/reusable/datePicker/reusableDatePicker_new'
import {useTranslation} from 'react-i18next'
import {makeStyles} from '@material-ui/core/styles'
import {Box} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  dateBox: {
    padding: theme.spacing('9px','18px'),
    margin: '24px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    border: '1px solid #e6e6e6',
  }
}))

const SelectTime = ({ goBack, onChange, from, to, isOutgoing }) => {
  const dayRef = useRef()
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    if (dayRef.current) {
      document.getElementsByClassName('react-datepicker')[0].scrollTo({ behavior: 'smooth', top: dayRef.current.parentNode?.parentNode?.parentNode.offsetTop - 150 })
    }
  })

  const onSelect = (day) => {
    onChange(moment(day))
    goBack()
  }

  return (
    <Box className={classes.dateBox}>
      <ReusableDatpicker
        value={isOutgoing ? from : to}
        hideCancel
        popperTop={110}
        onChange={onSelect}
        placeHolder={t('search time')}
        disableLabel={true}
        isOneWay={false}
        HOTEL={false}
        from={from}
        to={to}
        timeOnly
      />
    </Box>
  )
}

export default SelectTime
