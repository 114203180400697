import {
  Box,
  Button,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '600px',
    height: 'min-content',
    display: 'flex',
    flex: 1,
    flexGrow: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  btn: {
    borderRadius: '10px',
    boxShadow: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
    textTransform: 'uppercase',
    letterSpacing: '1.1px',
    borderRadius: '22px',
    padding: '8px 16px 8px 16px',
  },
}))
function SsoError({ message }) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()

  return (
    <Box className={classes.container}>
      <Box mb={2}>
        <Typography variant="h4" color="error">
          {t('sso error')}
        </Typography>
        <Typography variant="body1">
          {t('sso error description')}
          <br />
          <i>{message}</i>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          color="primary"
          variant="contained"
          className={classes.btn}
          onClick={() => window.history.back()}
        >
          {t('go back')}
        </Button>
      </Box>
    </Box>
  )
}

export default SsoError
