import React, { useEffect } from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { useProfileStyles } from './profileStyles'
import NoData from '../../components/profile/noData'
import { useDispatch, useSelector } from 'react-redux'
import InformationBlock from '../../containers/profile/reusable/informationBlock'
import { getFilteredCountries, renderIdDocsRows } from '../../utils/general'
import { getPassengersData } from '../../store/checkout/checkoutActions'

const IdDocuments = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useProfileStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const { user } = useSelector((state) => state.auth)
  const { countriesList } = useSelector((state) => state.other)
  const countries = getFilteredCountries(countriesList)
  const rows = renderIdDocsRows(user, countries, t)

  useEffect(() => {
    dispatch(getPassengersData([user?.uniqueId]))
  }, [])

  return (
    <Box pl={isDesktop ? 2 : 7} pr={2} pb={6} pt={isDesktop ? 0 : 2}>
      {!isDesktop && user.docId && user.docId.length > 0 && (
        <Typography variant="h3" className={classes.weight} gutterBottom>
          {t('nav id')}
        </Typography>
      )}
      {user.docId && user.docId.length > 0 ? (
        user.docId.map((doc, index) => (
          <InformationBlock
            key={doc}
            data={doc}
            rows={rows}
            name={user.docId.length > 1 ? `${t('passport details')} ${index + 1}`: t('passport details')}
            editable={true}
            section="passport"
            index={index}
          />
        ))
      ) : (
          <Box display="flex" style={{ width: '100%', justifyContent: 'center' }}>
            <NoData
              title={t('no id documents')}
              message={t('no saved id documents')}
            />
          </Box>
        )}
    </Box>
  )
}

export default IdDocuments
