import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React, { useContext, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import Rating from '@material-ui/lab/Rating/Rating'
import useCheckBoxFilter from '../../../hooks/useCheckBoxFilterHook'
import { setHotelStarsFilters } from '../../../store/hotels/hotelsAction'
import { HotelFilterContext } from './hotelFilterDrawer'

export const starsCount = [5, 4, 3, 2, 1]

const StarsFilter = () => {
  const reset = useContext(HotelFilterContext)
  const stars = useSelector((state) => state.hotels.filters.stars)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { filters, onChange } = useCheckBoxFilter(stars, reset)

  useEffect(() => {
    dispatch(setHotelStarsFilters(filters))
  }, [filters, dispatch])

  return (
    <>
      <Typography gutterBottom>
        {t('hotel results rating title')}
      </Typography>
      <FormGroup>
        {
          starsCount.map((star) => (
            <FormControlLabel
              key={star}
              control={
                <Checkbox
                  defaultValue={false}
                  checked={filters[star.toString()]}
                  onChange={onChange}
                  name={star.toString()}
                  color="primary"
                />
              }
              label={
                <Rating size="small" name="read-only" value={star} readOnly />
              }
            />
          ))
        }
      </FormGroup>
    </>
  )
}

export default StarsFilter
