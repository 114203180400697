import constants from './constants'

import storage from 'local-storage-fallback'

const outgoing = JSON.parse(storage.getItem('outgoingSelectedTrain'))
const returning = JSON.parse(storage.getItem('returningSelectedTrain'))

const initialState = {
  results: [],
  resultError: null,
  correlationID: outgoing?.train.correlationID,
  isLoading: false,
  outGoingCity: [],
  inGoingCity: [],
  selectedOutGoingTrip: outgoing || null,
  selectedReturnTrip: returning || null,
  accommodations: null,
  isAccommodationsLoading: false,
  seatsMapLoading: false,
  specificAccommodationSelected: [],
  seatsMap: {},
  seats: {},
  middleStepSettings: null,
  latestTime: null,
  firstTime: null,
  isEarlierTrips: false,
  isLaterTrips: false,
  showNext: true,
  showPrevious: true,
  chargeableSeats: {},
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.FETCH_TRAIN_RESULTS:
      return {
        ...state,
        ...payload,
        seatsMap: {},
      }
    case constants.FETCH_TRAIN_ERROR:
      return {
        ...state,
        resultError: payload,
      }

    case constants.FETCH_OUTGOING_CITIES:
      return {
        ...state,
        outGoingCity: payload,
      }
    case constants.FETCH_INCOMING_CITIES:
      return {
        ...state,
        inGoingCity: payload,
      }
    case constants.SET_SELECTED_OUTGOING_TRAIN_TRIP:
      return {
        ...state,
        selectedOutGoingTrip: payload,
        correlationID: payload?.train.correlationID,
        seatsMap: {},
      }

    case constants.SET_SELECTED_RETURN_TRIP:
      return {
        ...state,
        selectedReturnTrip: payload,
        seatsMap: {},
      }

    case constants.SET_TRAIN_ACCOMMODATIONS_OUTGOING:
      return {
        ...state,
        accommodations: { ...state.accommodations, outgoing: payload },
      }
    case constants.SET_TRAIN_ACCOMMODATIONS_RETURNING:
      return {
        ...state,
        accommodations: { ...state.accommodations, returning: payload },
      }
    case constants.REMOVE_TRAIN_ACCOMMODATIONS:
      return {
        ...state,
        accommodations: {},
      }
    case constants.IS_TRAIN_ACCOMMODATIONS_LOADING:
      return {
        ...state,
        isAccommodationsLoading: payload,
      }
    case constants.IS_TRAIN_LOADING:
      return {
        ...state,
        isLoading: payload,
      }

    case constants.REMOVE_TRAIN_SEARCH_RESULTS:
      return {
        ...state,
        results: [],
        seatsMap: {},
        latestTime: null,
        firstTime: null,
        seats: {},
        showNext: true,
        showPrevious: true,
        returnChaSeatAmount: {},
        chargeableSeats: {},
      }
    case constants.REMOVE_TRAIN_STATE:
      return {
        state,
      }
    case constants.TRAIN_SEATS_MAP_LOADING:
      return {
        ...state,
        seatsMapLoading: payload,
      }
    case constants.SET_TRAIN_SEATS: {
      const currentAccom = { ...state.seats }
      const currentChargeableSeats = { ...state.chargeableSeats }
      const { railindentifier, userId, accom, chargeableSeatAmount } = payload
      if (!railindentifier || !userId || (!accom && accom !== false)) {
        return state
      }

      if (!currentChargeableSeats[railindentifier]) {
        currentChargeableSeats[railindentifier] = {
          [userId]: chargeableSeatAmount ?? 0,
        }
      } else {
        currentChargeableSeats[railindentifier][userId] =
          chargeableSeatAmount ?? 0
      }

      if (accom === false) {
        if (currentAccom?.[railindentifier]?.[userId]) {
          currentAccom[railindentifier][userId] = null
        }

        return {
          ...state,
          seats: { ...currentAccom },
          chargeableSeats: { ...currentChargeableSeats },
        }
      }

      if (!currentAccom[railindentifier]) {
        currentAccom[railindentifier] = { [userId]: accom }
      } else {
        currentAccom[railindentifier][userId] = accom
      }

      return {
        ...state,
        seats: { ...currentAccom },
        chargeableSeats: { ...currentChargeableSeats },
      }
    }
    case constants.SET_TRAIN_SEATS_MAP: {
      const { railInd, seats } = payload
      if (!railInd) return state
      let currentSeatMap = { ...state.seatsMap }

      if (!seats && !!currentSeatMap[railInd]) {
        delete currentSeatMap[railInd]
      } else if (!!seats) {
        currentSeatMap[railInd] = seats
      }
      return {
        ...state,
        seatsMap: { ...currentSeatMap },
      }
    }
    case constants.SPECIFIC_ACCOMMODATION_SELECT: {
      const specificSelected = [...state.specificAccommodationSelected]
      const { railindentifier } = payload
      if (railindentifier && !specificSelected.includes(railindentifier)) {
        specificSelected.push(railindentifier)
      }

      return {
        ...state,
        specificAccommodationSelected: [...specificSelected],
      }
    }

    case constants.SPECIFIC_ACCOMMODATION_UNSELECT: {
      let specificSelected = [...state.specificAccommodationSelected]
      const { railindentifier } = payload

      if (railindentifier && specificSelected.includes(railindentifier)) {
        specificSelected = specificSelected.filter(
          (ind) => ind !== railindentifier
        )
      }

      return {
        ...state,
        specificAccommodationSelected: [...specificSelected],
      }
    }

    case constants.EMPTY_TRAIN_SEATS:
      return {
        ...state,
        seats: {},
      }

    case constants.SET_TRAIN_MIDDLESET_SETTINGS:
      return {
        ...state,
        middleStepSettings: payload,
      }
    case constants.IS_EARLIER_TRIPS:
      return {
        ...state,
        isEarlierTrips: payload,
      }
    case constants.IS_LATER_TRIPS:
      return {
        ...state,
        isLaterTrips: payload,
      }

    default:
      return state
  }
}
