import React, { memo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, AppBar, CardMedia, Divider } from '@material-ui/core'
import TabPanel from '@material-ui/lab/TabPanel'
import TabContext from '@material-ui/lab/TabContext'
import AuthWrapper from '../../containers/authWrapper/authWrapper'
import AccountLogin from '../../containers/authentication/accountLogin'
import BankIdTab from '../../containers/authentication/bankId'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from '../../assets/images/Logo-BLK-Horizontal.svg'
import { useHistory } from 'react-router-dom'
import { getBrandedUrl } from '../../utils/branding'

const useStyles = makeStyles((theme) => ({
  indicator: { borderBottom: `5px solid ${theme.palette.primary.main}` },
  weight: { fontWeight: theme.typography.fontWeightBold },
  logoImg: {
    height: '40px',
    width: '120px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'inline-block',
  },
  logoDivider: {
    color: theme.palette.primary.main,
    width: '1px',
    height: '40px',
    marginLeft: '20px',
    marginRight: '20px',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  companyLogo: {
    height: '40px',
    width: '120px',
    display: 'inline-block',
  },
  emailSentText: {
    fontSize: '16px',
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    marginBottom: '26px',
    lineHeight: '1.5',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  emailSentTextInner: {
    display: 'inline-block',
    maxWidth: '305px',
  },
  headerText: {
    fontSize: '43px',
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '1.5px',
    lineHeight: '1.3',
    textAlign: 'center',
    marginBottom: '15px',
    '& > span': {
      maxWidth: '200px',
    },
  },
  poweredBy: {
    lineHeight: '1.8',
    fontSize: '10px',
    fontWeight: theme.typography.fontWeightSemiBold,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: '1.1px',
    marginTop: '16px',

    '& > svg': {
      height: '14px',
      width: 'auto',
      marginLeft: '4px',
    },
  },
}))

const LoginBlock = ({ isModal, onBack }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState({ index: '0' })
  const [companyLogo, setCompanyLogo] = useState(null)
  const history = useHistory()

  const classes = useStyles()
  const activationSent = (userData) => {
    setValue({ index: '2', data: userData, prevIndex: '0' })
  }
  const emailNotFound = (userData) => {
    setValue({ index: '3', data: userData, prevIndex: '0' })
  }

  const onBackClicked = () => {
    if (typeof value.prevIndex !== 'undefined') {
      setValue({ index: value.prevIndex })
    } else if (onBack) {
      onBack()
    } else {
      return history.goBack()
    }
  }

  const logoUrl = getBrandedUrl('logo_retina.png')

  const loginStatusChanged = (newStatus) => {
    if (newStatus.companyLogo) {
      setCompanyLogo(newStatus.companyLogo)
    }
  }

  const getHeader = () => {
    switch (value.index) {
      case '2':
        const txt = t('check your email').split('\n')
        return (
          <Box className={classes.headerText}>
            {txt.map((text, ind) => {
              const res = [<span>{text}</span>]
              if (ind > 0) res.unshift(<br />)
              return res
            })}
          </Box>
        )
      default:
        return (
          <Box py={'20px'} textAlign={'center'}>
            <CardMedia image={logoUrl} className={classes.logoImg} />

            {companyLogo && (
              <>
                <Divider className={classes.logoDivider} />

                <CardMedia
                  data-id="company-logo"
                  image={companyLogo}
                  className={classes.companyLogo}
                  display="inline"
                />
              </>
            )}
          </Box>
        )
    }
  }

  return (
    <AuthWrapper isModal={isModal} onBackClicked={onBackClicked}>
      {getHeader()}
      <TabContext value={value.index}>
        <AppBar position="static" color="transparent" elevation={0}>
          {/*  This will be used in a later stage --- mobilt bankid    */}
          {/* <Tabs
            value={value}
            onChange={handleChange}
            centered
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab value={'0'} label="Konto" {...a11yProps(0)} />
            <Tab value={'1'} label="BankId" {...a11yProps(1)} />
          </Tabs> */}
        </AppBar>
        <TabPanel value="0" index={'0'}>
          <AccountLogin
            activationSent={activationSent}
            emailNotFound={emailNotFound}
            onStatusChanged={loginStatusChanged}
          />
        </TabPanel>
        <TabPanel value="1" index={'1'}>
          <BankIdTab />
        </TabPanel>
        <TabPanel value="2" index={'2'}>
          {value.index === '2' && (
            <Box>
              <Box className={classes.emailSentText}>
                <span
                  className={classes.emailSentTextInner}
                  dangerouslySetInnerHTML={{
                    __html: t('sent email part 1', {
                      email: `<span style="font-weight: 700">${value.data.email}</span>`,
                    }),
                  }}
                />
              </Box>
              <Box className={classes.emailSentText}>
                <span className={classes.emailSentTextInner}>
                  {t('sent email part 2')}
                </span>
              </Box>
              <Box className={classes.emailSentText}>
                <span className={classes.emailSentTextInner}>
                  {t('sent email part 3')}
                </span>
              </Box>
            </Box>
          )}
        </TabPanel>
        <TabPanel value="3" index={'3'}>
          {value.index === '3' && (
            <Box>
              <Box className={classes.emailSentText}>
                <span
                  className={classes.emailSentTextInner}
                  dangerouslySetInnerHTML={{
                    __html: t('email status error 1', {
                      email: `<span style="font-weight: 700">${value.data.email}</span>`,
                    }),
                  }}
                />
              </Box>
              <Box className={classes.emailSentText}>
                <span
                  className={classes.emailSentTextInner}
                  dangerouslySetInnerHTML={{
                    __html: t('email status error 2', {
                      phone: `<span style="white-space: nowrap">${value.data.supportTelephone}</span>`,
                    }),
                  }}
                />
              </Box>
            </Box>
          )}
        </TabPanel>
      </TabContext>
      <Box className={classes.poweredBy}>
        <span>{t('powered by')}</span>
        <Logo />
      </Box>
    </AuthWrapper>
  )
}

export default memo(LoginBlock)
