import CONSTANTS from './constants'
import { fetchFlights, fetchFlightAirport } from '../../repositories/flights'

import parseFlightsData from './helpers/parseFlightData'

import storage from 'local-storage-fallback'
import { getPassengerCountsByType } from '../../utils/general'

// Outbound flight
export const fetchOutgoingFlightAction =
  (params, lang) => async (dispatch, getState) => {
    storage.removeItem('transfer')
    storage.removeItem('transfer_phone')
    dispatch({ type: CONSTANTS.SET_SEARCH_ERROR, payload: null })
    dispatch({ type: CONSTANTS.CLEAR_FLIGHT_SEARCH })
    const { filters, searchOutgoing } = getState().flights
    if (searchOutgoing.length) return

    const passengerCounts = getPassengerCountsByType(params.passengers)

    const { isOneWay } = params

    const bothWayPayload = {
      to: params.to,
      from: params.from,
      date: params.dep,
      returndate: params.arr,
      family_information: 2,
      passengers: passengerCounts,
      group: 'trip',
      multiTicket: 'both',
      lang,
      cabin_class: params.ticketClass,
    }

    const oneWayPayload = {
      date: params.dep,
      family_information: 2,
      from: params.from,
      to: params.to,
      passengers: passengerCounts,
      lang,
      group: 'trip',
      cabin_class: params.ticketClass,
    }

    // Checks if oneway or returntrip
    const actualPayload =
      parseInt(isOneWay) === 0 ? oneWayPayload : bothWayPayload

    dispatch(isLoading(true))
    try {
      const data = await fetchFlights(actualPayload)
      const parsedData = parseFlightsData(data.data, filters, 'outbound')

      const filterOutgoin = parsedData.filter(
        (flight) => flight.group === 'trip'
      )

      // Filters Trip - Shows only outgoing trips
      const onlyOutgoing = {
        ...data,
        data: filterOutgoin,
      }

      dispatch(setFlightMargins(data.margins))
      dispatch(setFlightAgencyFilters(data?.agencySearchFilter || null))
      dispatch(setFlighOutgoingSearchResults(onlyOutgoing))
    } catch (e) {
      dispatch({ type: CONSTANTS.SET_SEARCH_ERROR, payload: e })

      console.log(e)
    } finally {
      dispatch(isLoading(false))
    }
  }

// Returning Flight
export const fetchReturningFlightAction =
  (
    { arr, dep, from, to, passengers, ticketClass, outgoingSearchToken },
    outgoing,
    lang
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: CONSTANTS.CLEAR_FLIGHT_SEARCH })
    dispatch({ type: CONSTANTS.SET_SEARCH_ERROR, payload: null })

    const { searchReturning, filters } = getState().flights
    if (searchReturning.length) return

    if (!outgoing) return
    dispatch({ type: CONSTANTS.SET_SEARCH_ERROR, payload: null })

    const passengerCounts = getPassengerCountsByType(passengers)

    const payload = {
      anchorItinerary: outgoing.original,
      date: dep,
      family_information: 2,
      from: from,
      multiTicket: 'inbound',
      passengers: passengerCounts,
      returndate: arr,
      to: to,
      lang,
      group: 'returnTrip',
      cabin_class: ticketClass,
      searchToken: outgoingSearchToken,
    }
    dispatch(isLoading(true))
    try {
      const data = await fetchFlights(payload)
      const parsedData = parseFlightsData(data.data, filters, 'inbound')
      // Filter OutOutgoing flights
      const filterReturningFlights = parsedData.filter(
        (flight) => flight.group === 'returnTrip'
      )
      const clean = {
        ...data,
        data: filterReturningFlights,
      }

      dispatch(setFlightMargins(data.margins))
      dispatch(setFlightReturningSearchResults(clean))
    } catch (e) {
      dispatch({ type: CONSTANTS.SET_SEARCH_ERROR, payload: e })

      console.log(e)
    }

    dispatch(isLoading(false))
  }

// Multicity Flight
export const fetchMultiFlightAction =
  (
    { dep, from, to, passengers, ticketClass, outgoingSearchToken, tripNumber },
    lang
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: CONSTANTS.CLEAR_FLIGHT_SEARCH })
    dispatch({ type: CONSTANTS.SET_SEARCH_ERROR, payload: null })

    const { filters, selectedMulti } = getState().flights

    let multiStop = []
    const formatDate = (d) =>
      `${d.substring(0, 4)}-${d.substring(4, 6)}-${d.substring(6, 8)}`
    for (let i = 0; i < from.split('-').length; i++) {
      multiStop.push({
        from: from.split('-')[i],
        to: to.split('-')[i],
        date: formatDate(dep.split('-')[i]),
        anchorItinerary: selectedMulti[i]?.original?.trip,
      })
    }

    const payload = {
      multiStop,
      family_information: 2,
      multiTicket: false,
      passengers: { adult: parseInt(passengers), child: 0, infant: 0 },
      lang,
      group: 'multi',
      cabin_class: ticketClass,
      searchToken: outgoingSearchToken,
      tripNumber: parseInt(tripNumber),
    }
    dispatch(isLoading(true))
    try {
      const data = await fetchFlights(payload)
      const parsedData = parseFlightsData(data.data, filters, 'outbound')

      // Filter flights to selected stop
      const filteredFlights = parsedData // parsedData.filter((flight) => flight.multiStop === tripNumber)
      const clean = {
        ...data,
        data: filteredFlights,
      }

      dispatch(setFlightMargins(data.margins))
      dispatch(setFlightMultiSearchResults(clean))
    } catch (e) {
      dispatch({ type: CONSTANTS.SET_SEARCH_ERROR, payload: e })

      console.log(e)
    }

    dispatch(isLoading(false))
  }

export const setFlightMargins = (margins) => async (dispatch) => {
  dispatch({ type: CONSTANTS.SET_FLIGHT_MARGINS, payload: margins })
}

export const setFlightAgencyFilters = (filters) => async (dispatch) => {
  dispatch({ type: CONSTANTS.SET_FLIGHT_AGENCY_FILTERS, payload: filters })
}

export const setOutgoingFlight = (flight) => async (dispatch) => {
  dispatch({ type: CONSTANTS.SET_FLIGHT_OUTGOING, payload: flight })
}

export const setReturningFlight = (flight) => (dispatch) => {
  const stringify = JSON.stringify(flight)
  storage.setItem('flightReturning', stringify)
  dispatch({ type: CONSTANTS.SET_FLIGHT_RETURNING, payload: flight })
}

export const setMultiFlight = (flight, tripNumber) => async (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_FLIGHT_MULTI,
    payload: {
      flight,
      tripNumber,
    },
  })
}

export const setFlightSearchType = (type) => async (dispatch) => {
  dispatch({ type: CONSTANTS.SET_FLIGHT_SEARCH_TYPE, payload: type })
}

// Get Airports Action

export const setAirportsDirectlyAction = (airports) => async (dispatch) => {
  return dispatch({ type: CONSTANTS.SET_FLIGHT_AIRPORTS, payload: airports })
}
export const setAirportsAction = (text, lang) => async (dispatch) => {
  let data
  try {
    data = await fetchFlightAirport(text, lang)
  } catch {
    data = []
  }
  return dispatch({ type: CONSTANTS.SET_FLIGHT_AIRPORTS, payload: data })
}

//

const setFlighOutgoingSearchResults = (results) => ({
  type: CONSTANTS.FETCH_OUTGOING_SEARCH,
  payload: results,
})

const setFlightReturningSearchResults = (result) => ({
  type: CONSTANTS.FETCH_RETURNING_SEARCH,
  payload: result,
})

const setFlightMultiSearchResults = (result) => ({
  type: CONSTANTS.FETCH_MULTI_SEARCH,
  payload: result,
})

export const setFlightsPassengers = (passengers) => ({
  type: CONSTANTS.SET_FLIGHT_PASSENGERS,
  payload: passengers,
})

export const clearFlightSearch = () => ({ type: CONSTANTS.CLEAR_FLIGHT_SEARCH })

export const clearSelectedOutbound = () => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_FLIGHT_OUTGOING,
    payload: null,
  })
}

export const clearSelectReturning = () => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_FLIGHT_RETURNING,
    payload: null,
  })
}

export const clearSelectedMulti = () => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_FLIGHT_MULTI,
    payload: null,
  })
}

const isLoading = (loading) => ({
  type: CONSTANTS.IS_SEARCH_LOADING,
  payload: loading,
})

export const resetState = () => (dispatch) => {
  dispatch({ type: CONSTANTS.RESET_STATE })
}

export const setCurrentFlightPrice = (price) => (dispatch) => {
  storage.setItem('totalFlightPrice', price)
  dispatch({ type: CONSTANTS.SET_CURRENT_FLIGHT_PRICE, payload: price })
}
