import React, { useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getPassengersText } from '../../../utils/general'
import numberWithCommas from '../../../utils/numberWithCommas'
import moment from 'moment'
import _ from 'lodash'
import usePriceFormat from '../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  bold: ({ isSideBar }) => ({
    fontWeight: theme.typography.fontWeightBold,
    fontSize: isSideBar ? '21px' : '24px',
  }),
  lineHeightNormal: {
    lineHeight: 'normal',
  },
  totalPriceBlock: {
    paddingTop: '10px',
  },
  between: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  priceUnderLine: ({ isSideBar }) => ({
    paddingTop: isSideBar ? '4px' : '7px',
  }),
  priceLine: ({ isSideBar }) => ({
    paddingBottom: isSideBar ? '6px' : '16px',
    marginBottom: isSideBar ? '6px' : '16px',
    borderBottom: isSideBar ? 'none' : `1px solid ${theme.palette.border}`,

    '&:last-child': {
      paddingBottom: isSideBar ? '20px' : '16px',
      borderBottom: `1px solid ${theme.palette.border}`,
    },
  }),
}))
const Total = ({ isSideBar = false, addFeeText = false }) => {
  const classes = useStyles({ isSideBar })
  const { t } = useTranslation()
  const checkoutStore = useSelector((state) => state.checkout)
  const { currency, serviceFee, hideCreditcardFees, selectedCurrency } =
    useSelector((state) => state.auth)
  const { formattedCurrency, formatPrice, exchangeRate } = usePriceFormat()

  const {
    prices,
    hasReturnTrip,
    passengers,
    hotelRooms,
    carDays,
    transfersCount,
    carExtras,
    addedAddons,
  } = useMemo(() => {
    const { items, ccFees } = checkoutStore

    const prices = {
      hotel: 0,
      hotelLocals: {},
      train: 0,
      car: 0,
      flight: 0,
      transfer: 0,
      total: 0,
      ccFee: 0,
    }
    const passengers = {
      trip: [],
      hotel: [],
      car: [],
    }
    let hotelRooms = 0
    let transfersCount = 0
    let hasReturnTrip = false
    let carDays = 0
    let carExtras = []
    let addedAddons = []

    items.forEach((itm) => {
      let ccFee = 0
      if (itm.uniqueInd && ccFees[itm.uniqueInd] && ccFees[itm.uniqueInd] > 0) {
        ccFee = ccFees[itm.uniqueInd]
        prices.ccFee += ccFees[itm.uniqueInd]
      }

      if (itm?.type === 'Car' && itm?.extras?.length > 0) {
        carExtras = [...itm.extras]
      }

      if (itm?.type === 'Car' && itm?.addedAddOns?.length > 0)
        addedAddons = [...itm.addedAddOns]

      if (
        (itm.type === 'Train' && itm.returnTrip) ||
        (itm.type === 'Flight' && itm.returning)
      ) {
        hasReturnTrip = true
      }
      let pasengerKey = null

      if (
        (itm.type === 'Train' || itm.type === 'Flight') &&
        itm.passengers.length > 0
      ) {
        pasengerKey = 'trip'
      } else if (itm.type === 'Hotel') {
        if (itm.passengers.length > 0) pasengerKey = 'hotel'
        if (!itm.trips) {
          hotelRooms += parseInt(itm.roomsCount)
        } else {
          itm.trips.forEach((t) => (hotelRooms += parseInt(t.roomsCount)))
        }
        if (itm.localCurrency && itm.localCurrency.toUpperCase() !== 'SEK') {
          const currKey = itm?.localCurrency?.toUpperCase()
          const currTotal = prices.hotelLocals[currKey] || 0
          prices.hotelLocals[currKey] =
            currTotal + parseFloat(itm.localTotalPrice)
        }
      } else if (itm.type === 'Car') {
        pasengerKey = 'car'
        const sDate = new moment(itm?.tarif?.startDate, 'YYYY-MM-DD HH:mm')
        const eDate = new moment(itm?.tarif?.endDate, 'YYYY-MM-DD HH:mm')

        if (sDate.isValid() && eDate.isValid()) {
          const msInDay = 1000 * 60 * 60 * 24 //milliseconds in day
          const diff = eDate.diff(sDate)
          carDays = Math.ceil(diff / msInDay)
        }
      } else if (itm.type === 'Transfer' && itm.details) {
        itm.details.forEach((tr) => {
          if (!tr.transfer?.length) return

          tr.transfer.forEach((t) => {
            if (
              t?.type === 'flightBus' ||
              (t?.type === 'tagTaxi' && t?.product?.code === 'RAILE')
            ) {
              transfersCount += t?.passenger?.length || 1
            } else {
              transfersCount++
            }
          })
        })
      }

      if (pasengerKey) {
        itm.passengers.forEach((p) => {
          let exists = passengers[pasengerKey].some(
            (tripPassenger) => tripPassenger.uniqueId === p.uniqueId
          )
          if (!exists) passengers[pasengerKey].push({ ...p })
        })
      }

      const key = itm.type.toLowerCase()

      if (serviceFee) {
        prices.serviceFee = serviceFee
        prices.total += serviceFee
      }

      if (typeof prices[key] !== 'undefined' && itm.totalPrice) {
        prices[key] += itm.totalPrice
        prices['total'] += itm.totalPrice + (ccFee || 0)
      }
    })

    return {
      prices,
      hasReturnTrip,
      passengers,
      hotelRooms,
      carDays,
      transfersCount,
      carExtras,
      addedAddons,
    }
  }, [checkoutStore, serviceFee])
  const tripLine = hasReturnTrip
    ? t('detail mob return')
    : t('detail mob outbound')
  let key = 1

  const buildLine = (text, price, type = null) => {
    key++
    return (
      <Box key={'price-line-' + key} className={`${classes.priceLine}`}>
        <Box className={classes.between}>
          <Typography>{text}</Typography>

          <Typography className={classes.lineHeightNormal} variant="h5">
            <span style={{ fontSize: '14px' }}>{formattedCurrency}</span>{' '}
            <span style={{ fontSize: '16px' }}>{formatPrice(price)}</span>
          </Typography>
        </Box>
        {type === 'car' &&
          !!carExtras?.length &&
          carExtras.map((e) => (
            <Box
              key={'price-under-line-' + e?.nameCode}
              className={`${classes.priceUnderLine} ${classes.between}`}
            >
              <Typography style={{ fontSize: '12px' }}>
                {_.capitalize((e?.name || '').toLowerCase())} x{e?.count || 1}
              </Typography>

              <Typography className={classes.lineHeightNormal}>
                <span style={{ fontSize: '12px' }}>
                  {formatPrice(e.price * (e?.count || 1), {
                    prependCurrency: true,
                  })}
                </span>
              </Typography>
            </Box>
          ))}
        {type === 'car' &&
          !!addedAddons.length &&
          addedAddons.map((addOn) => (
            <Box
              key={'price-under-line-' + addOn?.name}
              className={`${classes.priceUnderLine} ${classes.between}`}
            >
              <Typography style={{ fontSize: '12px' }}>
                {_.capitalize((addOn?.name || '').toLowerCase())}
              </Typography>

              <Typography className={classes.lineHeightNormal}>
                <span style={{ fontSize: '12px' }}>
                  {formatPrice(addOn.price, { prependCurrency: true })}
                </span>
              </Typography>
            </Box>
          ))}
        {type === 'hotel' &&
          (() => {
            const results = []
            for (let currency in prices.hotelLocals) {
              results.push(
                <Box
                  key={'price-under-line-' + currency}
                  className={`${classes.priceUnderLine} ${classes.between}`}
                >
                  <Typography style={{ fontSize: '12px' }}>
                    ( {t('charged local')}
                  </Typography>

                  <Typography className={classes.lineHeightNormal}>
                    <span style={{ fontSize: '12px' }}>{currency}</span>{' '}
                    <span style={{ fontSize: '12px' }}>
                      {numberWithCommas(prices.hotelLocals[currency])} )
                    </span>
                  </Typography>
                </Box>
              )
            }
            return results
          })()}
      </Box>
    )
  }

  return (
    <Box p={2}>
      {!isSideBar && (
        <Box mb={2}>
          <Typography variant="h5" className={classes.bold}>
            {t('detail total')}
          </Typography>
        </Box>
      )}
      <Box>
        {prices.train + prices.flight > 0 &&
          buildLine(
            tripLine + ` (${getPassengersText(passengers.trip, false)})`,
            prices.train + prices.flight,
            'train'
          )}
        {prices.car > 0 &&
          buildLine(
            `${t('rental car')} (${carDays} ${t('day', {
              count: carDays,
            }).toLowerCase()})`,
            prices.car,
            'car'
          )}
        {prices.hotel > 0 &&
          buildLine(
            `${t('hotel')} ${hotelRooms} ${t('rooms')} (${
              passengers.hotel.length
            } ${t('guest', { count: passengers.hotel.length }).toLowerCase()})`,
            prices.hotel,
            'hotel'
          )}
        {!hideCreditcardFees &&
          prices.ccFee > 0 &&
          buildLine(t('payment cc cost'), prices.ccFee)}
        {prices.transfer > 0 &&
          buildLine(
            `${transfersCount} x ${t('transfers')}`,
            prices.transfer,
            'transfer'
          )}
        {prices.serviceFee > 0 &&
          buildLine(t('service fee'), prices.serviceFee)}
      </Box>
      <Box
        key={'total-price-line'}
        className={`${classes.between} ${classes.totalPriceBlock}`}
      >
        <Box display={'inline-block'} lineHeight={'21px'}>
          <Box
            display={'inline-block'}
            style={{ lineHeight: '17px' }}
            width={'100%'}
          >
            <Typography style={{ lineHeight: '17px' }}>
              {isSideBar ? t('detail total') : t('payment total')}
            </Typography>
          </Box>
          {addFeeText && (
            <Box display={'inline-block'}>
              <Typography style={{ fontSize: '12px' }}>
                ( {t('total price fee warning')} )
              </Typography>
            </Box>
          )}
        </Box>
        <Typography className={classes.lineHeightNormal} variant="h5">
          <span style={{ fontSize: '16px' }}>{formattedCurrency}</span>{' '}
          <span className={classes.bold}>{formatPrice(prices.total)}</span>
        </Typography>
      </Box>
      {selectedCurrency && exchangeRate && (
        <Typography className={classes.lineHeightNormal} variant="h5">
          <span style={{ fontSize: '12px' }}>
            {t('based on exchange rate', {
              currency,
              selectedCurrency,
              exchangeRate,
            })}
          </span>
        </Typography>
      )}
    </Box>
  )
}

export default Total
