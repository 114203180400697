import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import { useTranslation } from 'react-i18next'
import { setHotelMaxPriceFilters } from '../../../store/hotels/hotelsAction'
import { useSliderStyles } from '../../flights/filters/sliderStyles'
import { HotelFilterContext } from './hotelFilterDrawer'
import { getCurrencyCodeBySign } from '../../../utils/general'
import usePriceFormat from '../../../hooks/usePriceFormat'

// const useStyles = makeStyles((theme) => ({
//   valueLabel: {
//     left: 'calc(-50% + 4px)',
//     '& *': {
//       background: 'transparent',
//       color: theme.palette.text.primary,
//     },
//     '&::after': {
//       content: '""',
//       position: 'absolute',
//       height: 10,
//       width: 10,
//       borderRadius: '50%',
//       background: theme.palette.text.primary,
//       top: -10,
//       left: 'calc(50% - 5px)',
//     },
//   },
// }))

// .MuiSlider-valueLabel

const MaxPriceSlider = () => {
  const [maxPrice, setMaxPrice] = useState(0)
  const reset = useContext(HotelFilterContext)
  const search = useSelector((state) => state.hotels.search)
  const priceFilter = useSelector((state) => state.hotels.filters.maxPrice)
  const dispatch = useDispatch()
  const classes = useSliderStyles()
  const { t } = useTranslation()
  const { formatPrice } = usePriceFormat()

  const maxPriceInArray = React.useCallback(
    () => _.maxBy(search, (el) => el.pricePerNight),
    [search]
  )

  useEffect(() => {
    if (priceFilter === 10000000000 && maxPriceInArray()?.pricePerNight) {
      setMaxPrice(Number(maxPriceInArray()?.pricePerNight))
      dispatch(
        setHotelMaxPriceFilters(Number(maxPriceInArray()?.pricePerNight))
      )
    } else {
      setMaxPrice(priceFilter)
    }
    // eslint-disable-next-line
  }, [reset])

  const onChange = (e, value) => {
    setMaxPrice(value)
  }

  const applyChange = (e, value) => {
    dispatch(setHotelMaxPriceFilters(value))
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography className={classes.text} style={{ fontWeight: 'bold' }}>
          {t('hotel results max price')}
        </Typography>
      </Box>
      <Slider
        defaultValue={
          Number(
            maxPriceInArray()?.pricePerNight && maxPriceInArray()?.pricePerNight
          ) || 0
        }
        value={maxPrice}
        onChange={onChange}
        onChangeCommitted={applyChange}
        valueLabelDisplay="on"
        step={50}
        valueLabelFormat={(val) => formatPrice(val, { appendCurrency: true })}
        color="primary"
        max={Number(maxPriceInArray() && maxPriceInArray()?.pricePerNight) || 1}
        min={50}
        classes={{ root: classes.root }}
        style={{ width: '96%' }}
      />
    </>
  )
}

export default MaxPriceSlider
