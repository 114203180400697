import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchOidcLogoutUrl } from '../../repositories/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import SsoError from '../../components/general/ssoError'
import { logOutUser } from '../../store/auth/authAction'
import storage from 'local-storage-fallback'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    marginTop: '20%',
    width: '600px',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    borderRadius: '10px',
  },

  btn: {
    borderRadius: '10px',
    boxShadow: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
    textTransform: 'none',
  },
}))

function OidcLogout() {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { isAuthenticated } = useSelector((state) => state.auth)
  const { companyId } = useParams()
  const [error, setError] = useState()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthenticated) {
      return history.push('/')
    }

    storage.removeItem('user')
    dispatch(logOutUser(true))
    storage.removeItem('state')

    fetchOidcLogoutUrl(companyId)
      .then((res) => {
        // Redirect user to OIDC logout endpoint
        window.location.href = res.logoutUrl
      })
      .catch((error) => {
        console.log(error)
        // Handle
        const errorMessage = error?.response?.data?.error || error
        setError(errorMessage)
      })
  }, [])

  return (
    <Box className={classes.root}>{error && <SsoError message={error} />}</Box>
  )
}

export default OidcLogout
