import React, { memo } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const TrainChooseSeatsType = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return <Typography className={classes.root}>{t('detail reservation')}</Typography>
}

export default memo(TrainChooseSeatsType)
