export const buildRemarks = (data, encloseValues = false) => {
  const { costFieldValues = {}, costfields } = data
  const {
    costfields: costArray = [],
    agencyRmType,
    remarkCategory,
  } = costfields || {}
  const availableCosts = costArray.map((cost) => cost.costType)

  const remarks = []
  for (let rmType in costFieldValues) {
    const appiaName = rmType.match(/^([A-Z]{2,5}) \/$/)?.[1] || null
    let val = String(costFieldValues[rmType])
    if (!val || val.trim() === '' || !availableCosts.includes(rmType)) continue

    if (encloseValues) {
      val = `"${val}"`
    }

    let category = agencyRmType === 'unitas' ? 'U' : '*'

    // Override category with agency remarkCategory option
    if (remarkCategory?.[rmType]) {
      category = remarkCategory[rmType]
    }

    const text = appiaName ? rmType.replace('/', val + '/') : rmType + val

    remarks.push({
      category,
      text,
    })
  }

  return remarks
}
