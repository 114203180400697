import { Button, Grid, makeStyles, useTheme } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import React from 'react'
const useStyles = makeStyles((theme) => ({
  modeSwitcher: {
    backgroundColor: '#eee',
    padding: '4px',
    borderRadius: '24px',
    width: 'auto',
  },

  switchButton: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    textTransform: 'none',
    marginLeft: 'auto',
    padding: '4px 12px 4px 12px',
    width: '100%',
    justifyContent: 'center',
    textWrap: 'nowrap',
  },
}))

function SearchLocationToggle({ label1, label2, isToggled, onChange }) {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Grid container className={classes.modeSwitcher}>
      <Grid item xs={6}>
        <Button
          disableRipple
          onClick={onChange}
          className={classes.switchButton}
          style={{
            backgroundColor: isToggled ? 'unset' : 'white',
          }}
          endIcon={
            isToggled ? null : (
              <Check
                style={{
                  fontSize: '18px',
                }}
                color="primary"
              />
            )
          }
        >
          {label1}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button
          disableRipple
          onClick={onChange}
          className={classes.switchButton}
          style={{
            backgroundColor: isToggled ? 'white' : 'unset',
          }}
          endIcon={
            isToggled ? (
              <Check
                style={{
                  fontSize: '18px',
                }}
                color="primary"
              />
            ) : null
          }
        >
          {label2}
        </Button>
      </Grid>
    </Grid>
  )
}

export default SearchLocationToggle
