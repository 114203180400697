import constants from './constants'

const initialState = {
  type: '',
  flights: {
    selectTrip: '',
    userDataSelection: '/flights/add-public-data',
    ticketSelection: '/flights/details',
    viewHotels: '',
  },
  trains: {
    selectTrip: '',
    ticketSelection: '/trains/choose-seats',
    viewHotels: '',
  },
  cars: {
    selectTrip: '/cars/search',
    ticketSelection: '/cars/details',
    viewHotels: '',
  },
  hotels: {
    browseHotel: '',
    selectHotel: '',
  },

  checkout: '/checkout',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.BREAD_CRUMB_FLIGHT_SELECT:
      return {
        ...state,
        type: 'flights',
        flights: {
          ...state.flights,
          selectTrip: action.payload,
        },
      }

    case constants.BREAD_CRUMB_TRAIN_SELECT:
      return {
        ...state,
        type: 'trains',
        trains: {
          ...state.trains,
          selectTrip: action.payload,
        },
      }
      case constants.BREAD_CRUMB_CAR_SELECT: {
        const carsState = !!state.cars ? {...state.cars} : {...initialState.cars}
        if (!!action.payload?.searchUrl) carsState.selectTrip = action.payload.searchUrl

        return {
          ...state,
          type: 'cars',
          cars: carsState,
          viewHotels: action?.payload?.middlestep || '',
        }
      }
    case constants.BREAD_CRUMB_HOTEL_SELECT:
      return {
        ...state,

        hotels: {
          ...state.hotels,
          browseHotel: action.payload,
        },
      }
    case constants.BREAD_CRUMB_SINGE_HOTEL_SELECT:
      return {
        ...state,
        hotels: {
          ...state.hotels,
          selectHotel: action.payload,
        },
      }
    case constants.BREAD_CRUMB_MIDDLE_STEP:
      return {
        ...state,
        type: action.payload.type,
        viewHotels: action.payload.url,
      }
    case constants.BREAD_CRUMB_SET_TYPE:
      return {
        ...state,
        type: action.payload,
      }

    default:
      return state
  }
}
