import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {review, book, cancel} from '../../repositories/pendingBookings'
import {makeStyles} from '@material-ui/core/styles'
import { getBrandedUrl } from '../../utils/branding'
import {useTranslation} from 'react-i18next'
import Container from '@material-ui/core/Container'
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9'
  },

  header: {
    position: 'sticky',
    padding: '6px',
    display: 'flex',
    top: 0,
    zIndex: '2',
    backgroundColor: 'white',
    height: '65px',
    width: '100%',
    borderBottom: 'solid 1px #ededed',
    alignItems: 'center',
    boxShadow: '0px 3px 16px 0px rgb(0, 0, 0, 0.23)',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  headLogoImage: {
    height: '40px',
    width: 'auto'
  },

  contentRoot: {
    display: 'flex',
    flex: 1,
    padding: '25px 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width:'100%'
  },

  progress: {
    marginBottom: '50px'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: '69px 27px',
      justifyContent: 'flex-start',
    }
  },
  logoImage: {
    height: '85px',
    width: 'auto',
    marginBottom: '70px',

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  btn: {
    borderRadius: '10px',
    boxShadow: 'none'
  },
  headerBlock: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    lineHeight: 'normal',
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '26px',

    '&.error': {
      color: theme.palette.secondary.main
    }
  },
  icon: {
    width: '45px',
    height: '45px',
    marginBottom: '31px',
    color: theme.palette.primary.main,

    '&.error': {
      color: theme.palette.secondary.main
    }
  },
  checkmarkBlock: {
    width: '45px',
    height: '45px',
    borderRadius: '25px',
    border: '2px solid #34c300',
    marginBottom: '31px',
    position: 'relative'
  },
  checkMark: {
    display: 'inline-block',
    position: 'absolute',
    top: '4px',
    left: '13px',
    height: '27px',
    width: '15px',
    borderRight: '2px solid #34c300',
    borderBottom: '2px solid #34c300',
    transform: 'rotate(45deg)'
  },
  text: {
    fontSize: '20px',
    lineHeight: 'normal',
    color: theme.palette.primary.main,
    textAlign: 'center',
    maxWidth: '610px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.error': {
      color: theme.palette.secondary.main
    }
  },
}))
const errorTexts = {
  approve: {
    1100: { texts: ['pending booking approve failed'] },
    1101: {
      texts: ['your booking cannot be processed', 'pending booking expired'],
      icon: NotInterestedIcon
    }
  },
  reject: {
    1100: { texts: ['pending booking reject failed'] },
    1101: {
      texts: ['your booking cannot be processed', 'pending booking expired'],
      icon: NotInterestedIcon
    }
  },
  book: {
    1100: { texts: ['booking failed'] },
    1101: {
      texts: ['your booking cannot be processed', 'pending booking expired'] ,
      icon: NotInterestedIcon
    }
  },
  cancel: {
    1100: { texts: ['pending booking cancellation failed'] },
    1101: {
      texts: ['your booking cannot be processed', 'pending booking expired'],
      icon: NotInterestedIcon
    }
  }
}

const iconsByStatuses = {
  'approve-success': ThumbUpIcon,
  'reject-success':  ThumbDownIcon,
  'cancel-success':  ThumbDownIcon
}

const EmailBookingApprove = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isSent: false,
    loading: true,
    resData: null,
    error: false,
  })
  let { jwt, act } = useParams()
  const texts = {
    'approve-success': ['pending booking approved'],
    'reject-success': ['pending booking rejected'],
    'cancel-success': [
      'pending booking cancelled',
      'pending booking cancelled text'
    ],
  }

  const doAction = useCallback(async () => {
    setState({...state, isSent: true, loading: true})
    const resultState = {...state, isSent: true, loading: false}
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      let rsData = null
      if (act === 'approve' || act === 'reject') {
        rsData = await review(jwt, act, `pending-booking-${act}`);
      } else if (act === 'book') {
        rsData = await book(jwt);
      } else if (act === 'cancel') {
        rsData = await cancel(jwt);
      } else {
        throw new Error('failed')
      }
      resultState.error = null
      resultState.resData = rsData
      setState(resultState)
      return true
    } catch (e) {
      const errCode = e?.response?.data?.code || 1100
      resultState.error = !!errorTexts[act]?.[errCode] ? {...errorTexts[act][errCode]} : {...errorTexts[act][1100]}
      setState(resultState)
      return false
    }
  }, [t, state, jwt, act])

  const approveStatus = useMemo(() => {
    const resStatus = state.resData?.status || null
    if (!!state.loading) return 'loading'
    if (!!state.error) return 'error'
    if (!!resStatus) return resStatus
    return `${act}-success`
  }, [state, act])

  const buildMainContent = useCallback(() => {

    if (approveStatus === 'loading') {
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          <CircularProgress className={classes.progress}/>
          {act === 'book' && (
            <Box key="header-block" className={classes.headerBlock}>
              <Box key={'please-wait'}>{t('please wait')}.</Box>
              <Box key={'booking-processing'}>{t('booking is processing')}...</Box>
            </Box>
          )}
        </Container>
      )
    }

    if (approveStatus === 'error') {
      const Icon = state.error.icon
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          {!!Icon && (<Icon key="err-icon" className={`${classes.icon} error`}/>)}
            <div key="header-block" className={`${classes.headerBlock} error`}>
            {state.error.texts.map((text, ind) => {
              return <Box key={`err-text-${ind}`}>{t(text)}</Box>
            })}
          </div>
        </Container>
      )
    }

    if (approveStatus === 'book-success') {
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          <div key="checkmark-block" className={classes.checkmarkBlock}>
            <div className={classes.checkMark}/>
          </div>
          <div key="text-block-1" className={classes.text}>{t('booking completed')}!</div>
          <div key="text-block-2" className={classes.text}>{t('confirmation send')}</div>
        </Container>
      )
    } else if (['approve-success', 'reject-success', 'cancel-success'].includes(approveStatus)) {
      const Icon = iconsByStatuses[approveStatus];
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          <Icon key="icon" className={classes.icon}/>
          {texts[approveStatus].map((text, ind) => {
            return <Box key={`text-block-${ind}`} className={classes.text}>{t(text, {name: state.resData.booker})}</Box>
          })}
        </Container>
      )
    }
  }, [approveStatus, classes, jwt])

  useEffect(() => {
    if (!state.isSent) doAction()
  }, [state, doAction, act])

  return (
    <Box className={classes.root}>
      <Box key={'header'} className={classes.header}>
        <img src={getBrandedUrl('logo_retina.png')} className={classes.headLogoImage}/>
      </Box>
      <Box key={'contentRoot'} className={classes.contentRoot}>
        {buildMainContent()}
      </Box>
    </Box>
  )
}

export default EmailBookingApprove
