import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { Box, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HotelIcon from '@material-ui/icons/Hotel'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import CountsBtnRow from "../../../../components/reusable/parts/countsBtnRow";

const useStyles = makeStyles((theme) => ({
  root: {},
  drawer: {
    '& .MuiDrawer-paper	': {
      width: '100%',
    },
  },
  align: { display: 'flex', alignItems: 'center' },
  medium: {
    fontWeight: 500,
    flex: 1,
    marginLeft: theme.spacing(1)
  },
  finishBtn: {
    borderRadius: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1.5, 2),
    letterSpacing: 1.1,
  },
  container: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
    marginBottom: '16px',
    paddingRight: 0,
  },
  inputPaddings: {
    padding: theme.spacing(0.5, 1.5),
  },
  btn: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '1.1px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(4),
    fontSize: '14px'
  }
}))

const SelectRoom = ({ initialValue, changeValue, goBack }) => {
  console.log('initialValue', initialValue)
  const classes = useStyles()
  const { t } = useTranslation()
  const [value, setValue] = useState(initialValue['roomCount'])
  const [beds, setBeds] = useState(initialValue['beds'])

  const onSubmit = () => {
    changeValue({roomCount: value, beds: beds})
    goBack()
  }

  const buttons = [
    {value: 1, name: 'single'},
    {value: 2, name: 'double'},
    {value: 3, name: 'triple'},
  ]

  return (
    <Box className={classes.container}>
      <Box className={classes.input}>
        <Box className={`${classes.align} ${classes.inputPaddings}`}>
          <HotelIcon color="primary"/>
          <Typography className={classes.medium}>{value} {value === 1 ? t('room') : t('rooms')}</Typography>
          <Box className={classes.align}>
            <IconButton color="primary" disabled={value === 1 || value === '1'} onClick={() => setValue(Number(value) - 1)}>
              <RemoveIcon color={(value === 1 || value === '1') ? 'disabled' : 'primary'} />
            </IconButton>
            <Typography className={classes.medium}>{value}</Typography>
            <IconButton color="primary" onClick={() => setValue(Number(value) + 1)}>
              <AddIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <CountsBtnRow px={'12px'} buttons={buttons} value={beds} key={'bedsBtns'} countKey={'beds'} modifyCounts={(key,act,val) => setBeds(val)}/>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={onSubmit}
        className={classes.btn}
      >
        {t('select')}
      </Button>
    </Box>
  )
}

export default SelectRoom
