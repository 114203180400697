import React, { useEffect, memo, useMemo, useRef } from 'react'
import { Box, Hidden } from '@material-ui/core'
import AddPublicUsersInfo from '../components/reusable/addPublicUsersInfo'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAddressDetails,
  setPublicContacts,
} from '../store/checkout/checkoutActions'
import MobileHeader from '../containers/header/mobileHeader/mobileHeader'
import DesktopDetailsHeader from '../containers/header/desktopDetailsHeader/desktopDetailsHeader'
import { setBreadcrumbType } from '../store/breadcrumbs/breadcrumbsAction'
import { setFlightsPassengers } from '../store/flights/searchAction'
import { setFlightSearchParameters } from '../store/search/searchAction'
import { useHistory } from 'react-router-dom'
import storage from 'local-storage-fallback'

const AddPublicDataPage = ({ type = 'flight' }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const publicGuestRef = useRef(null)
  const flightParams = useSelector((state) => state.search.flights)
  const { user, showAddressDetails } = useSelector((state) => state.auth)
  const { travellers: flightPassengers } = flightParams

  const publicGuests = useMemo(() => {
    return flightPassengers.filter((p) => p?.isPublic || false)
  }, [flightPassengers])

  useEffect(() => {
    dispatch(setBreadcrumbType('flights'))
  }, [dispatch])

  const updatePassengers = ({
    users: updatedUsers,
    email,
    phone,
    addressDetails,
  }) => {
    if (type === 'flight') {
      if (!user) {
        let transferPhone = {}
        updatedUsers.forEach((user) => {
          transferPhone = { ...transferPhone, [user.uniqueId]: phone }
        })

        if (Object.values(transferPhone).length) {
          storage.setItem('transfer_phone', JSON.stringify(transferPhone))
        }
      }
      const { from, to, departure, arrival, travelerCounts, ticketClass } =
        flightParams
      dispatch(
        setFlightSearchParameters(
          from,
          to,
          departure,
          arrival,
          updatedUsers,
          travelerCounts,
          ticketClass
        )
      )
      dispatch(setFlightsPassengers(updatedUsers))
      dispatch(setPublicContacts({ email, phone }))
      if (showAddressDetails) dispatch(setAddressDetails(addressDetails))
      history.push('/flights/details')
    }
  }

  return (
    <Box>
      <Hidden smUp>
        <MobileHeader />
      </Hidden>
      <Hidden smDown>
        <DesktopDetailsHeader />
      </Hidden>

      {/* *** CONTENT  *** */}
      <Box p={1} pb={29} mt={0}>
        <AddPublicUsersInfo
          ref={publicGuestRef}
          users={publicGuests}
          stickyTop={100}
          onSubmit={(updatedUsers) => updatePassengers(updatedUsers)}
        />
      </Box>
    </Box>
  )
}

export default memo(AddPublicDataPage)
