import agent from './agent'

export const fetchDlkAssociations = async () => {
  try {
    const url = '/v2/dlk/associations'
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}

export const fetchDlkTeams = async (associationId) => {
  try {
    const url = `/v2/dlk/teams/${associationId}`
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}
