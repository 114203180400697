import React, { memo } from 'react'
import ModalHook from './reusable/modalHook'
import TransferContainer from '../../containers/transfer/transferContainer'

const TransferModal = ({ modalBoolean, users, trip, skipLocalStorage, onSelect, onClose, addedTransfers, taxiOnly = false }) => {
  return (
    <ModalHook modalBoolean={modalBoolean} width={500} supportScrolling={true}>
      <TransferContainer
        users={users}
        trip={trip}
        skipLocalStorage={skipLocalStorage}
        onSelect={onSelect}
        onClose={onClose}
        addedTransfers={addedTransfers}
        taxiOnly={taxiOnly}
      />
    </ModalHook>
  )
}

export default memo(TransferModal)
