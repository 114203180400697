import React, { memo, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { IconButton } from '@material-ui/core'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    objectFit: 'contain',
  },
}))

// Takes  list and renders a slider
const ImageSlider = ({ images, width = 600, height = 255 }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = images ? images.length : 0

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  if (!images) return null

  const renderImages =
    images &&
    images.map((step, index) => (
      <div key={index}>
        {Math.abs(activeStep - index) <= 2 ? (
          <img
            className={classes.img}
            style={{ height }}
            src={step}
            alt=""
          />
        ) : null}
      </div>
    ))

  return (
    <div style={{ maxWidth: width }} className={classes.root}>
      <AutoPlaySwipeableViews
        autoplay={false}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {renderImages}
      </AutoPlaySwipeableViews>

      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        variant="text"
        nextButton={
          <IconButton
            className={classes.leftBtn}
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
                <KeyboardArrowRight />
              )}
          </IconButton>
        }
        backButton={
          <IconButton
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
                <KeyboardArrowLeft />
              )}
          </IconButton>
        }
      />
    </div>
  )
}

export default memo(ImageSlider)
