import React, { useMemo } from 'react'
import moment from 'moment'
import { Box, makeStyles, Grid, Select } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  dateInput: {
    borderRadius: '4px',
    '& .MuiOutlinedInput-input': {
      padding: '17px 14px',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginRight: 14,
    marginLeft: 14,
    marginTop: 3,
  },
}))

const month = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

const SelectUserDOB = ({ dob, onChange, name, touched, errors }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const currYear = parseInt(moment().format('YYYY'))
  const minYear = currYear - 80

  const date = useMemo(() => {
    return { y: dob?.y, m: dob?.m, d: dob?.d }
  }, [onChange, dob])
  const changeDate = (newDate) => {
    onChange(newDate)
  }

  const days = useMemo(() => {
    const momentDate = moment(`${date.y}-${date.m}`, 'YYYY-MM')
    return !momentDate.isValid() ? 31 : momentDate.daysInMonth()
  }, [date])

  const blurActive = () => {
    document.activeElement.blur()
  }

  const errorYear = touched?.y && errors?.y
  const errorMonth = touched?.m && errors?.m
  const errorDay = touched?.d && errors?.d

  const getDays = () => {
    const opts = []
    opts.push(
      <option key={'day-select'} value="">
        {t('select')}...
      </option>
    )
    for (let d = 1; d <= days; d++) {
      const day = d < 10 ? `0${d}` : d.toString()
      opts.push(
        <option key={'day-' + day} value={day}>
          {day}
        </option>
      )
    }
    return opts
  }

  const getMonth = () => {
    const opts = []
    opts.push(
      <option key={'month-select'} value="">
        {t('select')}...
      </option>
    )
    month.map((m, i) => {
      const mKey = i < 9 ? `0${i + 1}` : (i + 1).toString()
      opts.push(
        <option key={'month-' + mKey} value={mKey}>
          {t(m)}
        </option>
      )
    })

    return opts
  }

  const getYears = () => {
    const opts = []
    opts.push(
      <option key={'year-select'} value="">
        {t('select')}...
      </option>
    )
    for (let y = currYear - 2; y >= minYear; y--) {
      opts.push(
        <option key={'year-' + y} value={y.toString()}>
          {y.toString()}
        </option>
      )
    }
    return opts
  }

  const renderDobForm = () => (
    <Grid container spacing={0}>
      <Grid item xs={4}>
        <Box pr={1.5}>
          <Select
            native
            name={`${name}.y`}
            fullWidth
            value={date.y}
            variant={'outlined'}
            className={classes.dateInput}
            onChange={(e) => {
              blurActive()
              changeDate({ ...date, y: e.target.value })
            }}
            IconComponent={KeyboardArrowDownIcon}
            error={!!errorYear}
          >
            {getYears()}
          </Select>
          {errorYear && (
            <span className={classes.errorMessage}>{errorYear}</span>
          )}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box px={1.5}>
          <Select
            native
            name={`${name}.m`}
            fullWidth
            value={date.m}
            variant={'outlined'}
            className={classes.dateInput}
            onChange={(e) => {
              blurActive()
              changeDate({ ...date, m: e.target.value })
            }}
            IconComponent={KeyboardArrowDownIcon}
            error={!!errorMonth}
          >
            {getMonth()}
          </Select>
          {errorMonth && (
            <span className={classes.errorMessage}>{errorMonth}</span>
          )}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box pl={1.5}>
          <Select
            native
            name={`${name}.d`}
            fullWidth
            value={date.d}
            variant={'outlined'}
            className={classes.dateInput}
            onChange={(e) => {
              blurActive()
              changeDate({ ...date, d: e.target.value })
            }}
            IconComponent={KeyboardArrowDownIcon}
            error={!!errorDay}
          >
            {getDays()}
          </Select>
          {errorDay && <span className={classes.errorMessage}>{errorDay}</span>}
        </Box>
      </Grid>
    </Grid>
  )

  return (
    <Box mb={'25px'} width={'100%'} position={'relative'}>
      {renderDobForm()}
    </Box>
  )
}

export default SelectUserDOB
