import React from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import pinIcon from '../../assets/pin_icon.svg'
import { useLoadScript } from '@react-google-maps/api'

const SmallGoogleMaps = ({ lat, lng }) => {
  const containerStyle = {
    position: 'relative',
    height: '360px',
    width: '100%',
    padding: '10px',
  }

  const libraries = ['places']

  const API_KEY = 'AIzaSyA1cRh_nRfWORBJpPOL1GU5Re7taLSiQ_E'

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries
    // ...otherOptions
  })

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: lat || 40.854885,
          lng: lng || -88.081807,
        }}
        zoom={8}
        height={{ height: '377px', width: '300px' }}
      >
        <Marker
          position={{ lat: lat, lng: lng }}
          icon={{ url: pinIcon, iconSize: new window.google.maps.Size(32, 32) }} />

      </GoogleMap>
    )
  )
}

export default SmallGoogleMaps
