import React, {
  Fragment,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Button,
  Box,
  AppBar,
  Toolbar,
  Grid,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import AutoCompleteInput from '../../components/reusable/autoCompleteInput'
import UsersSearch from '../../components/reusable/usersSearch'
import ReusableDatpicker from '../../components/reusable/datePicker/reusableDatePicker_new'
import { ReactComponent as Arrows } from '../../assets/images/Arrows-WHT.svg'
import MuiPopover from '@material-ui/core/Popover'
import { fetchGooglePlacesAutocomplete } from '../../repositories/data'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useDirections from '../../hooks/useDirectionsHook'
import useTravellers from '../../hooks/useTravellersHook'
import useDestinations from '../../hooks/useSwitchDestinationsHook'
import usePopover from '../../hooks/usePopoverHook'
import useCarsHook from '../../hooks/useCarsHook'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import DesktopSearchNav from '../../components/search/desktopSearchNav'
import isIframed from '../../utils/isIframed'

const useStyles = makeStyles((theme) => ({
  container: {
    transform: 'translate(0px, -50%)',
  },

  containerIframed: {},

  miniAppBar: {
    top: '74px',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
  },
  checkboxLabel: {
    '& > .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  difPlacesContainer: ({ isMinimized }) =>
    !isMinimized
      ? {
          marginTop: '15px',
          backgroundColor: 'white',
          padding: theme.spacing(1),
          borderRadius: theme.spacing(1),
          display: 'inline-block',
          boxShadow: '0px 13px 15px -8px rgba(0, 0, 0, .15)',
        }
      : {
          padding: '10px 0 18px 18px',
        },
  changeBtn: {
    position: 'absolute',
    right: '0px',
    top: '34px',
    padding: 0,
    zIndex: 1,
  },
  searchBtn: {
    width: '100%',
    fontWeight: 600,
    borderRadius: `0 8px 8px 0`,
    minHeight: '100%',
    padding: 0,
    margin: 0,
    fontSize: '16px',
    transform: 'scaleY(1.04)',
  },
  border: {
    height: '100%',
  },
  dateBox: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderLeft: '1px solid #e6e6e6',
  },
  weight: {
    fontWeight: 600,
    letterSpacing: 0.7,
  },
  arrows: {
    height: '18px',
    width: '18px',
  },
  popoverText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    fontSize: '12px',
  },
  eventIcon: {
    color: theme.palette.iframeSecondary.main,
  },
  checkboxLabelIframe: {
    '& > .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& > .MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.iframeSecondary.main,
    },
  },
}))

const Popover = withStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: '#e02020',
    boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.15)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    marginTop: theme.spacing(1.1),
    overflow: 'visible',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 0,
      height: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderBottom: '15px solid #e02020',
      borderTop: '15px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      marginTop: '-35px',
    },
  },
}))(MuiPopover)

const RentalCarExpandedSearch = ({
  isMinimized,
  isMiddlestep = false,
  showSearchModes,
  currentSearchMode,
  setSearchMode,
}) => {
  const classes = useStyles({ isMinimized })
  const { t, i18n } = useTranslation()
  const [places, setPlaces] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const search = useSelector((state) => state.search)
  const emptySearchState = useMemo(() => ({ travellers: [] }), [])
  const { user, authType, isAuthenticated } = useSelector((state) => state.auth)
  const { isIframe } = useSelector((state) => state.customization)
  const authTypes = ['agent', 'viaAgent']
  const isTopRow = !!user?.isBookingLink || authTypes.includes(authType)

  const {
    earliestDate,
    latestDate,
    suggestedEarliestDate,
    suggestedLatestDate,
  } = user?.bookingLink || {}

  const {
    difPlace: initialDifPlace,
    from: initialFrom,
    to: initialTo,
    departure: initialDeparture,
    arrival: initialArrival,
    travellers: initialTravellers,
  } = search?.cars || emptySearchState

  const {
    departure,
    depChange: setDeparture,
    arrival,
    arrOnChange: setArrival,
  } = useDirections(
    suggestedEarliestDate || initialDeparture,
    suggestedLatestDate || initialArrival,
    true,
    earliestDate,
    latestDate
  )
  const { travellers, setTravellers } = useTravellers(initialTravellers)
  //const MobSearchBar = isAuthenticated ? MobileSearch : GuestMobileSearch

  const {
    switchDestinations,
    setFrom,
    setTo,
    fromDest: from,
    toDest: to,
  } = useDestinations(initialFrom, initialTo, i18n, 'car')
  const {
    isPopoverOpenFrom,
    setIsPopoverOpenFrom,
    isPopoverOpenTo,
    setIsPopoverOpenTo,
    isPopoverOpenTraveller,
    setIsPopoverOpenTraveller,
  } = usePopover()

  const { diffPlace, setDiffPlace, onSubmit } = useCarsHook({
    fromDest: from,
    toDest: to,
    initialDifPlace,
    history,
    i18n,
    endDate: arrival,
    startDate: departure,
    dispatch,
    travellers,
    setIsPopoverOpenFrom,
    setIsPopoverOpenTo,
    setIsPopoverOpenTraveller,
    isMiddlestep,
  })

  const optionLabel = (option) => option?.description || ''
  const renderOption = (option) => {
    return (
      <Fragment>
        <Box mr={1}>
          <LocationOnIcon color="disabled" fontSize="large" />
        </Box>
        <Typography>
          <span>{option?.description}</span>
        </Typography>
      </Fragment>
    )
  }

  const getPlaces = useCallback(
    async (value) => {
      const data = await fetchGooglePlacesAutocomplete(
        value || null,
        i18n.language
      )
      setPlaces(data)
    },
    [i18n.language]
  )

  const ACFilterFunc = useCallback((option) => option?.description || '', [])

  const handleClose = () => {
    setIsPopoverOpenFrom(false)
    setIsPopoverOpenTo(false)
    setIsPopoverOpenTraveller(false)
  }

  const openFrom = Boolean(isPopoverOpenFrom)
  const openTo = Boolean(isPopoverOpenTo)

  const divFromRef = useRef()
  const divToRef = useRef()
  const toRef = useRef(null)
  const btnRef = useRef(null)

  const datesFields = (
    <Grid item xs={12} sm>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={6} sm={3}>
          <Box className={classes.dateBox}>
            <ReusableDatpicker
              value={departure}
              onChange={setDeparture}
              from={departure}
              to={arrival}
              placeHolder={t('pick-up search')}
              title={t('pick-up search')}
              isOutgoing
              back
              HOTEL={false}
              btnRef={btnRef}
              minDate={earliestDate}
              maxDate={latestDate}
            />
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Box className={classes.dateBox}>
            <ReusableDatpicker
              value={departure}
              onChange={setDeparture}
              placeHolder={t('search time')}
              title={t('search time')}
              from={departure}
              to={arrival}
              isOutgoing
              back
              HOTEL={false}
              btnRef={btnRef}
              timeOnly
              minDate={earliestDate}
              maxDate={latestDate}
            />
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Box className={classes.dateBox}>
            <ReusableDatpicker
              value={arrival}
              hideCancel
              onChange={setArrival}
              placeHolder={t('drop-off search')}
              title={t('drop-off search')}
              isOneWay={false}
              HOTEL={false}
              from={departure}
              to={arrival}
              btnRef={btnRef}
              minDate={earliestDate}
              maxDate={latestDate}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box className={classes.dateBox}>
            <ReusableDatpicker
              value={arrival}
              hideCancel
              onChange={setArrival}
              placeHolder={t('search time')}
              title={t('search time')}
              isOneWay={false}
              HOTEL={false}
              from={departure}
              to={arrival}
              btnRef={btnRef}
              timeOnly
              minDate={earliestDate}
              maxDate={latestDate}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )

  const destPlaces = (
    <Grid item xs={12} sm={!isMinimized && !isIframe ? 5 : 4}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={diffPlace ? 6 : 12}>
          <Box className={classes.border} position="relative" ref={divFromRef}>
            {diffPlace && (
              <IconButton
                className={classes.changeBtn}
                onClick={switchDestinations}
                color="primary"
                tabIndex="-1"
              >
                <SwapHorizIcon className={isIframe && classes.eventIcon} />
              </IconButton>
            )}
            <label>
              <Box pl={2} pt={'13px'}>
                <Typography
                  className={classes.weight}
                  variant="caption"
                  color="textSecondary"
                >
                  {t('pick-up place').toUpperCase()}
                </Typography>
              </Box>

              <Box pl={1}>
                <AutoCompleteInput
                  renderOption={renderOption}
                  fetch={getPlaces}
                  value={from}
                  onChange={(_, value) => setFrom(value)}
                  array={places}
                  customFilterFunc={ACFilterFunc}
                  optionLabel={optionLabel}
                  label={t('pick-up place')}
                  placeholder={t('pick-up place')}
                  toRef={toRef}
                  desktop
                  isFrom
                  inputStyles={{ paddingTop: '3px' }}
                />
              </Box>
            </label>
          </Box>
          <Popover
            className={classes.popoverText}
            id="fromfield"
            open={openFrom}
            anchorEl={divFromRef.current}
            onClose={handleClose}
            style={{ top: '70px' }}
          >
            <Typography className={classes.popoverText}>
              {t('enter place of departure')}
            </Typography>
          </Popover>
        </Grid>

        {diffPlace && (
          <Grid item xs={12} sm={6} ref={divToRef}>
            <label>
              <Box pl={2} pt={'13px'}>
                <Typography
                  className={classes.weight}
                  variant="caption"
                  color="textSecondary"
                >
                  {t('drop-off place').toUpperCase()}
                </Typography>
              </Box>
              <Box pl={1}>
                <AutoCompleteInput
                  forwardedRef={toRef}
                  renderOption={renderOption}
                  fetch={getPlaces}
                  value={to}
                  onChange={(_, value) => setTo(value)}
                  array={places}
                  optionLabel={optionLabel}
                  customFilterFunc={ACFilterFunc}
                  label={t('drop-off place')}
                  placeholder={t('drop-off place')}
                  inputStyles={{ paddingTop: '3px' }}
                  desktop
                  isTo
                />
              </Box>
            </label>
            <Popover
              id="tofield"
              open={openTo}
              anchorEl={divToRef.current}
              onClose={handleClose}
              style={{ top: '70px' }}
            >
              <Typography className={classes.popoverText}>
                {t('enter destination')}
              </Typography>
            </Popover>
          </Grid>
        )}
      </Grid>
    </Grid>
  )

  const difPlaceForm = (
    <Box className={classes.difPlacesContainer}>
      <FormControlLabel
        className={
          isIframe ? classes.checkboxLabelIframe : classes.checkboxLabel
        }
        key={'car-dif-places-block'}
        control={
          <Checkbox
            checked={!!diffPlace}
            color="primary"
            name="car-dif-places-cb"
            onChange={(e) => setDiffPlace(e.target.checked)}
          />
        }
        label={t('drop car off at different location')}
        labelPlacement="end"
      />
    </Box>
  )

  const appbarContent = (
    <Toolbar disableGutters>
      <Box p={isAuthenticated ? 1 : 0} width="100%">
        <Grid container>
          <Grid item xs>
            <Grid
              container
              style={{
                border: '1px solid #e6e6e6',
                borderRadius: '8px',
                positon: 'relative',
              }}
            >
              {destPlaces}
              {datesFields}
              {(isMinimized || isIframe) && (
                <Grid item xs={12} sm={isIframe ? 2 : 3}>
                  <Box className={classes.dateBox}>
                    <UsersSearch
                      entityType={'car'}
                      value={travellers}
                      setValue={setTravellers}
                      noLabel
                      withUserModal={true}
                      userShadow={false}
                      isMinimized
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={isMinimized ? 1 : 2}>
                <Button
                  ref={btnRef}
                  disableElevation
                  onClick={onSubmit}
                  variant="contained"
                  color="primary"
                  className={classes.searchBtn}
                  endIcon={<Arrows className={classes.arrows} />}
                >
                  {t('search go')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Toolbar>
  )

  const getNormalContent = () => (
    <Container
      className={isIframed ? classes.containerIframed : classes.container}
      style={{ maxWidth: '1140px' }}
      disableGutters
    >
      {!isIframe && (
        <UsersSearch
          entityType={null}
          value={travellers}
          setValue={setTravellers}
          isPopoverOpenTraveller={isPopoverOpenTraveller}
          setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
          handleClose={handleClose}
        />
      )}
      {showSearchModes && isIframe && (
        <DesktopSearchNav
          currentSearchMode={currentSearchMode}
          setSearchMode={setSearchMode}
        />
      )}
      <AppBar
        color="inherit"
        position="static"
        style={{
          borderRadius: 8,
          boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
        }}
      >
        {appbarContent}
      </AppBar>
      {difPlaceForm}
    </Container>
  )

  const getMinimizedContent = () => (
    <AppBar
      color="inherit"
      position="sticky"
      className={classes.miniAppBar}
      style={{ top: !!isTopRow ? '127px' : '78px' }}
    >
      <Container maxWidth={'lg'} disableGutters>
        {appbarContent}
        {difPlaceForm}
      </Container>
    </AppBar>
  )

  return (
    <Fragment>
      {!isMinimized ? getNormalContent() : getMinimizedContent()}
    </Fragment>
  )
}

export default memo(RentalCarExpandedSearch)

//search go
