import React, { useEffect, useMemo } from 'react'
import { Grid, Paper, Container } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import TrainSearchContainer from '../../containers/trains/trainSearchContainer'
import {
  fetchTrainSearchAction,
  isEarlierTrip,
  isLaterTrip,
} from '../../store/train/trainAction'

import SelectedTrains from '../../containers/trains/trainContainers/selectedTrains/selectedTrains'
import TrainSearchPageForm from '../../components/trains/trainSearchPageForm'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NoTripsFound from '../../components/general/noTripsFound'
import { trainOutgoingbreadCrumbAction } from '../../store/breadcrumbs/breadcrumbsAction'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '12px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}))

const OutgoingTrainSearch = () => {
  const { pathname } = useLocation()
  const classes = useStyles()
  const { resultError, isLoading } = useSelector((state) => state.train)
  const dispatch = useDispatch()
  const params = useParams()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user, authType } = useSelector((state) => state.auth)

  const passengers = useSelector((state) => state.search.train.travellers)
  const { from, to, dep, arr, isTwoWay, depTimeOutbound, depTimeInbound } =
    params
  const authTypes = ['agent', 'viaAgent']
  const isTopRow = !!user?.isBookingLink || authTypes.includes(authType)

  const { latestTime, firstTime, showPrevious, showNext } = useSelector(
    (state) => state.train
  )

  useEffect(() => {
    dispatch(trainOutgoingbreadCrumbAction(pathname))
  }, [dispatch, pathname])

  const clickNext = () => {
    let newDate = moment(latestTime).format('YYYY-MM-DD HH:mm')

    dispatch(isEarlierTrip(false))
    dispatch(isLaterTrip(true))
    getTrips(newDate)
  }

  const clickPrevious = () => {
    const newDate = moment(firstTime)
      .subtract(3, 'hours')
      .format('YYYY-MM-DD HH:mm')
    dispatch(isLaterTrip(false))
    dispatch(isEarlierTrip(true))
    getTrips(newDate)
  }

  const getTrips = (newDate) => {
    dispatch(
      fetchTrainSearchAction({
        from,
        to,
        dep: newDate,
        arr,
        users: passengers,
        isTwoWay,
        depTimeOutbound,
        depTimeInbound,
      })
    )
  }

  return (
    <>
      <TrainSearchPageForm searchParams={params} searchType={'outbound'} />
      {resultError && !isLoading ? (
        <NoTripsFound />
      ) : (
        <Box p={1}>
          <Container maxWidth="lg" disableGutters>
            <Grid container spacing={isMobile ? 2 : 4} direction="row">
              <Grid item xs={12} sm={12} md={9}>
                <Box className={classes.paper}>
                  <TrainSearchContainer
                    clickNext={clickNext}
                    clickPrevious={clickPrevious}
                    showNext={showNext}
                    showPrevious={showPrevious}
                  />
                </Box>
              </Grid>

              {isMobile ? (
                ''
              ) : (
                <Grid item xs={12} sm={12} md={3}>
                  <Paper
                    className={classes.paper}
                    elevation={0}
                    style={{
                      position: 'sticky',
                      top: !isTopRow ? '185px' : '240px',
                    }}
                  >
                    <SelectedTrains />
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      )}
    </>
  )
}

export default OutgoingTrainSearch
