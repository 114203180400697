export const dmsToDd = (coord) => {
  let reg = /^(\d{2})(\d{2})(\d{2})[A-Z]{1}$/i
  let matches = coord.match(reg)

  if (!matches) {
    return null
  }

  let d = parseInt(matches[1])
  let m = parseInt(matches[2])
  let s = parseInt(matches[3])
  let dir = matches[3]

  let dd = d + m / 60 + s / (60 * 60)

  if (dir.toUpperCase() === 'S' || dir.toUpperCase() === 'W') {
    dd = dd * -1
  }
  return dd
}
