import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: theme.palette.dimWhite },
  weight: { fontWeight: 700 },
  drawer: {
    marginBottom: theme.spacing(10),
    '& .MuiDrawer-paper	': {
      backgroundColor: theme.palette.dimWhite,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '400px',
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  header: ({filter}) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: filter === 'take_date' || filter === 'drop_date' ? 'absolute' : 'relative',
    top: 0,
    height: '64px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 3),
    zIndex: 1000
  }),
  dayNames: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    top: '64px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 5),
    zIndex: 1000,
    borderTop: '1px solid #e2e2e2',
    borderBottom: '1px solid #e2e2e2',
    height: '34px'
  },
  dayName: {
    fontSize: '12px',
  },
  boldTitle: {
    fontWeight: 700,
    fontSize: theme.spacing(2)
  },
  resetFilter: {
    fontSize: theme.spacing(1.75),
    textTransform: 'capitalize',
    lineHeight: '16px',
    fontWeight: 700
  }
}))

const StepHeader = ({ filter, goBack, toggleDrawer }) => {
  const { t } = useTranslation()
  const classes = useStyles({filter})

  const selectHeading = () => {
    switch(filter) {
      case 'from':
        return t('pick-up place')
      case 'to':
        return t('drop-off place')
      case 'take_date':
        return t('pick-up search')
      case 'drop_date':
        return t('drop-off search')
      case 'take_time':
        return t('search time')
      case 'drop_time':
        return t('search time')
      default:
        return t('unknown step')
    }
  }

  return (
    <>
      <Box className={classes.header}>
        <Box display="flex" justifyContent="flex-start" width="64px">
          <ArrowBackIcon color="primary" onClick={goBack}/>
        </Box>
        <Box flex={1} textAlign={'center'}>
          <Typography className={classes.boldTitle}>
            {selectHeading()}
          </Typography>
        </Box>
        <Box width="64px" display="flex" justifyContent="flex-end">
          <CloseIcon color="primary" onClick={toggleDrawer} style={{ cursor: 'pointer' }} />
        </Box>
      </Box>
      {
        (filter === 'take_date' || filter === 'drop_date') && (
          <Box className={classes.dayNames}>
            <Typography className={classes.dayName}>Mon</Typography>
            <Typography className={classes.dayName}>Tue</Typography>
            <Typography className={classes.dayName}>Wed</Typography>
            <Typography className={classes.dayName}>Thu</Typography>
            <Typography className={classes.dayName}>Fri</Typography>
            <Typography className={classes.dayName}>Sat</Typography>
            <Typography className={classes.dayName}>Sun</Typography>
          </Box>
        )
      }
    </>
  )
}

export default StepHeader
