import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px',
  },

  label: {
    fontSize: '14px',
    color: 'black',
  },

  checkbox: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },

  helpTitle: {
    marginTop: '10px',
    marginBottom: '10px',
    color: 'black',
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightMedium,
  },
  closeButton: {
    marginLeft: theme.spacing(1),
    textTransform: 'unset',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  linkButton: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  activeButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'unset',
  },
  navButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'unset',
    border: 'unset',
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.grey.main,
      border: '1px solid',
      borderColor: theme.palette.grey.main,
    },
  },
}))

function HotelZoneFilterMenu({
  items,
  selected,
  onChange,
  onClose,
  onNext,
  onPrevious,
  title,
}) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()
  const [itemHover, setItemHover] = useState(null)

  const handleChange = (value) => {
    if (selected.includes(value)) {
      let updatedSelected = selected.filter((v) => v !== value)
      onChange(updatedSelected)
    } else {
      let updatedSelected = [...selected, value]
      onChange(updatedSelected)
    }
  }

  const handleSelectAll = () => {
    onChange([...items.map((item) => item.value)])
  }

  const handleClearAll = () => {
    onChange([])
  }

  const handleSelectOnly = (item) => {
    onChange([item.value])
  }

  const handleMouseOver = (item) => {
    setItemHover(item)
  }

  return (
    <Box className={classes.root}>
      <div className={classes.helpTitle}>{title}</div>
      <Box style={{ marginBottom: '6px' }}>
        <Link className={classes.linkButton} onClick={handleSelectAll}>
          {t('select all')}
        </Link>{' '}
        |&nbsp;
        <Link className={classes.linkButton} onClick={handleClearAll}>
          {t('clear all')}
        </Link>
      </Box>
      {items.map((item) => (
        <Box key={item.value} onMouseOver={() => handleMouseOver(item)}>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                className={classes.checkbox}
                checked={selected.includes(item.value)}
                color="primary"
                style={{ color: item.color ? item.color : null }}
                onChange={() => handleChange(item.value)}
                name={item.value}
                size="small"
              />
            }
            label={
              <Typography className={classes.label}>{item.text}</Typography>
            }
          />

          {itemHover == item && (
            <Link
              className={classes.linkButton}
              onClick={() => handleSelectOnly(item)}
            >
              {t('only')}
            </Link>
          )}
        </Box>
      ))}
      <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
      <Grid container justifyContent="flex-end">
        <Button
          variant="outlined"
          className={classes.navButton}
          disabled={onPrevious == null}
          onClick={onPrevious}
          disableRipple
          disableElevation
          style={{
            backgroundColor:
              onPrevious == null ? 'transparent' : theme.palette.primary.main,
          }}
        >
          {t('previous')}
        </Button>
        <Button
          variant="outlined"
          className={classes.navButton}
          disabled={onNext == null}
          onClick={onNext}
          disableRipple
          disableElevation
          style={{
            backgroundColor:
              onNext == null ? 'transparent' : theme.palette.primary.main,
          }}
        >
          {t('next')}
        </Button>
        <Button
          variant="outlined"
          className={classes.closeButton}
          onClick={onClose}
          disableRipple
          disableElevation
          style={{
            backgroundColor: 'transparent',
          }}
        >
          {t('close')}
        </Button>
      </Grid>
    </Box>
  )
}

export default HotelZoneFilterMenu
