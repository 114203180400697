import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import { InputAdornment, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import EventIcon from '@material-ui/icons/Event'
import HotelIcon from '@material-ui/icons/Hotel'
import PersonIcon from '@material-ui/icons/Person'
import StepHandler from './stepHandler'
import { fetchCityBasedOnCoordinates } from '../../../../repositories/hotel'
import moment from 'moment'
import Input from '@material-ui/core/Input'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { setMapCenter } from '../../../../store/hotels/hotelsAction'
import {useDispatch, useSelector} from 'react-redux'
import {setHotelSearchParameters} from "../../../../store/search/searchAction";
import { DrawerContext } from '../../result/hotelSearchContent'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.dimWhite,
    display: 'flex',
    flexDirection: 'column'
  },
  weight: { fontWeight: 700 },
  drawer: {
    marginBottom: theme.spacing(10),
    '& .MuiDrawer-paper	': {
      backgroundColor: theme.palette.dimWhite,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '400px',
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    height: '64px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 3),
    zIndex: 1000,
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse'
    }
  },
  boldTitle: {
    fontWeight: 700,
    fontSize: theme.spacing(2)
  },
  resetFilter: {
    fontSize: theme.spacing(1.75),
    textTransform: 'capitalize',
    lineHeight: '16px',
    fontWeight: 700
  },
  input: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e6e6e6',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,

    '& .MuiInput-input': {
      cursor: 'pointer'
    },
    '&.MuiInput-underline:after': {
      display: 'none'
    },
    '&.MuiInput-underline:before': {
      display: 'none'
    },
  },
  headerBtn: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '1.1px'
  }
}))

const HotelMiddlestepFilter = ({ drawer, toggleDrawer, onSelect }) => {
  const classes = useStyles()
  const { searchFilters } = useContext(DrawerContext)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const params = useParams()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const { type, countryCode, lat, lng, checkIn, checkOut, roomCount, guests, beds:paramBeds } = (searchFilters || params)
  const searchData = useSelector((state) => state.search)
  const bedsCount = useMemo(() => {
    if (!!paramBeds) {
      return parseInt(paramBeds)
    }
    return searchData.hotels?.travelerCounts?.beds?.count || 1
  }, [searchData.hotels, paramBeds])

  const initialValues = {
    type,
    checkIn,
    checkOut,
    countryCode,
    guests,
    beds: bedsCount,
    lat,
    lng,
    roomCount: parseInt(roomCount)
  }
  const [filter, setFilter] = useState(null)
  const [filters, setFilters] = useState(initialValues)
  const [city, setCity] = useState(null)

  const fetchInitialCity = useCallback(async () => {
    const city = await fetchCityBasedOnCoordinates({ lat: filters.lat, lng: filters.lng})
    return city['Location']
  }, [filters])

  useEffect(() => {
    if (filters.lat && filters.lng && !city) {
      (async function functionName() {
        const newCity = await fetchInitialCity()
        setCity(newCity)
      })()
    }
  }, [filters, city, fetchInitialCity])

  const calculateRooms = useCallback((usersCount, skipCalculation = true) => {
    const tmpUsers = usersCount || 1;
    let rooms = tmpUsers
    let beds = 1

    if (!skipCalculation) {
      for (let bCount = 2; bCount < 4; bCount++) {
        const tmpRooms = Math.ceil(tmpUsers/bCount)
        if (tmpRooms < rooms) {
          rooms = tmpRooms
          beds = bCount
        }
      }
    }
    return {beds, roomCount: rooms}
  },
    // eslint-disable-next-line
    []
  )

  const handleValueChange = useCallback((field, value) => {
    if (!value) return false
    let updObj = (typeof(value) === 'object') ? value : {[field]: value}
    if (field === 'guests') {
      const res = calculateRooms(parseInt(value))
      updObj = { ...updObj, ...res }
    }
    setFilters({ ...filters, ...updObj })
  }, [filters, calculateRooms])

  useEffect(() => {
    if (moment(filters.checkIn).isSameOrAfter(filters.checkOut)) {
      handleValueChange('checkOut', moment(filters.checkIn).add(1, 'day').format('YYYY-MM-DD'))
    }
  }, [handleValueChange, filters])

  const handleCityChange = (lat, lng, countryCode) => {
    setFilters({ ...filters, lat, lng, countryCode })
  }

  const handleClick = (type) => {
    setFilter(type)
  }

  const handleClose = () => {
    setFilter(null)
    setFilters(initialValues)
    toggleDrawer()
  }

  const saveBeds = () => {
    const { beds } = filters
    const { cityValue, city, checkIn, checkOut, travellers, roomCount, travelerCounts } = searchData.hotels
    const countsForSave = !!travelerCounts && typeof(travelerCounts) === 'object' ? {...travelerCounts} : {}
    if (!countsForSave.beds) {
      countsForSave.beds = { count: beds || 1 }
    } else {
      countsForSave.beds.count = beds
    }
    dispatch(
      setHotelSearchParameters(
        cityValue,
        city,
        checkIn,
        checkOut,
        travellers,
        roomCount,
        countsForSave
      )
    )

  }

  const handleSubmit = () => {
    const { type, countryCode, lat, lng, checkIn, checkOut, roomCount, guests, beds } = filters
    saveBeds()
    if (!!onSelect) {
      onSelect({
        type,
        countryCode,
        lat,
        lng,
        checkIn,
        checkOut,
        roomCount,
        guests,
        beds
      })
    } else {
      dispatch(setMapCenter(null))
      const uri = `/middlestep/${type}/${countryCode}/${lat}/${lng}/${checkIn}/${checkOut}/${roomCount}/${guests}`
      history.push(uri)
    }
    toggleDrawer()
  }

  return (
    <Drawer anchor="right" open={drawer} onClose={handleClose} className={classes.drawer}>
      <Box className={classes.container}>
        {
          filter ? (
            <StepHandler
              filter={filter}
              filters={filters}
              setCity={setCity}
              toggleDrawer={handleClose}
              goBack={() => setFilter(null)}
              handleValueChange={handleValueChange}
              handleCityChange={handleCityChange}
            />
          ) : (
            <>
              <Box className={classes.header}>
                <Box display="flex" justifyContent={isMobile ? 'flex-end' : 'flex-start'} width="100px">
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleSubmit}
                    className={classes.headerBtn}
                    color="primary"
                  >
                    {t('done')}
                  </Button>
                </Box>
                <Box flex={1} textAlign={'center'}>
                  <Typography className={classes.boldTitle}>
                    {t('edit hotel search')}
                  </Typography>
                </Box>
                <Box width="64px" display="flex" justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                  <CloseIcon color="primary" style={{ cursor: 'pointer' }} onClick={toggleDrawer}/>
                </Box>
              </Box>
              <Box pl={3} pr={3} pb={3} pt={0} className={classes.container} style={{ paddingTop: '24px', }}>
                <Input
                  placeholder="City"
                  value={city}
                  startAdornment={
                    <InputAdornment position="start">
                      <LocationCityIcon color="primary"/>
                    </InputAdornment>
                  }
                  onClick={() => handleClick('city')}
                  className={classes.input}
                />
                <Box display="flex">
                  <Input
                    placeholder="From"
                    value={moment(filters.checkIn).format('ddd D MMM')}
                    startAdornment={
                      <InputAdornment position="start">
                        <EventIcon color="primary"/>
                      </InputAdornment>
                    }
                    onClick={() => handleClick('from')}
                    className={classes.input}
                  />
                  <Box width="8px"/>
                  <Input
                    placeholder="To"
                    value={moment(filters.checkOut).format('ddd D MMM')}
                    startAdornment={
                      <InputAdornment position="start">
                        <EventIcon color="primary"/>
                      </InputAdornment>
                    }
                    onClick={() => handleClick('to')}
                    className={classes.input}
                  />
                </Box>
                <Input
                  placeholder="Rooms"
                  value={
                    filters.roomCount.toString() === '1'
                      ? `${filters.roomCount} ${t('room')}`
                      : `${filters.roomCount} ${t('rooms')}`
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <HotelIcon color="primary"/>
                    </InputAdornment>
                  }
                  onClick={() => handleClick('rooms')}
                  className={classes.input}
                />
                <Input
                  placeholder="Travellers"
                  value={
                    filters.guests.toString() === '1'
                      ? `${filters.guests} ${t('traveler').toLowerCase()}`
                      : `${filters.guests} ${t('travelers').toLowerCase()}`
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonIcon color="primary"/>
                    </InputAdornment>
                  }
                  onClick={() => handleClick('travellers')}
                  className={classes.input}
                />
              </Box>
            </>
          )
        }
      </Box>
    </Drawer>
  )
}

export default HotelMiddlestepFilter
