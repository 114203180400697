const SET_MY_BOOKINGS = 'SET_MY_BOOKINGS'
const IS_MY_TRIP_LOADING = 'IS_MY_TRIP_LOADING'
const ERROR_MY_TRIP = 'ERROR_MY_TRIP'

const SET_BOOKING_NUMBER = 'SET_BOOKING_NUMBER'

export default {
  SET_MY_BOOKINGS,
  IS_MY_TRIP_LOADING,
  ERROR_MY_TRIP,
  SET_BOOKING_NUMBER,
}
