import agent from "./agent";

// POST ** Switch admin state **

export const fetchSwitchAdmin = async (id, isAdmin) => {
  try {
    const url = '/admin/set-admin/'
    const { data } = await agent.post(`${url}${id}?admin=${isAdmin}`, {})
    return data
  } catch (e) {
    console.log(e)
    throw e
  }
}

// POST ** Send activation email **

export const fetchSendEmails = async (id) => {
  try {
    const url = '/admin/send-activation/'
    const { data } = await agent.post(`${url}${id}`)
    return data
  } catch (e) {
    throw e
  }
}
