export default function filterFlights(filters, flights) {
  const data = [...flights]
  for (const item of data) {
    item.filtered = false

    const travelType = !filters.travelTypes[item.travelType]
    const companies = filters.companies[item.company] === false
    const time = !(
      item.departTime >= filters.time[item.group].from &&
      item.departTime <= filters.time[item.group].to
    )
    const travelTime = item.travelTime > filters.travelTime[item.group]
    const stopsFilter = !filters.stops[item.via.length - 1]

    item.filtered =
      travelType || companies || time || travelTime || stopsFilter
  }
  return data
}
