import React, { memo, Fragment, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, useTheme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { openSimpleInfoModal } from '../../store/modals/modalAction'
import Linkify from 'linkify-react'
import storage from 'local-storage-fallback'

const useStyles = makeStyles((theme) => ({
  infoTopRow: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#fff0a9',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: '49px',
    borderBottom: `1px solid ${theme.palette.border}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 10px',

    '& .semibold': {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  showLinkModal: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.blueLink,
    padding: '0 0 0 12px',
    cursor: 'pointer',
  },
}))

const COLORS = {
  top: '#fff0a9',
  train: '#fefae9',
  hotel: '#fefae9',
  flight: '#fefae9',
  car: '#fefae9',
}

const BannerRow = ({ bannerKey }) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { banners, agencyBanners } = useSelector((state) => state.auth)
  const { i18n, t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const lang = i18n.language?.split('-')[0].toLowerCase()

  const currentBanner = banners?.[bannerKey]
  const currentLanguageBanner = currentBanner?.[lang]
  const color = COLORS[bannerKey] ?? '#fff0a9'

  const currentAgencyBanner = agencyBanners?.[bannerKey]
  const currentAgencyLanguageBanner = currentAgencyBanner?.[lang]

  const detailsIsALink = currentLanguageBanner?.details?.startsWith('http')

  const onShowLinkModal = useCallback(
    (details) => {
      if (detailsIsALink) {
        window.open(details)
      } else {
        dispatch(
          openSimpleInfoModal({
            title: '',
            text: details,
          })
        )
      }
    },
    [dispatch]
  )

  const maybeShowModal = (banner, translatedBanner) => {
    let { text, details } = translatedBanner
    let { ts } = banner

    const lastShown = storage.getItem(bannerKey)
    if (lastShown && parseInt(lastShown) == ts) return null

    onShowLinkModal(`${text}${details ? '\n' + details : ''}`)

    storage.setItem(bannerKey, ts)
  }

  /**
   * If agency banner is set to show as popup and company banner is not, override company banner.
   */
  if (currentAgencyBanner?.showAsPopup && currentAgencyLanguageBanner?.text) {
    maybeShowModal(currentAgencyBanner, currentAgencyLanguageBanner)
  }

  if (!currentLanguageBanner?.text) {
    return <></>
  }

  let { text, details } = currentLanguageBanner

  /**
   * Show as popup option. Only show if not already shown.
   */
  if (currentBanner?.showAsPopup && currentLanguageBanner?.text) {
    if (maybeShowModal(currentBanner, currentLanguageBanner)) return null
    return null
  }

  // On mobile, cut off text at second newline and if found, put the entire text in details
  if (isMobile) {
    let secondNewline = text.indexOf('\n', text.indexOf('\n') + 1)
    if (secondNewline > -1) {
      details = text.slice()
      text = text.substring(0, secondNewline)
    }
  }

  return (
    <Fragment>
      <Box
        className={`${classes.infoTopRow}`}
        style={{ backgroundColor: color }}
      >
        <span className={'semibold'}>
          <Linkify options={{ nl2br: true }}>{text}</Linkify>
          {details && (
            <span
              onClick={() => onShowLinkModal(details)}
              className={classes.showLinkModal}
            >
              {detailsIsALink ? t('click here') : t('see details')}
            </span>
          )}
        </span>
      </Box>
    </Fragment>
  )
}

export default memo(BannerRow)
