import React, { memo, Fragment } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FlightSearchContainer from '../../containers/flights/flightSearchContainer'
import Flightfilter from '../../containers/flights/filters/flightfilters'
import FlightSelectedTrip from '../../containers/flights/flightSelectedTrip'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NoTripsFound from '../../components/general/noTripsFound'
import FlightSearchPageForm from "../../components/flights/flightSearchPageForm"
import {useParams} from "react-router-dom"

const ReturningFlight = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    searchError
  } = useSelector((state) => state.flights)
  const { searchReturning } = useSelector((state) => state.flights)
  const { user, authType } = useSelector((state) => state.auth)
  const authTypes = ['agent', 'viaAgent']
  const isTopRow = !!user?.isBookingLink || authTypes.includes(authType)
  const searchParams = useParams()

  if (searchError) {
    return <NoTripsFound />
  }

  // Get minimum price, used for result list relative prices and selected outgoing trip to adapt to changed prices.
  const minimumPrice = searchReturning
    ? searchReturning.reduce(
        (prev, curr) => (prev && prev < curr.price ? prev : curr.price),
        null
      )
    : 0

  return (
    <>
      <FlightSearchPageForm searchParams={searchParams} searchType={'return'}/>
      <Box mt={isMobile ? 1 : 3} p={1}>
        <Container maxWidth="lg" disableGutters>
          <Grid
            container
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={2}
          >
            {isMobile ? (
              ''
            ) : (
              <Grid item xs={12} md={3}>
                <Flightfilter />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <FlightSearchContainer returning minimumPrice={minimumPrice} />
            </Grid>

            <Grid item xs={12} md={3}>
              <Card
                elevation={0}
                style={{
                  borderRadius: '12px',
                  position: 'sticky',
                  top: !isTopRow ? '177px' : '232px',
                }}
              >
                <CardContent>
                  <FlightSelectedTrip minimumPrice={minimumPrice} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default memo(ReturningFlight)
