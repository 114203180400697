import { Box, Button, Typography, ButtonGroup } from '@material-ui/core'
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: '5px',
    '&:last-child': {
      marginBottom: '0',
    }
  },
  group: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '3px',
    borderRadius: '5px',
    margin: '12px 0'
  },
  btn: {
    borderRadius: '5px !important',
    fontSize: '14px',
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightMedium,
    "&.MuiButtonGroup-groupedTextHorizontal:not(:last-child)": {
      borderRight: 'none'
    },
  },
  align: { display: 'flex', alignItems: 'center' },
  medium: {
    fontWeight: 600,
  },
}))

const CountsBtnRow = ({px = "16px", buttons = [], countKey, title, value, modifyCounts}) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const set = (newValue) => {
    if (newValue !== value) modifyCounts(countKey, 'set', newValue)
  }

  return (
    <Box px={px} className={classes.row}>
      {!!title &&
        <Box flex={1}>
          <Typography className={classes.medium}>{t(title)}</Typography>
        </Box>
      }

      <Box className={classes.align}>
        <ButtonGroup className={classes.group} fullWidth disableElevation variant="text" color="primary">
          {
            buttons.map((b, i) => (
              <Button key={`btn-${i}`} className={classes.btn} onClick={() => set(b.value)} variant={value === b.value ? 'contained' : 'text'}>{t(b.name)}</Button>
            ))
          }
        </ButtonGroup>
      </Box>
    </Box>
  )
}

export default memo(CountsBtnRow)
