import React, { createContext, memo, useRef, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Button,
  Box,
  AppBar,
  Toolbar,
  Grid,
  Container,
  Typography,
} from '@material-ui/core'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import AutoCompleteInput from '../../components/reusable/autoCompleteInput'
import UsersSearch from '../../components/reusable/usersSearch'
import CompaniesSearch from '../../components/reusable/companiesSearch'
import ReusableDatpicker from '../../components/reusable/datePicker/reusableDatePicker_new'
import { ReactComponent as Arrows } from '../../assets/images/Arrows-WHT.svg'
import MuiPopover from '@material-ui/core/Popover'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  container: {
    // transform: 'translate(0px, -50%)',
  },
  inputContainer: {
    display: 'flex',
    position: 'relative',
  },
  changeBtn: {
    position: 'absolute',
    top: '40px',
    padding: 0,
    zIndex: 1,
  },
  searchBtn: {
    textTransform: 'none',
    fontWeight: 600,
    minWidth: '140px',
    fontSize: '16px',
  },
  lowercase: {
    textTransform: 'capitalize',
  },
  border: {
    height: '100%',
  },
  weight: {
    fontWeight: 600,
    letterSpacing: 0.7,
  },
  addTravelerBtn: {
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'none',
  },
  arrows: {
    height: '18px',
    width: '18px',
  },

  popoverText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    fontSize: '12px',
  },

  lockIcon: {
    height: '100%',
    marginTop: '-9px',
    color: theme.palette.primary.main,
  },

  stopHeader: {
    background: 'white',
    marginBottom: '6px',
    marginTop: '24px',
    padding: '8px',
    borderRadius: '6px',
    width: '200px',
    fontWeight: theme.typography.fontWeightBold,
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 20px 15px -20px',
  },

  stopHeaderText: {
    alignItems: 'center',
    display: 'flex',
  },

  addStop: {
    textTransform: 'none',
  },

  removeStop: {
    textTransform: 'none',
    backgroundColor: '#cdebeb',
    color: '#007e7e',
    maxHeight: '40px',
    marginRight: '8px',
    '&:hover': {
      backgroundColor: '#cdebeb',
    },
  },

  bottomButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
}))

const Popover = withStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: '#e02020',
    boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.15)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    marginTop: theme.spacing(1.1),
    overflow: 'visible',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 0,
      height: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderBottom: '15px solid #e02020',
      borderTop: '15px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      marginTop: '-35px',
    },
  },
}))(MuiPopover)

export const RefsContext = createContext([{}, () => {}])

const MultiCitySearch = ({
  /* */
  entityType = null,
  /* */
  travellers,
  setTravellers,
  setAgentCompany,
  onSubmit,
  /* */
  getCities,
  optionLabel,
  array,
  renderOption,
  /**/
  setTicketClass,
  ticketClass,
  ticketClasses,
  earliestDate,
  latestDate,
  isToLocked,
  usersDisabled = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, authType, agentCompany } = useSelector((state) => state.auth)
  const [isPopoverOpenTraveller, setIsPopoverOpenTraveller] = useState(false)
  const [isPopoverOpenFrom, setIsPopoverOpenFrom] = useState(false)
  const [isPopoverOpenTo, setIsPopoverOpenTo] = useState(false)
  const [multistop, setMultistop] = useState([
    {
      from: null,
      to: null,
      departure: null,
    },
    {
      from: null,
      to: null,
      departure: null,
    },
  ])

  const handleClose = () => {
    setIsPopoverOpenFrom(false)
    setIsPopoverOpenTo(false)
    setIsPopoverOpenTraveller(false)
  }

  const openFrom = Boolean(isPopoverOpenFrom)
  const openTo = Boolean(isPopoverOpenTo)

  const divFromRef = useRef([])
  const divToRef = useRef([])
  const toRef = useRef([])
  const btnRef = useRef(null)

  const addStop = () => {
    const newMultistop = [...multistop]
    const lastTo = multistop[multistop.length - 1].to

    newMultistop.push({
      from: lastTo,
      to: null,
      departure: null,
    })
    setMultistop(newMultistop)
  }

  const removeStop = (index) => {
    const newMultistop = [...multistop]
    newMultistop.splice(index, 1)
    setMultistop(newMultistop)
  }

  const switchDestinations = (index) => {
    const newMultistop = [...multistop]
    const { from, to } = newMultistop[index]
    newMultistop[index].from = to
    newMultistop[index].to = from
    setMultistop(newMultistop)
  }

  const setDeparture = (value, index) => {
    const newMultistop = [...multistop]
    newMultistop[index].departure = value
    setMultistop(newMultistop)
  }

  const setFrom = (value, index) => {
    const newMultistop = [...multistop]
    newMultistop[index].from = value
    setMultistop(newMultistop)
  }

  const setTo = (value, index) => {
    const newMultistop = [...multistop]
    newMultistop[index].to = value
    setMultistop(newMultistop)
  }

  const handleSearch = () => {
    onSubmit(multistop)
  }

  return (
    <Container
      className={classes.container}
      style={{ maxWidth: '1140px' }}
      disableGutters
    >
      <Box width={'100%'}>
        {!!user && authType === 'agent' && (
          <Box overflow="hidden" mr={2} display={'inline-block'}>
            <CompaniesSearch value={agentCompany} setValue={setAgentCompany} />
          </Box>
        )}
        {(authType !== 'agent' || !!agentCompany?.uniqueId) && (
          <Box overflow="hidden" display={'inline-block'}>
            <UsersSearch
              disabled={usersDisabled}
              entityType={entityType}
              value={travellers}
              setValue={setTravellers}
              isPopoverOpenTraveller={isPopoverOpenTraveller}
              setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
              handleClose={handleClose}
              setTicketClass={setTicketClass}
              ticketClass={ticketClass}
              ticketClasses={ticketClasses}
            />
          </Box>
        )}
      </Box>

      {multistop.map((stop, idx) => (
        <>
          <AppBar
            key={idx}
            color="inherit"
            position="static"
            style={{
              borderRadius: 8,
              boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
              marginBottom: '16px',
            }}
          >
            <Toolbar disableGutters>
              <Box p={1} width="100%">
                <Grid container>
                  <Grid item xs>
                    <Grid
                      container
                      style={{
                        border: '1px solid #e6e6e6',
                        borderRadius: '8px',
                        positon: 'relative',
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <Grid container style={{ height: '100%' }}>
                          <Grid item xs sm={5}>
                            <Box
                              className={classes.border}
                              position="relative"
                              ref={(el) => (divFromRef.current[idx] = el)}
                            >
                              <label>
                                <Box pl={2} pt={'13px'}>
                                  <Typography
                                    className={classes.weight}
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {t('search from').toUpperCase()}
                                  </Typography>
                                </Box>

                                <Box pl={1}>
                                  <AutoCompleteInput
                                    renderOption={renderOption}
                                    fetch={getCities}
                                    value={stop.from}
                                    onChange={(_, value) => setFrom(value, idx)}
                                    array={array}
                                    optionLabel={optionLabel}
                                    label={t('search from')}
                                    placeholder={t('search from')}
                                    desktop
                                    isFrom
                                    inputStyles={{ paddingTop: '3px' }}
                                  />
                                </Box>
                              </label>
                            </Box>
                            <Popover
                              className={classes.popoverText}
                              id="fromfield"
                              open={openFrom}
                              anchorEl={divFromRef.current[idx]}
                              onClose={handleClose}
                              style={{ top: '70px' }}
                            >
                              <Typography className={classes.popoverText}>
                                {t('enter place of departure')}
                              </Typography>
                            </Popover>
                          </Grid>
                          <Grid item xs sm={1}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {!isToLocked && (
                                <IconButton
                                  className={classes.changeBtn}
                                  onClick={() => switchDestinations(idx)}
                                  color="primary"
                                  tabIndex="-1"
                                >
                                  <SwapHorizIcon />
                                </IconButton>
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            ref={(el) => (divToRef.current[idx] = el)}
                          >
                            <label>
                              <Box pl={2} pt={'13px'}>
                                <Typography
                                  className={classes.weight}
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {t('search to').toUpperCase()}
                                </Typography>
                              </Box>
                              <Box pl={1}>
                                <AutoCompleteInput
                                  forwardedRef={toRef}
                                  renderOption={renderOption}
                                  fetch={getCities}
                                  value={stop.to}
                                  onChange={(_, value) => setTo(value, idx)}
                                  array={array}
                                  optionLabel={optionLabel}
                                  label={t('search to')}
                                  placeholder={t('search to')}
                                  inputStyles={{
                                    paddingTop: '3px',
                                  }}
                                  desktop
                                  isTo
                                />
                              </Box>
                            </label>
                            <Popover
                              id="tofield"
                              open={openTo}
                              anchorEl={divToRef.current}
                              onClose={handleClose}
                              style={{ top: '70px' }}
                            >
                              <Typography className={classes.popoverText}>
                                {t('enter destination')}
                              </Typography>
                            </Popover>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm>
                        <Grid container style={{ height: '100%' }}>
                          <Grid item xs={6} sm={6}>
                            <Box
                              p={1}
                              pt={0}
                              pb={0}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                borderLeft: '1px solid #e6e6e6',
                                height: '100%',
                              }}
                            >
                              <ReusableDatpicker
                                value={stop.departure}
                                onChange={(value) => setDeparture(value, idx)}
                                from={stop.departure}
                                back
                                placeHolder={t('departure')}
                                title={t('departure')}
                                btnRef={btnRef}
                                isOutgoing
                                MULTISTOP
                                minDate={earliestDate}
                                maxDate={latestDate}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      {idx > 1 && (
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            onClick={() => removeStop(idx)}
                            className={classes.removeStop}
                            variant="contained"
                            color="primary"
                            size="small"
                            disableRipple
                            disableElevation
                          >
                            {t('exact del')}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Toolbar>
          </AppBar>
        </>
      ))}

      <Box className={classes.bottomButtonContainer}>
        <Button
          onClick={addStop}
          variant="contained"
          color="primary"
          className={classes.addStop}
          disableRipple
          disableElevation
        >
          {t('add flight')}
        </Button>

        <Button
          ref={btnRef}
          disableElevation
          onClick={handleSearch}
          variant="contained"
          color="primary"
          className={classes.searchBtn}
          endIcon={<Arrows className={classes.arrows} />}
          disableRipple
        >
          {t('search go')}
        </Button>
      </Box>
    </Container>
  )
}

export default memo(MultiCitySearch)

//search go
