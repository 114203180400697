import React, { memo, Fragment } from 'react'
import FlightSummary from './cards/flightAccordium/flightSummary'
import TrainSummary from './cards/trainAccordium/trainSummary'
import HotelSummary from './cards/hotelAccordium/hotelSummary'
import CarSummary from './cards/carAccordium/carSummary'
import OfflineSummary from './cards/offlineAccordium/offlineSummary'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import NoTrips from '../../containers/myTrips/noTrips'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import miscSummary from './cards/miscAccordium/miscSummary'

export const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  travelerSpan: {
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.5),
    },
  },
  tripRow: {
    cursor: 'pointer',
  },
  arrowIcon: {
    height: '12px',
    width: 'auto',
  },
  statusOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: '45px',
    },
  },
  statusInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flex: 1,
    paddingRight: '15px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '20px',
    },
  },
  aboveStatusRow: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      marginBottom: '13px',
      '&:last-child': {
        marginBottom: '0',
      },
    },
  },
  table: {
    '& .MuiTableCell-root': {
      fontSize: theme.spacing(2),
      lineHeight: 'normal',
      color: theme.palette.common.black,
      padding: '31px 21px',
      borderBottom: `1px solid ${theme.palette.border}`,
      '&.MuiTableCell-head': {
        fontWeight: theme.typography.fontWeightBold,
        [theme.breakpoints.down('sm')]: {
          padding: '32px 0',
          '&:last-child': {
            paddingRight: '20px',
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        padding: '28px 0',
      },
    },
  },
  statusTextCancel: {
    color: '#ff4700 !important',
  },
  statusText: {
    color: `${theme.palette.primary.main} !important`,
  },
  statusTextPast: {
    color: `${theme.palette.common.black} !important`,
  },
  traveler: {
    fontSize: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.5),
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  dots: {
    flex: 1,
    borderBottom: `1px dashed #cccccc`,
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  nameWrapper: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '150px',
    },
  },
}))

const viewComponents = {
  flights: FlightSummary,
  rails: TrainSummary,
  hotel: HotelSummary,
  rentalCar: CarSummary,
  misc: miscSummary,
}

const ViewHandler = ({ bookings, isPast = false, onChangeActive, bookingsScanStatus }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  if (bookings.length === 0) {
    return <NoTrips psdt={isPast} />
  }
  const tripPriority = ['misc', 'flights', 'rails', 'hotel', 'rentalCar'];
  const renderItems = bookings.map((component, key) => {
    let priorTrip = null;
    tripPriority.forEach(tripT => {
      if (!priorTrip && !!component?.[tripT]) priorTrip = tripT
    })
    const TagName = viewComponents?.[priorTrip]

    if (TagName) {
      return (
        <Fragment key={key}>
          <TagName
            index={key}
            model={component}
            bookingsScanStatus={bookingsScanStatus}
            handleChange={() => onChangeActive(component)}
          />
        </Fragment>
      )
    }
  })

  // find keys that are present in bookingsScanStatus but are missing in bookings[].pnr, and render them
  const renderOfflineItems = Object.keys(bookingsScanStatus).map((pnr, key) => {
    if (!bookings.find((booking) => booking.pnr === pnr)) {
      return (
        <Fragment key={key}>
          <OfflineSummary pnr={pnr} />
        </Fragment>
      )
    }
  })

  return (
    <Box>
      <Box>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {!isMobile ? (
                <Fragment>
                  <TableCell style={{ width: '160px' }} align="left">
                    {t('mth start date')}
                  </TableCell>
                  <TableCell style={{ width: '140px' }} align="left">
                    {t('booking number')}
                  </TableCell>
                  <TableCell style={{ width: '140px' }} align="left">
                    {t('booked how')}
                  </TableCell>
                  <TableCell style={{ width: '200px' }} align="left">
                    {t('mth products')}
                  </TableCell>
                  <TableCell style={{ width: '250px' }} align="left">
                    {t('mth destination')}
                  </TableCell>
                  <TableCell
                    style={{ paddingRight: isMobile ? '20px' : '0' }}
                    align="left"
                  >
                    {t('mth travellers')}
                  </TableCell>
                </Fragment>
              ) : (
                <TableCell align="left">{t('mth destination')}</TableCell>
              )}
              <TableCell
                style={
                  !isMobile
                    ? { width: '140px', paddingRight: '44px' }
                    : { paddingRight: '28px' }
                }
                align={'right'}
              >
                {t(!isMobile ? 'mth status' : 'mth start date')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderItems}
            {renderOfflineItems}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default memo(ViewHandler)
