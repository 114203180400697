import React, {
  createContext,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import ExpandedSearchBar from '../../containers/search/rentalCarExpandedSearch'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NoTripsFound from '../../components/general/noTripsFound'
import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import CarsSearchContent from '../../containers/cars/carsSearchContent'
import { fetchAndSaveCars, clearCarsSearch } from '../../store/cars/carAction'
import CarBackgroundMap from '../../components/cars/carBackgroundMap'
import DesktopDetailsHeader from '../../containers/header/desktopDetailsHeader/desktopDetailsHeader'
import CarMiddlestepFilter from '../../containers/cars/carMiddlestepFilter/carMiddlestepFilter'
import isIframed from '../../utils/isIframed'

const useStyles = makeStyles(() => ({
  fixedDiv: {
    position: 'sticky',
    top: 177,
    borderRadius: '12px',
  },
}))

export const CarSearchContext = createContext({
  isMiddlestep: null,
  actionHandler: null,
})

const CarsSearchResults = ({ isMiddlestep = false }) => {
  const classes = useStyles()
  const params = new URLSearchParams(window.location.search)
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false)
  const searchCache = params.get('cache') || ''
  const { cars = {}, isLoading } = useSelector((state) => state.cars)
  const { cars: searchCars } = useSelector((state) => state.search)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()

  useEffect(() => {
    // fetch cars
    dispatch(fetchAndSaveCars(searchCache))
    // return () => { dispatch(clearCarsSearch()) }
  }, [dispatch, searchCache])

  const renderHeader = () => {
    if (isMobile) return <MobileHeader />
    if (!isMiddlestep) return <ExpandedSearchBar isMinimized />
    return (
      <Fragment>
        <DesktopDetailsHeader />
        <ExpandedSearchBar isMiddlestep isMinimized />
      </Fragment>
    )
  }

  const actionHandler = useCallback(
    (action, value) => {
      switch (action) {
        case 'searchDrawer':
          setOpenSearchDrawer(!!value)
          break

        default:
          console.log('unknown action')
          break
      }
    },
    [setOpenSearchDrawer]
  )

  const contextValue = useMemo(
    () => ({
      isMiddlestep,
      actionHandler,
    }),
    [isMiddlestep, actionHandler]
  )

  return (
    <CarSearchContext.Provider value={contextValue}>
      {renderHeader()}

      {!cars?.rates?.length && !isLoading ? (
        <NoTripsFound />
      ) : (
        <Fragment>
          {!isIframed && (
            <CarBackgroundMap
              initialLat={searchCars?.from?.coords?.lat}
              initialLng={searchCars?.from?.coords?.lng}
            />
          )}
          <Box className={classes.box}>
            <Container maxWidth="lg" disableGutters>
              <Grid container>
                <Grid item xs={isMobile ? 12 : 7} style={{ zIndex: 1 }}>
                  <Box mt={isMobile ? 0 : 5} mb={10} p={0}>
                    <CarsSearchContent />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Fragment>
      )}
      {!!isMiddlestep && (
        <CarMiddlestepFilter
          drawer={openSearchDrawer}
          toggleDrawer={() => setOpenSearchDrawer(false)}
        />
      )}
    </CarSearchContext.Provider>
  )
}

export default CarsSearchResults
