const FETCH_OUTGOING_SEARCH = 'FETCH_OUTGOING_SEARCH'
const FETCH_RETURNING_SEARCH = 'FETCH_RETURNING_SEARCH'
const FETCH_MULTI_SEARCH = 'FETCH_MULTI_SEARCH'

const SET_SEARCH_ERROR = 'SET_SEARCH_ERROR'

const SET_FLIGHT_OUTGOING = 'SET_FLIGHT_OUTGOING'
const SET_FLIGHT_RETURNING = 'SET_FLIGHT_RETURNING'
const SET_FLIGHT_MULTI = 'SET_FLIGHT_MULTI'

const SET_FLIGHT_AIRPORTS = 'SET_FLIGHT_AIRPORTS'

const IS_SEARCH_LOADING = 'IS_SEARCH_LOADING'
const SET_FLIGHT_PASSENGERS = 'SET_FLIGHT_PASSENGERS'

const CLEAR_FLIGHT_SEARCH = 'CLEAR_FLIGHT_SEARCH'

const SET_CURRENT_FLIGHT_PRICE = 'SET_CURRENT_FLIGHT_PRICE'

const SET_FLIGHT_MARGINS = 'SET_FLIGHT_MARGINS'

const SET_FLIGHT_AGENCY_FILTERS = 'SET_FLIGHT_AGENCY_FILTERS'

const RESET_STATE = 'RESET_STATE'

const SET_FLIGHT_SEARCH_TYPE = 'SET_FLIGHT_SEARCH_TYPE'

export default {
  FETCH_OUTGOING_SEARCH,
  SET_SEARCH_ERROR,
  SET_FLIGHT_OUTGOING,
  SET_FLIGHT_RETURNING,
  IS_SEARCH_LOADING,
  SET_FLIGHT_PASSENGERS,
  CLEAR_FLIGHT_SEARCH,
  FETCH_RETURNING_SEARCH,
  SET_FLIGHT_AIRPORTS,
  RESET_STATE,
  SET_CURRENT_FLIGHT_PRICE,
  SET_FLIGHT_MARGINS,
  SET_FLIGHT_AGENCY_FILTERS,
  FETCH_MULTI_SEARCH,
  SET_FLIGHT_MULTI,
  SET_FLIGHT_SEARCH_TYPE,
}
