import {
  Box,
  Button,
  Divider,
  Dialog,
  Typography,
  TextField,
} from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  header: ({ isMobile }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isMobile ? theme.spacing(1.5) : theme.spacing(3),
  }),
  boldTitle: ({ isMobile }) => ({
    fontSize: isMobile ? '16px' : '32px',
    fontWeight: theme.typography.fontWeightBold,
  }),
  destOuter: {
    cursor: 'pointer',
    '&:last-child .user-block-divider': {
      display: 'none',
    },
  },
  headerBtn: ({ isMobile }) => ({
    fontWeight: '500',
    fontSize: isMobile ? '16px' : '13px',
    textTransform: isMobile ? 'capitalize' : 'uppercase',
  }),
  destList: {
    overflowY: 'auto',
    minHeight: '200px',
    maxHeight: '400px',
    height: '400px',
  },
  destName: {
    fontSize: 16,
    color: theme.palette.common.black,
  },
  startAdornment: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 0,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  destIcon: {
    color: '#cccccc',
    height: '34px',
    width: 'auto',
  },
  searchBox: {
    '& .MuiOutlinedInput-root': {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 8,
      outline: 'none',

      '& input': {
        fontSize: '24px',
        fontWeight: '600',
        paddingTop: '9px',
        paddingBottom: '8px',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
        border: 'none',
      },
    },
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
  },
}))

const SelectDestinationModal = ({
  options,
  modalBoolean,
  handleClose,
  handleFinish,
  fetchMethod,
  getDestText,
  header = '',
  placeholder = '',
  setIsDisabled,
  isTo,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [searching, setSearching] = useState(false)
  const [filterText, setFilterText] = useState('')
  const classes = useStyles({ isMobile })

  const debounceSearch = useCallback(
    _.debounce((filterText) => {
      fetchMethod(filterText, isTo)
    }, 200),
    []
  )

  useEffect(() => {
    setSearching(true)

    debounceSearch(filterText || '')
  }, [filterText, debounceSearch])

  useEffect(() => {
    setSearching(false)
  }, [options])

  const cancelSelection = () => {
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    })
    handleClose()
  }

  const inputChanged = (val) => {
    const newVal = val || ''
    if (newVal !== filterText) {
      setFilterText(newVal)
    }
  }
  const paperProps = {
    style: { borderRadius: isMobile ? 0 : 16 },
  }

  const escapeHtml = (unsafe) => {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  }

  let regexTxt = ''
  if (filterText && filterText.trim() !== '') {
    regexTxt = filterText
      .replace(/\./g, '\\.')
      .replace(/\*/g, '\\*')
      .replace(/\(/g, '\\(')
      .replace(/\)/g, '\\)')
      .replace(/o/gi, '(o|ö)')
  }

  return (
    <Dialog
      onEntered={() => inputChanged('')}
      PaperProps={paperProps}
      fullScreen={isMobile}
      onClose={cancelSelection}
      fullWidth
      maxWidth={isMobile ? false : 'sm'}
      open={modalBoolean}
    >
      <Box component="header" className={classes.header}>
        <Box flex={1}>
          <Button
            onClick={cancelSelection}
            className={classes.headerBtn}
            color="primary"
          >
            {t('cancel btn')}
          </Button>
        </Box>
        <Box flex={1} textAlign={'center'}>
          <Typography className={classes.boldTitle}>{header}</Typography>
        </Box>
        <Box flex={1} />
      </Box>
      <Divider />
      <Box>
        <Box className={classes.searchBar}>
          <TextField
            autoFocus={true}
            classes={{ root: classes.searchBox }}
            onChange={(e) => inputChanged(e.target.value)}
            variant="outlined"
            placeholder={placeholder}
            inputProps={{
              autoComplete: 'off',
              spellCheck: 'false',
              autoCorrect: 'off',
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Box className={classes.destList}>
        {!searching &&
          options.map((fDest, key) => {
            if (!fDest) return null
            let text = getDestText(fDest)
            if (!text || text.trim() === '') return null

            if (regexTxt) {
              text = escapeHtml(text)
              const regex = new RegExp(regexTxt.trim(), 'ig')
              text = text.replace(
                regex,
                (match) => `<span style="font-weight: bold;">${match}</span>`
              )
            }
            return (
              <Box
                key={'user-list-' + key}
                onClick={() => handleFinish(fDest)}
                className={classes.destOuter}
              >
                <Box display="flex" alignItems="center" pl={'20px'} py={2}>
                  <LocationOnIcon className={classes.destIcon} />
                  <Box ml={'20px'}>
                    <Typography className={classes.destName}>
                      <span dangerouslySetInnerHTML={{ __html: text }} />
                    </Typography>
                  </Box>
                </Box>
                <Divider className={'user-block-divider'} />
              </Box>
            )
          })}
      </Box>
    </Dialog>
  )
}

export default SelectDestinationModal
