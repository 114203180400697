import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import defaultStyles from './defaultTimeline.styles'
import {
  openSimpleInfoModal,
  setBookingPartCancelModalData,
} from '../../store/modals/modalAction'
import CustomIconButton from '../reusable/customIconButton'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  canCancelBooking,
  prepareCancellationProhibitedModal,
} from '../../utils/general'
import { getPnrCarData } from '../../pages/my-trips/my-trips'
import GetCompanyIcon from '../reusable/getCompanyIcon'
import { constructOpenHoursStrings } from '../../utils/carHelpers'
import TripHeader from './tripHeader'
import { Clear } from '@material-ui/icons'
import { createPortal } from 'react-dom'

// takes an array of flights and produces a timeline
const CarTimeLine = ({ model, enableCancel = true, onClose, mainRef }) => {
  const dispatch = useDispatch()
  const classes = defaultStyles()
  const { t } = useTranslation()
  const car = getPnrCarData(model)
  if (!car) return null
  const {
    carCompanyData,
    vehicle,
    pickLocation,
    dropLocation,
    startDate,
    endDate,
  } = car

  const momentSD = moment(startDate, 'YYYY-MM-DD HH:mm')
  const momentED = moment(endDate, 'YYYY-MM-DD HH:mm')
  const days = Math.max(1, momentED.diff(momentSD, 'days'))
  const { user, currency } = useSelector((state) => state.auth)

  const onCancel = async () => {
    const { canCancel, reason } = canCancelBooking(user, model, ['rentalCar'])
    if (!canCancel) {
      const modalData = prepareCancellationProhibitedModal(reason, model, user)
      return dispatch(openSimpleInfoModal(modalData))
    }

    dispatch(
      setBookingPartCancelModalData({
        type: 'car',
        pnr: model?.pnr,
        model: model,
        passengers: model?.passengers,
        item: { ...model.car, withReturn: false },
      })
    )
    return true
  }

  const isSeparateCancelDisabled =
    !!model.netsPaymentId || model.paiwiseCheckoutId
  const isCancelled = !!model && (car.cancelled || model?.cancelled)
  const getCancelButton = () => {
    if (isSeparateCancelDisabled) return null
    if (!model?.pnr) return null
    if (isCancelled) {
      return <Box className={classes.cancelledText}>{t('cancelled')}</Box>
    }
    if (!model?.isUpcoming || !enableCancel) return null
    return (
      <CustomIconButton
        label={`${t('cancel booking')} ${t('rental car').toLowerCase()}`}
        className={classes.partCancelBtn}
        icon={<Clear />}
        onClick={(e) => {
          e.stopPropagation()
          onCancel()
        }}
        color="secondary"
        variant={'outlined'}
      />
    )
  }

  const daysText = `${days} ${t('day', { count: days }).toLowerCase()}`

  const tripText =
    _.capitalize(moment(startDate).format('ddd DD MMM')) +
    ' - ' +
    _.capitalize(moment(endDate).format('ddd DD MMM'))
  const driverText = `${model.passengers.length} ${t(
    model.passengers.length > 1 ? 'drivers' : 'driver'
  )}`

  const renderPlace = (title, location, date) => {
    const placeText =
      _.startCase(_.toLower(location.addressLine)) +
      (pickLocation.city ? ', ' + _.startCase(_.toLower(location.city)) : '')
    const dateText = _.capitalize(moment(date).format('ddd DD MMM'))
    const timeText = moment(date).format('HH:mm')

    const hourTexts = location.openHours
      ? constructOpenHoursStrings(location.openHours, t)
      : null

    return (
      <>
        <Box>
          <Typography className={classes.subTitle}>{title}</Typography>
        </Box>

        <Box>{placeText}</Box>
        <Box mt={1}>
          {dateText}
          <Box className={classes.dot} />
          {timeText}
        </Box>

        {hourTexts && (
          <Box className={classes.details}>
            <Box mt={2}>{t('opening hours')}</Box>
            {hourTexts.map((text, index) => (
              <Box key={index}>{text}</Box>
            ))}
          </Box>
        )}
      </>
    )
  }

  const vehiclePrefType = vehicle.rentalPrefType || ''
  const category = t('car category ' + vehiclePrefType.substr(0, 1), '')
  const type = t('car type ' + vehiclePrefType.substr(1, 1), '')
  const transmission = t('car transmission ' + vehiclePrefType.substr(2, 1), '')
  const fuel = t('car fuel ' + vehiclePrefType.substr(3, 1), '')
  const milageText =
    car.vehicle.freeMileage == 'UNL'
      ? t('unlimited').toLowerCase()
      : car.vehicle.freeMileage

  return (
    <>
      <TripHeader type="car" onClose={onClose} />
      <Box className={classes.titleSection}>
        <Typography className={classes.title}>
          {_.startCase(carCompanyData.companyName)}{' '}
          {t('rental car').toLowerCase()}
        </Typography>
        <Typography className={classes.subtitle}>
          {tripText}, {driverText}
        </Typography>
      </Box>

      <Box className={classes.card}>
        <Box mt={1} mb={1}>
          <Grid container spacing={2}>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <GetCompanyIcon
                height={20}
                width={20}
                company={`car/${carCompanyData.companyCode}`}
              />
            </Grid>
            <Grid item xs={10}>
              <Box class={classes.subTitle}>{_.startCase(vehicle.model)}</Box>

              <Box>{t('or similar car')}</Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={3} mb={3}>
          <Divider />
        </Box>

        {renderPlace(t('trip pick-up'), pickLocation, startDate)}

        <Box mt={2}>
          <Divider />
        </Box>
        <Box mt={2} mb={2} style={{ color: 'black' }}>
          <span className={classes.weight}>{daysText}</span>
          <span> {t('rent')}</span>
        </Box>
        <Box mb={3}>
          <Divider />
        </Box>

        {renderPlace(t('trip drop-off'), dropLocation, endDate)}

        <Box mt={2} mb={3}>
          <Divider />
        </Box>

        <Typography className={classes.subTitle}>
          {t('vehicle description')}
        </Typography>

        <Box className={`${classes.details} ${classes.detailSection}`}>
          {category && <Box>{category}</Box>}
          {type && <Box>{type}</Box>}
          {transmission && <Box>{transmission}</Box>}
          {fuel && <Box>{fuel}</Box>}
        </Box>
      </Box>

      <Box className={`${classes.card} ${classes.detailSection}`}>
        <Typography className={classes.subTitle}>
          {t('other details')}
        </Typography>

        <Box className={classes.details}>
          {car.reservationNum && (
            <Box>
              {t('book data car ref')} {car.reservationNum}
            </Box>
          )}
          {milageText && (
            <Box>
              {t('mileage')} {milageText}
            </Box>
          )}
          {car.co2 && (
            <Box>
              {t('emission')} {car.co2} g / km
            </Box>
          )}
          {car.total && (
            <Box>
              {t('price')} {car.total} {currency}
            </Box>
          )}
        </Box>
      </Box>

      <Box style={{ marginBottom: '100px' }} />

      {createPortal(
        <Box className={classes.footer}>
          <Divider mb={2} />
          {getCancelButton()}
        </Box>,
        mainRef.current
      )}
    </>
  )
}

export default memo(CarTimeLine)
