import React, { memo } from 'react'
import { Box, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import FlightmiddleStepmobile from '../mobile/flightMiddleStepMobile'
import GetCompanyIconElement from '../../../../components/reusable/getCompanyIcon'
import { flightIsSameDir } from '../../../../utils/general'
import Policy from '../../../../components/reusable/tags/policy'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },

  borderColor: {
    borderColor: theme.palette.border,
  },

  invalidDName: {
    backgroundColor: '#fff5e0',
    padding: '4px 5px',
    borderRadius: '3px',
  },
}))

const Outgoing = () => {
  const { i18n } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.align}>
      <ArrowForwardIcon />
      <Typography variant="body2" className={classes.bold}>
        {i18n.t('chosen trip')}
      </Typography>
    </Box>
  )
}

const Returning = () => {
  const { i18n } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.align}>
      <ArrowBackIcon />
      <Typography variant="body2" className={classes.bold}>
        {i18n.t('chosen returntrip')}
      </Typography>
    </Box>
  )
}

const OutgoingFlightCard = ({
  flight,
  isOutgoing,
  totalPrice,
  compareTrip,
}) => {
  const classes = useStyles()
  const { i18n } = useTranslation()
  const theme = useTheme()
  const isSameDir = compareTrip ? flightIsSameDir(compareTrip, flight) : null
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // flightMiddlestepDetails sending null flight after changing dates
  if (!flight) return null

  const isDirect =
    flight.via.length <= 1
      ? i18n.t('filter directly small')
      : `${flight.via.length - 1} ${i18n.t('changes')}`

  if (!isMobile) {
    return (
      <Box
        borderRight={isOutgoing ? 1 : 0}
        className={classes.borderColor}
        p={3}
      >
        <Box className={classes.align} pb={1.5}>
          {isOutgoing ? <Outgoing /> : <Returning />}
        </Box>
        <Box>
          <Box className={classes.align} pb={1.5}>
            <Typography>
              <Moment format="ddd D MMM">{flight.date}</Moment>
            </Typography>
            <Typography className={classes.bold}>
              {flight.departTime}-{flight.arriveTime}
            </Typography>
          </Box>

          <Box className={classes.align} pb={1.5}>
            <Typography>
              <span
                className={`${
                  !!isSameDir && !isSameDir.isFrom ? classes.invalidDName : ''
                }`}
              >
                {flight.from.city} ({flight.from.shortName})
              </span>{' '}
              –{' '}
              <span
                className={`${
                  !!isSameDir && !isSameDir.isTo ? classes.invalidDName : ''
                }`}
              >
                {flight.to.city} ({flight.to.shortName})
              </span>
            </Typography>
          </Box>

          <Box className={classes.align}>
            <GetCompanyIconElement small={isMobile} company={flight.company} />
            <Typography className={classes.bold}>
              {flight.formattedTravelTime}
            </Typography>
            <Typography>{isDirect}</Typography>
            <Policy
              policy={flight.exceedsPolicy}
              deal={flight.isDealFare}
              corporate={flight.isCorporateFare}
            />
          </Box>
        </Box>
      </Box>
    )
  }
  return (
    <FlightmiddleStepmobile
      totalPrice={totalPrice}
      flight={flight}
      isOutgoing={isOutgoing}
    />
  )
}
export default memo(OutgoingFlightCard)
