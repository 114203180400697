import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import RailTripSearchItem from './trainContainers/trainSearchItem/RailTripSearchItem'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import DateHeader from './trainContainers/dateHeader.js/dateHeader'
import TrainSkeletons from './../../components/reusable/skeletons/trainSkeletons'
import { useTranslation } from 'react-i18next'
import PreviousResultsScroller from '../../components/trains/previousResultsScroller'
import NextResultsScroller from '../../components/trains/nextResultsScroller'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  removeSelectedReturningTrain,
  removeTrainSearchResults,
  setOutgoingCities,
  setOutgoingTrain,
  setReturningTrain,
} from '../../store/train/trainAction'

// MockTrainArray
//import results from '../../mock/trains.json'

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '12px 12px 0 0px',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  trainClasses: {
    display: 'flex',
    alignItems: 'stretch',
    '& > *': {
      margin: theme.spacing(1, 7.5),
    },
  },
  titleRow: {
    width: '100%',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  alignJustify: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  onLoadmoreTrains: {
    textTransform: 'none',
    fontSize: '14px',
  },
  accordion: {
    background: `${theme.palette.primary.light}`,
    margin: theme.spacing(1, 0),
  },
  laterTravelPaper: {
    borderRadius: '0 0 12px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterBtn: {
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'capitalize',
  },
  boxBorder: { borderBottom: '1px solid rgba(0, 0, 0, .125)' },
  boxHeight: { height: theme.spacing(5) },
  boxHeightMobile: { height: theme.spacing(4) },
  wrapper: {
    padding: theme.spacing(2, 0),
    borderBottom: '1px solid #f0f0f0',
    height: theme.spacing(8),
  },
  tripsListOuter: {
    backgroundColor: 'white',
  },
}))

const Accordion = withStyles((theme) => ({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: '8px 0',
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&expanded': {
      margin: theme.spacing(1, 0),
      minHeight: '0 !important',
    },
  },
}))(MuiAccordion)

const AccordionSummary = withStyles(() => ({
  root: {
    padding: 0,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 0,
  },

  content: {
    margin: '0 !important',
    minHeight: 0,
  },
}))(MuiAccordionSummary)

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails)

const TrainSearchContainer = ({
  clickNext,
  clickPrevious,
  showPrevious,
  showNext,
  direction = 'outbound',
}) => {
  const { from, to, dep, arr, isTwoWay, depTimeOutbound, depTimeInbound } =
    useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const { isAuthenticated, trainSearch } = useSelector((state) => state.auth)
  const [expanded, setExpanded] = useState(null)
  const { t } = useTranslation()

  const results = useSelector((state) => state.train.results)
  const isLoading = useSelector((state) => state.train.isLoading)
  const { user } = useSelector((state) => state.auth)

  const highlightRefundableTickets = user?.highlightRefundableTickets

  const handleChange = (panel) => (_event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const selectTrain = async (tr) => {
    const result = {
      train: { ...tr, solution: undefined },
      pricing: { ...tr.solution },
      searchDates: { ...tr.searchDates || {} },
      hasFastTrain: tr.hasFastTrain,
      trainTypes: tr.trainTypes,
      correlationID: tr.correlationID,
      dataForFilter: tr.dataForFilter,
    }

    const returnLocation = location.pathname.includes('returning')

    if (returnLocation || isTwoWay === '0') {
      const uri = isAuthenticated
        ? '/trains/choose-seats'
        : '/trains/add-public-data'

      dispatch(setOutgoingCities([]))
      if (isTwoWay === '0') {
        dispatch(removeSelectedReturningTrain())
        dispatch(setOutgoingTrain(result))
        return history.push(uri)
      }
      dispatch(setReturningTrain(result))

      return history.push(uri)
    }
    let uri = `/trains/returning/${from}/${to}/${dep}/${arr}/${isTwoWay}`
    if (trainSearch === 'custom')
      uri = `${uri}/${depTimeOutbound}/${depTimeInbound}`

    await dispatch(removeTrainSearchResults())
    dispatch(setOutgoingTrain(result))
    dispatch(removeSelectedReturningTrain())
    history.push(uri)
  }

  const renderOutgoingTrains = /*results*/ results.map((train, i) => (
    <RailTripSearchItem
      key={i}
      item={train}
      lgSize={7}
      onSelectTicket={selectTrain}
    />
  ))

  const title =
    direction !== 'return' ? t('choose departure mob') : t('select inbound')
  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <Box p={2} pt={0} className={isLoading ? classes.boxBorder : ''}>
          {!isLoading && <Box className={classes.titleRow}>{title}</Box>}

          {isLoading ? (
            <Box className={classes.wrapper}> </Box>
          ) : (
            <DateHeader />
          )}

          {isLoading ? (
            <Box className={classes.boxHeight}> </Box>
          ) : (
            <Box mt={2}>
              <Grid container alignItems="flex-end">
                <Grid item xs={7}>
                  {/*
                  <Button
                    className={classes.filterBtn}
                    startIcon={<TuneRoundedIcon color="primary" />}
                    onClick={toggleDrawer('left', true)}
                  >
                    Filter
                  </Button>

                  <FilterTrainDrawer
                    drawer={drawer}
                    toggleDrawer={toggleDrawer}
                  />
                  */}
                </Grid>
                <Grid item xs container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body1" align="center">
                      {t('2 class')}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography align="center" variant="body1">
                      {t('1 class')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>

      {isLoading ? (
        <TrainSkeletons padding />
      ) : (
        <>
          <PreviousResultsScroller show={showPrevious} click={clickPrevious} />

          <Box className={classes.tripsListOuter}>{renderOutgoingTrains}</Box>

          <NextResultsScroller click={clickNext} show={showNext} />
        </>
      )}
    </>
  )
}

export default TrainSearchContainer
