import React, { useContext, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useCheckBoxFilter from '../../../hooks/useCheckBoxFilterHook'
import { setHotelPopularFilters } from '../../../store/hotels/hotelsAction'
import { HotelFilterContext } from './hotelFilterDrawer'

const PopularFilters = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const popular = useSelector((state) => state.hotels.filters.popular)
  const reset = useContext(HotelFilterContext)
  const { filters, onChange } = useCheckBoxFilter(popular, reset)

  useEffect(() => {
    dispatch(setHotelPopularFilters(filters))
  }, [filters, dispatch])

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={filters.cancellation}
            onChange={onChange}
            name="cancellation"
            color="primary"
          />
        }
        label={t('hotel popular free')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={filters.breakfast}
            onChange={onChange}
            name="breakfast"
            color="primary"
          />
        }
        label={t('hotel popular breakfast')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={filters.wifi}
            onChange={onChange}
            name="wifi"
            color="primary"
          />
        }
        label={t('hotel popular wifi')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={filters.eco}
            onChange={onChange}
            name="eco"
            color="primary"
          />
        }
        label={t('environmentally friendly hotel')}
      />
    </>
  )
}

export default PopularFilters
