import React, { createContext, memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { use100vh } from 'react-div-100vh'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import BackgroundImage from '../assets/images/background/joshua-sortino-71vAb1FXB6g-unsplash.jpg'
import { useMediaQuery } from '@material-ui/core'
import { useSelector } from 'react-redux'
import isIframed from '../utils/isIframed'

const getPicture = () => {
  const { hostname } = window.location

  if (['www.jade.se', 'localhost-test.citycity.se'].includes(hostname))
    return null

  if (hostname === 'boka.gulavaggen.nu')
    return 'https://static.citycity.se/agencies/boka.gulavaggen.nu/bakgrund.png'

  if (hostname === 'ksss.citycity.se')
    return 'https://static.citycity.se/agencies/ksss.citycity.se/background.jpg'

  return BackgroundImage
}

export const MainBGContext = createContext({ clientWidth: null })

const useStyles = makeStyles((theme) => ({
  root: {
    //    overflowY: 'scroll',
    backgroundAttachment: 'scroll',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: isIframed ? null : theme.palette.backgroundColor,
    //    display: 'flex',
    //    flexDirection: 'column',
    //    width: '100%',
    //    [theme.breakpoints.up('sm')]: {
    //    },
    minHeight: isIframed ? '400px' : '100vh',
    display: 'flex',
    flexDirection: 'column',
    //height: (props) => props.height,
    '-webkit-overflow-scrolling': 'touch',
    backgroundImage: (props) => (isIframed ? null : `url(${props.src})`),
    backgroundPosition: 'center center',
    //transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1)'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
}))

const BackgroundHook = ({ children }) => {
  const location = useLocation()
  const height = isIframed ? window.innerHeight : use100vh()
  const theme = useTheme()
  const [width, setWidth] = useState(window.innerWidth)
  const visibleAspect = width / (height - 380) // visible background aspect ratio
  const aspectRatio = 6555 / 4372 // imageWidth/imageHeight
  const isInvalidAspect = visibleAspect > aspectRatio
  const {
    checkoutItemsSideDrawer
  } = useSelector((state) => state.modal)
  const bgXSize = isInvalidAspect ? '100%' : 'auto'
  const bgYSize = isInvalidAspect ? 'auto' : 'calc(100% - 380px)'
  const mainWidth = !!checkoutItemsSideDrawer ? 'calc(100% - 370px)' : '100%'
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { backgroundColor, searchBackgroundColor, isIframe } = useSelector(
    (state) => state.customization
  )
  const { backgroundImage, backgroundColor: otherBackgroundColor } =
    useSelector((state) => state.auth)

  const src = backgroundImage || getPicture()

  const isCustomRoute = location.pathname.startsWith('/c/')

  useEffect(() => {
    let timeout = null
    const listener = () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => setWidth(window.innerWidth), 100)
    }
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', () => listener)
  }, [])

  useEffect(() => {
    if (isIframe) {
      document.body.classList.add('iframe-custom')
    }
  }, [isIframe])

  let bgStyles = !isMobile
    ? {
      //width: mainWidth,
    }
    : {
        backgroundPosition: 'center top',
        backgroundSize: `${bgXSize} ${bgYSize}`,
      }

  if (isIframed) {
    bgStyles.backgroundColor = 'transparent'
    bgStyles.minHeight = '100vh'
  } else {
    if (isCustomRoute && backgroundColor) {
      bgStyles.backgroundColor = isIframe ? 'transparent' : backgroundColor
    } else if (!isIframe && (searchBackgroundColor || otherBackgroundColor)) {
      bgStyles.backgroundColor = isIframe
        ? 'transparent'
        : searchBackgroundColor || otherBackgroundColor
    }
  }
  const selectLocation = () => {
    if (
      [
        '/flights',
        '/flights-multicity',
        '/cars',
        '/trains',
        '/',
        '/hotels',
        '/log-in',
        '/register',
        '/forgot-password',
        '/forgot',
        '/activate',
      ].includes(location.pathname)
    ) {
      return src
    }
    return null
  }

  const classes = useStyles({ height, src: selectLocation() })

  return (
    <Box className={classes.root} style={bgStyles} id="background">
      <MainBGContext.Provider value={{ clientWidth: width }}>
        {children}
      </MainBGContext.Provider>
    </Box>
  )
}

export default memo(BackgroundHook)
