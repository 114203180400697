import i18next from 'i18next'

export function getHotelSortTypes(defaultSortType) {
  const sortTypes = [
    { type: 'closest', label: i18next.t('closest') },
    { type: 'cheapest', label: i18next.t('sorted cheapest') },
    { type: 'stars', label: i18next.t('stars') },
    { type: 'deal', label: i18next.t('sorted deal') },
  ]

  if (defaultSortType === 'agency') {
    sortTypes.unshift({
      type: 'agency',
      label: i18next.t('agency favorites'),
    })
  }

  return sortTypes
}
