import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { GoogleMap, OverlayView, useLoadScript } from '@react-google-maps/api'
import { setCarFilters } from '../../store/cars/carAction'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { applyCarFilters } from '../../utils/carHelpers'
import { HomeRounded } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  marker: {
    backgroundColor: 'white',
    cursor: 'pointer',
    padding: '7px 9px',
    borderRight: '8px',
    borderRadius: '16px',
    boxShadow: '0 1px 3px -1px rgba(0, 0, 0, 0.2)',
    fontSize: '16px',
    minWidth: '87px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,

    '&.selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 0px 5px 1px ${theme.palette.primary.main}`,
    },
  },

  addressMarker: {
    fontFamily: 'Metropolis',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'black',
    padding: '3px 4px 3px 4px',
    borderRadius: '16px',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
  },

  hoverBox: {
    position: 'fixed',
    right: '80px',
    bottom: '24px',
    borderRadius: '6px',
    backgroundColor: 'white',
    fontSize: '16px',
    padding: '6px 12px 6px 12px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.25)',
  },
}))

const mapNumber = (n) => {
  return Number(Number.parseFloat(n).toPrecision(10))
}

const libraries = ['places']

const CarBackgroundMap = ({ initialLat, initialLng, showMap }) => {
  const initialCenter = useMemo(
    () => ({
      lat: mapNumber(initialLat),
      lng: mapNumber(initialLng),
    }),
    [initialLat, initialLng]
  )
  const [mapCenter, setMapCenter] = useState()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { user, addresses } = useSelector((state) => state.auth)
  const { cars, filters } = useSelector((state) => state.cars)

  const [hoverAddress, setHoverAddress] = useState(null)

  const setLocFilter = useCallback(
    (loc) => {
      if (!loc?.uniqueKey) return
      const newLKey =
        filters?.locationKey === loc.uniqueKey ? null : loc.uniqueKey
      dispatch(setCarFilters({ locationKey: newLKey }))
    },
    [dispatch, filters]
  )
  const markerCorrectOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })

  const markers = useMemo(() => {
    const filtered = applyCarFilters(cars?.rates, filters)
    const aLoc = []
    filtered.forEach((f) => {
      const pkey = f?.pickLocation?.uniqueKey
      const dkey = f?.dropLocation?.uniqueKey
      if (pkey && !aLoc.includes(pkey)) aLoc.push(pkey)
      if (dkey && !aLoc.includes(dkey)) aLoc.push(dkey)
    })

    const loc = cars?.locations || []
    const selectedLoc = filters?.locationKey || null
    return loc
      .map((l) => {
        if (!aLoc.includes(l.uniqueKey)) return null
        const isSelected = !!selectedLoc && selectedLoc === l.uniqueKey
        return (
          <OverlayView
            getPixelPositionOffset={markerCorrectOffset}
            key={l.uniqueKey}
            position={{
              lat: mapNumber(l.coords?.lat),
              lng: mapNumber(l.coords?.lng),
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div
              onClick={() => setLocFilter(l)}
              className={`${classes.marker} ${isSelected ? 'selected' : ''}`}
            >
              {_.startCase(_.toLower(l.companyName))}
            </div>
          </OverlayView>
        )
      })
      .filter((l) => !!l)
  }, [filters, classes, cars, setLocFilter])

  const addressMarkers = useMemo(
    () =>
      addresses?.map((address) => (
        <OverlayView
          key={address.placeId}
          position={{
            lat: address.location.lat,
            lng: address.location.lng,
          }}
          mapPaneName={OverlayView.FLOAT_PANE}
        >
          <div
            className={classes.addressMarker}
            onMouseOver={() => setHoverAddress(address)}
            onMouseOut={() => setHoverAddress(null)}
          >
            <HomeRounded></HomeRounded>
          </div>
        </OverlayView>
      )),
    [addresses, user]
  )

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries,
    // ...otherOptions
  })
  const [map, setMap] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [searchPosition, setSearchPosition] = useState(null)

  useEffect(() => {
    const point =
      mapCenter || offsetCenter(searchPosition || initialCenter, 500, 0)
    if (map && point) {
      if (!isMobile) {
        map.panTo(point)
      } else {
        map.setCenter(point)
      }
    }
    // eslint-disable-next-line
  }, [isMobile, initialCenter, searchPosition, mapCenter, map])

  function offsetCenter(latlng, offsetx, offsety) {
    if (isMobile) return latlng
    if (!map || !map.getProjection()) return

    var scale = Math.pow(2, map.getZoom())

    var worldCoordinateCenter = map
      .getProjection()
      .fromLatLngToPoint({ lat: latlng.lat, lng: latlng.lng })
    var pixelOffset = {
      x: offsetx / scale || 0,
      y: offsety / scale || 0,
    }

    var worldCoordinateNewCenter = {
      x: worldCoordinateCenter.x - pixelOffset.x,
      y: worldCoordinateCenter.y + pixelOffset.y,
    }
    const point = map
      .getProjection()
      .fromPointToLatLng(worldCoordinateNewCenter)
    return { lat: point.lat(), lng: point.lng() }
  }

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  const wrapperStyle = {
    position: 'fixed',
    width: 'calc(100vw - 15px)', // Workaround
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
  }

  const wrapperStyleMobile = showMap
    ? {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
      }
    : {
        position: 'static',
        width: '100vw', // Workaround
        top: 0,
        left: 0,
        right: 0,
        height: '280px',
      }

  const containerStyle = {
    width: '100%',
    height: '100%',
    padding: '0px',
  }

  useEffect(() => {
    setMapCenter(null)
    setSearchPosition(null)
    // eslint-disable-next-line
  }, [initialCenter?.lat, initialCenter?.lng])

  const onDragEnd = () => {
    if (map && map.getCenter()) {
      setMapCenter({ lat: map.getCenter().lat(), lng: map.getCenter().lng() })
    }
  }

  const currInitCenter = mapCenter || offsetCenter(initialCenter, 500, 0)

  const memorizedCenter = useMemo(
    () => currInitCenter,
    [currInitCenter?.lat, currInitCenter?.lng]
  )

  return isLoaded ? (
    <div style={isMobile ? wrapperStyleMobile : wrapperStyle}>
      <GoogleMap
        clickableIcons={false}
        options={{
          disableDefaultUI: true,
          gestureHandling: isMobile && showMap ? 'greedy' : 'cooperative',
          zoomControl: !isMobile,
          scrollwheel: false,
        }}
        mapContainerStyle={containerStyle}
        center={memorizedCenter}
        zoom={12}
        style={{ position: 'fixed' }}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onDragEnd={onDragEnd}
      >
        {markers}

        {addressMarkers}
      </GoogleMap>

      {!isMobile && hoverAddress && (
        <div className={classes.hoverBox}>
          {hoverAddress && (
            <span style={{ fontWeight: 600 }}>{hoverAddress.name}</span>
          )}
        </div>
      )}
    </div>
  ) : (
    ''
  )
}
export default CarBackgroundMap
