import React, { memo, useEffect } from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchTransferFlightTaxi,
  cleanTransferSearch,
  fetchTransferFlightBus,
} from '../../../store/transfer/transferAction'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import TransferViewhandler from './transferViewHandler'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const SelectTransferStep = ({
  title,
  current,
  street,
  setRelevantItinerary,
  handleReset,
  setActiveStep,
  isFlightbusDisabled = false,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const error = useSelector((state) => state.transfer.allError)
  const { t } = useTranslation()

  useEffect(() => {
    if (error) {
      setActiveStep(1)
    }
  }, [error, setActiveStep])

  useEffect(() => {
    let params = {}

    // Going to From Airport
    if (current && current.meta.from === true) {
      params = {
        startLocationCode: current?.meta?.locationCode,
        connectionDate: moment(current?.meta?.time).format(
          'YYYY-MM-DDTHH:mm:ss'
        ),
        endLocationCode: street?.location?.code,
        endStreetName: street?.name,
        endStreetNumber: street?.number,
        type: current.meta.connection.type,
        connectionArrival: 1,
        international: current?.meta?.connection?.international,
      }
      if (!!street?.letter) params.endStreetLetter = street.letter
    } else {
      // Going to the airport
      params = {
        startLocationCode: street.location.code,
        connectionDate: moment(current.meta.time).format('YYYY-MM-DDTHH:mm:ss'),
        endLocationCode: current?.meta?.locationCode,
        startStreetName: street.name,
        startStreetNumber: street.number,
        type: current.meta.connection.type,
        international: current?.meta?.connection?.international,
      }
      if (!!street?.letter) params.startStreetLetter = street.letter
    }

    // FlygTaxi
    try {
      dispatch(fetchTransferFlightTaxi(params, t))
    } catch (e) {
      setActiveStep(1)
    }
  }, [setActiveStep, dispatch, current, street, t])

  useEffect(() => {
    return () => {
      dispatch(cleanTransferSearch())
      // Should clean up all
    }
  }, [dispatch])

  return (
    <Box>
      <Box>
        <Typography
          gutterBottom
          align="center"
          variant="h4"
          className={classes.weight}
        >
          {isMobile || title}
        </Typography>
      </Box>

      <Box>
        <TransferViewhandler
          handleReset={handleReset}
          setRelevantItinerary={setRelevantItinerary}
          current={current}
        />
      </Box>
    </Box>
  )
}
export default memo(SelectTransferStep)
