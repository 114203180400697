import axios from 'axios'
import moment from 'moment'
import { storeCache } from '../store/store'
import { fetchRefreshAccessToken } from './auth'
import { storage } from 'local-storage-fallback'

const { hostname } = window.location

const getSuitableBackendDomain = () => {
  if (hostname.startsWith('localhost')) {
    return hostname
  }

  if (
    hostname === 'citycity.bigtraveltest.bizpart.com' ||
    hostname === 'citycity.bigtravel.se'
  ) {
    return hostname
  }

  if (hostname == 'bigtraveltest.bizpart.com') {
    return 'citycity.bigtraveltest.bizpart.com'
  }

  if (hostname.endsWith('bizpart.com') || hostname.endsWith('bigtravel.se')) {
    return 'citycity.bigtravel.se'
  }

  return hostname
}

const backendDomain = getSuitableBackendDomain()
// console.log('***')
// console.log(`*** HOST = ${hostname} BACKEND = ${backendDomain}`)
// console.log('***')

const API_PORT = process.env.REACT_APP_IS_LOCAL ? '8080' : ''
const API_URL = hostname.startsWith('localhost')
  ? `http://${backendDomain}${API_PORT}/`
  : `https://${backendDomain}/api`

const agent = axios.create({
  baseURL: API_URL,
})

const ignoreSavedUrl =
  hostname.endsWith('bizpart.com') || hostname.endsWith('bigtravel.se')

agent.interceptors.request.use((config) => {
  if (hostname.startsWith('localhost')) {
    //    console.log('development url')
    if (config.url.match(/\/v2/)) {
      config.baseURL = `http://${backendDomain}:4000`
      config.url = config.url.substring(3)
    } else {
      if (process.env.REACT_APP_IS_LOCAL) {
        // Local Docker
        config.baseURL = `http://${backendDomain}:8080`
      } else {
        // Local standalone
        config.baseURL = `http://${backendDomain}`
      }
    }
  } else if (!ignoreSavedUrl) {
    const url = storage.getItem('url')
    if (url) {
      //console.log(`using saved url ${url}`)
      config.baseURL = url
    }
  }
  return config
})

export const getApiUrl = (path) => {
  if (hostname.startsWith('localhost')) {
    if (path.match(/\/v2/)) {
      return `http://${backendDomain}:4000${path.substring(3)}`
    }
  } else if (!ignoreSavedUrl) {
    const url = storage.getItem('url')
    if (url) {
      return `${url}${path}`
    }
  }
  return `${API_URL}${path}`
}

const cancelTokens = {}
export const cancelRqByKey = (key) => {
  if (cancelTokens[key]) {
    cancelTokens[key]()
    delete cancelTokens[key]
  }
}

/**
 * cancel requests by part of the cancel token key name
 * @param part
 * @returns {boolean}
 */
export const cancelRyByKeyPart = (part) => {
  if (!part) return false
  const tmpTokens = cancelTokens || {}

  for (let cKey in tmpTokens) {
    if (cKey.includes(part)) {
      cancelRqByKey(cKey);
    }
  }
}

/* get headers for request */
export const getRqHeaders = async (authNeeded = true) => {
  const expiry = storage.getItem('expiry')
  const state = !!storeCache ? storeCache.getState() : { auth: {} }
  const { auth } = state
  const { user, authType, agentCompany, isAuthenticated, agentAuthUser } = auth
  const headers = {}
  if (authNeeded && expiry) {
    const expired = moment(moment()).isAfter(expiry)

    if (expired) {
      try {
        const newToken = await fetchRefreshAccessToken(user.refresh_token)
        storage.setItem('jwtToken', newToken.access_token)
        storage.setItem(
          'expiry',
          moment().add(newToken.expiry, 'minutes').format()
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  const jwtToken = storage.getItem('jwtToken')

  if (!!isAuthenticated && authType === 'agent') {
    headers['cc-agent-company'] = !!agentCompany?.uniqueId
      ? agentCompany.uniqueId
      : 'none'
  }

  if (!!isAuthenticated && authType === 'viaAgent' && !!agentAuthUser) {
    headers['cc-agent-user-token'] = agentAuthUser
  }

  if (jwtToken !== null) {
    headers['x-auth-token'] = jwtToken
  }

  return headers
}

/* Interceptors to set Auth-x token and RefreshToken is needed */
agent.interceptors.request.use(async (config) => {
  const authNeeded = !config.url.match(/auth\//)

  if (config.cityCityCT) {
    if (cancelTokens[config.cityCityCT]) cancelTokens[config.cityCityCT]()
    delete cancelTokens[config.cityCityCT]
    config.cancelToken = new axios.CancelToken((c) => {
      cancelTokens[config.cityCityCT] = c
    })
  }
  config.auth = false
  const headers = await getRqHeaders(authNeeded)
  for (let headKey in headers) {
    config.headers[headKey] = headers[headKey]
  }
  return config
})

agent.interceptors.response.use((response) => {
  if (response.config.cityCityCT && cancelTokens[response.config.cityCityCT]) {
    delete cancelTokens[response.config.cityCityCT]
  }
  return response
})

export default agent
