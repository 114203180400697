import React, { memo } from 'react'
import Rating from '@material-ui/lab/Rating'
import { makeStyles } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'
import { STARS } from '../../constants/hotelRoomCodes'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
}))

const StarsRating = ({ stars }) => {
  const classes = useStyles()
  if (!stars) return null
  const star = typeof stars === 'string' ? STARS[stars] : stars

  return (
    <Card elevation={0} className={classes.container}>
      <Rating value={star} readOnly size="small" />
    </Card>
  )
}

export default memo(StarsRating)
