import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { switchCompanyAction } from '../../store/auth/authAction'
import { resetSearchTravellers } from '../../store/search/searchAction'

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    fontWeight: 600,
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    maxWidth: '150px',

    '& .MuiButton-label': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
      display: 'block',
    },
  },
}))

const CompanyChooser = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const { companyName, selectableCompanies } = useSelector(
    (state) => state.auth.user
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeCompany = async (companyId) => {
    setAnchorEl(null)
    dispatch(resetSearchTravellers())
    await dispatch(switchCompanyAction(companyId))
    //history.push('/')
  }

  // If we have no companies to select from, return.
  if (selectableCompanies?.length < 2) {
    return null
  }

  return (
    <>
      <Button
        onClick={handleClick}
        className={classes.iconBtn}
        id="company-button"
        aria-controls={isMenuOpen ? 'company-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
      >
        <span>{companyName}</span>
      </Button>

      <Menu
        id="company-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {selectableCompanies.map((company) => (
          <MenuItem
            onClick={() => handleChangeCompany(company.uniqueId)}
            key={company.uniqueId}
          >
            {company.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default memo(CompanyChooser)
