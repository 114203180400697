import React, { memo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getPrices } from '../../../../utils/railPricesCalculations'
import usePriceFormat from '../../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const TrainMiddleStepMobile = ({ model, isOutgoing }) => {
  const classes = useStyles()
  const { guests } = useParams()
  const { t } = useTranslation()
  const { currency } = useSelector((state) => state.auth)
  const { selectedOutGoingTrip, selectedReturnTrip, chargeableSeats } =
    useSelector((state) => state.train)
  const { train } = model
  const date = train?.scheduleSolution?.railstart?.dateTime
  const { formatPrice } = usePriceFormat()

  const price = getPrices({
    selectedOutGoingTrip,
    selectedReturnTrip,
    chargeableSeats,
  })

  const text = isOutgoing ? (
    <Typography align="right" className={classes.bold}>
      {formatPrice(price, { prependCurrency: true })}
    </Typography>
  ) : (
    <Typography align="right">
      {guests} {t('detail adults')}
    </Typography>
  )

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography>
          {isOutgoing ? t('detail outbound') : t('detail return')}
        </Typography>
      </Grid>

      <Grid item xs>
        <Typography align="center">
          <Moment format="ddd D MMM">{date}</Moment>
        </Typography>
      </Grid>
      <Grid item xs>
        {text}
      </Grid>
    </Grid>
  )
}

export default memo(TrainMiddleStepMobile)
