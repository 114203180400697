import {HIDE_SNACKBAR, SHOW_SNACKBAR} from "./constants";

export const showSnackbarAction = (snackbarType, message, position = null) => ({
  type: SHOW_SNACKBAR,
  snackbarType,
  message,
  position
})

export const hideSnackbarAction = () => ({
  type: HIDE_SNACKBAR
})
