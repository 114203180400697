import _ from 'lodash'
import { getSeatsPricing } from './_getSeatsPricing'
import { formatByRowPricing } from './_formatByRowPricing'

export const parseSeatsRes = (resSeats) => {
  if (_.isEmpty(resSeats) || _.isEmpty(resSeats.seatmapInformation)) {
    return null
  }

  const seats = resSeats.seatmapInformation

  if (_.isEmpty(seats.row) || _.isEmpty(seats.cabin)) {
    return null
  }

  const cabin = _.isArray(seats.cabin) ? seats.cabin[0] : seats.cabin

  if (
    _.isEmpty(cabin.compartmentDetails) ||
    _.isEmpty(cabin.compartmentDetails.columnDetails)
  ) {
    return null
  }

  const cols = []
  let prevCol = false
  const prices = getSeatsPricing(resSeats)

  cabin.compartmentDetails.columnDetails.forEach((col) => {
    if (!_.isArray(col.description)) {
      col.description = [col.description]
    }

    if (
      prevCol &&
      col.description.indexOf('A') >= 0 &&
      prevCol.description.indexOf('A') >= 0
    ) {
      cols.push('')
    }
    cols.push(col.seatColumn)
    prevCol = col
  })

  if (!_.isArray(seats.row)) {
    seats.row = [seats.row]
  }

  const resultSeats = {}
  let firstRow = 0
  let lastRow = 0
  const rowDescr = {}
  const notExistsSeats = []
  let isFreeExists = false

  seats.row.forEach((row) => {
    if (_.isEmpty(row.rowDetails)) {
      return false
    }

    const rowNum = row.rowDetails.seatRowNumber

    if (
      !_.isEmpty(row.rowDetails.rowCharacteristicDetails) &&
      typeof row.rowDetails.rowCharacteristicDetails.rowCharacteristic !==
        'undefined'
    ) {
      rowDescr[rowNum] = _.isArray(
        row.rowDetails.rowCharacteristicDetails.rowCharacteristic
      )
        ? row.rowDetails.rowCharacteristicDetails.rowCharacteristic
        : [row.rowDetails.rowCharacteristicDetails.rowCharacteristic]
    }

    if (_.isEmpty(row.rowDetails.seatOccupationDetails)) {
      return false
    }
    const rowSeats = _.isArray(row.rowDetails.seatOccupationDetails)
      ? row.rowDetails.seatOccupationDetails
      : [row.rowDetails.seatOccupationDetails]

    if (firstRow === 0) {
      firstRow = parseInt(rowNum)
    }

    lastRow = parseInt(rowNum)

    rowSeats.forEach((rowSeat) => {
      let seatInfo = []
      const noRowInf = [
        '8',
        'CL',
        'ST',
        'SO',
        'LG',
        'LA',
        'GN',
        'EX',
        'D',
        'BA',
        'AR',
        '701',
      ]
      const seatIsBusy = ['1', 'V']
      if (typeof rowSeat.seatCharacteristic !== 'undefined') {
        seatInfo = _.isArray(rowSeat.seatCharacteristic)
          ? rowSeat.seatCharacteristic
          : [rowSeat.seatCharacteristic]
      }

      const seatKey = rowNum.toString() + rowSeat.seatColumn
      let seatPrice = 0
      if (typeof prices.bySeats[seatKey] === 'undefined') {
        seatInfo.push('1')
      } else {
        seatPrice = prices.bySeats[seatKey]
      }
      const isNoSeat = _.intersection(seatInfo, noRowInf)
      const isBusy = _.intersection(seatInfo, seatIsBusy)
      if (!_.isEmpty(isNoSeat)) {
        notExistsSeats.push(seatKey)
        return false
      }

      if (seatInfo.indexOf('E') >= 0 && !rowDescr[rowNum]) {
        rowDescr[rowNum] = ['E']
      } else if (
        seatInfo.indexOf('E') >= 0 &&
        rowDescr[rowNum].indexOf('E') < 0
      ) {
        rowDescr[rowNum].push('E')
      }

      const isFree =
        _.isEmpty(isBusy) &&
        (!_.isString(rowSeat.seatOccupation) || rowSeat.seatOccupation === 'F')

      if (!isFreeExists && isFree) {
        isFreeExists = true
      }

      resultSeats[seatKey] = {
        isFree: isFree,
        seatInfo: seatInfo,
        price: seatPrice,
        selected: false,
      }
    })
  })

  const rPricing = formatByRowPricing(prices.byRows, cols, notExistsSeats)

  return {
    rows: {
      first: firstRow,
      last: lastRow,
    },
    cols: cols,
    seats: resultSeats,
    rPricing: rPricing,
    rowDescr: rowDescr,
    isFreeExists: isFreeExists,
  }
}
