import _ from 'lodash'
import i18n from './i18n'

export const getFFBags = (
  ticketClass,
  selectedOutboundTrip,
  selectedReturnTrip,
  original = null
) => {
  const bgSrv = {
    outbound: [],
    return: [],
    general: [],
  }

  if (ticketClass?.isMixFly && selectedOutboundTrip && selectedReturnTrip) {
    const { outboundFlightKeys, returnFlightKeys } = getFlightKeysByDirections(
      selectedOutboundTrip,
      selectedReturnTrip
    )
    //can be null if no FF (standart)
    const flights = ticketClass ? ticketClass.byFlights : null
    if (flights) {
      const outboundFlights = _.pickBy(flights, (flight, aircraftID) =>
        outboundFlightKeys.includes(aircraftID)
      )

      const returnFlights = _.pickBy(flights, (flight, aircraftID) =>
        returnFlightKeys.includes(aircraftID)
      )

      const outboundLeastBaggageConditions = chooseLeastBaggage(
        outboundFlights,
        'test test'
      )

      //can be emptyArray if oneway
      const returnLeastBaggageConditions = chooseLeastBaggage(returnFlights)

      bgSrv['outbound'] = outboundLeastBaggageConditions
      bgSrv['return'] = returnLeastBaggageConditions
    }
  } else {
    const srvsToShow = ['BAG', 'HBAG', 'UBAG', 'LBAG']
    if (!_.isEmpty(ticketClass?.ff?.[0]?.ffServ)) {
      ticketClass.ff[0].ffServ.forEach((srv) => {
        if (srvsToShow.includes(srv.type)) {
          bgSrv['general'].push(srv.text)
        }
      })
    }

    const trip = original
      ? original
      : !_.isEmpty(selectedReturnTrip)
      ? selectedReturnTrip.original
      : !_.isEmpty(selectedOutboundTrip)
      ? selectedOutboundTrip.original
      : null
    if (_.isEmpty(bgSrv['general'])) {
      bgSrv['general'].push(i18n.t('HBAG'))

      if (!_.isEmpty(trip) && !_.isEmpty(trip.FBA)) {
        bgSrv['general'].push(i18n.t(trip.FBA.text))
      }
    }
  }

  return bgSrv
}

export const chooseLeastBaggage = (flights, dbKey = null) => {
  const result = []
  const counter = {}

  const codes = {
    '0L5': {
      type: 'HBAG',
      points: 2,
    },
    '0LN': {
      type: 'HBAG',
      points: 3,
    },
    '0MM': {
      type: 'HBAG',
      points: 4,
    },
    '0MU': {
      type: 'HBAG',
      points: 5,
    },
    '0M3': {
      type: 'HBAG',
      points: 6,
    },
    CBY: {
      type: 'HBAG',
      points: 7,
    },
    '0MQ': {
      type: 'HBAG',
      points: 8,
    },
    '08A': {
      type: 'HBAG',
      points: 9,
    },
    '0MR': {
      type: 'HBAG',
      points: 10,
    },
    '0MY': {
      type: 'HBAG',
      points: 11,
    },
    '0KX': {
      type: 'HBAG',
      points: 12,
    },
    '0MO': {
      type: 'HBAG',
      points: 13,
    },
    CBC: {
      type: 'HBAG',
      points: 14,
    },
    C16: {
      type: 'HBAG',
      points: 15,
    },
    '0M1': {
      type: 'HBAG',
      points: 16,
    },
    PSL: {
      type: 'HBAG',
      points: 17,
    },
    '04J': {
      type: 'HBAG',
      points: 18,
    },
    '077': {
      type: 'HBAG',
      points: 19,
    },
    CBU: {
      type: 'HBAG',
      points: 20,
    },
    CB1_D8: {
      type: 'HBAG',
      points: 21,
    },
    CB1_DY: {
      type: 'HBAG',
      points: 22,
    },
    CB1: {
      type: 'HBAG',
      points: 23,
    },
    '0C2': {
      type: 'BAG',
      points: 2,
    },
    '0IJ': {
      type: 'BAG',
      points: 3,
    },
    '0CC': {
      type: 'BAG',
      points: 4,
    },
    CBG: {
      type: 'BAG',
      points: 5,
    },
    '0C3': {
      type: 'BAG',
      points: 6,
    },
    '0C4': {
      type: 'BAG',
      points: 7,
    },
    '0CCY': {
      type: 'BAG',
      points: 8,
    },
    C15: {
      type: 'BAG',
      points: 9,
    },
    FBY: {
      type: 'BAG',
      points: 10,
    },
    '0GO': {
      type: 'BAG',
      points: 11,
    },
    A30: {
      type: 'BAG',
      points: 12,
    },
    '0C6': {
      type: 'BAG',
      points: 13,
    },
    '0FM': {
      type: 'BAG',
      points: 14,
    },
    '0C7': {
      type: 'BAG',
      points: 15,
    },
    A35: {
      type: 'BAG',
      points: 16,
    },
    '0C5': {
      type: 'BAG',
      points: 17,
    },
    C18: {
      type: 'BAG',
      points: 18,
    },
    FBC: {
      type: 'BAG',
      points: 19,
    },
  }

  if (!_.isEmpty(flights)) {
    Object.keys(flights).forEach((aircraftID, i) => {
      const flight = flights[aircraftID]
      counter[aircraftID] = {
        HBAG: {
          points: 0,
          BagServ: [],
        },
        BAG: {
          points: 0,
          BagServ: [],
        },
      }

      const inclCodes = [
        ...(flight?.serviceCodes?.INC?.BG || []),
        ...(flight?.serviceCodes?.INC?.BF || []),
      ]

      if (inclCodes?.length > 0) {
        inclCodes.forEach((code, i) => {
          if (!codes[code]) return

          const type = codes[code].type
          counter[aircraftID][type].points = +codes[code].points
          flight.ffServ.forEach((serv, i) => {
            if (
              serv.code === code &&
              !counter[aircraftID][type].BagServ.includes(serv.text)
            ) {
              counter[aircraftID][type].BagServ.push(serv.text)
            }
          })
        })
      }
    })

    let minHBAG = Math.min.apply(
      null,
      Object.keys(counter).map((aircraftID) =>
        counter[aircraftID]['HBAG'].points > 0
          ? counter[aircraftID]['HBAG'].points
          : 9999
      )
    )

    if (minHBAG === 9999) minHBAG = 0
    const minHBAGObj =
      counter[
        Object.keys(
          _.pickBy(
            counter,
            (k, aircraftID) => counter[aircraftID]['HBAG'].points === minHBAG
          )
        )[0]
      ]

    let minBAG = Math.min.apply(
      null,
      Object.keys(counter).map((aircraftID) =>
        counter[aircraftID]['BAG'].points > 0
          ? counter[aircraftID]['BAG'].points
          : 9999
      )
    )

    if (minBAG === 9999) minBAG = 0
    const minBAGObj =
      counter[
        Object.keys(
          _.pickBy(
            counter,
            (k, aircraftID) => counter[aircraftID]['BAG'].points === minBAG
          )
        )[0]
      ]
    minHBAGObj['HBAG']['BagServ'].map((text) => result.push(text))
    minBAGObj['BAG']['BagServ'].map((text) => result.push(text))
  }
  return result
}

export const getFlightKeysByDirections = (
  selectedOutboundTrip,
  selectedReturnTrip
) => {
  const outboundFlightKeys = selectedOutboundTrip
    ? selectedOutboundTrip.original.trip.map((trip) => trip.uniqueInd)
    : []
  const returnFlightKeys = selectedReturnTrip?.original?.returnTrip
    ? selectedReturnTrip.original.returnTrip.map((trip) => trip.uniqueInd)
    : []
  return {
    outboundFlightKeys,
    returnFlightKeys,
  }
}

export const getTicketPriceParam_New = (ticketClass, flightKey, param) => {
  let paramValue = null

  if (
    !_.isEmpty(ticketClass) &&
    !_.isEmpty(ticketClass.fullData) &&
    !_.isEmpty(ticketClass.fullData.byFlights[flightKey]) &&
    typeof ticketClass.fullData.byFlights[flightKey][param] !== 'undefined'
  ) {
    paramValue = ticketClass.fullData.byFlights[flightKey][param]
  }

  return paramValue
}

export const getTicketPriceParam = (query, flightKey, param) => {
  const { ticketClass } = query

  let paramValue = null

  if (
    !_.isEmpty(ticketClass) &&
    !_.isEmpty(ticketClass.fullData) &&
    !_.isEmpty(ticketClass.fullData.byFlights[flightKey]) &&
    typeof ticketClass.fullData.byFlights[flightKey][param] !== 'undefined'
  ) {
    paramValue = ticketClass.fullData.byFlights[flightKey][param]
  }

  return paramValue
}

export const getFlightBookingClass = (query, flightKey) => {
  const { ticketClass } = query

  let bookingClass = null

  if (
    !_.isEmpty(ticketClass) &&
    !_.isEmpty(ticketClass.fullData) &&
    !_.isEmpty(ticketClass.fullData.byFlights[flightKey]) &&
    typeof ticketClass.fullData.byFlights[flightKey]['bookingClass'] !==
      'undefined'
  ) {
    bookingClass = ticketClass.fullData.byFlights[flightKey]['bookingClass']
  }

  return bookingClass
}

export const seatsInFF = (query) => {
  const { ticketClass } = query

  if (_.isEmpty(ticketClass) || _.isEmpty(ticketClass.fullData)) {
    return true
  }
  const tmpFF = ticketClass.fullData
  if (tmpFF['ff']) {
    return typeof tmpFF['ff']['seatsSelection'] !== 'undefined'
      ? tmpFF['ff']['seatsSelection']
      : true
  } else if (!_.isEmpty(tmpFF['byFlights'])) {
    for (const flNum in tmpFF['byFlights']) {
      const fl = tmpFF['byFlights'][flNum]
      if (typeof fl['seatsSelection'] !== 'undefined' && fl['seatsSelection']) {
        return true
      }
    }

    return false
  }

  return true
}

export const getSeatsPrice = (query) => {
  const { selectedSeats } = query

  const result = {
    count: 0,
    price: 0,
  }

  if (!seatsInFF(query)) {
    return result
  }

  if (_.isEmpty(query) || !selectedSeats || _.isEmpty(selectedSeats)) {
    return result
  }

  for (const ind in selectedSeats) {
    // Not Used
    //const flightNum = ind.split('_')[1]
    for (const i in selectedSeats[ind]) {
      result.count++

      if (selectedSeats[ind][i].price) {
        result.price += parseFloat(selectedSeats[ind][i].price)
      }
    }
  }

  return result
}

export const getSeatsForFlight = (
  query,
  flight,
  passengers,
  dir = 'outbound'
) => {
  if (_.isEmpty(flight) || typeof flight.uniqueInd === 'undefined') {
    return null
  }

  const { selectedSeats } = query

  if (!seatsInFF(query)) {
    return null
  }

  const key = dir + '_' + flight.uniqueInd.toString()

  if (_.isEmpty(selectedSeats[key])) {
    return null
  }
  const results = {}
  let seatsFound = false

  if (_.isEmpty(passengers)) {
    return null
  }

  passengers.forEach((user) => {
    if (typeof selectedSeats[key][user.locator] !== 'undefined') {
      const seat =
        selectedSeats[key][user.locator]['seat'] !== 'undefined'
          ? selectedSeats[key][user.locator]['seat']
          : null
      const seatInfo =
        selectedSeats[key][user.locator]['seatInfo'] !== 'undefined'
          ? selectedSeats[key][user.locator]['seatInfo']
          : null
      const price =
        selectedSeats[key][user.locator]['price'] !== 'undefined'
          ? selectedSeats[key][user.locator]['price']
          : null
      results[user.ind] = {
        seatName: seat,
        seatInfo: seatInfo,
        seatPrice: price,
      }
      seatsFound = true
    }
  })
  return seatsFound ? results : null
}

/**
 * calculate flight prices
 * @param flightData
 * @param flights
 * @returns {{fare: number, total: number, initTotal: number, farePerUser: number, bags: number, returnTotal: number, seats: number, bagsByType: null|{}, outboundTotal: number}}
 */
export const calculatePrice = (flightData, flights) => {
  const { currentFare, returnFare, selectedBaggage, passengerSeats } =
    flightData
  const { selectedMulti, returning } = flights
  const isMultiTicket = returning?.original?.isMultiTicket
  const initPrices = calculateInitialPrices(flights)

  const prices = {
    bags: 0,
    bagsByType: null,
    fare: 0,
    farePerUser: 0,
    total: 0,
    seats: 0,
    initTotal: 0,
    outboundTotal: 0,
    returnTotal: 0,
  }

  const bagsTypeKeys = ['general', 'carryOn']
  const bagDirTypes = []

  //bags price
  if (selectedBaggage.outbound) bagDirTypes.push('outbound')
  if (isMultiTicket && selectedBaggage.return) bagDirTypes.push('return')

  bagDirTypes.forEach((bagDir) => {
    const dirBagData = selectedBaggage?.[bagDir]
    if (!dirBagData) return false
    for (let boKey in dirBagData) {
      const oTmpBag = dirBagData[boKey]
      if (!oTmpBag) continue

      bagsTypeKeys.forEach((bagType) => {
        const singleBag = oTmpBag?.[bagType]
        if (!singleBag?.price) return false
        if (!prices.bagsByType) prices.bagsByType = {}
        if (!prices.bagsByType?.[bagType]) prices.bagsByType[bagType] = 0

        prices.bagsByType[bagType] += singleBag.price
        prices.bags += singleBag.price

        if (bagDir === 'outbound') {
          prices.outboundTotal += singleBag.price
        } else {
          prices.returnTotal += singleBag.price
        }
      })
    }
  })

  // fare price
  const farePrices = []
  prices.initTotal = initPrices.outbound

  if (!isMultiTicket) {
    const outboundFarePrice = currentFare
      ? currentFare?.totalPrice
      : initPrices.outbound

    farePrices.push(outboundFarePrice)
    prices.outboundTotal += outboundFarePrice
  } else {
    prices.initTotal += initPrices.return
    const outboundFarePrice = currentFare
      ? currentFare?.totalPrice
      : initPrices.outbound
    const returnFarePrice = returnFare
      ? returnFare?.totalPrice
      : initPrices.return

    farePrices.push(outboundFarePrice)
    farePrices.push(returnFarePrice)
    prices.returnTotal += outboundFarePrice
    prices.returnTotal += returnFarePrice
  }

  prices.seats = passengerSeats
    ? passengerSeats.map((x) => x.price).reduce((a, b) => a + b, 0)
    : 0

  farePrices.forEach((farePrice) => {
    prices.fare += farePrice
    prices.farePerUser += farePrice
  })
  prices.total = prices.bags + prices.fare + prices.seats

  return prices
}

/**
 * get flights initial prices
 *
 * @param flights
 * @returns {{outbound: (number), isMTK: boolean, return: (number)}}
 */
export const calculateInitialPrices = (flights) => {
  const { outgoing, returning, selectedMulti } = flights
  const isMultiTicket = returning?.original?.isMultiTicket

  if (Object.values(selectedMulti).length > 0) {
    return {
      isMTK: false,
      isMultiStop: true,
      multistop: selectedMulti[0].price,
    }
  } else if (isMultiTicket) {
    return {
      isMTK: true,
      outbound: returning.original.outboundPrice || 0,
      return: returning.original.inboundPrice || 0,
    }
  } else if (returning) {
    return {
      isMTK: false,
      outbound: returning.price || 0,
      return: 0,
    }
  } else {
    return {
      isMTK: false,
      outbound: outgoing.price || 0,
      return: 0,
    }
  }
}

export const hotelCoords = (iataObj) => {
  if (iataObj) {
    let { lat, lng, 'Hotel Coordinates': hotelCoordinates } = iataObj
    if (hotelCoordinates) {
      let [hotelLat, hotelLng] = hotelCoordinates
        .split(',')
        .map((s) => Number(s.trim()))
      lat = hotelLat
      lng = hotelLng
    }

    return { lat, lng }
  }
  return null
}
