import React, { memo } from 'react'
import { Box, Typography, Button, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ModalHook from './reusable/modalHook'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      flex: 1,
      marginBottom: '63px'
    },
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',

    '& .MuiIconButton-root': {
      padding: '28px 28px 11px 0'
    }
  },
  headerText: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '33px'
  },
  btns: {
    borderRadius: theme.spacing(3),
    margin: '0 4px',
    padding: theme.spacing('10px', 4),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing('10px', 2),
    },
  },
}))

const LoyaltyRemoveConfirmModal = ({ rmData, onConfirm, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const closeModal = () => {
    onClose()
  }

  const confirm = () => {
    onConfirm(rmData)
  }

  const buildDefaultContent = () => {
    return (
      <Box px={isMobile ? '30px' : '70px'} pb={!isMobile ? '28px' : 0} className={classes.root}>
        <Box>
          <Typography
            gutterBottom
            className={classes.headerText}
            variant="h4"
            align="center"
          >
            {t('confirm loyalty removal')}
          </Typography>
          <Typography align="center" className={classes.confirmText}>
            {t('confirm loyalty removal descr', {name: rmData.name})}
          </Typography>
        </Box>
        <Box mt={'31px'}>
          <Button
            className={classes.btns}
            color="primary"
            variant="outlined"
            onClick={closeModal}
          >
            {t('no')}
          </Button>
          <Button
            disableElevation
            className={classes.btns}
            color="primary"
            variant="contained"
            onClick={confirm}
          >
            {t('exact del')}
          </Button>
        </Box>
      </Box>
    )
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ModalHook
      minHeight={300}
      width={550}
      display={isMobile ? 'flex' : null}
      modalBoolean={!!rmData}
      mobileFullWidth={false}
    >
      <Box className={classes.header}>
        <IconButton
          color="primary"
          className={classes.closeBtn}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {buildDefaultContent()}
    </ModalHook>
  )
}

export default memo(LoyaltyRemoveConfirmModal)
