import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FlightIcon from '@material-ui/icons/Flight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { makeStyles } from '@material-ui/core/styles'
import GetCompanyIconElement from '../../reusable/getCompanyIcon'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Train } from '@material-ui/icons'
import {
  setMultiFlight,
  setOutgoingFlight,
  setReturningFlight,
} from '../../../store/flights/searchAction'
import numberWithCommas from '../../../utils/numberWithCommas'
import { flightIsSameDir } from '../../../utils/general'
import { useTranslation } from 'react-i18next'
import Policy from '../../../components/reusable/tags/policy'
import limitChars from '../../../utils/limitChars'
import { POLICY_RESULT } from '../../../constants/policy'
import {
  openPolicyExceededModal,
  openSimpleInfoModal,
} from '../../../store/modals/modalAction'
import COMPANIES from '../../../constants/flightCompanies'
import countries from '../../../utils/i18nCountries'
import usePriceFormat from '../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  bottomLineLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomLineRight: {
    maxWidth: '150px',
    display: 'flex',
    alignItems: 'center',
  },
  daysIndicator: {
    fontSize: '16px',
    color: '#FF4700',
    fontWeight: 600,
    letterSpacing: 1.1,
    position: 'relative',
    top: '-5px',
    left: '5px',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btn: {
    borderRadius: theme.spacing(3),
    width: '190px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
  },
  bottomLine: {
    paddingBottom: 0,
    paddingTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  tripText: {
    marginRight: theme.spacing(2),
  },
  line: {
    borderRadius: theme.spacing(1),
    minWidth: '34px',
    height: '1.5px',
    backgroundColor: theme.palette.border,
    width: '100%',
  },
  mobileLine: {
    borderRadius: theme.spacing(1),
    minWidth: '6px',
    height: '1.5px',
    backgroundColor: theme.palette.border,
    width: '100%',
    marginLeft: '4px',
    marginRight: '4px',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  flightIcon: {
    color: theme.palette.border,
    transform: 'rotate(90deg)',
  },
  trainIcon: {
    color: theme.palette.border,
  },
  fontEighteen: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold,
  },
  maximizeButton: {
    position: 'absolute',
    top: '6px',
    right: '-30px',
  },
  flightAndTrainLabel: {
    color: 'black',
    backgroundColor: '#ff9a50',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(1.5),
  },
  cheapestLabel: {
    backgroundColor: '#acffb1',
    color: 'black',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(3),
    fontSize: theme.spacing(1.5),
  },
}))

const MinimizedCard = ({ flight, minimumPrice, expanded }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t, i18n } = useTranslation()
  const { url } = useRouteMatch()
  const history = useHistory()
  const params = useParams()
  const classes = useStyles()
  const dispatch = useDispatch()
  const flightState = useSelector((state) => state.flights)
  const { outgoing, flightAgencyFilters } = flightState
  const isSameDir =
    flight?.group === 'returnTrip' && outgoing
      ? flightIsSameDir(outgoing, flight)
      : null
  const { isAuthenticated } = useSelector((state) => state.auth)
  const { formatPrice } = usePriceFormat()

  let differOpeartors = []
  flight.via.forEach((f) => {
    const title = COMPANIES?.[f.operatingCarrier]?.label || f.operatingCarrier
    if (
      title &&
      !differOpeartors.includes(title) &&
      f.operatingCarrier !== flight.company
    ) {
      differOpeartors.push(title)
    }
  })

  const isPartlyOperated = flight.company === flight.operatingCarrier
  const { exceedsPolicy, allowPolicyReasons, isForbiddenByAgency } = flight
  const isPolicyEx =
    flight.isDealFare || flight.isCorporateFare || exceedsPolicy
  const isPolAndOperated = isPolicyEx && differOpeartors.length

  const selectFlight = () => {
    const { isOneWay, tripNumber, from } = params

    // Multistop
    if (tripNumber) {
      dispatch(setMultiFlight(flight, tripNumber))

      let numStops = from.split('-').length
      if (tripNumber == numStops - 1) {
        // Last trip selected

        const uri = isAuthenticated
          ? '/flights/details'
          : '/flights/add-public-data'
        return history.push(uri)
      } else {
        // Intermediate trip selected
        const uri = `/flights/multi/${params.from}/${params.to}/${params.dep}/${
          params.passengers
        }/${params.initialTicketClass}/${parseInt(tripNumber) + 1}`
        return history.push(uri)
      }
    }

    // Checks if one way ticket
    if (parseInt(isOneWay) === 0) {
      const uri = isAuthenticated
        ? '/flights/details'
        : '/flights/add-public-data'
      dispatch(setOutgoingFlight(flight))
      return history.push(uri)
    }

    const toDetails = url.includes('returning')
    const returnUrl = `/flights/returning/${params.from}/${params.to}/${params.dep}/${params.arr}/${params.adult}/${params.child}/${params.infant}/${params.initialTicketClass}`
    const detailsUrl = isAuthenticated
      ? '/flights/details'
      : '/flights/add-public-data'
    const uri = toDetails ? detailsUrl : returnUrl
    !toDetails
      ? dispatch(setOutgoingFlight(flight))
      : dispatch(setReturningFlight(flight))

    history.push(uri)
  }

  const handleSelectFlight = (ev) => {
    ev.stopPropagation()

    if (!!isForbiddenByAgency) {
      const langMatch = (i18n.language || '').match(/(\w{2})-(\w{2})/i)
      const lang = langMatch?.[1] || i18n.language
      return dispatch(
        openSimpleInfoModal({
          title: t('blocked by agency'),
          text: t('blocked by agency text', {
            countries: flightAgencyFilters?.outboundCountries
              ?.map((code) => countries.getName(code, lang))
              ?.join(', '),
          }),
        })
      )
    }

    if (exceedsPolicy === POLICY_RESULT.BLOCK) {
      return dispatch(
        openPolicyExceededModal({
          allowReasons: allowPolicyReasons,
          clickHandler: selectFlight,
        })
      )
    }

    selectFlight()
  }

  const constructOperated = () => {
    let tmpOperators = [...differOpeartors]
    const first = tmpOperators.shift()
    return !first
      ? ''
      : `${first} ${
          tmpOperators.length ? t('and') + ' ' + tmpOperators.join(', ') : ''
        }`
  }

  const isReturning = url.includes('returning')

  const currentPrice = flight?.price
  const rawPrice =
    isReturning && outgoing ? currentPrice - minimumPrice : currentPrice
  const displayPrice = formatPrice(rawPrice, { prependCurrency: true })

  // const isDirect =
  //   flight.via.length <= 1
  //     ? t('filter directly')
  //     : `${flight.via.length - 1} ${t('changes')}`

  const isDirect =
    flight.via.length <= 1
      ? t('filter directly')
      : flight.via.length - 1 > 1
      ? `${flight.via.length - 1} ${t('changes')}`
      : `${flight.via.length - 1} ${t('change')}`

  const isReturnTrip =
    params.arr === '0' ? t('detail one way') : t('detail mob short return')

  const { co2 } = flight

  /*
  const formatFreeBagsString = (FBA) => {
    const { unit, quantity } = FBA
    const units = {
      N: 'bag(s)',
      K: 'kg',
      L: 'lb',
    }
    if (!units[unit]) return null

    if(FBA.unit == 'N') {
      return t('free bags included', { quantity })
      return `${FBA.quantity} ${units[FBA.unit]}`
    } else {
      return `${FBA.quantity} ${units[FBA.unit]}`
    }
  }
  */

  const freeBagsString = flight.FBA ? t(flight.FBA.text) : null

  const hasTrain = flight.via.find((via) => via.isTrain)
  const numPassengers =
    parseInt(params.adult) + parseInt(params.child) + parseInt(params.infant)

  return (
    <Box className={classes.column}>
      {flight.original.isMultiTicket && <Box>MultiTicket</Box>}
      <Box pb={1}>
        {flight.cheapestLabel && (
          <Box mr={1} display="inline-flex">
            <Typography component={'span'} className={classes.cheapestLabel}>
              {flight.cheapestLabel}
            </Typography>
          </Box>
        )}

        {hasTrain && (
          <Box mr={0} display="inline-flex">
            <Typography
              component={'span'}
              className={classes.flightAndTrainLabel}
            >
              {t('flight and train')}
            </Typography>
          </Box>
        )}

        <Button
          className={classes.maximizeButton}
          color="primary"
          startIcon={
            expanded ? (
              <KeyboardArrowUpIcon
                style={{ fontSize: '24px' }}
                color="primary"
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ fontSize: '24px' }}
                color="primary"
              />
            )
          }
        />
      </Box>
      <Grid container alignItems="flex-start">
        <Grid item xs={'auto'} md={'auto'}>
          <Typography variant="subtitle1" className={classes.fontEighteen}>
            {flight.departTime}
          </Typography>
          <Typography
            variant="body2"
            style={{
              position: 'absolute',
              backgroundColor:
                !isSameDir || isSameDir.isFrom ? 'transparent' : '#fff5e0',
              borderRadius: '3px',
              padding:
                !isSameDir || isSameDir.isFrom ? '1px 1px 0px 0px' : '1px 2px',
            }}
          >
            {limitChars(flight.from.city)} ({flight.from.shortName})
          </Typography>
        </Grid>

        {/* LINE */}
        <Grid item xs={3} md={3}>
          <Box className={classes.align}>
            <Box
              className={isMobile ? classes.mobileLine : classes.line}
              ml={1}
              pr={1}
              pl={1}
              mr={1}
            />
            <FlightIcon color="action" className={classes.flightIcon} />
            {hasTrain && <Train color="action" className={classes.trainIcon} />}
            <Box
              className={isMobile ? classes.mobileLine : classes.line}
              ml={1}
              pr={1}
              pl={1}
              mr={1}
            />
          </Box>
        </Grid>
        {/* LINE-END */}

        <Grid item xs md={3} style={{ paddingLeft: '8px' }}>
          <Typography
            display="inline"
            variant="subtitle1"
            className={classes.fontEighteen}
          >
            {flight.arriveTime}
          </Typography>

          {flight.days > 0 && (
            <Typography
              display="inline"
              color="primary"
              className={classes.daysIndicator}
            >
              +{flight.days}
            </Typography>
          )}

          <Typography
            variant="body2"
            style={{
              position: 'absolute',
              backgroundColor:
                !isSameDir || isSameDir.isTo ? 'transparent' : '#fff5e0',
              borderRadius: '3px',
              padding:
                !isSameDir || isSameDir.isTo ? '1px 1px 0px 0px' : '1px 2px',
            }}
          >
            {limitChars(flight.to.city)} ({flight.to.shortName})
          </Typography>
        </Grid>

        <Grid item xs={'auto'} md>
          <Typography
            style={{ marginLeft: '32px' }}
            align={isMobile ? 'left' : 'center'}
            variant="body2"
            className={classes.fontEighteen}
          >
            {flight.formattedTravelTime}
          </Typography>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={5} md={4}>
          <Typography variant="body2"></Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2"></Typography>
        </Grid>
        <Grid item xs={'auto'} md>
          <Typography align="center" variant="body2">
            {isDirect}
          </Typography>
        </Grid>
      </Grid>

      <Box className={classes.spaceBetween} pt={2}>
        <GetCompanyIconElement small={isMobile} company={flight.company} />

        <Box pt={1} pb={1}>
          <Button
            disableElevation
            onClick={handleSelectFlight}
            color="primary"
            variant="contained"
            className={classes.btn}
          >
            <>
              {isReturning || rawPrice < 0 || isNaN(rawPrice) ? '+' : null}{' '}
              {numberWithCommas(displayPrice)}
            </>
          </Button>
        </Box>
      </Box>

      <Grid className={classes.bottomLine} container>
        <Grid
          style={{ marginBottom: isMobile && isPolAndOperated ? '12px' : 0 }}
          className={classes.bottomLineLeft}
          xs={isPolAndOperated ? '12' : true}
          md
        >
          <Box flex={'1'}>
            {!!differOpeartors.length && (
              <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                {isPartlyOperated ? t('partly operated by') : t('operated by')}{' '}
                {constructOperated()}
              </Typography>
            )}
          </Box>
          <Box mr={isMobile && isPolAndOperated ? 0 : 2}>
            <Policy
              policy={exceedsPolicy}
              deal={flight.isDealFare}
              corporate={flight.isCorporateFare}
            />
          </Box>
        </Grid>
        <Grid className={classes.bottomLineRight}>
          <Box
            mr={-2}
            display="flex"
            alignItems="center"
            flexWrap={'wrap'}
            justifyContent="flex-end"
          >
            <Typography
              className={classes.tripText}
              variant="body2"
              align="right"
            >
              {numPassengers} {t('filter pers')}, {isReturnTrip}
              {co2 ? (
                <Box>
                  {co2}kg CO<sub>2</sub>
                </Box>
              ) : null}
              {freeBagsString}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MinimizedCard
