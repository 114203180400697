import React, { memo } from 'react'
import { Box, Button, useTheme } from '@material-ui/core'
import LoginBlock from '../../components/reusable/loginBlock'
import { useSelector } from 'react-redux'
import { initiateOidcLogin } from '../../utils/oidc/oidcHandler'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const { onlyOidc } = useSelector((state) => state.other)
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box>
      {onlyOidc ? (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            justifyItems: 'center',
          }}
        >
          <Button
            disableElevation
            disableRipple
            variant="contained"
            color="primary"
            onClick={() => initiateOidcLogin('login', '/')}
            style={{
              btn: {
                borderRadius: theme.spacing(4),
                fontWeight: theme.typography.fontWeightBold,
                fontSize: '18px',
                lineHeight: '29px',
                padding: theme.spacing(2, 3),
                letterSpacing: 1.1,
                maxWidth: '227px',
                width: '100%',
                height: '55px',
              },
            }}
          >
            {t('login')}
          </Button>
        </Box>
      ) : (
        <LoginBlock />
      )}
    </Box>
  )
}

export default memo(Login)
