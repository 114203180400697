import React, { memo } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { use100vh } from 'react-div-100vh'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { useLocation } from 'react-router'
import isIframed from '../utils/isIframed'
import i18n from 'i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    height: (props) => (isIframed ? null : props.height),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  contentRoot: {
    display: 'flex',
    width: '100%',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  iframeSearchOnTop: {
    alignItems: 'start',
  },
  rootIframed: {
    flexGrow: 1,
  },
}))

const getMobileOffset = (user, authType, banner) => {
  let offset = 64

  if (!!user?.isBookingLink || ['agent', 'viaAgent'].includes(authType))
    offset += 49

  const lang = i18n.language?.split('-')[0].toLowerCase()

  if (banner?.[lang]) offset += 49

  return offset
}

const CenterSearchBar = ({ children, topContent }) => {
  const { user, authType, banner } = useSelector((state) => state.auth)
  const location = useLocation()
  const height = isIframed ? window.innerHeight : use100vh()
  const headerHeight = useSelector((state) => state.other.headerHeight)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const actualHeight =
    parseInt(height) -
    parseInt(isMobile ? getMobileOffset(user, authType, banner) : headerHeight)
  const classes = useStyles({ height: actualHeight })
  const { backgroundImage } = useSelector((state) => state.customization)

  const isCustomRoute = location.pathname.startsWith('/c/')

  const bg =
    isCustomRoute && backgroundImage
      ? {
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }
      : null

  return (
    <Box
      className={isIframed ? [classes.root, classes.rootIframed] : classes.root}
      style={bg}
    >
      {!!topContent && <Box width="100%">{topContent}</Box>}
      <Box className={classes.contentRoot}>{children}</Box>
    </Box>
  )
}

export default memo(CenterSearchBar)
