import _ from 'lodash'

export const getSeatsPricing = (resSeats) => {
  const result = {
    byRows: {},
    bySeats: {},
  }

  if (
    _.isEmpty(resSeats.seatmapInformation) ||
    _.isEmpty(resSeats.seatmapInformation.customerCentricData) ||
    _.isEmpty(resSeats.seatmapInformation.customerCentricData.seatPrice)
  ) {
    return result
  }

  const prices = _.isArray(
    resSeats.seatmapInformation.customerCentricData.seatPrice
  )
    ? resSeats.seatmapInformation.customerCentricData.seatPrice
    : [resSeats.seatmapInformation.customerCentricData.seatPrice]

  prices.forEach((priceInfo) => {
    const Prices = {}
    if (
      _.isEmpty(priceInfo.seatPrice.monetaryDetails) ||
      _.isEmpty(priceInfo.rowDetails)
    ) {
      return false
    }
    const pricesInitial = _.isArray(priceInfo.seatPrice.monetaryDetails)
      ? priceInfo.seatPrice.monetaryDetails
      : priceInfo.seatPrice.monetaryDetails
    const rowDetails = _.isArray(priceInfo.rowDetails)
      ? priceInfo.rowDetails
      : [priceInfo.rowDetails]

    pricesInitial.forEach((pricePart) => {
      Prices[pricePart.typeQualifier] = parseFloat(pricePart.amount)
    })

    const price = typeof Prices['T'] !== 'undefined' ? Prices['T'] : 0

    rowDetails.forEach((rowDetail) => {
      const rowInd = rowDetail.seatRowNumber
      const seatsData = _.isArray(rowDetail.seatOccupationDetails)
        ? rowDetail.seatOccupationDetails
        : [rowDetail.seatOccupationDetails]
      if (typeof result.byRows[rowInd] === 'undefined') {
        result.byRows[rowInd] = {}
      }

      seatsData.forEach((seatCol) => {
        result.byRows[rowInd][seatCol.seatColumn] = price
        result.bySeats[rowInd + seatCol.seatColumn] = price
      })
    })
  })

  return result
}
