const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT'
const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST'
const SET_HIDE_TRAIN = 'SET_HIDE_TRAIN'
const SET_ONLY_OIDC = 'SET_ONLY_OIDC'

export default {
  SET_HEADER_HEIGHT,
  SET_COUNTRIES_LIST,
  SET_HIDE_TRAIN,
  SET_ONLY_OIDC,
}
