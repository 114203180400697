import moment from 'moment'
import i18n from 'i18next'

export const getFreeCancellationLabel = (
  cancellationType,
  freeCancellationDeadline,
  short = false
) => {
  if (cancellationType !== 'free') return null
  if (moment(freeCancellationDeadline).isBefore(new Date())) return null

  const format = short ? `YYYY-MM-DD` : `YYYY-MM-DD [${i18n.t('at')}] HH:mm`

  return (
    i18n.t('hotel popular free') +
    (freeCancellationDeadline
      ? ' ' +
        i18n.t('until').toLowerCase() +
        ' ' +
        moment(freeCancellationDeadline).format(format)
      : '')
  )
}
