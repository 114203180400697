import React, { memo, useEffect, useMemo, useState, Fragment } from 'react'
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ModalHook from './reusable/modalHook'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { fetchBookingsAction } from '../../store/myTrips/myTripsAction'
import FetchMyTrips from '../../repositories/my-trips'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeAllModals,
  openSimpleInfoModal,
} from '../../store/modals/modalAction'
import TripDetails from '../myTrips/cards/tripDetails'
import { canCancelBooking } from '../../utils/general'
import i18n from '../../utils/i18n'
const tripsRepository = new FetchMyTrips()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      flex: 1,
      padding: '0 10px 20px 10px',
    },

    [theme.breakpoints.up('sm')]: {
      padding: '0 20px 28px 20px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '0 30px 28px 30px',
    },
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  headBtnOuter: {
    display: 'inline-block',
    padding: '16px 16px 0 0',
  },
  headLeftBtnOuter: {
    display: 'inline-block',
    padding: '16px 0 0 16px',
  },
  headerText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textsBlock: {
    marginBottom: '33px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  btns: {
    borderRadius: theme.spacing(3),
    margin: '0 4px',
    padding: theme.spacing('10px', '20px'),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
    minWidth: '140px',

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing('10px', 2),
    },
  },
  noteBlock: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(3),
    borderRadius: '4px',
  },
  noteHead: {
    fontSize: '15px',
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(1),
  },
  noteText: {
    fontSize: '15px',
  },
  cancelTravellers: {
    textAlign: 'center',
    textDecoration: 'underline',
    fontSize: '15px',
  },
  passengerNameOuter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '14px 0px',
    borderBottom: `1px solid ${theme.palette.border}`,

    '&:first-child': {
      paddingTop: 0,
    },

    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
  passengerNameBlock: {
    flex: 1,
  },
  passengerFullName: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  passengerEmail: {
    fontSize: '14px',
    color: theme.palette.primary.main,
  },
  passengerIssue: {
    fontSize: '10px',
    color: theme.palette.secondary.main,
  },
  selectPassengerBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: 'normal',
    padding: '8px 0px',
    width: '93px',
    letterSpacing: '1px',
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
    },
  },
  userCancelled: {
    fontSize: '15px',
    fontWeight: 'bold',
    paddingRight: '10px',
    color: theme.palette.secondary.main,
  },
  detailsLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  descriptionText: {
    fontSize: '16px',
    textAlign: 'center',
  },
  error: {
    fontSize: '16px',
    textAlign: 'center',
    margin: '24px',
  },
}))

const CancelTripPartConfirmModal = ({ cancelData }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [contentType, setContentType] = useState('default')
  const [breadCrumbs, setBreadCrumbs] = useState([])
  const [passengerOpts, setPassengerOpts] = useState([])
  const { user } = useSelector((state) => state.auth)
  const [errorMessage, setErrorMessage] = useState(null)

  const isOwner = useMemo(() => {
    if (!cancelData?.model) return false
    const isOnlyUser =
      !cancelData.model.userId &&
      cancelData.model?.passengerIds?.length === 1 &&
      cancelData.model.passengerIds.includes(user?.uniqueId)
    return cancelData.model.userId === user?.uniqueId || isOnlyUser
  }, [cancelData, user])

  const stepBack = () => {
    if (!breadCrumbs?.length) return
    const bc = [...breadCrumbs]
    const target = bc.pop()
    setBreadCrumbs(bc)
    setContentType(target)
  }
  const getModelType = () => {
    const { hotel, flights, rails, rentalCar } = cancelData?.model || {}
    if (!!flights) return 'flight'
    if (!!rails) return 'train'
    if (!!hotel) return 'hotel'
    if (!!rentalCar) return 'rental car'
    return null
  }

  const moveToType = (type) => {
    const bc = [...breadCrumbs]
    bc.push(contentType)
    setBreadCrumbs(bc)
    setContentType(type)
  }

  useEffect(() => {
    let tmpPassengers = []
    if (cancelData?.passengers?.length) {
      tmpPassengers = cancelData.passengers.map((p) => {
        return {
          ...p,
          selected: false,
        }
      })
    }
    setContentType('default')
    setBreadCrumbs([])
    setPassengerOpts(tmpPassengers)
  }, [cancelData])

  const selPassengers = useMemo(() => {
    return passengerOpts.filter((p) => !!p.selected)
  }, [passengerOpts])

  const activePassengers = useMemo(() => {
    return passengerOpts.filter((p) => !p.cancelled)
  }, [passengerOpts])

  const setSuitableError = (e) => {
    const msg = e.response?.data?.error || e.message || 'general error'

    const translationKey = `error ${msg.toLowerCase()}`
    if (i18n.exists(translationKey)) {
      setErrorMessage(t(translationKey))
    } else {
      setErrorMessage(t('cancel booking fail'))
    }
  }

  const cancelTransfer = async () => {
    setLoading(true)
    setErrorMessage(null)
    try {
      await tripsRepository.cancelSingleTransfer(
        cancelData.pnr,
        cancelData.item.bookingReference
      )
      postCancelActions()
    } catch (e) {
      setLoading(false)
      setSuitableError(e)
    }
  }

  const selectPassenger = (ind) => {
    setPassengerOpts(
      passengerOpts.map((p, i) => {
        const tmpPass = { ...p }
        if (ind === i) tmpPass.selected = !p.selected
        return tmpPass
      })
    )
  }

  const cancelTrain = async () => {
    setLoading(true)
    setErrorMessage(null)
    try {
      await tripsRepository.cancelRail(cancelData.pnr)
      postCancelActions()
    } catch (e) {
      setLoading(false)
      setSuitableError(e)
    }
  }

  const cancelHotel = async () => {
    setLoading(true)
    setErrorMessage(null)
    try {
      await tripsRepository.cancelHotel(cancelData.pnr, cancelData.hotelIndex)
      postCancelActions()
    } catch (e) {
      setLoading(false)
      setSuitableError(e)
    }
  }

  const cancelFlight = async () => {
    setLoading(true)
    setErrorMessage(null)
    try {
      await tripsRepository.cancelFlight(cancelData.pnr)
      postCancelActions()
    } catch (e) {
      setLoading(false)
      setSuitableError(e)
    }
  }

  const cancelCar = async () => {
    setLoading(true)
    setErrorMessage(null)
    try {
      await tripsRepository.cancelCar(cancelData.pnr)
      postCancelActions()
    } catch (e) {
      setLoading(false)
      setSuitableError(e)
    }
  }

  const cancelPassengers = async () => {
    if (!selPassengers?.length) return false

    const { canCancel, reason } = canCancelBooking(user, cancelData.model)
    if (!canCancel) {
      const textKey = reason === 'rbk-nref' ? 'rbk-nref' : 'forbidden'
      dispatch(
        openSimpleInfoModal({
          title: t('cancel prohibit title'),
          text: t(`cancel ${textKey} note`, {
            phone: user?.supportTelephone || '',
          }),
          buttonText: t('ok'),
        })
      )
      return true
    }

    const isWholeCancel = selPassengers?.length >= activePassengers?.length
    setLoading(true)
    setErrorMessage(null)
    try {
      if (isWholeCancel) {
        await tripsRepository.cancelPnr(cancelData.pnr)
      } else {
        await tripsRepository.cancelPassenger(
          cancelData.pnr,
          selPassengers.filter((p) => !!p.pnrTatoo).map((p) => p.pnrTatoo)
        )
      }
      postCancelActions()
    } catch (e) {
      setLoading(false)
      setSuitableError(e)
    }
  }

  const postCancelActions = () => {
    setLoading(false)
    dispatch(fetchBookingsAction())
    return dispatch(closeAllModals())
  }

  const closeModal = () => {
    setLoading(false)
    return dispatch(closeAllModals())
  }

  const confirm = () => {
    if (cancelData?.type === 'transfer') return cancelTransfer()
    if (cancelData?.type === 'train') return cancelTrain()
    if (cancelData?.type === 'hotel') return cancelHotel()
    if (cancelData?.type === 'flight') return cancelFlight()
    if (cancelData?.type === 'car') return cancelCar()
  }

  const texts = useMemo(() => {
    const data = {
      header: t('cancel booking part', {
        name: t(cancelData?.type).toLowerCase(),
      }),
      description: t('cancel booking part descr', {
        name: t(cancelData?.type).toLowerCase(),
      }),
      note: null,
    }

    if (cancelData?.type === 'train') {
      return {
        ...data,
        description: cancelData?.item?.return
          ? null
          : t('cancel booking part descr', { name: t('train').toLowerCase() }),
        note: cancelData?.item?.return ? t('cancel booking part note') : null,
      }
    }

    if (cancelData?.type === 'flight') {
      return {
        ...data,
        description: cancelData?.item?.withReturn
          ? null
          : t('cancel booking part descr', { name: t('flight').toLowerCase() }),
        note: cancelData?.item?.withReturn
          ? t('cancel booking part note')
          : null,
      }
    }

    if (cancelData?.type === 'passengers') {
      return {
        header: `${t('cancel')} ${t('travelers')}?`,
        description: null,
        note: null,
      }
    }

    return data
  }, [cancelData, t])

  const buildPassengers = () => {
    return (
      <Box mt={'33px'}>
        {passengerOpts.map((p, i) => {
          const hasPermission = isOwner || p.locator === user.uniqueId
          return (
            <Box className={classes.passengerNameOuter}>
              <Box className={classes.passengerNameBlock}>
                <Box className={classes.passengerFullName}>
                  {p.firstName} {p.lastName}
                </Box>
                <Box className={classes.passengerEmail}>{p.email}</Box>
                {!p.pnrTatoo && (
                  <Box className={classes.passengerIssue}>
                    {t('cant cancel passenger')}
                  </Box>
                )}
              </Box>
              {!!p.cancelled && (
                <span className={classes.userCancelled}>{t('cancelled')}</span>
              )}
              {!hasPermission && (
                <span className={classes.userCancelled}>
                  {t('no permissions')}
                </span>
              )}
              {hasPermission && !!p.pnrTatoo && !p.cancelled && (
                <Button
                  onClick={() => selectPassenger(i)}
                  className={classes.selectPassengerBtn}
                  variant={!p.selected ? 'outlined' : 'contained'}
                  color="secondary"
                >
                  {t(!p.selected ? 'select' : 'selected')}
                </Button>
              )}
            </Box>
          )
        })}
      </Box>
    )
  }

  const buildButtons = () => {
    if (
      contentType === 'passengers' ||
      (contentType === 'default' && cancelData?.type === 'passengers')
    ) {
      const isDisabled = !selPassengers?.length
      const pCount = selPassengers.length
      return (
        <Box mt={'33px'}>
          <Button
            disableElevation
            disabled={isDisabled}
            style={{ width: '100%' }}
            className={classes.btns}
            color="primary"
            variant="contained"
            onClick={cancelPassengers}
          >
            {t('cancel booking')} {pCount > 0 ? t(`count_${pCount}`) : ''}{' '}
            {pCount > 0 ? `(${pCount})` : ''}{' '}
            {t('traveller', { count: pCount })}
          </Button>
        </Box>
      )
    }

    if (contentType === 'default')
      return (
        <Box mt={'33px'}>
          <Button
            className={classes.btns}
            color="primary"
            variant="outlined"
            onClick={closeModal}
          >
            {t('no')}
          </Button>
          <Button
            disableElevation
            className={classes.btns}
            color="primary"
            variant="contained"
            onClick={confirm}
          >
            {t('yes')}, {t('cancel booking').toLowerCase()}
          </Button>
        </Box>
      )

    return null
  }

  const buildDefaultTexts = (txtData = {}) => {
    const { header, note, description } = txtData
    const headerTxt = typeof header !== 'undefined' ? header : texts.header
    const noteText = typeof note !== 'undefined' ? note : texts.note
    const descriptionText =
      typeof description !== 'undefined' ? description : texts.description

    return (
      <Box>
        <Typography
          gutterBottom
          className={`${classes.headerText} ${classes.textsBlock}`}
          variant="h4"
          align="center"
        >
          {headerTxt}
        </Typography>
        {!!noteText && (
          <Box className={`${classes.noteBlock} ${classes.textsBlock}`}>
            <span className={classes.noteHead}>{t('please note')}:</span>
            <span className={classes.noteText}>{noteText}</span>
          </Box>
        )}
        {!!descriptionText && (
          <Typography align="center" className={classes.textsBlock}>
            {descriptionText}
          </Typography>
        )}
      </Box>
    )
  }

  const buildDetailsContent = () => {
    const mType = getModelType()
    const header = !!mType ? t(`${mType} details`) : ''
    return (
      <Box className={classes.root}>
        {buildDefaultTexts({ header: header })}
        <Box width={'100%'} mt={'9px'}>
          {!!mType && <TripDetails model={cancelData.model} mainTripOnly />}
        </Box>
      </Box>
    )
  }
  const buildPassengersContent = () => {
    const mType = getModelType()
    return (
      <Box px={'30px'} pb={!isMobile ? '28px' : 0} className={classes.root}>
        <Box>
          {buildDefaultTexts({
            header: `${t('cancel booking')} ${t('travelers').toLowerCase()}?`,
          })}
          <Box className={classes.descriptionText} mt={'31px'}>
            {t('traveller cancel dscr', {
              name: (!!mType ? t(mType) : '').toLowerCase(),
            })}
            .{' '}
            {!!mType && (
              <span
                onClick={() => moveToType('details')}
                className={classes.detailsLink}
              >
                {t(`${mType} details`)}
              </span>
            )}
          </Box>
          {buildPassengers()}
        </Box>
        {buildButtons()}
      </Box>
    )
  }

  const buildDefaultContent = () => {
    return (
      <Box px={'30px'} pb={!isMobile ? '28px' : 0} className={classes.root}>
        <Box>{buildDefaultTexts()}</Box>
        {buildButtons()}
        {!!passengerOpts.length && (
          <Box mt={'33px'} className={classes.cancelTravellers}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => moveToType('passengers')}
            >
              {t('cancel traveler lnk')}
            </span>
          </Box>
        )}
      </Box>
    )
  }

  const buildInnerContent = () => {
    if (errorMessage) return buildError()

    if (
      contentType === 'passengers' ||
      (contentType === 'default' && cancelData?.type === 'passengers')
    )
      return buildPassengersContent()
    if (contentType === 'details') return buildDetailsContent()
    if (contentType === 'default') return buildDefaultContent()
    return null
  }

  const isBIG = window.location.hostname.includes('bigtravel')

  const buildError = () => {
    return (
      <Box className={classes.root}>
        <Box>
          <Typography
            gutterBottom
            className={classes.headerText}
            variant="h4"
            align="center"
          >
            {t('cancellation failed')}
          </Typography>
          <Box textAlign={'center'} py={3} className={classes.error}>
            {errorMessage}
            {isBIG && (
              <a
                style={{ marginLeft: '8px' }}
                href="https://bigtravel.se/changebooking"
              >
                {t('click here')}
              </a>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  const buildLoading = () => {
    return (
      <Box className={classes.root}>
        <Box>
          <Typography
            gutterBottom
            className={classes.headerText}
            variant="h4"
            align="center"
          >
            {t('confirm wait title')}
          </Typography>
          <Box textAlign={'center'} py={3}>
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    )
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ModalHook
      minHeight={300}
      width={380}
      display={isMobile ? 'flex' : null}
      modalBoolean={!!cancelData}
      mobileFullWidth={false}
    >
      <Box pt={loading ? '28px' : 0} className={classes.header}>
        {!loading && !!breadCrumbs?.length && (
          <Box className={classes.headLeftBtnOuter}>
            <IconButton color="primary" onClick={stepBack}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
        )}
        {!loading && (
          <Fragment>
            <Box flex={1} />
            <Box className={classes.headBtnOuter}>
              <IconButton color="primary" onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Fragment>
        )}
      </Box>
      {!loading ? buildInnerContent() : buildLoading()}
    </ModalHook>
  )
}

export default memo(CancelTripPartConfirmModal)
