import cityCityException from '../../cityCityException.Class'
import { buildRemarks } from './helpers'

export const getAvailableFop = (checkout, isAuth, itm) => {
  const { fop, costfields } = checkout || {}
  const isGuarantee = !!itm?.tarif?.guaranteeRequired
  const primaryPayment =
    costfields?.paymentMethods?.primaryPayment || 'profileCC'
  const fops = isGuarantee ? ['cc'] : [primaryPayment]
  const rqFops = []
  if (!fops.includes(fop)) {
    rqFops.push(fops[0])
  }
  return { fops, rqFops }
}

export default (model, state, extData = {}) => {
  const { isAuthenticated, encloseCostfieldValues } = state.auth
  const fopInfo = getAvailableFop(state.checkout, isAuthenticated)
  const { fop, creditCard, costFieldValues = {}, costfields } = state.checkout
  let resultFop = null
  const currFop = !fopInfo.fops.includes(fop) ? fopInfo.fops[0] : fop

  if (currFop === 'cc') {
    if (!creditCard?.number || !creditCard?.expire || !creditCard.ccCode) {
      throw new cityCityException({ message: 'Invalid flight credit card' })
    }

    resultFop = {
      type: 'CC',
      vendorCode: creditCard?.ccCode || null,
      ccNumber: creditCard.number.replace(/\s+/g, ''),
      expire: creditCard.expire.replace('/', '').replace(/\s+/g, ''),
    }
  } else {
    resultFop = { type: fop }
  }

  return {
    fop: resultFop,
    passengers: model.passengers.map((usr) => {
      const passCfData = {
        costFieldValues: costFieldValues?.[usr.uniqueId] || {},
        costfields,
      }
      return {
        firstName: usr.firstName,
        lastName: usr.lastName,
        email: usr.email,
        type: usr?.age >= 18 || !usr?.age ? 'adult' : 'child',
        locator: usr.uniqueId,
        age: usr.age || 30,
        isGuest: usr.isGuest || false,
        phone: usr.mobile || null,
        remarks: buildRemarks(passCfData, encloseCostfieldValues),
      }
    }),
    totalPrice: model.totalPrice,
    extras: model?.extras?.length ? [...model?.extras] : [],
    addons: model?.addedAddOns?.length ? [...model?.addedAddOns] : [],
    availability: { ...(model?.tarif || {}) },
  }
}
