import { Box, Select, MenuItem } from '@material-ui/core'
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import isIframed from '../../../utils/isIframed'

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.common.black,
    paddingBottom: '13px',
    lineHeight: 'normal',
  },
  select: {
    width: '100%',
    borderRadius: '4px',
    fontSize: '16px',
    color: theme.palette.common.black,
    lineHeight: 'normal',

    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },

    '& .MuiSelect-root': {
      paddingTop: '15px',
      paddingBottom: '15px',
    },
  },
  smallSelect: {
    borderRadius: '4px',
    fontSize: '14px',
    backgroundColor: theme.palette.primary.medium,
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightSemiBold,

    '& .MuiSelect-root': {
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  },
}))

const TicketClassSelector = ({
  setTicketClass,
  ticketClass,
  ticketClasses,
  small,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getClassesOptions = () => {
    const results = []
    for (let tClass in ticketClasses) {
      isIframed
        ? results.push(
          <option
            key={tClass}
            value={tClass}
          >
            {t(ticketClasses[tClass])}
          </option>
        ) : results.push(
            <MenuItem key={tClass} value={tClass}>
              {t(ticketClasses[tClass])}
            </MenuItem>
          )
    }
    return results
  }

  const renderTicketClass = (val) => {
    let label = t(ticketClasses[val] + ' small')
    return label.toUpperCase()
  }

  const onClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  if (small)
    return (
      <Box>
        <Select
          className={classes.smallSelect}
          variant="outlined"
          IconComponent={() => null}
          value={ticketClass ?? ''}
          onClick={onClick}
          onChange={(e) => setTicketClass(e.target.value)}
          renderValue={renderTicketClass}
        >
          {getClassesOptions()}
        </Select>
      </Box>
    )

  return (
    <Box>
      <Box className={classes.header}>{t('class')}</Box>
      <Select
        native={isIframed}
        className={classes.select}
        variant="outlined"
        IconComponent={KeyboardArrowDownIcon}
        value={ticketClass ?? ''}
        onChange={(e) => setTicketClass(e.target.value)}
      >
        {getClassesOptions()}
      </Select>
    </Box>
  )
}

export default memo(TicketClassSelector)
