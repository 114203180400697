import React, { memo, Fragment } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  infoRows: {
    width: '100%',
    padding: '20px 10px',
    borderRadius: '5px',
    backgroundColor: '#eef8f8'
  },
  infoRow: {
    width: '100%',
    textAlign: 'center'
  }
}))

const ModalInfoRowsBlock = ({ textsArray }) => {
  const classes = useStyles()

  return (
    <Fragment>
      {!!textsArray?.length && (
        <Box className={classes.infoRows}>
          {textsArray.map((d, ind) => {
            const text = typeof d === 'string' ? d || '' : d?.text || ''
            const styles = !!d?.styles && typeof d.styles === "object" ? d.styles : {}
            return (
              <Box key={`txt-row-${ind}`} className={classes.infoRow} style={styles}>{text}</Box>
            )
          })}
        </Box>
      )}
    </Fragment>
  )
}

export default memo(ModalInfoRowsBlock)
