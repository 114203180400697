import React, { useCallback, useContext, useEffect } from 'react'
import _ from 'lodash'
import { Box, Typography, Button, IconButton, Hidden, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import {useDispatch, useSelector} from "react-redux";
import {
  resetSearchFilters,
  setCompaniesFilter, setDurationFilters,
  setTimeFilters,
} from '../../../store/search/searchAction'
import COMPANIES from '../../../constants/flightCompanies'
import {CompaniesContext} from "../../../components/flights/filter/companiesContext";
import Company from "./company";
import TwoSideSlider from "./twoSideSlider";
import OneSideSlider from "./oneSideSlider";
import Stops from './stops'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import MuiButton from '@material-ui/core/Button/Button'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  selectAllBlock: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    color: 'black',
    marginTop: '16px',
    marginBottom: '7px',
    display: 'flex'
  },
  selectAllLink: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },

  selectAllDivider: {
    width: '1px',
    backgroundColor: 'black',
    margin: '0 12px'
  },

  checkboxOuter: {
    display: 'inline-block',
    '& .hoverLink': {
      color: theme.palette.common.black,
      fontSize: '15px',
      display: 'none',
      cursor: 'pointer',
      fontWeight: theme.typography.fontWeightSemiBold,

      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '&:hover .hoverLink': {
      display: 'inline-block',
    }
  },
  card: {
    background: '#f7f7f7',
    borderRadius: '12px',
  },
  cardContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3,1)
    },
  },
  align: {
    display: 'flex',
    alignItems: 'center',
  },
  resetBtn: {
    margin: 0,
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
    background: theme.palette.primary.medium,
    color: theme.palette.primary.dark,
    borderRadius: theme.spacing(3),
    textTransform: 'none',

    '&:hover': {
      color: 'white'
    }
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    '& .MuiFormControlLabel-label': {
      fontSize: theme.spacing(1.75)
    }
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    height: '64px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 3),
    zIndex: 1000
  },
  boldTitle: {
    fontWeight: 700,
    fontSize: theme.spacing(2)
  },
  headerBtn: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.7px'
  }
}))

const FlightFilter = ({ closeIfMobile, isOutgoing }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const searchOutgoing = useSelector((state) => state.flights.searchOutgoing)
  const searchReturning = useSelector((state) => state.flights.searchReturning)
  const dispatch = useDispatch()
  const [companies, setCompanies] = useContext(CompaniesContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const filterUnique = (flights) => {
    const res =  _.uniqBy(flights, 'company').map(el => ({
      slug: el.company, state: true, company: {...COMPANIES[el.company]}, label: COMPANIES?.[el.company]?.label || ''
    }))
    return _.orderBy(res, ['label'])
  }

  useEffect(() => {
    setCompanies(filterUnique(isOutgoing ? searchOutgoing : searchReturning))
  }, [isOutgoing, searchReturning, searchOutgoing, setCompanies])

  useEffect(() => {
    if (companies.length > 0) {
      dispatch(setCompaniesFilter({...companies.reduce((acc, el) => el.state === true
          ? {...acc, [el.slug]: el.company}
          : acc, {})}))
    }
  }, [companies, dispatch])

  const convertStringToTime = (time) => {
    const hours = Number(time.toString().split(':')[0])
    const minutes = time.toString().split(':')[1] === '00' ? 0 : 0.5

    return hours + minutes
  }

  const convertTimeToString = (value) => {
    const time = Number.isInteger(value) ? `${value}:00` : `${Math.floor(value)}:30`

    if(time.split(':')[0].length < 2) {
      return `0${time}`
    } else {
      return time
    }
  }

  const handleCompaniesChange = (e, value) => {
    const { name } = e.target
    setCompanies([...companies.map(el => el.slug === name ? { ...el, state: value } : el)])
  }

  const handleOnlyCmpClick = (slug) => {
    setCompanies([...companies.map(el => ({ ...el, state: el.slug === slug }))])
  }

  const handleSetAllCmp = (state) => {
    setCompanies([...companies.map(el => ({ ...el, state: !!state }))])
  }

  const handleTimeChange = (type, value) => {
    if(value)
    dispatch(setTimeFilters({ [type]: {from: convertTimeToString(value[0]), to: convertTimeToString(value[1])} }))
  }

  const maxValue = useCallback((arr) => {
    const max = _.maxBy(arr, 'travelTime')
    if (max) return max.travelTime
  }, [])

  const roundValue = useCallback((value) => {
    return Math.ceil(maxValue(value) / 60 * 2)/2
  }, [maxValue])

  const resetFilters = () => {
    dispatch(resetSearchFilters())
    dispatch(setDurationFilters({ [isOutgoing ? 'trip' : 'returnTrip']: roundValue(isOutgoing ? searchOutgoing : searchReturning) * 60}))
    setCompanies(filterUnique(isOutgoing ? searchOutgoing : searchReturning))
  }

  return (
    <Card className={classes.card} elevation={0}>
      {
        isMobile
          && (
            <Box className={classes.header}>
              <Box flex={1}>
                <button
                  style={{
                    background: 'transparent',
                    outline: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    color: '#007f7f',
                    fontWeight: 700,
                    textDecoration: 'none',
                    fontSize: '14px',
                    paddingLeft: '0',
                    fontFamily: 'Metropolis'
                  }}
                  onClick={resetFilters}
                >
                  {t('reset filter')}
                </button>
              </Box>
              <Box flex={1} textAlign={'center'}>
                <Typography className={classes.boldTitle}>
                  {t('filter')}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end" flex={1}>
                <MuiButton
                  variant="contained"
                  disableElevation
                  onClick={closeIfMobile}
                  className={classes.headerBtn}
                  color="primary"
                >
                  {t('done')}
                </MuiButton>
              </Box>
            </Box>
          )
      }
      <CardContent className={classes.cardContent} style={{ paddingTop: isMobile && 0 }}>
        <Box>
          {
            !isMobile && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h4" className={classes.weight}>
                    {t('filter')}
                  </Typography>
                  <Hidden mdUp>
                    <IconButton onClick={closeIfMobile}>
                      <CloseIcon />
                    </IconButton>
                  </Hidden>
                </Box>

                <Box mt={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.resetBtn}
                    onClick={resetFilters}
                    disableElevation
                  >
                    {t('reset filter')}
                  </Button>
                </Box>
              </>
            )
          }
          <Box
            mt={4}
            className={classes.root}
            display="flex"
            flexDirection="column"
          >
            <Stops isOutgoing={isOutgoing} searchReturning={searchReturning} searchOutgoing={searchOutgoing}/>
          </Box>
          <Box mt={4} className={classes.root}>
            <Typography variant="body1" className={classes.weight}>
              {t('filter departure time')}
            </Typography>
            <Box pt={2}>
              <TwoSideSlider
                isOutgoing={isOutgoing}
                handleTimeChange={handleTimeChange}
                convertStringToTime={convertStringToTime}
                convertTimeToString={convertTimeToString}
                t={t}
                disabled={!isOutgoing}
              />
            </Box>
            <Box pt={2}>
              <TwoSideSlider
                isOutgoing={isOutgoing}
                handleTimeChange={handleTimeChange}
                convertStringToTime={convertStringToTime}
                convertTimeToString={convertTimeToString}
                t={t}
                disabled={isOutgoing}
              />
            </Box>
          </Box>
          <Box mt={4} className={classes.root}>
            <Typography variant="body1" className={classes.weight}>
              {t('filter max time')}
            </Typography>
            <Box>
              <Box pt={2}>
                <OneSideSlider
                  t={t}
                  searchOutgoing={searchOutgoing}
                  searchReturning={searchReturning}
                  roundValue={roundValue}
                  convertTimeToString={convertTimeToString}
                  isOutgoing={isOutgoing}
                  disabled={!isOutgoing}
                />
              </Box>
              <Box pt={2}>
                <OneSideSlider
                  t={t}
                  searchOutgoing={searchOutgoing}
                  searchReturning={searchReturning}
                  roundValue={roundValue}
                  convertTimeToString={convertTimeToString}
                  isOutgoing={isOutgoing}
                  disabled={isOutgoing}
                />
              </Box>
            </Box>
          </Box>
          <Box mt={4} className={classes.root}>
            <Typography variant="body1" className={classes.weight}>
              {t('filter travel agents')}
            </Typography>
            {!!companies?.length &&
              <Box className={classes.selectAllBlock}>
                <Box onClick={() => handleSetAllCmp(true)} className={classes.selectAllLink}>{t('select all')}</Box>
                <Box className={classes.selectAllDivider}/>
                <Box onClick={() => handleSetAllCmp(false)} className={classes.selectAllLink}>{t('clear all filters')}</Box>
              </Box>
            }

            <Box>
              <FormGroup>
                {companies.map(el => (
                  <Company key={el.slug} el={el} handleOnlyClick={handleOnlyCmpClick} handleChange={handleCompaniesChange}/>
                ))}
              </FormGroup>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default FlightFilter
