import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'

import PersonAddIcon from '@material-ui/icons/PersonAdd'
import SvgIcon from '@material-ui/core/SvgIcon'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'
import Popper from '@material-ui/core/Popper'
import AddUsersModal from '../../containers/modals/forms/addusersModal'
import SelectUserDOBModal from '../../containers/modals/selectUserDOBModal'
import AddGuestUserModal from '../../containers/modals/addGuestUserModal'
import MuiPopover from '@material-ui/core/Popover'
import { ListItemSecondaryAction, useMediaQuery } from '@material-ui/core'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import TicketClassSelector from './parts/ticketClassSelector'
import { debounce } from 'lodash'
import { fetchAllUsers } from '../../repositories/users'
import axios from 'axios'
import { StarBorder, StarOutlined } from '@material-ui/icons'
import { setFavoriteUsers } from '../../store/auth/authAction'
import { fetchUpdateUserFavorites } from '../../repositories/auth'

const useStyles = makeStyles((theme) => ({
  travelers: {
    borderRadius: 8,
    width: '100%',
    display: 'inline-block',
    backgroundColor: '#ffffff',

    [theme.breakpoints.up('md')]: {
      marginBottom: (props) => (props.noLabel ? 0 : 15),
      width: 385,
    },
  },
  input: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  travelersLarge: {
    borderRadius: 8,
    width: '100%',
    display: 'inline-block',
    backgroundColor: '#ffffff',
    marginBottom: '15px',

    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  travelersInner: {
    borderRadius: 8,
    border: '1px solid #e6e6e6',
  },
  showUserSearchBtn: {
    borderRadius: 0,
    padding: theme.spacing(1, 2),
    '-webkit-tap-highlight-color': 'transparent !important',
    '& div, span, p': {
      '-webkit-tap-highlight-color': 'transparent !important',
    },
  },
  showUserSearchLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textTransform: 'none',
  },
  startAdornment: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightSemiBold,
    letterSpacing: '0.7px',
    marginBottom: 5,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  travelersCount: {
    border: 'solid 1px #d8d8d8',
    backgroundColor: 'rgba(216, 216, 216, 0.2)',
    padding: '3px 8px 2px',
    fontSize: 14,
    fontWeight: 700,
    borderRadius: 6,
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2),
  },
  searchBox: {
    backgroundColor: '#ffffff',

    '& .MuiOutlinedInput-root': {
      paddingLeft: theme.spacing(0.25),
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 8,
      fontWeight: theme.typography.fontWeightSemiBold,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
      },
    },
  },
  userIcon: {
    color: '#cccccc',
  },
  userName: {
    fontSize: 16,
  },
  userEmail: {
    fontSize: 12,
    color: '#007e7e',
  },

  travelersText: {
    fontSize: theme.spacing(2),
    fontWeight: 600,
  },
  value: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  placeholder: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightSemiBold,
    opacity: 0.5,
  },

  placeholderError: {
    color: '#d0021b',
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(2.4),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2),
    },
  },
  innerInput: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: 0,
  },
  icon: {
    color: theme.palette.common.black,
    transform: 'scale(0.8)',
  },

  popoverText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    fontSize: '12px',
  },

  addGuestButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    zIndex: 2,
    height: '54px',
    backgroundColor: 'white',
    position: 'absolute',
    paddingLeft: theme.spacing(2),
    bottom: theme.spacing(1),
    left: 0,
    right: 0,

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },

  addGuestText: {
    flex: 1,
    fontSize: theme.spacing(2),
    color: theme.palette.common.black,
    lineHeight: 'normal',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: theme.typography.fontFamily,
  },

  addGuestIcon: {
    height: '24px',
    width: 'auto',
    color: theme.palette.primary.main,
    marginRight: '17px',
    textTransform: 'capitalize',
  },

  autocompletePopper: {
    '& .MuiAutocomplete-paper': {
      position: 'relative',
      paddingBottom: '62px',
      '& .MuiAutocomplete-listbox': {
        position: 'initial',
        paddingBottom: 0,
        '& > li:last-child': {
          padding: 0,
        },
      },
    },
  },

  ticketClass: {
    bottom: '28px',
    right: '8px',
  },

  ticketClassMinimized: {
    top: '50%',
    right: '16px',
  },

  ticketClassMobile: {
    top: '60%',
    right: '10px',
  },
  personIcon: {
    color: theme.palette.iframeSecondary.main,
  },

  option: {
    width: '100%',
  },

  favoriteIcon: {
    color: '#cccccc',
  },

  favoriteIconActive: {
    color: theme.palette.primary.main,
  },
}))

const Popover = withStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: '#e02020',
    boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.15)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    overflow: 'visible',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 0,
      height: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderTop: '15px solid #e02020',
      borderBottom: '15px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      top: '30px',
    },
  },
}))(MuiPopover)

const UsersSearch = ({
  entityType = null,
  disabled,
  value,
  setValue,
  noLabel,
  withUserModal,
  userShadow = true,
  isMinimized,
  isPopoverOpenTraveller,
  setIsPopoverOpenTraveller,
  handleClose,
  ticketClass,
  setTicketClass,
  ticketClasses,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user: authUser, favoriteUsers } = useSelector((state) => state.auth)
  const { isIframe } = useSelector((state) => state.customization)
  const isGuestAvailable = authUser?.guestBooking || false
  const classes = useStyles({ noLabel })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [showUserSearch, setShowUserSearch] = useState(false)
  const [userForDob, setUserForDob] = useState(null)
  const [guestInList, setGuestInList] = useState(false)
  const [isGuestModal, setIsGuestModal] = useState(false)
  const [openOnFocus, setOpenOnFocus] = useState(true)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [inputVal, setInputVal] = useState('')

  const openTraveller = Boolean(isPopoverOpenTraveller)
  const divTravelerRef = useRef()
  const isAdultFunc = entityType === 'train' || entityType === 'car'

  const sortUsers = useCallback(
    (list) =>
      list.sort((a, b) => {
        const aIsFav = favoriteUsers?.includes(a.uniqueId)
        const bIsFav = favoriteUsers?.includes(b.uniqueId)
        if (aIsFav && !bIsFav) return -1
        if (bIsFav && !aIsFav) return 1

        const aName = `${a.firstName} ${a.lastName}`
        const bName = `${b.firstName} ${b.lastName}`
        return aName?.localeCompare(bName, 'sv', { sensitivity: 'base' })
      }),
    [favoriteUsers]
  )

  const searchUsers = useCallback(
    debounce(async (name) => {
      try {
        const searchData = { name, limit: 30 }
        const users = await fetchAllUsers(searchData, 'usersSearch')
        sortUsers(users)
        setOptions(users?.length ? users : [])
        setLoading(false)
      } catch (e) {
        if (!axios.isCancel(e)) {
          setOptions([])
          setLoading(false)
        }
      }
    }, 400),
    []
  )

  const inputChanged = useCallback(
    debounce((newInputValue) => {
      setOptions([])
      setLoading(true)
      setInputVal(newInputValue)
    }, 10),
    []
  )

  useEffect(() => {
    searchUsers(inputVal)
  }, [inputVal])

  const handleChange = (event, newValue) => {
    const guestClicked = newValue?.find((opt) => opt?.type === 'guests_button')
    if (guestClicked) return setIsGuestModal(true)

    const tmpTravellers = calculateAge(newValue)
    setValue(tmpTravellers)
    if (setIsPopoverOpenTraveller) setIsPopoverOpenTraveller(false)
  }

  const filterOptions = createFilterOptions({ matchFrom: 'any' })
  const filterWraper = (...args) => {
    const sVal = args[1].inputValue
    const fopts = filterOptions(...args)
    const guestIsAdded =
      isGuestAvailable && !loading && (!sVal || fopts.length <= 0)
    if (guestIsAdded) fopts.push({ type: 'guests_button' })
    setGuestInList(guestIsAdded)
    return fopts
  }

  const onUserClick = (checkUser, e) => {
    e.stopPropagation()
    if (!isAdultFunc) return
    if (checkUser && !checkUser.birthDate) {
      setUserForDob(checkUser)
    }
    return null
  }

  const calculateAge = (travelers) => {
    if (!travelers?.length) return travelers
    return travelers.map((t) => {
      const dob = t.birthDate || null
      const tmpUser = { ...t }
      if (dob) {
        const tmpDate = new moment(dob, 'YYYY-MM-DD')
        if (tmpDate.isValid()) tmpUser.age = moment().diff(tmpDate, 'years')
      }
      return tmpUser
    })
  }

  const userDobUpdated = (user) => {
    const updatedUsers = value?.map((u) =>
      user.uniqueId === u.uniqueId ? { ...user } : { ...u }
    )
    setValue(updatedUsers)
    setUserForDob(null)
  }

  const modalFinish = (value) => {
    setShowUserSearch(false)
    handleChange(null, value)
  }

  const addGuestUser = (user) => {
    setIsGuestModal(false)

    // fix to set focus on field but don't open popper
    setOpenOnFocus(false)
    setShowUserSearch(true)
    setTimeout(() => setOpenOnFocus(true), 1)

    if (!user?.uniqueId) return false
    handleChange(null, [...(value || []), user])
  }

  const renderUserButton = () => {
    return (
      <Button
        style={{ backgroundColor: 'transparent', padding: isMobile && 0 }}
        fullWidth
        disabled={!!disabled}
        disableRipple
        onClick={() => setShowUserSearch(!showUserSearch)}
        classes={{
          root: classes.showUserSearchBtn,
          label: classes.showUserSearchLabel,
        }}
      >
        {noLabel ? (
          ''
        ) : (
          <Typography
            variant="body1"
            classes={{ root: classes.startAdornment }}
            style={isIframe ? { marginBottom: 0 } : {}}
          >
            {t('traveler')}
          </Typography>
        )}
        <Box
          display="flex"
          alignItems={isIframe ? 'center' : 'flex-end'}
          pb="1px"
          style={{ minHeight: isMinimized || isIframe ? '0' : '32px' }}
        >
          <PersonAddIcon
            color="primary"
            fontSize={isIframe ? 'small' : 'medium'}
            className={isIframe ? classes.personIcon : null}
          />
          <Box
            ml={1}
            display="flex"
            alignItems="center"
            className={classes.travelersText}
          >
            {value?.length > 0 ? (
              <Typography className={classes.value}>
                {value[0].firstName} {value[0].lastName}
              </Typography>
            ) : (
              <Typography
                className={
                  isPopoverOpenTraveller && isMobile
                    ? classes.placeholderError
                    : classes.placeholder
                }
              >
                {t('add traveler')}
              </Typography>
            )}
            {value?.length > 1 ? (
              <Box ml={1} className={classes.travelersCount}>
                {value?.length - 1} till
              </Box>
            ) : null}
          </Box>

          {ticketClasses && (
            <ListItemSecondaryAction
              className={
                isMinimized
                  ? classes.ticketClassMinimized
                  : withUserModal
                  ? classes.ticketClassMobile
                  : classes.ticketClass
              }
            >
              <TicketClassSelector
                setTicketClass={setTicketClass}
                ticketClass={ticketClass}
                ticketClasses={ticketClasses}
                small={true}
              />
            </ListItemSecondaryAction>
          )}
        </Box>
      </Button>
    )
  }

  const handleFavoriteClick = (event, option) => {
    event.stopPropagation()
    let newFavs = favoriteUsers || []
    if (favoriteUsers?.includes(option.uniqueId)) {
      newFavs = newFavs.filter((u) => u !== option.uniqueId)
      dispatch(setFavoriteUsers(newFavs))
      fetchUpdateUserFavorites(newFavs)
    } else {
      newFavs = [...newFavs, option.uniqueId]
      dispatch(setFavoriteUsers(newFavs))
      fetchUpdateUserFavorites(newFavs)
    }
    let newOptions = [...options]
    sortUsers(newOptions)
    setOptions(newOptions)
  }

  useEffect(() => {
    let newOptions = [...options]
    sortUsers(newOptions)
    setOptions(newOptions)
  }, [favoriteUsers, sortUsers])

  const renderOption = (option, { inputValue }) => {
    if (option?.type === 'guests_button') {
      return (
        <Box className={classes.addGuestButton}>
          <AddCircleOutlineIcon
            viewBox={'2 2 21 21'}
            className={classes.addGuestIcon}
          />
          <Box className={classes.addGuestText}>{t('add guest traveler')}</Box>
        </Box>
      )
    }

    const nameMatches = match(option.firstName, inputValue)
    const nameParts = parse(
      option.firstName,
      !!nameMatches?.length ? nameMatches : []
    )

    const lastMatches = match(option.lastName, inputValue)
    const lastParts = parse(
      option.lastName,
      !!lastMatches?.length ? lastMatches : []
    )
    const isFavorite = favoriteUsers?.includes(option.uniqueId)

    return (
      <Box display="flex" alignItems="center" className={classes.option}>
        <SvgIcon viewBox="0 0 34 34" className={classes.userIcon}>
          <path d="M17 .333C7.8.333.333 7.8.333 17 .333 26.2 7.8 33.667 17 33.667c9.2 0 16.667-7.467 16.667-16.667C33.667 7.8 26.2.333 17 .333zm0 5c2.767 0 5 2.234 5 5 0 2.767-2.233 5-5 5s-5-2.233-5-5c0-2.766 2.233-5 5-5zM17 29c-4.167 0-7.85-2.133-10-5.367.05-3.316 6.667-5.133 10-5.133 3.317 0 9.95 1.817 10 5.133C24.85 26.867 21.167 29 17 29z" />
        </SvgIcon>
        <Box ml={2}>
          {nameParts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}{' '}
          {lastParts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
          <Typography className={classes.userEmail}>{option.email}</Typography>
        </Box>
        <Box ml="auto">
          {isFavorite && (
            <StarOutlined
              className={classes.favoriteIconActive}
              onClick={(event) => handleFavoriteClick(event, option)}
            ></StarOutlined>
          )}
          {!isFavorite && (
            <StarBorder
              className={classes.favoriteIcon}
              onClick={(event) => handleFavoriteClick(event, option)}
            ></StarBorder>
          )}
        </Box>
      </Box>
    )
  }

  const ageToTag = (user) => {
    const { age } = user
    if (!age) return t('adult')
    if (age >= 65) return t('senior')
    if (age > 25) return t('adult')
    return t('youth')
  }

  const renderGeneral = () => {
    return (
      <Box className={noLabel ? '' : classes.travelersInner}>
        {!showUserSearch && renderUserButton()}

        {showUserSearch && (
          <Box>
            <Autocomplete
              disabled={!!disabled}
              multiple
              fullWidth
              filterSelectedOptions
              disableClearable
              openOnFocus={openOnFocus}
              popupIcon={null}
              filterOptions={filterWraper}
              onBlur={() => {
                setShowUserSearch(!showUserSearch)
                setOpenOnFocus(true)
              }}
              options={options}
              loading={loading}
              loadingText={t('loading')}
              noOptionsText={''}
              onInputChange={(event, val) => inputChanged(val)}
              getOptionLabel={(option) =>
                option.firstName ? `${option.firstName} ${option.lastName}` : ''
              }
              getOptionSelected={(option, value) =>
                option.email === value.email
              }
              value={value}
              PopperComponent={(props) => (
                <Popper
                  {...{
                    ...props,
                    className:
                      props.className +
                      ` ${guestInList ? classes.autocompletePopper : ''}`,
                  }}
                  placement="bottom"
                  modifiers={{
                    offset: {
                      enabled: true,
                      offset: '-12,6',
                    },
                  }}
                  style={isIframe ? { width: '250px' } : { width: '500px' }}
                />
              )}
              onChange={(e, value) => {
                handleChange(e, value)
              }}
              renderOption={renderOption}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  let ageLabel = ''
                  if (isAdultFunc) ageLabel = ` (${ageToTag(option)})`
                  return (
                    <Chip
                      onClick={(e) => onUserClick(option, e)}
                      label={`${option.firstName} ${option.lastName}${ageLabel}`}
                      variant="outlined"
                      deleteIcon={<CloseIcon className={classes.icon} />}
                      className={classes.chip}
                      {...getTagProps({ index })}
                    />
                  )
                })
              }
              renderInput={(params) => {
                return (
                  <Box className={classes.searchBar}>
                    {!isIframe && (
                      <Typography
                        variant="body1"
                        classes={{ root: classes.startAdornment }}
                      >
                        {t('traveler')}
                      </Typography>
                    )}
                    <Box
                      className={classes.innerInput}
                      display="flex"
                      mb={isMinimized ? 0 : '-6px'}
                    >
                      {!isIframe && <PersonAddIcon color="primary" />}
                      <TextField
                        {...params}
                        autoFocus
                        classes={{ root: classes.searchBox }}
                        className={classes.input}
                        variant="outlined"
                        placeholder={
                          value?.length > 0 ? null : t('add traveler')
                        }
                      />
                    </Box>
                  </Box>
                )
              }}
            />
          </Box>
        )}
        <SelectUserDOBModal user={userForDob} onSave={userDobUpdated} />
        <AddGuestUserModal
          isOpen={isGuestModal}
          onAdd={addGuestUser}
          onCancel={() => setIsGuestModal(false)}
        />
      </Box>
    )
  }

  const renderWithModal = () => {
    return (
      <Box style={isIframe ? { width: '100%' } : {}}>
        <Box className={noLabel || isIframe ? '' : classes.travelersInner}>
          {renderUserButton()}
        </Box>
        <AddUsersModal
          entityType={entityType}
          handleFinish={modalFinish}
          value={value}
          handleClose={() => setShowUserSearch(false)}
          modalBoolean={showUserSearch}
        />
      </Box>
    )
  }

  const renderGeneralSearch = () =>
    withUserModal ? renderWithModal() : renderGeneral()

  return (
    <>
      {isIframe && !isMobile && !isMinimized ? (
        <Box
          ref={divTravelerRef}
          p={1}
          pt={0}
          pb={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            borderLeft: '1px solid #e6e6e6',
            height: '100%',
            width: '100%',
          }}
        >
          {renderGeneralSearch()}
        </Box>
      ) : (
        <Box
          ref={divTravelerRef}
          p={isMinimized ? 0 : 1}
          className={
            showUserSearch && !isMinimized
              ? classes.travelersLarge
              : classes.travelers
          }
          style={{
            boxShadow: userShadow
              ? '0px 20px 15px -20px rgba(0, 0, 0, .15)'
              : 'none',
            padding: isMobile && 0,
          }}
        >
          {/*{renderWithModal()}*/}
          {renderGeneralSearch()}
        </Box>
      )}

      {!isMobile ? (
        <Popover
          className={classes.popoverText}
          id="travelerField"
          open={openTraveller}
          anchorEl={divTravelerRef.current}
          onClose={handleClose}
          style={{ top: '-40px' }}
        >
          <Typography className={classes.popoverText}>
            {t('please enter traveler')}
          </Typography>
        </Popover>
      ) : (
        ''
      )}
    </>
  )
}

export default UsersSearch
