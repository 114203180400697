import constants from './constants'

const initialState = {
  headerHeight: 0,
  countriesList: [],
  hideTrains: false,
  onlyOidc: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_HEADER_HEIGHT:
      return {
        ...state,
        headerHeight: action.payload,
      }
    case constants.SET_COUNTRIES_LIST:
      return {
        ...state,
        countriesList: action?.payload?.length ? action?.payload : [],
      }

    case constants.SET_HIDE_TRAIN:
      return {
        ...state,
        hideTrain: action.payload,
      }

    case constants.SET_ONLY_OIDC:
      return {
        ...state,
        onlyOidc: action.payload,
      }

    default:
      return state
  }
}
