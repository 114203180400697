import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchOidcLoginUrl } from '../../repositories/auth'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import SsoError from '../../components/general/ssoError'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    marginTop: '20%',
    width: '600px',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    borderRadius: '10px',
  },

  btn: {
    borderRadius: '10px',
    boxShadow: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
    textTransform: 'none',
  },
}))

function OidcRedirect() {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { isAuthenticated } = useSelector((state) => state.auth)
  const { companyId } = useParams()
  const [error, setError] = useState()
  const history = useHistory()

  useEffect(() => {
    if (isAuthenticated) {
      console.log('already logged in - redirecting to front page')
      history.push('/')
      return
    }

    fetchOidcLoginUrl(companyId)
      .then((res) => {
        window.location.href = res.loginUrl
      })
      .catch((error) => {
        console.log(error)
        // Handle
        const errorMessage = error?.response?.data?.error || error
        setError(errorMessage)
      })
  }, [])

  return (
    <Box className={classes.root}>
      {error && (
        <Box className={classes.container}>
          <SsoError message={error} />
        </Box>
      )}
    </Box>
  )
}

export default OidcRedirect
