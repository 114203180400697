import { useEffect, useState } from 'react'
import { fetchCityCoordinatesAction, setCoordinatesAction } from '../store/hotels/hotelsAction'
import { fetchCityBasedOnCoordinates } from '../repositories/hotel'
import { useTranslation } from 'react-i18next'

const useCityChoose = (val, dispatch, params) => {
  const [city, setCity] = useState(val)
  const { i18n } = useTranslation()

  const fetchcity = async (lat, lng, lang, cb) => {
    const payload = { lat, lng, lang }
    const result = await fetchCityBasedOnCoordinates(payload)
    cb(result)
  }

  useEffect(() => {
    if (city && city.Id) {
      dispatch(fetchCityCoordinatesAction(city.Id))
    } else if (city && city.Coordinates) {
      dispatch(setCoordinatesAction(city))
    }
  }, [dispatch, city])

  useEffect(() => {
    if (params) {
      fetchcity(params.lat, params.lng, i18n.language, setCity)
    }
  }, [params, i18n.language])

  const setCityOnChange = (e, value) => {
    setCity(value)
    if (!value) {
      return
    }
    dispatch(fetchCityCoordinatesAction(value.Id))
  }

  return { city, setCityOnChange }
}

export default useCityChoose
