import React, { memo } from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import { Box, Typography, Divider, IconButton, Button } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { removeCheckoutItemAction } from '../../store/checkout/checkoutActions'
import { openTransferModalAction } from '../../store/modals/modalAction'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  justify: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  changeBtn: {
    borderRadius: '12px',
    color: theme.palette.primary.dark,
    background: theme.palette.primary.medium,
    padding: 0,
    fontSize: '12px',
    textTransform: 'none',
    fontWeight: 600,
  },

  closeBtn: {
    padding: 0,
  },
}))

const CardHeaderComponent = ({
  index,
  icon,
  type,
  TRANSFER,
  disableEdit = false,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const removeItem = () => {
    // opens transfer modal if TRANSFER is truthy
    if (TRANSFER) {
      dispatch(openTransferModalAction())
    }
    dispatch(removeCheckoutItemAction(index))
  }

  return (
    <>
      <Box className={classes.justify} pb={2}>
        <Box className={classes.align}>
          {icon}
          <Typography variant="body2" className={classes.bold}>
            {type}
          </Typography>
        </Box>

        {TRANSFER && !disableEdit && (
          <Button onClick={removeItem} className={classes.changeBtn}>
            {t('filter change')}
          </Button>
        )}

        {!TRANSFER && !disableEdit && (
          <IconButton onClick={removeItem} className={classes.closeBtn}>
            <CancelIcon color="disabled" p={0} />
          </IconButton>
        )}
      </Box>
      <Divider />
    </>
  )
}

export default memo(CardHeaderComponent)
