import React, { memo } from 'react'
import {
  Container,
  Paper,
  Typography,
  Breadcrumbs,
  CardMedia,
  Divider,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getBrandedUrl } from '../../../utils/branding'
import BookingLinkRow from '../bookingLinkRow'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  borderRadius: {
    borderRadius: '12px',
  },

  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(0.5),
      fontWeight: 500,
    },
    paddingTop: '14px',
    paddingBottom: '13px',
  },
  title: {
    fontWeight: 600,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  weight: {
    fontWeight: 600,
    fontSize: theme.spacing(2),
    color: theme.palette.common.black,
    textDecoration: 'none',
  },
  disabled: {
    color: '#999999',
    fontWeight: 600,
    fontSize: theme.spacing(2),
    textDecoration: 'none',
  },
  arrowIcons: {
    color: theme.palette.action.disabled,
    fontWeight: 600,
  },
  continueBtn: {
    fontSize: '18px',
    padding: '16px 60px',
  },
  btnContainer: {
    marginBottom: theme.spacing(6),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  fixedHeader: {
    borderRadius: 0,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
  },

  logo: {
    height: '40px',
    width: '120px',
    display: 'inline-block',
  },
  logoDivider: {
    color: theme.palette.primary.main,
    width: '1px',
    height: '40px',
    marginLeft: '20px',
    marginRight: '20px',
    display: 'inline-block',
  },
  companyLogo: {
    height: '40px',
    width: '120px',
    display: 'inline-block',
  },
}))

const DesktopDetailsHeader = ({ tripPrice = 0 }) => {
  const { breadcrumbs } = useSelector((state) => state)
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const classes = useStyles()
  const history = useHistory()
  const { isAuthenticated, user } = useSelector((state) => state.auth)
  const { landingPage, isIframe } = useSelector((state) => state.customization)
  const handleClick = () => {
    if (landingPage) {
      history.push('/')
    } else if (`/${breadcrumbs.type}` !== pathname) {
      history.push(`/${breadcrumbs.type}`)
    }
  }

  const logoUrl = getBrandedUrl('logo_retina.png')
  const companyLogoUrl = user?.companyLogo

  const isWidget = window.parent !== window

  const relevantType = breadcrumbs[breadcrumbs.type]

  const newPayload = {
    ...relevantType,
    viewHotels: breadcrumbs.type === 'hotels' ? '' : breadcrumbs.viewHotels,
    checkout: breadcrumbs.checkout,
  }

  // TODO: we probably should rework breadcrumbs system
  const pages = [
    { url: 'outgoing', label: t('breadcrumbs choose trip'), howMany: 1 },
    {
      url: '/flights/add-public-data',
      label: t('breadcrumbs traveler details'),
      howMany: !isAuthenticated ? 1 : 0,
    },
    {
      url: '/flights/details',
      label: t('breadcrumbs make ticket'),
      howMany: 2,
    },
    { url: '/middlestep', label: t('breadcrumbs view hotels'), howMany: 3 },
    { url: '/cars/search', label: t('breadcrumbs view cars'), howMany: 1 },
    {
      url: '/cars/add-middlestep',
      label: t('breadcrumbs view cars'),
      howMany: 1,
    },
    { url: '/cars/details', label: t('select the car'), howMany: 2 },
    {
      url: '/trains/add-public-data',
      label: t('breadcrumbs traveler details'),
      howMany: !isAuthenticated ? 1 : 0,
    },
    {
      url: '/trains/choose-seats',
      label: t('breadcrumbs make ticket'),
      howMany: 1,
    },
    { url: '/hotels/search', label: t('breadcrumbs view hotels'), howMany: 1 },

    { url: '/hotels/details', label: t('select the room'), howMany: 2 },

    { url: '/checkout', label: t('breadcrumbs pay'), howMany: 4 },
  ]

  const renderElements = Object.keys(newPayload).map((key, index) => {
    const type = newPayload?.[key]
    if (!type) return null

    const findRelevant = pages.find(
      (x) => type.includes(x.url) && x.howMany > 0
    )
    const currentPath = pages.find((x) => pathname.includes(x.url))
    if (!findRelevant) return findRelevant
    const shouldHaveWeight = currentPath.howMany >= findRelevant.howMany

    return (
      <Box display="flex" alignItems="center" key={index}>
        <NavLink
          onClick={
            !shouldHaveWeight ? (event) => event.preventDefault() : () => {}
          }
          to={type}
          className={shouldHaveWeight ? classes.weight : classes.disabled}
        >
          {findRelevant?.label}
        </NavLink>
      </Box>
    )
  })

  return (
    <Paper elevation={0} className={classes.fixedHeader}>
      <BookingLinkRow tripPrice={tripPrice} />
      <Container maxWidth="lg" disableGutters>
        <Box pl={0} className={classes.align}>
          <Box
            pr={5}
            display="flex"
            alignItems="center"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
          >
            <Typography variant="h5" className={classes.title}>
              {!isIframe && !isWidget && (
                <CardMedia
                  data-id="header-logo"
                  image={logoUrl}
                  className={classes.logo}
                />
              )}

              {companyLogoUrl && !isWidget && (
                <>
                  <Divider className={classes.logoDivider} />

                  <CardMedia
                    data-id="company-logo"
                    image={companyLogoUrl}
                    className={classes.companyLogo}
                    display="inline"
                  />
                </>
              )}
            </Typography>
          </Box>
          <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
            {renderElements}
          </Breadcrumbs>
        </Box>
      </Container>
    </Paper>
  )
}

export default memo(DesktopDetailsHeader)
