import React, { memo, useEffect, useState } from 'react'
import { Dialog, Typography, Box, CircularProgress } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  preloader: {
    marginBottom: '30px',
  },
  content: ({ isMobile }) => ({
    textAlign: 'center',
    padding: !isMobile ? '75px 60px' : '40px 20px',
  }),
  header: {
    fontSize: '32px',
    marginBottom: '22px',
    fontWeight: 'bold',
    color: theme.palette.common.black,
    lineHeight: 1,
  },
  text: {
    fontSize: '16px',
    color: theme.palette.common.black,
    lineHeight: 1.5,
  },
  slowText: {
    marginTop: '24px',
    borderRadius: '6px',
    backgroundColor: '#fff0a9',
    padding: theme.spacing(2),
  },
}))

const BookingProcessModal = ({
  isApprove = false
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const { t } = useTranslation()
  const [isSlow, setIsSlow] = useState(false)

  const { bookingProcess } = useSelector((state) => state.modal)

  useEffect(() => {
    if (bookingProcess) {
      const timer = setTimeout(() => {
        setIsSlow(true)
      }, 40000)

      return () => clearTimeout(timer)
    }
  }, [bookingProcess])

  return (
    <Dialog open={bookingProcess}>
      <Box className={classes.content}>
        <CircularProgress
          size={80}
          thickness={6}
          className={classes.preloader}
        />
        <Box className={classes.header}>
          <Box>{t('confirm modal title top')}</Box>
          <Box>{t(isApprove ? 'confirm modal approving title bottom' : 'confirm modal title bottom')}</Box>
        </Box>
        <Typography className={classes.text}>
          {t(isApprove ? 'confirm modal approving desc' : 'confirm modal desc')}
        </Typography>

        {isSlow && (
          <Box className={classes.slowText}>
            <Typography>{t('confirm modal slow')}</Typography>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default memo(BookingProcessModal)
