import { HIDE_SNACKBAR, SHOW_SNACKBAR } from './constants'

const initialState = {
  show: false,
  snackbarType: 'success',
  message: '',
}

export default (state = initialState, action) => {
  const { type, snackbarType, message, position = null } = action

  switch (type) {
    case SHOW_SNACKBAR:
      return { ...state, snackbarType, message, position, show: true }
    case HIDE_SNACKBAR:
      return { ...state, show: false }
    default:
      return state
  }
}
