import React, {Fragment, memo} from 'react'
import { Box, Typography } from '@material-ui/core'
import Moment from 'react-moment'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { makeStyles } from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  align: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
  },
  serviceName: {
    marginLeft: '20px',
    fontSize: '16px',
    color: theme.palette.common.black,
    letterSpacing: 'normal'
  },
  logo: {
    height: '12px',
    width: 'auto',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  chooseBtn: {
    borderRadius: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    width: '30px',
    height: '30px',
  },
}))

const FlightTaxiCheckoutCard = ({ transfer }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { startNode, endNode, product, type } = transfer
  const trName = (product?.name || product?.name || '').toLowerCase()
  const isArlandaExpress = trName.includes('arlanda express')

  const buildAddress = (node) => {
    if (!node?.location) return '';

    if (node.location.type === 'LOCATION') {
      return !!node.name ? `${node.name || ''} ${node.number || ''}${node?.letter || ''} (${node.location.community})` : node.location.name
    } else {
      return node.location.name;
    }
  }

  const correctTransferDate = (date) => {
    if (typeof date === 'string' && date.includes('+')) return date.split('+')?.[0] || date
    return date
  }

  const logo = (
    <img
      src={
        isArlandaExpress
        ? '/assets/companies/ARX.png'
        : type === 'flightTaxi'
        ? '/assets/companies/flygtaxi.png'
        : '/assets/companies/tagtaxi.png'
    }
      className={classes.logo}
      style={{height: isArlandaExpress ? '29px' : '12px'}}
      alt={'taxi'}
    />
  )

  return (
    <Box>
      <Typography gutterBottom>
        <Moment format="ddd D MMM">
          {correctTransferDate(startNode?.time)}
        </Moment>
        {!!isArlandaExpress ? (
          <span> - {t('valid any departure')}</span>
        ) : (
          <Moment format="HH:mm" style={{marginLeft: '4px', fontWeight: 'bold'}}>{correctTransferDate(startNode?.time)}</Moment>
        )}
      </Typography>
      <Box className={classes.align}>
        <Typography display={"inlineBlock"} className={classes.ellipsis}>{buildAddress(startNode?.street)}</Typography>
        <ArrowForwardIcon />
        <Typography display={"inlineBlock"} className={classes.ellipsis}>{buildAddress(endNode?.street)}</Typography>
      </Box>
      <Box mt={'16px'} className={classes.align}>
        {logo}
        <span className={classes.serviceName}>{product?.name}</span>
      </Box>
    </Box>
  )
}

export default memo(FlightTaxiCheckoutCard)
