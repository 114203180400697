import React, { memo } from 'react'
import Container from '@material-ui/core/Container'
import { useMediaQuery, Box } from '@material-ui/core'
import MultiSearch from '../../containers/search/multiSearch'
import { useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import CustomPage from './customPage'

const Custom = ({ pageId }) => {
  const { pages } = useSelector((state) => state.customization)

  let page = pages.find((p) => p?.['_id'] === pageId) || {}

  const footerPage = pages?.find((p) => p.isFooterPage)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      width={'100%'}
      className={isMobile ? 'cms-mobile-section' : 'cms-desktop-section'}
    >
      <Container maxWidth={false} disableGutters>
        {page.isStartPage && <MultiSearch />}

        <CustomPage pageId={pageId}></CustomPage>
      </Container>

      {footerPage && (
        <Container maxWidth={false} disableGutters>
          <CustomPage pageId={footerPage._id}></CustomPage>
        </Container>
      )}
    </Box>
  )
}

export default memo(Custom)
