import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography, Divider } from '@material-ui/core'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { getUsersPhones } from '../../../repositories/users'
import { setItemsPassengerField } from '../../../store/checkout/checkoutActions'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotifyBlock from './notifyBlock'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  normalText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  radioInput: {
    color: theme.palette.primary.main,
  },
  optBlock: {
    '&:last-child .payment-divider': {
      display: 'none',
    },
  },
  checkbox: {
    width: '100%',
  },
}))
const TicketDeliveryType = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const formik = useFormikContext()
  const dispatch = useDispatch()
  const [phoneFetchStatus, setPhoneFetchStatus] = useState(null)
  const { items } = useSelector((state) => state.checkout)

  const fetchUsersPhones = useCallback(async () => {
    setPhoneFetchStatus('loading')
    let allPassengers = []
    items.forEach((item) => {
      const tmpPassengers = []
      ;(item.passengers || []).forEach((p) => {
        if (!p.mobile && !p.isPublic) tmpPassengers.push(p.uniqueId)
      })
      if (tmpPassengers.length)
        allPassengers = [...allPassengers, ...tmpPassengers]
    })

    if (allPassengers.length > 0) {
      const res = await getUsersPhones(allPassengers)
      if (res?.phones) {
        const updateFields = {}
        for (let uid in res?.phones) {
          updateFields[uid] = { mobile: res?.phones[uid] }
        }
        //set fetched user phones
        dispatch(setItemsPassengerField(updateFields))
      }
    }
    setPhoneFetchStatus('fetched')
  }, [items, dispatch])

  useEffect(() => {
    if (!phoneFetchStatus) {
      fetchUsersPhones()
    }
  }, [fetchUsersPhones, phoneFetchStatus])

  const availableOpts = useMemo(
    () => [
      {
        name: t('e-ticket via email'),
        value: 'email',
        note: t('tkt delivery email note'),
      },
      // NDS - disabled for now
      // {
      //   name: t('ticket via sms'),
      //   value: 'phone',
      //   note: t('tkt delivery phone note'),
      // },
    ],
    [t]
  )

  const renderDeliveryOptions = availableOpts?.map((option, i) => (
    <Box className={classes.optBlock} key={i}>
      {!!option.note && formik.values.eticketDelivery === option.value && (
        <Box pt={i > 0 ? 3 : 2}>
          <NotifyBlock noSubNote noteType={'paper'} noteText={option.note} />
        </Box>
      )}

      <Box py={1}>
        <FormControlLabel
          className={classes.checkbox}
          value={option.value}
          control={<Radio className={classes.radioInput} color="primary" />}
          label={option.name}
        />
      </Box>
      <Divider className={'payment-divider'} />
    </Box>
  ))

  return (
    <Box py={2}>
      <Box>
        <Box>
          <Typography variant="h5" className={classes.bold}>
            <span>{t('ticket delivery')}</span>{' '}
            <span className={classes.normalText}>
              {t('train').toLowerCase()}
            </span>
          </Typography>
        </Box>

        <Box>
          {phoneFetchStatus === 'loading' ? (
            <Box pt={2}>
              <CircularProgress />
            </Box>
          ) : (
            <FormControl component="fieldset" className={classes.checkbox}>
              <RadioGroup
                onChange={formik.handleChange}
                className={classes.checkbox}
                value={formik.values.eticketDelivery}
                name={'eticketDelivery'}
              >
                {renderDeliveryOptions}
              </RadioGroup>
            </FormControl>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default TicketDeliveryType
