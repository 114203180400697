import React, {memo, useContext, useEffect, useMemo, useState} from 'react'
import { Box } from '@material-ui/core'
import FlightBusCard from './transferCards/flightBusCard'
import FlightTaxiCard from './transferCards/flightTaxiCard'
import Pagination from './transferCards/pagination'
import { useSelector } from 'react-redux'
import {TransfersContext} from '../../../containers/transfer/transferContainer'

const ShowTransfersBlock = ({
  index,
  options,
  current,
  removeTransfer,
  addNewTransfer,
  setTransferPassenger,
  touched = false,
}) => {
  const [currPage, setCurrPage] = useState(options?.length || 1)
  const [locallyTouched, setLocallyTouched] = useState(false)
  const [addNewNoteTouch, setAddNewNoteTouch] = useState(false)
  const {allUsers} = useContext(TransfersContext)
  const { items } = useSelector((state) => state.checkout)

  const itemsCount = useMemo(() => {
    return !options?.length ? 1 : options.length
  }, [options])

  useEffect(() => {
    if (currPage < 1 || currPage > itemsCount) {
      setCurrPage(1)
    }
  }, [currPage, itemsCount])

  const transfer = useMemo(() => {
    if (!options) return null
    if (!options?.length) return options
    return options?.[currPage - 1] || options[0]
  }, [currPage, options])

  const transfersWithoutUser = useMemo(() => {
    const result = []
    options.forEach((t, i) => {
      if (!!t?.isAdded) return false
      const isRail =
        t.type === 'flightBus' ||
        (t?.type === 'tagTaxi' && t?.product?.code === 'RAILE')
      if (
        !t.passenger ||
        (isRail && typeof t.passenger !== 'object') ||
        !t.passenger?.length
      ) {
        result.push(i + 1)
      }
    })
    return result
  }, [options])

  const remainedUsers = useMemo(() => {
    const used = []
    options?.forEach((t, i) => {
      const isTrain =
        t?.type === 'flightBus' ||
        (t?.type === 'tagTaxi' && t?.product?.code === 'RAILE')

      if (
        !t?.passenger ||
        (isTrain && (typeof t.passenger !== 'object' || !t.passenger?.length))
      ) {
        return false
      }
      if (isTrain) {
        t.passenger?.forEach((pId) => {
          if (!used.includes(pId)) used.push(pId)
        })
      } else {
        if (!used.includes(t.passenger)) used.push(t.passenger)
      }
    })

    const notUsed = []
    allUsers?.forEach((p) => {
      if (
        !p?.uniqueId ||
        used.includes(p.uniqueId) ||
        notUsed.includes(p.uniqueId)
      )
        return false
      notUsed.push(p.uniqueId)
    })

    return notUsed
  }, [allUsers, options])

  const isTouched = useMemo(() => {
    return touched || locallyTouched
  }, [locallyTouched, touched])

  const noUsersNotify = useMemo(() => {
    if (!remainedUsers?.length && addNewNoteTouch) {
      return { type: 'warning', text: 'all travellers are associated' }
    }
    return null
  }, [remainedUsers, addNewNoteTouch])

  const setPage = (page) => {
    let newPage = page
    if (newPage < 1) newPage = 1
    if (newPage > itemsCount) newPage = itemsCount
    setCurrPage(newPage)
  }

  const onAddNew = () => {
    if (transfersWithoutUser.length > 0) {
      setLocallyTouched(true)
      return false
    } else if (!remainedUsers?.length) {
      setAddNewNoteTouch(true)
      return false
    }

    addNewTransfer()
  }

  const pagination = (
    <Pagination
      currentPage={currPage}
      totalItems={itemsCount}
      onAddNew={() => onAddNew()}
      onChangePage={setPage}
      errors={isTouched ? transfersWithoutUser : null}
    />
  )

  const onPassengersSelect = (val) => {
    setAddNewNoteTouch(false)
    setTransferPassenger({
      uniqueKey: transfer?.uniqueKey,
      passenger: val,
    })
  }

  const renderOptions = (
    <Box key={index}>
      <Box display="flex" flexDirection="column">
        <Box>
          {transfer?.type === 'flightBus' ? (
            <FlightBusCard
              model={transfer}
              current={current}
              removeable
              removeTransfer={removeTransfer}
              pagination={pagination}
              notification={noUsersNotify}
              onPassengerSelect={onPassengersSelect}
              goToTransfer={setPage}
              touched={isTouched}
              show
            />
          ) : (
            ['tagTaxi', 'flightTaxi'].includes(transfer?.type) && (
              <FlightTaxiCard
                model={transfer}
                current={current}
                removeable
                removeTransfer={removeTransfer}
                pagination={pagination}
                notification={noUsersNotify}
                onPassengerSelect={onPassengersSelect}
                goToTransfer={setPage}
                touched={isTouched}
                show
              />
            )
          )}
        </Box>
      </Box>
    </Box>
  )

  return <Box>{renderOptions}</Box>
}

export default memo(ShowTransfersBlock)
