import React, {Fragment, memo} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { Box, Card } from '@material-ui/core'
import CardHeader from '../../components/checkout/cardHeader'
import TransferViewHandlerCheckout from './transfer/transferViewHandlerCard'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '12px',
  },
}))

const TranferCardFull = ({ model, index, disableEdit = false, contentOnly = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { details } = model
  const renderRoutes = (
    <Box p={2}>
      <CardHeader
        TRANSFER
        index={index}
        icon={<SwapHorizIcon />}
        type={t('transfers').toUpperCase()}
        disableEdit={disableEdit}
      />
      <Box>
        <TransferViewHandlerCheckout details={details} />
      </Box>
    </Box>
  )

  return (
    <Fragment>
      {contentOnly ? renderRoutes : (
        <Card elevation={0} className={classes.card}>
          {renderRoutes}
        </Card>
      )}
    </Fragment>
  )
}

export default memo(TranferCardFull)
