export const rollbarConfig = {
  accessToken: process.env.ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV || 'production',
  enabled: process.env.NODE_ENV === 'production',
  server: {
    root: window.location.hostname,
  },
  code_version: '0.13.7',
}
