import React, { memo } from 'react'
import { Box, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { Button } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(() => ({
  onLoadmoreTrains: {
    textTransform: 'none',
    fontSize: '14px',
  },
  paper: {
    borderRadius: '0 0 12px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const NextResultsScroller = ({ click, show }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box mb={40}>
      <Paper elevation={0} className={classes.paper}>
        {show && (
          <Box m={2}>
            <Button
              onClick={click}
              className={classes.onLoadmoreTrains}
              startIcon={<ExpandMore color="primary" fontSize="large" />}
            >
              {t('later trips')}
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default memo(NextResultsScroller)
