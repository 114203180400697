import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Typography,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ModalHook from './reusable/modalHook'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 20px',
    marginBottom: '40px',
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 12px 0 12px',

    '& .MuiIconButton-root': {
      padding: '11px',
    },
  },
  headerText: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '33px',
  },
  btns: {
    borderRadius: theme.spacing(3),
    margin: '0',
    padding: theme.spacing('10px', 4),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing('10px', 2),
    },
  },
}))

const TripCalendarFiltersModal = ({
  modalBoolean,
  iniFilters,
  onChange,
  onClose,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [filters, setFilters] = useState({
    withBookings: false,
    withUpcomingBookings: false,
  })

  useEffect(
    () => {
      setFilters({ ...filters, ...(iniFilters || {}) })
    },
    // eslint-disable-next-line
    [modalBoolean, iniFilters]
  )

  const setFilter = (name, val) => {
    setFilters({ ...filters, [name]: val })
  }

  const reset = () => {
    setFilters({
      withBookings: false,
      withUpcomingBookings: false,
    })
  }

  const checkboxChange = (e) => setFilter(e.target.name, !!e.target.checked)

  const closeModal = () => {
    onClose()
  }

  const onSubmit = () => {
    onChange({ ...filters })
  }

  const activeCount = useMemo(() => {
    const defaultFilters = {
      withBookings: false,
      withUpcomingBookings: false,
    }
    let count = 0

    for (let fKey in defaultFilters) {
      const DF = defaultFilters?.[fKey]
      const CF = filters?.[fKey]

      if (DF !== CF) count++
    }

    return count
  }, [filters])

  const buildDefaultContent = () => {
    return (
      <Box key={'root-content-container'} className={classes.root}>
        <Box width={'100%'} key={'root-content-container-inner'}>
          <Typography
            gutterBottom
            className={classes.headerText}
            variant="h4"
            align="center"
            key={'modal-header'}
          >
            {t('filters')} {!!activeCount ? `(${activeCount})` : ''}
          </Typography>

          <Box key={'filters-outer-block'}>
            <Box key={'withUpcomingBookings-filter-block'}>
              <FormControlLabel
                className={classes.checkboxLabel}
                key={'withUpcomingBookings-filter'}
                control={
                  <Checkbox
                    checked={filters?.withUpcomingBookings || false}
                    color="primary"
                    name="withUpcomingBookings"
                    onChange={checkboxChange}
                  />
                }
                label={t('travelers with upcoming bookings')}
                labelPlacement="end"
              />
            </Box>

            <Box key={'withBookings-filter-block'}>
              <FormControlLabel
                className={classes.checkboxLabel}
                key={'withBookings-filter'}
                control={
                  <Checkbox
                    checked={filters?.withBookings || false}
                    color="primary"
                    name="withBookings"
                    onChange={checkboxChange}
                  />
                }
                label={t('travelers with bookings')}
                labelPlacement="end"
              />
            </Box>
          </Box>
        </Box>
        <Box key={'buttons-block-outer'} width={'100%'} mt={'31px'}>
          <Grid key={'buttons-block-inner'} spacing={1} container>
            <Grid key={'cancel-button-block'} item xs={6}>
              <Button
                key={'cancel-button'}
                className={classes.btns}
                color="primary"
                variant="outlined"
                onClick={closeModal}
              >
                {t('cancel btn')}
              </Button>
            </Grid>
            <Grid key={'done-button-block'} item xs={6}>
              <Button
                key={'done-button'}
                disableElevation
                className={classes.btns}
                color="primary"
                variant="contained"
                onClick={onSubmit}
              >
                {t('done')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }

  return (
    <ModalHook
      minHeight={300}
      width={340}
      mobileFullWidth={false}
      modalBoolean={modalBoolean}
      key="TripCalendarFiltersModal-outer"
    >
      <Box key="TripCalendarFiltersModal-header" className={classes.header}>
        <Button
          disabled={activeCount <= 0}
          style={{ textTransform: 'capitalize' }}
          color="primary"
          onClick={reset}
          key={'reset-h-button'}
        >
          {t('reset')}
        </Button>
        <IconButton
          key={'close-h-button'}
          color="primary"
          className={classes.closeBtn}
          onClick={closeModal}
        >
          <CloseIcon key={'close-h-button-icon'} />
        </IconButton>
      </Box>
      {buildDefaultContent()}
    </ModalHook>
  )
}

export default memo(TripCalendarFiltersModal)
