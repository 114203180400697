import React, { memo } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileSearchNav from '../../../components/search/mobileSearchNav'
import ReusableDatePicker from '../../../components/reusable/datePicker/reusableDatePicker_new'
import AutoCompleteInput from '../../../components/reusable/autoCompleteInput'
import AutoCompleteInputHotel from '../../../components/reusable/autoCompleteInputHotel'
import { useTranslation } from 'react-i18next'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { IconButton, useMediaQuery } from '@material-ui/core'
import { ReactComponent as DoubleArrow } from '../../../assets/images/Arrows-WHT.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import SelectCounts from '../../../components/reusable/selectCounts'
import { useSelector } from 'react-redux'

const isApp = window.location.hostname === 'app.citycity.se'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: isApp ? theme.spacing(5) : theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  searchBtn: {
    fontWeight: 600,
    letterSpacing: 1.1,
    fontSize: '16px',
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2),
  },
  box: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(0.5),
  },
  fromInputBox: {
    padding: theme.spacing(1),
    borderBottom: `1px dashed ${theme.palette.border}`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  },
  toInputBox: {
    padding: theme.spacing(1),
    width: '100%',
    borderTop: 0,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
  },
  swapContainer: {
    position: 'absolute',
    right: 24,
    top: 42,
    background: theme.palette.common.white,
  },
  swapButton: {
    padding: 0,
    transform: 'rotate(90deg)',
  },

  errorBox: {
    border: `1px solid #e02020`,
    borderRadius: theme.spacing(0.5),
    width: '100%',
  },

  destinationBox: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(0.5),
    width: '100%',
  },

  hotelErrorBox: {
    border: `1px solid #e02020`,
    borderRadius: theme.spacing(0.5),
  },
  eventIcon: {
    color: theme.palette.iframeSecondary.main
  }
}))

const GuestMobileSearch = ({
  /* */
  from,
  entityType=null,
  setFrom,
  to,
  setTo,
  /**/
  departure,
  setDeparture,
  arrival,
  setArrival,
  switchDestinations,
  toggleTravelType,
  isOneWay,
  onSubmit,
  /* */
  getCities,
  optionLabel,
  array,
  renderOption,
  /**/
  HOTEL,
  setHotel,
  /**/
  setIsPopoverOpenFrom,
  isPopoverOpenFrom,
  setIsPopoverOpenTo,
  isPopoverOpenTo,
  counts,
  modifyCounts,
  setTicketClass,
  ticketClass,
  ticketClasses,
  TRAIN
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { isIframe } = useSelector((state) => state.customization)

  return (
    <Paper className={classes.paper} elevation={0} paddingBottom={4}>
      <Box mb={0.5}>
        <MobileSearchNav />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} container>
          {HOTEL ? (
            <Grid item xs={12}>
              <Box
                p={1}
                className={
                  !isPopoverOpenFrom ? classes.box : classes.hotelErrorBox
                }
              >
                <AutoCompleteInputHotel
                  value={from}
                  onChange={setHotel}
                  array={array}
                  fetch={getCities}
                  optionLabel={optionLabel}
                  destinationModal={true}
                  label={'Destination'}
                  icon
                  isPopoverOpenFrom={isPopoverOpenFrom}
                />
              </Box>
            </Grid>
          ) : (
            <Box
              className={
                !isPopoverOpenFrom && !isPopoverOpenTo
                  ? classes.destinationBox
                  : classes.errorBox
              }
            >
              <Grid item xs={12}>
                <Box className={classes.fromInputBox} position="relative">
                  <AutoCompleteInput
                    icon={<RadioButtonUncheckedIcon color="primary" className={isIframe && classes.eventIcon}/>}
                    renderOption={renderOption}
                    fetch={getCities}
                    value={from}
                    onChange={(_, value) => {
                      setFrom(value)
                      setIsPopoverOpenFrom(false)
                    }}
                    array={array}
                    optionLabel={optionLabel}
                    destinationModal={true}
                    label={t('search from')}
                    placeholder={t('search from')}
                    isPopoverOpenFrom={isPopoverOpenFrom}
                  />
                  <Box className={classes.swapContainer}>
                    <IconButton
                      onClick={switchDestinations}
                      className={classes.swapButton}
                    >
                      <SwapHorizIcon color="primary" className={isIframe && classes.eventIcon}/>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.toInputBox}>
                  <AutoCompleteInput
                    icon={<LocationOnIcon color="primary" className={isIframe && classes.eventIcon}/>}
                    renderOption={renderOption}
                    fetch={getCities}
                    value={to}
                    onChange={(_, value) => {
                      setTo(value)
                      setIsPopoverOpenTo(false)
                    }}
                    array={array}
                    optionLabel={optionLabel}
                    destinationModal={true}
                    label={t('search to')}
                    placeholder={t('search to')}
                    isPopoverOpenTo={isPopoverOpenTo}
                  />
                </Box>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <Box paddingTop={1} paddingBottom="9px" paddingX={1} className={classes.box}>
              <ReusableDatePicker
                disableLabel
                value={departure}
                onChange={setDeparture}
                from={departure}
                to={arrival}
                isOutgoing
                back
              />
            </Box>
          </Grid>
          {
            TRAIN && (
              <Grid item xs={6}>
                <Box paddingTop={1} paddingBottom="9px" paddingX={1} className={classes.box}>
                  <ReusableDatePicker
                    disableLabel
                    value={departure}
                    onChange={setDeparture}
                    from={departure}
                    to={arrival}
                    isOutgoing
                    back
                    timeOnly
                  />
                </Box>
              </Grid>
            )
          }
          <Grid item xs={6}>
            <Box paddingTop={1} paddingBottom="9px" paddingX={1} className={classes.box}>
              <ReusableDatePicker
                disableLabel
                value={arrival}
                onChange={setArrival}
                toggleTravelType={toggleTravelType}
                isOneWay={isOneWay}
                minDate={departure}
                HOTEL={HOTEL}
                back={false}
                from={departure}
                to={arrival}
              />
            </Box>
          </Grid>
          {
            TRAIN && (
              <Grid item xs={6}>
                <Box paddingTop={1} paddingBottom="9px" paddingX={1} className={classes.box}>
                  <ReusableDatePicker
                    disableLabel
                    value={arrival}
                    onChange={setArrival}
                    toggleTravelType={toggleTravelType}
                    isOneWay={isOneWay}
                    minDate={departure}
                    HOTEL={HOTEL}
                    back={false}
                    from={departure}
                    to={arrival}
                    timeOnly
                  />
                </Box>
              </Grid>
            )
          }
        </Grid>
        {counts ? (
          <Grid item xs={12}>
            <Box pl={1} pr={1} className={classes.box}>
              <SelectCounts
                isHotel={!!HOTEL}
                mobileSearch
                counts={counts}
                modifyCounts={modifyCounts}
                setTicketClass={setTicketClass}
                ticketClass={ticketClass}
                ticketClasses={ticketClasses}
              />
            </Box>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <Box mt={HOTEL ? 0 : '11px'} mb={isMobile ? 0 : 7}>
            <Button
              disableElevation
              onClick={onSubmit}
              variant="contained"
              color="primary"
              fullWidth
              endIcon={<SvgIcon style={{ fontSize: '18px' }}><DoubleArrow /></SvgIcon>}
              className={classes.searchBtn}
            >
              {t('search go')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default memo(GuestMobileSearch)