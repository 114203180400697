import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Modal from '@material-ui/core/Modal'
import Box from '@material-ui/core/Box'
import Backdrop from '@material-ui/core/Backdrop'
import { useDispatch } from 'react-redux'
import { closeAllModals } from '../../../store/modals/modalAction'
import isWidget from '../../../utils/isWidget'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    alignItems: 'center',
    flexDirection: 'column',
    padding: ({ mobileFullWidth }) =>
      !mobileFullWidth ? theme.spacing(0, 1) : 0,
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: (props) => (props.isMobile ? 0 : theme.spacing(1.5)),
    outline: 0,
    overflow: 'hidden',
  },
}))

const ModalHook = ({
  children,
  modalBoolean,
  width = 800,
  height,
  fullHeight,
  display,
  onClose,
  mobileFullWidth = true,
  noBackdrop,
}) => {
  const dispatch = useDispatch()
  const handleClose = (_event, reason) => {
    if (reason === 'backdropClick' && noBackdrop) {
      return
    }
    return !onClose ? dispatch(closeAllModals()) : onClose()
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile, mobileFullWidth })

  const style = {
    height: '100%',
    margin: isMobile || fullHeight ? '0' : `16px auto`,
  }

  const containerStyle = {
    height: isMobile && mobileFullWidth ? '100%' : height,
    minHeight: isMobile && mobileFullWidth ? '-webkit-fill-available' : 'auto',
    maxWidth: isMobile && mobileFullWidth ? '100%' : width,
    flexDirection: 'column',
    borderRadius: isMobile && mobileFullWidth ? '0' : '12px',
    marginBottom: isMobile ? '0' : '32px',
    overflowY: 'auto',
  }

  if (display) containerStyle['display'] = display

  return (
    <Modal
      style={style}
      aria-labelledby="seat-reservation-modal"
      aria-describedby="seat-reservation-transitition"
      className={classes.modal}
      open={modalBoolean}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      hideBackdrop={isWidget ? true : false}
    >
      <Box style={containerStyle} className={classes.paper}>
        {children}
      </Box>
    </Modal>
  )
}

export default memo(ModalHook)
