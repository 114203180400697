import {
  Card,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    marginTop: '72px',
  },
  box: {
    padding: '12px',
    margin: '6px',
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontWeight: theme.typography.fontWeightMedium,
    height: '100%',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
  },
  info: {
    fontSize: '14px',
    paddingLeft: '10px',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  btn: {
    borderRadius: theme.spacing(3),
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1, 8),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: 'unset',
    cursor: 'pointer',
  },
}))

function groupTravels() {
  const classes = useStyles()

  const openPage = () => {
    window.open(
      `//app.bigtravel.se/shared/create_event/expressBooking?redirectUrl=https:%2F%2Fapp.bigtravel.se&locale=sv`,
      '_blank'
    )
  }

  return (
    <Container maxWidth="md" disableGutters className={classes.container}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Card className={classes.box}>
            <button onClick={openPage} className={classes.btn}>
              Boka gruppresor
            </button>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.box} style={{ alignItems: 'start' }}>
            <Typography className={classes.title}>
              Kontakta oss för gruppresor
            </Typography>

            <p className={classes.info}>
              <p className={classes.bold}>
                Är ni fler än 9 som ska resa? Kontakta oss så hjälper vi till!
              </p>
              <p>
                Vi projektleder, bokar gruppbiljetter och undersöker plats och
                kapacitet med våra partners och leverantörer för att skicka en
                komplett offert baserad på era behov.
              </p>
              <p>Välkommen att kontakta oss för bokning och mer information.</p>
              <p className={classes.bold}>Kontaktuppgifter:</p>
              <p>Telefon: 0771-10 20 20</p>
              <p>Epost: event@bigtravel.se</p>
            </p>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
export default groupTravels
