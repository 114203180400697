import AuthWrapper from '../../containers/authWrapper/authWrapper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  useTheme,
  FormHelperText,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { changePasswordAction } from '../../store/auth/authAction'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '46px',
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    marginBottom: theme.spacing(6),
  },
  text: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(4.5),
    maxWidth: '300px',
    textAlign: 'center',
  },
  inputHeading: {
    fontSize: theme.spacing(2),
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
  },
  input: {
    borderRadius: theme.spacing(0.75),
  },
  helperText: {
    paddingLeft: '15px',
  },
  formControl: {
    marginBottom: theme.spacing(2.5),
    width: '100%',
  },
  button: {
    fontSize: theme.spacing(2.25),
    padding: theme.spacing(1.5, 10),
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.spacing(4),
    letterSpacing: '1.1px',
    marginTop: theme.spacing(2.5),
  },
}))

const ResetPassword = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [showPassword, setShowPassword] = useState({
    password: false,
    rePassword: false,
  })

  const onSubmit = async (values) => {
    const params = history.location.search
      .substring(1)
      .split('&')
      .reduce((acc, el) => {
        const name = el.split('=')[0]
        const value = el.split('=')[1]

        return { ...acc, [name]: value }
      }, {})
    if (params.code && params.email) {
      try {
        await dispatch(changePasswordAction({ ...params, ...values }))

        history.push('/')
      } catch (e) {
        console.log(e)
      }
    }
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('too short'))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&#()]{6,}$/,
        t('at least one')
      )
      .required(t('required')),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      t('must match')
    ),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit,
  })

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <AuthWrapper>
      <form onSubmit={formik.handleSubmit}>
        <Box
          mt={6}
          px={isMobile ? 2 : 7}
          pb={7}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography className={classes.heading}>
            {t('select password')}
          </Typography>
          <Typography className={classes.inputHeading}>
            {t('new password')}
          </Typography>
          <FormControl
            error={formik.errors.password && formik.touched.password}
            className={classes.formControl}
          >
            <OutlinedInput
              fullWidth
              name="password"
              type={showPassword.password ? 'text' : 'password'}
              error={formik.errors.password && formik.touched.password}
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              onFocus={() => (formik.touched.password = true)}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              className={classes.input}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password,
                      })
                    }
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                tabIndex: '1',
                autoComplete: 'off',
                spellCheck: 'false',
                autoCorrect: 'off',
                style: { fontWeight: 500 },
              }}
            />
            {!!formik.errors.password && !!formik.touched.password && (
              <FormHelperText className={classes.helperText}>
                {formik.errors.password}
              </FormHelperText>
            )}
          </FormControl>
          <Typography className={classes.inputHeading}>
            {t('confirm password')}
          </Typography>
          <FormControl
            error={
              formik.errors.confirmPassword && formik.touched.confirmPassword
            }
            className={classes.formControl}
          >
            <OutlinedInput
              fullWidth
              type={showPassword.rePassword ? 'text' : 'password'}
              value={formik.values.confirmPassword}
              error={
                formik.errors.confirmPassword && formik.touched.confirmPassword
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              onFocus={() => (formik.touched.confirmPassword = true)}
              onChange={formik.handleChange}
              name="confirmPassword"
              InputLabelProps={{ shrink: false }}
              className={classes.input}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        rePassword: !showPassword.rePassword,
                      })
                    }
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword.rePassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                tabIndex: '2',
                autoComplete: 'off',
                spellCheck: 'false',
                autoCorrect: 'off',
                style: { fontWeight: 500 },
              }}
            />
            {!!formik.touched.confirmPassword &&
              !!formik.errors.confirmPassword && (
                <FormHelperText className={classes.helperText}>
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword}
                </FormHelperText>
              )}
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            className={classes.button}
          >
            {t('complete')}
          </Button>
        </Box>
      </form>
    </AuthWrapper>
  )
}

export default ResetPassword
