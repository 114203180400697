import React, { useEffect, useRef, useState } from 'react'
import Picker from 'react-datepicker'
import moment from 'moment'
import { getAbsoluteMonths } from '../../../../utils/getAbsoluteMonths'
// import { Box, Button } from '@material-ui/core'

const SelectDate = ({ goBack, handleChange, from, to, isOutgoing }) => {
  const dayRef = useRef()
  const [openedBefore, setOpenedBefore] = useState(false)

  useEffect(() => {
    if (dayRef.current) {
      document.getElementsByClassName('react-datepicker')[0].scrollTo({ behavior: 'smooth', top: dayRef.current.parentNode?.parentNode?.parentNode.offsetTop - 150 })
    }
  })

  const onSelect = (day) => {
    setOpenedBefore(true)

    if (isOutgoing) {
      handleChange('checkIn', moment(day).format('YYYY-MM-DD'))
    } else {
      handleChange('checkOut', moment(day).format('YYYY-MM-DD'))
    }
    goBack()
  }

  const selectMonth = () => {
    if (!openedBefore) {
      return moment().toDate()
    }
    const monthToAdd = getAbsoluteMonths(moment(from)) - getAbsoluteMonths(moment())
    return moment().add(monthToAdd, 'months').toDate()
  }

  const selectClassName = (day) => {
    let classes = ``
    if (moment(to).isSame(from, 'day')) {
      classes += 'react-datepicker__day--range-end'
    } else if (moment(from).isSame(day, 'day') && !to) {
      classes += 'react-datepicker__day--range-end'
    }
    if (to) {
      classes += ' react-datepicker__day--has-end'
    }
    if (from) {
      classes += ' react-datepicker__day--has-start'
    }
    return classes
  }


  return (
    <>
      <Picker
        startDate={new Date(from)}
        endDate={new Date(to)}
        minDate={isOutgoing ? new Date() : new Date(from)}
        monthsShown={6}
        showPreviousMonths={false}
        dayClassName={selectClassName}
        selectsStart={isOutgoing}
        selectsEnd={!isOutgoing}
        formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}
        openToDate={selectMonth()}
        locale="en-GB"
        renderDayContents={(day, date) => {
          return (
            <div
              className={`react-datepicker__day--inner`}
              ref={
                isOutgoing || !to
                  ? moment(date).isSame(from, 'day') && dayRef
                  : moment(date).isSame(to, 'day') && dayRef
              }
            >
              {day}
            </div>
          )
        }}
        calendarClassName="react-datepicker--middlestep"
        onChange={onSelect}
        inline
      >
        {
          // (
          //   <Box className={classes.mobileHeader}>
          //     <Button
          //       className={classes.cancelBtnMobile}
          //       color="primary"
          //     >
          //       {t('cancel btn')}
          //     </Button>
          //     <Typography className={classes.mobileHeading}>{isOutgoing ? t('select outbound date') : t('select inbound date')}</Typography>
          //     <Box width={theme.spacing(8)}/>
          //   </Box>
          // )
        }
      </Picker>
    </>
  )
}

export default SelectDate
