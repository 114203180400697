import { getChargeableSeatAmount } from './general'

export const getPrices = (rail, ...rest) => {
  const {
    selectedReturnTrip,
    selectedOutGoingTrip,
    chargeableSeats
  } = rail
  const chargeableSeatsAmount = getChargeableSeatsAmount(selectedOutGoingTrip, selectedReturnTrip, chargeableSeats)
  const array = [
    ...rest,
    selectedReturnTrip?.pricing?.price?.Amount || 0,
    selectedOutGoingTrip?.pricing?.price?.Amount || 0,
    chargeableSeatsAmount
  ]

  return array.reduce((a, b) => parseInt(a) + parseInt(b), 0)
}

const getChargeableSeatsAmount = (selectedOutGoingTrip, selectedReturnTrip, chargeableSeats) => {
  let seatAmount = 0;
  const rails = [selectedOutGoingTrip, selectedReturnTrip];
  rails.forEach(rail => {
    const serviceProvider = rail?.train?.segments[0]?.railserviceProvider?.Code;
    const railIdentifier = rail?.train?.segments[0]?.railidentifier;

    if (serviceProvider === 'ORS') {
      seatAmount += getChargeableSeatAmount(chargeableSeats, railIdentifier);
    }
  })

  return seatAmount;
}