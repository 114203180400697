import { useEffect, useState } from 'react'

const useCheckBoxFilter = (initialState, reset) => {
  const [filters, setFilters] = useState(initialState)

  useEffect(() => {
    setFilters(initialState)
    // eslint-disable-next-line
  }, [reset])

  const onChange = (e, value) => {
    const { name } = e.target
    setFilters({ ...filters, [name]: value })
  }

  return { filters, onChange }
}

export default useCheckBoxFilter
