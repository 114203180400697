import constants from './constants'

const initialState = {
  users: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      }
    default:
      return state
  }
}
