const SET_HOTEL_SEARCH_RESULTS = 'SET_HOTEL_SEARCH_RESULTS'
const SET_HOTEL_LOADING_RESULTS = 'SET_HOTEL_LOADING_RESULTS'
const SET_HOTEL_ERROR_RESULT = 'SET_HOTEL_ERROR_RESULT'

const CLEAN_HOTEL_SEARCH_STATES = 'CLEAN_HOTEL_SEARCH_STATES'

const SET_HOTEL_CITIES = 'SET_HOTEL_CITIES'
const SET_CITY_COORDINATES = 'SET_CITY_COORDINATES'
const SET_HOTEL_RATINGS = 'SET_HOTEL_RATINGS'
const REMOVE_HOTEL_STATE = 'REMOVE_HOTEL_STATE'

const SET_SINGLE_HOTEL = 'SET_SINGLE_HOTEL'
const SET_SINGLE_LOADING_HOTEL = 'SET_SINGLE_LOADING_HOTEL'
const SET_SINGLE_HOTEL_ERROR = 'SET_SINGLE_HOTEL_ERROR'

const SET_HOTEL_GUESTS = 'SET_HOTEL_GUESTS'

const SET_HOTEL_POPULAR_FILTER = 'SET_HOTEL_POPULAR_FILTER'
const SET_HOTEL_MAX_PRICE_FILTER = 'SET_HOTEL_MAX_PRICE_FILTER'
const SET_HOTEL_STARS_FILTER = 'SET_HOTEL_STARS_FILTER'
const SET_HOTEL_GUEST_RATING_FILTER = 'SET_HOTEL_GUEST_RATING_FILTER'
const RESET_HOTEL_FILTERS = 'RESET_HOTEL_FILTERS'

const SET_SELECTED_HOTEL = 'SET_SELECTED_HOTEL'
const SET_SELECTED_HOTEL_PRICE = 'SET_SELECTED_HOTEL_PRICE'

const SET_SHOW_MAP = 'SET_SHOW_MAP'
const SET_MAP_CENTER = 'SET_MAP_CENTER'

const SET_HOTEL_SEARCH_CHECKSUM = 'SET_HOTEL_SEARCH_CHECKSUM'
const SET_HOTEL_SINGLE_CHECKSUM = 'SET_HOTEL_SINGLE_CHECKSUM'
const SET_SEARCHED_HOTELS = 'SET_SEARCHED_HOTELS'

const SET_ENABLE_ZONEFILTER = 'SET_ENABLE_ZONEFILTER'
const SET_ZONE_FILTERED_SEARCH = 'SET_ZONE_FILTERED_SEARCH'
const SET_SEEN_ZONE_FILTER = 'SET_SEEN_ZONE_FILTER'

export default {
  SET_HOTEL_MAX_PRICE_FILTER,
  SET_HOTEL_STARS_FILTER,
  SET_HOTEL_GUEST_RATING_FILTER,
  RESET_HOTEL_FILTERS,
  SET_HOTEL_CITIES,
  SET_HOTEL_LOADING_RESULTS,
  SET_HOTEL_ERROR_RESULT,
  SET_CITY_COORDINATES,
  SET_HOTEL_SEARCH_RESULTS,
  REMOVE_HOTEL_STATE,
  SET_HOTEL_RATINGS,
  SET_SINGLE_HOTEL,
  SET_SINGLE_LOADING_HOTEL,
  SET_SINGLE_HOTEL_ERROR,
  SET_HOTEL_GUESTS,
  CLEAN_HOTEL_SEARCH_STATES,
  SET_HOTEL_POPULAR_FILTER,
  SET_SELECTED_HOTEL,
  SET_SELECTED_HOTEL_PRICE,
  SET_SHOW_MAP,
  SET_MAP_CENTER,
  SET_HOTEL_SEARCH_CHECKSUM,
  SET_HOTEL_SINGLE_CHECKSUM,
  SET_SEARCHED_HOTELS,
  SET_ENABLE_ZONEFILTER,
  SET_ZONE_FILTERED_SEARCH,
  SET_SEEN_ZONE_FILTER,
}
