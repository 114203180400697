import React from 'react'
import {useTheme, Snackbar} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";
import {hideSnackbarAction} from "../../store/snackbars/snackbarAction";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  success: {
    boxShadow: `0 15px 20px -15px rgba(0, 0, 0, 0.16)`,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  error: {
    boxShadow: `0 15px 20px -15px rgba(0, 0, 0, 0.16)`,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  warning: {
    boxShadow: `0 15px 20px -15px rgba(0, 0, 0, 0.16)`,
    backgroundColor: theme.palette.secondary.warning,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '& $text': {
      color: '#663c00'
    }
  },
  text: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  close: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    cursor: 'pointer'
  }
}))

const SnackbarComponent = () => {
  const classes = useStyles()
  const state = useSelector((state) => state.snackbar)
  const theme = useTheme()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const vertical = state?.position?.y || 'bottom'
  const horizontal = state?.position?.x || 'left'
  const messagesArray = (typeof (state?.message) !== 'object' || !state?.message?.length) ? [state.message] : state.message;

  return (
    <Snackbar anchorOrigin={{ vertical: vertical, horizontal: isDesktop ? horizontal : 'center' }} open={state.show} autoHideDuration={6000} onClose={() => dispatch(hideSnackbarAction())}>
      <Box display="flex" alignItems="center" className={classes[state.snackbarType]}>
        {
          state.snackbarType === 'success'
            ? <CheckIcon style={{ color: theme.palette.common.white }}/>
            : <ErrorOutlineIcon style={{ color: state.snackbarType === 'warning' ? '#663c00' : theme.palette.common.white }}/>
        }
        <Box key={'messages-block'}>
          {messagesArray.map((message, i) => (
            <Typography key={`message-${i}`} className={classes.text}>
              {state.message}
            </Typography>
          ))}
        </Box>

        <CloseIcon className={classes.close} onClick={() => dispatch(hideSnackbarAction())}/>
      </Box>
    </Snackbar>
  )
}

export default SnackbarComponent
