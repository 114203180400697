import MetroPolisRegular from '../../assets/fonts/Metropolis/Metropolis-Regular.otf'
import MetroPolisRegularItalic from '../../assets/fonts/Metropolis/Metropolis-RegularItalic.otf'
import MetroPolisBold from '../../assets/fonts/Metropolis/Metropolis-Bold.otf'
import MetroPolisSemiBold from '../../assets/fonts/Metropolis/Metropolis-SemiBold.otf'
import MetroPolisMedium from '../../assets/fonts/Metropolis/Metropolis-Medium.otf'

export const metroPolisRegularItalic = {
  fontFamily: 'Metropolis',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Metropolis-regular'),
      local('Metropolis-Regular'),
      url(${MetroPolisRegularItalic}) format('opentype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const metropolisRegular = {
  fontFamily: 'Metropolis',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Metropolis-regular'),
      local('Metropolis-Regular'),
      url(${MetroPolisRegular}) format('opentype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const metroPolisMedium = {
  fontFamily: 'Metropolis',
  fontStyle: 'medium',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
      local('Metropolis-medium'),
      local('Metropolis-medium'),
      url(${MetroPolisMedium}) format('opentype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const metroPolisSemiBold = {
  fontFamily: 'Metropolis',
  fontStyle: 'semi-bold',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
      local('Metropolis-semiBold'),
      local('Metropolis-semiBold'),
      url(${MetroPolisSemiBold}) format('opentype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const metroPolisBold = {
  fontFamily: 'Metropolis',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
      local('Metropolis-bold'),
      local('Metropolis-bold'),
      url(${MetroPolisBold}) format('opentype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}
