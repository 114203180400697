import React, { memo, Fragment } from 'react'
import { useSelector } from 'react-redux'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  infoRow: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#e0ecde',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    height: '36px',
    borderBottom: `1px solid ${theme.palette.border}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',

    '& .semibold': {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  }
}))

const ExtendCheckoutBanner = ({ type = 'flight' }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {flights:flightSearch, train:trainSearch} = useSelector((state) => state.search)
  const {items} = useSelector((state) => state.checkout)

  let extendCheckout = false
  let from = null
  let to = null
  let outboundDate = null
  let inboundDate = null

  if (type === 'flight') {
    extendCheckout = flightSearch?.extendCheckout

    const trainItem = items.find(i => i?.type === 'Train')
    const trainOutbound = trainItem?.outboundTrip?.train?.scheduleSolution
    const trainInbound = trainItem?.returnTrip?.train?.scheduleSolution

    from = trainOutbound?.railstart?.locationName
    to = trainOutbound?.railend?.locationName
    outboundDate = moment(trainOutbound?.railstart?.dateTime).format('YYYY-MM-DD')

    if (!!trainInbound) {
      inboundDate = moment(trainInbound?.railstart?.dateTime).format('YYYY-MM-DD')
    }
  } else if (type === 'train') {
    extendCheckout = trainSearch?.extendCheckout
    const flightItem = items.find(i => i?.type === 'Flight')

    const flightOutbound = flightItem?.outgoing
    const flightInbound = flightItem?.returning

    from = flightOutbound?.from?.displayName
    to = flightOutbound?.to?.displayName
    outboundDate = moment(flightOutbound?.departure).format('YYYY-MM-DD')

    if (!!flightInbound) {
      inboundDate = moment(flightInbound?.departure).format('YYYY-MM-DD')
    }
  }

  if (!extendCheckout) {
    return <Fragment></Fragment>
  }

  let toText = t('to').toLocaleLowerCase()

  let firstSentence = t(type === 'flight' ? 'adding flight to checkout' : 'adding train to checkout');
  let secondSentence = t('your current trip is from')
  let thirdSentence = null

  if (!!from && !!to) {
    thirdSentence = `${from} ${toText} ${to} ${outboundDate}${!!inboundDate ? ` – ${inboundDate}` : ''}`
  }


  return (
    <Fragment>
      <Box className={`${classes.infoRow}`}>
        <span>{firstSentence}</span>&nbsp;
        {!isMobile && !!thirdSentence && (
          <Fragment>
            <span>{secondSentence}</span>&nbsp;
            <span className={'semibold'}>{thirdSentence}</span>
          </Fragment>
        )}
      </Box>
    </Fragment>
  )
}

export default memo(ExtendCheckoutBanner)
