export const TRAIN_COMPANIES = {
  ARX: 'Arlanda Express',
  BRK: 'Bergkvarabuss',
  BGT: 'Bergslagståg',
  BLT: 'Blå Tåget',
  BKB: 'Blåklintsbussen',
  BVK: 'Bråvikslinjen',
  //    "LTF": "BSM Järnväg",
  B4U: 'Bus4You',
  FLB: 'Flygbussarna',
  GRT: 'Gröna Tåget',
  ILB: 'Inlandsbanan',
  KPL: 'Kustpilen',
  LTF: 'Länstrafik',
  LND: 'Lindbergs buss',
  MSX: 'Masexpressen',
  MTL: 'Mittlinjen',
  MTR: 'VR',
  NBE: 'Nettbuss Express',
  NKE: 'Nikkaluoktaexpressen',
  NRT: 'Norrtåg',
  NSB: 'NSB',
  ORS: 'Öresundstågen',
  SGR: 'Saga Rail',
  SGS: 'SGS Bussen',
  SJR: 'SJ',
  SKB: 'Skaraborgaren',
  SKE: 'SkiExpress',
  SLT: 'SL',
  SNA: 'Snälltåget',
  SBS: 'Söne Buss',
  STX: 'Strömma Expressbuss',
  SVB: 'Svenska Buss',
  SWU: 'Swebus',
  SDT: 'Sydvästen',
  TGB: 'Tågab',
  TGK: 'Tågkompaniet',
  TGN: 'Tågkompaniet Norrtåg',
  TPN: 'Tapanis buss',
  TRS: 'Trosabussen',
  GTT: 'Västtrafik',
  YBS: 'Y-buss',
  MLG: 'Mälartåg',
}
