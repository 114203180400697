import {
  fetchTransferLocation,
  fetchAvailability,
  fetchTransfers,
} from '../../repositories/transfer'
import { calculateTransferPrice } from '../../utils/priceMargins'
import constants from './constants'

export const fetchTransferSearchLocationAction =
  (string, destinationCode) => async (dispatch) => {
    dispatch({ type: constants.SET_TRANSFER_LOCATION_LOADING, payload: true })
    const searchResults = await fetchTransferLocation(string, destinationCode)
    if (!searchResults) {
      dispatch({
        type: constants.SET_TRANSFER_LOCATION_ERROR,
        payload: 'No adress found, please try again',
      })
    }
    dispatch({
      type: constants.SET_TRANSFER_LOCATION,
      payload: searchResults?.data,
    })
    return dispatch({
      type: constants.SET_TRANSFER_LOCATION_LOADING,
      payload: false,
    })
  }

export const clearSearchLocation = () => ({
  type: constants.REMOVE_LOCATION_STATE,
})

export const setTaxiError = (error) => ({
  type: constants.SET_TAXI_ERROR,
  payload: error,
})

export const fetchTransferFlightTaxi = (payload, t) => async (dispatch) => {
  dispatch(isFlygtaxiLoading(true))
  const flightTaxi = await fetchAvailability(payload)

  const { margins } = flightTaxi

  if (flightTaxi && flightTaxi?.errors) {
    dispatch(isFlygtaxiLoading(false))
    return dispatch(setTaxiError(t('cant find address')))
  }

  const clean = flightTaxi?.data.map((element) => {
    element.price.originalIncludingVat = element.price.includingVat
    let { price, markup } = calculateTransferPrice(
      margins,
      parseFloat(element.price.includingVat),
      'SE'
    )
    element.price.includingVat = price
    element.price.markup = markup

    return {
      ...element,
      type: payload.type === 'TRAIN' ? 'tagTaxi' : 'flightTaxi',
    }
  })
  const taxi = [
    {
      type: payload.type === 'TRAIN' ? 'tagTaxi' : 'flightTaxi',
      details: clean,
    },
  ]

  dispatch(isFlygtaxiLoading(false))
  return dispatch({
    type: constants.SET_TAXI,
    payload: taxi,
  })
}

export const fetchTransferFlightBus = (payload) => async (dispatch) => {
  dispatch(isTransferLoading(true))
  const flightTaxi = await fetchTransfers(payload)

  const { margins } = flightTaxi

  if (flightTaxi && flightTaxi.errors) {
    dispatch(isTransferLoading(false))
    return dispatch({
      type: constants.SET_TAXI_ERROR,
      payload: 'Could not find any ARX/FLB',
    })
  }

  const clean = flightTaxi?.data.map((element) => {
    element.price.originalIncludingVat = element.price.includingVat
    let { price, markup } = calculateTransferPrice(
      margins,
      parseFloat(element.price.includingVat),
      'SE'
    )
    element.price.includingVat = price
    element.price.markup = markup

    return {
      ...element,
      type: 'flightBus',
    }
  })
  const transfers = [{ type: 'flightBus', details: clean }]

  dispatch(isTransferLoading(false))
  return dispatch({ type: constants.SET_TAXI, payload: transfers })
}

/*
export const _amadeus_fetchTransferFlightBus = (payload) => async (dispatch) => {
  const providers = ['ARX', 'FLB']

  dispatch(isTransferLoading(true))
  const hejsanHopppsanMap = providers.map(async (provider) => {
    // DeepClone to kill reference
    const deepClone = JSON.parse(JSON.stringify(payload))
    deepClone.schedule.outbound.providers = [provider]
    const data = await fetchTransfers(deepClone)
    return (!!data?.schedules) ? [data?.schedules[0]] : []
  })
  const promiseAll = (await Promise.all(hejsanHopppsanMap)).flat()

  const clean = promiseAll
    .filter((x) => x)
  .map((element) => ({ ...element, type: 'flightBus' }))

dispatch(isTransferLoading(false))
dispatch({ type: constants.SET_TAXI, payload: clean })
}
*/

export const isTransferLoading = (isLoading) => ({
  type: constants.SET_TAXI_LOADING,
  payload: isLoading,
})

export const isFlygtaxiLoading = (isLoading) => ({
  type: constants.SET_FLYGTAXI_LOADING,
  payload: isLoading,
})

export const isServiceLocationLoading = (isLoading) => ({
  type: constants.SET_SERVICE_LOCATION_LOADING,
  payload: isLoading,
})

export const cleanTransferSearch = () => ({
  type: constants.REMOVE_TRANSFER_SEARCH,
})
