import { combineReducers } from 'redux'

import authReducer from './auth/authReducer'
import flightsReducer from './flights/searchReducer'
import flightDetailsReducer from './flightDetails/flightDetailsReducer'
import usersReducer from './users/usersReducer'
import trainReducer from './train/trainReducer'
import hotelReducer from './hotels/hotelsReducer'
import carReducer from './cars/carReducer'
import checkoutReducer from './checkout/checkoutReducer'
import modalReducer from './modals/modalReducer'
import transferReducer from './transfer/transferReducer'
import myTripsReducer from './myTrips/myTripsReducer'
import searchReducer from './search/searchReducer'
import breadcrumbsReducer from './breadcrumbs/breadcrumbsReducer'
import otherReducer from './other/otherReducer'
import snackbarReducer from './snackbars/snackbarReducer'
import customizationReducer from './customization/customizationReducer'

export default combineReducers({
  auth: authReducer,
  flights: flightsReducer,
  flightDetails: flightDetailsReducer,
  train: trainReducer,
  hotels: hotelReducer,
  cars: carReducer,
  users: usersReducer,
  checkout: checkoutReducer,
  modal: modalReducer,
  transfer: transferReducer,
  myTrips: myTripsReducer,
  search: searchReducer,
  breadcrumbs: breadcrumbsReducer,
  other: otherReducer,
  snackbar: snackbarReducer,
  customization: customizationReducer,
})
