import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TextField, Box, useMediaQuery, InputAdornment } from '@material-ui/core'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import SelectDestinationModal from '../../containers/modals/forms/selectDestinationModal'

import Popper from '@material-ui/core/Popper'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: 0,
    border: 0,
    height: '100%',
  },
  input: {
    height: '100%',
    width: '100%',
    borderRadius: '0',
    border: 0,
    padding: '0',
    margin: '0',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.common.black,
    },
    '&::placeholder': {
      color: 'red !important',
    },
  },

  inputError: {
    height: '100%',
    width: '100%',
    borderRadius: '0',
    border: 0,
    padding: '0',
    margin: '0',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .MuiInputBase-input::placeholder': {
      color: '#d0021b',
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: theme.spacing(2.4),
      opacity: 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
      },
    },
  },
  align: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    alignItems: 'center',
  },
  auto: {
    fontWeight: 600,

    [theme.breakpoints.up('md')]: {
      width: '100%',
      minWidth: 100,
    },
  },
  innerInput: ({ inputStyles = {} }) => ({
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      { ...inputStyles },
  }),
}))

const CssTextField = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    height: '100%',
    '& .MuiInput-underline:after': {
      border: 0,
    },
    '& .MuiOutlinedInput-root': {
      height: '100%',
      padding: '0 !important',
      fontWeight: 600,
      '& fieldset': {
        borderRadius: 0,
        border: 0,
      },
    },
    '& .MuiFormLabel-root': {
      color: 'red', // or black
    },
    '&::placeholder': {
      color: 'red !important',
    },

    '& .MuiAutocomplete-input': {
      textOverflow: theme.direction === 'rtl' ? 'unset' : 'ellipsis',
    },
  },
}))(TextField)

const AutoCompleteInput = ({
  value,
  onChange,
  endAdornment,
  array,
  fetch,
  optionLabel,
  icon,
  placeholder,
  renderOption,
  desktop,
  label,
  destinationModal = false,
  isPopoverOpenFrom,
  isPopoverOpenTo,
  forwardedRef,
  toRef,
  isFrom,
  isTo,
  inputStyles = {},
  inputClass = null,
  customFilterFunc,
  isReadonly,
  fullWidth = false,
  isMinimal,
}) => {
  const classes = useStyles({ inputStyles })
  const [openModal, setOpenModal] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isDisabled, setIsDisabled] = useState(isReadonly)
  const [inputValue, setInputValue] = useState('')
  const ref = useRef()

  // Todo: refactor
  const handleOnFocus = async () => {
    fetch('', isTo)
  }

  const changeValue = (itm, val) => {
    if (isFrom && toRef?.current) {
      toRef.current.focus()
    }
    if ((isTo || (!isTo && !isFrom)) && forwardedRef?.current) {
      forwardedRef.current.blur()
    }
    onChange(itm, val)
  }

  const onFinishModal = (itm) => {
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    })
    setOpenModal(false)
    onChange(null, itm)
  }

  const debounceSearch = useCallback(
    _.debounce((text) => fetch(text, isTo), 200),
    []
  )

  useEffect(() => {
    debounceSearch(inputValue)
    //fetch ? (e) => fetch(e.target.value) : null
  }, [inputValue, debounceSearch])

  let optionArray = [...array]
  /*
  if (value && !optionArray.find((a) => a.IATA === value.IATA))
    optionArray.push(value)
  */

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify:
      customFilterFunc ||
      ((option) =>
        `${option.IATA} ${option.City} ${option.Airport} ${option['City ENG']} ${option['City SWE']} ${option.latinName} ${option.place_id}`),
  })

  const renderAutocomplete = () => {
    return (
      <Box
        className={classes.align}
        style={{ height: '100%', width: '100%', overflow: 'hidden' }}
        onClick={openModalIfNeeded}
      >
        {icon ? (
          <Box display="flex" alignItems="center" ml={0}>
            {icon}
          </Box>
        ) : (
          ''
        )}
        <Autocomplete
          // open={true}
          // disableCloseOnSelect={true}
          ref={ref}
          popupIcon={null}
          noOptionsText={'No match found'}
          className={classes.auto}
          openOnFocus={!isMobile}
          autoComplete
          disableClearable
          disabled={isDisabled}
          fullWidth
          options={optionArray}
          //getOptionSelected={(option) => option.IATA === value?.IATA}
          getOptionLabel={optionLabel}
          value={value || null}
          defaultValue={null}
          onChange={changeValue}
          style={{ height: '100%' }}
          renderOption={renderOption}
          filterOptions={filterOptions}
          PopperComponent={(props) => (
            <Popper
              {...props}
              placement="bottom-start"
              style={{
                width: !fullWidth && desktop ? '370px' : (props?.style?.width || 'auto'),
                height: '50px'
              }}
            />
          )}
          renderInput={(params) => {
            if (endAdornment) {
              params.InputProps.endAdornment = (
                <InputAdornment position="end">
                  <Box display={'inline-flex'} pr={'10px'}>
                    {endAdornment}
                  </Box>
                </InputAdornment>
              )
            }
            return (
              <CssTextField
                inputRef={forwardedRef}
                placeholder={placeholder}
                style={{ height: '100%' }}
                {...params}
                variant="outlined"
                className={`${
                  isPopoverOpenFrom || (isPopoverOpenTo && isMobile)
                    ? classes.inputError
                    : classes.input
                } ${classes.innerInput} ${!!inputClass ? inputClass : ''}`}
                fullWidth
                margin="normal"
                onChange={(e) => setInputValue(e.target.value)}
                onFocus={(e) => handleOnFocus(e.target.value)}
              />
            )
          }}
        />
      </Box>
    )
  }

  const openModalIfNeeded = () => {
    if (isDisabled) return false
    if (destinationModal) setOpenModal(true)
  }

  return (
    <Box style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      {renderAutocomplete()}
      {destinationModal && (
        <SelectDestinationModal
          value={value}
          options={array || []}
          modalBoolean={openModal}
          handleClose={() => setOpenModal(false)}
          handleFinish={onFinishModal}
          fetchMethod={fetch}
          getDestText={optionLabel}
          placeholder={placeholder}
          header={label}
          setIsDisabled={setIsDisabled}
          isTo={isTo}
        />
      )}
    </Box>
  )
}

export default AutoCompleteInput
