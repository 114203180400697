import React, { memo, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'
import { fetchCityByIata } from '../../../../../repositories/flights'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cityText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dateText: {
    color: theme.palette.dimGray,
  },
}))

const FlightType = () => {
  const classes = useStyles()
  const params = useParams()

  const [start, setStart] = useState('')
  const [destination, setDestination] = useState('')

  const fetchAwaits = async (param, set) => {
    const result = await fetchCityByIata(param)
    set(result)
  }

  useEffect(() => {
    fetchAwaits(params.from, setStart)
    fetchAwaits(params.to, setDestination)
    return () => {
      setStart('')
      setDestination('')
    }
  }, [params.from, params.to])

  return (
    <Box className={classes.root}>
      <Typography className={classes.cityText} align="center">
        <>
          {start ? start['City SWE'] : ''} -{' '}
          {destination ? destination['City SWE'] : ''}
        </>
      </Typography>
      <Typography variant="caption" align="center" className={classes.dateText}>
        <Moment format="ddd D MMM">{params.dep}</Moment>
        {params.arr !== '0' ? (
          <>
            {` - `}
            <Moment format="ddd D MMM ">{params.arr}</Moment>
          </>
        ) : null}
      </Typography>
    </Box>
  )
}

export default memo(FlightType)
