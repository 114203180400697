import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { openEditInfoModal } from '../../store/modals/modalAction'
import { useTranslation } from 'react-i18next'

const MyPassword = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const openEditModal = useCallback((name, rows, section) => {
    dispatch(openEditInfoModal({ name, rows, section }))
  }, [dispatch])

  useEffect(() => {
    openEditModal(
      t('nav pass'),
      [
        { title: t('current password'), key: 'oldpassword' },
        { title: t('new password'), key: 'password', order: 1 },
        { title: t('confirm password'), key: 'confirmpassword', order: 2 },
      ],
      'password')
  }, [openEditModal, t])

  return <div />
}

export default MyPassword
