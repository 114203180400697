import moment from 'moment'
import i18n from 'i18next'
//YYYY-MM-DD
export function toFullDate(n = new Date()) {
  return moment(new Date(n)).format('YYYY-MM-DD')
}

export function toFullDateTime(n = new Date()) {
  return moment(new Date(n)).format('YYYY-MM-DD HH:mm')
}

export function getDate(n = new Date()) {
  return moment(new Date(n)).format('DD')
}

export function getMonthNameShort(date = new Date()) {
  switch (date.getMonth()) {
    case 0:
      return `${i18n.t('jan')}`
    case 1:
      return `${i18n.t('feb')}`
    case 2:
      return `${i18n.t('mar')}`
    case 3:
      return `${i18n.t('apr')}`
    case 4:
      return `${i18n.t('may short')}`
    case 5:
      return `${i18n.t('jun')}`
    case 6:
      return `${i18n.t('jul')}`
    case 7:
      return `${i18n.t('agus')}`
    case 8:
      return `${i18n.t('sept')}`
    case 9:
      return `${i18n.t('oct')}`
    case 10:
      return `${i18n.t('nov')}`
    case 11:
      return `${i18n.t('dec')}`
    default:
      return ''
  }
}

export function getWeekdayNameShort(date = new Date()) {
  switch (date.getDay()) {
    case 0:
      return `${i18n.t('sun')}`
    case 1:
      return `${i18n.t('mon')}`
    case 2:
      return `${i18n.t('tue')}`
    case 3:
      return `${i18n.t('wed')}`
    case 4:
      return `${i18n.t('thu')}`
    case 5:
      return `${i18n.t('fri')}`
    case 6:
      return `${i18n.t('sat')}`
    default:
      return ''
  }
}

// hh-mm
export function toHoursAndMinutes(n = new Date()) {
  return moment(new Date(n)).format('HH:mm')
}

// Return a date not in the past, with optionally days added.
export function getNonPastDate(input, daysToAdd = 0) {
  if (!input) return moment().add(daysToAdd, 'days').toDate()
  return moment
    .max(moment(new Date(input)), moment().add(daysToAdd, 'days'))
    .toDate()
}
