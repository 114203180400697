import React, { memo } from 'react'
import { Paper, Box, Grid, useMediaQuery } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', borderRadius: '12px' },
  padding: {
    paddingLeft: theme.spacing(4),
  },

  mobilePadding: {
    paddingTop: theme.spacing(2),
  },

  skeletonPadding: {
    paddingTop: theme.spacing(7),
  },

  border: { borderRadius: '4px' },
}))

const HotelSkeletons = ({ arrayLength = 5 }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const filledSkeletons = new Array(arrayLength).fill(null).map((x, i) => (
    <Box
      pl={isMobile ? 1 : 0}
      pr={isMobile ? 1 : 0}
      key={i}
      mt={2}
      width="100%"
    >
      <Paper className={classes.root} elevation={0}>
        <Box p={!isMobile ? 3 : 2}>
          <Grid container>
            {/* Image skeleton */}

            <Skeleton
              animation="wave"
              variant="rect"
              width={!isMobile ? 320 : '100vw'}
              height={!isMobile ? 172 : 144}
              className={classes.border}
            />

            <Grid
              item
              xs={12}
              sm
              container
              className={!isMobile ? classes.padding : classes.mobilePadding}
            >
              {/* left boxes */}
              <Grid item xs container direction="column">
                <Grid item xs>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width={!isMobile ? 160 : 147.5}
                    height={40}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width={!isMobile ? 80 : 73.8}
                    height={24}
                    className={classes.border}
                  />
                </Grid>

                <Grid item>
                  <Box pt={7}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={!isMobile ? 80 : 73.8}
                      height={40}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* right box */}
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={!isMobile ? 120 : 110.6}
                  height={40}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  ))
  return <Box width="100%">{filledSkeletons}</Box>
}

export default memo(HotelSkeletons)
