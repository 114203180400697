import React, { Fragment, memo, useCallback, useMemo, useState } from 'react'
import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CarInstructionsModal from '../../containers/cars/carInstructionsModal'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import moment from 'moment'
import _ from 'lodash'
import CancelIcon from '@material-ui/icons/Cancel'
import { useDispatch, useSelector } from 'react-redux'
import { removeCheckoutItemAction } from '../../store/checkout/checkoutActions'

const useStyles = makeStyles((theme) => ({
  headerIcon: {},
  tripHeaderOuter: {
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  tripHeader: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.border}`,
    paddingBottom: '16px',
    marginBottom: '16px',
  },
  btn: {
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.primary.medium,
    color: theme.palette.primary.dark,
    padding: theme.spacing(1, 4),
  },
  headerText: {
    paddingLeft: '14px',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
  },
  imageOuter: {
    width: '85px',
  },
  noImgBlock: {
    width: '100%',
    paddingTop: '56.25%',
    backgroundColor: theme.palette.dimGray,
    position: 'relative',
  },
  noImgInnerBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  },
  singleCarImage: {
    width: '100%',
    height: 'auto',
  },
  btnRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topInfoRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px',
  },
  carModel: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    marginBottom: '6px',
  },
  carModelSmall: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.black,
  },
  carHeader: {
    paddingLeft: '16px',
  },
  locationRow: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  dateText: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.black,
    marginBottom: '8px',
  },
  instrText: {
    color: '#0091ff',
    fontSize: '14px',
    cursor: 'pointer',
  },
}))

const ChoosenTripCard = ({ car, disableEdit = false, index = null }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [instrModalData, setInstrModalData] = useState(null)
  const atTxt = t('at')
  const { tarif } = car || {}

  const setInstr = useCallback(
    (key = 'pick') => {
      if (key === 'pick') {
        setInstrModalData({
          title: t('pick-up instructions'),
          locationTitle: t('pick-up location'),
          location: { ...tarif.pickLocation },
        })
      } else {
        setInstrModalData({
          title: t('drop-off instructions'),
          locationTitle: t('drop-off location'),
          location: { ...tarif.dropLocation },
        })
      }
    },
    [t, tarif]
  )

  const remove = useCallback(() => {
    if (!disableEdit && index !== null && index !== false) {
      dispatch(removeCheckoutItemAction(index))
    }
  }, [disableEdit, index])

  const handleCloseInstructionModal = () => {
    // Send scroll-to-top in iframe mode.
    if (window.parent !== window) {
      window.parent.postMessage({ type: 'scroll-to-top' }, '*')
    }
    setInstrModalData(null)
  }

  const renderMainContent = () => {
    const sDate = new moment(tarif?.startDate, 'YYYY-MM-DD HH:mm')
    const eDate = new moment(tarif?.endDate, 'YYYY-MM-DD HH:mm')
    const startLoc =
      _.startCase(_.toLower(tarif?.pickLocation.addressLine)) +
      ', ' +
      _.startCase(_.toLower(tarif?.pickLocation.city))
    const endLoc =
      _.startCase(_.toLower(tarif?.dropLocation.addressLine)) +
      ', ' +
      _.startCase(_.toLower(tarif?.dropLocation.city))

    return (
      <Fragment>
        <Box className={classes.topInfoRow}>
          <Box className={classes.imageOuter}>
            {!!tarif?.vehicle?.image &&
            tarif.vehicle.image !== 'https://none' ? (
              <img
                src={tarif.vehicle.image}
                className={classes.singleCarImage}
              />
            ) : (
              <Box className={classes.noImgBlock}>
                <Box className={classes.noImgInnerBlock}>{t('no image')}</Box>
              </Box>
            )}
          </Box>

          <Box className={classes.carHeader}>
            <Box className={classes.carModel}>
              {tarif?.vehicle?.model || t('unknown car model')}
            </Box>
            <Box className={classes.carModelSmall}>
              {t('or similar sized car')}
            </Box>
          </Box>
        </Box>
        <Box className={classes.locationRow}>
          <Box className={classes.dateText}>
            {_.capitalize(sDate.format(`ddd, D MMM [${atTxt}] HH:mm`))},{' '}
            {startLoc}
          </Box>
          <Box onClick={() => setInstr()} className={classes.instrText}>
            {t('pick-up instructions link')}
          </Box>
        </Box>
        <Box className={classes.locationRow}>
          <Box className={classes.dateText}>
            {_.capitalize(eDate.format(`ddd, D MMM [${atTxt}] HH:mm`))},{' '}
            {endLoc}
          </Box>
          <Box onClick={() => setInstr('drop')} className={classes.instrText}>
            {t('drop-off instructions link')}
          </Box>
        </Box>
        <CarInstructionsModal
          isOpen={!!instrModalData}
          data={instrModalData}
          onClose={() => handleCloseInstructionModal()}
        />
      </Fragment>
    )
  }

  return (
    <Box className={classes.tripHeaderOuter} p={2}>
      <Box className={classes.tripHeader}>
        <Box className={classes.headerIcon}>
          <DirectionsCarIcon />
        </Box>
        <Box className={classes.headerText}>{t('selected rental car')}</Box>
        {!disableEdit && tarif && index !== null && (
          <IconButton onClick={remove} className={classes.closeBtn}>
            <CancelIcon color="disabled" />
          </IconButton>
        )}
      </Box>
      {renderMainContent()}
    </Box>
  )
}

export default memo(ChoosenTripCard)
