import { CircularProgress, TableCell, TableRow, useMediaQuery, useTheme } from '@material-ui/core'
import React, { memo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../../viewHandler'
import Box from '@material-ui/core/Box'

const OfflineSummary = ({ pnr }) => {
  const classes = useStyles()
  let itmKey = 1
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fragment>
      <TableRow
        onClick={() => null}
        className={`${classes.root} ${classes.tripRow}`}
      >
        {!isMobile ? (
          <Fragment>
            <TableCell key={`itm-${itmKey++}`} align="left">
              ***
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {pnr}
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {t('offline')}
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              ***
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              ***
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              ***
            </TableCell>
          </Fragment>
        ) : (
          <TableCell key={`itm-${itmKey++}`} align="left">
            {pnr}
          </TableCell>
        )}
        <TableCell key={`itm-${itmKey++}`} align="left">
          <Box className={classes.statusOuter}>
            <Box className={classes.statusInner}>
          <CircularProgress size={32} />
            </Box>
            </Box>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default memo(OfflineSummary)
