import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import defaultStyles from './defaultTimeline.styles'
import _ from 'lodash'

// takes an array of flights and produces a timeline
const MiscTimeLine = ({ model }) => {
  const defaultClasses = defaultStyles()
  const { t } = useTranslation()
  const segments = model.misc

  let itmKey = 1

  const isCancelled = !!model && model?.cancelled

  const renderSegments = segments.map((seg) => {
    if (seg.type == 'train') {
      return (
        <>
          <Box
            key={`itm-${itmKey++}`}
            className={defaultClasses.normalDetailLine}
          >
            {t('departure')}: {seg.date}
          </Box>

          <Box
            key={`itm-${itmKey++}`}
            className={defaultClasses.normalDetailLine}
          >
            {t('book data rail ref')}: {seg.bookingRef}
          </Box>
        </>
      )
    }
  })

  const title = segments.map((seg) => t(seg.type)).join(', ')

  return (
    <Accordion
      key={`itm-${itmKey++}`}
      className={defaultClasses.accordion}
      defaultExpanded
    >
      <AccordionSummary key={`itm-${itmKey++}`} expandIcon={<ExpandMoreIcon />}>
        <span style={{ textDecoration: isCancelled ? 'line-through' : 'none' }}>
          {title}
        </span>
      </AccordionSummary>
      <AccordionDetails key={`itm-${itmKey++}`}>
        {renderSegments}
      </AccordionDetails>
    </Accordion>
  )
}

export default memo(MiscTimeLine)
