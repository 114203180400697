import React, { memo } from 'react'
import { Box, Divider, useTheme } from '@material-ui/core'

import Skeletons from '../../reusable/skeletons/flightSkeleton'
import FlightTaxiCard from './transferCards/flightTaxiCard'
import FlightBusCard from './transferCards/flightBusCard'
import { useSelector } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

//import Taxi from './mock/newTaxi.json'

const viewHandler = {
  flightTaxi: FlightTaxiCard,
  tagTaxi: FlightTaxiCard,
  flightBus: FlightBusCard,
}

const TransferViewHandler = ({
  setRelevantItinerary,
  current,
  handleReset,
  transfers,
}) => {
  const { all, isAllLoading, isFlygtaxiLoading } = useSelector((state) => state.transfer)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const array = transfers || all

  const renderViewHandlerCards = array.map((component, key) => {
    const TagName = viewHandler[component.type]

    return component.details.length > 0 ? (
      <Box key={key} p={isMobile ? 0 : 2} pb={0}>
        <TagName
          model={component}
          type={component.type}
          setRelevantItinerary={setRelevantItinerary}
          current={current}
          handleReset={handleReset}
        />
        <Box mt={2}>
          <Divider />
        </Box>
      </Box>
    ) : ""
  })

  return <Box>{isAllLoading || isFlygtaxiLoading ? <Skeletons arrayLength={3} /> : renderViewHandlerCards}</Box>
}

export default memo(TransferViewHandler)
