import i18n from 'i18next'

export default function setMinPriceLabels(data) {
  const newData = [...data]
  const MAX_PRICE = 9999999
  const minPricesHash = {
    trip: {
      bus: MAX_PRICE,
      train: MAX_PRICE,
      air: MAX_PRICE,
    },
    returnTrip: {
      bus: MAX_PRICE,
      train: MAX_PRICE,
      air: MAX_PRICE,
    },
  }

  // data is not empty
  if (newData.length < 1) return

  // trips min prices hash
  const minPrices = newData.map((d) => d.price)

  // find min price helper
  const updateMinPrice = (trip, minPrice, tripDirection, tripType) => {
    if (
      trip.group === tripDirection &&
      trip.travelType === tripType &&
      minPrice < minPricesHash[tripDirection][tripType]
    ) {
      minPricesHash[tripDirection][tripType] = minPrice
    }
  }

  // check if it's a min price
  const isMinPrice = (trip, minPrice, tripDirection, tripType) =>
    trip.group === tripDirection &&
    trip.travelType === tripType &&
    minPrice === minPricesHash[tripDirection][tripType]

  // find min price
  newData.forEach((d, i) => {
    updateMinPrice(d, minPrices[i], 'trip', 'bus')
    updateMinPrice(d, minPrices[i], 'trip', 'train')
    updateMinPrice(d, minPrices[i], 'trip', 'air')
    updateMinPrice(d, minPrices[i], 'returnTrip', 'bus')
    updateMinPrice(d, minPrices[i], 'returnTrip', 'train')
    updateMinPrice(d, minPrices[i], 'returnTrip', 'air')
  })

  // clear labels
  newData.forEach((d) => {
    d.cheapestLabel = ''
  })

  // set labels on trips with min price
  newData.forEach((d, i) => {
    if (isMinPrice(d, minPrices[i], 'trip', 'bus'))
      d.cheapestLabel = `${i18n.t('cheapest bus')}`
    if (isMinPrice(d, minPrices[i], 'trip', 'train'))
      d.cheapestLabel = `${i18n.t('cheapest train')}`
    if (isMinPrice(d, minPrices[i], 'trip', 'air'))
      d.cheapestLabel = `${i18n.t('cheapest flight')}`
    if (isMinPrice(d, minPrices[i], 'returnTrip', 'bus'))
      d.cheapestLabel = `${i18n.t('cheapest bus')}`
    if (isMinPrice(d, minPrices[i], 'returnTrip', 'train'))
      d.cheapestLabel = `${i18n.t('cheapest train')}`
    if (isMinPrice(d, minPrices[i], 'returnTrip', 'air'))
      d.cheapestLabel = `${i18n.t('cheapest flight')}`
  })

  return newData
}
