import React, { Fragment, memo } from 'react'
import Box from '@material-ui/core/Box'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../../viewHandler'
import { TableCell, TableRow, useMediaQuery, useTheme } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import capFirstLetter from '../../../../utils/capitalizeFirstLetter'

const MiscSummary = ({ model, handleChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { misc, passengers, unixTsDeparture } = model
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  let itmKey = 1
  const products = misc?.map((seg) => t(seg.type)).join(', ')

  const renderGuests = passengers?.map(
    ({ firstName, lastName }, key, array) => {
      return (
        <span key={`itm-${itmKey++}`} className={classes.traveler}>
          {capFirstLetter(`${firstName} ${lastName}`)}
          {key < 2 && key < array.length - 1 ? ', ' : ' '}
        </span>
      )
    }
  )

  return (
    <Fragment>
      <TableRow
        onClick={() => handleChange()}
        className={`${classes.root} ${classes.tripRow}`}
      >
        {!isMobile ? (
          <Fragment>
            <TableCell key={`itm-${itmKey++}`} align="left">
              <Moment format={'MMM D, YYYY'}>{unixTsDeparture}</Moment>
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {model.pnr}
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {t(model.parsed ? 'offline' : 'online')}
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {products}
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left"></TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {renderGuests}
            </TableCell>
          </Fragment>
        ) : (
          <TableCell key={`itm-${itmKey++}`} align="left"></TableCell>
        )}
        <TableCell key={`itm-${itmKey++}`} align="left">
          <Box className={classes.statusOuter}>
            <Box className={classes.statusInner}>
              <span className={classes.aboveStatusRow}>
                <Moment format={'MMM D, YYYY'}>{unixTsDeparture}</Moment>
              </span>
              {(!!model.cancelled || !isMobile) && (
                <span
                  style={{ flex: 1 }}
                  className={
                    model.cancelled
                      ? classes.statusTextCancel
                      : model.isUpcoming
                      ? classes.statusText
                      : classes.statusTextPast
                  }
                >
                  {model.cancelled
                    ? t('cancelled')
                    : t(model.isUpcoming ? 'upcoming' : 'previous')}
                </span>
              )}
            </Box>
            <ChevronRightIcon
              className={classes.arrowIcon}
              viewBox="8 6 8 12"
            />
          </Box>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default memo(MiscSummary)
