import { useState } from 'react'

const useRoomCount = (initialRoomCount) => {
  const [roomCount, setRoomCount] = useState(initialRoomCount || 1)

  const incrementRoom = () => {
    setRoomCount((prev) => ++prev)
  }

  const decrementRoom = () => {
    setRoomCount((prev) => (prev === 1 ? 1 : --prev))
  }

  return { roomCount, incrementRoom, decrementRoom, setRoomCount }
}

export default useRoomCount
