import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const scrollToRef = (ref) => ref.current.scrollIntoView()

export default function () {
  const { pathname } = useLocation()
  const myRef = useRef(null)

  const executeScroll = () => scrollToRef(myRef)

  const isWidget = window.parent !== window

  useEffect(() => {
    if (isWidget) {
      window.parent.postMessage({ type: 'scroll-to-top' }, '*')
    } else {
      executeScroll()
    }
  }, [pathname])

  return <div ref={myRef}></div>
}
