import React, { useState } from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'
import HotelIcon from '@material-ui/icons/Hotel'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {},
  drawer: {
    '& .MuiDrawer-paper	': {
      width: '100%',
    },
  },
  align: { display: 'flex', alignItems: 'center' },
  medium: {
    fontWeight: 500,
    flex: 1,
    marginLeft: theme.spacing(1)
  },
  finishBtn: {
    borderRadius: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1.5, 2),
    letterSpacing: 1.1,
  },
  container: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    padding: theme.spacing(0.5, 1.5),
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
    marginBottom: '16px',
    paddingRight: 0,
  },
  btn: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '1.1px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(4),
    fontSize: '14px'
  }
}))

const SelectTraveller = ({ initialValue, changeValue, goBack }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [value, setValue] = useState(initialValue)

  const onSubmit = () => {
    changeValue(value)
    goBack()
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.input}>
        <Box className={classes.align}>
          <HotelIcon color="primary"/>
          <Typography
            className={classes.medium}
          >
            {value} {t('detail adults')}
          </Typography>
          <Box className={classes.align}>
            <IconButton
              color="primary"
              disabled={value === 1 || value === '1'}
              onClick={() => setValue(Number(value) - 1)}
            >
              <RemoveIcon color={(value === 1 || value === '1') ? 'disabled' : 'primary'}/>
            </IconButton>
            <Typography className={classes.medium}>{value}</Typography>
            <IconButton
              color="primary"
              onClick={() => setValue(Number(value) + 1)}
            >
              <AddIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={onSubmit}
        className={classes.btn}
      >
        {t('select')}
      </Button>
    </Box>
  )
}

export default SelectTraveller
