import React, { memo, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Box,
  AppBar,
  Toolbar,
  Grid,
  Container,
} from '@material-ui/core'
import AutoCompleteInput from '../../components/reusable/autoCompleteInput'
import ReusableDatpicker from '../../components/reusable/datePicker/reusableDatePicker_new'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    position: 'relative',
  },
  align: {
    display: 'flex',
    alignItems: 'stretch',
  },
  searchBtn: {
    borderRadius: '12px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(1, '18px'),
    width: '100%',
    height: '48px',
    letterSpacing: '0.7px',
  },
  lowercase: {
    textTransform: 'capitalize',
  },
  border: {
    borderRadius: `8px 8px 8px 8px`,
    border: `1px solid ${theme.palette.border}`,
    marginBottom: theme.spacing(1),
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const CheckoutInlineSearch = ({
  /* */
  from,
  setFrom,
  to,
  setTo,
  /**/
  departure,
  setDeparture,
  arrival,
  setArrival,
  /* */
  isOneWay,
  onSubmit,
  /* */
  getCities,
  optionLabel,
  array,
  /**/
  renderOption,
  /**/
  TRAIN,
  earliestDate,
  latestDate,
}) => {
  const { isAuthenticated } = useSelector((state) => state.auth)
  const classes = useStyles({ isAuthenticated })
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const departureRef = useRef()
  const toRef = useRef()

  return (
    <Container maxWidth="lg" disableGutters>
      <Box
        className={classes.container}
        mt={1}
        mb={1}
        style={{ width: '100%', flexWrap: 'unset' }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.border} pl={2}>
              <AutoCompleteInput
                fetch={getCities}
                value={from}
                onChange={(_, value) => setFrom(value)}
                array={array}
                optionLabel={optionLabel}
                label={t('search from')}
                placeholder={t('search from')}
                renderOption={renderOption}
                isFrom
                desktop
                toRef={toRef}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box pl={2} className={classes.border}>
              <AutoCompleteInput
                renderOption={renderOption}
                fetch={getCities}
                value={to}
                onChange={(_, value) => setTo(value)}
                array={array}
                optionLabel={optionLabel}
                label={t('search to')}
                placeholder={t('search to')}
                isTo
                desktop
                forwardedRef={toRef}
              />
            </Box>
          </Grid>

          <Grid container>
            <Grid item xs={TRAIN ? 7 : 12}>
              <Box
                pl={2}
                className={classes.border}
                style={{ cursor: 'pointer' }}
              >
                <ReusableDatpicker
                  disableLabel
                  value={departure}
                  onChange={setDeparture}
                  from={departure}
                  to={arrival}
                  isOutgoing
                  back
                  minDate={earliestDate}
                  maxDate={latestDate}
                  minimalPlacementLogic={true}
                  ref={departureRef}
                />
              </Box>
            </Grid>
            {TRAIN && (
              <Grid item xs={5}>
                <Box
                  pl={2}
                  ml={1}
                  className={classes.border}
                  style={{ cursor: 'pointer' }}
                >
                  <ReusableDatpicker
                    disableLabel
                    value={departure}
                    onChange={setDeparture}
                    from={departure}
                    to={arrival}
                    isOutgoing
                    back
                    timeOnly
                    minDate={earliestDate}
                    maxDate={latestDate}
                    minimalPlacementLogic={true}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={TRAIN ? 7 : 12}>
              <Box
                pl={2}
                className={classes.border}
                style={{ cursor: 'pointer' }}
              >
                <ReusableDatpicker
                  disableLabel
                  value={arrival}
                  onChange={setArrival}
                  isOneWay={isOneWay}
                  HOTEL={false}
                  from={departure}
                  to={arrival}
                  minDate={earliestDate}
                  maxDate={latestDate}
                  minimalPlacementLogic={true}
                />
              </Box>
            </Grid>
            {TRAIN && (
              <Grid item xs={5}>
                <Box
                  pl={2}
                  ml={1}
                  className={classes.border}
                  style={{ cursor: 'pointer' }}
                >
                  <ReusableDatpicker
                    disableLabel
                    value={arrival}
                    onChange={setArrival}
                    from={departure}
                    to={arrival}
                    isOutgoing
                    back
                    timeOnly
                    minDate={earliestDate}
                    maxDate={latestDate}
                    minimalPlacementLogic={true}
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button
              disableRipple
              disableElevation
              onClick={onSubmit}
              variant="contained"
              color="primary"
              className={classes.searchBtn}
            >
              {t('search go')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default memo(CheckoutInlineSearch)

//search go
