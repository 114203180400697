import React, {Fragment, memo, useContext, useMemo, useState} from 'react';
import {Box, Select, MenuItem, FormControlLabel, Checkbox, FormHelperText, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Alert} from "@material-ui/lab";
import {TransfersContext} from "../../containers/transfer/transferContainer";

const useStyles = makeStyles((theme) => ({
  goToLink: {
    cursor: 'pointer',
    marginLeft: '5px',
    fontWeight: theme.typography.fontWeightSemiBold
  },
  userSelect: {
    '&.Mui-error': {
      color: '#f44336'
    },
    '& .MuiSelect-root': {
      paddingTop: '14.5px',
      paddingBottom: '14.5px'
    },
    '&.MuiOutlinedInput-root': {
      borderRadius: '4px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e6e6e6'
      }
    }
  },
  errorLabel: {
    marginTop: '-6px',
    lineHeight: 'normal',
    width: '100%'
  },
  selectboxItem: {
    flexDirection: 'column'
  },
  noMarginTop: {
    marginTop: 0
  },
  userCheckbox: {
    width: '100%'
  }
}))
const SelectTransferPassenger = ({parentModel, transfer, onSelect, goToTransfer, touched}) => {
  const {allUsers, userLocators} = useContext(TransfersContext)
  const {items} = useSelector((state) => state.checkout)
  const { t } = useTranslation()
  const classes = useStyles()
  const [clickedUsers, setClickedUsers] = useState([])

  const touchUser = (uInd, transferInd = null) => {
    if (!clickedUsers.includes(uInd)) {
      setClickedUsers([...clickedUsers, uInd])
      return true
    }

    if (transferInd === null || typeof(goToTransfer) !== 'function') return false
    goToTransfer(transferInd)
  }

  const usedUsers = useMemo(() => {
    const used = {}
    parentModel?.transfer?.forEach((t, i) => {
      const isTrain = (t?.type === 'flightBus' || (t?.type === 'tagTaxi' && t?.product?.code === 'RAILE'))

      if (
        !t?.passenger ||
        (isTrain && (typeof(t.passenger) !== 'object' || !t.passenger?.length)) ||
        transfer.uniqueKey === t.uniqueKey
      ) {
        return false;
      }
      if (isTrain) {
        t.passenger?.forEach(pId => {
          if (!!used[pId]) return false;
          used[pId] = i+1;
        })
      } else {
        used[t.passenger] = i+1;
      }

    })
    return used
  }, [parentModel, transfer])

  const isMulti = useMemo(() => {
    return (
      transfer.type === 'flightBus' ||
      (transfer?.type === 'tagTaxi' &&
      transfer?.product?.code === 'RAILE')
    )
  }, [transfer])

  let selectedUser = useMemo(() => {
    let resultUser = null;
    if (isMulti) {
      const multiSelected = transfer?.passenger?.length ? transfer.passenger : []
      const result = multiSelected.filter(uLocator => userLocators.includes(uLocator))
      resultUser = result?.length ? result : null;
    } else {
      resultUser = userLocators.includes(transfer?.passenger) ? transfer.passenger : null
    }
    return resultUser
  }, [transfer, isMulti])

  const isError = useMemo(() => {
    return !selectedUser && touched && !transfer.isAdded
  }, [selectedUser, touched])

  const selectSinglePassenger = (e) => {
    onSelect(e.target.value || null)
  }

  const selectMultiPassenger = (uniqueId) => {
    let newSelected = selectedUser?.length ? [...selectedUser] : [];
    if (newSelected.includes(uniqueId)) {
      newSelected = newSelected.filter(id => id !== uniqueId)
    } else {
      newSelected.push(uniqueId)
    }

    if (!newSelected?.length) {
      newSelected = null
    }

    onSelect(newSelected)
  }

  const getAlert = (usedUser, user, skipLink = false, alwaysShow = false) => {
    if (!usedUser || (!clickedUsers.includes(user.uniqueId) && !alwaysShow)) return null
    return (
      <FormHelperText
        key={`select-help-text-users-${user.uniqueId}`}
        error={true}
        className={`${classes.errorLabel} ${skipLink ? classes.noMarginTop : ''}`}
      >
        <span>{t('already has flygtaxi')}</span>
        {typeof(goToTransfer) === 'function' && !skipLink && (
          <span
            className={classes.goToLink}
            onClick={() => goToTransfer(usedUser)}
          >
            {t('go there')}
          </span>
        )}
      </FormHelperText>
    )
  }

  const selectSingleUserForm = () => {
    return (
      <Box key={`select-outer-user-${transfer.uniqueKey}`} width={'100%'}>
        <Select
          key={`select-transfer-user-${transfer.uniqueKey}`}
          displayEmpty
          className={classes.userSelect}
          InputLabelProps={{ shrink: false }}
          variant={'outlined'}
          error={isError}
          placeholder={t('select passenger')}
          fullWidth
          disabled={!!transfer?.isAdded}
          value={selectedUser}
          onChange={(e) => selectSinglePassenger(e)}
          IconComponent={ExpandMoreIcon}
        >
          <MenuItem component={'div'} style={{ color: 'silver' }} key={`empty-val`} value={null}>
            {t('select passenger')}
          </MenuItem>
          {allUsers.map(u => {
            const isSelected = selectedUser === u.uniqueId
            const usedUser = usedUsers[u.uniqueId]
            return (
              <MenuItem
                className={classes.selectboxItem}
                component={'div'}
                disabled={usedUser} key={`user-${u.uniqueId}`}
                value={u.uniqueId}
              >
                <Box width={'100%'} key={'user-name'}>{u.firstName} {u.lastName}</Box>
                {getAlert(usedUser, u, true, true)}
              </MenuItem>
            )
          })}
        </Select>
      </Box>
    )
  }

  const selectMultiUserForm = () => {
    return (
      <Box key={`select-outer-users-${transfer.uniqueKey}`} width={'100%'}>
        {allUsers.map(u => {
          const isSelected = selectedUser?.includes(u.uniqueId)
          const usedUser = usedUsers[u.uniqueId]
          return (
            <Fragment key={`users-fragment-block-${u.uniqueId}`}>
              <FormControlLabel
                className={classes.userCheckbox}
                key={`select-fgl-users-${u.uniqueId}`}
                disabled={!!transfer?.isAdded}
                onClick={() => touchUser(u.uniqueId)}
                control={
                  <Checkbox
                    key={`select-fgl-users-${u.uniqueId}`}
                    checked={!!isSelected}
                    disabled={(!isSelected && !!usedUser) || !!transfer?.isAdded}
                    onChange={() => selectMultiPassenger(u.uniqueId)}
                    color="primary"
                  />
                }
                label={`${u.firstName} ${u.lastName}`}
              />
              {getAlert(usedUser, u)}
            </Fragment>
          )
        })}
      </Box>
    )
  }
  const renderUserForm = () => {
    if (!isMulti) {
      return (
        <Fragment>
          {selectSingleUserForm()}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {selectMultiUserForm()}
        </Fragment>
      )
    }
  }

  return (
    <Box width={'100%'}>
      {renderUserForm()}
      {!!isError && (
        <Box mt={2}>
          <Alert severity="error">{t('should assign user')}</Alert>
        </Box>
      )}
      {!!transfer?.isAdded && (
        <Box mt={2}>
          <Alert severity="warning">{t('this transfer booked')}</Alert>
        </Box>
      )}
    </Box>
  )
}


export default memo(SelectTransferPassenger)
