import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Box,
  Typography,
  Divider,
  TextField,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { Formik } from 'formik'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'
import ModalHook from '../../modals/reusable/modalHook'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPassengersData,
  setItemsPassengerField,
} from '../../../store/checkout/checkoutActions'
import {
  getIsFlightRequireDocs,
  getIsFlightRequiresValidName,
  getPhoneNumberFormat,
  validatePhoneNumber,
} from '../../../utils/general'
import useFormsHook from '../../../hooks/useFormsHook'
import NotifyBlock from './notifyBlock'
import { phoneInputHandler } from '../../../utils/phoneInputs'
import CostCenter from './costCenter'
import BookingRefData from '../bookingRefData'

const useStyles = makeStyles((theme) => ({
  phoneModal: {
    padding: '15px',
  },
  phoneFieldInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      '& input': {
        paddingTop: '13px',
        paddingBottom: '13px',
      },
    },
  },
  editPhoneLabel: {
    fontSize: '24px',
    marginBottom: '24px',
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  submitBlock: {
    textAlign: 'right',
  },
  phoneFieldBlock: {
    marginBottom: '24px',
  },
  editPhoneBtn: {
    fontWeight: theme.typography.fontWeightSemiBold,
    letterSpacing: '1.1px',
    paddingLeft: '24px',
    paddingRight: '24px',
    boxShadow: 'none',
  },
  align: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  mobile: {
    textDecoration: 'underline',
    cursor: 'pointer',

    '&.error': {
      color: theme.palette.secondary.main,
    },
  },
  editMobile: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&.error': {
      color: theme.palette.secondary.main,
    },
  },
  mobileUserText: {
    marginRight: '8px',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  contactUsBlock: {
    backgroundColor: '#eef8f8',
    borderRadius: 4,
    padding: '25px 20px',
    width: '100%',
  },
  mainText: {
    fontSize: '16px',
    color: theme.palette.common.black,
  },
  wrapper: {
    marginBottom: '24px',
  },
}))

const Travelers = ({
  items,
  padding = 2,
  noBottomBorder,
  useCF = false,
  bookingResult,
}) => {
  let allPassengers = []
  const passengerIds = []
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const formik = useFormikContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [phoneEdit, setPhoneEdit] = useState(null)
  const { countryPhone, isAuthenticated } = useSelector((state) => state.auth)
  const { passengerData, passengerDataLoading, costfields } = useSelector(
    (state) => state.checkout
  )
  const hasCostfields = costfields?.costfields?.length > 0
  const phoneNumberFormat = getPhoneNumberFormat(countryPhone?.code)

  const carItem = items.find((i) => i?.type === 'Car')
  const isFlightRequireDocs = getIsFlightRequireDocs(items)

  const hasTrain = items.find((i) => i.type === 'Train')
  const trainWithSJR = items.find(
    (item) =>
      item.type === 'Train' &&
      item.outboundTrip.train.scheduleSolution.serverProviderCode === 'SJR'
  )
  const trainWithMTR = items.find(
    (item) =>
      item.type === 'Train' &&
      item.outboundTrip.train.scheduleSolution.serverProviderCode === 'MTR'
  )

  const isFlightRequiresValidName = getIsFlightRequiresValidName(items)

  items.forEach((item) => {
    if (item.passengers) allPassengers = [...allPassengers, ...item.passengers]
  })
  allPassengers = allPassengers.filter((usr) => {
    if (
      !passengerIds.includes(usr.uniqueId) &&
      (!usr.isPublic || usr.isUpdated)
    ) {
      passengerIds.push(usr.uniqueId)
      return true
    }
    return false
  })

  const onChangePhone = useCallback(
    (data) => {
      if (!data?.phone || !phoneEdit?.uniqueId) {
        setPhoneEdit(null)
        return
      }
      dispatch(
        setItemsPassengerField({ [phoneEdit.uniqueId]: { mobile: data.phone } })
      )
      setPhoneEdit(null)
      let index = formik.values.travellers.findIndex(
        (t) => t.uniqueId == phoneEdit.uniqueId
      )
      formik.setFieldValue(`travellers.[${index}].mobile`, data.phone, false)
    },
    [dispatch, phoneEdit]
  )

  const initialValues = useMemo(
    () => ({
      phone: phoneEdit?.mobile || '',
    }),
    [phoneEdit]
  )

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .nullable()
      .required(t('required field'))
      .test(
        'valid-number',
        t('invalid phone number', { phoneNumberFormat }),
        (value) => validatePhoneNumber(value, countryPhone.code)
      ),
  })

  useEffect(() => {
    if (passengerIds?.length) {
      dispatch(getPassengersData(passengerIds))
    }
  }, [])

  const editPhoneModal = (
    <ModalHook
      modalBoolean={!!phoneEdit}
      onClose={() => setPhoneEdit(null)}
      mobileFullWidth={false}
      width={500}
    >
      <Box className={classes.phoneModal}>
        <Formik
          enableReinitialize
          onSubmit={onChangePhone}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            errors,
            values,
            touched,
            handleChange,
            setFieldValue,
          }) => {
            const phonePasteHandler = (event) => {
              let text = event.clipboardData.getData('Text')

              text = text.replace(/[^\+0-9]/gm, '')
              setFieldValue('phone', text, false)
              event.preventDefault()
            }

            const Error = touched?.phone && errors?.phone
            return (
              <form onSubmit={handleSubmit}>
                <Box className={classes.editPhoneLabel}>
                  {t('phone mobile')}
                </Box>
                <Box className={classes.phoneFieldBlock}>
                  <TextField
                    name={'phone'}
                    autoComplete={'off'}
                    inputProps={{ autoCorrect: 'off', spellCheck: 'false' }}
                    type={'tel'}
                    fullWidth
                    error={!!Error}
                    helperText={Error}
                    InputLabelProps={{ shrink: false }}
                    value={values?.phone || ''}
                    variant="outlined"
                    className={classes.phoneFieldInput}
                    onKeyPress={phoneInputHandler}
                    InputProps={{ style: { fontWeight: 500 } }}
                    onChange={handleChange}
                    placeholder={phoneNumberFormat}
                    onPaste={phonePasteHandler}
                  />
                </Box>
                <Box className={classes.submitBlock}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.editPhoneBtn}
                  >
                    {t('save')}
                  </Button>
                </Box>
              </form>
            )
          }}
        </Formik>
      </Box>
    </ModalHook>
  )

  const renderPassengers = allPassengers.map((p, i) => {
    const { email, firstName, lastName, uniqueId, mobile } = p
    const mobTxt = mobile || t('no mobile').toLowerCase()
    const addTxt = !!mobile ? t('edit btn') : t('add')
    const errClass =
      !!formik && formik.submitCount > 0 && !mobile ? 'error' : ''
    const driveText = !!carItem && i === 0 ? ` (${t('driver')})` : ''
    const {
      buildGender,
      buildBirthDateField,
      buildCountryField,
      buildLoyaltyField,
    } = useFormsHook()

    let loyaltyPlaceholder = null
    if (trainWithSJR) loyaltyPlaceholder = t('add loyalty program SJ')
    if (trainWithMTR) loyaltyPlaceholder = t('add loyalty program MTR')
    let bookings = null
    if (bookingResult?.[uniqueId]?.bookings) {
      bookings = (
        <Box mt={'20px'} pl={'20px'} className={`${classes.mainText}`}>
          <BookingRefData bookingResult={bookingResult[uniqueId].bookings} />
        </Box>
      )
    }
    const showLoyalty =
      !passengerData?.[uniqueId]?.docExists &&
      formik &&
      !passengerDataLoading &&
      (trainWithSJR || trainWithMTR) &&
      p.isGuest

    // NDS - require phone and email
    const requirePhone = formik?.values?.eticketDelivery === 'phone' || hasTrain

    const showRequireDocs =
      !passengerData?.[uniqueId]?.docExists &&
      isFlightRequireDocs &&
      isAuthenticated &&
      formik &&
      !passengerDataLoading

    const showNameNote = !showRequireDocs && isFlightRequiresValidName

    return (
      <Fragment key={uniqueId}>
        <Divider />
        <Box pt={2} pb={2}>
          <Box className={classes.align}>
            {!requirePhone ? (
              <Fragment>
                <Typography>
                  {firstName} {lastName} {driveText}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {email}
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Typography className={classes.mobileUserText}>
                  {firstName} {lastName}, {email},{' '}
                  <span
                    onClick={() => setPhoneEdit({ ...p })}
                    className={`${classes.mobile} ${errClass}`}
                  >
                    {mobTxt}
                  </span>
                </Typography>
                <span
                  className={`${classes.editMobile} ${errClass}`}
                  onClick={() => setPhoneEdit({ ...p })}
                >
                  {addTxt}
                </span>
              </Fragment>
            )}
          </Box>
          {!passengerDataLoading ? bookings : null}
        </Box>

        {passengerDataLoading && (
          <Box mx={4} m={2} textAlign={'center'}>
            <CircularProgress />
          </Box>
        )}

        {showNameNote ||
          (showRequireDocs && (
            <Box
              display={'flex'}
              mb={'35px'}
              alignItems={'center'}
              className={`${classes.contactUsBlock}`}
            >
              <span className={`${classes.mainText}`}>
                <strong>{t('please note')}: </strong>
                {t('traveler note check name')}
              </span>
            </Box>
          ))}

        {showRequireDocs && (
          <Box position="relative">
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.wrapper}
                key={'sex-field-traveller'}
              >
                {buildGender(formik, i, t('email form'))}
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.wrapper}
                key={'nationality'}
              >
                {buildCountryField(
                  formik,
                  'nationality',
                  t('nationality'),
                  false,
                  i
                )}
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.wrapper}
                key={'birthDate-traveller'}
              >
                {buildBirthDateField(
                  formik,
                  'birthDate',
                  i,
                  t('profile date'),
                  'travellers'
                )}
              </Grid>
            </Grid>
          </Box>
        )}

        {showLoyalty && (
          <>
            <Box pt={2} pb={2}>
              <NotifyBlock
                noSubNote
                noteType={'paper'}
                noteText={t('loyalty card information')}
              />
            </Box>

            {buildLoyaltyField(
              formik,
              'loyalty',
              i,
              'travellers',
              loyaltyPlaceholder
            )}
          </>
        )}
        {!!hasCostfields && !passengerDataLoading && !!useCF && (
          <Box pb={5}>
            <CostCenter uid={uniqueId} isGuest={p.isGuest} />
          </Box>
        )}
      </Fragment>
    )
  })
  return (
    <Fragment>
      {!!renderPassengers?.length && (
        <Fragment>
          <Box pt={2} pb={4} px={isMobile ? 0 : 2}>
            <Box p={padding}>
              <Box pb={3}>
                <Typography variant="h5" className={classes.bold}>
                  {t('detail travelers')}
                </Typography>
              </Box>
              <Box>{renderPassengers}</Box>
            </Box>
          </Box>
          {!noBottomBorder && <Divider />}
        </Fragment>
      )}
      {editPhoneModal}
    </Fragment>
  )
}

export default Travelers
