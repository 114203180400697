import React, {useCallback, useEffect, useState} from 'react'
import Drawer from '@material-ui/core/Drawer'
import { Typography, Box, Paper, useTheme } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'
import RangeSlider from './rangeSlider'
import CheckList from './checkList'
import {useDispatch, useSelector} from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import {setCarFilters, resetCarFilters} from '../../store/cars/carAction'
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: theme.palette.dimWhite },
  weight: { fontWeight: 700 },
  drawer: {
    marginBottom: theme.spacing(10),
    '& .MuiDrawer-paper	': {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '400px',
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    height: '64px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 3),
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
    zIndex: 1000
  },
  boldTitle: {
    fontWeight: 700,
    fontSize: theme.spacing(2)
  },
  resetFilter: {
    fontSize: theme.spacing(1.75),
    textTransform: 'capitalize',
    lineHeight: '16px',
    fontWeight: 700
  },
  headerBtn: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.7px'
  }
}))

const CarFilterDrawer = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [tmpFilters, setTmpFilters] = useState(null)
  const theme = useTheme()
  const {cars, filters} = useSelector((state) => state.cars)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // build filters to show in form
  const buildTmpFilters = useCallback((carsObj, filters) => {

    // --- prepare PRICE filter for form
    let highest = 0
    const resFilters = {}
    const tmpRates = (carsObj?.rates || [])
    tmpRates.forEach(c => {
      if ((c?.estimatedTotal || 0) >  highest) highest = Math.ceil(c.estimatedTotal)
    })

    resFilters['price'] = {
      highest,
      minPrice: filters.minPrice || 0,
      maxPrice: filters.maxPrice || highest,
    }

    // --- prepare COMPANY filter for form
    const companies = []
    const usedCompanies = [];
    const tmpLoc = (carsObj?.locations || [])

    tmpLoc.forEach(l => {
      if (!l?.companyName || usedCompanies.includes(l.companyName)) return
      const label = _.startCase(_.toLower(l.companyName))
      const key = l.companyName.replace(/\s/ig, '-')
      const fCompanies = filters?.companies || []
      companies.push({
        label,
        key,
        fkey: l.companyName,
        checked: fCompanies?.includes(l.companyName)
      })
      usedCompanies.push(l.companyName)
    })
    resFilters.companies = companies

    // --- prepare MILEAGE filter for form
    const filterMil = filters.mileages || []
    resFilters.mileages = [
      {
        label: t('unlimited'),
        key: 'unlimited',
        fkey: 'unlimited', // key for filter array
        checked: filterMil.includes('unlimited')
      }
    ]

    // --- prepare TRANSMISSION filter for form
    const filterTR = filters.transmissions || []
    resFilters.transmissions = [
      {
        label: t('automatic'),
        key: 'auto',
        fkey: 'auto', // key for filter array
        checked: filterTR.includes('auto')
      },

      {
        label: t('manual'),
        key: 'manual',
        fkey: 'manual', // key for filter array
        checked: filterTR.includes('manual')
      }
    ]

    // --- prepare FUEL filter for form
    const filterFuel = filters.fuel || []
    resFilters.fuel = [
      {
        label: t('green cars'),
        key: 'green',
        fkey: 'green', // key for filter array
        checked: filterFuel.includes('green')
      },

      {
        label: t('alternative fuel cars'),
        key: 'alternative',
        fkey: 'alternative', // key for filter array
        checked: filterFuel.includes('alternative')
      },

      {
        label: t('petrol cars'),
        key: 'petrol',
        fkey: 'petrol', // key for filter array
        checked: filterFuel.includes('petrol')
      }
    ]
    resFilters.changed = false
    return resFilters
  }, [t])

  const getCommitOptsFilter = (key) => {
    const fArr = tmpFilters?.[key] || []
    return fArr.filter(c => !!c?.checked && !!c.fkey).map(c => c.fkey)
  }

  const buildCommitFilters = () => {
    return {
      minPrice: tmpFilters?.price?.minPrice || null,
      maxPrice: tmpFilters?.price?.maxPrice || null,
      companies: getCommitOptsFilter('companies'),
      fuel: getCommitOptsFilter('fuel'),
      mileages: getCommitOptsFilter('mileages'),
      transmissions: getCommitOptsFilter('transmissions')
    }
  }

  useEffect(() => {
    if (!!open) setTmpFilters(buildTmpFilters(cars, filters))
  }, [open, cars, filters, buildTmpFilters])

  const commitFilters = () => {
    if (!!tmpFilters && typeof(tmpFilters) === 'object' && !!tmpFilters?.changed) {
      dispatch(setCarFilters(buildCommitFilters()))
      setTmpFilters({...tmpFilters, changed: false})
    }
    onClose()
  }

  const resetFilters = () => {
    dispatch(resetCarFilters())
  }

  const prepareOptsFilter = useCallback((opts, key, checked) => {
    return opts.map(c => {
      const resComp = {...c}
      if (resComp.key === key) resComp.checked = !!checked
      return resComp
    })
  }, [])

  const setTmpFilter = useCallback((key, val) => {
    if (!tmpFilters || typeof(tmpFilters) !== 'object') return false
    const resFilters = {...tmpFilters}
    const optKeys = [
      'companies',
      'mileages',
      'transmissions',
      'fuel',
    ]
    if (key === 'price') {
      if (val?.length <= 0) return false
      resFilters.price = {...(resFilters.price || {}), minPrice: val[0], maxPrice: val[1]}
      resFilters.changed = true
    } else if (optKeys.includes(key)) {
      if (!val?.key || !resFilters?.[key]?.length) return false
      resFilters[key] = prepareOptsFilter(resFilters[key], val?.key, val?.checked)
      resFilters.changed = true
    }
    setTmpFilters(resFilters)
  }, [prepareOptsFilter, tmpFilters])

  return (
    <Drawer className={classes.drawer} style={{ position: 'relative' }} anchor={'left'} open={open}>
      <Box height={'100%'} className={classes.container}>
        <Box className={classes.header}>
          <Box flex={1}>
            <button
              style={{
                background: 'transparent',
                outline: 'none',
                border: 'none',
                cursor: 'pointer',
                color: '#007f7f',
                fontWeight: 700,
                textDecoration: 'none',
                fontSize: '14px',
                paddingLeft: 0,
                fontFamily: 'Metropolis'
              }}
              onClick={resetFilters}
            >
              {t('reset filter')}
            </button>
          </Box>
          <Box flex={1} textAlign={'center'}>
            <Typography className={classes.boldTitle}>
              {t('filter')}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" flex={1}>
            <MuiButton
              variant="contained"
              disableElevation
              onClick={commitFilters}
              className={classes.headerBtn}
              color="primary"
            >
              {t('done')}
            </MuiButton>
          </Box>
        </Box>
        <Box px={isMobile ? 1 : 3} pb={3} pt={0} className={classes.container}>
          {tmpFilters?.price?.highest > 0 &&
            <Paper className={classes.paper} elevation={0}>
              <RangeSlider
                title={t('price range')}
                highest={tmpFilters.price.highest}
                min={tmpFilters.price.minPrice}
                max={tmpFilters.price.maxPrice}
                callBack={(val) => setTmpFilter('price', val)}
              />
            </Paper>
          }

          {tmpFilters?.transmissions?.length &&
            <Paper className={classes.paper} elevation={0}>
              <CheckList options={tmpFilters.transmissions} title={t('transmission')}  callBack={(val) => setTmpFilter('transmissions', val)}/>
            </Paper>
          }

          {tmpFilters?.mileages?.length &&
            <Paper className={classes.paper} elevation={0}>
              <CheckList options={tmpFilters.mileages} title={t('mileage')}  callBack={(val) => setTmpFilter('mileages', val)}/>
            </Paper>
          }

          {tmpFilters?.fuel?.length &&
            <Paper className={classes.paper} elevation={0}>
              <CheckList options={tmpFilters?.fuel} title={t('fuel type')}  callBack={(val) => setTmpFilter('fuel', val)}/>
            </Paper>
          }

          {tmpFilters?.companies?.length &&
            <Paper className={classes.paper} elevation={0}>
              <CheckList options={tmpFilters.companies} title={t('supplier')}  callBack={(val) => setTmpFilter('companies', val)}/>
            </Paper>
          }
        </Box>
      </Box>
    </Drawer>
  )
}

export default CarFilterDrawer
