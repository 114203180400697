import React, { memo } from 'react'
import { Box, Button, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.border}`,
    '&:first-child': {
      borderRadius: theme.spacing(1),
    },
    '&:last-child': {
      borderRadius: theme.spacing(1),
    },
  },
  btn: {
    flex: 1,
    display: 'flex',
    borderRadius: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    background: '',
    border: `1px solid transparent`,
    width: 'auto',
    minWidth: '32px'
  },
  selected: {
    display: 'flex',
    flex: ({isMobile, isSmallMobile, btnCount}) => {
      if (!isMobile || btnCount < 3) return 1
      const res = (isSmallMobile) ? btnCount - 1 : btnCount - 2
      return (res < 2) ? 2 : res
    },
    borderRadius: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}))

const ReserveSeatsHeader = ({
  seats,
  setFlight,
  currentFlight,
  passengerSeats,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({isMobile, isSmallMobile, btnCount: seats.length})
  const { t } = useTranslation()

  const renderFlights = seats.map((flight, key) => {
    const isReadyForNextFlight = passengerSeats.every(
      (passenger) =>
        passenger.flightKey !== flight?.flight?.uniqueInd ||
        passenger.seat
    )
    const isSelected = (currentFlight === key)
    return (
      <Button
        className={ isSelected ? classes.selected : classes.btn}
        fullWidth
        color="primary"
        onClick={() => setFlight(key)}
        key={key}
        endIcon={
          isReadyForNextFlight && flight.isFreeExists ? <CheckIcon /> : ''
        }
      >
        {(isSelected || !isMobile) ? t('flight') + ' ' : ''}{key + 1}
      </Button>
    )
  })

  return (
    <Box ml={3} mr={3} className={classes.align}>
      {renderFlights}
    </Box>
  )
}

export default memo(ReserveSeatsHeader)
