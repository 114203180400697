import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import Slider from "@material-ui/core/Slider"
import { useSliderStyles } from './sliderStyles'
import theme from '../../../theme/theme'
import { setDurationFilters } from '../../../store/search/searchAction'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'

const convertTimeToString = (value) => {
  return Number.isInteger(value) ? `${value}h 00m` : `${Math.floor(value)}h 30m`
}

const OneSideSlider = ({ roundValue, searchOutgoing, disabled, searchReturning, isOutgoing, t }) => {
  const travelTime = useSelector((state) => state.flights.filters.travelTime)
  const isLoading = useSelector((state) => state.flights.isLoading)

  const [maxJourney, setMaxJourney] = useState(null)
  const dispatch = useDispatch()
  const classes = useSliderStyles()

  const handleChangeMaxTime = (type, value) => {
    dispatch(setDurationFilters({ [type]: value * 60 }))
  }

  useEffect(() => {
    setMaxJourney(isOutgoing ? travelTime.trip : travelTime.returnTrip)
  }, [isOutgoing, searchOutgoing, searchReturning, travelTime.trip, travelTime.returnTrip])

  useEffect(() => {
    if ((!maxJourney || maxJourney === 100000000) && roundValue(isOutgoing ? searchOutgoing : searchReturning)) {
      dispatch(setDurationFilters({ [isOutgoing ? 'trip' : 'returnTrip']: (roundValue(isOutgoing ? searchOutgoing : searchReturning)) * 60 }))
    }
  },[maxJourney, searchReturning, searchOutgoing, dispatch, isOutgoing, roundValue])

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography className={classes.text} style={{ color: disabled ? theme.palette.divider : theme.palette.common.black }}>
          {!disabled ? t('outbound title') : t('search return')}
        </Typography>
        {!disabled && (
          <Typography className={classes.text} style={{ marginLeft: "16px" }}>
            {isLoading ? convertTimeToString(0) : convertTimeToString(maxJourney / 60)}
          </Typography>)
        }
      </Box>
      <Slider
        defaultValue={disabled ? 0 : roundValue(isOutgoing ? searchOutgoing : searchReturning)}
        value={(disabled || isLoading) ? 1 : ((maxJourney / 60) || 0) }
        onChange={(e,value) => setMaxJourney(value * 60)}
        onChangeCommitted={(e, value) => handleChangeMaxTime(isOutgoing ? 'trip' : 'returnTrip', value)}
        step={0.5}
        color="primary"
        min={0.5}
        max={(disabled || isLoading) ? 1 : roundValue(isOutgoing ? searchOutgoing : searchReturning)}
        disabled={disabled}
        classes={{root: classes.root}}
      />
    </>
  )
}

export default OneSideSlider
