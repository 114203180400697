import agent from './agent'

/*
    Fetch all customization data.
*/
export const fetchCustomization = async (lng) => {
  const url = `/v2/customization`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    // console.log('failed to fetch customization')
    return null
  }
}
