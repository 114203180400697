import CONSTANTS from './constants'

const initialState = {
  totalPrice: null,
  currentFare: null,
  returnFare: null,
  fareLoading: false,

  passengerSeats: [],
  seats: [],
  seatsLoading: false,
  //
  outboundCatalogue: null,
  returningCatalogue: null,
  selectedBaggage: {
    outbound: {},
    return: {},
  },

  standAloneLoading: false,
  //
  familyOutgoingPricing: [],
  familyReturningPricing: [],
  //
  middleStepSettings: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Seats
    case CONSTANTS.SET_FLIGHT_SEATS:
      return {
        ...state,
        seats: [...payload],
      }

    case CONSTANTS.SET_PASSENGER_SEATS:
      return {
        ...state,
        passengerSeats: payload,
      }

    case CONSTANTS.SET_TOTAL_PRICE:
      return {
        ...state,
        totalPrice: payload.price,
        currentFare: payload.fare,
      }
    case CONSTANTS.SET_OUTBOUND_FARE_FAMILY:
      return {
        ...state,
        currentFare: payload.fare,
      }
    case CONSTANTS.SET_RETURN_FARE_FAMILY:
      return {
        ...state,
        returnFare: payload.fare,
      }

    case CONSTANTS.CLEAR_ALL_SEATS:
      return {
        ...state,
        seats: [],
      }
    // Catalogue
    case CONSTANTS.SET_OUTGOING_CATALOGUE:
      return {
        ...state,
        outboundCatalogue: payload,
      }
    case CONSTANTS.SET_RETURN_CATALOGUE:
      return {
        ...state,
        returningCatalogue: payload,
      }
    case CONSTANTS.CLEAR_CATALOGUE_DATA:
      return {
        ...state,
        returningCatalogue: null,
        outboundCatalogue: null,
      }
    case CONSTANTS.CLEAR_SELECTED_BAGGAGE:
      return {
        ...state,
        selectedBaggage: {
          outbound: {},
          return: {},
        },
      }
    case CONSTANTS.SET_CATALOGUE_LOADING:
      return {
        ...state,
        standAloneLoading: payload,
      }
    //Selected baggage
    case CONSTANTS.SET_USER_BAGGAGE: {
      const { selectedBaggage } = state
      const { type, userId, value, bagType = 'general' } = payload

      const baggage = {
        outbound: { ...selectedBaggage.outbound },
        return: { ...selectedBaggage.return },
      }

      if (baggage[type] && value) {
        if (!baggage[type][userId]) baggage[type][userId] = {}
        baggage[type][userId][bagType] = value
      } else if (baggage[type] && baggage[type][userId] && value == null) {
        if (baggage[type][userId][bagType]) {
          delete baggage[type][userId][bagType]
        }

        const objKeys = (baggage[type][userId] && typeof baggage[type][userId] === 'object') ? Object.keys(baggage[type][userId]) : []
        if (!objKeys?.length) delete baggage[type][userId]
      }

      return {
        ...state,
        selectedBaggage: baggage,
      }
    }
    // Family Pricing
    case CONSTANTS.SET_OUTGOING_FAMILY_PRICING:
      return {
        ...state,
        familyOutgoingPricing: payload,
      }
    case CONSTANTS.SET_RETURNING_FAMILY_PRICING:
      return {
        ...state,
        familyReturningPricing: payload,
      }
    case CONSTANTS.SET_FARE_LOADING:
      return {
        ...state,
        fareLoading: payload,
      }
    case CONSTANTS.SET_FLIGHT_MIDDLESET_SETTINGS:
      return {
        ...state,
        middleStepSettings: payload,
      }
    // Loaders
    case CONSTANTS.SET_FLIGHT_SEAT_LOADING:
      return {
        ...state,
        seatsLoading: payload,
      }

    case CONSTANTS.FLIGHT_DETAILS_CLEAR_ALL:
      return initialState

    default:
      return state
  }
}
