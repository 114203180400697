import cityCityException from '../../cityCityException.Class'
import { buildRemarks } from './helpers'

export const GUARANTEE = '31'
export const DEPOSIT = '8'

/**
 * get available forms of payment
 *
 * @param checkout "current checkout state"
 * @param isAuthenticated
 * @param room
 * @returns {{rqFops: *[], fops: *[]}}
 */
export const getAvailableFop = (checkout, isAuthenticated = true, room) => {
  const { costfields = {}, fop, hotelGuarantee = null } = checkout || {}
  const primaryPayment =
    costfields?.paymentMethods?.primaryPayment || 'profileCC'
  const ccAvailable = costfields?.paymentMethods?.creditCard || false
  const { forceB2BWalletForLeisureHotels } = costfields || {}

  const { roomGuaranteeCode, isLeisure, country } = room
  const isGuarantee = roomGuaranteeCode === GUARANTEE

  const payHotelKey = isLeisure ? 'leisureHotel' : 'gdsHotel'
  let hotelAgreement =
    costfields?.paymentMethods?.agreementMethods?.[payHotelKey]

  let fops = []
  let rqFops = []

  if (forceB2BWalletForLeisureHotels && isLeisure) {
    fops.push('agreement')
  } else if (
    primaryPayment === 'agreement' &&
    hotelAgreement === 'b2bwallet' &&
    isLeisure
  ) {
    fops.push('agreement')
  } else if (
    primaryPayment === 'agreement' &&
    hotelAgreement === 'billback' &&
    !isLeisure
  ) {
    fops.push('agreement')
  } else if (primaryPayment === 'agreement' && hotelAgreement === 'voucherCC') {
    fops.push('agreement')
  } else if (isLeisure) {
    fops.push(primaryPayment)
  } else if (!isGuarantee) {
    ccAvailable ? fops.push('cc') : rqFops.push('cc')
  } else {
    const isInvoice =
      fop === 'invoice' || (fop === 'agreement' && hotelAgreement === 'invoice')
    const isProfileCC =
      fop === 'profileCC' ||
      (fop === 'agreement' && hotelAgreement === 'profileCC')

    fops.push(primaryPayment)
    if (ccAvailable && !isInvoice) {
      fops.push('cc')
    }
    const isPCCWithSaved = isProfileCC && hotelGuarantee
    if (fop !== 'cc' && !isPCCWithSaved) rqFops.push('cc')
  }

  if (!isAuthenticated && fops.includes('cc')) fops = ['cc'] // if public user && CC available then leave only Credit Card payment
  if (!fops.includes(fop) && fops[0] && rqFops.length === 0)
    rqFops.push(fops[0])

  return { fops, rqFops }
}

/**
 * build payment data
 * @param room
 * @param state
 * @param extData
 * @returns {{guaranteeCardData: *, fop: string}|{guaranteeCardData: {type: string, value: {number: *, code: (string|null), exp: *}, option: string}, fop: string}|{guaranteeCardData: null, fop: string, ccInfo: {firstName: *, ccNumber: *, surname: *, expire: *, guarantee: string, ccHolderName: string, vendorCode: (string|null)}}|{guaranteeCardData: null, fop: string}}
 */
const getFormOfPayment = (room, state, extData = {}) => {
  const {
    fop,
    hotelGuarantee,
    creditCard,
    costfields,
    netsPaymentId,
    paiwiseCheckoutId,
  } = state.checkout
  const primaryPayment =
    costfields?.paymentMethods?.primaryPayment || 'profileCC'
  const { user, isAuthenticated } = state.auth
  const { roomGuaranteeCode, isLeisure, country } = room
  const isGuarantee = roomGuaranteeCode === GUARANTEE

  const payHotelKey = isLeisure ? 'leisureHotel' : 'gdsHotel'
  let hotelAgreement =
    costfields?.paymentMethods?.agreementMethods?.[payHotelKey]

  const available = getAvailableFop(state.checkout, isAuthenticated, room)
  let currentFop = available.fops.includes(fop) ? fop : available.fops[0]

  const uData = isAuthenticated
    ? user
    : extData?.usersData?.[0] || { firstName: '', lastName: '' } //use first user data if public booking
  if (!currentFop && available.rqFops.length > 0)
    currentFop = available.rqFops[0]

  // For Nets payments, always use creditline. This assumes we only have Leisure (non-GDS) content.
  if (netsPaymentId || paiwiseCheckoutId) {
    return {
      fop: 'creditline',
      guaranteeCardData: null,
    }
  }

  if (currentFop === 'cc') {
    if (
      !creditCard?.number ||
      !creditCard?.expire ||
      (!isGuarantee && !creditCard?.cvv) ||
      !creditCard?.ccCode
    ) {
      throw new cityCityException({ message: 'Invalid Guarantee' })
    }

    return isGuarantee
      ? buildManualGuarantee(state.checkout)
      : {
          fop: 'cc',
          guaranteeCardData: null,
          ccInfo: {
            vendorCode: creditCard?.ccCode || null,
            ccNumber: creditCard.number.replace(/\s+/g, ''),
            ccHolderName: `${uData.firstName} ${uData.lastName}`,
            firstName: uData.firstName,
            surname: uData.lastName,
            expire: creditCard.expire.replace('/', '').replace(/\s+/g, ''),
            guarantee: 'deposit',
            cvv: creditCard.cvv,
          },
        }
  }

  if (
    isGuarantee &&
    !isLeisure &&
    currentFop === primaryPayment &&
    hotelAgreement !== 'voucherCC' &&
    hotelAgreement !== 'billback'
  ) {
    const guaranteeCard =
      available.rqFops.includes('cc') && !hotelGuarantee
        ? buildManualGuarantee(state.checkout)['guaranteeCardData']
        : hotelGuarantee
    if (!guaranteeCard)
      throw new cityCityException({ message: 'Invalid Guarantee' })

    return {
      fop: currentFop,
      guaranteeCardData: guaranteeCard,
    }
  }

  return {
    fop: currentFop,
    guaranteeCardData: null,
  }
}

const buildManualGuarantee = (checkoutState) => {
  const { creditCard } = checkoutState

  if (!creditCard?.number || !creditCard?.expire || !creditCard?.ccCode) {
    throw new cityCityException({ message: 'Invalid Guarantee' })
  }

  return {
    fop: 'cc',
    guaranteeCardData: {
      value: {
        number: creditCard.number,
        exp: creditCard.expire.replace('/', ''),
        code: creditCard?.ccCode || null,
      },
      type: 'CC',
      option: 'creditcard',
    },
  }
}

export default (checkoutHotel, state, extData) => {
  const tmpModels = checkoutHotel.trips ? checkoutHotel.trips : [checkoutHotel]
  const { passengers } = checkoutHotel
  const { usersData = [] } = extData || {}
  const usersDataById = {}
  usersData.forEach((u) => (usersDataById[u.uniqueId] = { ...u }))
  const fixedPassengers = passengers.map((p) => {
    const pData = usersDataById?.[p.uniqueId] || {}
    return { ...p, ...pData }
  })

  const hotels = tmpModels.map((model) => {
    const {
      room,
      hotel,
      roomsCount,
      totalPrice,
      verifyPrice,
      usersPerRoom = 1,
    } = model
    const { isLeisure } = room
    const fop = getFormOfPayment(
      { ...room, country: hotel?.params?.countryCode },
      state,
      extData
    )
    const { isAuthenticated, encloseCostfieldValues } = state.auth

    const {
      publicContacts,
      costFieldValues = {},
      costfields,
    } = state.checkout || {}
    const hotelData = hotel?.parsedHotel || null
    const { usersData = [] } = extData || {}

    if (!hotelData) {
      throw Error('Invalid Hotel')
    }

    const usersDataById = {}
    usersData.forEach((u) => (usersDataById[u.uniqueId] = { ...u }))

    let addressLine = hotelData?.location
    return {
      totalPrice,
      isLeisure,
      bookingParams: {
        count: roomsCount,
        guests: [{ ageCode: 'adult', count: usersPerRoom }],
      },
      confirmationData: {
        checkInTime: hotelData?.checkInTime,
        checkOutTime: hotelData?.checkOutTime,
        cancellationRule:
          room.cancellationType === 'free'
            ? 'Gratis avbokning t.o.m ' + room.freeCancellationDeadline
            : '',
        freeCancellation:
          room.cancellationType === 'free'
            ? room.freeCancellationDeadline
            : null,
        cancellationType: room.cancellationType,
        breakfastIncluded: room.breakfastIncluded,
        startDate: room.timeSpan?.Start || '',
        endDate: room.timeSpan?.End || '',
        guests: fixedPassengers.map((p) => {
          const passCfData = {
            costFieldValues: costFieldValues?.[p.uniqueId] || {},
            costfields,
          }
          return {
            name: p.firstName + ' ' + p.lastName,
            email: p?.email || null,
            firstName: p.firstName,
            lastName: p.lastName,
            mobile: p?.mobile || p?.phone || null,
            locator: p.uniqueId,
            isGuest: p.isGuest || false,
            remarks: buildRemarks(passCfData, encloseCostfieldValues),
          }
        }),
        hotelCode: hotelData?.hotelCode || '',
        image: hotel.image || '',
        location: addressLine,
        address: hotelData?.address,
        zip: hotelData?.zip,
        city: hotelData?.city,
        countryCode: hotelData.countryCode,
        price: totalPrice,
        rooms: roomsCount,
        title: hotelData?.title || '',
        environmentallyFriendly: hotelData?.environmentallyFriendly,
        freeWifi: room.freeWifi,
        phone: hotelData?.phone,
        isCollectiveAgreement: room.isCollectiveAgreement,
        bed: room.bed,
        roomTitle: room.title,
        size: room.size,
        sleepingSpots: room.sleepingSpots,
        noWindowRoom: room.noWindowRoom,
        isNoBathroom: room.isNoBathroom,
        isBunkbed: room.isBunkbed,
      },
      countryCode: hotelData?.CachedData?.COUNTRY_CODE || '',
      startDate: room.timeSpan?.Start || '',
      roomsCount: roomsCount,
      rawPrice: room?.rawPrice,
      rawPriceBeforeTax: room?.rawPriceBeforeTax,
      descriptionText: room.roomDescriptionText,
      endDate: room.timeSpan?.End || '',
      hotelChainCode: hotelData?.chainCode || '',
      hotelCityCode: hotelData?.cityCode || '',
      hotelCode: hotelData?.hotelCode || '',
      isCancellation: room.cancellationType === 'free',
      roomRateCode: room.ratePlanCode || '',
      roomTypeCode: room.roomTypeCode || '',
      roomInfoCode: room.roomInfoCode || '',
      guaranteeCode: room.roomGuaranteeCode || '',
      roomGuarantees: room.roomGuarantees || '',
      verifyRoomPrice: parseFloat(room.rawPrice),
      verifyPrice: verifyPrice,
      ...(isAuthenticated ? {} : { publicContacts: { ...publicContacts } }),
      ...fop,
    }
  })

  return { passengers: [...fixedPassengers], hotels: [...hotels] }
}
