import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Routes from './routes/routes'
import ModalHandler from './containers/modals/modalHandler'
import ScrollToTop from './hooks/scrollToTop'
import RegularLoader from './components/reusable/loaders/regularLoader'
import BackgroundHook from './hooks/backgroundHook'
import './theme/datePicker.css'
// import RestoreIcon from '@material-ui/icons/Restore';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import LocationOnIcon from '@material-ui/icons/LocationOn';

import 'moment/locale/sv'
import 'moment/locale/en-gb'
import SnackbarComponent from './containers/snackbar/snackbar'
import { fetchPublicity, logOutUser } from './store/auth/authAction'
import {
  getCustomization,
  setIsIframe,
} from './store/customization/customizationAction'
// import BottomNavigation from '@material-ui/core/BottomNavigation'
// import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import ReactGA from 'react-ga4'
import useOpenIdConnectHook from './hooks/useOpenIdConnectHook'

import './assets/cms-styles.css'

import i18n from './utils/i18n'
import { setHideTrain, setOnlyOidc } from './store/other/otherAction'
import { setSearchTravellers } from './store/search/searchAction'
import { openAddMobileModal } from './store/modals/modalAction'
// import { setEnableZoneFilter } from './store/hotels/hotelsAction'

const App = () => {
  const {
    isAuthenticated,
    user,
    isLoading,
    publicity,
    defaultLang,
    requireMobile,
    // useAvropa,
  } = useSelector((state) => state.auth)
  const { users } = useSelector((state) => state.users)
  const dispatch = useDispatch()
  const { title, favicon } = useSelector((state) => state.customization)
  const { oidcLoading } = useOpenIdConnectHook()

  useEffect(() => {
    ReactGA.initialize('G-GVJKGHL4QD')
    ReactGA.send('pageview')

    if (parent.window !== window) {
      dispatch(setIsIframe(true))
      window.parent.postMessage({ type: 'loaded' }, '*')
    }

    // hideTrains parameter
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('hideTrain')) {
      dispatch(setHideTrain(searchParams.get('hideTrain')))
    }

    // onlyOidc parameter
    if (searchParams.has('onlyOidc')) {
      dispatch(setOnlyOidc(true))
    }
  }, [])

  /**
   * Set default search travellers to active user.
   */
  useEffect(() => {
    if (users && isAuthenticated) {
      let currentUser = users.find((u) => u.uniqueId === user.uniqueId)
      if (currentUser) {
        dispatch(setSearchTravellers([currentUser]))
      }
    }
  }, [users, isAuthenticated])

  /**
   * Prompt for mobile number if not set.
   *
   * - agency option "requireMobile"
   */
  useEffect(() => {
    if (isAuthenticated && requireMobile && !user.isBookingLink) {
      const mobile = user.isUmbrella
        ? user.contacts?.mobile
        : user.contacts?.phone
      if (!mobile) {
        dispatch(openAddMobileModal())
      }
    }
  }, [isAuthenticated, user, requireMobile])

  // fetch site publicity and customization
  useEffect(() => {
    if (oidcLoading === false) {
      dispatch(fetchPublicity())
      dispatch(getCustomization())
    }
  }, [dispatch, oidcLoading])

  // Set site title and favicon from customization.
  useEffect(() => {
    if (title) {
      document.title = title
    }
    if (favicon) {
      const faviconEl = document.getElementById('favicon')
      if (faviconEl) {
        faviconEl.href = favicon
      }
    }
  }, [title, favicon])

  // Use default agency language if it was not set on page load.
  useEffect(() => {
    if (defaultLang && !i18n.languageWasSet) {
      i18n.changeLanguage(defaultLang)
    }
  }, [defaultLang])

  // // Use Avropa if enabled
  // useEffect(() => {
  //   dispatch(setEnableZoneFilter(useAvropa))
  // }, [useAvropa])

  // Logout user if publicity "public only"
  useEffect(() => {
    if (isAuthenticated && publicity === 'public') dispatch(logOutUser())
  }, [dispatch, isAuthenticated, publicity])

  if (oidcLoading) {
    return <></>
  }

  return (
    <>
      <RegularLoader open={isLoading ? isLoading : false} />
      <Box key={'root-container'}>
        <Router key={'root-router-block'} basename={process.env.PUBLIC_URL}>
          <ScrollToTop />
          <BackgroundHook key={'root-router-bg-hook'}>
            <Routes key={'root-router-component'} />
            <ModalHandler />
          </BackgroundHook>
        </Router>
      </Box>
      <>
        <SnackbarComponent />
        {/*<BottomNavigation*/}
        {/*  showLabels*/}
        {/*>*/}
        {/*  <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />*/}
        {/*  <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />*/}
        {/*  <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />*/}
        {/*</BottomNavigation>*/}
      </>
    </>
  )
}

export default App
