import React, { memo } from 'react'
import { Box, Typography, Button, Paper, Container } from '@material-ui/core'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  paper: { borderRadius: '12px' },
  paperMobile: {
    height: '100vh',
    width: '100%',
  },
  btn: {
    fontWeight: 600,
    padding: theme.spacing(1, 4),
    letterSpacing: 1.1,
    backgroundColor: theme.palette.primary.medium,
    color: theme.palette.primary.dark,
  },
  sadIcon: {
    height: '80px',
    width: '80px',
    margin: theme.spacing(4, 0),
  },

  noTrips: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(4),
    width: '300px',
    margin: '0 auto 24px',
    lineHeight: '1',
  },

  noTripsMobile: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(4),
    lineHeight: '1',
  },

  noTripsDesc: {
    width: '380px',
    margin: '0 auto',
  },
}))

const NoTripsFound = ({
  isHotel = false,
  isSingleHotel = false
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const startPage = () => history.goBack()

  let description = t('not find trips desc')
  let title = t('not find trips')
  let button = t('go home page')
  if (isSingleHotel) {
    title = t('not find hotel rooms')
    description = t('not find hotel rooms desc')
    button = t('detail btn')
  } else if (isHotel) {
    title = t('not find hotels')
    description = t('not find hotels desc')
  }

  return (
    <Box className={classes.root} mt={isMobile ? 0 : 5}>
      <Container maxWidth="sm" disableGutters>
        <Paper className={isMobile ? classes.paperMobile : classes.paper}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
          >
            <SentimentVeryDissatisfiedIcon
              className={classes.sadIcon}
              color="primary"
            />

            <Container fixed>
              <Typography
                gutterBottom
                align="center"
                className={!isMobile ? classes.noTrips : classes.noTripsMobile}
              >
                {title}
              </Typography>
            </Container>

            <Typography
              gutterBottom
              align="center"
              className={!isMobile ? classes.noTripsDesc : ''}
            >
              {description}
            </Typography>

            <Box mt={4} mb={4}>
              <Button onClick={startPage} className={classes.btn}>
                {button}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  )
}

export default memo(NoTripsFound)
