import agent, { getApiUrl, getRqHeaders } from './agent'
import ndjsonStream from 'can-ndjson-stream'
import constants from '../store/hotels/constants'
import storage from 'local-storage-fallback'
import i18n from '../utils/i18n'
import { getVirtualCustomerConfig } from '../utils/virtualCustomerConfigs'

/**
 * fetch controllers list for locally executed request
 *
 * @type {*[]}
 */
const fetchControllers = []
const handleHotelsChunk = (chunk, dispatch, params) => {
  let hotelsCount = 0

  const { priorityHotels } = getVirtualCustomerConfig()

  Object.entries(chunk).forEach((entry) => {
    const [key, item] = entry
    switch (key) {
      case 'returnedData':
        if (item?.hotels.length > 0) {
          // Apply client-side priority to hotels for virtual customers
          if (priorityHotels) {
            item.hotels = item.hotels.map((hotel) => ({
              ...hotel,
              priority: priorityHotels[hotel.hotelCode] || null,
            }))
          }

          dispatch({ type: constants.SET_SEARCHED_HOTELS, payload: item })
          hotelsCount = item?.hotels.length
          dispatch({
            type: constants.SET_HOTEL_LOADING_RESULTS,
            payload: false,
          })
        }
        break
      case 'singleHotel':
        if (Object.values(item).length > 0) {
          if (params) item.params = params
          dispatch({ type: constants.SET_SINGLE_HOTEL, payload: item })
          hotelsCount = Object.values(item).length
        }
    }
  })

  return hotelsCount
}

/**
 * cancel locally (nut using agent) executed requests
 *
 * @param key
 */
export const cancelFetch = (key) => {
  if (!!key) {
    if (!!fetchControllers[key]) {
      try {
        fetchControllers[key].abort()
      } catch (e) {}
      fetchControllers[key] = null
    }
  }
}

export const fetchHotelSearch = async (payload, dispatch, params = null) => {
  const jwtToken = storage.getItem('jwtToken')
  const headers = await getRqHeaders()
  const fetchKey = params?.fetchKey || null
  let controller = null

  if (!!fetchKey) {
    cancelFetch(fetchKey)
    controller = new AbortController()
    fetchControllers[fetchKey] = controller
  }

  headers['content-type'] = 'application/json'
  const url = getApiUrl(`/v2/hotel/search?lng=${i18n.language}`)
  let hotelsCount = 0
  const extSettings = {}
  if (!!controller) {
    extSettings.signal = controller.signal
  }

  let cancelledErr = null
  try {
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers,
      ...extSettings,
    })
      .then((response) => ndjsonStream(response.body))
      .then(async (stream) => {
        const reader = stream.getReader()

        while (true) {
          const { value, done } = await reader.read()
          if (done || !value) break

          hotelsCount += handleHotelsChunk(value, dispatch, params)
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') cancelledErr = error;
        hotelsCount = 0
      })
  } catch (error) {
    console.log(error)
  }

  if (!!cancelledErr) {
    throw cancelledErr
  }

  return hotelsCount
}

export const fetchHotelCities = async (string) => {
  if (!string) string = ''
  const url = `/v2/hotel/city/${string.trim()}`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}

export const fetchCityCoordinates = async (cityId) => {
  if (!cityId) return
  const url = `/v2/hotel/city/details/${cityId}`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchCityBasedOnCoordinates = async ({ lat, lng, lang }) => {
  if (!lat || !lng) return
  const url = `/v2/hotel/by-coordinates/city?lat=${lat}&lng=${lng}&lang=${lang}`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchHotelRatings = async ({
  latitude,
  longitude,
  countryCode,
}) => {
  if (!latitude || !longitude) return

  const url =
    countryCode == 'SE'
      ? `/v2/hotel/ratings?lat=${latitude}&lng=${longitude}`
      : `/city/reviews/${latitude},${longitude}`
  try {
    const { data } = await agent.get(url, { requestId: 'hotel_reviews' })
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchRoomGuarantee = async (payload) => {
  const url = '/room-guarantee'
  try {
    const { data } = await agent.post(url, payload)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchHotelDescriptiveContents = async (hotelCode) => {
  if (!hotelCode) return
  const url = `/v2/hotel/${hotelCode}/descriptions`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}
