import React, { useContext } from 'react'
import GoogleMap from '../../../components/hotels/hotelBackgroundMap'
import HotelSearchResults from './hotelSearchResults'
import { DrawerContext } from './hotelSearchContent'

const DesktopView = ({lat, lng, isMiddleStep, isMobile, toggleMap, showMap, mapFieldPosition}) => {
  const { filteredSearch } = useContext(DrawerContext)
  return (
    <>
      {
        showMap && <GoogleMap initialLat={lat} initialLng={lng} hotels={filteredSearch} isMiddleStep={isMiddleStep} mapFieldPosition={mapFieldPosition}/>
      }

      <HotelSearchResults isMobile={isMobile} isMiddleStep={isMiddleStep} search={filteredSearch} toggleMap={toggleMap} showMap={showMap}/>
    </>
  )
}

export default DesktopView
