export default [
  'ARN',
  'BMA',
  'NYO',
  'LPI',
  'NRK',
  'JKG',
  'VXO',
  'KLR',
  'VBY',
  'RNB',
  'AGH',
  'KID',
  'MMX',
  'HAD',
  'GOT',
  'THN',
  'HFS',
  'KSD',
  'TYF',
  'ORB',
  'VST',
  'BLE',
  'MXX',
  'KRF',
  'OER',
  'SDL',
  'EVG',
  'OSD',
  'HMV',
  'LYC',
  'SFT',
  'UME',
  'VHM',
  'AJR',
  'GEV',
  'KEM',
  'KRN',
  'LLA',
  'HEL',
  'TKU',
  'TLL',
  'RIX',
  'VNO',
  'GDN',
  'KTW',
  'DUS',
  'FRA',
  'HAJ',
  'HAM',
  'MUC',
  'AMS',
  'LUX',
  'BRU',
  'LGW',
  'LHR',
  'MAN',
  'EDI',
  'DUB',
  'BCN',
  'MAD',
  'CDG',
  'LYS',
  'NCE',
  'ORY',
  'GVA',
  'ZRH',
  'LIN',
  'MXP',
  'IST',
  'DXB',
  'BKK',
  'HKG',
  'EWR',
  'JFK',
  'ORD',
  'OSL',
  'TRD',
  'BGO',
  'AAR',
  'BLL',
  'CPH',
]
