import airportTimezone from '../../../constants/airport-timezones.json'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

export default function getDuration(
  from_iata,
  to_iata,
  from_time,
  to_time,
  checkIata = true
) {
  try {
    var from_airport = airportTimezone.filter(function (airport) {
      return airport.code === from_iata
    })[0]

    var to_airport = airportTimezone.filter(function (airport) {
      return airport.code === to_iata
    })[0]

    if (checkIata && (!from_airport || !to_airport)) return -1

    var from = checkIata
      ? moment.tz(from_time, from_airport.timezone)
      : moment(from_time)
    var to = checkIata
      ? moment.tz(to_time, to_airport.timezone)
      : moment(to_time)
    return moment.duration(to.diff(from)).asMinutes()
  } catch (e) {
    console.log(e)
  }

  return null
}
