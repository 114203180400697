import React, { memo } from 'react'
import { Box, Typography } from '@material-ui/core'
import Moment from 'react-moment'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { getFlightbusLogoFile } from '../../../utils/taxiHelpers'

const useStyles = makeStyles((theme) => ({
  align: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
  },
  serviceName: {
    marginLeft: '20px',
    fontSize: '16px',
    color: theme.palette.common.black,
    letterSpacing: 'normal',
  },
  logo: {
    height: '12px',
    width: 'auto',
  },
  icon: {
    objectFit: 'contain',
    width: '80px',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  chooseBtn: {
    borderRadius: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    width: '30px',
    height: '30px',
  },
}))

const FlightBusCheckoutCard = ({ transfer }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { startNode, endNode, product } = transfer
  const buildAddress = (node) => {
    if (!node?.location) return ''

    if (node.location.type === 'LOCATION') {
      return !!node.name
        ? `${node.name || ''} ${node.number || ''} (${node.location.community})`
        : (node.location.name || '').replace(/^flygbuss\s/i, '')
    } else {
      return (node.location.name || '').replace(/^flygbuss\s/i, '')
    }
  }

  const correctTransferDate = (date) => {
    if (typeof date === 'string' && date.includes('+'))
      return date.split('+')?.[0] || date
    return date
  }

  const logo = (
    <img
      className={classes.logo}
      src={getFlightbusLogoFile(
        transfer.product.code,
        transfer.startNode.street?.location?.name,
        transfer.endNode.street?.location?.name
      )}
      alt={product || ''}
    />
  )

  return (
    <Box>
      <Typography gutterBottom>
        <Moment format="ddd D MMM">
          {correctTransferDate(startNode?.time)}
        </Moment>
        <Moment
          format="HH:mm"
          style={{ marginLeft: '4px', fontWeight: 'bold' }}
        >
          {correctTransferDate(startNode?.time)}
        </Moment>{' '}
        - {t('valid any departure')}
      </Typography>
      <Box className={classes.align}>
        <Typography className={classes.ellipsis}>
          {buildAddress(startNode?.street)}
        </Typography>
        <ArrowForwardIcon />
        <Typography className={classes.ellipsis}>
          {buildAddress(endNode?.street)}
        </Typography>
      </Box>
      <Box mt={'16px'} className={classes.align}>
        {logo}
        <span className={classes.serviceName}>{product?.name}</span>
      </Box>
    </Box>
  )
}

export default memo(FlightBusCheckoutCard)
