import agent from './agent'
import CONSTANTS from "../store/flightDetails/constants";
import {parseSeatsRes} from "../store/flightDetails/helpers/parseSeats";
import {setPassengerSeatings} from "../store/flightDetails/flightDetailsActions";

// **POST** fetches flights
export const fetchFlights = async (payload) => {
  try {
    const { data } = await agent.post('/v2/flights', payload)
    return data
  } catch (error) {
    throw error
  }
}

export const fetchCityByIata = async (text, lang) => {
  if (!text) return
  const url = `/v2/flight/city-by-iata/${text}?lang=${lang}`

  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}

export const fetchFlightAirport = async (text, lang, isTo = false) => {
  if (!text) {
    text = ''
  }
  const dest = encodeURIComponent(text.trim())
  const url = `/v2/flight/city/${dest}?lang=${lang}&isTo=${isTo ? 1 : 0}`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}

// ** POST ** Fetches StandaloneCatalogue
export const fetchPostStandaloneCatalogue = async (payload, ctKey = null) => {
  try {
    const url = `/v2/flights/get-standalone-catalogue`
    const { data } = await agent.post(url, payload,{
      cityCityCT: ctKey,
    })
    return data
  } catch (error) {
    throw error
  }
}

/* **POST** fetches family pricing */
export const fetchPostFamiliesPricing = async (payload, ctKey = null) => {
  try {
    const url = '/v2/flights/get-families-pricing'
    const { data } = await agent.post(url, payload, {
      cityCityCT: ctKey,
    })
    return data
  } catch (error) {
    throw error
  }
}

/* **POST**  Get Seats   */
export const fetchPostGetSeats = async (payload, ctKey = null) => {
  const url = `/v2/flights/get-seats`
  try {
    const { data } = await agent.post(url, payload, {
      cityCityCT: ctKey,
    })
    return data
  } catch (error) {
    throw error
  }
}

/**
 * get flight trip seats
 * @param outgoing
 * @param returning
 * @param isMultiTicket
 * @param flightDetails
 * @returns {Promise<*[]>}
 */
export const getFlightTripSeats = async (outgoing, returning, isMultiTicket, flightDetails) => {
  const rqCTKType = '::flightDetails::'
  const outgoingTrip = outgoing || []
  const returningTrip = returning || []
  const mashTogether = [...outgoingTrip, ...returningTrip]
  // Mash Together the lists
  const { returnFare, outboundFare } = flightDetails || {}
  let fareByFlights = outboundFare?.byFlights || {}

  if (isMultiTicket && returnFare?.byFlights) {
    fareByFlights = { ...fareByFlights, ...returnFare.byFlights }
  }

  // Iterate them
  const fetchedSeats = []
  for (const flight of mashTogether) {
    try {
      const { uniqueInd: flightUId } = flight
      if (fareByFlights[flightUId]) {
        flight.fareBasis = fareByFlights[flightUId]?.ffFareBasis
        flight.bookingclass = fareByFlights[flightUId]?.bookingClass
        flight.cabinCode = fareByFlights[flightUId]?.cabin
      }
      console.log('fetch', flight)
      const seats = await fetchPostGetSeats(
        { flight },
        `${flightUId}-fetchSeats${rqCTKType}`
      )
      const parseddata = parseSeatsRes(seats)

      // Special case for Emirates
      // Disable seating for Economy
      if (flight.airline === 'EK' && ['M'].includes(flight.cabinCode)) {
        // console.log('disable seats')
        parseddata.isFreeExists = false
      }

      const data = {
        ...parseddata,
        flightnumber: flight.flightnumber,
        flightKey: flight.uniqueInd,
        flight: flight,
      }

      fetchedSeats.push({ ...data })
    } catch (e) {
      fetchedSeats.push({
        flightnumber: flight.flightnumber,
        flightKey: flight.uniqueInd,
        flight: flight,
      })
    }
  }

  return fetchedSeats
}

/* **GET**    Get MIDDLESTEP */
export const fetchGetMiddleStepSettings = async () => {
  const url = '/middlestep-settings'
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}
