import React, {useCallback, useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Popper from '@material-ui/core/Popper'
import { useMediaQuery } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {getAgentCompanies} from '../../repositories/data'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'
import {debounce} from 'lodash'

const useStyles = makeStyles((theme) => ({
  companies: {
    borderRadius: 8,
    width: '100%',
    display: 'inline-block',
    backgroundColor: '#ffffff',

    [theme.breakpoints.up('md')]: {
      marginBottom: (props) => (props.noLabel ? 0 : 15),
      width: 385,
    },
  },
  icon: {
    color: theme.palette.common.black,
    transform: 'scale(0.8)',
  },
  input: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  companiesInner: {
    borderRadius: 8,
    border: '1px solid #e6e6e6',
  },
  companyText: {
    fontSize: theme.spacing(2),
    fontWeight: 600,
  },
  value: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  showCompanySearchBtn: {
    borderRadius: 0,
    padding: theme.spacing(1, 2),
    '-webkit-tap-highlight-color': 'transparent !important',
    '& div, span, p': {
      '-webkit-tap-highlight-color': 'transparent !important',
    },
  },
  showCompanySearchLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textTransform: 'none',
  },
  placeholder: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightSemiBold,
    opacity: 0.5,
  },
  startAdornment: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightSemiBold,
    letterSpacing: '0.7px',
    marginBottom: 5,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: ({noLabel}) => !noLabel ? theme.spacing(1, 2) : 0,
  },
  searchBox: {
    backgroundColor: '#ffffff',

    '& .MuiOutlinedInput-root': {
      paddingLeft: theme.spacing(0.25),
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 8,
      fontWeight: theme.typography.fontWeightSemiBold,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
      },
    },
  },
  innerInput: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: 0,
  },
}))


const CompaniesSearch = ({
  value,
  setValue,
  noLabel,
  withUserModal,
  userShadow = true,
  isMinimized,
}) => {
  const { t } = useTranslation()
  const { user: authUser } = useSelector((state) => state.auth)
  const classes = useStyles({ noLabel })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [showCompanySearch, setShowCompanySearch] = useState(false)
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [searchText, setSearchText] = useState('')
  const [openOnFocus, setOpenOnFocus] = useState(true)
  const divTravelerRef = useRef()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const getCompanies = useCallback(async (name) => {
    const data = await getAgentCompanies(name)

    setCompanies( data?.length > 0 ? data : [])
    setLoading(false)
  }, [])

  useEffect(() => {
    setLoading(true)
    const timerId = setTimeout(() => {
      getCompanies(searchText)
    }, 1000)

    return () => {
      clearTimeout(timerId);
    };
  }, [searchText, getCompanies])

  const renderOption = (option, { inputValue }) => {
    const nameMatches = match(option?.name || '', inputValue || "", {insideWords: true})
    const nameParts = parse(option?.name || '', nameMatches)

    return (
      <Box display="flex" alignItems="center">
        <Box>
          {nameParts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </Box>
      </Box>
    )
  }

  const renderCmpButton = () => {
    return (
      <Button
        style={{ backgroundColor: 'transparent', padding: isMobile && 0 }}
        fullWidth
        disableRipple
        onClick={() => setShowCompanySearch(!showCompanySearch)}
        classes={{
          root: classes.showCompanySearchBtn,
          label: classes.showCompanySearchLabel,
        }}
      >
        {noLabel ? (
          ''
        ) : (
          <Typography
            variant="body1"
            classes={{ root: classes.startAdornment }}
          >
            {t('company')}
          </Typography>
        )}
        <Box
          display="flex"
          alignItems="flex-end"
          pb="1px"
          style={{ minHeight: isMinimized ? '0' : '32px' }}
        >
          <AddCircleIcon color="primary" />
          <Box
            ml={1}
            display="flex"
            alignItems="center"
            className={classes.companyText}
          >
            {!!value?.uniqueId ? (
              <Typography className={classes.value}>
                {value.name}
              </Typography>
            ) : (
              <Typography className={classes.placeholder}>
                {t('select company')}
              </Typography>
            )}
          </Box>

        </Box>
      </Button>
    )
  }

  const renderGeneral = () => {
    return (
      <Box className={noLabel ? '' : classes.companiesInner}>
        {!showCompanySearch && renderCmpButton()}
        {showCompanySearch && (
          <Box>
            <Autocomplete
              loading={loading}
              multiple
              fullWidth
              filterSelectedOptions
              disableClearable
              openOnFocus={openOnFocus}
              popupIcon={null}
              onBlur={() => {
                setShowCompanySearch(!showCompanySearch)
                setOpenOnFocus(true)
              }}
              options={companies}
              noOptionsText={''}
              getOptionLabel={(option) => option?.name}
              getOptionDisabled={(option, value) => option.uniqueId === value?.uniqueId }
              value={!!value ? [value] : []}
              inputValue={searchText}
              onInputChange={(e,v) => {
                if (!!e) setSearchText(v)
              }}
              PopperComponent={(props) => (
                <Popper
                  {...{
                    ...props,
                    className:
                      props.className,
                  }}
                  placement="bottom"
                  modifiers={{
                    offset: {
                      enabled: true,
                      offset: '-12,6',
                    },
                  }}
                  style={{ width: '384px' }}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  return (
                    <Chip
                      label={`${option?.name}`}
                      variant="outlined"
                      deleteIcon={<CloseIcon className={classes.icon} />}
                      {...getTagProps({ index })}
                    />
                  )
                })
              }
              onChange={(e, value) => {
                const newVal = (typeof(value) === 'object' && !!value?.length) ? value[value.length -1] : null
                handleChange(e, newVal)
              }}
              renderOption={renderOption}
              renderInput={(params) => {
                return (
                  <Box className={classes.searchBar}>
                    {!noLabel &&
                      <Typography
                        variant="body1"
                        classes={{ root: classes.startAdornment }}
                      >
                        {t('company')}
                      </Typography>
                    }
                    <Box
                      className={classes.innerInput}
                      display="flex"
                      mb={isMinimized ? 0 : '-6px'}
                    >
                      <AddCircleIcon color="primary" />
                      <TextField
                        {...params}
                        autoFocus
                        classes={{ root: classes.searchBox }}
                        className={classes.input}
                        value={searchText || ""}
                        onChange={(e) => setSearchText(e?.target?.value)}
                        variant="outlined"
                        placeholder={
                          !!value?.uniqueId ? null : t('select company')
                        }
                      />
                    </Box>
                  </Box>
                )
              }}
            />
          </Box>
        )}
      </Box>
    )
  }
  return (
    <>
      <Box
        ref={divTravelerRef}
        p={isMinimized ? 0 : 1}
        className={classes.companies}
        style={{
          boxShadow: userShadow
            ? '0px 20px 15px -20px rgba(0, 0, 0, .15)'
            : 'none',
          padding: isMobile && 0,
        }}
      >
        {renderGeneral()}
      </Box>
    </>
  )
}

export default CompaniesSearch
