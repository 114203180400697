import agent from './agent'
import axios from "axios";

// get ** Fetches all Users
export const fetchAllUsers = async (rqData, ctKey = null) => {
  try {
    const { name = null, limit = null } = rqData || {}
    const config = { cityCityCT: ctKey, params: {} }
    if (!!name && typeof (name) === 'string') config.params.name = name
    if (!!limit) config.params.limit = limit
    const { data } = await agent.get('/v2/users', config)
    return data
  } catch (error) {
    throw error
  }
}
// get ** Fetches all Users
export const fetchApprovers = async (rqData, ctKey = null) => {
  try {
    const { name = null, limit = 30, group = null } = rqData || {}
    const config = { cityCityCT: ctKey, params: {} }
    if (!!limit) config.params.limit = limit
    const { data } = await agent.post('/v2/approvers', { name, limit, group }, config)
    return data
  } catch (e) {
    if (!!ctKey && axios.isCancel(e)) throw e
    return []
  }
}

export const fetchUserGroups = async () => {
  try {
    const { data } = await agent.get('/v2/user-groups')
    return data
  } catch (e) {
    return []
  }
}
// get ** Fetches users for agent by name
export const fetchUsersForAgent = async (agentCompany = null, name) => {
  try {
    const { data } = await agent.post('/v2/agent/users', { agentCompany, name })
    return data
  } catch (error) {
    throw error
  }
}

// get users phones from Amadeus profiles
export const getUsersPhones = async (passengers) => {
  const url = `/v2/data/users-phone`
  try {
    const { data } = await agent.post(url, passengers)
    return data
  } catch (error) {
    console.log(error)
  }
}
