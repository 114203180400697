import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 58px 0px 58px',
    minHeight: '430px',
  },

  title: {
    fontSize: '40px',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    marginBottom: '40px',
  },

  companyNotice: {
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#f7f7f7',
    textAlign: 'center',
    marginBottom: '16px',
  },

  companyButton: {
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: '28px',
    border: 'unset',
    backgroundColor: '#0dc5ba',
    marginTop: '16px',
    padding: '12px',
    letterSpacing: '0.7px',
  },
}))

function CompanySelection({ isLoading, companies, onSelected }) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  if (isLoading)
    return (
      <Box
        className={classes.root}
        pl={isMobile ? 0 : 2}
        pr={isMobile ? 0 : 2}
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          alignItems: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={30} color={'primary'} />
      </Box>
    )

  return (
    <Box className={classes.root} pl={isMobile ? 0 : 2} pr={isMobile ? 0 : 2}>
      <Typography className={classes.title}>{t('choose company')}</Typography>
      <Typography className={classes.companyNotice}>
        {t('choose company text')}
      </Typography>
      <Box mt={1}>
        {companies.map((company) => (
          <Button
            disableElevation
            className={classes.companyButton}
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => onSelected(company.uniqueId)}
          >
            {company.name}
          </Button>
        ))}
      </Box>
    </Box>
  )
}

export default CompanySelection
