import React, { useMemo, Fragment, useRef } from 'react'
import { Formik, Field, useFormikContext } from 'formik'
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  useTheme,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Paper,
  Hidden,
  Container,
  Select,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import ChoosenJourney from '../../containers/flights/details/choosenJourney'
import ChoosenTrainJourney from '../../containers/trains/trainContainers/selectedTrains/choosenJourney'
import {
  getPhoneNumberFormat,
  getPublicUsersFormikInitials,
  getPublicUsersValidationSchema,
} from '../../utils/general'
import { openLoginModal } from '../../store/modals/modalAction'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import TotalPriceDetails from '../../containers/flights/details/totalPriceDetails'
import isIframed from '../../utils/isIframed'
import useFormsHook from '../../hooks/useFormsHook'
import moment from 'moment'

export const useStyles = makeStyles((theme) => ({
  blockOuter: {
    marginTop: '62px',
    '&:first-child': {
      marginTop: 0,
    },
  },
  loginButton: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightSemiBold,
    lineHeight: 'normal',
    paddingTop: '9px',
    paddingBottom: '9px',
    textTransform: 'none',
    borderRadius: '4px',
  },
  input: {
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: theme.spacing(0.75),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(0.75),
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
  inputHeading: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 'normal',
    paddingBottom: '11px',
    color: theme.palette.common.black,
  },
  blockHeader: {
    fontSize: '24px',
    paddingBottom: '28px',
    marginBottom: '28px',
    borderBottom: 'solid 1px #e6e6e6',
    lineHeight: 'normal',
  },
  wrapper: {
    marginBottom: '24px',
  },
  borderRadius: {
    borderRadius: '12px',
  },
  fixedDiv: {
    position: 'sticky',
    top: ({ stickyTop }) => !isIframed && stickyTop,
  },
  link: {
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    textTransform: 'none',
    left: 0,
    position: 'absolute',
  },
  continueBtn: {
    fontSize: '18px',
    lineHeight: 'normal',
    padding: '23px 60px',
    letterSpacing: '1.2px',
    borderRadius: 50,
    fontWeight: 700,
  },
  btnContainer: {
    marginBottom: theme.spacing(6),
    marginTop: 0,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
  },
  middleWrapper: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    marginBottom: '24px',
  },
  wrapperRight: {
    paddingRight: theme.spacing(1.5),
    marginBottom: '24px',
  },
  wrapperLeft: {
    paddingLeft: theme.spacing(1.5),
    marginBottom: '24px',
  },
  contactUsBlock: {
    backgroundColor: '#eef8f8',
    borderRadius: 4,
    padding: '25px 20px',
    width: '100%',
  },
  mainText: {
    fontSize: '16px',
    color: theme.palette.common.black,
  },
}))

/**
 * component for adding Public users info
 *
 * @param users
 * @param onSubmit
 * @param stickyTop
 * @param type
 * @returns {JSX.Element}
 */
const AddPublicUsersInfo = ({
  users,
  onSubmit,
  stickyTop = '0',
  type = 'flights',
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const {
    isAuthenticated,
    publicity,
    countryPhone,
    showAddressDetails,
    showBirthDate,
  } = useSelector((state) => state.auth)
  const { countriesList } = useSelector((state) => state.other)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile, stickyTop })
  const formRef = useRef(null)
  const history = useHistory()
  const { t } = useTranslation()
  let RightMenu = null
  let TotalPriceBlock = null
  const phoneNumberFormat = getPhoneNumberFormat(countryPhone?.code)

  // select trip info component (for right menu)
  switch (type) {
    case 'flights':
      RightMenu = ChoosenJourney
      break
    case 'trains':
      RightMenu = ChoosenTrainJourney
      break
    case 'hotel':
    default:
      RightMenu = Box
      break
  }

  // select trip total component (for right menu)
  switch (type) {
    case 'flights':
      TotalPriceBlock = TotalPriceDetails
      break
    case 'trains':
    case 'hotel':
    default:
      TotalPriceBlock = Box
      break
  }

  const initials = useMemo(() => getPublicUsersFormikInitials(users), [users])
  initials.addressDetails.showAddressDetails = showAddressDetails
  const validateBirthDate =
    !isAuthenticated && showBirthDate && type === 'flights'
  const validationSchema = useMemo(
    () =>
      !!users
        ? Yup.object().shape(
            getPublicUsersValidationSchema(
              countryPhone?.code,
              validateBirthDate
            )
          )
        : null,
    [users]
  )
  const formikContext = useFormikContext() // get formik from parent context
  const onlyForm = !!formikContext // return only form if parent formik context

  const saveChanges = (values) => {
    if (values?.users?.length) {
      const valuesCopy = { ...values }
      valuesCopy.users = valuesCopy?.users?.map(
        ({ firstName, lastName, birthDate, ...rest }) => {
          const tmpDate = new moment(
            `${birthDate.y}-${birthDate.m}-${birthDate.d}`,
            'YYYY-MM-DD'
          )
          const changedBirthDate = tmpDate.isValid()
            ? tmpDate.format('YYYY-MM-DD')
            : ''
          return {
            ...rest,
            firstName: firstName.replace("'", ' '),
            lastName: lastName.replace("'", ' '),
            birthDate: changedBirthDate,
          }
        }
      )
      onSubmit(valuesCopy)
    }
  }

  const submit = () => {
    if (formRef?.current?.handleSubmit) formRef.current.handleSubmit()
  }

  const goBack = () => history.goBack()

  const buildGender = (formik, index) => {
    const name = `users.${index}.sex`
    return (
      <FormControl component="fieldset">
        <Typography className={classes.inputHeading}>{t('sex')}</Typography>
        <Field name={name}>
          {({ field }) => {
            return (
              <RadioGroup
                {...field}
                row
                name={name}
                value={formik?.values?.users?.[index]?.sex || ''}
              >
                <FormControlLabel
                  key={'sex-male'}
                  value="male"
                  control={<Radio color="primary" />}
                  label={t('male')}
                />
                <FormControlLabel
                  key={'sex-female'}
                  value="female"
                  control={<Radio color="primary" />}
                  label={t('female')}
                />
              </RadioGroup>
            )
          }}
        </Field>
      </FormControl>
    )
  }

  const openLogin = () => {
    dispatch(openLoginModal())
  }

  const buildTextField = (
    formik,
    name,
    index,
    title,
    placeholder = null,
    isFromAddress = false
  ) => {
    const Error =
      index !== false
        ? formik.touched?.users?.[index]?.[name] &&
          formik.errors?.users?.[index]?.[name]
        : isFromAddress
        ? formik.touched?.addressDetails?.[name] &&
          formik.errors?.addressDetails?.[name]
        : formik.touched?.[name] && formik.errors?.[name]

    const tmpName =
      index !== false
        ? `users.${index}.${name}`
        : isFromAddress
        ? `addressDetails.${name}`
        : name
    const tmpVal =
      index !== false
        ? formik?.values?.users?.[index]?.[name]
        : isFromAddress
        ? formik?.values?.addressDetails?.[name]
        : formik?.values?.[name]
    let inputType = 'text'
    if (name === 'email') {
      inputType = 'email'
    }

    const phoneInputHandler = (event) => {
      if (!/[\+0-9]/.test(event.key)) {
        event.preventDefault()
      }
    }

    const phonePasteHandler = (event) => {
      let text = event.clipboardData.getData('Text')

      text = text.replace(/[^\+0-9]/gm, '')
      formik.setFieldValue(name, text, false)
      event.preventDefault()
    }

    const onPasteHandler = name == 'phone' ? phonePasteHandler : null

    const onKeyPressHandler = name == 'phone' ? phoneInputHandler : null

    const onFocusHandler = (evt) => {
      if (
        evt.target.name === 'email' &&
        formik.values.email === '' &&
        formik.values.users[0]?.email
      ) {
        formik.setFieldValue('email', formik.values.users[0]?.email, false)
      }
    }
    const notRequiredTypes = ['middleName']

    return (
      <Box>
        <Typography className={classes.inputHeading}>
          {title}
          {!notRequiredTypes.includes(name) && ' *'}
        </Typography>
        <Box>
          <TextField
            name={tmpName}
            autoComplete={'off'}
            inputProps={{ autoCorrect: 'off', spellCheck: 'false' }}
            type={inputType}
            fullWidth
            error={!!Error}
            helperText={Error}
            InputLabelProps={{ shrink: false }}
            value={tmpVal}
            variant="outlined"
            className={classes.input}
            onKeyPress={onKeyPressHandler}
            InputProps={{ style: { fontWeight: 500 } }}
            onChange={formik.handleChange}
            style={{ borderRadius: theme.spacing(0.75) }}
            onFocus={onFocusHandler}
            placeholder={placeholder}
            onPaste={onPasteHandler}
          />
        </Box>
      </Box>
    )
  }

  const buildCountryField = (
    formik,
    name,
    title,
    fromAddress = false,
    index = false
  ) => {
    const tmpName =
      index !== false
        ? `users.${index}.${name}`
        : fromAddress
        ? `addressDetails.${name}`
        : name
    const tmpVal =
      index !== false
        ? formik?.values?.users?.[index]?.[name]
        : fromAddress
        ? formik?.values?.addressDetails?.[name]
        : formik?.values?.[name]
    const error =
      index !== false
        ? formik?.touched?.users?.[index]?.[name] &&
          formik?.errors.users?.[index]?.[name]
        : fromAddress
        ? formik?.touched?.addressDetails?.[name] &&
          formik?.errors?.addressDetails?.[name]
        : formik?.touched[name] && formik?.errors[name]
    const opts = countriesList.map((c) => {
      return (
        <option value={name === 'nationality' ? c.alpha3Code : c.code}>
          {c.name}
        </option>
      )
    })
    return (
      <Box>
        <Typography className={classes.inputHeading}>{title} *</Typography>
        <Box>
          <Select
            native
            name={tmpName}
            value={tmpVal}
            variant="outlined"
            className={classes.input}
            InputLabelProps={{ shrink: false }}
            onChange={formik.handleChange}
            style={{ borderRadius: theme.spacing(0.75) }}
            error={!!error}
          >
            <option value={''}> </option>
            {opts}
          </Select>
          {error && <span className={classes.errorMessage}>{error}</span>}
        </Box>
      </Box>
    )
  }

  const buildForm = (formik) => {
    return (
      <Box>
        {formik.values.users.map((user, index) => {
          const { buildBirthDateField } = useFormsHook()
          return (
            <Box key={`user-data-${index}`} className={classes.blockOuter}>
              <Box className={classes.blockHeader}>
                <Grid container>
                  <Grid
                    item
                    xs
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span>
                      <span style={{ fontWeight: 'bold' }}>{`${t(
                        'traveller'
                      )} ${index + 1}`}</span>
                      <span style={{ paddingLeft: '10px' }}>
                        {user.age > 18 ? t('adult') : t('child')}
                      </span>
                    </span>
                  </Grid>
                  {/*{index === 0 && !isAuthenticated && publicity !== 'public' && (*/}
                  {/*  <Grid*/}
                  {/*    item*/}
                  {/*    xs={3}*/}
                  {/*    md*/}
                  {/*    style={{*/}
                  {/*      display: 'flex',*/}
                  {/*      alignItems: 'center',*/}
                  {/*      justifyContent: 'flex-end',*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <Button*/}
                  {/*      className={classes.loginButton}*/}
                  {/*      onClick={openLogin}*/}
                  {/*      variant="outlined"*/}
                  {/*      color="primary"*/}
                  {/*    >*/}
                  {/*      {t(isMobile ? 'login' : 'login public user')}*/}
                  {/*    </Button>*/}
                  {/*  </Grid>*/}
                  {/*)}*/}
                </Grid>
              </Box>
              <Box position={'relative'}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={classes.wrapper}
                    key={'sex-field'}
                  >
                    {buildGender(formik, index, t('email form'))}
                  </Grid>

                  {type === 'flights' && (
                    <Box
                      display={'flex'}
                      mb={'35px'}
                      alignItems={'center'}
                      className={`${classes.contactUsBlock}`}
                    >
                      <span className={`${classes.mainText}`}>
                        <strong>{t('please note')}: </strong>
                        {t('public user note')}
                      </span>
                    </Box>
                  )}

                  <Grid
                    item
                    xs={isMobile ? 12 : type === 'flights' ? 4 : 6}
                    className={
                      isMobile ? classes.wrapper : classes.wrapperRight
                    }
                    key={'first-name-field'}
                  >
                    <Box>
                      {buildTextField(
                        formik,
                        'firstName',
                        index,
                        t('profile name')
                      )}
                    </Box>
                  </Grid>

                  {type === 'flights' && (
                    <Grid
                      item
                      xs={isMobile ? 12 : 4}
                      className={
                        isMobile ? classes.wrapper : classes.middleWrapper
                      }
                      key={'first-name-field'}
                    >
                      <Box>
                        {buildTextField(
                          formik,
                          'middleName',
                          index,
                          t('middle name')
                        )}
                      </Box>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={isMobile ? 12 : type === 'flights' ? 4 : 6}
                    className={isMobile ? classes.wrapper : classes.wrapperLeft}
                    key={'last-name-field'}
                  >
                    <Box pl={isMobile ? 0 : '4px'}>
                      {buildTextField(
                        formik,
                        'lastName',
                        index,
                        t('last name form')
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid
                    item
                    xs={
                      !isAuthenticated && showBirthDate && type === 'flights'
                        ? 6
                        : 12
                    }
                    className={
                      type === 'flights'
                        ? classes.wrapperRight
                        : classes.wrapper
                    }
                    key={'traveller-email-field'}
                  >
                    <Box pl={isMobile ? 0 : '4px'}>
                      {buildTextField(formik, 'email', index, t('email form'))}
                    </Box>
                  </Grid>
                  {!isAuthenticated && showBirthDate && type === 'flights' && (
                    <Grid
                      item
                      xs={6}
                      className={
                        !isAuthenticated && showBirthDate
                          ? classes.wrapperLeft
                          : classes.wrapper
                      }
                      key={'nationality'}
                    >
                      {buildCountryField(
                        formik,
                        'nationality',
                        t('nationality'),
                        false,
                        index
                      )}
                    </Grid>
                  )}
                </Grid>

                {!isAuthenticated && showBirthDate && type === 'flights' && (
                  <Grid
                    item
                    xs={12}
                    className={classes.wrapper}
                    key={'birthDate'}
                  >
                    {buildBirthDateField(
                      formik,
                      'birthDate',
                      index,
                      t('profile date'),
                      'users'
                    )}
                  </Grid>
                )}
              </Box>
            </Box>
          )
        })}
        <Box key={'user-data-contacts'} className={classes.blockOuter}>
          <Box className={classes.blockHeader}>
            <span style={{ fontWeight: 'bold' }}>{t('contact')}</span>
          </Box>
          <Box position={'relative'}>
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.wrapper}
                key={'email-field'}
              >
                {buildTextField(formik, 'email', false, t('email form'))}
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.wrapper}
                key={'phone-field'}
              >
                {buildTextField(
                  formik,
                  'phone',
                  false,
                  t('contact phone1'),
                  `${phoneNumberFormat}`
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        {!isAuthenticated && showAddressDetails && (
          <Box key={'user-data-address'} className={classes.blockOuter}>
            <Box className={classes.blockHeader}>
              <span style={{ fontWeight: 'bold' }}>{t('address details')}</span>
            </Box>
            <Box position="relative">
              <Grid container>
                <Grid
                  item
                  xs={isMobile ? 12 : 6}
                  className={isMobile ? classes.wrapper : classes.wrapperRight}
                  key={'street-field'}
                >
                  <Box>
                    {buildTextField(
                      formik,
                      'street',
                      false,
                      t('street and street number'),
                      null,
                      true
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 6}
                  className={isMobile ? classes.wrapper : classes.wrapperLeft}
                  key={'city-field'}
                >
                  <Box pl={isMobile ? 0 : '4px'}>
                    {buildTextField(
                      formik,
                      'city',
                      false,
                      t('city'),
                      null,
                      true
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 6}
                  className={isMobile ? classes.wrapper : classes.wrapperRight}
                  key={'country'}
                >
                  <Box>
                    {buildCountryField(
                      formik,
                      'country',
                      t('address land'),
                      true
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 6}
                  className={isMobile ? classes.wrapper : classes.wrapperLeft}
                  key={'post-code-field'}
                >
                  <Box pl={isMobile ? 0 : '4px'}>
                    {buildTextField(
                      formik,
                      'postCode',
                      false,
                      t('post code'),
                      null,
                      true
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    )
  }
  const getFormik = () => {
    return (
      <Formik
        innerRef={formRef}
        enableReinitialize
        onSubmit={saveChanges}
        initialValues={initials}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            {buildForm(formik)}
          </form>
        )}
      </Formik>
    )
  }
  const getFullContent = () => (
    <Container maxWidth="lg" disableGutters>
      <Box mt={isMobile ? 1 : isIframed ? 0 : 3} mb={0}>
        <Container
          container
          disableGutters
          direction={isMobile ? 'column-reverse' : 'row'}
          spacing={isMobile ? 2 : 0}
        >
          <Box width={'100%'} mb={3} mt={1}>
            <Grid
              container
              direction={isMobile ? 'column-reverse' : 'row'}
              spacing={isMobile ? 2 : 6}
            >
              <Grid item xs={12} md={7} sm={12}>
                <Paper elevation={0} className={classes.borderRadius}>
                  <Box px={isMobile ? 2 : 4} pt={'35px'} pb={'70px'}>
                    {!!formikContext ? buildForm(formikContext) : getFormik()}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={5} sm={12}>
                <Box data-some={'aaaa'} className={classes.fixedDiv}>
                  <Paper elevation={0} className={classes.borderRadius}>
                    <RightMenu returning />
                  </Paper>
                  <Paper elevation={0} className={classes.borderRadius}>
                    <Box mt={isMobile ? 2 : 3} height="100%">
                      <TotalPriceBlock />
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Grid container spacing={0}>
        <Grid item md={7} sm={12} xs={12}>
          <Box className={classes.btnContainer} mb={7} mt={0}>
            <Hidden smDown>
              <Button
                onClick={goBack}
                className={classes.link}
                startIcon={<ArrowBackIcon color="primary" />}
              >
                {t('back to select ticket')}
              </Button>
            </Hidden>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              style={{}}
              onClick={submit}
              className={classes.continueBtn}
            >
              {t('detail btn')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )

  const getOnlyForm = () => (
    <Box>{!!formikContext ? buildForm(formikContext) : getFormik()}</Box>
  )

  return <Fragment>{!!onlyForm ? getOnlyForm() : getFullContent()}</Fragment>
}

export default AddPublicUsersInfo
