import React from 'react'
import { Provider, ErrorBoundary } from '@rollbar/react'
import ReactDOM from 'react-dom'
import App from './App'
import Root from './root'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { rollbarConfig } from './config/rollbar'

const render = () =>
  ReactDOM.render(
    <Root>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>
    </Root>,
    document.getElementById('root')
  )

render()

window.ReactDOM = ReactDOM
window.render = render

serviceWorkerRegistration.unregister()
