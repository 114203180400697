import React, {Fragment} from 'react'
import Box from '@material-ui/core/Box'
import StepHeader from './stepHeader'
import SelectCity from './citySelectStep'
import SelectDate from './dateSelectStep'
import SelectRoom from './roomSelectStep'
import SelectTraveller from './travellerSelectStep'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const StepHandler = ({ handleCityChange, filter, filters, goBack, toggleDrawer, handleValueChange, setCity }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fragment>
      <StepHeader
        filter={filter}
        goBack={goBack}
        toggleDrawer={toggleDrawer}
      />
      {
        filter === 'city' && (
          <SelectCity
            handleValueChange={handleCityChange}
            changeCity={setCity}
            goBack={goBack}
          />
        )
      }
      {
        (filter === 'from' || filter === 'to') && (
          <Box pt={isMobile ? 0 : '98px'}>
            <SelectDate
              handleChange={handleValueChange}
              type={filter}
              goBack={goBack}
              isOutgoing={filter === 'from'}
              from={filters.checkIn}
              to={filters.checkOut}
            />
          </Box>
          )
      }
      {
        filter === 'rooms' && (
          <SelectRoom
            initialValue={{roomCount: filters['roomCount'], beds: filters['beds'] || 1}}
            goBack={goBack}
            changeValue={value => handleValueChange(null, value)}
          />
          )
      }
      {
        filter === 'travellers' && (
          <SelectTraveller
            initialValue={filters['guests']}
            goBack={goBack}
            changeValue={(value) => handleValueChange('guests', value)}
          />
          )
      }
    </Fragment>
  )
}

export default StepHandler
