import agent from './agent'
import axios from 'axios'

// **POST** fetch cars
export const fetchCars = async (payload, ctKey = null) => {
  try {
    const { data } = await agent.post('/v2/car/availability', payload, {
      cityCityCT: ctKey,
    })
    if (!data?.rates?.length) throw new Error('empty result')
    return data
  } catch (e) {
    if (!!ctKey && axios.isCancel(e)) throw e
    return null
  }
}

// **POST** fetch car tarif info
export const fetchTarifInfo = async (payload, ctKey = null) => {
  try {
    const { data } = await agent.post('/car/rate-info', payload, {
      cityCityCT: ctKey,
    })
    if (!data) throw new Error('empty result')
    return data
  } catch (e) {
    if (!!ctKey && axios.isCancel(e)) throw e
    return null
  }
}
