import React from 'react'
import { Box, Typography, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { setCostFieldValue } from '../../../store/checkout/checkoutActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const fieldBorderStyles = {
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  border: '1px solid',
}

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  borderRadius: {
    marginTop: 0,
    '&:active': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
    '&:before, & .MuiInputBase-root:before': { display: 'none' },
    '& fieldset': { ...fieldBorderStyles },
    '& .MuiInputBase-input.Mui-disabled': {
      padding: '18.5px 14px',
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      color: '#333',
      borderRadius: '6px',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderColor: '#e6e6e6',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {},
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6',
    },
    '&.MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      ...fieldBorderStyles,
      borderColor: '#e6e6e6',
      backgroundColor: '#e6e6e6',
      borderRadius: '6px',
      color: '#333',
    },
    '&.Mui-disabled .MuiSelect-icon': {
      color: '#333',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  marginBotton: {
    marginBottom: theme.spacing(4),
  },
  medium: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '14px',
    marginBottom: theme.spacing(1),
    marginTop: '16px',
  },
}))

const costCenterLabels = [
  { value: 'costCenter', RM: ['CO-', 'KST-', 'ATCOSTCE='] },
  { value: 'project', RM: ['PRO /', 'PR-', 'PRO-', 'ATPROJ='] },
  { value: 'employeeId', RM: ['EM-', 'ANSTID-', 'ATEMPLID='] },
  { value: 'referens', RM: ['RE-'] },
  { value: 'section', RM: ['ATAVD='] },
  { value: 'numberCase', RM: ['ATARANDE='] },
  { value: 'depCostCenter', RM: ['AVD /'] },
]

const CostCenter = ({ uid, isGuest }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const formik = useFormikContext()
  const delayKeys = {}
  const { user } = useSelector((state) => state.auth)
  let fieldsCount = 0

  const { costfields, costFieldValues = {} } = useSelector(
    (state) => state.checkout
  )

  const uind = (formik?.values?.travellers || []).findIndex(
    (t) => !!t.uniqueId && t.uniqueId === uid
  )

  if (uid < 0) return null
  const costChanged = (e, type) => {
    formik.handleChange(e)
    let newValue = e.target.value || ''

    setStoreValue(type, newValue)
  }

  const setStoreValue = (type, val) => {
    const currVal = costFieldValues?.[type] || ''
    if (val === currVal) return

    if (delayKeys[type]) {
      clearTimeout(delayKeys[type])
      delayKeys[type] = null
    }
    delayKeys[type] = setTimeout(() => {
      dispatch(setCostFieldValue(type, uid, val))
    }, 100)
  }
  const buildTextField = (data) => {
    const { costType: type, editable, required, defaultLabel, labelType } = data

    const lanCode = i18n.language.split('-')[0].toUpperCase()
    const labelObj = costCenterLabels.find((rmObj) => rmObj.RM.includes(type))
    const labelLocal = defaultLabel || labelObj?.value || type
    const label =
      labelType === 'custom'
        ? data?.['label' + lanCode] || t(type)
        : t(labelLocal)
    const val = formik.values?.travellers?.[uind]?.costfields?.[type] || ''
    const touched = formik.touched?.travellers?.[uind]?.costfields?.[type]
    const error = formik.errors?.travellers?.[uind]?.costfields?.[type]
    fieldsCount++

    const isEditable = editable || (isGuest && !user?.isBookingLink)

    return (
      <Box key={'field-outer-' + fieldsCount}>
        <Typography
          key={'field-label-' + fieldsCount}
          className={classes.medium}
        >
          {label}
          {required ? ' *' : ''}
        </Typography>
        <TextField
          disabled={!isEditable}
          key={'field-' + fieldsCount}
          error={!!touched && !!error}
          helperText={touched && error}
          className={classes.borderRadius}
          InputLabelProps={{ shrink: false }}
          name={`travellers.${uind}.costfields.${type}`}
          fullWidth
          value={val}
          onChange={(e) => costChanged(e, type)}
          variant={isEditable ? 'outlined' : 'filled'}
          label={val ? '' : t('payment text')}
        />
      </Box>
    )
  }

  const buildSelectBox = (data, options) => {
    const { costType: type, editable, required, defaultLabel, labelType } = data
    const labelObj = costCenterLabels.find((rmObj) => rmObj.RM.includes(type))

    const lanCode = i18n.language.split('-')[0].toUpperCase()
    const labelLocal = defaultLabel || labelObj?.value || type
    const label =
      labelType === 'custom'
        ? data?.['label' + lanCode] || t(type)
        : t(labelLocal)

    const val = formik.values?.travellers?.[uind]?.costfields?.[type] || ''
    const touched = formik.touched?.travellers?.[uind]?.costfields?.[type]
    const error = formik.errors?.travellers?.[uind]?.costfields?.[type]
    const tmpOpts =
      options.length > 0
        ? [
            ...options.sort((a, b) =>
              a.value.toString().localeCompare(b.value.toString())
            ),
          ]
        : []

    fieldsCount++
    if (val) {
      const currVal = tmpOpts.find((opt) => opt.value === val)
      if (!currVal) tmpOpts.push({ name: val, value: val })
    }

    const isEditable = editable || (isGuest && !user?.isBookingLink)

    return (
      <Box key={'field-outer-' + fieldsCount}>
        <Typography
          key={'field-label-' + fieldsCount}
          className={classes.medium}
        >
          {label}
          {required ? ' *' : ''}
        </Typography>
        <Select
          disabled={!isEditable}
          key={'field-' + fieldsCount}
          displayEmpty
          error={!!touched && !!error}
          helperText={touched && error}
          className={classes.borderRadius}
          InputLabelProps={{ shrink: false }}
          name={`travellers.${uind}.costfields.${type}`}
          variant={isEditable ? 'outlined' : 'filled'}
          placeholder={t('select')}
          fullWidth
          value={val}
          onChange={(e) => costChanged(e, type)}
          IconComponent={ExpandMoreIcon}
        >
          <MenuItem style={{ color: 'silver' }} key={`${type}-empty`} value="">
            <span style={{ color: '#999999', fontWeight: '500' }}>
              {t('select')}...
            </span>
          </MenuItem>
          {tmpOpts?.length > 0 &&
            tmpOpts.map((o, ind) => (
              <MenuItem key={`${type}-${fieldsCount}-${ind}`} value={o.value}>
                {o.value} ({o.name})
              </MenuItem>
            ))}
        </Select>
      </Box>
    )
  }

  const buildFields = () => {
    if (!costfields?.costfields?.length) return null
    return costfields.costfields.map((cf) => {
      return cf.type === 'dropdownList'
        ? buildSelectBox(cf, cf.options)
        : buildTextField(cf)
    })
  }

  return <Box>{buildFields()}</Box>
}

export default CostCenter
