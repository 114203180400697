import React, {memo, useCallback, useMemo} from 'react'
import ModalFlightSeatReservation from '../flights/details/modalContent/modalFlightSeatReservation'
import ModalHook from './reusable/modalHook'
import {useDispatch, useSelector} from "react-redux";
import {setPassengerSeatings} from "../../store/flightDetails/flightDetailsActions";
import {closeAllModals} from "../../store/modals/modalAction";

const SeatReservationModal = ({
  modalBoolean,
  iniSelSeats,
  seatsList,
  passengersList,
  onSelectSeats,
  onClose
}) => {
  const dispatch = useDispatch()
  const InitpassengerSeats = useSelector(
    (state) => state.flightDetails.passengerSeats
  )
  const { passengers } = useSelector((state) => state.flights)
  const { seats } = useSelector((state) => state.flightDetails)
  const { resIniSeats, resPass, resSeats } = useMemo(() => {
      if (!!iniSelSeats || !!seatsList || !!passengersList) {
        return {
          resIniSeats: iniSelSeats,
          resPass: passengersList,
          resSeats: seatsList
        }
      } else {
        return {
          resIniSeats: InitpassengerSeats,
          resPass: passengers,
          resSeats: seats
        }
      }
  }, [InitpassengerSeats, passengers, seats, iniSelSeats, seatsList, passengersList])


  const selectSeats = useCallback((passengerSeats) => {
    if (!!onSelectSeats) {
      onSelectSeats(passengerSeats)
    } else {
      dispatch(setPassengerSeatings(passengerSeats))
      dispatch(closeAllModals())
    }
  }, [dispatch])

  const close = useCallback(() => {
    if (!!onClose) {
      onClose()
    } else {
      dispatch(closeAllModals())
    }
  }, [dispatch])


  return (
    <ModalHook modalBoolean={modalBoolean}>
      <ModalFlightSeatReservation InitpassengerSeats={resIniSeats} passengers={resPass} seats={resSeats} onSelectSeats={selectSeats} onClose={close}/>
    </ModalHook>
  )
}

export default memo(SeatReservationModal)
