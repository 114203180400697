import React, { memo } from 'react'
import { Box, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'
import capfirst from '../../../../utils/capitalizeFirstLetter'
import { formatTrainDuration } from '../../../../utils/formatDurations'
import Moment from 'react-moment'
import GetCompanyIcon from '../../../../components/reusable/getCompanyIcon'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import TrainMiddleStepMobile from '../mobile/trainMiddleStepMobile'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },

  borderColor: {
    borderColor: theme.palette.border,
  },
}))

const Outgoing = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.align}>
      <ArrowForwardIcon />
      <Typography variant="body2" className={classes.weight}>
        {t('chosen trip')}
      </Typography>
    </Box>
  )
}

const Returning = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.align}>
      <ArrowBackIcon />
      <Typography variant="body2" className={classes.weight}>
        {t('chosen returntrip')}
      </Typography>
    </Box>
  )
}
const TrainMiddlestepCard = ({ isOutgoing, train }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const solutions = train.train?.scheduleSolution
  const { railstart, railend, Duration } = solutions
  const formattedDuration = formatTrainDuration(Duration)
  const isDirect = solutions.segments <= 0

  if (isDesktop) {
    return (
      <Box
        borderRight={isOutgoing ? 1 : 0}
        className={classes.borderColor}
        p={3}
      >
        <Box className={classes.align} pb={1.5}>
          {isOutgoing ? <Outgoing /> : <Returning />}
        </Box>
        <Box>
          <Box className={classes.align} pb={1.5}>
            <Typography>
              <Moment format="ddd D MMM">{railstart.dateTime}</Moment>{' '}
              <span className={classes.weight}>
                <Moment format="HH:mm">{railstart.dateTime}</Moment>-
                <Moment format="HH:mm">{railend.dateTime}</Moment>
              </span>
            </Typography>
          </Box>
          <Box pb={1.5}>
            <Typography>
              {capfirst(railstart.locationName)} -{' '}
              {capfirst(railend.locationName)}
            </Typography>
          </Box>
          <Box pb={1.5} className={classes.align}>
            <GetCompanyIcon company={solutions.serverProviderCode} />
            <Typography className={classes.weight}>
              {formattedDuration}
            </Typography>
            <Typography>
              {isDirect
                ? t('filter directly small')
                : `${train.train?.segments.length - 1} ${t('flter exchanges')}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return <TrainMiddleStepMobile model={train} isOutgoing={isOutgoing} />
}

export default memo(TrainMiddlestepCard)
