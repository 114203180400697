import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import ChooseAccommodationItem from '../../../../components/trains/chooseAccommodation'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DesktopAccomodationHeader from './choseAccommodationHeader/desktop'
import MobileAccomodationHeader from './choseAccommodationHeader/mobile'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '264px',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minHeight: '280px',
      padding: theme.spacing(3),
    },
  },
}))

const ChooseAccommodationContainer = ({ trainDetails, index }) => {
  const theme = useTheme()
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    selectedOutGoingTrip,
    selectedReturnTrip,
  } = useSelector((state) => state.train)

  const train = index === 0 ? selectedOutGoingTrip : selectedReturnTrip

  return (
    <div>
      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {isMobile ? (
              <MobileAccomodationHeader train={train} index={index} />
            ) : (
              <DesktopAccomodationHeader train={train} index={index} />
            )}
          </Grid>
          <Grid item xs={12}>
            {
              <ChooseAccommodationItem options={trainDetails} train={train} index={index} />
            }
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default memo(ChooseAccommodationContainer)
