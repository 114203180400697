import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const NoSeatsOnPlane = () => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Box display="flex" alignItems="center" justifyContent="center" p={10}>
      <Typography className={classes.weight} variant="h6">
        {t('error flights seats')}
      </Typography>
    </Box>
  )
}

export default memo(NoSeatsOnPlane)
