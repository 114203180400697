import React, { memo, useEffect, useState } from 'react'
import AuthWrapper from '../../containers/authWrapper/authWrapper'
import { Box, Typography, TextField, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { forgotPasswordAction } from '../../store/auth/authAction'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '42px',
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'pre-line',
  },
  button: {
    borderRadius: theme.spacing(4),
    padding: theme.spacing(1.5, 0),
    width: '100%',
    letterSpacing: 1.1,
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0.5),
  },
  text: {
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(5),
    textAlign: 'center',
  },

  error: {
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    padding: '20px',
  },
}))

const ForgotPassword = () => {
  const { t } = useTranslation()
  let [error, setError] = useState(null)

  const dispatch = useDispatch()

  const classes = useStyles()

  const [isSent, setIsSent] = useState(false)

  const onSubmit = async ({ email }) => {
    try {
      await dispatch(forgotPasswordAction(email))
      setIsSent(true)
    } catch (e) {
      if (e.response.status === 500) {
        setError(t('forgot password url missing error'))
      } else {
        setError(t('forgot password unknown error'))
      }
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('valid email error'))
      .required(t('required field')),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit,
  })

  /*
  useEffect(() => {
    setError(t('forgot password url missing error'))
  }, [])
  */

  if (error) {
    return (
      <AuthWrapper>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box mb={8} mt={1}>
            <Typography className={classes.heading} align="center" variant="h3">
              {t('forgot password error')}
            </Typography>
          </Box>

          <Typography className={classes.error} align="center">
            {error}
          </Typography>

          <Button
            disableElevation
            href="/log-in"
            color="primary"
            variant="contained"
            className={classes.btn}
            tabIndex="3"
          >
            {t('login')}
          </Button>
        </Box>
      </AuthWrapper>
    )
  }

  return (
    <AuthWrapper>
      {!isSent ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box mb={4} mt={1}>
            <Typography className={classes.heading} align="center" variant="h3">
              {t('request pass')}
            </Typography>
          </Box>

          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              maxWidth="365px"
              flex={1}
            >
              <Typography className={classes.label}>
                {t('placeholder email')}
              </Typography>
              <TextField
                name="email"
                error={formik.errors.email && formik.touched.email}
                helperText={formik.touched.email && formik.errors.email}
                InputLabelProps={{ shrink: false, inputMode: 'email' }}
                autoComplete={'off'}
                inputProps={{ autoCorrect: 'off', spellCheck: 'false' }}
                type={'email'}
                label={formik.values.email ? '' : t('placeholder email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Box
                mt={10}
                mb={8}
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  className={classes.button}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  {t('send link')}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      ) : (
        <Box
          mx="auto"
          maxWidth="300px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mb={4}
        >
          <Typography className={classes.heading} align="center" variant="h3">
            {t('check your email')}
          </Typography>
          <Typography className={classes.text}>
            {t('have sent email')}
          </Typography>
        </Box>
      )}
    </AuthWrapper>
  )
}

export default memo(ForgotPassword)
