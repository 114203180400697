import React, { memo, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'
import {
  CardMedia,
  Collapse,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import MobileListItem from './parts/mobileListItem'
import List from '@material-ui/core/List'
import { useTranslation } from 'react-i18next'
// Icons
import FlightIcon from '@material-ui/icons/Flight'
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway'
import HotelIcon from '@material-ui/icons/Hotel'
import MapIcon from '@material-ui/icons/Map'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import ExitToApp from '@material-ui/icons/ExitToApp'
import { useDispatch, useSelector } from 'react-redux'
import { openLogoutModal } from '../../store/modals/modalAction'
import LanguageIcon from '@material-ui/icons/Language'
import { getBrandedUrl } from '../../utils/branding'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import {
  AttachMoney,
  DirectionsCar,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons'
import { setCurrency, switchCompanyAction } from '../../store/auth/authAction'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'
import { supportedLanguages } from '../../utils/general'

export const myInformation = (t, user, useNested = true) => {
  const { isSSOEnabled } = useSelector((state) => state.auth)
  const { hideIDdocs, hideLoyalty, hidePersonal, hidePassword } = user || {}
  const { i18n } = useTranslation()

  let links = []

  const isWidget = window.parent !== window

  if (!hidePersonal) {
    links.push({
      name: t('nav personal'),
      slug: `/profile/personal-information`,
      icon: InfoOutlinedIcon,
    })
  }

  if (!hideLoyalty) {
    links.push({
      name: t('loyalty program'),
      slug: `/profile/my-loyalty-programs`,
      icon: FavoriteBorderOutlinedIcon,
    })
  }

  if (!hideIDdocs) {
    links.push({
      name: t('nav id'),
      slug: `/profile/id-documents`,
      icon: ContactMailIcon,
    })
  }

  if (!isWidget && !hidePassword && !isSSOEnabled) {
    links.push({
      name: t('nav pass'),
      icon: VpnKeyIcon,
      slug: `/profile/my-password`,
    })
  }

  let lang = i18n.languages[0] || 'sv'
  let policyField = `hasPolicyDocument${lang.toUpperCase()}`
  if (user?.[policyField]) {
    links.push({
      name: t('policy document'),
      slug: `/profile/policy-document`,
      icon: PictureAsPdfIcon,
    })
  }

  if (useNested) {
    return {
      title: t('my info'),
      icon: InfoOutlinedIcon,
      links: links,
    }
  } else {
    return links
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiDrawer-paperAnchorRight': {
      width: '100%',
    },
  },
  logo: { flex: 1, alignItems: 'center' },
  weight: {
    fontWeight: 500,
  },

  langItem: {
    '& .MuiListItemText-primary': {
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: 'none',
    },
  },

  listItemText: {
    '& .MuiListItemText-primary': {
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  logoItem: {
    height: '33px',
    width: '100px',
    display: 'inline-block',
  },
  logoDivider: {
    color: theme.palette.primary.main,
    width: '1px',
    height: '40px',
    marginLeft: '10px',
    marginRight: '10px',
    display: 'inline-block',
  },
  companyLogo: {
    height: '40px',
    width: '120px',
    display: 'inline-block',
  },
  header: {
    boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.15)',
  },
}))

const NavLinksMobile = ({ open, close }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { user, isAuthenticated, authType } = useSelector((state) => state.auth)
  const {
    currencies,
    selectedCurrency,
    currency: defaultCurrency,
    allowCurrencySelection,
  } = useSelector((state) => state.auth)
  const { isFlightsEnabled, isCarsEnabled, isHotelsEnabled, isTrainsEnabled } =
    useEnabledBookingTypesHook()

  const logoUrl = getBrandedUrl('logo_retina.png')
  const companyLogoUrl = user?.companyLogo
  const isAgent = !!isAuthenticated && authType === 'agent' // is user auth type - agent

  const { menu: customMenu } = useSelector((state) => state.customization)

  const lang = (i18n.language?.split('-')[0] || 'sv').toLowerCase()

  const [isLocaleMenuOpen, setIsLocaleMenuOpen] = useState(false)
  const [isCompaniesMenuOpen, setIsCompaniesMenuOpen] = useState(false)
  const [isCurrencyMenuOpen, setIsCurrencyMenuOpen] = useState(false)

  const isWidget = window.parent !== window

  const customLinks = customMenu
    ?.map((menu, i) =>
      menu.map((item) => ({
        to: `/c/${item[lang].slug}`,
        title: item[lang].title,
        icon: MenuBookIcon,
      }))
    )
    .flat()

  let links = []

  if (isTrainsEnabled) {
    links.push({
      to: '/trains',
      title: `${t('nav train')}`,
      icon: DirectionsRailwayIcon,
    })
  }

  if (isFlightsEnabled) {
    links.push({
      to: '/flights',
      title: `${t('nav flight')}`,
      icon: FlightIcon,
    })
  }

  if (isHotelsEnabled) {
    links.push({ to: '/hotels', title: `${t('nav hotel')}`, icon: HotelIcon })
  }

  if (isCarsEnabled) {
    links.push({ to: '/cars', title: `${t('rentalCar')}`, icon: DirectionsCar })
  }

  if (user && !isAgent && !user?.hideMyTrips) {
    links.push({
      to: '/my-trips',
      title: `${t('nav my travels')}`,
      icon: MapIcon,
    })
  }

  if (!!user?.bookingCalendar) {
    links.push({
      to: '/company-trips-calendar',
      title: `${t('booking calendar')}`,
      icon: DateRangeIcon,
    })
  }

  if (!user?.isBookingLink) {
    links.push({
      to: '/checkIn',
      title: `${t('airline checkin')}`,
      icon: FlightIcon,
    })
  }

  const mobileLinks = customLinks
    ? customLinks
    : [...links, myInformation(t, user)]

  const renderLinks = !user?.isBookingLink
    ? mobileLinks.map((nav, index) => {
        return <MobileListItem nav={nav} key={index} />
      })
    : []

  const onShowlogoutModal = () => dispatch(openLogoutModal())

  const onGoToAdmin = () => {
    document.location = '/admin/company/' + user.companyId
  }

  const setLocale = (locale) => {
    return i18n.changeLanguage(locale)
  }

  const locales = {
    sv: 'Svenska',
    en: 'English',
    fi: 'Suomalainen',
  }
  const currentLocaleText = locales[i18n.languages[0]]

  const handleSelectCompany = (companyId) => {
    setIsCompaniesMenuOpen(false)
    close()
    dispatch(switchCompanyAction(companyId))
  }

  const handleChangeCurrency = (currency) => {
    dispatch(setCurrency(currency))
    setIsCurrencyMenuOpen(false)
  }

  const showCompanySelection =
    user?.selectableCompanies?.length > 1 && !isWidget

  const showCurrencySelection = currencies && allowCurrencySelection

  return (
    <Drawer anchor="right" open={open} className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        py={'12px'}
        className={classes.header}
      >
        <Box
          display="flex"
          alignItems="left"
          width="100%"
          justifyContent="left"
          className={classes.logo}
        >
          {!isWidget && (
            <CardMedia
              data-id="header-logo"
              image={logoUrl}
              className={classes.logoItem}
            />
          )}

          {!isWidget && companyLogoUrl && (
            <>
              <Divider className={classes.logoDivider} />

              <CardMedia
                data-id="company-logo"
                image={companyLogoUrl}
                className={classes.companyLogo}
                display="inline"
              />
            </>
          )}
        </Box>

        <CloseIcon color="primary" onClick={close} />
      </Box>
      <Box>
        <List component="nav" aria-labelledby="nested-list-subheader">
          {renderLinks}

          {!isWidget && (
            <>
              {user?.admin && (
                <ListItem button onClick={() => onGoToAdmin()}>
                  <ListItemIcon style={{ minWidth: '40px' }}>
                    <AccountBalanceIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={t('nav admin')} />
                </ListItem>
              )}

              <ListItem
                button
                onClick={() => setIsLocaleMenuOpen(!isLocaleMenuOpen)}
              >
                <ListItemIcon style={{ minWidth: '40px' }}>
                  <LanguageIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  className={classes.langItem}
                  primary={currentLocaleText}
                />
                {isLocaleMenuOpen ? (
                  <ExpandLess color="primary" />
                ) : (
                  <ExpandMore color="primary" />
                )}
              </ListItem>
              <Collapse in={isLocaleMenuOpen} timeout="auto" unmountOnExit>
                <List
                  style={{ paddingLeft: '40px' }}
                  component="div"
                  disablePadding
                >
                  {Object.entries(supportedLanguages)
                    .filter(([code, title]) => code !== i18n.languages[0])
                    .map(([code, title]) => (
                      <ListItem key={code} onClick={() => setLocale(code)}>
                        <ListItemText primary={title} />
                      </ListItem>
                    ))}
                </List>
              </Collapse>

              {showCurrencySelection && (
                <>
                  <ListItem
                    button
                    onClick={() => setIsCurrencyMenuOpen(!isCurrencyMenuOpen)}
                  >
                    <ListItemIcon style={{ minWidth: '40px' }}>
                      <AttachMoney color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.langItem}
                      primary={selectedCurrency || defaultCurrency}
                    />
                    {isCurrencyMenuOpen ? (
                      <ExpandLess color="primary" />
                    ) : (
                      <ExpandMore color="primary" />
                    )}
                  </ListItem>
                  <Collapse
                    in={isCurrencyMenuOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      style={{ paddingLeft: '40px' }}
                      component="div"
                      disablePadding
                    >
                      {selectedCurrency &&
                        selectedCurrency != defaultCurrency && (
                          <ListItem
                            onClick={() => handleChangeCurrency(null)}
                            key={'defaultcurrency'}
                          >
                            <ListItemText primary={defaultCurrency} />
                          </ListItem>
                        )}

                      {Object.keys(currencies).map((currency) => (
                        <ListItem
                          onClick={() => handleChangeCurrency(currency)}
                        >
                          <ListItemText primary={currency} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              )}
            </>
          )}

          {showCompanySelection && (
            <>
              <ListItem
                button
                onClick={() => setIsCompaniesMenuOpen(!isCompaniesMenuOpen)}
              >
                <ListItemIcon style={{ minWidth: '40px' }}>
                  <LanguageIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  className={classes.langItem}
                  primary={t('switch to')}
                />
                {isCompaniesMenuOpen ? (
                  <ExpandLess color="primary" />
                ) : (
                  <ExpandMore color="primary" />
                )}
              </ListItem>

              <Collapse in={isCompaniesMenuOpen} timeout="auto" unmountOnExit>
                <List
                  style={{ paddingLeft: '40px' }}
                  component="div"
                  disablePadding
                >
                  {user.selectableCompanies.map((company) => (
                    <ListItem
                      onClick={() => handleSelectCompany(company.uniqueId)}
                    >
                      <ListItemText primary={company.name} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          )}

          {isAuthenticated && !isWidget && (
            <ListItem button onClick={onShowlogoutModal}>
              <ListItemIcon style={{ minWidth: '40px' }}>
                <ExitToApp color="primary" />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={t('nav logout')}
              />
            </ListItem>
          )}
        </List>
      </Box>
    </Drawer>
  )
}

export default memo(NavLinksMobile)
