import React, { memo } from 'react'
import { Box, Typography, useMediaQuery } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import GetCompanyIconElement from '../../components/reusable/getCompanyIcon'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'
import { toHoursAndMinutes } from '../../utils/formatDates'
import { formatTrainDuration } from '../../utils/formatDurations'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '0 3px',
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const ChoosenTripCard = ({ trip, title, home }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const rotateIcon = home ? classes.rotate : null
  const scheduleSolution = trip?.train?.scheduleSolution
  const departureTime = toHoursAndMinutes(scheduleSolution?.railstart?.dateTime)
  const arrival = toHoursAndMinutes(scheduleSolution?.railend?.dateTime)
  const railStartDestination = scheduleSolution?.railstart?.locationName
  const railEndDestination = scheduleSolution?.railend?.locationName
  const formattedDuration = formatTrainDuration(scheduleSolution.Duration)
  const isDirect = trip.train.segments.length <= 1
  const iconString = scheduleSolution?.serverProviderCode
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box p={2} pb={1}>
      <Box className={classes.align} pt={1} pb={1.5}>
        <ArrowForwardIcon className={rotateIcon} />
        <Typography className={classes.weight} variant="body2">
          {title}
        </Typography>
      </Box>

      <Box className={classes.align} pb={1.5}>
        <Typography>
          <Moment format="ddd D MMM">
            {scheduleSolution?.railstart?.dateTime}
          </Moment>
        </Typography>
        <Typography className={classes.weight}>
          {departureTime}–{arrival}
        </Typography>
      </Box>

      <Box className={classes.align} pb={1.5}>
        <Typography>
          {railStartDestination} – {railEndDestination}
        </Typography>
      </Box>

      <Box className={classes.align} pb={2}>
        <GetCompanyIconElement small={isMobile} company={iconString} />
        <Typography className={classes.weight}>{formattedDuration}</Typography>
        <Typography>
          {isDirect
            ? t('filter directly small')
            : trip.train.segments.length - 1 > 1
            ? `${trip.train.segments.length - 1} ${t('changes')}`
            : `${trip.train.segments.length - 1} ${t('change')}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default memo(ChoosenTripCard)
