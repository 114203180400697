import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import WorkIcon from '@material-ui/icons/Work'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import WorkOffIcon from '@material-ui/icons/WorkOff'
import AirlineSeatReclineNormalIcon from '@material-ui/icons/AirlineSeatReclineNormal'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > * ': {
      marginTop: theme.spacing(1),
      margin: `0px ${theme.spacing(1)}px`,
    },
  },
  img: {
    width: '16px',
  },
}))

const TicketLabel = ({ labels }) => {

  const classes = useStyles()
  const { t } = useTranslation()

  const icons = {
    BAG: WorkIcon,
    UBAG: BusinessCenterIcon,
    LBAG: BusinessCenterIcon,
    HBAG: BusinessCenterIcon,
    NBAG: WorkOffIcon,
    STS: AirlineSeatReclineNormalIcon,
    FTR: DirectionsRunIcon,
    PRB: AccessAlarmIcon,
    PBAG: AccessAlarmIcon,
    PCI: AccessAlarmIcon,
    NREF: CloseIcon,
    NRBK: CloseIcon,
    default: CheckIcon,
  }

  const renderLabels = labels?.ffServ?.map(({ type, text }, key) => {
    const Icon = icons[type] ? icons[type] : icons['default']

    return (
      <Box key={key} className={classes.align}>
        <Icon color="primary" />
        <Typography>{t(text)}</Typography>
      </Box>
    )
  })

  return <Box>
    {renderLabels || (
      <Box key={'no-services'} className={classes.align}>
        <Typography>{t('services list not available')}</Typography>
      </Box>
    )}
  </Box>
}

export default TicketLabel
