//
const SET_TRANSFER_LOCATION = 'SET_TRANSFER_LOCATION'
const SET_TRANSFER_LOCATION_LOADING = 'TRANSFER_LOCATION_LOADING'
const SET_TRANSFER_LOCATION_ERROR = 'SET_TRANSFER_LOCATION_ERROR'
const REMOVE_LOCATION_STATE = 'REMOVE_LOCATION_STATE'
//
const SET_TAXI = 'SET_TAXI'
const SET_TAXI_LOADING = 'SET_TAXI_LOADING'
const SET_FLYGTAXI_LOADING = 'SET_FLYGTAXI_LOADING'
const SET_TAXI_ERROR = 'SET_TAXI_ERROR'
const REMOVE_TRANSFER_SEARCH = 'REMOVE_TRANSFER_SEARCH'
//
const SET_SERVICE_LOCATION = 'SET_SERVICE_LOCATION'
const SET_SERVICE_LOCATION_LOADING = 'SET_SERVICE_LOCATION_LOADING'
const SET_SERVICE_LOCATION_ERROR = 'SET_SERVICE_LOCATION_ERROR'

export default {
  SET_TRANSFER_LOCATION,
  SET_TRANSFER_LOCATION_LOADING,
  SET_FLYGTAXI_LOADING,
  SET_TRANSFER_LOCATION_ERROR,
  REMOVE_LOCATION_STATE,
  //
  SET_TAXI,
  SET_TAXI_LOADING,
  SET_TAXI_ERROR,
  REMOVE_TRANSFER_SEARCH,
  //
  SET_SERVICE_LOCATION,
  SET_SERVICE_LOCATION_LOADING,
  SET_SERVICE_LOCATION_ERROR
}
