// FLIGHT
const BREAD_CRUMB_FLIGHT_SELECT = 'BREAD_CRUMB_FLIGHT_SELECT'

//MIDDLESTEP
const BREAD_CRUMB_MIDDLE_STEP = 'BREAD_CRUMB_MIDDLE_STEP'

// TRAIN
const BREAD_CRUMB_TRAIN_SELECT = 'BREAD_CRUMB_TRAIN_SELECT'

// CAR
const BREAD_CRUMB_CAR_SELECT = 'BREAD_CRUMB_CAR_SELECT'

// HOTEL
const BREAD_CRUMB_HOTEL_SELECT = 'BREAD_CRUMB_HOTEL_SELECT'
//SINGLE HOTEL
const BREAD_CRUMB_SINGE_HOTEL_SELECT = 'BREADK_CRUMB_SINGE_HOTEL_SELECT'

//  SET TYPE
const BREAD_CRUMB_SET_TYPE = 'BREAD_CRUMB_SET_TYPE'

export default {
  BREAD_CRUMB_FLIGHT_SELECT,
  BREAD_CRUMB_MIDDLE_STEP,
  BREAD_CRUMB_TRAIN_SELECT,
  BREAD_CRUMB_HOTEL_SELECT,
  BREAD_CRUMB_SINGE_HOTEL_SELECT,
  BREAD_CRUMB_SET_TYPE,
  BREAD_CRUMB_CAR_SELECT
}
