import constants from './constants'
import storage from 'local-storage-fallback'

const guests = JSON.parse(storage.getItem('hotelGuests'))

const initialFilters = {
  popular: {
    wifi: false,
    cancellation: false,
    breakfast: false,
    eco: false,
  },
  maxPrice: 10000000000,
  stars: {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
  },
  rating: {
    excellent: true,
    veryGood: true,
    good: true,
    fair: true,
    no: true,
  },
}

const initialState = {
  search: [],
  searchChecksum: null,
  singleChecksum: null,
  isSearchLoading: false,
  searchError: null,
  currency: null,
  cities: [],
  city: null,
  ratings: [],
  single: null,
  singleLoading: false,
  singleError: null,
  selectedHotel: null,
  showMap: true,
  mapCenter: null,
  filters: initialFilters,
  guests: guests || [],
  enableZoneFilter: false,
  zoneFilteredSearch: [],
  seenZoneFilter: false,
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.SET_HOTEL_SEARCH_RESULTS:
      return {
        ...state,
        search: payload.hotels,
        currency: payload.currency,
      }
    case constants.SET_SEARCHED_HOTELS:
      let hotels = [].concat(state.search, payload?.hotels)
      let currency = payload?.currency || state.currency
      return {
        ...state,
        search: hotels,
        currency,
      }
    case constants.SET_HOTEL_LOADING_RESULTS:
      return {
        ...state,
        isSearchLoading: payload,
      }
    case constants.SET_HOTEL_ERROR_RESULT:
      return {
        ...state,
        searchError: payload,
      }
    case constants.SET_HOTEL_CITIES:
      return {
        ...state,
        cities: payload,
      }

    case constants.SET_CITY_COORDINATES:
      return {
        ...state,
        city: payload,
      }
    case constants.SET_HOTEL_RATINGS:
      return {
        ...state,
        ratings: payload,
      }
    case constants.SET_SINGLE_HOTEL:
      return {
        ...state,
        single: payload,
      }

    case constants.SET_SINGLE_LOADING_HOTEL:
      return {
        ...state,
        singleLoading: payload,
      }
    case constants.SET_SINGLE_HOTEL_ERROR:
      return {
        ...state,
        singleError: payload,
      }

    case constants.SET_HOTEL_GUESTS:
      return {
        ...state,
        guests: payload,
      }

    case constants.SET_HOTEL_SEARCH_CHECKSUM:
      return {
        ...state,
        searchChecksum: payload,
      }

    case constants.SET_HOTEL_SINGLE_CHECKSUM:
      return {
        ...state,
        singleChecksum: payload,
      }

    case constants.CLEAN_HOTEL_SEARCH_STATES:
      return {
        ...state,
        search: [],
        single: null,
        selectedHotel: null,
        selectedHotelPrice: null,
        zoneFilteredSearch: [],
      }

    case constants.REMOVE_HOTEL_STATE:
      return initialState

    case constants.SET_SELECTED_HOTEL:
      return {
        ...state,
        selectedHotel: payload,
      }

    case constants.SET_SELECTED_HOTEL_PRICE:
      return {
        ...state,
        selectedHotel: payload,
        selectedHotelPrice: payload,
      }

    case constants.SET_SHOW_MAP:
      return {
        ...state,
        showMap: payload,
      }

    case constants.SET_HOTEL_POPULAR_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          popular: {
            ...state.filters.popular,
            ...payload,
          },
        },
      }

    case constants.SET_HOTEL_MAX_PRICE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          maxPrice: payload,
        },
      }

    case constants.SET_HOTEL_STARS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          stars: {
            ...state.filters.stars,
            ...payload,
          },
        },
      }

    case constants.SET_HOTEL_GUEST_RATING_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          rating: {
            ...state.filters.rating,
            ...payload,
          },
        },
      }

    case constants.RESET_HOTEL_FILTERS:
      return {
        ...state,
        filters: initialFilters,
      }

    case constants.SET_MAP_CENTER:
      return {
        ...state,
        mapCenter: payload,
      }

    case constants.SET_ENABLE_ZONEFILTER:
      return {
        ...state,
        enableZoneFilter: payload,
      }

    case constants.SET_ZONE_FILTERED_SEARCH:
      return {
        ...state,
        zoneFilteredSearch: payload,
      }

    case constants.SET_SEEN_ZONE_FILTER:
      return {
        ...state,
        seenZoneFilter: payload,
      }

    default:
      return state
  }
}
