import constants from './constants'
import _ from 'lodash'
import CheckoutFetch from '../../repositories/checkout'
import { getPassengerData, getUserRemarks } from '../../repositories/data'
import { generateUniqueRandomString } from '../../utils/randomString'

export const getPaymentData = (items) => async (dispatch) => {
  const checkoutFetch = new CheckoutFetch()
  let data = await checkoutFetch.fetchPaymentData(items)
  dispatch({
    type: constants.SET_PAYMENT_DATA,
    payload: data,
  })
}

export const setNetsCompleted = () => (dispatch) => {
  return dispatch({ type: constants.SET_NETS_COMPLETED, payload: true })
}

export const addToCheckoutAction = (data) => (dispatch) => {
  const checksum = generateUniqueRandomString()
  dispatch({ type: constants.SET_CHECKOUT_CHECKSUM, payload: checksum })

  return dispatch({ type: constants.ADD_ITEM, payload: data })
}

export const removeCheckoutItemAction = (item) => (dispatch) => {
  const checksum = generateUniqueRandomString()
  dispatch({ type: constants.SET_CHECKOUT_CHECKSUM, payload: checksum })

  return dispatch({ type: constants.REMOVE_ITEM, payload: item })
}

export const setCreditCardData = (ccData) => (dispatch) => {
  return dispatch({ type: constants.SET_CC_DATA, payload: ccData })
}

export const setPolicyExceededReason = (reason) => (dispatch) => {
  return dispatch({
    type: constants.SET_POLICY_EXCEEDED_REASON,
    payload: reason,
  })
}

export const setFop =
  (fop = null) =>
  (dispatch) => {
    return dispatch({ type: constants.SET_FOP, payload: fop })
  }

export const setCostFieldValue =
  (type, uid, value = '') =>
  (dispatch) => {
    return dispatch({
      type: constants.SET_COSTFIELDS_VALUE,
      payload: { type, value, uid },
    })
  }

export const setAllItems = (ccData) => (dispatch) => {
  return dispatch({ type: constants.SET_CHECKOUT_ITEMS, payload: ccData })
}

export const setItemsPassengerField =
  (fields = {}) =>
  async (dispatch, getState) => {
    const state = getState()
    const { items } = state.checkout

    if (items?.length > 0) {
      const results = items.map((i) => ({
        ...i,
        passengers: !i.passengers
          ? []
          : i.passengers.map((p) => {
              const tmpFields = fields?.[p.uniqueId] || {}
              return {
                ...p,
                ...(typeof tmpFields ? tmpFields : {}),
              }
            }),
      }))
      dispatch(setAllItems(results))
    }
  }

export const getCostfieldsAction =
  (userId = null) =>
  async (dispatch, getState) => {
    const checkoutFetch = new CheckoutFetch()
    dispatch({
      type: constants.COSTFIELDS_LOADING,
      payload: true,
    })
    const costfields = await checkoutFetch.fetchCostFields()
    const state = getState()
    const { costFieldValues, items } = state.checkout
    const { user } = state.auth
    let remarks = {}

    if (!!user?.isBookingLink && user.uniqueId) {
      remarks[user.uniqueId] = user?.more?.remarks || {}
      remarks['link-user-id'] = user?.more?.remarks || {}
    } else if (!!userId) {
      const ids = typeof userId !== 'object' ? [userId] : userId
      const rqs = ids.map((uid) => getUserRemarks(uid))
      const resRm = await Promise.allSettled(rqs)
      resRm.forEach((rm, ind) => {
        if (
          !ids[ind] ||
          rm.status !== 'fulfilled' ||
          !rm.value ||
          !_.isPlainObject(rm.value)
        ) {
          return false
        }
        remarks[ids[ind]] = rm.value
      })
    }
    /* gds hotel payment exceptions */
    const hotel = items.find((i) => i.type === 'Hotel')
    const hotelCountry = hotel?.hotel?.params?.countryCode || null
    const gdsExceptions =
      costfields?.paymentMethods?.agreementMethods?.exceptions?.gdsHotel
    const gdsHotelPay = costfields?.paymentMethods?.agreementMethods?.gdsHotel
    if (
      gdsExceptions?.length &&
      gdsHotelPay &&
      hotelCountry &&
      gdsExceptions.includes(hotelCountry)
    ) {
      costfields.paymentMethods.agreementMethods.gdsHotel =
        gdsHotelPay === 'invoice' ? 'profileCC' : 'invoice'
    }

    const defaultVals = {}
    if (costfields?.costfields?.length && remarks) {
      costfields.costfields.forEach((c) => {
        if (!c?.costType) return
        for (let rmUId in remarks) {
          if (costFieldValues?.[rmUId]?.[c.costType]) continue
          const val = remarks[rmUId]?.[c.costType]
          if (val) {
            if (!defaultVals[rmUId]) defaultVals[rmUId] = {}
            defaultVals[rmUId][c.costType] = val
          }
        }
      })
    }
    dispatch({
      type: constants.SET_COSTFIELDS,
      payload: { costfields, defaultVals },
    })
    dispatch({
      type: constants.COSTFIELDS_LOADING,
      payload: false,
    })
  }

export const setCCFees =
  (fees = {}) =>
  async (dispatch) => {
    dispatch({ type: constants.SET_CC_FEES, payload: fees })
  }

export const getHotelGuaranteeAction = (roomData) => async (dispatch) => {
  dispatch({ type: constants.HOTEL_GUARANTEE_LOADING })
  const checkoutFetch = new CheckoutFetch()
  const guarantee = await checkoutFetch.fetchHotelGuarantee(roomData)
  dispatch({ type: constants.SET_HOTEL_GUARANTEE, payload: guarantee })
}

export const setBookingData = (bookingData) => async (dispatch) => {
  dispatch({ type: constants.SET_BOOKING_RESULT, payload: bookingData })
}

export const setPublicContacts = (publicContacts) => (dispatch) => {
  dispatch({
    type: constants.SET_PUBLIC_CONTACTS,
    payload: publicContacts || {},
  })
}

export const setAddressDetails = (addressDetails) => ({
  type: constants.SET_ADDRESS_DETAILS,
  payload: addressDetails,
})

export const removeAllCheckoutAction = () => ({
  type: constants.REMOVE_ALL_CHECKOUT,
})

export const correctHotelPrice =
  (prices = {}) =>
  (dispatch) => {
    return dispatch({
      type: constants.CORRECT_HOTEL_PRICE,
      payload: { prices },
    })
  }

export const getPassengersData = (passengerIds) => async (dispatch) => {
  dispatch(setPassengerDataLoading(true))
  const passengerData = await getPassengerData(passengerIds)

  dispatch({
    type: constants.SET_PASSENGERS_DATA,
    payload: passengerData,
  })

  dispatch(setPassengerDataLoading(false))
}

export const setPassengerDataLoading = (loading) => ({
  type: constants.SET_PASSENGERS_DATA_LOADING,
  payload: loading,
})

export const setAdditionalRecipientEmail = (email) => ({
  type: constants.SET_ADDITIONAL_RECIPIENT_EMAIL,
  payload: email,
})

export const setDlk = (assocId, teamId, error) => ({
  type: constants.SET_DLK,
  payload: {
    assocId,
    teamId,
    error,
  },
})
