import React from 'react'
import { Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import NotifyBlock from './notifyBlock'
import { getHotelsCheckoutNotify } from '../../../utils/general'

const HotelNotify = ({fops, noteObject = null, useOnlySavedCC = false}) => {
  const checkout = useSelector((state) => state.checkout)
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = currency === 'EUR' ? 'eur' : 'kr'
  const hotelHeaders = noteObject || getHotelsCheckoutNotify(checkout, fops, currencyCode, useOnlySavedCC)

  return (
    <Box>
      {!!hotelHeaders?.paymentNote &&
        <NotifyBlock noteType={hotelHeaders.type} noteText={hotelHeaders.paymentNote || ''}/>
      }
    </Box>
  )
}
export default HotelNotify
