import React, {useCallback, useMemo, useState} from 'react'
import { useHistory } from 'react-router-dom'
import {Checkbox, FormControlLabel, InputAdornment, Typography, useTheme, Box, Drawer} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import StepHandler from './stepHandler'
import Input from '@material-ui/core/Input'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import {useDispatch, useSelector} from 'react-redux'
import useDirections from '../../../hooks/useDirectionsHook'
import useTravellers from '../../../hooks/useTravellersHook'
import useDestinations from '../../../hooks/useSwitchDestinationsHook'
import useCarsHook from '../../../hooks/useCarsHook'
import EventIcon from '@material-ui/icons/Event';
import Grid from '@material-ui/core/Grid';
import UserSearch from '../../../components/reusable/usersSearch';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.dimWhite,
    display: 'flex',
    flexDirection: 'column'
  },
  checkboxLabel: {
    '& > .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  weight: { fontWeight: 700 },
  drawer: {
    marginBottom: theme.spacing(10),
    '& .MuiDrawer-paper	': {
      backgroundColor: theme.palette.dimWhite,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '400px',
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    height: '64px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 3),
    zIndex: 1000,
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse'
    }
  },
  boldTitle: {
    fontWeight: 700,
    fontSize: theme.spacing(2)
  },
  resetFilter: {
    fontSize: theme.spacing(1.75),
    textTransform: 'capitalize',
    lineHeight: '16px',
    fontWeight: 700
  },
  rowBlock: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e6e6e6',
    boxShadow: 'none',
    borderRadius: '4px',
    marginBottom: theme.spacing(1),
  },
  input: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e6e6e6',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.MuiInput-underline:after': {
      display: 'none'
    },
    '&.MuiInput-underline:before': {
      display: 'none'
    },
  },
  headerBtn: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '1.1px'
  }
}))

const CarMiddlestepFilter = ({ drawer, toggleDrawer }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const [filter, setFilter] = useState(null)


  const search = useSelector((state) => state.search)
  const emptySearchState = useMemo(() => ({travellers: []}), [])

  const {
    difPlace: initialDifPlace,
    from: initialFrom,
    to: initialTo,
    departure: initialDeparture,
    arrival: initialArrival,
    travellers: initialTravellers,
  } = search?.cars || emptySearchState

  const { departure, depChange:setDeparture, arrival, arrOnChange:setArrival } = useDirections(
    initialDeparture,
    initialArrival,
    true
  )

  const { travellers, setTravellers } = useTravellers(initialTravellers)

  const {
    setFrom,
    setTo,
    fromDest: from,
    toDest: to,
  } = useDestinations(initialFrom, initialTo, i18n, 'car')

  const { diffPlace, setDiffPlace, onSubmit, validate } = useCarsHook({
    fromDest: from,
    toDest: to,
    initialDifPlace,
    history,
    i18n,
    endDate: arrival,
    startDate: departure,
    dispatch,
    travellers,
    isMiddlestep: true
  })

  const handleValueChange = useCallback((field, value) => {
      switch (field) {
        case 'from':
          setFrom(value)
        break
        case 'to':
          setTo(value)
        break
        case 'take_date':
        case 'take_time':
          setDeparture(value)
        break
        case 'drop_date':
        case 'drop_time':
          setArrival(value)
        break
      }
  }, [setFrom, setTo, setDeparture, setArrival])

  const filters = useMemo(() => ({
    from,
    to,
    take_date: departure,
    take_time: departure,
    drop_date: arrival,
    drop_time: arrival,
  }), [from, to, departure, arrival])

  const handleClick = (type) => {
    setFilter(type)
  }

  const handleClose = () => {
    setDiffPlace(initialDifPlace)
    setFrom(initialFrom)
    setTo(initialTo)
    setDeparture(initialDeparture)
    setArrival(initialArrival)
    setTravellers(initialTravellers)

    toggleDrawer()
  }


  const handleSubmit = () => {
    if (validate()) {
      toggleDrawer()
      onSubmit()
    }
  }

  return (
    <Drawer anchor="right" open={drawer} onClose={handleClose} className={classes.drawer}>
      <Box className={classes.container}>
        {
          filter ? (
            <StepHandler
              filter={filter}
              filters={filters}
              toggleDrawer={handleClose}
              goBack={() => setFilter(null)}
              handleValueChange={handleValueChange}
            />
          ) : (
            <>
              <Box className={classes.header}>
                <Box display="flex" justifyContent={isMobile ? 'flex-end' : 'flex-start'} width="100px">
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleSubmit}
                    className={classes.headerBtn}
                    color="primary"
                  >
                    {t('done')}
                  </Button>
                </Box>
                <Box flex={1} textAlign={'center'}>
                  <Typography className={classes.boldTitle}>
                    {t('edit car search')}
                  </Typography>
                </Box>
                <Box width="64px" display="flex" justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                  <CloseIcon color="primary" style={{ cursor: 'pointer' }} onClick={toggleDrawer}/>
                </Box>
              </Box>
              <Box pl={3} pr={3} pb={3} pt={0} className={classes.container} style={{ paddingTop: '24px', }}>
                <Box className={classes.rowBlock}>
                  <UserSearch
                    entityType={'car'}
                    value={travellers}
                    setValue={setTravellers}
                    noLabel
                    withUserModal={true}
                    userShadow={false}
                  />
                </Box>
                <Input
                  placeholder={t('pick-up place')}
                  value={filters?.from?.description || ''}
                  startAdornment={
                    <InputAdornment position="start">
                      <LocationCityIcon color="primary"/>
                    </InputAdornment>
                  }
                  onClick={() => handleClick('from')}
                  className={classes.input}
                />
                {!!diffPlace && (
                  <Input
                    placeholder={t('drop-off place')}
                    value={filters?.to?.description || ''}
                    startAdornment={
                      <InputAdornment position="start">
                        <LocationCityIcon color="primary"/>
                      </InputAdornment>
                    }
                    onClick={() => handleClick('to')}
                    className={classes.input}
                  />
                )}

                <Grid item container spacing={1}>
                  <Grid item xs={6}>
                    <Box>
                      <Input
                        placeholder={t('pick-up search')}
                        value={!!departure ? departure.format('ddd D MMM') : ''}
                        startAdornment={
                          <InputAdornment position="start">
                            <EventIcon color="primary"/>
                          </InputAdornment>
                        }
                        onClick={() => handleClick('take_date')}
                        className={classes.input}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Input
                        placeholder={t('search time')}
                        value={!!departure ? departure.format('HH:mm') : ''}
                        onClick={() => handleClick('take_time')}
                        className={classes.input}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item container spacing={1}>
                  <Grid item xs={6}>
                    <Box>
                      <Input
                        placeholder={t('drop-off search')}
                        value={!!arrival ? arrival.format('ddd D MMM') : ''}
                        startAdornment={
                          <InputAdornment position="start">
                            <EventIcon color="primary"/>
                          </InputAdornment>
                        }
                        onClick={() => handleClick('drop_date')}
                        className={classes.input}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Input
                        placeholder={t('search time')}
                        value={!!arrival ? arrival.format('HH:mm') : ''}
                        onClick={() => handleClick('drop_time')}
                        className={classes.input}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <FormControlLabel
                  className={classes.checkboxLabel}
                  key={'car-dif-places-block'}
                  control={
                    <Checkbox checked={!!diffPlace} color="primary" name="car-dif-places-cb" onChange={e => setDiffPlace(e.target.checked)}/>
                  }
                  label={t('drop car off at different location')}
                  labelPlacement="end"
                />
              </Box>
            </>
          )
        }
      </Box>
    </Drawer>
  )
}

export default CarMiddlestepFilter
