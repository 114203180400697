/**
 *
 * @param margins
 * @param fromCountry
 * @param toCountry
 */
export const filterMargins = (margins, fromCountry, toCountry) => {
  return margins.filter(margin => {
    if (margin.country === 'nordic') {
      if (isNordicCountry(fromCountry) && isNordicCountry(toCountry)) return margin
    } else {
      return margin
    }
  })
}
/**
 *
 * @param countryCode
 * @returns {boolean}
 */
export const isNordicCountry = (countryCode) => {
  return ['SE', 'DK', 'NO', 'FI', 'IS'].includes(countryCode)
}