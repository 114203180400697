import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    flex: 0,
    paddingLeft: 0,
  },
  connector: {
    backgroundColor: theme.palette.common.black,
    margin: theme.spacing(1, 0),
  },
  weight: {
    fontWeight: 'bold',
  },
  partCancelBtn: {
    fontSize: '13px',
    lineHeight: 'normal',
    padding: '4px 6px',
    borderRadius: '4px',
    textTransform: 'none',
    marginLeft: '10px',
  },
  smallBtn: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    padding: '1px 10px 1px 10px',
  },
  cancelledText: {
    margin: '20px',
    fontSize: '16px',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  accordion: {
    margin: '0 !important',
    padding: '0 !important',
    boxShadow: 'none !important',
    '&:before': {
      display: 'none',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0 !important',
      display: 'block',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 'auto',
      padding: 0,
      marginBottom: '24px',
      '&.Mui-expanded': {
        marginBottom: '34px',
      },
    },
    '& .MuiAccordionSummary-expandIcon': {
      padding: 0,
      color: theme.palette.primary.main,
      margin: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      padding: 0,
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      alignItems: 'center',
    },
  },
  normalDetailLine: {
    fontSize: '14px',
    lineHeight: '12px',
    fontWeight: 'normal',
    marginBottom: '25px',
    '&:last-child': {
      marginBottom: theme.spacing(5),
    },
  },
  detailLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    lineHeight: '12px',
    fontWeight: 'normal',
    marginBottom: '25px',
    '&:last-child': {
      marginBottom: theme.spacing(5),
    },
  },
  detailHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: '10px',
    width: '94px',
  },
  detailText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    flex: 1,
  },
  detailTextLine: {
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  timelineText: {
    fontSize: '14px',
    lineHeight: 'normal',
    fontWeight: 'normal',
    textAlign: 'left',
    marginTop: '8px',
    '&:first-child': {
      marginTop: 0,
    },
  },
  contentPrefix: {
    width: '39px',
    lineHeight: '1.3em',
  },
  timeLineIcon: {
    width: '17px',
    height: 'auto',
  },
  timeLineContent: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:first-child': {
      paddingLeft: 0,
      paddingRight: theme.spacing(3),
    },
    '&:last-child': {
      paddingLeft: theme.spacing(3),
      paddingRight: 0,
    },
  },
  timelineItm: {
    '&:last-child': {
      minHeight: 'auto',
    },
  },
  timeline: {
    padding: 0,
    width: '100%',
    margin: theme.spacing(0, 0, 5, 0),
  },

  cardContent: {
    margin: theme.spacing(3, 3, 3, 3),
    fontSize: '16px',
  },
  label: {
    marginBottom: '8px',
  },

  title: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },

  subTitle: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },

  value: {},

  importantValue: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dot: {
    height: '4px',
    width: '4px',
    verticalAlign: 'middle',
    backgroundColor: theme.palette.grey[600],
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '8px',
    marginRight: '8px',
  },

  footer: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    backgroundColor: theme.palette.background.paper,
    zIndex: 10000,
    width: '428px',
  },

  footerOld: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'sticky',
    bottom: '0px',
    left: '0px',
    backgroundColor: theme.palette.background.paper,
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  small: {
    fontSize: '12px',
  },
  icon: {
    display: 'inline-block',
    objectFit: 'contain',
    height: '20px',
    width: '50px',
  },
  card: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: '8px',
    fontSize: '14px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  details: {
    color: '#666',
    lineHeight: '1.8em',
  },
  titleSection: {
    margin: theme.spacing(3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: '#666',
    fontSize: '12px',
  },
  tag: {
    borderRadius: '4px',
    // margin: '11px 6px 20px 19.5px',
    padding: '0px 9px',
    backgroundColor: '#e6e6e6',
    fontSize: '12px',
    color: 'black',
    width: 'fit-content',
  },

  expandedHeaderBlock: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: '20px',
    paddingBottom: '20px',
    boxShadow: 'rgba(0, 0, 0, 0.10) 0px 5px 5px 0px',
    position: 'sticky',
    top: '0px',
    left: '0px',
    backgroundColor: '#fff',
    zIndex: 1,
    height: '62px',
  },
  expandedHeaderBackBtn: {
    position: 'absolute',
    left: '20px',
    top: '18px',
    fontSize: '32px',
    cursor: 'pointer',
  },
}))
