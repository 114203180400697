import React, { useMemo } from 'react'
import _ from 'lodash'
import { Box, Typography, Divider } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import NotifyBlock from './notifyBlock'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getFops } from "../../../utils/booking";
import {callbackJoin} from '../../../utils/general';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  normalText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  radioInput: {
    color: theme.palette.primary.main
  },
  mutedTxt: {
    color: '#757575',
    marginLeft: '4px',
  },
  checkbox: {
    width: '100%',
  },
}))
const ProfileCCPayment = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const checkoutState = useSelector((state) => state.checkout)
  const { user, isAuthenticated } = useSelector((state) => state.auth)
  const { costfields } = checkoutState
  const fops = useMemo(() => getFops({isAuthenticated: isAuthenticated || false, checkoutState}), [checkoutState, isAuthenticated])
  const isCar = fops.perFop[fops.primaryFop].find(i => i === 'Car')
  const isCarNotEvoucher = !costfields?.paymentMethods?.carIncludeEvoucher && !!isCar
  let paysWith = [...(fops.perFop[fops.primaryFop] || [])]
  if (isCarNotEvoucher) paysWith = paysWith.filter(pw => pw !== 'Car')
  const translated = fops.perFop[fops.primaryFop].map(pf => t(pf.toLowerCase()).toLowerCase()) // for title
  const translatedPW = paysWith.map(pf => t(pf.toLowerCase()).toLowerCase()) // for note text
  const paymentTitle = t('payment')

  const paymentFor = (translated?.length) ? ` ${callbackJoin((pi, ni) => {
    return (ni >= translated.length - 1) ? ' & ' : ', '
  }, translated)}` : ''

  const noteNoCarTypes= _.capitalize(callbackJoin((pi, ni) => {
    return (ni >= translatedPW.length - 1) ? ' and ' : ', '
  }, translatedPW))

  const noteTypes = _.capitalize(callbackJoin((pi, ni) => {
    return (ni >= translated.length - 1) ? ' and ' : ', '
  }, translated))

  const noteTemplate = isCarNotEvoucher ? 'profile cc pay note dif types' : 'profile cc pay note'
  const translateObj = {'itm_types': noteTypes, 'itm_not_car': noteNoCarTypes}
  let noteText = ''
  if(isCarNotEvoucher && translatedPW?.length <= 0)  {
    noteText = t('car pay evoucher')
  } else {
    noteText = `${t(noteTemplate, translateObj)} ${isCarNotEvoucher ? t('car pay evoucher alt') : ''}`
  }

  const profileCCmuted = useMemo(() => {
    const opts = user?.paymentOptions || [];
    const card = opts.find(opt => opt.value === 'profileCC')
    return (card) ? card.underline || null : null
  }, [user])

  const FopTranslations = {
    invoice: t('invoice'),
    agreement: t('as per agreement'),
    profileCC: (
      <span>
        {t('payment account')}
        <span className={classes.mutedTxt}>{profileCCmuted}</span>
      </span>
    ),
  }

  return (
    <Box p={2}>
      <Box>
        <Box mb={2}>
          <Typography variant="h5" className={classes.bold}>
            <span>{paymentTitle}</span>
            <span className={classes.normalText}> {paymentFor}</span>
          </Typography>
        </Box>
        <Divider />
        <Box my={2}>
          <NotifyBlock noteText={noteText} noteType={'hotelPayNotify'} />
        </Box>
        <Box>
          <FormControl component="fieldset" className={classes.checkbox}>
            <RadioGroup
              value={fops.primaryFop}
              className={classes.checkbox}
            >
              <Box py={1}>
                <FormControlLabel
                  className={classes.checkbox}
                  value={fops.primaryFop}
                  control={<Radio className={classes.radioInput} color="primary" />}
                  label={(
                    <span>
                      {FopTranslations[fops.primaryFop]}
                    </span>
                  )}
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </Box>
  )
}

export default ProfileCCPayment
