import React, { memo } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TrainMiddleStepCard from './trainMiddlestepCard'
import TrainTotalPrice from './trainTotalPrice'
import { useSelector } from 'react-redux'
import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getPrices } from '../../../../utils/railPricesCalculations'

const useStyles = makeStyles((theme) => ({
  align: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },

  text: {
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'none',
    color: theme.palette.common.black,
  },
}))

const OneWayTrip = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const classes = useStyles()
  const { t } = useTranslation()

  if (isDesktop) {
    return (
      <Box className={classes.align}>
        <Typography variant="body1" className={classes.text}>
          {t('one-way trip')}
        </Typography>
      </Box>
    )
  } else {
    return ''
  }
}

const TrainMiddlestepCard = () => {
  const {
    selectedOutGoingTrip,
    selectedReturnTrip,
    chargeableSeats
  } = useSelector((state) => state.train)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const showMap = useSelector(store => store.hotels.showMap)

  const outgoingPrice = selectedOutGoingTrip.pricing.price?.Amount

  const returningPrice = selectedReturnTrip
    ? selectedReturnTrip.pricing.price?.Amount
    : 0

  const totalPrice = getPrices({selectedOutGoingTrip, selectedReturnTrip, chargeableSeats})

  const roundTrip = !!selectedReturnTrip

  return (isMobile && showMap) || (
    <Box>
      <Grid container style={{ padding: isMobile && '8px' }}>
        <Grid item xs={12} sm={12} md={4}>
          <TrainMiddleStepCard isOutgoing train={selectedOutGoingTrip} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {selectedReturnTrip ? (
            <TrainMiddleStepCard train={selectedReturnTrip} />
          ) : (
              <OneWayTrip />
            )}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TrainTotalPrice totalPrice={totalPrice} roundTrip={roundTrip} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default memo(TrainMiddlestepCard)
