import React, { Fragment, memo } from 'react'
import { Box, Button } from '@material-ui/core'
import numberWithCommas from '../../utils/numberWithCommas.js'
import ModalHook from './reusable/modalHook'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { closeAllModals } from '../../store/modals/modalAction'
import { toFullDate } from '../../utils/formatDates.js'

const useStyles = makeStyles((theme) => ({
  modalInner: {
    padding: '57px 15px',
  },
  header: {
    fontSize: '32px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '37px',
    color: theme.palette.common.black,
    textAlign: 'center',
  },
  textLine: {
    fontSize: '16px',
    color: theme.palette.common.black,
    textAlign: 'center',
    '&.warningTxt': {
      padding: '5px',
      backgroundColor: '#ffe1d6',
    },

    '& .bold': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  btn: {
    borderRadius: '32px',
    margin: theme.spacing(0, 2),
    padding: theme.spacing(2, 5),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
    },
  },
}))

const BookingLinkInfoModal = ({ modalBoolean }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { user } = useSelector((state) => state.auth)
  const { firstName = '', lastName = '', bookingLink = {} } = user || {}
  const { t } = useTranslation()
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = currency === 'EUR' ? 'eur' : 'kr'
  const { earliestDate, latestDate } = bookingLink

  const closeModal = () => {
    return dispatch(closeAllModals())
  }

  return (
    <ModalHook
      height={'auto'}
      minHeight={300}
      mobileFullWidth={false}
      width={450}
      modalBoolean={modalBoolean}
    >
      <Box className={classes.modalInner}>
        <Box className={classes.header}>{t('book your trip')}!</Box>
        <Box mb={3} className={classes.textLine}>
          {t('hello')} {firstName || ''} {lastName || ''},
        </Box>
        <Box className={classes.textLine}>{t('go link book')}:</Box>
        <Box mb={3} className={classes.textLine}>
          <span className={'bold'}>{bookingLink?.emailTitle || ''}</span>
        </Box>
        {!user?.isBooking ? (
          <Fragment>
            <Box mb={3} className={classes.textLine}>
              {t('book until')}{' '}
              <span className={'bold'}>{bookingLink?.bookDate}</span>
            </Box>
            {earliestDate && latestDate && (
              <Box mb={3} className={classes.textLine}>
                {t('bookable dates, between')} <br />
                <span className={'bold'}>{toFullDate(earliestDate)}</span>
                &nbsp;{t('and')}&nbsp;
                <span className={'bold'}>{toFullDate(latestDate)}</span>
              </Box>
            )}
            {earliestDate && !latestDate && (
              <Box mb={3} className={classes.textLine}>
                {t('bookable dates are')} <br />
                {t('from date')}&nbsp;
                <span className={'bold'}>{toFullDate(earliestDate)}</span>
              </Box>
            )}
            {latestDate && !earliestDate && (
              <Box mb={3} className={classes.textLine}>
                {t('bookable dates are')} <br />
                {t('until date')}&nbsp;
                <span className={'bold'}>{toFullDate(latestDate)}</span>
              </Box>
            )}

            {!bookingLink.unlimitedAmount && (
              <Box className={classes.textLine}>
                {t('maximum price')}{' '}
                <span className={'bold'}>
                  {numberWithCommas(bookingLink?.amount || 0)}
                  {currencyCode}
                </span>
              </Box>
            )}
          </Fragment>
        ) : (
          <Box className={`${classes.textLine} warningTxt`}>
            <span>{t('book link in process warning')}</span>
          </Box>
        )}

        <Box mt={'52px'} textAlign={'center'}>
          <Button
            className={classes.btn}
            color="primary"
            variant="contained"
            onClick={closeModal}
          >
            {t('book trip')}
          </Button>
        </Box>
      </Box>
    </ModalHook>
  )
}

export default memo(BookingLinkInfoModal)
