import React, { memo, useEffect, useState } from 'react'
import { intersection } from 'lodash'
import {
  Box,
  Typography,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core'
import CellSeat from './cellSeat'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import usePriceFormat from '../../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  container: {
    // borderRadius: '120px  120px 32px 32px',
    background: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  preloaderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    minHeight: '200px',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  seatsBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  plane: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  iconExit: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '24px',
    width: '8px',
    fontWeight: 'normal',
    fontSize: 9,
    color: '#fff',
    letterSpacing: 0,
    textAlign: 'left',
    background: theme.palette.secondary.main,
    justifyContent: 'center',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  priceGroupBlock: {
    border: `3px solid ${theme.palette.common.black}`,
    borderBottom: 'none',
    position: 'relative',
    marginTop: theme.spacing(1),
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
  },
  pricesBlockText: {
    display: 'inline-block',
    position: 'absolute',
    top: '-13px',
    height: '26px',
    backgroundColor: theme.palette.common.black,
    fontSize: '13px',
    color: 'white',
    padding: '5px 10px',
    fontWeight: 'bold',
    borderRadius: '4px',
  },
}))

const SeatsContainer = ({
  flight,
  passengers,
  activePassengerId,
  selectSeat,
  passengerSeats,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const { formatPrice } = usePriceFormat()

  const renderColumnHeader = flight.cols
    ? flight.cols?.map((column, i) => (
        <Box key={i}>
          <Typography className={classes.weight}> {column}</Typography>
        </Box>
      ))
    : []

  useEffect(() => {
    setLoading(true)
    const timerId = setTimeout(() => setLoading(false), 1000)

    return () => {
      clearTimeout(timerId)
    }
  }, [flight])

  const getRowTooltip = (rIndex) => {
    const { rPricing } = flight

    if (rPricing && rPricing[rIndex]) {
      const prices = rPricing[rIndex]
      let labelText = ''
      const pricesMin = prices.min
      const pricesMax = prices.max
      if (pricesMin === pricesMax && pricesMin === 0) {
        labelText = 'Gratis'
      } else if (pricesMin === pricesMax) {
        labelText = `${formatPrice(pricesMin, { appendCurrency: true })}`
      } else {
        const pricesMinText =
          pricesMin === 0 ? 'Gratis' : formatPrice(pricesMin)
        labelText = `${pricesMinText} - ${formatPrice(pricesMax, {
          appendCurrency: true,
        })}`
      }

      return (
        <div
          key={rIndex.toString() + 'priceBlock'}
          className={classes.priceGroupBlock}
        >
          <span className={classes.pricesBlockText}>{labelText}</span>
        </div>
      )
    }

    return false
  }

  const buildExitIcon = (dir) => {
    const rExitIcon = {
      right: '-16px',
      position: 'absolute',
    }

    const lExitIcon = {
      left: '-16px',
      position: 'absolute',
    }

    const iconStyles = dir === 'r' ? rExitIcon : lExitIcon
    return (
      <Box className="exit-icon-plane" style={iconStyles}>
        <Typography className={classes.iconExit}>E</Typography>
      </Box>
    )
  }

  const createTable = () => {
    //const { selectedSeats, seatsData } = this.props
    const seatsData = flight

    let tableTmp = {}

    for (let j = seatsData?.rows?.first; j <= seatsData?.rows?.last; j++) {
      let tmp = []
      for (let i in seatsData.cols) {
        let nameItem = seatsData.cols[i]
        let seatData = null
        let seatKey = j.toString() + nameItem
        //   let selected = _.findKey(selectedSeats, { seat: seatKey })
        if (typeof seatsData.seats[j.toString() + nameItem] != 'undefined') {
          seatData = seatsData.seats[j.toString() + nameItem]
        }

        let cell = {
          exists: seatData != null,
          price: seatData && seatData.price ? seatData.price : 0,
          userKey: null,
          name: nameItem,
          y: j,
          seat: seatKey,
          busy:
            seatData === null ||
            typeof seatData.isFree === 'undefined' ||
            !seatData.isFree ||
            seatData.seatInfo.indexOf('1') >= 0,
          selected: seatData?.selected,
          seatInfo:
            seatData && seatData.seatInfo && seatData.seatInfo.length > 0
              ? seatData.seatInfo
              : [],
        }
        tmp.push(cell)
      }
      tableTmp[j] = tmp
    }
    return tableTmp
  }

  const renderBody = () => {
    const seatsData = flight

    const rPricing = seatsData?.rPricing

    const table = createTable()
    let bodyTable = []

    for (let rIndex in table) {
      let row = table[rIndex]

      let rowToolTip = false
      if (rPricing && rPricing[rIndex]) {
        rowToolTip = getRowTooltip(rIndex)
        if (rowToolTip) bodyTable.push(rowToolTip)
      }

      let le = ''
      let re = ''
      let rowExtCss = {}
      let ExtraLeg = ['E', 'A', 'EL', 'ER']

      if (
        seatsData.rowDescr &&
        seatsData.rowDescr[rIndex] &&
        seatsData.rowDescr[rIndex].indexOf('Z') >= 0
      ) {
        continue
      }

      if (
        seatsData.rowDescr &&
        seatsData.rowDescr[rIndex] &&
        intersection(ExtraLeg, seatsData.rowDescr[rIndex]).length
      ) {
        rowExtCss['paddingTop'] = '10px'
      }

      if (
        seatsData.rowDescr &&
        seatsData.rowDescr[rIndex] &&
        (seatsData.rowDescr[rIndex].indexOf('E') >= 0 ||
          seatsData.rowDescr[rIndex].indexOf('EL') >= 0)
      ) {
        le = buildExitIcon('l')
      }

      if (
        seatsData.rowDescr &&
        seatsData.rowDescr[rIndex] &&
        (seatsData.rowDescr[rIndex].indexOf('E') >= 0 ||
          seatsData.rowDescr[rIndex].indexOf('ER') >= 0)
      ) {
        re = buildExitIcon('r')
      }

      bodyTable.push(
        <div key={rIndex} className={classes.plane}>
          {le}
          {re}
          {row.map((col, index) => {
            return (
              <CellSeat
                key={index}
                passengers={passengers}
                col={col}
                selectSeat={selectSeat}
                flightKey={flight.flight.uniqueInd}
                passengerSeats={passengerSeats}
              />
            )
          })}
        </div>
      )
    }
    return bodyTable
  }

  const renderExitIcons = () => {
    return ''
  }

  return (
    <>
      {!loading && (
        <Box pt={isMobile ? 3 : 0} p={isMobile ? 0 : 6}>
          <Box p={2} className={classes.container}>
            <Box pt={3} className={classes.align}>
              {renderColumnHeader}
            </Box>
            <Box className={classes.seatsBody} pt={3} pb={3}>
              {renderBody()}
              {renderExitIcons()}
            </Box>
          </Box>
        </Box>
      )}
      {loading && (
        <Box className={classes.preloaderBox}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

export default memo(SeatsContainer)
