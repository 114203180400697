export const getTaxiCompanyFromProduct = (productCode) => {
  const productCodeToCompany = {
    FBUS: 'Flygbuss',
    VTAX: 'Flygtaxi',
    ELIM: 'Flygtaxi',
    TAXI: 'Flygtaxi',
    RAILE: 'ArlandaExpress',
  }

  const companyName = productCodeToCompany[productCode]

  return companyName || 'Flygtaxi'
}

export const getFlightbusLogoFile = (productCode, from, to) => {
  if (productCode === 'FBUS') {
    // Special case for Luleå Lokaltrafik - no identifying feature other than location name
    if (
      from?.trim() == 'Luleå Lokaltrafik' ||
      to.trim() == 'Luleå Lokaltrafik'
    ) {
      return '/assets/companies/LLT.png'
    } else {
      return '/assets/companies/FBUS.png'
    }
  } else {
    return '/assets/companies/ARX.png'
  }
}
