import { setCarSearchParameters } from '../store/search/searchAction'
import { resetState } from '../store/cars/carAction'
import { useState } from 'react'
import { fetchGooglePlaceDetails } from '../repositories/data'
import { carBreadCrumbAction } from '../store/breadcrumbs/breadcrumbsAction'
import { removeAllCheckoutAction } from '../store/checkout/checkoutActions'
import { useSelector } from 'react-redux'
import { carLocationIsValid } from '../utils/carHelpers'

const useCarsHook = ({
  fromDest,
  toDest,
  history,
  i18n,
  startDate,
  endDate,
  dispatch,
  initialDifPlace,
  travellers,
  setIsPopoverOpenFrom = null,
  setIsPopoverOpenTo = null,
  setIsPopoverOpenTraveller = null,
  isMiddlestep = false,
}) => {
  const [diffPlace, setDiffPlace] = useState(initialDifPlace || '')

  const validate = () => {
    if (!carLocationIsValid(fromDest)) {
      if (!!setIsPopoverOpenFrom) setIsPopoverOpenFrom(true)
      return false
    } else if (!!setIsPopoverOpenFrom) {
      setIsPopoverOpenFrom(false)
    }

    if (diffPlace && !carLocationIsValid(toDest)) {
      if (!!setIsPopoverOpenTo) setIsPopoverOpenTo(true)
      return false
    } else if (!!setIsPopoverOpenTo) {
      setIsPopoverOpenTo(false)
    }

    if (travellers.length <= 0) {
      if (!!setIsPopoverOpenTraveller) setIsPopoverOpenTraveller(true)
      return false
    } else if (!!setIsPopoverOpenTraveller) {
      setIsPopoverOpenTraveller(false)
    }

    return true
  }

  const onSubmit = async () => {
    try {
      if (!validate()) return false

      const tmpFrom = { ...fromDest }
      if (!fromDest?.coords?.lat || !fromDest?.coords?.lng) {
        const dataFrom = await fetchGooglePlaceDetails(
          tmpFrom.place_id,
          i18n.language,
          'geometry'
        )
        let { lat, lng } = dataFrom?.geometry?.location || {}
        tmpFrom.coords = { lat, lng }
      }

      let tmpTo = null
      if (
        diffPlace &&
        toDest &&
        (!toDest?.coords?.lat || !toDest?.coords?.lng)
      ) {
        const dataTo = await fetchGooglePlaceDetails(
          toDest.place_id,
          i18n.language,
          'geometry'
        )
        let { lat, lng } = dataTo?.geometry?.location || {}
        tmpTo = { ...toDest, coords: { lat, lng } }
      } else if (diffPlace && toDest) {
        tmpTo = { ...toDest }
      }

      dispatch(resetState())
      if (!isMiddlestep) dispatch(removeAllCheckoutAction())

      if (!startDate || !endDate) return
      const cache = Date.now() + '-' + Math.floor(Math.random() * 10000)
      dispatch(
        setCarSearchParameters(
          tmpFrom,
          tmpTo,
          startDate,
          endDate,
          travellers,
          !!diffPlace
        )
      )

      const newPath = !isMiddlestep
        ? `/cars/search?cache=${cache}`
        : `/cars/add-middlestep?cache=${cache}`
      dispatch(carBreadCrumbAction(newPath))

      if (window.parent !== window) {
        window.parent.postMessage(
          { type: 'searching', mode: 'car', path: newPath },
          '*'
        )
        if (window.location.pathname == '/iframe') return
      }

      history.push(newPath)
    } catch (ex) {}
  }

  return {
    validate,
    diffPlace,
    setDiffPlace,
    onSubmit,
  }
}

export default useCarsHook
