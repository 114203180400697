import { useSelector } from 'react-redux'
import i18n from '../utils/i18n'
import { useCallback, useMemo } from 'react'
import numberWithCommas from '../utils/numberWithCommas'

const usePriceFormat = () => {
  const { currency, currencies, selectedCurrency } = useSelector(
    (state) => state.auth
  )
  const locale = i18n.languages[0] || 'sv-SE'

  //   console.log(currency)
  //   console.log(currencies)
  //   console.log(locale)
  //   console.log(selectedCurrency)

  /**
   * Formats a price value based on the selected currency and locale.
   *
   * @param {number|null} price - The price value to format.
   * @return {string|null} The formatted price value as a string, or null if the price is null.
   */
  const formatPrice = useCallback(
    (price, options = {}) => {
      //   console.log('price formatter created')
      if (price == null) return null

      const {
        prependCurrency = false,
        appendCurrency = false,
        withFractions = false,
      } = options

      if (
        !selectedCurrency ||
        selectedCurrency === currency ||
        !currencies?.[selectedCurrency]
      ) {
        if (appendCurrency) return numberWithCommas(price) + ' ' + currency
        if (prependCurrency) return currency + ' ' + price
        return numberWithCommas(price)
      }

      const rate = currencies[selectedCurrency]
      const convertedPrice = withFractions
        ? Number(price * rate).toFixed(2)
        : Math.floor(price * rate)

      //   const formattedPrice = new Intl.NumberFormat(locale, {
      //     style: 'currency',
      //     currency: selectedCurrency,
      //   }).format(price * rate)

      if (appendCurrency)
        return numberWithCommas(convertedPrice) + ' ' + selectedCurrency
      if (prependCurrency) return selectedCurrency + ' ' + convertedPrice
      return numberWithCommas(convertedPrice)
    },
    [currency, currencies, selectedCurrency, locale]
  )

  /**
   * Current formatted selected currency.
   */
  const formattedCurrency = useMemo(() => {
    if (!selectedCurrency) return currency
    return selectedCurrency
  }, [currency, selectedCurrency])

  /**
   * Current exchange rate.
   */
  const exchangeRate = useMemo(() => {
    if (currencies?.[selectedCurrency]) {
      return Number(currencies[selectedCurrency]).toFixed(2)
    }
    return 1
  }, [currency, selectedCurrency])

  return { formattedCurrency, formatPrice, exchangeRate }
}

export default usePriceFormat
