import {
  fetchOutgoingCities,
  removeAccommodations,
  removeSelectedOutgoingTrain,
  setOutgoingCities,
  removeSelectedReturningTrain,
  isLoading,
} from '../store/train/trainAction'
import { removeAllCheckoutAction } from '../store/checkout/checkoutActions'
import { setTrainSearchParameters } from '../store/search/searchAction'
import { toFullDate, toHoursAndMinutes } from '../utils/formatDates'
import React, { useCallback, useState } from 'react'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { Typography } from '@material-ui/core'
import { setMapCenter } from '../store/hotels/hotelsAction'
import { buildUsersByCount } from '../utils/general'
import { useSelector } from 'react-redux'

const useTrains = ({
  from,
  to,
  departure,
  arrival,
  travellers,
  isOneWay,
  dispatch,
  history,
  setIsPopoverOpenFrom,
  setIsPopoverOpenTo,
  setIsPopoverOpenTraveller,
  forceNewSearch = true,
  isExtendingFromCheckout = false,
}) => {
  const searchData = useSelector((state) => state.search)
  const { isAuthenticated, trainSearch, user } = useSelector(
    (state) => state.auth
  )
  const { train: trainSearchData } = searchData
  const initCounts = trainSearchData?.travelerCounts || {}

  const allowedDestination = user?.bookingLink?.allowedTrainDestination

  const [counts, setCounts] = useState({
    adult: {
      count: initCounts?.adult?.count || 1,
      title: 'adult',
    },
    child: {
      count: initCounts?.child?.count || 0,
      title: 'child',
    },
  })

  const modifyCounts = useCallback(
    (type = 'adults', action = 'increment') => {
      if (!counts?.[type]) return false
      let tmpCounts = { ...counts }
      if (action === 'increment') {
        tmpCounts[type].count++
      } else if (tmpCounts[type].count > 0) {
        tmpCounts[type].count--
      }
      setCounts(tmpCounts)
    },
    [counts]
  )

  const getCities = useCallback(
    (value, isTo) => {
      if (isTo && allowedDestination) {
        let destinations = Array.isArray(allowedDestination)
          ? allowedDestination.map((d) => d.destination)
          : [allowedDestination.destination]
        dispatch(setOutgoingCities(destinations))
      } else {
        dispatch(fetchOutgoingCities(value))
      }
    },
    [dispatch, allowedDestination]
  )

  const getTravelers = () => {
    return isAuthenticated ? travellers : buildUsersByCount(counts)
  }

  const isSearchDataValid = () => {
    try {
      const tmpTravelers = getTravelers()
      return !!from && !!to && !!departure && tmpTravelers?.length > 0
    } catch (e) {
      return false
    }
  }

  const saveSearchParams = () => {
    const tmpTravelers = getTravelers()
    if (!from || !to || !departure || tmpTravelers.length === 0) return false

    const extendCheckout =
      trainSearchData?.extendCheckout || isExtendingFromCheckout
    dispatch(
      setTrainSearchParameters(
        from,
        to,
        departure,
        arrival,
        tmpTravelers,
        counts,
        !forceNewSearch && !!extendCheckout
      )
    )

    return true
  }

  // Submit
  const onSubmit = () => {
    if (!from) {
      setIsPopoverOpenFrom(true)
    } else {
      setIsPopoverOpenFrom(false)
    }

    if (!to) {
      setIsPopoverOpenTo(true)
    } else {
      setIsPopoverOpenTo(false)
    }

    const tmpTravelers = isAuthenticated
      ? travellers
      : buildUsersByCount(counts)

    if (tmpTravelers.length === 0) {
      setIsPopoverOpenTraveller(true)
    } else {
      setIsPopoverOpenTraveller(false)
    }

    const paramsSaved = saveSearchParams()
    if (!paramsSaved) return

    // Set loading early for checkout search to prevent "No trips found"
    dispatch(isLoading(true))

    // if (!from && !to && !to && !departure) return
    dispatch(removeSelectedOutgoingTrain())
    dispatch(removeSelectedReturningTrain())

    const extendCheckout = trainSearchData?.extendCheckout
    if (forceNewSearch || !extendCheckout) {
      dispatch(removeAllCheckoutAction())
    }

    dispatch(removeAccommodations())
    dispatch(setMapCenter(null))
    dispatch(setOutgoingCities([]))

    const dep = toFullDate(departure)
    const oneWay = isOneWay === true ? '0' : '1'
    const depTimeOutboundSplit = toHoursAndMinutes(departure).split(':')
    const depTimeInboundSplit = toHoursAndMinutes(arrival).split(':')

    const depTimeOutbound =
      depTimeOutboundSplit.length &&
      `${depTimeOutboundSplit[0]}${depTimeOutboundSplit[1]}`
    const depTimeInbound =
      depTimeInboundSplit.length &&
      `${depTimeInboundSplit[0]}${depTimeInboundSplit[1]}`
    // Arrival gets a 0 if singleTrip
    const arr = toFullDate(arrival)
    let uri = `/trains/outgoing/${from['Location Code']}/${
      to['Location Code']
    }/${dep}/${isOneWay ? 0 : arr}/${oneWay}`

    if (trainSearch === 'custom')
      uri = `${uri}/${depTimeOutbound}/${depTimeInbound}`

    if (window.parent !== window) {
      window.parent.postMessage(
        { type: 'searching', mode: 'train', path: uri },
        '*'
      )
      if (window.location.pathname == '/iframe') return
    }

    history.push(uri)
  }

  const optionLabel = (options) => {
    return options['Station Name'] ? options['Station Name'] : ''
  }

  const renderOption = (option, { inputValue }) => {
    const matches = match(option['Station Name'], inputValue)
    const parts = parse(option['Station Name'], matches)

    return (
      <>
        <Typography>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </Typography>
      </>
    )
  }

  return {
    getCities,
    onSubmit,
    optionLabel,
    renderOption,
    modifyCounts,
    counts,
    saveSearchParams,
    isSearchDataValid,
  }
}

export default useTrains
