import React, { memo, useEffect, useState } from 'react'
import {
  Paper,
  Container,
  Typography,
  Box,
  Button,
  Grid,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  fetchTrainAccommodations,
  fetchOutgoingCities,
  fetchTrainSeatsMap,
  getTrainMiddleStepSettingsAction,
  setOutgoingCities,
} from '../../store/train/trainAction'
import { getPrices } from '../../utils/railPricesCalculations'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { addToCheckoutAction } from '../../store/checkout/checkoutActions'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  fetchHotelRatingsAction,
  fetchHotelSearchAction,
  resetHotelFilters,
  setHotelGuestsAction,
} from '../../store/hotels/hotelsAction'
import moment from 'moment'
import { dmsToDd } from '../../utils/dmsToDd'
import EditPassengersGender from '../../containers/modals/editPassengersGender'
import ChooseAccommodationContainer from '../../containers/trains/trainContainers/chooseAccommodation/chooseAccommodation'
import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import DesktopDetailsHeader from '../../containers/header/desktopDetailsHeader/desktopDetailsHeader'
import { setBreadcrumbType } from '../../store/breadcrumbs/breadcrumbsAction'
import isSameDay from '../../utils/isSameDay'
import { useTranslation } from 'react-i18next'
import { setHotelSearchParameters } from '../../store/search/searchAction'
import { fetchCityBasedOnCoordinates } from '../../repositories/hotel'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'
import { fetchTrainCityByCode } from '../../repositories/trains'
import { closeAllModals } from '../../store/modals/modalAction'

const useStyles = makeStyles((theme) => ({
  paper: { borderRadius: '12px' },
  align: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    '& > *': {
      margin: '0 10px',
      marginLeft: '0',
    },
  },
  opacity: { opacity: '0.6' },
  checkoutBtn: {
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: '50px',
    margin: '0 auto',
    fontSize: '18px',
    lineHeight: 'normal',
    padding: theme.spacing('23px', 6),
    letterSpacing: 1.3,
  },
  goBackBtn: { position: 'absolute', left: 0 },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  noTransform: {
    textTransform: 'none',
    color: theme.palette.common.black,
    fontWeight: 500,
  },
}))

const ChooseSeats = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const accommodations = useSelector((state) => state.train.accommodations)
  const {
    selectedOutGoingTrip,
    selectedReturnTrip,
    seats,
    specificAccommodationSelected,
    middleStepSettings,
    chargeableSeats,
  } = useSelector((state) => state.train)
  const { outGoingCity } = useSelector((state) => state.train)
  const searchState = useSelector((state) => state.search)
  const {
    travellers,
    from: searchFrom,
    to: searchTo,
    extendCheckout,
  } = searchState.train
  const [accommodationItems, setAccommodationItems] = useState([])
  const [loadingMiddle, setLoadingMiddle] = useState(false)
  const [selectGender, setSelectGender] = useState(false)
  const { isHotelsEnabled } = useEnabledBookingTypesHook()

  const { hotels: hotelsSearchData } = searchState

  const totalPrice = getPrices({
    selectedOutGoingTrip,
    selectedReturnTrip,
    chargeableSeats,
  })

  const formatdate = (date) => moment(date).format('YYYY-MM-DD')

  const forwardDate = (date) => moment(date).add(1, 'days').format('YYYY-MM-DD')

  const hotelCheckOut = selectedReturnTrip
    ? formatdate(
        selectedReturnTrip?.train?.scheduleSolution?.railstart?.dateTime
      )
    : forwardDate(selectedOutGoingTrip.train.scheduleSolution.railend.dateTime)

  const useMiddleStep =
    middleStepSettings?.settings?.useMiddlestep && isHotelsEnabled

  useEffect(() => {
    dispatch(
      fetchTrainAccommodations({ selectedOutGoingTrip, selectedReturnTrip })
    )
    // dispatch(fetchTrainSeatsMap(selectedOutGoingTrip, selectedReturnTrip))

    dispatch(getTrainMiddleStepSettingsAction())

    setAccommodationItems(
      [
        selectedOutGoingTrip && 'outgoing',
        selectedReturnTrip && 'returning',
      ].filter((item) => !!item)
    )
  }, [dispatch, selectedOutGoingTrip, selectedReturnTrip])

  useEffect(() => {
    dispatch(setBreadcrumbType('trains'))
    dispatch(closeAllModals())
  }, [dispatch])

  useEffect(() => {
    const fetchOutgoing = async () => {
      // console.log(selectedOutGoingTrip?.train?.scheduleSolution?.railend)
      const station = await fetchTrainCityByCode(
        selectedOutGoingTrip?.train?.scheduleSolution?.railend.raillocationCode
      )
      // console.log(station)
      if (station) {
        dispatch(setOutgoingCities([station]))
      }

      // const string =
      //   selectedOutGoingTrip?.train?.scheduleSolution?.railend?.locationName
      // const destination = string ? string.replace(/ .*/, '') : ''
      // dispatch(fetchOutgoingCities(destination))
    }

    fetchOutgoing()
      .then(() => console.log('outgoing city loaded'))
      .catch((error) => console.error())
  }, [dispatch, selectedOutGoingTrip])

  useEffect(() => {
    if (outGoingCity?.length && useMiddleStep) {
      const city = outGoingCity[0]
      const { train } = selectedOutGoingTrip
      const countryCode = city['Country Code']

      const checkIn = formatdate(train.scheduleSolution.railend.dateTime)
      const latitude = dmsToDd(city.lat)
      const longitude = dmsToDd(city.lng)

      const data = {
        countryCode: countryCode,
        lng: longitude,
        lat: latitude,
        checkIn: checkIn,
        checkOut: hotelCheckOut,
        guests: travellers.length <= 3 ? String(travellers.length) : '3',
        roomCount: '1',
      }

      dispatch(
        fetchHotelRatingsAction({
          lat: latitude,
          lng: longitude,
          countryCode,
        })
      )
      dispatch(fetchHotelSearchAction(data))
    }
  }, [
    dispatch,
    outGoingCity,
    selectedReturnTrip,
    selectedOutGoingTrip,
    travellers.length,
    hotelCheckOut,
  ])

  const addToCheckout = async (genders = {}) => {
    setSelectGender(false)
    if (!!loadingMiddle) return false

    try {
      setLoadingMiddle(true)
      const formatdate = (date) => moment(date).format('YYYY-MM-DD')

      // Add to checkout here and add to basket

      // add to checkout here

      const citySV = await fetchCityBasedOnCoordinates({
        lat: dmsToDd(searchTo.lat),
        lng: dmsToDd(searchTo.lng),
        lang: 'sv',
      })
      const cityEN = await fetchCityBasedOnCoordinates({
        lat: dmsToDd(searchTo.lat),
        lng: dmsToDd(searchTo.lng),
        lang: 'en',
      })

      const payload = {
        type: 'Train',
        accommodations: {},
        city: [cityEN, citySV],
        searchCodes: {
          from: searchFrom.UIC,
          fromCoords: {
            lat: dmsToDd(searchFrom.lat),
            lng: dmsToDd(searchFrom.lng),
          },

          to: searchTo.UIC,
          toCoords: { lat: dmsToDd(searchTo.lat), lng: dmsToDd(searchTo.lng) },
        },
        specificAccommodationSelected: specificAccommodationSelected,
        correlationID: selectedOutGoingTrip.train.correlationID,
        outboundTrip: selectedOutGoingTrip,
        passengers: travellers.map((p) => ({
          ...p,
          ...(!!genders?.[p?.uniqueId] ? { gender: genders[p.uniqueId] } : {}),
        })),
        totalPrice: totalPrice,
        seats: seats,
        co2:
          selectedOutGoingTrip.train.co2 +
          (selectedReturnTrip?.train?.co2 || 0),
      }

      if (selectedReturnTrip) {
        payload.returnTrip = selectedReturnTrip
      }

      dispatch(addToCheckoutAction(payload))

      if (useMiddleStep && !extendCheckout) {
        if (!outGoingCity.length) {
          console.log('no city found - skipping middlestep')
          const url = `/checkout`
          return history.push(url)
        }

        const city = outGoingCity[0]
        const { train } = selectedOutGoingTrip
        const checkIn = formatdate(train.scheduleSolution.railend.dateTime)
        const latitude = dmsToDd(city.lat)
        const longitude = dmsToDd(city.lng)

        const sameDay = isSameDay(checkIn, hotelCheckOut)

        if (sameDay) {
          return history.push('/checkout')
        }

        // set hotel search data
        const { cityValue, city: hotelCity } = hotelsSearchData

        dispatch(
          setHotelSearchParameters(
            cityValue,
            hotelCity,
            checkIn,
            hotelCheckOut,
            [...travellers],
            travellers.length,
            { beds: { count: 1 } }
          )
        )
        dispatch(resetHotelFilters())
        dispatch(setHotelGuestsAction([...travellers]))

        const url = `/middlestep/trains/SE/${latitude}/${longitude}/${checkIn}/${hotelCheckOut}/${travellers.length}/${travellers.length}`
        setLoadingMiddle(false)
        return history.push(url)
      } else {
        const url = `/checkout`
        setLoadingMiddle(false)
        return history.push(url)
      }
    } catch (e) {
      setLoadingMiddle(false)
    }
  }

  const preSubmit = () => {
    if (!!loadingMiddle) return false
    const berthTypes = ['SlCouchette', 'SlShared', 'SlSingle']
    let hasBerth = false

    try {
      const sTypes = {
        ...(selectedOutGoingTrip?.pricing?.seatTypes || {}),
        ...(selectedReturnTrip?.pricing?.seatTypes || {}),
      }
      for (let key in sTypes) {
        if (berthTypes.includes(sTypes[key])) hasBerth = true
      }
    } catch (e) {}

    if (hasBerth) {
      setSelectGender(true)
    } else {
      addToCheckout()
    }
  }

  const renderTravelDetails = accommodationItems.map((key, i) => {
    const trainDetails = accommodations && accommodations[key]

    return (
      <Box pt={i === 0 ? 0 : 1} pb={1} key={key}>
        <Paper className={classes.paper} elevation={0}>
          <ChooseAccommodationContainer trainDetails={trainDetails} index={i} />
        </Paper>
      </Box>
    )
  })

  const getContent = () => {
    return renderTravelDetails
  }

  return (
    <>
      {!isDesktop ? (
        <MobileHeader tripPrice={totalPrice} />
      ) : (
        <DesktopDetailsHeader tripPrice={totalPrice} />
      )}

      <Container maxWidth="lg" disableGutters>
        <Box
          mt={!isDesktop ? 2 : 4}
          pl={!isDesktop ? 1 : 0}
          pr={!isDesktop ? 1 : 0}
          pb={12}
        >
          {getContent()}
          <Grid container>
            <Grid item xs={12}>
              <Box
                mt={!isDesktop ? 1 : 2}
                mb={!isDesktop ? 40 : 0}
                className={classes.btnContainer}
              >
                {!loadingMiddle && (
                  <Button
                    className={classes.goBackBtn}
                    disableElevation
                    startIcon={<ArrowBackIcon />}
                    color="primary"
                    onClick={() => history.goBack()}
                  >
                    <Typography className={classes.noTransform}>
                      {t('back')}
                    </Typography>
                  </Button>
                )}

                <Button
                  fullWidth={!isDesktop}
                  disableElevation
                  display="flex"
                  align="center"
                  color="primary"
                  variant="contained"
                  onClick={preSubmit}
                  className={classes.checkoutBtn}
                >
                  {!loadingMiddle ? t('continue') : t('booking wait')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <EditPassengersGender
        passengers={travellers}
        modalBoolean={selectGender}
        onSave={addToCheckout}
      />
    </>
  )
}
export default memo(ChooseSeats)
