import constants from './constants'

const initialState = {
  /* Seat Rervation */
  flightSeatReservation: false,
  /* Booking in Proccess */
  bookingProcess: false,
  /* Transfer Modal */
  transferModal: false,
  /* Cancel Booking Modal */
  cancelBooking: false,
  /* Single hotel image modal */
  hotelImageModal: false,
  /* Log out modal */
  logoutModal: false,
  /* Log out modal */
  loginModal: false,
  /* booking link info modal */
  bookingLinkInfoModal: false,
  /* Modal for users selection to login via Agent */
  selectAgentAuthUserModal: false,
  /* Right side drawer */
  checkoutItemsSideDrawer: false,
  /* Edit info modal */
  editInfoModal: {
    state: false,
    rows: {
      rows: [],
    },
  },
  /* Booking part cancellation modal */
  bookingPartCancel: false,
  /* Employees user modal */
  employeesUserModal: {
    state: false,
    user: {},
  },
  /* Deactivate user modal */
  deactivateUserModal: {
    state: false,
    user: {},
  },
  /* */
  editUserModal: false,
  activateUserModal: false,
  /* Policy exceeded modal, preventing booking */
  policyExceededModal: {
    state: false,
    allowReasons: false,
    clickHandler: null,
  },
  registrationModal: false,
  simpleInfoData: false,
  loginWithCompanyModal: {
    state: false,
    email: null,
    password: null,
    companies: [],
    agency: null,
  },
  addMobileModal: false,
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.OPEN_LOGIN_WITH_COMPANY_MODAL:
      return {
        ...state,
        loginWithCompanyModal: {
          state: true,
          email: payload.email,
          password: payload.password,
          companies: payload.companies,
          agency: payload.agency,
        },
      }

    case constants.OPEN_SEAT_RESERVATION_MODAL:
      return {
        ...state,
        flightSeatReservation: true,
      }

    case constants.TOGGLE_BOOKING_IN_PROCESS:
      return {
        ...state,
        bookingProcess: !!payload,
      }

    case constants.OPEN_TRANSFER_MODAL:
      return { ...state, transferModal: true }

    case constants.OPEN_BOOKING_LINK_INFO_MODAL:
      return { ...state, bookingLinkInfoModal: true }

    case constants.OPEN_CANCEL_BOOKING_MODAL:
      return { ...state, cancelBooking: !!payload ? payload : true }

    case constants.OPEN_SINGLE_HOTEL_IMAGE_MODAL:
      return {
        ...state,
        hotelImageModal: true,
      }
    case constants.OPEN_LOGOUT_MODAL:
      return {
        ...state,
        logoutModal: true,
      }
    case constants.OPEN_LOGIN_MODAL:
      return {
        ...state,
        loginModal: true,
      }

    case constants.OPEN_EDIT_INFO_MODAL:
      return {
        ...state,
        editInfoModal: {
          state: true,
          rows: payload,
        },
      }
    case constants.OPEN_ADD_USERS_MODAL:
      return {
        ...state,
        editUserModal: true,
      }

    case constants.OPEN_EMPLOYEES_USER_MODAL:
      return {
        ...state,
        employeesUserModal: {
          state: true,
          user: payload,
        },
      }

    case constants.OPEN_DEACTIVATE_USER_MODAL:
      return {
        ...state,
        deactivateUserModal: {
          state: true,
          user: payload,
        },
      }
    case constants.OPEN_ACTIVATE_USER_MODAL:
      return {
        ...state,
        activateUserModal: true,
      }

    case constants.OPEN_POLICY_EXCEEDED_MODAL:
      return {
        ...state,
        policyExceededModal: {
          state: true,
          allowReasons: payload?.allowReasons,
          clickHandler: payload?.clickHandler,
        },
      }

    case constants.OPEN_REGISTRATION_MODAL:
      return {
        ...state,
        registrationModal: true,
      }
    case constants.SET_BOOKING_PART_CANCEL_MODAL_DATA:
      return {
        ...state,
        bookingPartCancel: payload || false,
      }
    case constants.OPEN_AGENT_AUTH_USER_MODAL:
      return {
        ...state,
        selectAgentAuthUserModal: !!payload,
      }
    case constants.OPEN_SIMPLE_INFO_MODAL:
      return {
        ...state,
        simpleInfoData: payload,
      }
    case constants.TOGGLE_CHECKOUT_SIDE_DRAWER:
      return {
        ...state,
        checkoutItemsSideDrawer: !!payload,
      }

    case constants.CLOSE_ALL_MODALS: {
      const { excepts = [] } = payload
      const exceptState = {}
      excepts.forEach((key) => {
        if (typeof state[key] != 'undefined') {
          exceptState[key] =
            typeof state[key] === 'object' ? { ...state[key] } : state[key]
        }
      })
      return { ...initialState, ...exceptState }
    }

    case constants.OPEN_ADD_MOBILE_MODAL: {
      return {
        ...state,
        addMobileModal: true,
      }
    }
    default:
      return state
  }
}
