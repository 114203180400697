import React, {useCallback, useEffect, useState} from 'react'
import ModalHook from './reusable/modalHook'
import {setAgentAuthUser} from '../../store/auth/authAction'
import {closeAllModals} from '../../store/modals/modalAction'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Button, useTheme
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import SearchFieldForAgent from '../../components/reusable/searchFieldForAgent'
import {getAgentCompanies} from '../../repositories/data'
import {fetchUsersForAgent} from '../../repositories/users'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloseIcon from '@material-ui/icons/Close'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 15px 40px 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%'
  },
  topRow: {
    display: 'flex',
    justifyContent: 'right',
    marginBottom: '20px',
    width: '100%'
  },
  closeBtn: {
    height: '20px',
    cursor: 'pointer',
    margin: '8px 8px 0 0',
    color: theme.palette.primary.main
  },
  bodyBlock: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  headerBlock: {
    marginBottom: '20px'
  },
  headerLine: {
    fontSize: '30px',
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
    textAlign: 'center',
    marginBottom: '25px',

    '&:last-child': {
      marginBottom: '0',
    }
  },
  subHeaderLine: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightRegular
  },
  inputRow: {
    width: '100%',
    marginBottom: '15px',

    '&:last-child': {
      marginBottom: 0
    }
  },
  doneBtn: {
    fontSize: '17px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '1.1px',
    width: '206px',
    height: '50px',
    borderRadius: '60px',
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none'
    }
  },
  btnBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px'
  },
}))

const selectAgentAuthUserModal = ({ modalBoolean }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [company, setCompany] = useState(null)
  const [user, setUser] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user:authUser, isAuthenticated, authType } = useSelector((state) => state.auth)
  const ATypesForAgent = ['agent', 'viaAgent']

  const isAgent = !!isAuthenticated && ATypesForAgent.includes(authType)
  let aCustomerType = t(`customer`)
  if (isAgent && authUser?.agentType === 'group') {
    aCustomerType = t(`co-worker`)
  }

  useEffect(() => {
    setUser(null)
  }, [company])

  const getCompanies = useCallback(async (name) => {
    return await getAgentCompanies(name)
  }, [])
  const renderCompanyOption = useCallback((option, { inputValue }) => {
    const nameMatches = match(option.name, inputValue, {insideWords: true})
    const nameParts = parse(option.name, nameMatches)

    return (
      <Box>
        {nameParts.map((part, index) => (
          <span
            key={index}
            style={{ fontWeight: part.highlight ? 700 : 400 }}
          >
              {part.text}
            </span>
        ))}
      </Box>
    )
  }, [])

  const renderCompanyLabel = useCallback(option => option?.name, [])
  const checkSelectedCompany = useCallback((option, value) => option?.uniqueId === value?.uniqueId, [])

  const getUsers = useCallback(async (name) => {
    if (!company?.uniqueId) return []
    return await fetchUsersForAgent(company?.uniqueId, name)
  }, [company])

  const renderUserOption = useCallback((option, { inputValue }) => {
    const name = (option?.firstName || '') + ' ' + (option?.lastName || '')
    const nameMatches = match(name, inputValue, {insideWords: true})
    const nameParts = parse(name, nameMatches)
    return (
      <Box>
        {nameParts.map((part, index) => (
          <span
            key={index}
            style={{ fontWeight: part.highlight ? 700 : 400 }}
          >
              {part.text}
            </span>
        ))} ({option?.email})
      </Box>
    )
  }, [])
  const renderUserLabel = useCallback(option => `${option?.firstName} ${option?.lastName}`, [])
  const checkSelectedUser = useCallback((option, value) => option?.email === value?.email, [])

  const setUserToken = async () => {
    if (!user?.token) return false
    await dispatch(setAgentAuthUser(user.token))
    setCompany(null)
    setUser(null)
    history.push('/')
    dispatch(closeAllModals())
  }

  return (
    <ModalHook height={520} modalBoolean={modalBoolean} width={500} noBackdrop>
      <Box className={classes.root}>
        <Box className={classes.topRow}>
          <CloseIcon onClick={() => dispatch(closeAllModals())} className={classes.closeBtn}/>
        </Box>
        <Box px={isMobile ? 0 : 5} className={classes.bodyBlock}>
          <Box className={classes.headerBlock}>
            <Box className={classes.headerLine}>{t('act as customer', {type: aCustomerType})}</Box>
            <Box className={`${classes.headerLine} ${classes.subHeaderLine}`}>{t('via agent modal semi header')}</Box>
          </Box>
          <Box className={classes.inputRow} width={''}>
            <SearchFieldForAgent
              autoFocus = {!company?.uniqueId}
              setValue={setCompany}
              value={company}
              fetchOptions={getCompanies}
              renderOption={renderCompanyOption}
              getOptionLabel={renderCompanyLabel}
              checkSelectedOpt={checkSelectedCompany}
              placeholder={t('select company')}
              label={t('company')}
              FieldIcon={AddCircleIcon}
            />
          </Box>
          {!!company?.uniqueId &&
            <Box className={classes.inputRow} width={'100%'}>
              <SearchFieldForAgent
                autoFocus
                setValue={setUser}
                value={user}
                fetchOptions={getUsers}
                renderOption={renderUserOption}
                getOptionLabel={renderUserLabel}
                checkSelectedOpt={checkSelectedUser}
                placeholder={t('add traveler')}
                label={t('traveler')}
                FieldIcon={PersonAddIcon}
              />
            </Box>
          }
        </Box>
        <Box className={classes.btnBlock}>
          <Button className={classes.doneBtn} disabled={!user?.token} onClick={setUserToken} variant="contained" color="primary"> {t('done')} </Button>
        </Box>
      </Box>
    </ModalHook>
  )
}

export default selectAgentAuthUserModal
