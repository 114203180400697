import React, { memo } from 'react'
import Box from '@material-ui/core/Box'
import EventIcon from '@material-ui/icons/Event'
import {
  Button,
  Container,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },

  eventIcon: {
    height: '56px',
    width: 'auto',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
    color: theme.palette.common.black,
  },

  btn: {
    fontWeight: 600,
    padding: theme.spacing(1, 4),
    letterSpacing: 1.1,
    backgroundColor: theme.palette.primary.medium,
    color: theme.palette.primary.dark,
  },

  noTrips: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(4),
    margin: '0 auto 28px',
    lineHeight: theme.spacing(0.15),
  },

  noTripsMobile: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(4),
    margin: '0 auto 17px',
    lineHeight: theme.spacing(0.15),
  },

  noTripsDesc: {
    width: '280px',
    margin: '0 auto',
  },
}))

const NoTrips = ({ past = false }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const startPage = () => history.push('/flights')

  const title = past ? t('booking title one') : t('booking title two')
  const message = past ? t('booking mess one') : t('booking mess two')

  return (
    <Box className={classes.root}>
      <Paper elevation={0}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={4}
        >
          <EventIcon className={classes.eventIcon} />

          <Container fixed>
            <Typography
              gutterBottom
              align="center"
              className={classes.noTrips}
            >
              {title}
            </Typography>
          </Container>

          <Typography
            gutterBottom
            align="center"
            className={!isMobile ? classes.noTripsDesc : ''}
          >
            {message}
          </Typography>

          <Box onClick={startPage} mt={4} mb={4}>
            <Button className={classes.btn}>{t('mth emty trip btn')}</Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
export default memo(NoTrips)
