import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'
import moment from 'moment'
import FlightIcon from '@material-ui/icons/Flight'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import airplaneModels from '../../../constants/airplaneModels.json'
import flightCompanies from '../../../constants/flightCompanies'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { formatDuration } from '../../../utils/formatDurations'

const useStyles = makeStyles((theme) => ({
  aircraftInfo: {
    minHeight: '40px',
    maxWidth: '230px',
    borderRadius: '20px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    color: '#212121',
    padding: '10px 15px',
    backgroundColor: '#F9F9F9',
  },

  flightIcon: {
    position: 'absolute',
    left: '-12px',
    transform: 'rotate(45deg)',
    backgroundColor: 'white',
  },

  flightIconRight: {
    transform: 'rotate(45deg)',
    fontSize: '14px',
    marginRight: '10px',
  },

  depRow: {
    display: 'flex',
    alignItems: 'center',
  },

  providerInfo: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: '#212121',
  },

  logoBlock: {
    width: '57px',
    padding: '0 5px',
  },

  providerBlock: {
    flex: 1,
  },

  iconBlock: {
    lineHeight: '1px',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '23px',
  },

  lineBlock: {
    width: '1px',
    backgroundColor: '#5cdd86',
    flex: 1,
  },

  circleIcon: {
    display: 'inline-block',
    height: '7px',
    width: '7px',
    borderRadius: '14px',
    border: '1px solid #5cdd86',
  },

  tripInfoBlock: {
    minHeight: '70px',
    marginLeft: '26px',
    borderLeft: '1px solid #5cdd86',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '26px',
    position: 'relative',
  },

  changeInfoBlock: {
    minHeight: '40px',
    border: 'none',
    borderTop: `1px solid ${theme.palette.border}`,
    borderBottom: `1px solid ${theme.palette.border}`,
    margin: '20px 0',
    paddingLeft: 0,
  },

  dotIcon: {
    display: 'inline-block',
    height: '4px',
    width: '4px',
    backgroundColor: 'black',
    borderRadius: '10px',
  },

  descriptionBlock: {
    flex: 1,
    paddingLeft: '25px',
    lineHeight: '18px',
  },

  schemeOuter: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px',
    width: '100%',
  },

  schemeHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '15px 20px',
    borderBottom: `1px solid ${theme.palette.border}`,
  },

  schemeInner: {
    padding: '25px',
    alignSelf: 'stretch',
  },
}))

// takes an array of flights and produces a timeline
const FlightTimeLine = ({ flight, smallPadding = false }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { i18n, t } = useTranslation()
  const [logosLoaded, setLogosLoaded] = useState({})
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMd = useMediaQuery(theme.breakpoints.down('md')) && !isMobile

  const showLogo = (s) => {
    const code = s?.company || null
    if (!code) return false
    setLogosLoaded({ ...logosLoaded, [code]: true })
  }
  const getAircraft = (s) => {
    return (
      airplaneModels.find((plane) => plane.iata === s?.equipmentType || '')
        ?.model || s?.equipmentType
    )
  }

  const getLogoUrl = (s) => {
    const code = s?.company || ''
    return `/assets/companies/${code}.png`
  }

  const getProviderName = (s) => {
    const code = s?.company || ''
    return flightCompanies?.[code]?.label || code
  }

  const getChangePlace = (index) => {
    const segments = flight?.via || []
    if (index <= 0 || !segments?.[index - 1]) return ''
    const s = segments[index - 1]

    return `${s.toCity} (${s.to})`
  }

  const getChangeTime = (index) => {
    const segments = flight?.via || []
    if (index <= 0 || !segments?.[index - 1] || !segments?.[index]) return 0
    const curr = moment(segments?.[index]?.departure)
    const prev = moment(segments?.[index - 1]?.arrival)

    if (!curr.isValid() || !prev.isValid()) return 0

    const mins = curr.diff(prev, 'minutes')
    if (mins <= 0) return 0
    const diffHrs = curr.diff(prev, 'hours')
    const diffMin = mins - diffHrs * 60

    return `${diffHrs > 0 ? diffHrs + 'h' : ''} ${
      diffMin > 0 ? diffMin + 'm' : ''
    }`
  }

  const getTimeText = (dir = null, type = 'time') => {
    const startDate = flight?.departure
    const endDate = flight?.arrival
    const sMoment = moment(startDate)
    const eMoment = moment(endDate)
    if (!sMoment.isValid() || !eMoment.isValid()) return ''

    const format = type === 'time' ? 'HH:mm' : 'ddd D MMMM'
    if (dir === 'dep') return `${sMoment.format(format)}`
    if (dir === 'dest') return `${eMoment.format(format)}`
    return `${sMoment.format(format)} - ${eMoment.format(format)}`
  }

  const getTimeFromDate = (date) => {
    const momentDate = moment(date)
    if (!momentDate.isValid()) return ''
    return momentDate.format('HH:mm')
  }

  const schemeBlock = (
    <Box key="trip-scheme-block" className={classes.root}>
      {(flight.via || []).map((s, i) => (
        <Box key={`segment-outer-${i}`}>
          {i > 0 && (
            <Box className={classes.depRow}>
              <Box
                key="change-trip-block"
                width={'100%'}
                className={`${classes.tripInfoBlock} ${classes.changeInfoBlock}`}
              >
                <span key="cahnge-time" style={{ marginRight: '15px' }}>
                  {getChangeTime(i)}
                </span>
                <Box
                  key="dot-icon"
                  mr={'15px'}
                  className={`${classes.dotIcon}`}
                />
                <span key="cahnge-text">
                  {t('change aircraft in')} {getChangePlace(i)}
                </span>
              </Box>
            </Box>
          )}
          <Box className={`${classes.depRow} ${classes.providerInfo}`}>
            <Box key="logo-block" className={`${classes.logoBlock}`}>
              <img
                alt={'logo'}
                style={{
                  visibility: logosLoaded[s.company] ? 'visible' : 'hidden',
                  width: '100%',
                }}
                src={getLogoUrl(s)}
                onLoad={() => showLogo(s)}
              />
            </Box>
            <Box key="provider-block" className={classes.providerBlock}>
              {getProviderName(s)}{' '}
              {!!s.flightnumber ? `(${s.company} ${s.flightnumber})` : ''}
            </Box>
            {!!flight?.original?.ffName && (
              <span key="fare-block">{flight.original.ffName}</span>
            )}
          </Box>
          <Grid key="main-info-row" container spacing={0}>
            <Grid key="main-row-left" xs={12} md={true} item>
              <Box className={classes.depRow}>
                <Box className={classes.iconBlock}>
                  <Box key="circle-icon" className={classes.circleIcon} />
                  <Box key="line-block" className={classes.lineBlock} />
                </Box>
                <Box
                  className={`${classes.descriptionBlock} ${
                    i === 0 ? classes.fontWeightBold : ''
                  }`}
                >
                  {getTimeFromDate(s.departure)} {s.fromCity} ({s.fromShort})
                </Box>
              </Box>
              <Box className={classes.depRow}>
                <Box className={classes.tripInfoBlock}>
                  <FlightIcon
                    key="flight-icon"
                    className={classes.flightIcon}
                    icon="flight"
                  />
                  <span>{formatDuration(s.duration)}</span>
                </Box>
              </Box>
              <Box className={classes.depRow}>
                <Box className={classes.iconBlock}>
                  <Box key="line-block" className={classes.lineBlock} />
                  <Box key="circle-icon" className={classes.circleIcon} />
                </Box>
                <Box
                  className={`${classes.descriptionBlock} ${
                    i >= flight.via.length - 1 ? classes.fontWeightBold : ''
                  }`}
                >
                  {getTimeFromDate(s.arrival)} {s.toCity} ({s.toShort})
                </Box>
              </Box>
            </Grid>
            {!!getAircraft(s) && (
              <Grid key="main-row-right" item xs={12} md={'auto'}>
                <Box
                  key="aircraft-info"
                  style={{ marginTop: isMobile ? '20px' : '0' }}
                  className={classes.aircraftInfo}
                >
                  <FlightIcon
                    key="flight-icon"
                    className={classes.flightIconRight}
                  />
                  <span key="aircraft-text">{getAircraft(s)}</span>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
    </Box>
  )

  return (
    <Box key="scheme-outer" className={`${classes.schemeOuter}`}>
      <Box key="scheme-header" className={`${classes.schemeHeader}`}>
        <Box
          key="departure-text-outer"
          display={'flex'}
          alignItems={'center'}
          flex={1}
        >
          <span key="dep-text" style={{ marginRight: '8px' }}>
            {t('departure')}
          </span>
          <Box key="dot-icon" className={`${classes.dotIcon}`} />
          <span key="dep-date" style={{ marginLeft: '8px' }}>
            {getTimeText('dep', 'day')}
          </span>
        </Box>

        <Box key="trip-time" display={'flex'} alignItems={'center'}>
          {formatDuration(flight.travelTime)}
        </Box>
      </Box>
      <Grid key="scheme-body" container>
        <Grid
          key="expanded-row-left"
          item
          xs={12}
          style={{ padding: `25px ${smallPadding && isMd ? '10px' : '25px'}` }}
          className={`${classes.schemeInner}`}
        >
          {schemeBlock}
        </Grid>
      </Grid>
    </Box>
  )
}

export default memo(FlightTimeLine)
