import React, { memo } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { closeAllModals } from '../../store/modals/modalAction'
import ModalHook from './reusable/modalHook'
import ImageSlider from '../../components/hotels/slider/imageSlider'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
}))

const HotelImageModal = ({ modalBoolean }) => {
  const { single } = useSelector((state) => state.hotels)
  const classes = useStyles()
  const dispatch = useDispatch()
  const closeModal = () => dispatch(closeAllModals())

  const hotelImages = single?.parsedHotel?.additional_images || []

  // Pick out Array of images ---> into slider >>>
  return (
    <ModalHook
      modalBoolean={modalBoolean}
      fullHeight
      width={'100%'}
      height={'100vh'}
    >
      <Box p={4} className={classes.header}>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ImageSlider images={hotelImages} width={1000} height={500} />
      </Box>
    </ModalHook>
  )
}

export default memo(HotelImageModal)
