import agent from './agent'

// **POST** approve pending booking
export const review = async (token, act = 'approve', ctKey = null) => {
  try {
    const url = '/v2/booking/review-pending'
    const { data } = await agent.post(url, {token, act}, {cityCityCT: ctKey})
    return data
  } catch (error) {
    throw error
  }
}

export const book = async (token, ctKey = null) => {
  try {
    const url = '/v2/booking/book-pending'
    const { data } = await agent.post(url, {token}, {cityCityCT: ctKey})
    return data
  } catch (error) {
    throw error
  }
}

export const cancel = async (token, ctKey = null) => {
  try {
    const url = '/v2/booking/cancel-pending'
    const { data } = await agent.post(url, {token}, {cityCityCT: ctKey})
    return data
  } catch (error) {
    throw error
  }
}