import { Box, Popper, Paper } from '@material-ui/core'
import React, { memo } from 'react'
import TicketClassSelector from './ticketClassSelector'

import CountRow from './countRow'
import CountsBtnRow from './countsBtnRow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  bottomRow: {
    marginTop: '12px',
    lineHeight: 'normal',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightMedium
  }
}))

const DesktopCountsMenu = ({
  counts,
  modifyCounts,
  anchorEl,
  open,
  onClose,
  setTicketClass,
  ticketClass,
  ticketClasses,
  menuOffset= '4px',
  zIndex
}) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const buildCounts = () => {
    const results = []
    if (!counts) return results

    for (let key in counts) {
      const {title, count, muted, displayType, buttons} = counts[key]
      if (!displayType || displayType === 'count') results.push(<CountRow title={title} count={count} muted={muted} key={key} countKey={key} modifyCounts={modifyCounts}/>)
      if (displayType === 'buttons') results.push(<CountsBtnRow buttons={buttons} title={title} value={count} muted={muted} key={key} countKey={key} modifyCounts={modifyCounts}/>)
    }
    return results
  }

  const getBedsText = () => {
    if (!counts.beds) return 'single'

    const {count, buttons} = counts.beds
    const res = buttons.find(b => b.value === count)
    return (!!res) ? res.name : 'single'
  }

  const buildBottomLine = () => {
    if (!counts.rooms) return null
    const bedText = getBedsText()
    const { count } = counts.rooms
    return (
      <Box px={2} className={classes.bottomRow}>
        {count} {t(bedText)} {t('counts room', {count: count}).toLowerCase()}
      </Box>
    )
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={onClose}
    >
      <Popper
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        placement={'bottom-start'}
        style={{
          zIndex: zIndex || 2
        }}
      >
        <Paper
          style={{
            width: 310,
            marginTop: menuOffset,
            marginBottom: '4px',
            paddingBottom: '29px',
            paddingTop: '13px',
            borderRadius: '8px',
          }}
        >
          {!!ticketClasses &&
            <Box pt={'7px'} px={2} pb={'30px'}>
              <TicketClassSelector
                setTicketClass={setTicketClass}
                ticketClass={ticketClass}
                ticketClasses={ticketClasses}
              />
            </Box>
          }
          {buildCounts()}
          {buildBottomLine()}
        </Paper>
      </Popper>
    </ClickAwayListener>
  )
}

export default memo(DesktopCountsMenu)
