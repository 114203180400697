import { Box, Divider, Typography, Button } from '@material-ui/core'
import React, { memo } from 'react'
import { Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TicketClassSelector from './ticketClassSelector'
import { useTranslation } from 'react-i18next'
import CountRow from './countRow'
import CountsBtnRow from "./countsBtnRow";

const useStyles = makeStyles((theme) => ({
  drawer: {
    '& .MuiDrawer-paper	': {
      width: '100%',
    },
  },
  bottomRow: {
    marginTop: '12px',
    lineHeight: 'normal',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightMedium
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  finishBtn: {
    borderRadius: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1.5, 2),
    letterSpacing: 1.1,
  },
}))

const MobileCountsDrawer = ({
  open,
  toggleDrawer,
  counts,
  modifyCounts,
  isHotel,
  setTicketClass,
  ticketClass,
  ticketClasses,
}) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const buildCounts = () => {
    const results = []
    if (!counts) return results

    for (let key in counts) {
      const {title, count, muted, displayType, buttons} = counts[key]
      if (displayType === 'buttons') results.push(<CountsBtnRow buttons={buttons} title={title} value={count} muted={muted} key={key} countKey={key} modifyCounts={modifyCounts}/>)
      if (!displayType || displayType === 'count') results.push(<CountRow title={title} count={count} muted={muted} key={key} countKey={key} modifyCounts={modifyCounts}/>)
    }
    return results
  }

  const getBedsText = () => {
    if (!counts.beds) return 'single'

    const {count, buttons} = counts.beds
    const res = buttons.find(b => b.value === count)
    return (!!res) ? res.name : 'single'
  }

  const buildBottomLine = () => {
    if (!counts.rooms) return null;
    const bedText = getBedsText();
    const { count } = counts.rooms
    return (
      <Box px={2} className={classes.bottomRow}>
        {count} {t(bedText)} {t('counts room', {count: count}).toLowerCase()}
      </Box>
    )
  }

  return (
    <>
      <Drawer
        anchor="left"
        className={classes.drawer}
        open={open}
        onClose={toggleDrawer(false)}
        SlideProps={{
          unmountOnExit: true,
        }}
      >
        <Box flex="1">
          <Box p={3}>
            <Typography className={classes.bold} align="center">
              {isHotel ? t('number of rooms') : t('travellers and class')}
            </Typography>
          </Box>
          <Divider style={{marginBottom: '12px'}} />
          {!!ticketClasses &&
            <Box pt={'20px'} px={2} pb={'30px'}>
              <TicketClassSelector
                setTicketClass={setTicketClass}
                ticketClass={ticketClass}
                ticketClasses={ticketClasses}
              />
            </Box>
          }
          {buildCounts()}
          {buildBottomLine()}
        </Box>

        <Box p={3}>
          <Button
            onClick={toggleDrawer(false)}
            className={classes.finishBtn}
            fullWidth
            color="primary"
            variant="contained"
          >
            {t('counts finish')}
          </Button>
        </Box>
      </Drawer>
    </>
  )
}

export default memo(MobileCountsDrawer)
