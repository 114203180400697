import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import Box from '@material-ui/core/Box'
import ModalHook from './reusable/modalHook'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  useTheme,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { activateUserAction } from '../../store/auth/authAction'
import { useDispatch } from 'react-redux'
import {
  closeAllModals,
  openActivateUserModalAction,
} from '../../store/modals/modalAction'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import agent from '../../repositories/agent'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.spacing(4),
    marginBottom: theme.spacing(5),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  text: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(4.5),
    maxWidth: '300px',
    textAlign: 'center',
  },
  invalidText: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(4.5),
    maxWidth: '600px',
    textAlign: 'center',
  },
  inputHeading: {
    fontSize: theme.spacing(2),
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  input: {
    borderRadius: theme.spacing(0.75),
  },
  helperText: {
    paddingLeft: '15px',
  },
  formControl: {
    marginBottom: theme.spacing(2.5),
    width: '100%',
  },
  button: {
    padding: theme.spacing(1.5, 10),
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: theme.spacing(3),
    letterSpacing: '1.1px',
    marginTop: theme.spacing(5),
  },
}))

const ActivateUserModal = ({ modalBoolean }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isInvalidCode, setInvalidCode] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [showPassword, setShowPassword] = useState({
    password: false,
    rePassword: false,
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let email = params.get('email')
    let code = params.get('code')

    agent
      .get(`/v2/auth/pre-activate?email=${email}&code=${code}`)
      .then(() => {
        setLoading(false)
      })
      .catch((err) => {
        setInvalidCode(true)
      })
  }, [setInvalidCode, setLoading])

  const onSubmit = async (values) => {
    const params = history.location.search
      .substring(1)
      .split('&')
      .reduce((acc, el) => {
        const name = el.split('=')[0]
        const value = el.split('=')[1]

        return { ...acc, [name]: value }
      }, {})

    if (params.code && params.email) {
      try {
        dispatch(closeAllModals())

        await dispatch(activateUserAction({ ...params, ...values }))

        history.push('/flights')
      } catch (e) {
        console.log(e)
        dispatch(openActivateUserModalAction())
      }
    }
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('too short'))
      .matches(/[A-Za-z]/, t('at least one'))
      .matches(/\d/, t('at least one'))
      .required(t('required')),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      t('must match')
    ),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit,
  })

  const goToLogin = () => {
    dispatch(closeAllModals())
    history.push('/log-in')
  }

  if (isInvalidCode) {
    return (
      <ModalHook modalBoolean={modalBoolean} width={500} noBackdrop>
        <Box
          px={isMobile ? 2 : 4}
          py={isMobile ? 5 : 10}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography className={classes.heading}>
            {t('invalid code header')}
          </Typography>
          <Typography className={classes.invalidText}>
            {t('invalid code text')}{' '}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="button"
            onClick={goToLogin}
            className={classes.button}
          >
            {t('go login')}
          </Button>
        </Box>
      </ModalHook>
    )
  }

  if (isLoading) {
    return false
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <ModalHook modalBoolean={modalBoolean} width={500} noBackdrop>
      <form onSubmit={formik.handleSubmit}>
        <Box
          px={isMobile ? 2 : 9}
          py={isMobile ? 5 : 10}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography className={classes.heading}>
            {t('welcome modal')}
          </Typography>
          <Typography className={classes.text}>
            {t('please')}{' '}
            <span style={{ fontWeight: 'bold' }}>{t('create password')}</span>{' '}
            {t('before trips')}
          </Typography>
          <Typography className={classes.inputHeading}>
            {t('new password')}
          </Typography>
          <FormControl
            error={formik.errors.password && formik.touched.password}
            className={classes.formControl}
          >
            <OutlinedInput
              fullWidth
              name="password"
              type={showPassword.password ? 'text' : 'password'}
              error={formik.errors.password && formik.touched.password}
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              onFocus={() => (formik.touched.password = true)}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: false }}
              style={{ borderRadius: theme.spacing(0.75) }}
              variant="outlined"
              className={classes.input}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password,
                      })
                    }
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                tabIndex: '1',
                autoComplete: 'off',
                spellCheck: 'false',
                autoCorrect: 'off',
                style: { fontWeight: 500 },
              }}
            />
            {!!formik.touched.password && !!formik.errors.password && (
              <FormHelperText className={classes.helperText}>
                {formik.errors.password}
              </FormHelperText>
            )}
          </FormControl>

          <Typography className={classes.inputHeading}>
            {t('confirm password')}
          </Typography>
          <FormControl
            error={
              formik.errors.confirmPassword && formik.touched.confirmPassword
            }
            className={classes.formControl}
          >
            <OutlinedInput
              fullWidth
              type={showPassword.rePassword ? 'text' : 'password'}
              value={formik.values.confirmPassword}
              error={
                formik.errors.confirmPassword && formik.touched.confirmPassword
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              onFocus={() => (formik.touched.confirmPassword = true)}
              onChange={formik.handleChange}
              name="confirmPassword"
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              className={classes.input}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        rePassword: !showPassword.rePassword,
                      })
                    }
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword.rePassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                tabIndex: '1',
                autoComplete: 'off',
                spellCheck: 'false',
                autoCorrect: 'off',
                style: { fontWeight: 500 },
              }}
            />
            {!!formik.touched.confirmPassword &&
              !!formik.errors.confirmPassword && (
                <FormHelperText className={classes.helperText}>
                  {formik.errors.confirmPassword}
                </FormHelperText>
              )}
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            className={classes.button}
          >
            {t('get started')}
          </Button>
        </Box>
      </form>
    </ModalHook>
  )
}

export default ActivateUserModal
