import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import { Box, Typography } from '@material-ui/core'
import {
  setTrainSeatAction,
} from '../../../store/train/trainAction'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 0,
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
    backgroundColor: `${theme.palette.common.white} !important`,
    borderRadius: '12px',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  semiBold: {
    fontWeight: 500,
  },
  timeline: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
      borderRadius: '12px'
    },
  }
}))

const PassengerSelectSeats = ({ passenger, opts, identifier }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const {seats} = useSelector((state) => state.train)
  const { firstName, lastName, uniqueId } = passenger
  const seatEnv = seats?.[identifier]?.[uniqueId]?.seatEnv || ''
  const seatSide = seats?.[identifier]?.[uniqueId]?.seatSide || ''

  const handleSeatRequestChange = (val, type= 'seatEnv') => {
    const payload = {
      accom: { seatEnv: seatEnv, seatSide: seatSide, [type]: val},
      railindentifier: identifier,
      userId: uniqueId,
    }
    dispatch(setTrainSeatAction(payload))
  }

  const renderSeatPosMenuItems = opts?.seatPos?.map((val, key) => {
    return (
      <MenuItem key={key} value={val}>
        {t(`accom ${val.transl}`)}
      </MenuItem>
    )
  })

  const renderTrainPosItems = opts?.env?.map((val, key) => (
    <MenuItem value={val} key={key}>
      {t(`accom ${val.transl}`)}
    </MenuItem>
  ))

  return (
    <Grid item container spacing={5}>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography variant="body2" gutterBottom>
            <span className={classes.weight}>{t('seat request')}</span>
            {' '}
            <span className={classes.semiBold}>
              ({`${firstName} ${lastName}`})
            </span>
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="seatRequetLabel">{t('seat')}</InputLabel>
            <Select
              IconComponent={ExpandMoreIcon}
              fullWidth
              labelId="seatRequetLabel"
              id="seatRequetLabel"
              value={seatEnv}
              onChange={(e) => handleSeatRequestChange(e.target.value, 'seatEnv')}
              label="osdkfoskok"
              className={classes.select}
            >
              {renderSeatPosMenuItems}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography variant="body2" gutterBottom>
            <span className={classes.weight}>{t('seat')}</span>{' '}
            <span className={classes.semiBold}>
              ({`${firstName} ${lastName}`})
            </span>
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="seatLabel">{t('select')}</InputLabel>
            <Select
              IconComponent={ExpandMoreIcon}
              fullWidth
              labelId="seatLabel"
              id="seatLabel"
              value={seatSide}
              className={classes.select}
              onChange={(e) => handleSeatRequestChange(e.target.value, 'seatSide')}
            >
              {renderTrainPosItems}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  )
}

export default memo(PassengerSelectSeats)
