const getCustomerDomain = () => {
  let hostname = window.location.hostname

  if (hostname === 'app.citycity.se') {
    const storedUrl = localStorage.getItem('url')
    if (storedUrl) {
      return new URL(storedUrl).hostname || storedUrl
    }
  }
  return hostname
}

export function getBrandedUrl(path) {
  const isLocalhost = window.location.hostname.startsWith('localhost')
  const onlyLocalhost = window.location.hostname === 'localhost'
  let customerDomain = getCustomerDomain()

  let agency = isLocalhost
    ? onlyLocalhost
      ? 'localhost'
      : window.location.hostname.substring(10)
    : customerDomain
  return `https://static.citycity.se/agencies/${agency}/${path}`
}

export function getDomain() {
  const isLocalhost = window.location.hostname.startsWith('localhost')
  const onlyLocalhost = window.location.hostname === 'localhost'
  return isLocalhost
    ? onlyLocalhost
      ? 'localhost'
      : window.location.hostname.substring(10)
    : window.location.hostname
}
