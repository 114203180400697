import React, { memo } from 'react'
import ModalHook from './reusable/modalHook'
import LoginBlock from '../../components/reusable/loginBlock'
import { closeAllModals } from '../../store/modals/modalAction'
import { useDispatch } from 'react-redux'


const LoginModal = ({ modalBoolean }) => {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(closeAllModals())

  return (
    <ModalHook
      minHeight={300}
      width={500}
      modalBoolean={modalBoolean}
      marginTop={250}
    >
      <LoginBlock isModal onBack={closeModal} />
    </ModalHook>
  )
}

export default memo(LoginModal)
