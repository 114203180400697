import React, {Fragment, memo} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Card } from '@material-ui/core'
import ChoosenTripCard from "../../components/cars/choosenTripCard";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '12px',
  },
}))

const CarCard = ({ model = null, disableEdit = false, index = null, contentOnly = false }) => {
  const classes = useStyles()

  return (
    <Fragment>
      {contentOnly ? (
        <ChoosenTripCard car={model} disableEdit={disableEdit} index={index}/>
      ) : (
        <Card elevation={0} className={classes.card}>
          <Box>
            <ChoosenTripCard car={model} disableEdit={disableEdit} index={index}/>
          </Box>
        </Card>
      )}
    </Fragment>
  )
}

export default memo(CarCard)
