import React, { useCallback, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import { useSliderStyles } from '../flights/filters/sliderStyles'
import usePriceFormat from '../../hooks/usePriceFormat'

const RangeSlider = ({
  title = '',
  min,
  max,
  highest,
  lowest = 0,
  callBack,
}) => {
  const classes = useSliderStyles()
  const [val, setVal] = useState([0, 0])
  const { formatPrice } = usePriceFormat()

  const onChange = useCallback((e, v) => {
    setVal(v)
  }, [])
  const applyChange = (e, v) => {
    callBack(v)
  }
  useEffect(() => {
    setVal([min, max])
  }, [min, max])
  const rHighest = Math.round(highest)
  const rLowest = Math.round(lowest || 0)
  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography className={classes.text} style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Box>
      <Box padding={'0 7px'}>
        <Slider
          value={val}
          onChange={onChange}
          onChangeCommitted={applyChange}
          valueLabelDisplay="on"
          step={10}
          valueLabelFormat={(val) =>
            formatPrice(val || 0, { appendCurrency: true })
          }
          color="primary"
          max={rHighest}
          min={rLowest}
          classes={{ root: `${classes.root} medium-label` }}
          style={{ width: '96%' }}
        />
      </Box>
    </>
  )
}

export default RangeSlider
