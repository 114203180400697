import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  fetchHotelCitiesAction,
  removeHotelStateAll,
  resetHotelFilters,
  setHotelGuestsAction,
  setMapCenter,
} from '../store/hotels/hotelsAction'
import { setBreadcrumbType } from '../store/breadcrumbs/breadcrumbsAction'
import { removeAllCheckoutAction } from '../store/checkout/checkoutActions'
import { setHotelSearchParameters } from '../store/search/searchAction'
import { toFullDate } from '../utils/formatDates'
import { useSelector } from 'react-redux'
import { buildUsersByCount } from '../utils/general'

const useHotels = ({
  cityValue,
  city,
  checkIn,
  checkOut,
  travellers,
  roomCount,
  url,
  dispatch,
  history,
  setIsPopoverOpenFrom,
  setIsPopoverOpenTraveller,
  incrementRoom,
  decrementRoom,
  setRoomCount,
  // setIsPopoverOpenCheckIn,
  // setIsPopoverOpenCheckOut,
}) => {
  // Travellers first time initiated (to skip first time rooms calculation)
  const [travelInit, setTravelInit] = useState(false)

  useEffect(() => {
    return () => {
      if (dispatch) dispatch(removeHotelStateAll())
    }
  }, [dispatch])

  const getCities = useCallback(
    (value) => {
      if (dispatch) dispatch(fetchHotelCitiesAction(value))
    },
    [dispatch]
  )

  const calculateRooms = useCallback(
    (usersCount, skipCalculation = true) => {
      const tmpUsers = usersCount || 1
      let rooms = tmpUsers
      let beds = 1
      if (!skipCalculation) {
        for (let bCount = 2; bCount < 4; bCount++) {
          const tmpRooms = Math.ceil(tmpUsers / bCount)
          if (tmpRooms < rooms) {
            rooms = tmpRooms
            beds = bCount
          }
        }
      }
      setRoomCount(rooms)
      modifyCounts('beds', 'set', beds)
    },
    // eslint-disable-next-line
    []
  )

  const searchData = useSelector((state) => state.search)
  const { searchByEvents } = useSelector((state) => state.customization)
  const { isAuthenticated } = useSelector((state) => state.auth)
  const { hotels: hotelsSearchData } = searchData
  const initCounts = hotelsSearchData?.travelerCounts || {}

  const [counts, setCounts] = useState(
    !isAuthenticated
      ? {
          adult: {
            count: initCounts?.adult?.count || 1,
            title: 'adult',
          },
          child: {
            count: initCounts?.child?.count || 0,
            title: 'child',
            muted: '(12-17 yrs)',
          },
          beds: {
            count: initCounts?.beds?.count || 1,
            displayType: 'buttons',
            buttons: [
              { value: 1, name: 'single' },
              { value: 2, name: 'double' },
              { value: 3, name: 'triple' },
            ],
          },
        }
      : {
          beds: {
            count: initCounts?.beds?.count || 1,
            displayType: 'buttons',
            buttons: [
              { value: 1, name: 'single' },
              { value: 2, name: 'double' },
              { value: 3, name: 'triple' },
            ],
          },
        }
  )

  useEffect(() => {
    if (!travelInit) {
      setTravelInit(true)
    } else if (isAuthenticated) {
      calculateRooms(travellers.length)
    }
    // eslint-disable-next-line
  }, [travellers, isAuthenticated, calculateRooms])

  const hotelCounts = useMemo(
    () => ({
      // this is done in such way to preserve the order of the elements
      ...(!!counts.adult ? { adult: { ...counts.adult } } : {}),
      ...(!!counts.child ? { child: { ...counts.child } } : {}),
      rooms: {
        count: roomCount,
        title: 'counts room',
      },
      beds: { ...counts.beds },
    }),
    [roomCount, counts]
  )

  const modifyCounts = useCallback(
    (type = 'rooms', action = 'increment', val = null) => {
      if (type === 'rooms') {
        if (action === 'increment') {
          incrementRoom()
        } else {
          decrementRoom()
        }
        return true
      }

      if (!counts?.[type]) return false

      let tmpCounts = { ...counts }
      const min = tmpCounts?.[type]?.min || 0
      const max = tmpCounts?.[type]?.max || 99

      switch (action) {
        case 'increment':
          if (tmpCounts[type].count < max) tmpCounts[type].count++
          break

        case 'decrement':
          if (tmpCounts[type].count > min) tmpCounts[type].count--
          break

        case 'set':
          if (val !== null && val >= min && val <= max)
            tmpCounts[type].count = val
          break

        default: {
          return false
        }
      }
      setCounts(tmpCounts)
      setTimeout(() => {
        if (type === 'adult' || type === 'child') {
          calculateRooms(
            (counts?.adult?.count || 1) + (counts?.child?.count || 0)
          )
        }
      }, 1)
    },
    [incrementRoom, decrementRoom, counts, calculateRooms]
  )

  const tmpTravelers = isAuthenticated ? travellers : buildUsersByCount(counts)

  const onSubmit = () => {
    if (tmpTravelers.length === 0) {
      setIsPopoverOpenTraveller(true)
    } else {
      setIsPopoverOpenTraveller(false)
    }

    if (!cityValue) {
      setIsPopoverOpenFrom(true)
    } else {
      setIsPopoverOpenFrom(false)
    }

    if (tmpTravelers.length === 0 || !cityValue || !checkOut || !checkIn) {
      return
    }
    dispatch(setBreadcrumbType('hotels'))
    dispatch(removeAllCheckoutAction())
    dispatch(setHotelGuestsAction(tmpTravelers))
    dispatch(resetHotelFilters())

    // remove unnecessary counts data
    const countsForSave = {}
    for (let countKey in counts) {
      countsForSave[countKey] = {
        count: counts[countKey].count || 0,
      }
    }

    dispatch(
      setHotelSearchParameters(
        cityValue,
        city,
        checkIn,
        checkOut,
        tmpTravelers,
        roomCount,
        countsForSave
      )
    )
    dispatch(setMapCenter(null))

    const numberOfGuests = tmpTravelers.length
    const { lat, lng } = city?.Coordinates
    const uri = `/hotels/search/${city.Country}/${lat}/${lng}/${toFullDate(
      checkIn
    )}/${toFullDate(checkOut)}/${roomCount}/${numberOfGuests}/`

    if (window.parent !== window) {
      window.parent.postMessage(
        { type: 'searching', mode: 'hotel', path: uri },
        '*'
      )
      if (window.location.pathname == '/iframe') return
    }

    history.push(uri)
  }

  const optionLabel = (options) => {
    return options.Name ? options.Name : ''
  }

  return { onSubmit, getCities, optionLabel, modifyCounts, counts: hotelCounts }
}

export default useHotels
