import React, { memo } from 'react'
import { Drawer, Box, Button, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CheckoutItems from '../checkout/viewHandler'
import { closeAllModals } from '../../store/modals/modalAction'
import { useDispatch } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom'
import { disableExtendCheckout } from '../../store/search/searchAction'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

export const useStyles = makeStyles((theme) => ({
  drawerElement: {
    '& .MuiDrawer-paper': {
      width: '328px',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: '100%',
      },
      zIndex: 10000,
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '64px',
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.border}`,
    padding: theme.spacing(1, 3),
  },
  headerSideBlock: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTextBlock: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyOuter: {},
  bodyInner: {
    flex: 1,
    paddingBottom: '20px',
    display: 'flex',
    overflowY: 'auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    borderTop: `1px solid ${theme.palette.border}`,
  },
  backBtn: {
    boxShadow: 'none',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    display: 'inline-flex',
    cursor: 'pointer',
    letterSpacing: '0.7px',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 'normal',
  },
  checkoutBtnArrow: {
    marginRight: '10px',
  },
}))

const CheckoutItemsDrawer = ({ modalBoolean }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  let itmKey = 1

  const onClose = () => {
    dispatch(closeAllModals())
  }

  const goToCheckout = () => {
    onClose()
    dispatch(disableExtendCheckout())
    history.push('/checkout')
  }

  return (
    <Drawer
      className={classes.drawerElement}
      anchor={'right'}
      variant={isMobile ? 'temporary' : 'persistent'}
      open={!!modalBoolean}
      onClose={() => onClose()}
    >
      <Box
        key={`itm-${itmKey++}`}
        display={'flex'}
        height={'100%'}
        className={classes.bodyOuter}
        flexDirection={'column'}
      >
        <Box className={classes.header}>
          <Box className={classes.headerSideBlock} />
          <Box className={classes.headerTextBlock}>{t('checkout items')}</Box>
          <Box
            className={classes.headerSideBlock}
            style={{ justifyContent: 'flex-end' }}
          >
            <Box
              display="inline-flex"
              style={{ cursor: 'pointer' }}
              onClick={onClose}
            >
              <CloseIcon />
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyInner}>
          <CheckoutItems
            disableEdit={true}
            contentOnly={true}
            useDivider={true}
          />
        </Box>
        <Box className={classes.footer}>
          <Button
            onClick={goToCheckout}
            className={classes.backBtn}
            variant="outlined"
            color="primary"
          >
            <ArrowBackIcon
              color="primary"
              className={classes.checkoutBtnArrow}
            />
            <span>{t('back to checkout')}</span>
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}

export default memo(CheckoutItemsDrawer)
