import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import useEnabledBookingTypesHook from './useEnabledBookingTypesHook'
import { useLocation, useHistory } from 'react-router-dom'
import usePrevious from './usePrevious'

const RedirectifAuth = ({ children, skipPublicityCheck = false }) => {
  const { isAuthenticated, publicity, user, defaultPath, isLogginIn } =
    useSelector((state) => state.auth)
  const location = useLocation()
  const history = useHistory()

  const prevIsAuthenticated = usePrevious(isAuthenticated)

  const { isFlightsEnabled, isCarsEnabled, isHotelsEnabled, isTrainsEnabled } =
    useEnabledBookingTypesHook()

  const isPublic = publicity === 'both' || publicity === 'public'

  const findFirstValidPath = () => {
    const valid = []
    if (isTrainsEnabled) valid.push('/trains')
    if (isFlightsEnabled) valid.push('/flights')
    if (isFlightsEnabled) valid.push('/flights-multicity')
    if (isHotelsEnabled) valid.push('/hotels')
    if (isCarsEnabled) valid.push('/cars')

    if (user?.defaultPath && valid.includes(user.defaultPath))
      return user.defaultPath
    if (defaultPath && valid.includes(defaultPath)) return defaultPath

    if (valid.length > 0) return valid[0]

    // We have no valid default path -
    console.log('no valid default path - using login')

    if (isAuthenticated) return '/my-trips'

    return '/log-in'
  }

  /**
   * Ridiculous workaround for BIG embedded site. Needs to redirect to / after login to get to /trains; some issue with disabledPublic.
   */
  useEffect(() => {
    if (prevIsAuthenticated === false && isAuthenticated) {
      history.push('/')
    }
  }, [isAuthenticated, history])

  if ((!isLogginIn && isAuthenticated) || (isPublic && !skipPublicityCheck)) {
    let firstValidPath = findFirstValidPath()
    if (firstValidPath != location.pathname) {
      return <Redirect to={firstValidPath} />
    }
  }

  return children
}

export default RedirectifAuth
