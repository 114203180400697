const COMPANIES = {
  others: {
    label: 'Övriga',
    travelType: 'air',
  },
  EE: {
    label: 'Regional Jet',
    travelType: 'air',
  },
  A5: {
    label: 'Air France Hop',
    travelType: 'air',
    checkInUrl:
      'https://checkin.si.amadeus.net/1ASIHSSCWEBA5/sscwa5/checkin?ln=en',
  },
  I2: {
    label: 'Iberia Express',
    travelType: 'air',
    checkInUrl:
      'https://www.iberia.com/es/online-checkin/?language=en&market=es#!/ibcose',
  },
  YW: {
    label: 'Iberia Regional',
    travelType: 'air',
  },
  E2: {
    label: 'Eurowings',
    travelType: 'air',
  },
  WX: {
    label: 'CityJet',
    travelType: 'air',
  },
  SU: {
    label: 'Aeroflot',
    travelType: 'air',
    checkInUrl: 'https://www.aeroflot.ru/ru-en/information/checkin/web_checkin',
  },
  AC: {
    label: 'Air Canada',
    travelType: 'air',
    checkInUrl: 'https://www.aircanada.com/ca/en/aco/home.html#/home:checkIn',
  },
  AY: {
    label: 'Finnair',
    travelType: 'air',
    checkInUrl: 'https://www.finnair.com/en/check-in',
  },
  KL: {
    label: 'KLM',
    travelType: 'air',
    checkInUrl: 'https://www.klm.nl/en/check-in',
  },
  SK: {
    label: 'SAS',
    travelType: 'air',
    checkInUrl: 'https://www.flysas.com/us-en/checkin/',
  },
  UA: {
    label: 'United Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.united.com/en/us/checkin',
  },
  DL: {
    label: 'Delta Air',
    travelType: 'air',
    checkInUrl: 'https://www.delta.com/PCCOciWeb/findBy.action',
  },
  AF: {
    label: 'Air France',
    travelType: 'air',
    checkInUrl: 'https://wwws.airfrance.de/en/check-in',
  },
  BA: {
    label: 'British Airways',
    travelType: 'air',
    checkInUrl:
      'https://www.britishairways.com/travel/olcilandingpageauthreq/public/en_us',
  },
  EK: {
    label: 'Emirates',
    travelType: 'air',
    checkInUrl: 'https://fly2.emirates.com/CAB/IBE/SearchAvailability.aspx',
  },
  EY: {
    label: 'Ethiad Airways',
    travelType: 'air',
    checkInUrl: 'https://www.etihad.com/en/manage/check-in',
  },
  LH: {
    label: 'Lufthansa',
    travelType: 'air',
    checkInUrl: 'https://www.lufthansa.com/us/en/online-check-in?tl=1&l=en',
  },
  AA: {
    label: 'American Airlines',
    travelType: 'air',
    checkInUrl:
      'https://www.aa.com/reservation/flightCheckInViewReservationsAccess.do',
  },
  IB: {
    label: 'Iberia',
    travelType: 'air',
    checkInUrl:
      'https://www.iberia.com/es/online-checkin/?language=en&market=es#!/ibcose',
  },
  LO: {
    label: 'LOT',
    travelType: 'air',
    checkInUrl: 'https://checkin.si.amadeus.net/static/PRD/LO/#/identification',
  },
  LX: {
    label: 'Swiss',
    travelType: 'air',
    checkInUrl: 'https://www.swiss.com/us/en/homepage',
  },
  OS: {
    label: 'Austrian',
    travelType: 'air',
    checkInUrl: 'https://wci.austrian.com/app/ck.fly?l=en',
  },
  SN: {
    label: 'Brussels Airlines',
    travelType: 'air',
    checkInUrl:
      'https://checkin.brusselsairlines.com/CI/WebForms/PaxByQuery.aspx',
  },
  TP: {
    label: 'TAP Portugal',
    travelType: 'air',
    checkInUrl: 'https://www.flytap.com/en-pt/check-in/online-check-in',
  },
  AZ: {
    label: 'ITA Airways',
    travelType: 'air',
  },
  BT: {
    label: 'Air Baltic',
    travelType: 'air',
    checkInUrl: 'https://www.airbaltic.com/en-ZZ/index?opentab=CKI',
  },
  TF: {
    label: 'BRA',
    travelType: 'air',
    checkInUrl: 'https://www.flygbra.se/en/check-in',
  },
  WF: {
    label: 'Wideroe',
    travelType: 'air',
    checkInUrl:
      'https://checkin.si.amadeus.net/1ASIHSSCWEBWF/sscwwf/checkin?ln=en&type=W&Redirected=true',
  },
  A3: {
    label: 'Aegean',
    travelType: 'air',
    checkInUrl:
      'https://checkin.si.amadeus.net/static/PRD/A3/#/checkin?ln=en&appRedirected=true',
  },
  D8: {
    label: 'Norwegian',
    travelType: 'air',
    checkInUrl:
      'https://www.norwegian.com/uk/travel-info/check-in-and-boarding/online-check-in/',
  },
  DY: {
    label: 'Norwegian',
    travelType: 'air',
    checkInUrl:
      'https://www.norwegian.com/us/ipr/CheckIn#/auth/lookup?returnState=checkin.gotoresdetails',
  },
  WS: {
    label: 'WestJet',
    travelType: 'air',
    checkInUrl: 'https://www.westjet.com/en-ca?tabName=checkin-tab',
  },
  AS: {
    label: 'Alaska Airlines',
    travelType: 'air',
    checkInUrl: 'https://webselfservice.alaskaair.com/checkinweb/default.aspx',
  },
  CX: {
    label: 'Cathay Pacific',
    travelType: 'air',
    checkInUrl: 'https://www.cathaypacific.com/mb/#!/en_HK/olci/login',
  },
  MH: {
    label: 'Malaysia Airlines',
    travelType: 'air',
    checkInUrl:
      'https://www.malaysiaairlines.com/my/en/travel-info/check-in.html',
  },
  QR: {
    label: 'Qatar Airways',
    travelType: 'air',
    checkInUrl: 'https://www.qatarairways.com/en-us/homepage.html',
  },
  AQ: {
    label: '9 Air',
    travelType: 'air',
  },
  SQ: {
    label: 'Singapore Airlines',
    travelType: 'air',
    checkInUrl:
      'https://www.singaporeair.com/en_UK/plan-and-book/your-booking/checkin/',
  },
  CI: {
    label: 'China Airlines',
    travelType: 'air',
    checkInUrl: 'https://calec.china-airlines.com/echeckin_tn/eCheckin_en.aspx',
  },
  KE: {
    label: 'Korean Air',
    travelType: 'air',
    checkInUrl: 'https://www.koreanair.com/',
  },
  TK: {
    label: 'Turkish Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.turkishairlines.com/#bookerManageTab',
  },
  TG: {
    label: 'Thai Airways',
    travelType: 'air',
    checkInUrl: 'https://www.thaiairways.com/en/index.page',
  },
  CA: {
    label: 'Air China',
    travelType: 'air',
    checkInUrl: 'https://www.chalair.fr/',
  },
  NH: {
    label: 'ANA',
    travelType: 'air',
    checkInUrl:
      'https://aswbe-i.ana.co.jp/international_asw/pages/webcheckin/checkin_search_input.xhtml?CONNECTION_KIND=DEU&LANG=dehttps://aswbe-i.ana.co.jp/international_asw/pages/webcheckin/checkin_search_input.xhtml?CONNECTION_KIND=LAX&LANG=en',
  },
  SA: {
    label: 'South African Airways',
    travelType: 'air',
    checkInUrl: 'https://checkin.si.amadeus.net/static/PRD/SA/#/identification',
  },
  B6: {
    label: 'JetBlue',
    travelType: 'air',
    checkInUrl: 'https://checkin.jetblue.com/checkin/',
  },
  WN: {
    label: 'Southwest Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.southwest.com/air/check-in/',
  },
  EI: {
    label: 'Aer Lingus',
    travelType: 'air',
    checkInUrl: 'https://webcheckin.aerlingus.com/html/checkIn/checkin.html',
  },
  AD: {
    label: 'Azul',
    travelType: 'air',
    checkInUrl: 'https://www.voeazul.com.br/br/pt/home/azulwebcheckin',
  },
  AR: {
    label: 'Aerolineas Argentinas',
    travelType: 'air',
    checkInUrl: 'https://www.aerolineas.com.ar/?activeTab=checkIn',
  },
  AV: {
    label: 'Avianca',
    travelType: 'air',
    checkInUrl: 'https://www.avianca.com/en/your-booking/online-check-in/',
  },
  BW: {
    label: 'Caribbean Airlines',
    travelType: 'air',
  },
  FI: {
    label: 'Icelandair',
    travelType: 'air',
  },
  GQ: {
    label: 'Sky Express',
    travelType: 'air',
  },
  HA: {
    label: 'Hawaiian Airlines',
    travelType: 'air',
  },
  J2: {
    label: 'Azerbaijan Airlines',
    travelType: 'air',
  },
  JU: {
    label: 'Air Serbia',
    travelType: 'air',
    checkInUrl: 'https://www.airserbia.com/sr_latin_RS',
  },
  KC: {
    label: 'Fly Astana',
    travelType: 'air',
    checkInUrl: 'https://airastana.com/ind/en-us/',
  },
  KM: {
    label: 'Air Malta',
    travelType: 'air',
  },
  KQ: {
    label: 'Kenya Airways',
    travelType: 'air',
  },
  LA: {
    label: 'LATAM',
    travelType: 'air',
    checkInUrl: 'https://www.latamairlines.com/cl/es',
  },
  LG: {
    label: 'Luxair',
    travelType: 'air',
    checkInUrl: 'https://www.luxair.lu/en/checkin',
  },
  LY: {
    label: 'EL AL',
    travelType: 'air',
    checkInUrl: 'https://www.elal.com/checkin/',
  },
  NZ: {
    label: 'Air New Zeeland',
    travelType: 'air',
  },
  OA: {
    label: 'Olympic Air',
    travelType: 'air',
    checkInUrl: 'https://www.olympicair.com/?a_id=573',
  },
  OK: {
    label: 'Czech Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.csa.cz/en/check-in',
  },
  OU: {
    label: 'Croatia Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.croatiaairlines.com/check-in',
  },
  PC: {
    label: 'Pegasus',
    travelType: 'air',
    checkInUrl: 'https://www.flypgs.com/',
  },
  PD: {
    label: 'Porter Airlines',
    travelType: 'air',
  },
  PG: {
    label: 'Bangkok Airways',
    travelType: 'air',
  },
  PS: {
    label: 'Ukraine Int.',
    travelType: 'air',
  },
  RJ: {
    label: 'Royal Jordanien',
    travelType: 'air',
  },
  SS: {
    label: 'Corsair',
    travelType: 'air',
  },
  TA: {
    label: 'Avianca El Salvador',
    travelType: 'air',
  },
  TM: {
    label: 'LAM Mozambique',
    travelType: 'air',
  },
  TU: {
    label: 'Tunisair',
    travelType: 'air',
  },
  U6: {
    label: 'Ural Airlines',
    travelType: 'air',
  },
  UU: {
    label: 'Air Austral',
    travelType: 'air',
  },
  UX: {
    label: 'Air Europa',
    travelType: 'air',
    checkInUrl: 'https://www.aireuropa.com/en/flights/error/not-found',
  },
  VA: {
    label: 'Virgin Australia',
    travelType: 'air',
    checkInUrl: 'https://check-in.virginaustralia.com/checkin/#/login',
  },
  VS: {
    label: 'Virgin Atlantic',
    travelType: 'air',
    checkInUrl: 'https://www.virginatlantic.com/my-trips/search',
  },
  '2D': {
    label: 'Eastern',
    travelType: 'air',
  },
  '4Y': {
    label: 'Eurowings',
    travelType: 'air',
  },
  '5N': {
    label: 'Nordavia',
    travelType: 'air',
  },
  '7R': {
    label: 'RusLine',
    travelType: 'air',
  },
  AM: {
    label: 'Aeromexico',
    travelType: 'air',
    checkInUrl: 'https://www.aeromexico.com/en-us/check-in',
  },
  B0: {
    label: 'La Compagnie',
    travelType: 'air',
    checkInUrl: 'https://www.lacompagnie.com/en',
  },
  B2: {
    label: 'Belavia',
    travelType: 'air',
  },
  BF: {
    label: 'Frencch Bee',
    travelType: 'air',
  },
  BJ: {
    label: 'Nouvelair',
    travelType: 'air',
    checkInUrl: 'https://www.nouvelair.com/en/reservation',
  },
  BM: {
    label: 'bmi Regional',
    travelType: 'air',
  },
  CY: {
    label: 'Cyprus Airlines',
    travelType: 'air',
  },
  E9: {
    label: 'Evelop Airlines',
    travelType: 'air',
  },
  EN: {
    label: 'Air Dolomiti',
    travelType: 'air',
    checkInUrl: 'https://www.airdolomiti.eu/online-check-in-instructions',
  },
  FC: {
    label: 'Fly Corporate',
    travelType: 'air',
  },
  GF: {
    label: 'Gulf Air',
    travelType: 'air',
  },
  H2: {
    label: 'Sky Airline',
    travelType: 'air',
  },
  J9: {
    label: 'Jazeera Airways',
    travelType: 'air',
  },
  N4: {
    label: 'Nordwind',
    travelType: 'air',
  },
  NF: {
    label: 'Air Vanuattu',
    travelType: 'air',
  },
  NT: {
    label: 'Binter Canarias',
    travelType: 'air',
    checkInUrl: 'https://www.bintercanarias.com/en/checkinonline',
  },
  QS: {
    label: 'SmartWings',
    travelType: 'air',
  },
  S7: {
    label: 'S7 Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.s7.ru/en/',
  },
  SX: {
    label: 'SkyWork Airlines',
    travelType: 'air',
  },
  TS: {
    label: 'Air Transat',
    travelType: 'air',
  },
  TX: {
    label: 'Air Caraibes',
    travelType: 'air',
  },
  UK: {
    label: 'Vistara',
    travelType: 'air',
  },
  UT: {
    label: 'UTair',
    travelType: 'air',
  },
  WZ: {
    label: 'Red Wings',
    travelType: 'air',
  },
  Y7: {
    label: 'Nordstar',
    travelType: 'air',
  },
  ZL: {
    label: 'Regional Express',
    travelType: 'air',
  },
  AI: {
    label: 'Air India',
    travelType: 'air',
  },
  OZ: {
    label: 'Asiana Airlines',
    travelType: 'air',
    checkInUrl: 'https://flyasiana.com/C/GB/EN/contents/online-check-in',
  },
  MU: {
    label: 'China Eastern',
    travelType: 'air',
  },
  CZ: {
    label: 'China Southern',
    travelType: 'air',
    checkInUrl:
      'https://extra.csair.com/content/TravelCenter/login.html?lang=cn#/seatLogin&locale=en',
  },
  U2: {
    label: 'easyJet',
    travelType: 'air',
    checkInUrl: 'https://www.easyjet.com/en?accntmdl=2',
  },
  EW: {
    label: 'Eurowings',
    travelType: 'air',
    checkInUrl: 'https://www.eurowings.com/en/my-trip/checkin.html',
  },
  JL: {
    label: 'Japan Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.jal.co.jp/uk/en/',
  },
  PR: {
    label: 'Philippine Airlines',
    travelType: 'air',
  },
  QF: {
    label: 'Qantas',
    travelType: 'air',
    checkInUrl: 'https://www.qantas.com/au/en/travel-info/check-in.html',
  },
  '3U': {
    label: 'Sichuan Airlines',
    travelType: 'air',
  },
  Y4: {
    label: 'Volaris',
    travelType: 'air',
  },
  VY: {
    label: 'Vueling',
    travelType: 'air',
    checkInUrl:
      'https://tickets.vueling.com/SearchWebCheckin.aspx?Culture=en-GB&AspxAutoDetectCookieSupport=1',
  },
  MD: {
    label: 'Air Madagascar',
    travelType: 'air',
  },
  TN: {
    label: 'Air Tahiti Nui',
    travelType: 'air',
  },
  NX: {
    label: 'Air Macau',
    travelType: 'air',
  },
  RC: {
    label: 'Atlantic Airways',
    travelType: 'air',
  },
  S4: {
    label: 'Azores Airlines',
    travelType: 'air',
  },
  UP: {
    label: 'Bahamasair',
    travelType: 'air',
  },
  BG: {
    label: 'Biman Airlines',
    travelType: 'air',
  },
  OB: {
    label: 'BoA',
    travelType: 'air',
  },
  KX: {
    label: 'Cayman Airways',
    travelType: 'air',
  },
  LF: {
    label: 'Contour Aviation',
    travelType: 'air',
  },
  FJ: {
    label: 'Fiji Airways',
    travelType: 'air',
  },
  OM: {
    label: 'MIAT Mongolian Airlines',
    travelType: 'air',
  },
  NP: {
    label: 'Nile Air',
    travelType: 'air',
    checkInUrl: 'https://www.nileair.com/#/en/home',
  },
  PW: {
    label: 'Precision Air',
    travelType: 'air',
  },
  WB: {
    label: 'RwandAir',
    travelType: 'air',
  },
  DT: {
    label: 'TAAG Angola',
    travelType: 'air',
  },
  UR: {
    label: 'Uganda Airlines',
    travelType: 'air',
  },
  WM: {
    label: 'Winair',
    travelType: 'air',
  },
  VW: {
    label: 'Aeromar',
    travelType: 'air',
  },
  AH: {
    label: 'Air Algerie',
    travelType: 'air',
  },
  PX: {
    label: 'Air Niugini',
    travelType: 'air',
  },
  '4Z': {
    label: 'Airlink',
    travelType: 'air',
  },
  DE: {
    label: 'Condor',
    travelType: 'air',
  },
  CM: {
    label: 'Copa Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.copaair.com/en-gs/',
  },
  MS: {
    label: 'Egyptair',
    travelType: 'air',
    checkInUrl: 'https://checkin.si.amadeus.net/static/PRD/MS/#/identification',
  },
  ET: {
    label: 'Ethiopian Airlines',
    travelType: 'air',
  },
  FZ: {
    label: 'Flydubai',
    travelType: 'air',
  },
  GA: {
    label: 'Garuda Indonesia',
    travelType: 'air',
    checkInUrl:
      'https://checkin.si.amadeus.net/1ASIHSSCWEBGA/sscwga/checkin?ln=en',
  },
  G3: {
    label: 'Gol',
    travelType: 'air',
    checkInUrl: 'https://b2c.voegol.com.br/check-in/',
  },
  HU: {
    label: 'Hainan Airlines',
    travelType: 'air',
  },
  KU: {
    label: 'Kuwait Airways',
    travelType: 'air',
  },
  OD: {
    label: 'Malindo Air',
    travelType: 'air',
  },
  ME: {
    label: 'Middle East Airlines',
    travelType: 'air',
  },
  WY: {
    label: 'Oman Air',
    travelType: 'air',
  },
  PK: {
    label: 'Pakistan Int. Airlines',
    travelType: 'air',
  },
  AT: {
    label: 'Royal Air Maroc',
    travelType: 'air',
  },
  SV: {
    label: 'Saudia',
    travelType: 'air',
  },
  TR: {
    label: 'Scoot',
    travelType: 'air',
  },
  ZH: {
    label: 'Shenzhen Airlines',
    travelType: 'air',
  },
  IE: {
    label: 'Solomon Airlines',
    travelType: 'air',
  },
  XQ: {
    label: 'SunExpress',
    travelType: 'air',
  },
  RO: {
    label: 'Tarom',
    travelType: 'air',
    checkInUrl:
      'https://www.tarom.ro/en/flights-and-reservations/online-check-in/',
  },
  HV: {
    label: 'Transavia',
    travelType: 'air',
    checkInUrl: 'https://www.transavia.com/en-EU/home/',
  },
  TO: {
    label: 'Transavia',
    travelType: 'air',
  },
  VN: {
    label: 'Vietnam Airlines',
    travelType: 'air',
  },
  V7: {
    label: 'Volotea',
    travelType: 'air',
  },
  MF: {
    label: 'Xiamen Air',
    travelType: 'air',
  },
  HX: {
    label: 'Hong Kong Airlines',
    travelType: 'air',
  },
  BP: {
    label: 'Air Botswana',
    travelType: 'air',
  },
  MK: {
    label: 'Air Mauritius',
    travelType: 'air',
  },
  HM: {
    label: 'Air Seychelles',
    travelType: 'air',
  },
  RA: {
    label: 'Nepal Airlines',
    travelType: 'air',
  },
  PY: {
    label: 'Surinam Airways',
    travelType: 'air',
  },
  G4: {
    label: 'Allegiant',
    travelType: 'air',
  },
  EU: {
    label: 'Chengdu Airlines',
    travelType: 'air',
  },
  '6E': {
    label: 'IndiGo Airlines',
    travelType: 'air',
  },
  LS: {
    label: 'Jet2',
    travelType: 'air',
    checkInUrl: 'https://www.jet2.com/en/login',
  },
  HO: {
    label: 'Juneyao Airlines',
    travelType: 'air',
  },
  DP: {
    label: 'Pobeda',
    travelType: 'air',
  },
  FR: {
    label: 'Ryanair',
    travelType: 'air',
    checkInUrl: 'https://www.ryanair.com/us/en/lp/check-in',
  },
  PQ: {
    label: 'SkyUp Airlines',
    travelType: 'air',
  },
  SG: {
    label: 'Spicejet',
    travelType: 'air',
    checkInUrl: 'https://www.spicejet.com/#checkin',
  },
  NK: {
    label: 'Spirit Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.spirit.com/#check-in',
  },
  '9C': {
    label: 'Spring Airlines',
    travelType: 'air',
  },
  W6: {
    label: 'Wizz Air',
    travelType: 'air',
    checkInUrl: 'https://wizzair.com/',
  },
  W9: {
    label: 'Wizz Air',
    travelType: 'air',
  },
  Y6: {
    label: 'AB Aviation',
    travelType: 'air',
  },
  S0: {
    label: 'Aerolineas Sosa',
    travelType: 'air',
  },
  V5: {
    label: 'Aerovias DAP',
    travelType: 'air',
  },
  HN: {
    label: 'Afghan Jet',
    travelType: 'air',
  },
  XU: {
    label: 'African Express',
    travelType: 'air',
  },
  UY: {
    label: 'Air Caucasus',
    travelType: 'air',
  },
  Y2: {
    label: 'Air Century',
    travelType: 'air',
  },
  ZM: {
    label: 'Air Manas',
    travelType: 'air',
  },
  '7P': {
    label: 'Air Panama',
    travelType: 'air',
  },
  D7: {
    label: 'AirAsia X',
    travelType: 'air',
  },
  T6: {
    label: 'AirSWIFT',
    travelType: 'air',
  },
  AP: {
    label: 'AlbaStar',
    travelType: 'air',
  },
  MZ: {
    label: 'Amakusa Airlines',
    travelType: 'air',
  },
  Q3: {
    label: 'Anguilla Air',
    travelType: 'air',
  },
  '6A': {
    label: 'Armenia Airways',
    travelType: 'air',
  },
  '5O': {
    label: 'ASL Airlines',
    travelType: 'air',
  },
  LZ: {
    label: 'Belleair',
    travelType: 'air',
  },
  BAY: {
    label: 'Bravo Airways',
    travelType: 'air',
  },
  '9Q': {
    label: 'Caicos Express Airways',
    travelType: 'air',
  },
  '9J': {
    label: 'Dana Air',
    travelType: 'air',
  },
  DCT: {
    label: 'Directflight',
    travelType: 'air',
  },
  E7: {
    label: 'Equaflight Service',
    travelType: 'air',
  },
  E4: {
    label: 'Estelar',
    travelType: 'air',
  },
  '5H': {
    label: 'Fly540',
    travelType: 'air',
  },
  '4F': {
    label: 'Freedom Airline Express',
    travelType: 'air',
  },
  Y5: {
    label: 'Golden Myanmar',
    travelType: 'air',
  },
  I8: {
    label: 'Izhavia',
    travelType: 'air',
  },
  J6: {
    label: 'Jamaica Air Shuttle',
    travelType: 'air',
  },
  Y9: {
    label: 'Kish Air',
    travelType: 'air',
  },
  '6O': {
    label: 'Orbest',
    travelType: 'air',
  },
  OF: {
    label: 'Overland Airways',
    travelType: 'air',
  },
  PF: {
    label: 'Primera Air',
    travelType: 'air',
  },
  FV: {
    label: 'Rossiya',
    travelType: 'air',
  },
  NL: {
    label: 'Shaheen Air',
    travelType: 'air',
  },
  PV: {
    label: 'St Barth',
    travelType: 'air',
  },
  RB: {
    label: 'Syrian Air',
    travelType: 'air',
  },
  HH: {
    label: 'Taban Air',
    travelType: 'air',
  },
  '9D': {
    label: 'Toumai Air',
    travelType: 'air',
  },
  KTB: {
    label: 'Transaviabaltika',
    travelType: 'air',
  },
  '4T': {
    label: 'Transwest Air',
    travelType: 'air',
  },
  U8: {
    label: 'TUS Airways',
    travelType: 'air',
  },
  UF: {
    label: 'UM Air',
    travelType: 'air',
  },
  '4H': {
    label: 'United Airways',
    travelType: 'air',
  },
  QU: {
    label: 'UTair',
    travelType: 'air',
  },
  VU: {
    label: 'Veca Airlines',
    travelType: 'air',
  },
  '2W': {
    label: 'World 2 Fly',
    travelType: 'air',
  },
  YE: {
    label: 'Yanair',
    travelType: 'air',
  },
  YT: {
    label: 'Yeti Airlines',
    travelType: 'air',
  },
  AN: {
    label: 'Advanced Air',
    travelType: 'air',
  },
  E5: {
    label: 'Air Arabia Egypt',
    travelType: 'air',
  },
  BX: {
    label: 'Air Busan',
    travelType: 'air',
  },
  SM: {
    label: 'Air Cairo',
    travelType: 'air',
  },
  '9H': {
    label: 'Air Changan',
    travelType: 'air',
  },
  XK: {
    label: 'Air Corsica',
    travelType: 'air',
  },
  HF: {
    label: 'Air Cote Dlvoire',
    travelType: 'air',
  },
  X4: {
    label: 'Air Excursions',
    travelType: 'air',
  },
  GL: {
    label: 'Air Greenland',
    travelType: 'air',
    checkInUrl:
      'https://checkin.si.amadeus.net/1ASIHSSCWEBGL/sscwgl/checkin?ln=en',
  },
  '3H': {
    label: 'Air Inuit',
    travelType: 'air',
  },
  WJ: {
    label: 'Air Labrador',
    travelType: 'air',
  },
  LT: {
    label: 'Air Lituanica',
    travelType: 'air',
  },
  P4: {
    label: 'Air Peace',
    travelType: 'air',
  },
  HC: {
    label: 'Air Senegal',
    travelType: 'air',
  },
  TC: {
    label: 'Air Tanzania',
    travelType: 'air',
  },
  IX: {
    label: 'Air-India Express',
    travelType: 'air',
  },
  QZ: {
    label: 'AirAsia',
    travelType: 'air',
  },
  Z2: {
    label: 'AirAsia',
    travelType: 'air',
  },
  I5: {
    label: 'AirAsia India',
    travelType: 'air',
  },
  CG: {
    label: 'Airlines PNG',
    travelType: 'air',
  },
  TL: {
    label: 'Airnorth',
    travelType: 'air',
  },
  QQ: {
    label: 'Alliance Airlines',
    travelType: 'air',
  },
  '6R': {
    label: 'Alrosa',
    travelType: 'air',
  },
  W3: {
    label: 'Arik Air',
    travelType: 'air',
  },
  IZ: {
    label: 'Arkia',
    travelType: 'air',
  },
  A2: {
    label: 'Astra Airlines',
    travelType: 'air',
  },
  TBZ: {
    label: 'ATA Airlines',
    travelType: 'air',
  },
  UI: {
    label: 'Auric Air',
    travelType: 'air',
  },
  YK: {
    label: 'Avia Traffic',
    travelType: 'air',
  },
  A4: {
    label: 'Azimuth',
    travelType: 'air',
  },
  QH: {
    label: 'Bamboo Airways',
    travelType: 'air',
  },
  JV: {
    label: 'Bearskin Airlines',
    travelType: 'air',
  },
  '8E': {
    label: 'Bering Air',
    travelType: 'air',
  },
  SI: {
    label: 'Blue Island',
    travelType: 'air',
  },
  BZ: {
    label: 'Bluebird Airways',
    travelType: 'air',
  },
  '4B': {
    label: 'Boutique Air',
    travelType: 'air',
  },
  MX: {
    label: 'Breeze Airways',
    travelType: 'air',
  },
  U4: {
    label: 'Buddha Air',
    travelType: 'air',
  },
  VE: {
    label: 'CAI Second',
    travelType: 'air',
  },
  A7: {
    label: 'Calafia',
    travelType: 'air',
  },
  MO: {
    label: 'Calm Air',
    travelType: 'air',
  },
  '9K': {
    label: 'Cape Air',
    travelType: 'air',
  },
  CPN: {
    label: 'Caspian Airlines',
    travelType: 'air',
  },
  DG: {
    label: 'Cebgo',
    travelType: 'air',
  },
  '5Z': {
    label: 'CemAir',
    travelType: 'air',
  },
  '9M': {
    label: 'Central Mountain Air',
    travelType: 'air',
  },
  '6Q': {
    label: 'Cham Wings Airlines',
    travelType: 'air',
  },
  CQ: {
    label: 'Coastal Aviation',
    travelType: 'air',
  },
  '8Z': {
    label: 'Congo Airways',
    travelType: 'air',
  },
  V0: {
    label: 'Conviasa',
    travelType: 'air',
  },
  CD: {
    label: 'Corendon',
    travelType: 'air',
  },
  XC: {
    label: 'Corendon',
    travelType: 'air',
  },
  DX: {
    label: 'Danish Air',
    travelType: 'air',
  },
  T3: {
    label: 'Eastern Airways',
    travelType: 'air',
  },
  '8J': {
    label: 'EcoJet',
    travelType: 'air',
  },
  EV: {
    label: 'ExpressJet',
    travelType: 'air',
  },
  FO: {
    label: 'Felix Airways',
    travelType: 'air',
  },
  FY: {
    label: 'Firefly',
    travelType: 'air',
  },
  IF: {
    label: 'Fly Baghdad',
    travelType: 'air',
  },
  '7B': {
    label: 'Fly Blue Crane',
    travelType: 'air',
  },
  '5F': {
    label: 'Fly One',
    travelType: 'air',
  },
  FP: {
    label: 'Fly Pelican',
    travelType: 'air',
  },
  F3: {
    label: 'flyadeal',
    travelType: 'air',
  },
  FH: {
    label: 'Freebird Airlines',
    travelType: 'air',
  },
  JH: {
    label: 'Fuji Dream Airlines',
    travelType: 'air',
  },
  '4G': {
    label: 'Gazpromavia',
    travelType: 'air',
  },
  A9: {
    label: 'Georgian Airways',
    travelType: 'air',
  },
  GM: {
    label: 'Germania Flug',
    travelType: 'air',
  },
  H4: {
    label: 'HiSky',
    travelType: 'air',
  },
  FW: {
    label: 'Ibex Airlines',
    travelType: 'air',
  },
  JY: {
    label: 'interCaribbean Airways',
    travelType: 'air',
  },
  '3L': {
    label: 'InterSky',
    travelType: 'air',
  },
  B9: {
    label: 'Iran Air Tours',
    travelType: 'air',
  },
  '2O': {
    label: 'Island Air Service',
    travelType: 'air',
  },
  JM: {
    label: 'Jambojet',
    travelType: 'air',
  },
  R5: {
    label: 'Jordan Aviation',
    travelType: 'air',
  },
  NU: {
    label: 'JTA',
    travelType: 'air',
  },
  '3J': {
    label: 'Jubba Airways',
    travelType: 'air',
  },
  RQ: {
    label: 'Kam Air',
    travelType: 'air',
  },
  KG: {
    label: 'Key Lime Air',
    travelType: 'air',
  },
  QV: {
    label: 'Lao Airlines',
    travelType: 'air',
  },
  LN: {
    label: 'Libyan Airlines',
    travelType: 'air',
  },
  BN: {
    label: 'Luxwing',
    travelType: 'air',
  },
  Q2: {
    label: 'Maldivian',
    travelType: 'air',
  },
  L6: {
    label: 'Mauritania Airlines',
    travelType: 'air',
  },
  '2M': {
    label: 'Maya Island Air',
    travelType: 'air',
  },
  RRV: {
    label: 'Mombasa Air Safari',
    travelType: 'air',
  },
  '8M': {
    label: 'Myanmar Airways',
    travelType: 'air',
  },
  '8P': {
    label: 'Pacific Coasttal Airlines',
    travelType: 'air',
  },
  P6: {
    label: 'Pascan Aviation',
    travelType: 'air',
    checkInUrl: 'https://res.pascan.com/res/search',
  },
  MM: {
    label: 'Peach',
    travelType: 'air',
  },
  PI: {
    label: 'Polar Airlines',
    travelType: 'air',
  },
  P0: {
    label: 'Proflight Zambia',
    travelType: 'air',
  },
  IQ: {
    label: 'Qazaq Air',
    travelType: 'air',
  },
  '7H': {
    label: 'Ravn Alaska',
    travelType: 'air',
  },
  F2: {
    label: 'Safarilink Aviation',
    travelType: 'air',
  },
  OV: {
    label: 'SalamAir',
    travelType: 'air',
  },
  RZ: {
    label: 'Sansa',
    travelType: 'air',
  },
  DV: {
    label: 'Scat Air',
    travelType: 'air',
  },
  ER: {
    label: 'SereneAir',
    travelType: 'air',
  },
  '3M': {
    label: 'Silver Airways',
    travelType: 'air',
  },
  DU: {
    label: 'Skyjet',
    travelType: 'air',
  },
  BC: {
    label: 'Skymark Airlines',
    travelType: 'air',
  },
  QN: {
    label: 'Skytrans Airlines',
    travelType: 'air',
  },
  '6J': {
    label: 'Solaseed Air',
    travelType: 'air',
  },
  SZ: {
    label: 'Somon Air',
    travelType: 'air',
  },
  S8: {
    label: 'Sounds Air',
    travelType: 'air',
  },
  '9X': {
    label: 'Southern Airways Express',
    travelType: 'air',
  },
  S5: {
    label: 'Star Air',
    travelType: 'air',
  },
  JX: {
    label: 'Starlux Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.starlux-airlines.com/en-US',
  },
  Y8: {
    label: 'Suparna Airlines',
    travelType: 'air',
  },
  IU: {
    label: 'Super Air Jet',
    travelType: 'air',
  },
  FS: {
    label: 'Syphax Airlines',
    travelType: 'air',
  },
  '5U': {
    label: 'TAG Airlines',
    travelType: 'air',
  },
  YQ: {
    label: 'TAR Aerolineas',
    travelType: 'air',
  },
  '3T': {
    label: 'Tarco Air',
    travelType: 'air',
  },
  VZ: {
    label: 'Thai Vietjet Air',
    travelType: 'air',
  },
  '2T': {
    label: 'Timbis Air',
    travelType: 'air',
  },
  C3: {
    label: 'Trade Air',
    travelType: 'air',
  },
  IL: {
    label: 'Trigana Air',
    travelType: 'air',
  },
  '9N': {
    label: 'Tropic Aair',
    travelType: 'air',
  },
  TZ: {
    label: 'Tsaradia',
    travelType: 'air',
    checkInUrl: 'https://makeabooking.flyscoot.com/',
  },
  T7: {
    label: 'Twin Jet',
    travelType: 'air',
  },
  UQ: {
    label: 'Urumqi Air',
    travelType: 'air',
  },
  BS: {
    label: 'US-Bangla Airlines',
    travelType: 'air',
  },
  VRH: {
    label: 'Varesh Airlines',
    travelType: 'air',
  },
  WSG: {
    label: 'Wasaya Airways',
    travelType: 'air',
  },
  '7W': {
    label: 'Windrose Airlines',
    travelType: 'air',
  },
  '5W': {
    label: 'Wizz Air Abu Dhabi',
    travelType: 'air',
  },
  YC: {
    label: 'Yamal Airlines',
    travelType: 'air',
  },
  IY: {
    label: 'Yemenia',
    travelType: 'air',
  },
  IZG: {
    label: 'Zagros Airlines',
    travelType: 'air',
  },
  NB: {
    label: 'Berniq Airways',
    travelType: 'air',
  },
  UZ: {
    label: 'Buraq Air',
    travelType: 'air',
  },
  YU: {
    label: 'EuroAtalntic',
    travelType: 'air',
  },
  G7: {
    label: 'GoJet Airlines',
    travelType: 'air',
  },
  G2: {
    label: 'GullivAir',
    travelType: 'air',
  },
  H7: {
    label: 'HiSky',
    travelType: 'air',
  },
  II: {
    label: 'IBC Airways',
    travelType: 'air',
  },
  '3Q': {
    label: 'KC International',
    travelType: 'air',
  },
  M2: {
    label: 'MHS Aviation',
    travelType: 'air',
  },
  ON: {
    label: 'Nauru Airlines',
    travelType: 'air',
  },
  YP: {
    label: 'Perimeter Aviation',
    travelType: 'air',
  },
  '6Y': {
    label: 'SmartLynx Airlines',
    travelType: 'air',
  },
  TEZ: {
    label: 'TezJet Airlines',
    travelType: 'air',
  },
  UN: {
    label: 'Transaero',
    travelType: 'air',
  },
  GE: {
    label: 'TransAsia',
    travelType: 'air',
  },
  G9: {
    label: 'Air Arabia',
    travelType: 'air',
  },
  '3O': {
    label: 'Air Arabia Maroc',
    travelType: 'air',
  },
  VT: {
    label: 'Air Tahiti',
    travelType: 'air',
  },
  AK: {
    label: 'AirAsia',
    travelType: 'air',
  },
  '9I': {
    label: 'Alliance Air',
    travelType: 'air',
  },
  KP: {
    label: 'ASKY Airlines',
    travelType: 'air',
  },
  HZ: {
    label: 'Aurora',
    travelType: 'air',
  },
  ZF: {
    label: 'Azur Air',
    travelType: 'air',
  },
  J4: {
    label: 'Badr Airlines',
    travelType: 'air',
  },
  ID: {
    label: 'Baltik Air',
    travelType: 'air',
  },
  JD: {
    label: 'Beijing Capital',
    travelType: 'air',
  },
  '0B': {
    label: 'Blue Air',
    travelType: 'air',
  },
  FB: {
    label: 'Bulgaria Air',
    travelType: 'air',
    checkInUrl: 'https://www.air.bg/en',
  },
  '5T': {
    label: 'Canadian North',
    travelType: 'air',
  },
  '5J': {
    label: 'Cebu Pacific',
    travelType: 'air',
  },
  G5: {
    label: 'China Express',
    travelType: 'air',
  },
  KN: {
    label: 'China United',
    travelType: 'air',
  },
  QG: {
    label: 'Citilink',
    travelType: 'air',
  },
  GY: {
    label: 'Colorful Guizhou Airlines',
    travelType: 'air',
  },
  XR: {
    label: 'Corendon',
    travelType: 'air',
  },
  DZ: {
    label: 'Donghai Airlines',
    travelType: 'air',
  },
  WK: {
    label: 'Edelweiss Air',
    travelType: 'air',
    checkInUrl:
      'https://checkin.flyedelweiss.com/web?languageChange=true&l=en&_t=1461592736#ck_retrieve',
  },
  K2: {
    label: 'Eurolot',
    travelType: 'air',
  },
  BR: {
    label: 'EVA Air',
    travelType: 'air',
    checkInUrl:
      'https://booking.evaair.com/flyeva/EVA/B2C/manage-your-trip/log_in.aspx?lang=en-Global',
  },
  F8: {
    label: 'Flair Airlines',
    travelType: 'air',
  },
  W2: {
    label: 'FlexFlight',
    travelType: 'air',
  },
  F9: {
    label: 'Frontier Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.flyfrontier.com/travel/my-trips/check-in/#checkIn',
  },
  FU: {
    label: 'Fuzhou Airlines',
    travelType: 'air',
  },
  G8: {
    label: 'Go Air',
    travelType: 'air',
  },
  GX: {
    label: 'GX Airlines',
    travelType: 'air',
  },
  NS: {
    label: 'Hebei Airlines',
    travelType: 'air',
  },
  UO: {
    label: 'Hong Kong Express',
    travelType: 'air',
  },
  A6: {
    label: 'Hongtu Airlines',
    travelType: 'air',
  },
  IO: {
    label: 'IrAero',
    travelType: 'air',
  },
  IR: {
    label: 'Iran Air',
    travelType: 'air',
  },
  EP: {
    label: 'Iran Aseman',
    travelType: 'air',
  },
  IA: {
    label: 'Iraqi Airways',
    travelType: 'air',
  },
  '7C': {
    label: 'Jeju Air',
    travelType: 'air',
  },
  JA: {
    label: 'JetSmart',
    travelType: 'air',
  },
  GK: {
    label: 'Jetstar',
    travelType: 'air',
  },
  JQ: {
    label: 'Jetstar',
    travelType: 'air',
  },
  '3K': {
    label: 'Jetstar Asia',
    travelType: 'air',
  },
  RY: {
    label: 'Jiangxi Air',
    travelType: 'air',
  },
  LJ: {
    label: 'Jin Air',
    travelType: 'air',
  },
  JR: {
    label: 'Joy Air',
    travelType: 'air',
  },
  KY: {
    label: 'Kunming Airlines',
    travelType: 'air',
  },
  JT: {
    label: 'Lion Air',
    travelType: 'air',
  },
  LM: {
    label: 'Loganair',
    travelType: 'air',
  },
  GJ: {
    label: 'Loong Air',
    travelType: 'air',
  },
  '8L': {
    label: 'Lucky Air',
    travelType: 'air',
  },
  W5: {
    label: 'Mahan Air',
    travelType: 'air',
  },
  AE: {
    label: 'Mandarin Airlines',
    travelType: 'air',
  },
  IN: {
    label: 'Nam Air',
    travelType: 'air',
  },
  XY: {
    label: 'Nas Air',
    travelType: 'air',
  },
  NO: {
    label: 'Neos',
    travelType: 'air',
  },
  DD: {
    label: 'Nok Air',
    travelType: 'air',
  },
  BK: {
    label: 'Okay Airways',
    travelType: 'air',
  },
  EO: {
    label: 'Pegas Fly',
    travelType: 'air',
  },
  OG: {
    label: 'Play',
    travelType: 'air',
  },
  PB: {
    label: 'Provincial Airlines',
    travelType: 'air',
  },
  QW: {
    label: 'Qingdao Airlines',
    travelType: 'air',
  },
  BI: {
    label: 'Royal Brunei Airlines',
    travelType: 'air',
  },
  DR: {
    label: 'Rulli Airlines',
    travelType: 'air',
  },
  '7S': {
    label: 'Ryan Air Service',
    travelType: 'air',
  },
  RK: {
    label: 'Ryanair UK',
    travelType: 'air',
  },
  '9R': {
    label: 'SATENA',
    travelType: 'air',
  },
  D2: {
    label: 'Severstal',
    travelType: 'air',
  },
  SC: {
    label: 'Shandong Airlines',
    travelType: 'air',
  },
  FM: {
    label: 'Shanghai Airlines',
    travelType: 'air',
  },
  UL: {
    label: 'SriLankan Airlines',
    travelType: 'air',
  },
  SJ: {
    label: 'Sriwijaya Air',
    travelType: 'air',
  },
  SY: {
    label: 'Sun Country Airlines',
    travelType: 'air',
  },
  WG: {
    label: 'Sunwing',
    travelType: 'air',
  },
  WO: {
    label: 'Swoop',
    travelType: 'air',
  },
  FD: {
    label: 'Thai AirAsia',
    travelType: 'air',
  },
  SL: {
    label: 'Thai Lion Air',
    travelType: 'air',
  },
  WE: {
    label: 'Thai Smile',
    travelType: 'air',
  },
  GS: {
    label: 'Tianjin Airlines',
    travelType: 'air',
  },
  TV: {
    label: 'Tibet Airlines',
    travelType: 'air',
  },
  TB: {
    label: 'TUI Fly',
    travelType: 'air',
  },
  X3: {
    label: 'TUI Fly',
    travelType: 'air',
  },
  OR: {
    label: 'TUI Fly',
    travelType: 'air',
  },
  TW: {
    label: 'Tway Air',
    travelType: 'air',
  },
  B7: {
    label: 'Uni Air',
    travelType: 'air',
  },
  HY: {
    label: 'Uzbekiztan Airways',
    travelType: 'air',
  },
  VJ: {
    label: 'VietJet Air',
    travelType: 'air',
  },
  VH: {
    label: 'Viva Air Colombia',
    travelType: 'air',
  },
  VB: {
    label: 'VivaAerobus',
    travelType: 'air',
  },
  '2Z': {
    label: 'Voepass',
    travelType: 'air',
  },
  PN: {
    label: 'West Air',
    travelType: 'air',
  },
  IW: {
    label: 'Wings Air',
    travelType: 'air',
  },
  '8V': {
    label: 'Wright Air Service',
    travelType: 'air',
  },
  R3: {
    label: 'Yakutia',
    travelType: 'air',
    checkInUrl: 'https://www.orenair.ru/eng/',
  },
  '4V': {
    label: 'Fly Gangwon',
    travelType: 'air',
  },
  ND: {
    label: 'FMI Air',
    travelType: 'air',
  },
  I4: {
    label: 'Island Air Express',
    travelType: 'air',
  },
  '6H': {
    label: 'Israir',
    travelType: 'air',
  },
  YL: {
    label: 'Libyan Wings',
    travelType: 'air',
  },
  '7Y': {
    label: 'Mann Yadanarpon',
    travelType: 'air',
  },
  '3R': {
    label: 'Moskovia',
    travelType: 'air',
  },
  '8Y': {
    label: 'Pan Pacific Airlines',
    travelType: 'air',
  },
  HI: {
    label: 'Papillion Airways',
    travelType: 'air',
  },
  '8F': {
    label: 'STP Airways',
    travelType: 'air',
  },
  WT: {
    label: 'Swiftair',
    travelType: 'air',
  },
  EB: {
    label: 'Wamos Air',
    travelType: 'air',
  },
  N2: {
    label: 'Aero Contractors',
    travelType: 'air',
  },
  M0: {
    label: 'Aero Mongolia',
    travelType: 'air',
  },
  N3: {
    label: 'Aerolineas Mas',
    travelType: 'air',
  },
  A8: {
    label: 'Aerolink Uganda',
    travelType: 'air',
  },
  AW: {
    label: 'Africa World Airlines',
    travelType: 'air',
  },
  J7: {
    label: 'Afrijet',
    travelType: 'air',
  },
  '8U': {
    label: 'Afriqiyah',
    travelType: 'air',
  },
  '6I': {
    label: 'Air Alsie',
    travelType: 'air',
    checkInUrl:
      'https://book.alsieexpress.dk/check-in/search?_ga=2.110481933.961372572.1712220346-1704084069.1712220345',
  },
  '3S': {
    label: 'Air Antilles Express',
    travelType: 'air',
  },
  KF: {
    label: 'Air Belgium',
    travelType: 'air',
  },
  '2J': {
    label: 'Air Burkina',
    travelType: 'air',
  },
  '3C': {
    label: 'Air Chathams',
    travelType: 'air',
  },
  HD: {
    label: 'Air Do',
    travelType: 'air',
  },
  XLL: {
    label: 'Air Excel',
    travelType: 'air',
  },
  RLY: {
    label: 'Air Loyaute',
    travelType: 'air',
  },
  RS: {
    label: 'Air Seoul',
    travelType: 'air',
  },
  PA: {
    label: 'Airblue',
    travelType: 'air',
  },
  SB: {
    label: 'Aircalin',
    travelType: 'air',
  },
  P2: {
    label: 'AirKenya Express',
    travelType: 'air',
  },
  UM: {
    label: 'Airr Zimbabwe',
    travelType: 'air',
  },
  FL: {
    label: 'AirTran',
    travelType: 'air',
  },
  '2B': {
    label: 'Albawings',
    travelType: 'air',
  },
  Z8: {
    label: 'Amaszonas',
    travelType: 'air',
  },
  '2G': {
    label: 'Angara Airlines',
    travelType: 'air',
  },
  FG: {
    label: 'Ariana',
    travelType: 'air',
  },
  AG: {
    label: 'Aruba Airlines',
    travelType: 'air',
  },
  GR: {
    label: 'Aurigny',
    travelType: 'air',
  },
  XP: {
    label: 'Avelo Airlines',
    travelType: 'air',
  },
  GD: {
    label: 'Aviair',
    travelType: 'air',
  },
  T9: {
    label: 'Aviator Airlines',
    travelType: 'air',
  },
  '9V': {
    label: 'Avior',
    travelType: 'air',
  },
  YB: {
    label: 'Borajet',
    travelType: 'air',
  },
  BU: {
    label: 'CAA',
    travelType: 'air',
  },
  '4A': {
    label: 'California Pacific Airlines',
    travelType: 'air',
  },
  QC: {
    label: 'Camair-Co',
    travelType: 'air',
    checkInUrl: 'https://webcheckin.info/camair-co/check-in',
  },
  K6: {
    label: 'Cambodia Angkor Air',
    travelType: 'air',
  },
  PM: {
    label: 'Canaryfly',
    travelType: 'air',
  },
  C2: {
    label: 'CEIBA',
    travelType: 'air',
  },
  CE: {
    label: 'Chalair',
    travelType: 'air',
  },
  CU: {
    label: 'Cubana',
    travelType: 'air',
  },
  D3: {
    label: 'Daallo Airlines',
    travelType: 'air',
  },
  KB: {
    label: 'Druk Air',
    travelType: 'air',
  },
  '7Q': {
    label: 'Elite Airways',
    travelType: 'air',
  },
  ES: {
    label: 'Estelar',
    travelType: 'air',
  },
  '5V': {
    label: 'Everts Air',
    travelType: 'air',
  },
  ZD: {
    label: 'EWA Air',
    travelType: 'air',
  },
  OW: {
    label: 'Executive Airlines',
    travelType: 'air',
  },
  FE: {
    label: 'Far Eastern Air',
    travelType: 'air',
  },
  FN: {
    label: 'Fastjet',
    travelType: 'air',
  },
  FXX: {
    label: 'Felix Airways',
    travelType: 'air',
  },
  '4J': {
    label: 'FlyDamas',
    travelType: 'air',
  },
  FT: {
    label: 'FlyEgypt',
    travelType: 'air',
  },
  '9A': {
    label: 'Gran Colombia de Aviacion',
    travelType: 'air',
  },
  CN: {
    label: 'Grand China Air',
    travelType: 'air',
  },
  Q9: {
    label: 'Green Africa Airways',
    travelType: 'air',
  },
  '7Z': {
    label: 'Halcyonair',
    travelType: 'air',
  },
  HBR: {
    label: 'Hebridean Air Service',
    travelType: 'air',
  },
  H9: {
    label: 'Himalaya Airlines',
    travelType: 'air',
  },
  OI: {
    label: 'Hinterland Aviation',
    travelType: 'air',
  },
  QI: {
    label: 'Ibom Air',
    travelType: 'air',
  },
  V8: {
    label: 'Iliamna Air Taxi',
    travelType: 'air',
  },
  WU: {
    label: 'Jetways airlines',
    travelType: 'air',
  },
  U5: {
    label: 'Karinou Airlines',
    travelType: 'air',
  },
  MN: {
    label: 'Kulula',
    travelType: 'air',
  },
  LQ: {
    label: 'Lanmei Airlines',
    travelType: 'air',
  },
  LK: {
    label: 'Lao Skyway',
    travelType: 'air',
  },
  JN: {
    label: 'Livingston',
    travelType: 'air',
  },
  JE: {
    label: 'Mango',
    travelType: 'air',
  },
  MYI: {
    label: 'MAYAir',
    travelType: 'air',
  },
  WV: {
    label: 'Midwest Airlines (Egypt)',
    travelType: 'air',
  },
  ZB: {
    label: 'Monarch',
    travelType: 'air',
  },
  M9: {
    label: 'Motor Sich',
    travelType: 'air',
  },
  UB: {
    label: 'Myanmar',
    travelType: 'air',
  },
  HW: {
    label: 'North-Wright Airways',
    travelType: 'air',
  },
  J3: {
    label: 'Northwestern Air',
    travelType: 'air',
  },
  VQ: {
    label: 'NovoAir',
    travelType: 'air',
  },
  OC: {
    label: 'Oriental Aiir Bridge',
    travelType: 'air',
  },
  ZP: {
    label: 'Paranair',
    travelType: 'air',
  },
  PU: {
    label: 'Plus Ultra',
    travelType: 'air',
  },
  '8N': {
    label: 'Regional Air',
    travelType: 'air',
  },
  RL: {
    label: 'Royal Flight',
    travelType: 'air',
  },
  '5R': {
    label: 'RUTACA Airlines',
    travelType: 'air',
  },
  FA: {
    label: 'Safair',
    travelType: 'air',
  },
  SP: {
    label: 'SATA',
    travelType: 'air',
  },
  SLD: {
    label: 'Silver Air',
    travelType: 'air',
  },
  HK: {
    label: 'Skippers',
    travelType: 'air',
  },
  PL: {
    label: 'Southern Air Charter',
    travelType: 'air',
  },
  IJ: {
    label: 'Spring Airlines Japan',
    travelType: 'air',
  },
  '7G': {
    label: 'Star Flyer',
    travelType: 'air',
  },
  '2I': {
    label: 'Star Peru',
    travelType: 'air',
  },
  S9: {
    label: 'Starbow',
    travelType: 'air',
  },
  S6: {
    label: 'Sunrise Airways',
    travelType: 'air',
  },
  EQ: {
    label: 'TAME',
    travelType: 'air',
  },
  SF: {
    label: 'Tassil Airlines',
    travelType: 'air',
  },
  XJ: {
    label: 'Thai AirAsia X',
    travelType: 'air',
  },
  IT: {
    label: 'Tigerair',
    travelType: 'air',
    checkInUrl: 'https://www.ita-airways.com/en_us/fly-ita/check-in.html',
  },
  TJ: {
    label: 'Tradewind Aviation',
    travelType: 'air',
  },
  TOM: {
    label: 'TUI Airways',
    travelType: 'air',
  },
  UG: {
    label: 'TunisAir Express',
    travelType: 'air',
  },
  T5: {
    label: 'Turkmenistan Airlines',
    travelType: 'air',
  },
  RT: {
    label: 'UVT Aero',
    travelType: 'air',
  },
  VNE: {
    label: 'Venezolana',
    travelType: 'air',
  },
  VV: {
    label: 'Viva Air Peru',
    travelType: 'air',
  },
  Q6: {
    label: 'Volaris Costa Rica',
    travelType: 'air',
  },
  ZN: {
    label: 'Zambia Airways',
    travelType: 'air',
  },
  ZG: {
    label: 'ZIPAR',
    travelType: 'air',
  },
  GT: {
    label: 'Air Guilin',
    travelType: 'air',
  },
  K7: {
    label: 'Air KBZ',
    travelType: 'air',
  },
  IK: {
    label: 'Air Kiribati',
    travelType: 'air',
  },
  CWM: {
    label: 'Air Marshall Islands',
    travelType: 'air',
  },
  '9U': {
    label: 'Air Moldova',
    travelType: 'air',
  },
  '4N': {
    label: 'Air North',
    travelType: 'air',
  },
  OP: {
    label: 'Air Pegasus',
    travelType: 'air',
  },
  GZ: {
    label: 'Air Rarotonga',
    travelType: 'air',
  },
  PJ: {
    label: 'Air Saint Pierre',
    travelType: 'air',
  },
  RSI: {
    label: 'Air Sunshine',
    travelType: 'air',
  },
  ATM: {
    label: 'Airlines of Tasmania',
    travelType: 'air',
  },
  WC: {
    label: 'Avianca',
    travelType: 'air',
  },
  GU: {
    label: 'Avianca',
    travelType: 'air',
  },
  EX: {
    label: 'Avianca',
    travelType: 'air',
  },
  '2K': {
    label: 'Avianca',
    travelType: 'air',
  },
  LR: {
    label: 'Avianca',
    travelType: 'air',
  },
  QL: {
    label: 'LASER Airlines',
    travelType: 'air',
  },
  K3: {
    label: 'Taquan Air',
    travelType: 'air',
  },
  JTU: {
    label: 'Zhetysu',
    travelType: 'air',
  },
  HP: {
    label: 'Amapola',
    travelType: 'air',
  },
  CL: {
    label: 'Lufthansa CityLine',
    travelType: 'air',
  },
  '2L': {
    label: 'Helvetic Airways',
    travelType: 'air',
    checkInUrl: 'https://www.helvetic.com/',
  },
  US: {
    label: 'American Airlines',
    travelType: 'air',
    checkInUrl:
      'https://www.aa.com/reservation/flightCheckInViewReservationsAccess.do?anchorEvent=false&from=Nav',
  },
  '6B': {
    label: 'Transavia Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.transavia.com/en-EU/home/',
  },
  MW: {
    label: 'Mokulele Airlines',
    travelType: 'air',
    checkInUrl: 'https://www.mokuleleairlines.com/',
  },
  '4O': {
    label: 'Iberia',
    travelType: 'air',
    checkInUrl:
      'https://www.iberia.com/es/online-checkin/?language=en&market=es#!/ibcose',
  },
  '6S': {
    label: 'Cham Wings Airlines',
    travelType: 'air',
    checkInUrl: 'https://chamwings.com/',
  },
  '7I': {
    label: 'Corendon Airlines',
    travelType: 'air',
    checkInUrl:
      'https://www.corendonairlines.com/book-and-manage/online-check-in',
  },
  '9B': {
    label: 'Accesrail',
    travelType: 'air',
  },
  JON: {
    label: 'JONAIR',
    travelType: 'air',
    checkInUrl: 'https://jonair.se/fore-avgang/',
  },
}

export default COMPANIES
