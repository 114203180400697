import React, { memo, useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TrainIcon from '@material-ui/icons/Train'
import Moment from 'react-moment'
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp'
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  getChargeableSeatAmount,
  getPassengersText,
} from '../../../../../utils/general'
import usePriceFormat from '../../../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  between: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  travelDate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  destination: {
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const DesktopAccomodationHeader = ({ train, index }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const scheduleSolution = train?.train?.scheduleSolution
  const { railstart, railend } = scheduleSolution
  const { travellers: searchTravellers } = useSelector(
    (state) => state.search.train
  )
  const [chargeableSeatAmount, setChargeableSeatAmount] = useState(0)
  const { chargeableSeats } = useSelector((state) => state.train)
  const railStartDestination = railstart.locationName
  const railEndDestination = railend.locationName
  const { Amount } = train?.pricing?.price
  const { railidentifier } = train?.train?.segments?.[0]
  const travellerText = getPassengersText(searchTravellers)
  const { formattedCurrency, formatPrice } = usePriceFormat()

  useEffect(() => {
    const amount = getChargeableSeatAmount(chargeableSeats, railidentifier)
    setChargeableSeatAmount(amount)
  }, [chargeableSeats])

  return (
    <>
      <Box className={classes.between} pb={2}>
        <Typography className={classes.destination} variant="h5">
          {railStartDestination.toLowerCase()} - {''}
          {railEndDestination.toLowerCase()}
        </Typography>
        <Box className={classes.align}>
          <Typography variant="body2">{travellerText}</Typography>

          <Box className={classes.align}>
            <Typography>{formattedCurrency}</Typography>
            <Typography className={classes.weight} variant="h5">
              {formatPrice(
                chargeableSeatAmount ? Amount + chargeableSeatAmount : Amount
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.align}>
        {index === 0 ? <ArrowForwardSharpIcon /> : <ArrowBackSharpIcon />}
        <Typography className={classes.weight}>
          {index === 0 ? t('outbound title') : t('inbound title')}:
        </Typography>
        <Typography>
          <Moment format="ddd D MMM">{railstart.dateTime}</Moment>
        </Typography>
        <Typography className={classes.weight}>
          <Moment format="HH:mm">{railstart.dateTime}</Moment>-
          <Moment format="HH:mm">{railend.dateTime}</Moment>
        </Typography>
      </Box>
      <Box className={classes.travelDate} pt={2}>
        <TrainIcon />
        <Typography>
          {t(train.pricing.bookingClass === '001' ? '1 class' : '2 class')},{' '}
          {t(`flexibility_${train.pricing.flexibility}`)}
        </Typography>
      </Box>
    </>
  )
}

export default memo(DesktopAccomodationHeader)
