import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: 'none',
    },
  },
  listItemTextWithoutIcon: {
    paddingLeft: theme.spacing(1),
  },
}))

const GenericMenuItems = ({ items, handleClose, anchorEl }) => {
  const classes = useStyles()

  const renderList = items.map((item, i) => {
    if (item.icon) {
      return (
        <ListItem key={i} component={Link} to={item.slug} onClick={handleClose}>
          <ListItemIcon style={{ minWidth: '40px' }}>
            {item.icon && <item.icon color="primary" />}
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary={item.title} />
        </ListItem>
      )
    }

    return (
      <ListItem key={i} component={Link} to={item.slug} onClick={handleClose}>
        <ListItemText
          className={classes.listItemTextWithoutIcon}
          primary={item.title}
        />
      </ListItem>
    )
  })

  return (
    <Menu
      PaperProps={{
        style: {
          width: 300,
          borderRadius: '8px',
          boxShadow: '0px 5px 25px 0px rgba(0, 0, 0, .15)',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {renderList}
      </List>
    </Menu>
  )
}

export default memo(GenericMenuItems)
