import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography, Box, Paper } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SmallGoogleMaps from '../../../components/googleMaps/smallGoogleMaps'
import UserRating from '../../../components/hotels/usersRating'
import { useTranslation } from 'react-i18next'
import ReusableDatpicker from '../../../components/reusable/datePicker/reusableDatePicker_new'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  card: { borderRadius: theme.spacing(1) },
  align: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
      '&:first-child': { marginLeft: 0 },
    },
  },
  alignItems: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  top: {
    marginTop: theme.spacing(4),
  },
  ratingContainer: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(1),
  },
  checkInCheckout: {
    padding: 5,
    borderRadius: theme.spacing(1),
    textTransform: 'uppercase',
  },
  adress: {
    display: 'flex',
    '& > *': {
      margin: '0 5px',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  choosebtn: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 4),
    letterSpacing: 1.1,
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
  },
  checkoutCheckinContainer: {
    fontWeight: 600,
    letterSpacing: '1.2',
    textTransform: 'capitalize',
  },
}))

const HotelDetails = ({ info, LONGITUDE, LATITUDE, searchParams }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { ratings } = useSelector((state) => state.hotels)
  const urlParams = useParams()
  const {
    checkIn,
    checkOut,
    guests,
    roomsCount,
    countryCode,
    cityCode,
    hotelCode,
  } = searchParams || urlParams || {}
  const classes = useStyles()
  const history = useHistory()

  const findRatings = ratings?.length
    ? ratings.find(
        (x) =>
          x.name?.toLowerCase() === info?.title.toLowerCase() ||
          x.hotelCode == hotelCode
      )
    : ''

  const setCheckIn = (date) => {
    const newCheckIn = moment(date).format('YYYY-MM-DD')

    const url = `/hotels/details/${countryCode}/${cityCode}/${hotelCode}/${newCheckIn}/${checkOut}/${guests}/${roomsCount}`
    history.push({
      pathname: url,
    })
  }

  const setCheckOut = (date) => {
    const newCheckOut = moment(date).format('YYYY-MM-DD')

    const url = `/hotels/details/${countryCode}/${cityCode}/${hotelCode}/${checkIn}/${newCheckOut}/${guests}/${roomsCount}`
    history.push({
      pathname: url,
    })
  }

  return (
    <Grid container direction={isMobile ? 'row' : 'row'} spacing={2}>
      {isMobile ? (
        <Grid item xs={12} sm>
          <Paper className={classes.ratingContainer} elevation={0}>
            <Box className={classes.align}>
              <UserRating rating={findRatings} padding long />
            </Box>
          </Paper>
        </Grid>
      ) : (
        ''
      )}

      {isMobile ? (
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.card}>
            <Box p={2} pb={1} pt={1}>
              <SmallGoogleMaps lat={LATITUDE} lng={LONGITUDE} />
            </Box>
          </Paper>
        </Grid>
      ) : (
        ''
      )}

      {isMobile ? (
        <Grid item container xs={12} direction="row" justify="space-between">
          <Grid item>
            <Typography className={classes.weight} variant="h5">
              {t('select the room')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {roomsCount} {t('room')}, {guests} {t('one travel')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ''
      )}

      <Grid item xs={6} sm>
        <Paper className={classes.checkInCheckout} elevation={0}>
          <Box m={1}>
            <Typography
              color="textSecondary"
              variant="caption"
              className={classes.checkInCheckout}
            >
              {t('hotel search check in')}
            </Typography>
            <Box className={classes.align} pt={0}>
              <ReusableDatpicker
                value={checkIn}
                onChange={setCheckIn}
                back
                disableLabel
                HOTEL
                minimized
                from={checkIn}
                to={checkOut}
              />
            </Box>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={6} sm>
        <Paper elevation={0} className={classes.checkInCheckout}>
          <Box m={1}>
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.checkInCheckout}
            >
              {t('hotel search check out')}
            </Typography>
            <Box pt={0} className={classes.align}>
              <ReusableDatpicker
                value={checkOut}
                onChange={setCheckOut}
                back
                HOTEL
                disableLabel
                minimized
                from={checkIn}
                to={checkOut}
              />
            </Box>
          </Box>
        </Paper>
      </Grid>

      {!findRatings || isMobile ? (
        ''
      ) : (
        <Grid item xs={12} sm>
          <Paper className={classes.ratingContainer} elevation={0}>
            <Box className={classes.align}>
              <UserRating rating={findRatings} padding long />
            </Box>
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}

export default memo(HotelDetails)
