import React, { memo, Fragment, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Details from './accommodationAccordium/details'
import Summary from './accommodationAccordium/summary'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import {useSelector} from "react-redux";

const Accordion = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    marginBottom: 0,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails)

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  semiBold: {
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '14px',
  },
  text: {
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 1.1,
  },
}))

const ChooseAccommodationItem = ({ options, train, index }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const seatsMap = useSelector((state) => state.train.seatsMap)
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (_event, newExpanded) => {
    return setExpanded(newExpanded ? panel : false)
  }

  const renderRows = train?.train?.segments.map((segment, i) => {
    const { railidentifier } = segment
    const option = options ? options[railidentifier] : ''
    const seatType = train?.pricing?.seatTypes?.[segment?.ID]
    const berthTypes = ['SlCouchette', 'SlShared', 'SlSingle']
    return (
      <Fragment key={i}>
        <Box mt={2} />
        <Divider />
        <Accordion
          elevation={0}
          square
          expanded={expanded === `panel${i}`}
          onChange={(option || !!seatsMap?.[railidentifier]) && !berthTypes.includes(seatType) ? handleChange(`panel${i}`) : () => { }}
        >
          <AccordionSummary
            aria-label="Expand"
            aria-controls="additional-actions1-content"
          >
            <Summary segment={segment} option={option} index={index} solution={train?.pricing}/>
          </AccordionSummary>
          <AccordionDetails>
            <Details isShown={expanded === `panel${i}`} trian={train} segment={segment} option={option} index={index}/>
          </AccordionDetails>
        </Accordion>
      </Fragment>
    )
  })

  return (
    <Box mt={isMobile ? 2 : 4}>
      <Grid container>
        {isMobile ? (
          <Grid item xs>
            <Divider />
            <Box pt={2}>
              <Typography className={classes.semiBold}>
                {t('leg of journey')}
              </Typography>{' '}
            </Box>
          </Grid>
        ) : (
            <>
              <Grid container item xs={7}>
                <Grid item xs>
                  <Typography variant="body2" className={classes.text}>
                    {t('seats')}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography variant="body2" className={classes.text}>
                    {t('leg of journey')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs>
                <Grid item xs>
                  <Typography variant="body2" className={classes.text}>
                    {t('travel time')}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" className={classes.text}>
                    {t('operator')}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" className={classes.text}>
                    {t('choose seat')}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
      </Grid>
      {renderRows}
    </Box>
  )
}

export default memo(ChooseAccommodationItem)
