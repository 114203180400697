import React, { useCallback } from 'react'
import { Box, Divider, Paper } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SelectTicketType from '../../../components/flights/details/selectTicketType'
import SelectBagage from '../../../components/flights/details/selectBagage'
import { makeStyles } from '@material-ui/core/styles'
import SeatReservation from './seatReservation'
import {
  setOutboundFare,
  setPassengerSeatings,
  setReturnFare,
  setUserBaggage,
} from '../../../store/flightDetails/flightDetailsActions'

const useStyles = makeStyles(() => ({
  borderedBlock: {
    borderRadius: '12px',
  },
}))

const SelectTicket = () => {
  const classes = useStyles()
  const { familyOutgoingPricing, familyReturningPricing } = useSelector(
    (state) => state.flightDetails
  )

  const { outboundCatalogue, returningCatalogue, currentFare, returnFare } =
    useSelector((state) => state.flightDetails)
  const dispatch = useDispatch()
  const { outgoing, returning, selectedMulti } = useSelector(
    (state) => state.flights
  )
  const { selectedBaggage } = useSelector((state) => state.flightDetails)

  const { t } = useTranslation()

  const outboundSelect = useCallback(
    (flight) => {
      dispatch(setOutboundFare(flight))
      dispatch(setPassengerSeatings([]))
    },
    [dispatch]
  )

  const returnSelect = useCallback(
    (flight) => {
      dispatch(setReturnFare(flight))
      dispatch(setPassengerSeatings([]))
    },
    [dispatch]
  )

  const onSelectOutboundBag = useCallback(
    (userId, bag, bagType = 'general') =>
      dispatch(setUserBaggage(userId, bag, 'outbound', bagType)),
    [dispatch]
  )

  const onSelectReturnBag = useCallback(
    (userId, bag, bagType = 'general') =>
      dispatch(setUserBaggage(userId, bag, 'return', bagType)),
    [dispatch]
  )

  const buildTicketBlocks = () => {
    let ticketBlocks = []
    const isMultiTicket = returning?.original?.isMultiTicket
    const isMultiStop = Object.values(selectedMulti).length > 0

    if (isMultiStop) {
      Object.values(selectedMulti).forEach((item, index) => {
        ticketBlocks.push(
          <Box key={index}>
            <SelectTicketType
              titlePrefix={`${item.from.city} - ${item.to.city}`}
              pricing={familyOutgoingPricing}
              onSelected={outboundSelect}
              outgoing={item}
            />
            <Divider />
            <SelectBagage
              selected={selectedBaggage.outbound}
              outgoing={item}
              onSelect={onSelectOutboundBag}
              fareFamily={currentFare}
              catalogue={outboundCatalogue}
            />
          </Box>
        )
      })
    }

    if (outgoing) {
      ticketBlocks.push(
        <Box key={ticketBlocks.length}>
          <SelectTicketType
            titlePrefix={isMultiTicket ? t('outbound trip') : null}
            pricing={familyOutgoingPricing}
            onSelected={outboundSelect}
            returning={isMultiTicket ? null : returning}
            outgoing={outgoing}
          />
          <Divider />
          <SelectBagage
            selected={selectedBaggage.outbound}
            returning={isMultiTicket ? null : returning}
            outgoing={outgoing}
            onSelect={onSelectOutboundBag}
            fareFamily={currentFare}
            catalogue={outboundCatalogue}
          />
        </Box>
      )
    }

    if (isMultiTicket) {
      ticketBlocks.push(
        <Box key={ticketBlocks.length} mt={2}>
          <SelectTicketType
            titlePrefix={t('detail return')}
            pricing={familyReturningPricing}
            onSelected={returnSelect}
            returning={null}
            outgoing={returning}
            isMTKInbound={isMultiTicket}
          />
          <Divider />
          <SelectBagage
            selected={selectedBaggage.return}
            onSelect={onSelectReturnBag}
            fareFamily={returnFare}
            catalogue={returningCatalogue}
            returning={null}
            outgoing={returning}
            isMultiTicket={isMultiTicket}
          />
        </Box>
      )
    }

    ticketBlocks.push(
      <SeatReservation
        key={ticketBlocks.length}
        isMultiTicket={isMultiTicket}
      />
    )

    // wrap blocks
    if (isMultiTicket) {
      return ticketBlocks.map((ticketBox, key) => (
        <Paper key={key} elevation={0} className={classes.borderedBlock}>
          {ticketBox}
        </Paper>
      ))
    }

    return (
      <Paper elevation={0} className={classes.borderedBlock}>
        {ticketBlocks}
      </Paper>
    )
  }

  return <Box>{buildTicketBlocks()}</Box>
}

export default SelectTicket
