import React, {useCallback} from 'react'
import ModalHook from './reusable/modalHook'
import { setAuthType } from '../../store/auth/authAction'
import {
  useTheme,
  Box,
  Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%'
  },
  header: {
    fontSize: '32px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '37px',
    color: theme.palette.common.black,
    textAlign: 'center',
  },
  textLine: {
    fontSize: '16px',
    color: theme.palette.common.black,
    textAlign: 'center',
    maxWidth: '275px',

    '& .bold': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  btnBlock: {},
  btnBlockInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),

    '& .MuiButton-root': {
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '1.1px',
      minWidth: '200px',
      boxShadow: 'none',
      paddingTop: '9px',
      minHeight: '45px',
      paddingBottom: '9px',
      borderRadius: '20px',
    },
    '&:last-child': {
      marginBottom: '0'
    }
  }
}))

const SelectAuthTypeModal = ({ modalBoolean }) => {
  const classes = useStyles()
  const { user } = useSelector((state) => state.auth)
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tmpAuthTypes = user?.authTypes?.length > 0 ? user.authTypes : []
  const btnVariants = {
    'default': 'outlined',
    'agent': 'contained',
  }

  const changeAuthType = useCallback((type) => {
    dispatch(setAuthType(type))
  }, [dispatch])

  let agentType = ""
  if (tmpAuthTypes.includes('agent') && !!user.agentType) {
    agentType = t(`auth type ${user.agentType}`)
  }

  return (
    <ModalHook height={570} modalBoolean={modalBoolean} width={500} noBackdrop>
      <Box className={classes.root}>
        <Box className={classes.header}>{t('log-in mode')}</Box>
        <Box mb={3} className={classes.textLine}>
          {t('login mode description', {type: agentType})}
        </Box>
        <Box mb={3} className={classes.textLine}>
          <span className={'bold'}>{t('select login mode question')}</span>
        </Box>
        <Box className={classes.btnBlock}>
          {tmpAuthTypes.map(authType => {
            let btnType = authType
            if (btnType === 'agent' && !!user?.agentType) {
              btnType = user.agentType
            }
            const tmpVariant = btnVariants?.[authType] || 'outlined'
            return (
              <Box key={`auth-btn-${authType}-outer`} className={classes.btnBlockInner}>
                <Button key={`auth-btn-${authType}`} onClick={() => changeAuthType(authType)} color="primary" variant={tmpVariant}>
                  {t(`auth type ${btnType}`)}
                </Button>
              </Box>
            )
          })}
        </Box>
      </Box>
    </ModalHook>
  )
}

export default SelectAuthTypeModal
