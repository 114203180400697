import React, { memo, useCallback, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  makeStyles,
  useMediaQuery,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import MultiSearch from '../../containers/search/multiSearch'
import { useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
  sectionContainer: {},
  section: {
    padding: '4px',
    width: '100%',
  },
}))

// components mapping for cms page content
const validComponents = {
  container: Container,
  accordion: Accordion,
  accordionsummary: AccordionSummary,
  accordiondetails: AccordionDetails,
  expandmoreicon: ExpandMoreIcon,
}

// attributes mapping for cms page content
const attrMapping = {
  'ac-expand-icon': 'expandIcon',
}

const CustomPage = ({ pageId }) => {
  const { pages } = useSelector((state) => state.customization)

  let page = {}

  if (pages?.length) {
    const searchRes = pages.find((p) => p?.['_id'] === pageId)
    page = searchRes || {}
  }

  const { i18n } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const getNodes = useCallback((html) => {
    if (!html || typeof html !== 'string' || html.trim() === '') return null
    const nodes = new DOMParser().parseFromString(html, 'text/html')?.body
      ?.childNodes
    if (!nodes) return null
    return nodes
  }, [])

  // convert DOMParser nodes to jsx
  const createJSX = useCallback(
    (nodeArray) => {
      return nodeArray.map((node) => {
        let attributeObj = {}
        const { attributes, localName, childNodes, nodeValue = '' } = node || {}
        let TagName = localName

        //check (by tag name) if component exists in mapping array
        if (!!TagName && !!validComponents?.[TagName])
          TagName = validComponents[TagName]

        if (attributes) {
          Array.from(attributes).forEach((attribute) => {
            if (attribute.name === 'style') {
              let styleAttributes = attribute.nodeValue.split(';')
              let styleObj = {}
              styleAttributes.forEach((attribute) => {
                let [key, value] = attribute.split(':')
                if (!!key) key = key.trim()
                styleObj[key] = value
              })
              attributeObj[attribute.name] = styleObj
            } else {
              const attrNode = getNodes(attribute.nodeValue)
              const attrNodeName = attrNode?.[0]?.localName || ''
              let attrVal = attribute.nodeValue
              let attrName = attribute.name
              if (!!attrMapping?.[attrName]) attrName = attrMapping[attrName]
              if (!!validComponents?.[attrNodeName]) {
                attrVal = createJSX([attrNode[0]])
              }
              attributeObj[attrName] = attrVal
            }
          })
        }
        const isChildNodes =
          !!childNodes &&
          Array.isArray(Array.from(childNodes)) &&
          childNodes.length > 0

        if (!TagName) return !!(nodeValue || '').trim() ? nodeValue : null
        return !!isChildNodes ? (
          <TagName {...attributeObj}>
            {createJSX(Array.from(childNodes))}
          </TagName>
        ) : (
          <TagName {...attributeObj} />
        )
      })
    },
    [getNodes]
  )

  // convert html string to DOMParser nodes
  const StringToJSX = useCallback(
    (html) => {
      return createJSX(Array.from(getNodes(html)))
    },
    [createJSX, getNodes]
  )

  const content = useMemo(() => {
    const language = i18n.language || 'sv'
    const availableLanguages = ['sv', 'en']
    let result = null
    if (!!page?.content) {
      for (let lanKey in page?.content) {
        let isEmpty = true
        if (
          !availableLanguages.includes(lanKey) ||
          !page?.content?.[lanKey]?.sections?.length
        )
          continue
        page.content[lanKey].sections.forEach((s) => {
          const tmpHtml = (s?.html || '').replace(/\s/gi, '')
          if (isEmpty && !!tmpHtml) isEmpty = false
        })
        if (isEmpty) continue

        if (language === lanKey || !result) {
          result = page.content[lanKey]
          if (language === lanKey) break
        }
      }
    }

    return result
  }, [page, i18n])

  const { isStartPage } = page

  const renderSections = useMemo(
    () =>
      content?.sections?.map((section, sectionIndex) => {
        const backgroundImage =
          section.background === 'image' ? section.backgroundImage : null
        const backgroundColor =
          section.background === 'color' ? section.backgroundColor : null

        const height = section.height ? section.height + 'px' : null

        const backgroundStyle = backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              height,
            }
          : backgroundColor
          ? { backgroundColor, height }
          : null

        return (
          <Container
            key={sectionIndex}
            spacing={4}
            className={classes.sectionContainer}
            maxWidth={false}
            style={backgroundStyle}
          >
            <Container
              style={!!height ? { height: '100%' } : {}}
              maxWidth={section.width === 'full' ? false : 'lg'}
            >
              {!!section.css ? (
                <style dangerouslySetInnerHTML={{ __html: section.css }} />
              ) : (
                ''
              )}
              <Grid
                className={classes.section}
                style={!!height ? { height: '100%' } : {}}
              >
                {StringToJSX(section.html)}
              </Grid>
            </Container>
          </Container>
        )
      }),
    [StringToJSX, content, classes]
  )

  return renderSections
}

export default memo(CustomPage)
