export const formatByRowPricing = (byRows, cols, notExistsSeats) => {
  let prevPricing = { min: -1, max: -1 }
  const rowPricings = {}

  for (const priceRowInd in byRows) {
    const rowPrices = byRows[priceRowInd]
    const rowPricing = {
      min: false,
      max: 0,
    }
    cols.forEach((col) => {
      if (!col || col === '') {
        return false
      }

      if (typeof rowPrices[col] === 'undefined') {
        if (notExistsSeats.indexOf(priceRowInd.toString() + col) < 0)
          rowPricing.min = 0
        return false
      }

      if (rowPricing.min === false || rowPricing.min > rowPrices[col]) {
        rowPricing.min = rowPrices[col]
      }

      if (rowPricing.max < rowPrices[col]) {
        rowPricing.max = rowPrices[col]
      }
    })

    rowPricing.min = rowPricing.min === false ? 0 : rowPricing.min

    if (
      prevPricing.min !== rowPricing.min ||
      prevPricing.max !== rowPricing.max
    ) {
      rowPricings[priceRowInd] = rowPricing
    }

    prevPricing = rowPricing
  }

  return rowPricings
}
