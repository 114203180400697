import React, { memo } from 'react'
import ModalHook from './reusable/modalHook'
import { makeStyles } from '@material-ui/core/styles'
import ModalHeader from './reusable/modalHeader'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { closeAllModals } from '../../store/modals/modalAction'
import { logOutUser } from '../../store/auth/authAction'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import storage from 'local-storage-fallback'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginTop: '220px',
    },
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  confirmText: {
    maxWidth: '380px',
  },
  btns: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 3),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
    whiteSpace: 'nowrap',
    fontSize: theme.spacing(2),
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  infoText: {
    whiteSpace: 'nowrap',
  },
}))

const LogOutModal = ({ modalBoolean }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const onCloseModal = () => dispatch(closeAllModals())
  const history = useHistory()
  const { user, isSSOEnabled } = useSelector((state) => state.auth)

  const onLogout = () => {
    if (isSSOEnabled) {
      dispatch(closeAllModals())
      history.push(`/oidc/${user.companyId}/logout`)
    } else {
      dispatch(logOutUser(true))
      dispatch(closeAllModals())
      storage.removeItem('state')

      history.push('/')
    }
  }

  return (
    <ModalHook
      height={300}
      minHeight={300}
      width={550}
      modalBoolean={modalBoolean}
      marginTop={250}
    >
      <Box p={2} height="100%">
        <ModalHeader />
        <Box
          className={classes.root}
          pl={isDesktop ? 1 : 0}
          pr={isDesktop ? 1 : 0}
        >
          <Box>
            <Typography align="center" className={classes.bold} variant="h4">
              {t('nav logout')}?
            </Typography>
          </Box>
          <Box mt={4} height="100%">
            <Typography className={classes.infoText} align="center">
              {t('log out text')}
            </Typography>
          </Box>
          <Box mt={4}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  onClick={onCloseModal}
                  disableElevation
                  fullWidth
                  className={classes.btns}
                  color="primary"
                  variant="outlined"
                >
                  {t('cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={onLogout}
                  disableElevation
                  fullWidth
                  className={classes.btns}
                  color="primary"
                  variant="contained"
                >
                  {t('nav logout')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ModalHook>
  )
}

export default memo(LogOutModal)
