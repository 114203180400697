import CONSTANTS from './constants'

export const initialFilters = {
  locationKey: false,
  maxPrice: null,
  minPrice: null,
  companies: null,
  mileages: null,
  transmissions: null,
  fuel: null,
}

const initialState = {
  isLoading: false,
  cars: {},
  selectedCar: null,
  selectedExtras: [],
  filters: {...initialFilters},
  addedAddOns: []
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CONSTANTS.FETCH_RENTAL_CARS:
      return {
        ...state,
        cars: payload?.rates?.length > 0 ? payload : {}
      }
    case CONSTANTS.SET_SELECTED_CAR:
      return {
        ...state,
        selectedCar: payload || {}
      }
    case CONSTANTS.IS_CARS_SEARCH_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case CONSTANTS.CLEAR_CARS_SEARCH:
      return {
        ...state,
        cars: {},
      }
    case CONSTANTS.SET_CAR_EXTRAS: {
      if (typeof(payload?.length) === 'undefined') return {...state}
      const ids = [];

      return {
        ...state,
        selectedExtras: payload.filter(e => {
          const { uniqueId } = e
          if (!uniqueId || ids.includes(uniqueId)) return false
          ids.push(uniqueId)
          return true
        }),
      }
    }
    case CONSTANTS.SET_CAR_FILTERS: {
      const tmpFilters = !state.filters || typeof(state.filters) !== 'object' ? {...initialFilters} : {...state.filters}
      if (!!payload && typeof(payload) === 'object') {
        for (let fkey in payload) {
          if (typeof (initialFilters[fkey]) === 'undefined') continue;
          const fval = !!payload[fkey] ? payload[fkey] : null
          tmpFilters[fkey] = fval
        }
      }
      return {
        ...state,
        filters: tmpFilters
      }
    }
    case CONSTANTS.RESET_CAR_FILTERS: {
      return {
        ...state,
        filters: {...initialFilters}
      }
    }
    case CONSTANTS.REMOVE_CAR_EXTRA: {
      return {
        ...state,
        selectedExtras: ([...(state?.selectedExtras || [])]).filter(e => e.uniqueId !== payload)
      }
    }
    case CONSTANTS.SET_ADD_ONS: {
      const addOns = [...state.addedAddOns]
      addOns.push(payload)

      return {
        ...state,
        addedAddOns: addOns
      }
    }
    case CONSTANTS.REMOVE_ADD_ONS: {
      const filteredAddons = state.addedAddOns.filter(addOn => addOn.uniqueId !== payload)

      return {
        ...state,
        addedAddOns: filteredAddons
      }
    }
    case CONSTANTS.RESET_CARS_STATE:
      return initialState
    default:
      return state
  }
}
