import React, { memo, useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import Moment from 'react-moment'
import { fetchTrainCityByCode } from '../../../../../repositories/trains'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cityText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dateText: {
    color: theme.palette.dimGray,
  },
}))

const TrainType = () => {
  const { pathname } = useLocation()
  const params = useParams()
  const classes = useStyles()

  const [start, setStart] = useState('')
  const [destination, setDestination] = useState('')

  const fethCityByCode = async (code, cb) => {
    const result = await fetchTrainCityByCode(code)
    cb(result)
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      fethCityByCode(params.from, setStart)
      fethCityByCode(params.to, setDestination)
    }

    return () => {
      mounted = false
    }
  }, [params.from, params.to])

  return (
    <Box className={classes.root}>
      <Typography className={classes.cityText}>
        <>
          {pathname.includes('returning')
            ? `${destination ? destination['Station Name'] : ''} - ${
                start ? start['Station Name'] : ''
              }`
            : `${start ? start['Station Name'] : ''} - ${
                destination ? destination['Station Name'] : ''
              }`}
        </>
      </Typography>
      <Typography variant="caption" align="center" className={classes.dateText}>
        <Moment format="ddd D MMM">{params.dep}</Moment>
        {params.arr === '0' ? null : (
          <>
            {' - '}
            <Moment format="ddd D MMM ">{params.arr}</Moment>
          </>
        )}
      </Typography>
    </Box>
  )
}

export default memo(TrainType)
