import { Button, Grid, Box } from '@material-ui/core'
import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import FlightIcon from '@material-ui/icons/Flight'
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway'
import HotelIcon from '@material-ui/icons/Hotel'
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'
import useHandleClickOnDisabledLink from '../../hooks/useHandleClickOnDisabledLink'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    width: '100%',
    position: 'relative',
    borderTop: '5px solid transparent',
    '&:before': {
      content: '',
      height: '10px',
      background: 'red',
      width: '500%',
      top: '-10px',
      left: '-50%',
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      textTransform: 'capitalize',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  active: {
    color: theme.palette.primary.main,
    borderTop: `5px solid ${theme.palette.primary.main}`,
  },
  activeIframe: {
    color: theme.palette.iframeSecondary.main,
    borderTop: `5px solid ${theme.palette.iframeSecondary.main}`,
  },
  btnContainer: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(0.5),
  },
  eventIcon: {
    color: theme.palette.iframeSecondary.main,
  },
  navItem: {
    '&:active': {
      color: theme.palette.iframeSecondary.main,
    },
  },
  disabledLink: {
    opacity: '0.5',
  },
}))

const MobileSearchNav = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { isIframe } = useSelector((state) => state.customization)
  const { showDisabledLinks } = useSelector((state) => state.auth)
  const { isFlightsEnabled, isCarsEnabled, isHotelsEnabled, isTrainsEnabled } =
    useEnabledBookingTypesHook()
  const handleClickOnDisabledLink = useHandleClickOnDisabledLink()

  let links = []

  if (isTrainsEnabled) {
    links.push({
      to: '/trains',
      label: `${t('nav train')}`,
      icon: DirectionsRailwayIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      to: '/trains',
      label: `${t('nav train')}`,
      disabled: true,
      icon: DirectionsRailwayIcon,
    })
  }

  if (isFlightsEnabled) {
    links.push({
      to: '/flights',
      label: `${t('nav flight')}`,
      icon: FlightIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      to: '/flights',
      disabled: true,
      label: `${t('nav flight')}`,
      icon: FlightIcon,
    })
  }

  if (isHotelsEnabled) {
    links.push({
      to: '/hotels',
      label: `${t('nav hotel')}`,
      icon: HotelIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      to: '/hotels',
      disabled: true,
      label: `${t('nav hotel')}`,
      icon: HotelIcon,
    })
  }

  if (isCarsEnabled) {
    links.push({
      to: '/cars',
      label: `${t('cars')}`,
      icon: DriveEtaIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      to: '/cars',
      disabled: true,
      label: `${t('cars')}`,
      icon: DriveEtaIcon,
    })
  }

  const renderNavs = links.map((nav, i) => {
    const type = nav.to.replace('/', '')

    if (nav.disabled) {
      return (
        <Grid key={i} item xs>
          <Box className={`${classes.btnContainer} ${classes.disabledLink}`}>
            <Button
              className={classes.root}
              startIcon={
                <nav.icon
                  color="primary"
                  fontSize="large"
                  className={isIframe && classes.eventIcon}
                />
              }
              onClick={() => handleClickOnDisabledLink(type)}
            >
              {nav.label}
            </Button>
          </Box>
        </Grid>
      )
    } else {
      return (
        <Grid key={i} item xs>
          <Box className={classes.btnContainer}>
            <Button
              activeClassName={isIframe ? classes.activeIframe : classes.active}
              className={classes.root}
              startIcon={
                <nav.icon
                  color="primary"
                  fontSize="large"
                  className={isIframe && classes.eventIcon}
                />
              }
              to={isIframe ? `/iframe/${type}` : nav.to}
              component={NavLink}
            >
              {nav.label}
            </Button>
          </Box>
        </Grid>
      )
    }
  })

  return (
    <Grid container spacing={1}>
      {renderNavs}
    </Grid>
  )
}

export default memo(MobileSearchNav)
