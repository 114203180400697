import { createTheme } from '@material-ui/core/styles'
import isWidget from '../utils/isWidget'

import {
  metropolisRegular,
  metroPolisRegularItalic,
  metroPolisBold,
  metroPolisSemiBold,
  metroPolisMedium,
} from './typography/typography'

export default createTheme({
  direction: document.dir,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      slg: 1082,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      black: '#333333',
      white: '#ffffff',
      gray: '#fafafa',
    },
    primary: {
      main: '#08c5b9',
      light: '#eef8f8',
      medium: '#cdebeb',
      dark: '#007e7e',
      contrastText: '#ffffff',
    },
    secondary: { main: '#ff4700', light: '#ffdacc', warning: '#fff4e5' },
    dimGray: '#666666',
    dimWhite: '#f7f7f7',
    border: '#e6e6e6',
    lightGreen: '#DCF7D9',
    success: {
      main: '#30cb1d',
    },
    blueLink: '#257df2',
    divider: '#e6e6e6',
    policyGreen: '#dcf7d9',
    policyTextGreen: '#15c500',
    policyBlue: '#257df2',
    policyTextBlue: '#ffffff',
    indicatorGrey: '#979797',
    yellow: '#ffefd6',
    orange: '#ff9900',
    yellowOrange: '#f5a623',
    dateRange: '#E6F9F7',
    backgroundColor: '#C6DEE6',
    iframePrimary: {
      main: '#EE3725',
    },
    iframeSecondary: {
      main: '#78BDC4',
    },
    iframeBackground: {
      main: '#F8F8F8',
    },
  },
  typography: {
    // Added custom
    fontWeightSemiBold: 600,
    fontFamily: [
      'Metropolis',
      'MetropolisBlack',
      'MetropolisBlackItalic',
      'MetropolisBold',
      'MetropolisBoldItalic',
      'MetropolisExtraBold',
      'MetropolisExtraBoldItalic',
      'MetropolisExtraLight',
      'MetropolisExtraLightItalic',
      'MetropolisLight',
      'MetropolisLightItalic',
      'MetropolisMedium',
      'MetropolisMediumItalic',
      'MetropolisSemiBold',
      'MetropolisSemiBoldItalic',
      'Arial',
    ],
    gray: '#333333',
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          background: 'transparent',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {},
        body: {},
        a: {
          color: '#333333',
        },
        strong: {
          fontWeight: '600',
        },
        '@font-face': [
          metropolisRegular,
          metroPolisRegularItalic,
          metroPolisMedium,
          metroPolisBold,
          metroPolisSemiBold,
        ],
      },
    },
    MuiButton: {
      root: {
        borderRadius: '20px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '12px',
        '& $notchedOutline': {
          borderColor: '#e6e6e6',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#e6e6e6',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: '#e6e6e6',
          },
        },
        '&$focused $notchedOutline': {
          // borderColor: '#e6e6e6',
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#999999',
        fontWeight: 500,
        '&$focused': {
          color: '#333333',
        },
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          display: 'none',
        },
      },
    },

    MuiContainer: isWidget
      ? {
          maxWidthLg: {
            maxWidth: '100% !important',
          },
        }
      : null,
  },
})
