import { makeStyles } from '@material-ui/core/styles'

export const useSliderStyles = makeStyles((theme) => ({
  root: {
    '&.medium-label .MuiSlider-thumb .MuiSlider-valueLabel': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& .MuiSlider-rail': {
      height: theme.spacing(0.5),
      color: theme.palette.divider,
    },
    '& .MuiSlider-track': {
      height: theme.spacing(0.5),
    },
    '& .MuiSlider-thumb': {
      height: theme.spacing(2),
      width: theme.spacing(2),
      marginTop: -theme.spacing(0.75),
      marginLeft: -theme.spacing(1),
    },
    '&.Mui-disabled': {
      color: theme.palette.divider,
    },
    '& .MuiSlider-valueLabel': {
      color: 'transparent',
      bottom: 0,
      left: 'calc(-50% - 0px)',
      top: 'auto',
      '& span span': {
        color: theme.palette.common.black,
        fontSize: theme.spacing(1.75),
      },
      textWrap: 'nowrap',
    },
  },
  text: {
    fontSize: theme.spacing(1.75),
    marginBottom: theme.spacing(3),
  },
}))
