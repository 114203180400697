import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Link } from 'react-router-dom'
import { openEditInfoModal } from "../../../store/modals/modalAction";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
  nested: {
    paddingLeft: theme.spacing(4),
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: 'none',
    },
  },
}))

const MobileListItem = ({ nav }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  const openEditModal = (name, rows, section) => {
    dispatch(openEditInfoModal({ name, rows, section }))
  }

  const renderNestedLinks = nav.links
    ? nav.links.map((link, key) => {
      return (
        <Box key={key} mt={0.5}>
          <ListItem
            onClick={link.rows ? () => openEditModal(link.name, link.rows, link.section) : null}
            to={link.rows ? undefined : link.slug}
            component={link.rows ? 'button' : Link}
            className={classes.nested}
          >
            <ListItemIcon style={{ minWidth: '40px' }}>
              {<link.icon color="primary" />}
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={link.name}
            />
          </ListItem>
        </Box>
      )
    })
    : ''

  return (
    <Box mt={0.5}>
      <ListItem
        to={nav.to}
        component={!nav.links || nav.logout ? Link : Button}
        onClick={handleClick}
      >
        <ListItemIcon style={{ minWidth: '40px' }}>
          {<nav.icon color="primary" />}
        </ListItemIcon>
        <ListItemText className={classes.listItemText} primary={nav.title} />
        {nav.links ? (
          <>
            {open ? (
              <ExpandLess color="primary" />
            ) : (
                <ExpandMore color="primary" />
              )}
          </>
        ) : (
            ''
          )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderNestedLinks}
        </List>
      </Collapse>
    </Box>
  )
}

export default MobileListItem
