import React, { memo, useEffect, useRef, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import HotelIcon from '@material-ui/icons/Hotel'
import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'
import MobileCountsDrawer from './parts/mobileCountsDrawer'
import DesktopCountsMenu from './parts/desktopCountsMenu'
import { useTranslation } from 'react-i18next'
import useTheme from '@material-ui/core/styles/useTheme'
import { Typography, useMediaQuery } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: '600',
    letterSpacing: '0.7px',
    textTransform: 'uppercase',
    width: '100%',
    fontSize: '12px',
    lineHeight: '20px',
  },
  eventIcon: {
    color: theme.palette.iframeSecondary.main,
  },
}))

const Button = withStyles((theme) => ({
  root: {
    fontSize: theme.spacing(2),
    fontWeight: 600,
    textTransform: 'none',
    // paddingTop: theme.spacing(1.5),
    // paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    '& .MuiButton-label': {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },

  text: {
    padding: '4px 8px 0px 8px',
  },
}))(MuiButton)

const SelectCounts = ({
  mobileSearch = false,
  isOpen,
  setIsOpen,
  modifyCounts,
  counts,
  isHotel,
  setTicketClass,
  ticketClass,
  ticketClasses,
  menuOffset,
  zIndex,
  hideHeader,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const buttonRef = useRef(null)
  const { isIframe } = useSelector((state) => state.customization)

  const getBedsText = () => {
    if (!counts.beds) return 'single'

    const { count, buttons } = counts.beds
    const res = buttons.find((b) => b.value === count)
    return !!res ? res.name : 'single'
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setOpen(open)
  }

  useEffect(() => {
    if (isOpen) {
      setAnchorEl(buttonRef.current)
      setIsOpen(false)
    }
  }, [isOpen, setIsOpen])

  const getText = () => {
    let texts = []
    let mixKey = !isHotel ? 'travelers' : 'guest'
    let travelKeys = ['adult', 'child']
    let firstKey = null
    let travelersKey = null
    let travelersCount = 0
    //travelers
    for (let key in counts) {
      const { count, title, displayType } = counts[key]
      if (displayType && displayType !== 'count') continue

      if (!firstKey) firstKey = key
      if (count <= 0) continue
      if (travelKeys.includes(key)) {
        travelersCount += count
        travelersKey = !travelersKey && !isHotel ? title : mixKey
      } else {
        texts.push(`${count} ${t(title, { count: count })}`)
      }
    }
    if (travelersKey)
      texts.unshift(
        `${travelersCount} ${t(travelersKey, { count: travelersCount })}`
      )
    if (ticketClasses?.[ticketClass])
      texts.push(t(ticketClasses?.[ticketClass] + ' small'))
    return texts.length > 0
      ? texts.join(', ')
      : `${counts[firstKey].count} ${t(counts[firstKey].title, {
          count: counts[firstKey].count,
        })}`
  }
  const handleClick = (event) =>
    setAnchorEl(!!anchorEl ? null : buttonRef.current)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <Box
        pl={1}
        display="flex"
        flexDirection={'column'}
        justifyContent="center"
        ref={buttonRef}
        onClick={mobileSearch ? toggleDrawer(true) : handleClick}
      >
        {isHotel && !isMobile && !hideHeader && (
          <Typography
            className={classes.header}
            variant="caption"
            color="textSecondary"
          >
            {t(getBedsText())}{' '}
            {t('counts room', { count: counts?.rooms?.count })}
          </Typography>
        )}

        <Button
          startIcon={
            isHotel ? (
              <HotelIcon
                color="primary"
                fontSize="small"
                className={isIframe ? classes.eventIcon : null}
              />
            ) : (
              <GroupIcon
                color="primary"
                fontSize="small"
                className={isIframe ? classes.eventIcon : null}
              />
            )
          }
          disableRipple
          fullWidth
          style={{
            backgroundColor: 'transparent',
            padding: isMobile && theme.spacing(1.75, 0),
          }}
        >
          {getText()}
        </Button>
      </Box>
      <MobileCountsDrawer
        isHotel={isHotel}
        open={open}
        counts={counts}
        toggleDrawer={toggleDrawer}
        modifyCounts={modifyCounts}
        setTicketClass={setTicketClass}
        ticketClass={ticketClass}
        ticketClasses={ticketClasses}
      />
      <DesktopCountsMenu
        counts={counts}
        modifyCounts={modifyCounts}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        setTicketClass={setTicketClass}
        ticketClass={ticketClass}
        ticketClasses={ticketClasses}
        menuOffset={menuOffset}
        zIndex={zIndex}
      />
    </>
  )
}

export default memo(SelectCounts)
