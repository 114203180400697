import { Box, Button, makeStyles } from '@material-ui/core'
import { memo } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    fontWeight: 500,
    color: 'black',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'unset',
    textTransform: 'none',
    textAlign: 'left',
    justifyContent: 'flex-start',
    display: 'inline-block',
    paddingRight: '0px',

    '& .MuiButton-label': {
      maxWidth: '230px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
    },

    '&:hover': {
      background: 'none',
    },
  },

  selectedButton: {
    color: theme.palette.primary.main,

    '&.MuiButton-text': {
      textDecoration: 'underline!important',
      textDecorationColor: '#5ac2b9!important',
      textUnderlineOffset: '4px',
      textDecorationThickness: '2px!important',
    },
  },

  buttonContainer: {
    display: 'inline-block',
    paddingTop: '6px',
    cursor: 'pointer',
  },

  buttonEndIcon: {
    fontSize: '24px',
  },
}))

const MenuButton = ({ label, onClick, isOpen }) => {
  const classes = useStyles()

  return (
    <>
      <Button
        disableRipple
        onClick={onClick}
        className={`${classes.iconBtn} ${
          isOpen ? classes.selectedButton : null
        }`}
      >
        {label}
      </Button>

      <Box className={classes.buttonContainer} onClick={onClick}>
        {isOpen ? (
          <KeyboardArrowUpIcon
            className={classes.buttonEndIcon}
            color="primary"
          />
        ) : (
          <KeyboardArrowDownIcon
            className={classes.buttonEndIcon}
            color="primary"
          />
        )}
      </Box>
    </>
  )
}

export default memo(MenuButton)
