import React, {useCallback} from 'react'
import FlightSearchPageForm from '../../../components/flights/flightSearchPageForm'
import {toggleCheckoutItemsDrawer} from "../../../store/modals/modalAction";
import {useDispatch} from "react-redux";

function AddFlight() {
  const searchParams = {}
  const dispatch = useDispatch()
  const openCheckoutDrawer = useCallback(() => {
    const width = window.innerWidth
    if (width >= 1920) {
      dispatch(toggleCheckoutItemsDrawer())
    }
  }, [dispatch])

  return (
    <FlightSearchPageForm
      searchParams={searchParams}
      searchType={'outbound'}
      beforeSearch={openCheckoutDrawer}
      isExtendingFromCheckout={true}
    />
  )
}

export default AddFlight
