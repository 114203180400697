import { getPnrProductTypes, getPnrCarData } from '../../../../pages/my-trips/my-trips'
import { TableCell, TableRow, useMediaQuery, useTheme } from '@material-ui/core'
import capFirstLetter from '../../../../utils/capitalizeFirstLetter'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../../viewHandler'
import React, { memo, Fragment } from 'react'
import Box from '@material-ui/core/Box'
import Moment from 'react-moment'
import _ from 'lodash'

const CarSummary = ({ model, handleChange }) => {
  const car = getPnrCarData(model)
  const classes = useStyles()
  let itmKey = 1
  const { t } = useTranslation()
  const { passengers } = model
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const startTime = car.startDate
  const destination = car?.dropLocation
  const products = getPnrProductTypes(model)

  const renderPassengers = passengers
    ?.map(({ firstName, lastName }, key, array) => {
      if (key >= 2 && key === array.length - 1 && !isMobile)
        return (
          <span
            className={classes.weight}
            style={{ color: theme.palette.primary.main }}
            key={key}
          >
            +{array.length - 2}
          </span>
        )
      if (key > 0 && key === array.length - 1 && isMobile)
        return (
          <span
            className={classes.weight}
            style={{ color: theme.palette.primary.main }}
            key={key}
          >
            +{array.length - 1}
          </span>
        )

      if (key >= 2 || (key > 0 && isMobile)) return null
      return (
        <span key={`itm-${itmKey++}`} className={classes.traveler}>
          {capFirstLetter(`${firstName} ${lastName}`)}
          {key < 2 && key < array.length - 1 && !isMobile ? ', ' : ' '}
        </span>
      )
    })
    .filter((x) => x)

  return (
    <Fragment>
      <TableRow
        onClick={() => handleChange()}
        className={`${classes.root} ${classes.tripRow}`}
      >
        {!isMobile ? (
          <Fragment>
            <TableCell key={`itm-${itmKey++}`} align="left">
              <Moment format={'MMM D, YYYY'}>{startTime}</Moment>
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {model.pnr}
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {t(model.parsed ? 'offline' : 'online')}
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {products.map(p => t(p)).join(', ')}
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {_.startCase(
                _.toLower(destination?.city || destination?.addressLine)
              )}
            </TableCell>
            <TableCell key={`itm-${itmKey++}`} align="left">
              {renderPassengers}
            </TableCell>
          </Fragment>
        ) : (
          <TableCell key={`itm-${itmKey++}`} align="left">
            {_.startCase(
              _.toLower(destination?.city || destination?.addressLine)
            )}
          </TableCell>
        )}
        <TableCell key={`itm-${itmKey++}`} align="left">
          <Box className={classes.statusOuter}>
            <Box className={classes.statusInner}>
              <span className={classes.aboveStatusRow}>
                <Moment format={'MMM D, YYYY'}>{startTime}</Moment>
              </span>
              {(!!model.cancelled || !isMobile) && (
                <span
                  style={{ flex: 1 }}
                  className={
                    model.cancelled
                      ? classes.statusTextCancel
                      : model.isUpcoming
                      ? classes.statusText
                      : classes.statusTextPast
                  }
                >
                  {model.cancelled
                    ? t('cancelled')
                    : t(model.isUpcoming ? 'upcoming' : 'previous')}
                </span>
              )}
            </Box>
            <ChevronRightIcon
              className={classes.arrowIcon}
              viewBox="8 6 8 12"
            />
          </Box>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default memo(CarSummary)
