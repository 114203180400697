import React, { memo } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const ColoredTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
  },
  arrow: { color: theme.palette.primary.main },
}))(Tooltip)

const CustomTooltop = ({ children, title, placement }) => {
  return (
    <ColoredTooltip arrow title={title} placement={placement}>
      {children}
    </ColoredTooltip>
  )
}

export default memo(CustomTooltop)
