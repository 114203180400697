import cityCityException from '../../cityCityException.Class'
import { useMemo } from 'react'

export const getAvailableFop = (checkout) => {
  const { costfields = {}, fop } = checkout || {}
  const primaryPayment =
    costfields?.paymentMethods?.primaryPayment || 'profileCC'
  const rqFops = fop !== primaryPayment ? [primaryPayment] : []
  return { fops: [primaryPayment], rqFops }
}

export default (model, state, additionalData = {}) => {
  let results = []

  model.details.forEach((tr) => {
    if (!tr.transfer) return false
    results = [...results, ...prepareFligTaxi(tr, state, model, additionalData)]
  })

  return {
    totalPrice: model.totalPrice,
    transfers: results,
  }
}

const getUsersFromCheckoutItems = (state) => {
  const { items } = state.checkout
  const allUsers = []
  const userLocators = []
  items?.forEach((i) => {
    i?.passengers?.forEach((p) => {
      if (!p?.uniqueId || userLocators.includes(p.uniqueId)) return false
      allUsers.push({ ...p })
      userLocators.push(p.uniqueId)
    })
  })

  return allUsers
}

const prepareFligTaxi = (data, state, model, additionalData = {}) => {
  const { user: authUser, isAuthenticated } = state.auth
  const { fop, publicContacts } = state.checkout
  const { users: forceUsers, fop: forceFop } = additionalData

  const allUsers = forceUsers?.length
    ? forceUsers
    : getUsersFromCheckoutItems(state)
  let transferFop = null
  if (!!forceFop) {
    transferFop = forceFop
  } else {
    const availableFops = getAvailableFop(state.checkout)
    transferFop = !availableFops.fops.includes(fop)
      ? availableFops.fops[0]
      : fop
  }

  if (!authUser?.customerId && transferFop === 'invoice') {
    throw new cityCityException({ message: 'Invalid or empty customerId' })
  }

  const resultTrips = []
  data.transfer.forEach((t) => {
    const isTrain =
      t?.type === 'flightBus' ||
      (t?.type === 'tagTaxi' && t?.product?.code === 'RAILE')
    if (
      !t?.passenger ||
      (isTrain && (typeof t.passenger !== 'object' || !t?.passenger?.length))
    ) {
      throw new cityCityException({ message: 'Invalid transfer passengers' })
    }

    let uIdToCheck = []

    if (isTrain) {
      uIdToCheck = t.passenger
    } else {
      uIdToCheck.push(t.passenger)
    }

    const users = allUsers.filter(
      (u) => u?.uniqueId && uIdToCheck.includes(u.uniqueId)
    )

    if (users.length !== uIdToCheck.length) {
      throw new cityCityException({ message: 'Invalid transfer passengers' })
    }

    let authPassenger = users.find((p) => !p.isGuest)
    const user = authPassenger || users[0]
    const userPhone = isAuthenticated
      ? user.contacts?.phone || user.contacts?.mobile
      : publicContacts?.phone

    users.forEach((u) => {
      resultTrips.push({
        price: t.price.includingVat,
        startNode: t.startNode,
        endNode: t.endNode,
        product: t.product,
        connection: data.meta.connection,
        customerId: authUser?.customerId,
        fop: transferFop,
        passenger: {
          firstName: u.firstName,
          lastName: u.lastName,
          email: isAuthenticated ? u.email : publicContacts?.email,
          phone: model?.phone?.[u?.uniqueId] || userPhone,
          locator: u?.uniqueId,
          numberOfPassengers: 1,
          foid: t.foid,
        },
        type: 'taxi',
        taxiType: t.type,
        dirKey: t.dirKey,
        distance: t.distanceMeters,
        co2: t.co2,
      })
    })
  })

  return resultTrips
}
