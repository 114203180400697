import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import avropaZones from '../../constants/avropa_zones.json'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#e7f2ed',
    padding: '14px',
    height: '50px',
    color: 'black',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
  },

  icon: {
    marginRight: '4px',
  },

  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },

  zone: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  circle: {
    width: '10px',
    height: '10px',
    borderRadius: '12px',
    marginRight: '8px',
    position: 'relative',
    top: '-1px',
  },
  zoneMobile: {
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  category: {
    textAlign: 'center',
  },

  rank: {
    textAlign: 'right',
    paddingRight: '16px',
  },

  rankMobile: {
    textAlign: 'right',
    paddingRight: '8px',
  },
}))

function HotelZoneInfo({ data }) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const zoneData = avropaZones.find((zone) => zone.name == data['Ort/Zon'])

  if (isMobile) {
    return (
      <Grid container xs={12} className={classes.container}>
        <Grid item xs={6} className={classes.zoneMobile}>
          {data['AliasMobile']}
        </Grid>

        <Grid item xs={6} className={classes.rankMobile}>
          {t('category')}{' '}
          <span className={classes.bold}>{data['Kategori']}</span> &nbsp;
          {t('rank')} <span className={classes.bold}>{data['Rank']}</span>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container xs={12} className={classes.container}>
        <Grid item xs={8} className={classes.zone}>
          <div
            className={classes.circle}
            style={{ backgroundColor: zoneData?.color || 'lightblue' }}
          ></div>

          {data['AliasDesktop']}
        </Grid>
        <Grid item xs={4} className={classes.rank}>
          {t('category')}{' '}
          <span className={classes.bold}>{data['Kategori']}</span> &nbsp;
          {t('rank')} <span className={classes.bold}>{data['Rank']}</span>
        </Grid>
      </Grid>
    )
  }
}

export default HotelZoneInfo
