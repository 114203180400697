import React, { memo } from 'react'
import {
  openSimpleInfoModal,
  setBookingPartCancelModalData,
} from '../../store/modals/modalAction'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import defaultStyles from './defaultTimeline.styles'
import _ from 'lodash'
import {
  canCancelBooking,
  prepareCancellationProhibitedModal,
} from '../../utils/general'
import GetCompanyIcon from '../reusable/getCompanyIcon'
import CustomIconButton from '../reusable/customIconButton'
import { Clear } from '@material-ui/icons'
import moment from 'moment'
import {
  getFlightbusLogoFile,
  getTaxiCompanyFromProduct,
} from '../../utils/taxiHelpers'
import TripHeader from './tripHeader'
import { createPortal } from 'react-dom'

const TransferTimeline = ({
  transfer,
  model,
  enableCancel = true,
  onClose,
  mainRef,
}) => {
  const classes = defaultStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { user, currency } = useSelector((state) => state.auth)

  const onCancel = async () => {
    const { canCancel, reason } = canCancelBooking(user, model, ['transfer'])
    if (!canCancel) {
      const modalData = prepareCancellationProhibitedModal(reason, model, user)
      return dispatch(openSimpleInfoModal(modalData))
    }

    dispatch(
      setBookingPartCancelModalData({
        type: 'transfer',
        pnr: model?.pnr,
        item: { ...transfer },
      })
    )
  }

  const isSeparateCancelDisabled =
    model.netsPaymentId !== null || model.paiwiseCheckoutId

  const isCancelled = !!model && (transfer?.cancelled || model?.cancelled)
  const getCancelButton = () => {
    if (isSeparateCancelDisabled) return null
    if (!model?.pnr) return null

    if (isCancelled) {
      return <Box className={classes.cancelledText}>{t('cancelled')}</Box>
    }
    if (!model?.isUpcoming || !enableCancel) return null
    return (
      <CustomIconButton
        label={`${t('cancel booking')} ${t('transfer').toLowerCase()}`}
        icon={<Clear />}
        color="red"
        onClick={(e) => {
          e.stopPropagation()
          onCancel()
        }}
      />
    )
  }

  const companyName = getTaxiCompanyFromProduct(transfer.productCode)
  const titleText = `${transfer.from} ${t('to').toLowerCase()} ${transfer.to}`

  const driverText = `1 ${t('traveler')}`
  const productTypeText = t(`transfer product ${transfer.productCode}`)

  const parseDate = (str, locale) => {
    let d = moment(str, 'DD MMMM YYYY HH:mm', locale)
    if (d.isValid()) return d
  }

  const fromStr = `${transfer.fromDate} ${transfer.fromTime}`
  const fromDate = (
    parseDate(fromStr, 'sv') || parseDate(fromStr, 'en')
  ).toDate()

  const toStr = `${transfer.toDate} ${transfer.toTime}`
  const toDate = parseDate(toStr, 'sv') || parseDate(toStr, 'en').toDate()

  const dateText = (date) => _.capitalize(moment(date).format('ddd D MMM'))
  const timeText = (date) => moment(date).format('HH:mm')

  const durationText = moment(toDate).diff(moment(fromDate), 'minutes') + 'm'

  const showInfo = (description) => {
    let texts = description.split('\n')
    return dispatch(
      openSimpleInfoModal({
        text: ' ',
        infoTexts: texts,
        buttonText: t('ok'),
      })
    )
  }

  const renderLocation = (name, location, date) => {
    const descriptionIsLong = location?.description?.length > 100

    if (!location) return <Box>{name}</Box>

    const address = `${name.trim()}${
      location.type === 'AIRPORT' ? ' (' + location.code + ')' : ''
    }${location.community ? ', ' + location.community : ''}`

    return (
      <>
        {location.latitude ? (
          <Box>
            <a
              target="_new"
              href={`https://www.google.com/maps/place/${location.latitude},${location.longitude}`}
              style={{
                textDecoration: 'underline',
                color: '#000',
              }}
            >
              {address}
            </a>
          </Box>
        ) : (
          <Box>{address}</Box>
        )}

        <Box>
          {dateText(date)}
          <Box className={classes.dot} />
          {timeText(date)}
        </Box>

        {/* {location.description && !descriptionIsLong && (
          <Box>{location.description}</Box>
        )}
        {location.description && descriptionIsLong && (
          <Box
            onClick={() => showInfo(location.description)}
            style={{
              textDecoration: 'underline',
              color: '#000',
            }}
          >
            {t('show information')}
          </Box>
        )} */}
      </>
    )
  }

  return (
    <>
      <TripHeader type="transfer" onClose={onClose} />

      <Box className={classes.titleSection}>
        <Typography className={classes.title}>{titleText}</Typography>
        <Typography className={classes.subtitle}>{driverText}</Typography>
      </Box>

      <Box className={classes.card}>
        <Box mt={1} mb={1}>
          <Grid container spacing={2}>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              {transfer.productCode === 'FBUS' ? (
                <img
                  src={getFlightbusLogoFile(
                    transfer.productCode,
                    transfer.from,
                    transfer.to
                  )}
                  className={classes.icon}
                  alt={transfer.productCode}
                />
              ) : (
                <GetCompanyIcon
                  height={20}
                  width={20}
                  company={transfer.productCode}
                />
              )}
            </Grid>
            <Grid item xs={10}>
              <Box class={classes.subTitle}>{companyName}</Box>
              <Box>
                {productTypeText}
                <Box className={classes.dot} />
                {durationText}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={3} mb={3}>
          <Divider />
        </Box>

        <Box>
          <Typography className={classes.subTitle}>
            {t('pick-up search')}
          </Typography>
          {renderLocation(transfer.from, transfer.fromDetails, fromDate)}
        </Box>

        <Box mt={3} mb={2}>
          <Typography className={classes.subTitle}>
            {t('drop-off search')}
          </Typography>
          {renderLocation(transfer.to, transfer.toDetails, toDate)}
        </Box>
      </Box>

      <Box className={classes.card}>
        <Typography className={classes.subTitle}>
          {t('other details')}
        </Typography>

        <Box className={classes.details}>
          {transfer.bookingReference && (
            <Box>
              {t('book data transfer ref')} {transfer.bookingReference}
            </Box>
          )}
          {transfer.user && (
            <Box>
              {_.capitalize(t('traveler'))} {transfer.user.firstName}{' '}
              {transfer.user.lastName}
            </Box>
          )}
          {transfer.distance && (
            <Box>
              {t('distance')} {Math.round(transfer.distance / 1000)} km
            </Box>
          )}
          {transfer.co2 && (
            <Box>
              {t('emission')} {transfer.co2} kg
            </Box>
          )}
          <Box>
            {t('price')} {transfer.price} {currency}
          </Box>
        </Box>
      </Box>

      <Box style={{ marginBottom: '100px' }} />

      {createPortal(
        <Box className={classes.footer}>
          <Divider mb={2} />
          {getCancelButton()}
        </Box>,
        mainRef.current
      )}
    </>
  )
}

export default memo(TransferTimeline)
