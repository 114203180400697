import { Button } from '@material-ui/core'
import GoogleMap from '../../../components/hotels/hotelBackgroundMap'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import HotelSearchResults from './hotelSearchResults'
import { useDispatch, useSelector } from 'react-redux'
import HotelSearchItem from '../../../components/hotels/hotelSearchItem'
import SwipeableViews from 'react-swipeable-views'
import CloseIcon from '@material-ui/icons/Close'
import TuneRoundedIcon from '@material-ui/icons/TuneRounded'
import { setSelectedHotel } from '../../../store/hotels/hotelsAction'
import SmallSkeletons from './smallSkeletons'
import Typography from '@material-ui/core/Typography'
import { DrawerContext } from './hotelSearchContent'
import _ from 'lodash'
import useSort from '../../../hooks/useSortHook'
import { sortHotels } from '../../../hooks/useHotelSortHook'
import isIframed from '../../../utils/isIframed'
import { getVirtualCustomerConfig } from '../../../utils/virtualCustomerConfigs'

const MobileView = ({
  lat,
  lng,
  search,
  isMiddleStep,
  isMobile,
  toggleMap,
  showMap,
}) => {
  const { selectedHotel, isSearchLoading } = useSelector(
    (store) => store.hotels
  )
  const ratings = useSelector((state) => state.hotels.ratings)
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const { toggleDrawer, filteredSearch, activeFilters } =
    useContext(DrawerContext)

  const { priorityHotels } = getVirtualCustomerConfig()

  const localUseSort = useSort(
    null,
    search,
    null,
    'hotel',
    priorityHotels ? 'agency' : null
  )

  const results = useMemo(() => {
    const { sort, nameSearchStr } = localUseSort
    const res = nameSearchStr
      ? filteredSearch.filter((h) =>
          h.title.match(new RegExp(nameSearchStr, 'i'))
        )
      : filteredSearch
    return sortHotels(res, sort)
  }, [localUseSort, filteredSearch])

  const handleHotelChange = _.debounce(() => {
    if (results[activeStep]) {
      dispatch(setSelectedHotel(results[activeStep].id))
    }
  }, 200)

  // User clicked hotel price on map
  useEffect(() => {
    if (results?.[activeStep]?.id !== selectedHotel) {
      handleHotelChange()
    }
    // eslint-disable-next-line
  }, [activeStep, results])

  // User swiped hotels
  useEffect(() => {
    const index = results.findIndex((el) => el.id === selectedHotel)
    setActiveStep(index >= 0 ? index : 0)
  }, [results, selectedHotel])

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  const buildSwipeAble = () => {
    if (!isSearchLoading) {
      return results.map((hotel) => (
        <HotelSearchItem
          flex={1}
          key={hotel.id}
          hotel={hotel}
          ratings={ratings}
          isSmall={showMap}
        />
      ))
    }
    return [
      <SmallSkeletons staticPosition />,
      <SmallSkeletons staticPosition />,
      <SmallSkeletons staticPosition />,
    ]
  }

  return (
    <>
      {showMap && !isIframed ? (
        <>
          <Button
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={toggleMap}
            style={{
              width: '58px',
              height: '54px',
              position: 'absolute',
              backgroundColor: 'white',
              zIndex: '5',
              top: '20px',
              left: '15px',
              borderRadius: '8px',
              cursor: 'pointer',
              padding: 0,
              minWidth: 0,
            }}
          >
            <CloseIcon color="primary" />
          </Button>
          <Button
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={toggleDrawer('left', true)}
            style={{
              width: '58px',
              height: '54px',
              position: 'absolute',
              backgroundColor: 'white',
              zIndex: '5',
              top: '20px',
              right: '15px',
              borderRadius: '8px',
              cursor: 'pointer',
              minWidth: 0,
            }}
          >
            <TuneRoundedIcon color="primary" />
            <Typography color="primary" style={{ fontWeight: 700 }}>
              {activeFilters > 0 && activeFilters}
            </Typography>
          </Button>
          <GoogleMap
            initialLat={lat}
            initialLng={lng}
            hotels={results}
            isMiddleStep={isMiddleStep}
            showMap={showMap}
          />
          <SwipeableViews
            axis={'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            style={{
              position: 'fixed',
              bottom: '40px',
              width: '100%',
              height: '124px',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
            enableMouseEvents
            slideStyle={{ paddingLeft: '4px', paddingRight: '4px' }}
          >
            {buildSwipeAble()}
          </SwipeableViews>
        </>
      ) : (
        <>
          {!isIframed && (
            <Button style={{ padding: 0 }} onClick={toggleMap}>
              <GoogleMap
                initialLat={lat}
                initialLng={lng}
                hotels={results}
                isMiddleStep={isMiddleStep}
                showMap={showMap}
              />
            </Button>
          )}
          <HotelSearchResults
            sortContext={localUseSort}
            isMobile={isMobile}
            isMiddleStep={isMiddleStep}
            search={results}
            showMap={showMap}
            toggleMap={toggleMap}
          />
        </>
      )}
    </>
  )
}

export default MobileView
