import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getHotelSortTypes } from '../utils/hotelSortTypes'

const useSort = (
  sortTypes,
  searchOutgoing,
  searchReturning,
  modelType,
  defaultSortType = null
) => {
  const tmpSortTypes = sortTypes || getHotelSortTypes(defaultSortType)

  const {
    hotelSortType = 'default',
    flightSortType = 'default',
    carSortType,
  } = useSelector((state) => state?.auth?.user?.sortTypes ?? {})

  const getSortType = () => {
    if (defaultSortType && tmpSortTypes.includes(defaultSortType)) {
      return defaultSortType
    }
    if (modelType === 'hotel') {
      return hotelSortType === 'cr' ? 'deal' : 'closest'
    } else if (modelType === 'cars') {
      return carSortType === 'car-company' ? 'car-company' : 'cheapest'
    }
    return flightSortType === 'cr' ? 'deal' : 'price'
  }

  const type = getSortType()
  const tmpSortType = tmpSortTypes.find((sortType) => sortType.type === type)

  const [sort, setSort] = useState(tmpSortType)
  const [anchorEl, setAnchorEl] = useState(null)
  const [nameSearchStr, setNameSearchStr] = useState(null)

  useEffect(() => {
    setSort(tmpSortType)
    // eslint-disable-next-line
  }, [searchOutgoing, searchReturning])

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = React.useCallback(
    (event) => {
      setSort(event.label ? event : sort)
      setAnchorEl(null)
    },
    [sort]
  )

  const handleNameSearch = React.useCallback((txt) => {
    setNameSearchStr(txt)
  }, [])

  return useMemo(
    () => ({
      sort,
      anchorEl,
      handleClick,
      handleClose,
      handleNameSearch,
      nameSearchStr,
    }),
    [nameSearchStr, sort, anchorEl, handleClick, handleClose, handleNameSearch]
  )
}

export default useSort
