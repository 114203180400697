import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import SelectUserDOB from '../containers/profile/reusable/selectUserDOB'
import { useSelector } from 'react-redux'

export const useStyles = makeStyles((theme) => ({
  inputHeading: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 'normal',
    paddingBottom: '11px',
    color: theme.palette.common.black,
  },
  input: {
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: theme.spacing(0.75),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(0.75),
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
  },
}))

const useFormsHook = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { countriesList } = useSelector((state) => state.other)

  const buildGender = (formik, index) => {
    const name = 'sex'
    const tmpName = `travellers.${index}.sex`
    const error =
      index !== false
        ? formik?.touched?.travellers?.[index]?.[name] &&
          formik?.errors.travellers?.[index]?.[name]
        : formik?.touched[name] && formik?.errors[name]
    return (
      <FormControl component="fieldset" error={!!error}>
        <Typography className={classes.inputHeading}>{t('sex')}</Typography>
        <Field name={tmpName}>
          {({ field }) => {
            return (
              <>
                <RadioGroup
                  {...field}
                  row
                  name={tmpName}
                  value={formik?.values?.travellers?.[index]?.sex || ''}
                >
                  <FormControlLabel
                    key={'sex-male'}
                    value="male"
                    control={<Radio color="primary" />}
                    label={t('male')}
                  />
                  <FormControlLabel
                    key={'sex-female'}
                    value="female"
                    control={<Radio color="primary" />}
                    label={t('female')}
                  />
                </RadioGroup>
                {error && <span className={classes.errorMessage}>{error}</span>}
              </>
            )
          }}
        </Field>
      </FormControl>
    )
  }

  const buildBirthDateField = (formik, name, index, title, key) => {
    const tmpName = index !== false ? `${key}.${index}.${name}` : name
    const tmpVal = index !== false ? formik?.values?.[key]?.[index]?.[name] : ''
    const touched = formik?.touched?.[key]?.[index]?.[name]
    const errors = formik?.errors?.[key]?.[index]?.[name]

    return (
      <Box>
        <Typography className={classes.inputHeading}>{title} *</Typography>
        <Box>
          <SelectUserDOB
            dob={tmpVal}
            onChange={(d) => {
              formik?.setFieldValue(tmpName, d)
            }}
            name={tmpName}
            touched={touched}
            errors={errors}
          />
        </Box>
      </Box>
    )
  }

  const buildLoyaltyField = (formik, name, index, key, placeholder) => {
    const tmpName = index !== false ? `${key}.${index}.${name}` : name
    const tmpVal = index !== false ? formik?.values?.[key]?.[index]?.[name] : ''

    const pasteHandler = (event) => {
      let text = event.clipboardData.getData('Text')

      text = text.replace(/[^a-zA-Z0-9]/gm, '')
      formik?.setFieldValue(tmpName, text)
      event.preventDefault()
    }

    const inputHandler = (event) => {
      if (!/[a-zA-Z0-9]/.test(event.key)) {
        event.preventDefault()
      }
    }

    return (
      <Box>
        <TextField
          name={tmpName}
          autoComplete={'off'}
          inputProps={{ autoCorrect: 'off', spellCheck: 'false' }}
          fullWidth
          InputLabelProps={{ shrink: false }}
          value={tmpVal}
          variant="outlined"
          className={classes.phoneFieldInput}
          onKeyPress={inputHandler}
          onChange={(e) => {
            formik?.setFieldValue(tmpName, e.target.value)
          }}
          placeholder={placeholder}
          onPaste={pasteHandler}
        />
      </Box>
    )
  }

  const buildCountryField = (
    formik,
    name,
    title,
    fromAddress = false,
    index = false
  ) => {
    const tmpName =
      index !== false
        ? `travellers.${index}.${name}`
        : fromAddress
        ? `addressDetails.${name}`
        : name
    const tmpVal =
      index !== false
        ? formik?.values?.travellers?.[index]?.[name]
        : fromAddress
        ? formik?.values?.addressDetails?.[name]
        : formik?.values?.[name]
    const error =
      index !== false
        ? formik?.touched?.travellers?.[index]?.[name] &&
          formik?.errors.travellers?.[index]?.[name]
        : fromAddress
        ? formik?.touched?.addressDetails?.[name] &&
          formik?.errors?.addressDetails?.[name]
        : formik?.touched[name] && formik?.errors[name]
    const opts = countriesList.map((c) => {
      return (
        <option value={name === 'nationality' ? c.alpha3Code : c.code}>
          {c.name}
        </option>
      )
    })
    return (
      <Box>
        <Typography className={classes.inputHeading}>{title} *</Typography>
        <Box>
          <Select
            native
            name={tmpName}
            value={tmpVal}
            variant="outlined"
            className={classes.input}
            InputLabelProps={{ shrink: false }}
            onChange={formik?.handleChange}
            style={{ borderRadius: theme.spacing(0.75) }}
            error={!!error}
          >
            <option value={''}> </option>
            {opts}
          </Select>
          {error && <span className={classes.errorMessage}>{error}</span>}
        </Box>
      </Box>
    )
  }

  return {
    buildGender,
    buildBirthDateField,
    buildCountryField,
    buildLoyaltyField,
  }
}

export default useFormsHook
