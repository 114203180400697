import React, { memo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, CardMedia } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { openSingleHotelImageAction } from '../../../store/modals/modalAction'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: { height: '100%' },
  singleImg: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    //    borderRadius: '8px',
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    //    borderRadius: '8px',
  },
  btn: {
    position: 'absolute',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.common.white,
  },
}))

const SingleHotelImages = ({ hotelImages }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const mainImage = hotelImages[0]
  const images = hotelImages.slice(1)

  const [actualImage, setActualImage] = useState(mainImage)

  const openImageModal = () => dispatch(openSingleHotelImageAction())

  const renderImages = images.map((image, i) => {
    if (i === 0 || i > 4) return null
    return (
      <Grid item xs={6} key={i}>
        <Box position="relative" width="100%" height="100%">
          {image && image.split('.')[image.split('.').length - 1] !== 'png' ? (
            <CardMedia
              className={classes.img}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              image={image}
              component="img"
              title="hotel room"
            />
          ) : (
            <Box
              style={{
                backgroundColor: '#f3f3f3',
                height: '100%',
                minHeight: '170px',
                width: '100%',
                objectFit: 'cover',
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography>Image missing</Typography>
            </Box>
          )}

          {i === 4 ? (
            <Button
              onClick={openImageModal}
              color="primary"
              variant="outlined"
              className={classes.btn}
            >
              {t('all images')}
            </Button>
          ) : (
            ''
          )}
        </Box>
      </Grid>
    )
  })

  return (
    <Box className={classes.root}>
      <Grid spacing={2} container>
        <Grid item xs={6}>
          {actualImage &&
          actualImage?.split('.')[actualImage?.split('.').length - 1] !==
            'png' ? (
            <CardMedia
              className={classes.singleImg}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              image={actualImage}
              component="img"
              title="hotel room"
              onError={(e) => {
                setActualImage(null)
              }}
            />
          ) : (
            <Box
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#f3f3f3',
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography>Image missing</Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={6} container spacing={2}>
          {renderImages}
        </Grid>
      </Grid>
    </Box>
  )
}

export default memo(SingleHotelImages)
