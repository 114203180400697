import agent from './agent'

// **GET** user profile
export const fetchGetUser = async (ctKey = null) => {
  const url = '/v2/profile'
  try {
    const { data } = await agent.get(url, { cityCityCT: ctKey })

    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

// **GET** site publicity
export const getSitePublicity = async () => {
  const url = '/v2/agency-publicity'
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}

// **POST** update user
export const fetchUpdateUserInfo = async (newData) => {
  const url = '/v2/profile/update'
  try {
    const { data } = await agent.post(url, newData)
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

// **POST** update user favorites
export const fetchUpdateUserFavorites = async (newFavorites) => {
  const url = '/v2/profile/update-favorites'
  try {
    const { data } = await agent.post(url, newFavorites)
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}
// **POST** Refresh Token
export const fetchRefreshAccessToken = async (token) => {
  const url = '/v2/auth/token'
  try {
    const { data } = await agent.post(url, { refresh_token: token })
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

// **POST** Login user
export const fetchLogInUser = async (user) => {
  const url = user.agency
    ? `https://${user.agency}/api/v2/auth/login`
    : '/v2/auth/login'

  const { data } = await agent.post(url, user)
  return data
}

// **POST** Switch company
export const switchCompany = async (companyId) => {
  const url = `/v2/switch-company?companyId=${companyId}`

  const { data } = await agent.post(url)
  return data
}

// **POST** Update password

export const fetchUpdatePassword = async (values) => {
  try {
    const url = '/profile/changepass'
    const { data } = await agent.post(url, values)
    return data
  } catch (e) {
    throw e
  }
}

// **POST** Register user
export const fetchRegisterUser = async (user) => {
  const url = '/v2/auth/register'
  const { data } = await agent.post(url, user)
  return data
}

// **POST** Activate user

export const fetchActivateUser = async (values) => {
  const url = '/v2/auth/activate'
  try {
    const { data } = await agent.post(url, values)
    return data
  } catch (e) {
    throw e
  }
}

export const fetchChangePassword = async (values) => {
  const url = '/v2/auth/forgot/change'
  try {
    const { data } = await agent.post(url, values)
    return data
  } catch (e) {
    throw e
  }
}

// **POST** Forgot password

export const fetchForgotPassword = async (email) => {
  const url = '/v2/auth/forgot'
  try {
    const { data } = await agent.post(url, { email })
    return data
  } catch (e) {
    throw e
  }
}

// **POST** Get user status
export const getUserStatus = async (user) => {
  const url = user.agency
    ? `https://${user.agency}/api/v2/auth/user/check-status`
    : '/v2/auth/user/check-status'

  const { data } = await agent.post(url, {
    email: user.email,
    sendActivation: user.sendActivation || false,
  })
  return data
}

// **POST** Get user status
export const activateBookLink = async (jwt) => {
  const url = '/v2/auth/activate-booking-link'
  const { data } = await agent.post(url, { jwt })
  return data
}

export const joinBookLink = async (identifier, recipientData) => {
  const url = '/v2/auth/join-booking-link'
  const { data } = await agent.post(url, { identifier, ...recipientData })
  return data
}

export const fetchBookLink = async (identifier) => {
  const url = `/v2/auth/booking-link/${identifier}`
  const { data } = await agent.get(url)
  return data
}

export const fetchOidcLoginUrl = async (companyId) => {
  const url = `/v2/auth/oidc/login-url/${companyId}`
  const { data } = await agent.get(url)
  return data
}

export const fetchOidcLogoutUrl = async (companyId) => {
  const url = `/v2/auth/oidc/logout-url/${companyId}`
  const { data } = await agent.get(url)
  return data
}

export const authenticateWithOidc = async (companyId, code) => {
  const { data } = await agent.post(`/v2/auth/oidc/${companyId}/login`, {
    code,
  })

  return data
}

export const completeOidcRegistration = async (
  companyId,
  user,
  editingToken
) => {
  const { data } = await agent.post(
    `/v2/auth/oidc/${companyId}/complete-registration`,
    {
      user,
      editingToken,
    }
  )

  return data
}
