import React, { useContext, useEffect, Fragment } from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch/Switch'
import { UserContext } from '../../containers/modals/employeesUserModal'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  shadowText: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    opacity: 0.6,
  },
  valueText: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  notice: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    color: '#f00',
    marginLeft: '10px',
  },

  removeBtn: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    lineHeight: 'normal',
    padding: '8px 15px',
  },
  container: {
    margin: theme.spacing(3, 0),
  },
  centerFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  docContainer: {
    flexWrap: 'nowrap'
  }
}))

const RowWithDivider = ({ name, value = null, notice = null, onRemove }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [switcher, setSwitcher] = useContext(UserContext)

  useEffect(() => {
    if (typeof value === 'boolean') {
      setSwitcher(value)
    }
  }, [value, setSwitcher])

  const handleChange = () => {
    setSwitcher(!switcher)
  }

  const isRemoveBtn = !!onRemove

  const getValueLine = () => {
    return typeof value === 'boolean' ? (
      <Grid
        className={classes.centerFlex}
        key={2}
        xs={isRemoveBtn ? 12 : 6}
        md={isRemoveBtn ? 5 : 6}
      >
        <Switch
          checked={typeof switcher === 'boolean' ? switcher : value}
          color="primary"
          onChange={handleChange}
        />
      </Grid>
    ) : (
      <Grid
        className={classes.centerFlex}
        key={3}
        xs={isRemoveBtn ? 12 : 6}
        md={isRemoveBtn ? 5 : 6}
      >
        <Typography className={classes.valueText}>{value || '–'}</Typography>

        {notice !== null && (
          <Typography className={classes.notice}>{notice}</Typography>
        )}
      </Grid>
    )
  }

  if (isRemoveBtn) {
    return (
      <Fragment>
        <div className={classes.container}>
          <Grid container>
            <Grid container xs={7} md={10}>
              <Grid
                className={classes.centerFlex}
                key={1}
                xs={12}
                md={value !== null ? 7 : 12}
              >
                <Typography className={classes.shadowText}>{name}</Typography>
              </Grid>
              {value !== null && getValueLine()}
            </Grid>
            <Grid
              key={4}
              xs={5}
              md={2}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={onRemove}
                variant="outlined"
                color="secondary"
                className={`${classes.removeBtn}`}
              >
                {t('exact del')}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Divider />
      </Fragment>
    )
  }

  return (
    <>
      <div className={classes.container}>
        <Grid container className={classes.docContainer}>
          <Grid
            className={classes.centerFlex}
            key={1}
            xs={6}
          >
            <Typography className={classes.shadowText}>{name}</Typography>
          </Grid>
          { getValueLine() }
        </Grid>
      </div>
      <Divider />
    </>
  )
}

export default RowWithDivider
