import React, { memo } from 'react'
import { Box, Typography, useMediaQuery } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import GetCompanyIconElement from '../../../components/reusable/getCompanyIcon'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'
import Policy from '../../reusable/tags/policy'
import { flightIsSameDir } from '../../../utils/general'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '0 3px',
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  invalidDName: {
    backgroundColor: '#fff5e0',
    padding: '4px 5px',
    borderRadius: '3px',
  },

  flightAndTrainLabel: {
    color: 'black',
    backgroundColor: '#ff9a50',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(1.5),
  },
}))

/**
 *
 * @param trip
 * @param title
 * @param home
 * @param compareTrip flight to compare destinations
 * @returns {JSX.Element}
 * @constructor
 */
const ChoosenTripCard = ({ trip, title, home, compareTrip, fare }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isSameDir = compareTrip ? flightIsSameDir(compareTrip, trip) : null
  const rotateIcon = home ? classes.rotate : null
  const isDirect = trip?.via?.length <= 1
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const hasTrain = trip.via.find((via) => via.isTrain)

  const isDealFare = fare ? fare.isDealFare : trip.isDealFare
  const isCorporateFare = fare ? fare.isCorporateFare : trip.isCorporateFare
  const exceedsPolicy = fare ? fare.exceedsPolicy : trip.exceedsPolicy

  return (
    <Box p={2} pb={1}>
      <Box className={classes.align} pt={1} pb={1.5}>
        <ArrowForwardIcon className={rotateIcon} />
        <Typography className={classes.weight} variant="body2">
          {title}
        </Typography>
      </Box>

      <Box className={classes.align} pb={1.5}>
        <Typography>
          <Moment format="ddd D MMM">{trip?.date}</Moment>
        </Typography>
        <Typography className={classes.weight}>
          {trip?.departTime}–{trip?.arriveTime}
        </Typography>
      </Box>

      <Box className={classes.align} pb={1.5}>
        <Typography>
          <span
            className={`${
              !!isSameDir && !isSameDir.isFrom ? classes.invalidDName : ''
            }`}
          >
            {trip?.from?.city} ({trip?.from?.shortName})
          </span>{' '}
          –{' '}
          <span
            className={`${
              !!isSameDir && !isSameDir.isTo ? classes.invalidDName : ''
            }`}
          >
            {trip?.to?.city} ({trip?.to?.shortName})
          </span>
        </Typography>
      </Box>

      <Box className={classes.align} pb={2}>
        <GetCompanyIconElement small={isMobile} company={trip?.company} />
        <Typography className={classes.weight}>
          {trip?.formattedTravelTime}
        </Typography>
        <Typography>
          {isDirect
            ? t('filter directly small')
            : trip.via.length - 1 > 1
            ? `${trip.via.length - 1} ${t('changes')}`
            : `${trip.via.length - 1} ${t('change')}`}
        </Typography>
        <Policy
          rootStyles={{ display: 'inline-flex' }}
          deal={isDealFare}
          corporate={isCorporateFare}
          exceedsPolicy={exceedsPolicy}
        />

        {hasTrain && (
          <Box mr={0} display="inline-flex">
            <Typography
              component={'span'}
              className={classes.flightAndTrainLabel}
            >
              {t('flight and train')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default memo(ChoosenTripCard)
