import React, {useCallback, useMemo} from 'react'
import {Box, Select} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TicketLabel from "../../flights/details/ticketLabel"

const useStyles = makeStyles((theme) => ({
  bagsOuter: {
    '& .user-bags': {
      borderTop: `solid 1px ${theme.palette.border}`,
      paddingTop: '35px',
      marginBottom: '35px',

      '& .user-name-block': {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '20px',
      },
      '& .service-row': {
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {
          marginBottom: '0',
        },

        '& .incl-label': {
          width: '84px',
          height: '30px',
          borderRadius: '4px',
          backgroundColor: '#dcf7d9',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
          lineHeight: 'normal',
          fontWeight: '600',
          color: '#333',
          marginRight: '15px',
        },

        '& .bag-srv-text': {
          fontSize: '16px',
          color: '#333',
          flex: '1',
        }
      },
      '& .bag-select-block': {
        marginTop: '25px',

        '& .bag-select-header': {
          marginBottom: '12px',
        },

        '& .bag-select': {
          height: '54px',
          width: '100%',
          borderRadius: '4px',
          backgroundColor: '#fff',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: 'normal',
          color: '#333',
          padding: '4px',
          backgroundPosition: 'calc(100% - 14px)'
        }
      }
    }
  }
}))

const SelectBags = ({ fare, bags, selected, select, passengers }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const setBag = (uInd, type, val) => {
    let resBags = {}
    if (!!selected && typeof resBags === 'object') {
      resBags = {...selected}
    }

    let currUserBag = resBags?.[uInd] || null;

    if (!!val) {
      const foundVal = bags?.[type]?.find(b => b.checkKey === val)
      if (!currUserBag || typeof currUserBag !== 'object' ) currUserBag = {}
      currUserBag[type] = foundVal
    } else if (!!currUserBag?.[type]) {
      currUserBag[type] = null
    }
    resBags[uInd] = currUserBag;

    const mappedResults = {}
    for (let uKey in resBags) {
      const uBag = resBags[uKey]
      if (!uBag?.carryOn && !uBag?.general) continue;
      mappedResults[uKey] = {...resBags[uKey]}
    }

    document.activeElement.blur() // remove selectBox green border (remove focus state)
    select(resBags)
  }

  const buildBagOpt = useCallback(b => {
    const pt = b?.pricingType || 'PPR'
    const bgCnt = b.count
    const dscrTxt = b.description
    const descr = `${(pt !== 'PPK') ? bgCnt : '' } ${t(dscrTxt, pt === 'PPK' ? {w: b.w} : {})}, ${b.price} SEK`

    return {value: b.checkKey, text: descr}
  }, [])

   const bagServices = useMemo(() => {
    const bagKeys = ['UBAG', 'HBAG', 'BAG']
    const ff = fare?.ff || []
    const serviceTexts = []
    let hbagExists = false

    ff.forEach(f => {
      const srvices = f?.ffServ || []
      srvices.forEach(s => {
        if (!bagKeys.includes(s.type) || serviceTexts.includes(s.text)) return false
        serviceTexts.push(s.text)
        if (s.type === 'HBAG' ) hbagExists = true
      })
    })
    if (!hbagExists) serviceTexts.unshift('HBAG')
    return serviceTexts
  }, [fare])

  const bagsOpts = {
    carryOn: (bags?.carryOn || []).map((b) => buildBagOpt(b)),
    general: (bags?.general  || []).map((b) => buildBagOpt(b)),
  }

  const selectedVal = {}
  passengers.forEach(p => {
    const bag = selected?.[p.uind]
    if (!bag) {
      selectedVal[p.uind] = {carryOn: null, general: null}
      return false
    }

    selectedVal[p.uind] = {
      carryOn: bag?.carryOn?.checkKey || null,
      general: bag?.general?.checkKey || null
    }
  })

  return (
    <Box key="bags-outer" className={`${classes.bagsOuter}`}>
      {passengers.map((u, uind) => (
        <Box key={`user-bags-${uind}`} className="user-bags">
          <Box key="user-name-block" className="user-name-block">
            {u.firstName} {u.lastName}
          </Box>
          <Box key="incl-bags-block" className="incl-bags-block">
            {bagServices.map((sBag, sind) => (
              <Box key={`service-row-${sind}`} className="service-row">
                <Box key="incl-label" className="incl-label">{t('included')}</Box>
                <Box key="bag-srv-text" className="bag-srv-text">{t(sBag)}</Box>
              </Box>
            ))}
          </Box>
          {(!!bagsOpts.carryOn.length || !!selectedVal[u.uind].carryOn) && (
            <Box key="carryon-bag-select-block" className="bag-select-block">
              <Box key="bag-select-header" className="bag-select-header">{t('add carry-on baggage')}</Box>
              <Select
                  native
                  value={selectedVal[u.uind].carryOn}
                  variant="outlined"
                  className={'bag-select'}
                  InputLabelProps={{ shrink: false }}
                  onChange={(e) => setBag(u.uind, 'carryOn', e.target.value)}
              >
                <option value={null}>{t('select')}...</option>
                {bagsOpts.carryOn.map(bo => (
                    <option key={`b-opt-${bo.value}`} value={bo.value}>{bo.text}</option>
                ))}
              </Select>
            </Box>
          )}
          <Box key="general-bag-select-block" className="bag-select-block">
            <Box key="bag-select-header" className="bag-select-header">{t('add luggage')}</Box>
            <Select
              native
              value={selectedVal[u.uind].general}
              variant="outlined"
              className={'bag-select'}
              InputLabelProps={{shrink: false}}
              onChange={(e) => setBag(u.uind, 'general', e.target.value)}
            >
              <option value={null}>{t('select')}...</option>
              {bagsOpts.general.map(bo => (
                <option key={`b-opt-${bo.value}`} value={bo.value}>{bo.text}</option>
              ))}
            </Select>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default SelectBags
