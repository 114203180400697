import React from 'react'
import { Grid, Typography, Box, Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Moment from 'react-moment'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import isSameDay from '../../../../utils/isSameDay'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2, 0),
    borderBottom: '1px solid #f0f0f0',
  },
  trainClasses: {
    display: 'flex',
    alignItems: 'stretch',
    '& > *': {
      margin: '10px 20px',
    },
  },
  fullDate: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  navigationBtn: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
}))

const DateHeader = ({ flight }) => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const params = useParams()
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const updateParams = (oldParams, dateToChange, trip, typeOfTrip) => {
    const uri = Object.keys(oldParams)
      .map((key) => {
        const travel = kindOfTrip === 'outgoing' ? 'dep' : 'arr'
        oldParams[travel] = dateToChange
        const uri = params[key]
        return uri
      })
      .join('/')

    return `/${typeOfTrip}/${trip}/${uri}`
  }
  const typeOfTrip = path.includes('flights') ? 'flights' : 'trains'
  const kindOfTrip = path.includes('outgoing') ? 'outgoing' : 'returning'

  const relevantDate = kindOfTrip === 'outgoing' ? params.dep : params.arr

  const backDate = () => {
    const subtract = moment(relevantDate)
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
    const pushparams = updateParams(params, subtract, kindOfTrip, typeOfTrip)
    history.push(pushparams)
  }

  const forwardDate = () => {
    const add = moment(relevantDate).add(1, 'days').format('YYYY-MM-DD')
    const pushparams = updateParams(params, add, kindOfTrip, typeOfTrip)
    history.push(pushparams)
  }

  // Hides date header of flight boolean && if Desktop
  if (flight && isDesktop) {
    return null
  }

  const isBackButtonDisabled = () => {
    if (params.arr === '0' && isSameDay(params.dep)) return true
    if (params.arr === '0') return false
    if (isSameDay(params.arr, params.dep) && path.includes('returning'))
      return true
    return false
  }

  const isForwardButtonDisabled = () => {
    if (params.arr === '0') return false
    if (isSameDay(params.dep, params.arr) && path.includes('outgoing'))
      return true
    return false
  }

  return (
    <Box className={classes.wrapper}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          {!isDesktop ? (
            <IconButton disabled={isBackButtonDisabled()} onClick={backDate}>
              <ArrowBackIcon
                color={isBackButtonDisabled() ? 'disabled' : 'primary'}
                fontSize="medium"
              />
            </IconButton>
          ) : (
            <Button
              disabled={isBackButtonDisabled()}
              onClick={backDate}
              className={classes.navigationBtn}
              startIcon={
                <ArrowBackIcon
                  color={isBackButtonDisabled() ? 'disabled' : 'primary'}
                  fontSize="large"
                />
              }
            >
              <Typography variant="body2">
                <Moment format="D MMMM" subtract={{ days: 1 }}>
                  {kindOfTrip === 'outgoing' ? params.dep : params.arr}
                </Moment>
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid item>
          <Typography className={classes.fullDate}>
            <Moment format={!isDesktop ? 'ddd D MMM YYYY' : 'dddd D MMMM YYYY'}>
              {kindOfTrip === 'outgoing' ? params.dep : params.arr}
            </Moment>
          </Typography>
        </Grid>
        <Grid item>
          {!isDesktop ? (
            <IconButton
              disabled={isForwardButtonDisabled()}
              onClick={forwardDate}
            >
              <ArrowForwardIcon
                color={isForwardButtonDisabled() ? 'disabled' : 'primary'}
                fontSize="medium"
              />
            </IconButton>
          ) : (
            <Button
              disabled={isForwardButtonDisabled()}
              onClick={forwardDate}
              className={classes.navigationBtn}
              endIcon={
                <ArrowForwardIcon
                  color={isForwardButtonDisabled() ? 'disabled' : 'primary'}
                  fontSize="large"
                />
              }
            >
              <Typography variant="body2">
                <Moment format="D MMMM" add={{ days: 1 }}>
                  {kindOfTrip === 'outgoing' ? params.dep : params.arr}
                </Moment>
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default DateHeader
