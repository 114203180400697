import constants from './constants'

const initialState = {
  all: [],
  isAllLoading: false,
  isFlygtaxiLoading: false,
  allError: null,
  /* Search-location */
  locations: [],
  isLocationLoading: false,
  isLocationError: null,
  serviceLocations: [],
  isServiceLocationLoading: false,
  isServiceLocationError: null,
  /* results from searching transfer Flyg-Taxi  */
}

export default (state = initialState, action) => {
  switch (action.type) {
    // Location
    case constants.SET_TRANSFER_LOCATION:
      return {
        ...state,
        locations: action.payload,
      }
    case constants.SET_TRANSFER_LOCATION_LOADING:
      return {
        ...state,
        isLocationLoading: action.payload,
      }
    case constants.SET_TRANSFER_LOCATION_ERROR:
      return {
        ...state,
        isLocationError: action.payload,
      }
    case constants.REMOVE_LOCATION_STATE:
      return {
        ...state,
        locations: [],
      }
    // Taxi
    case constants.SET_TAXI:
      return {
        ...state,
        all: [...state.all, action.payload].flat(),
      }
    case constants.SET_TAXI_LOADING:
      return {
        ...state,
        isAllLoading: action.payload,
      }
    case constants.SET_FLYGTAXI_LOADING:
      return {
        ...state,
        isFlygtaxiLoading: action.payload,
      }
    case constants.SET_TAXI_ERROR:
      return {
        ...state,
        allError: action.payload,
      }
    case constants.REMOVE_TRANSFER_SEARCH:
      return {
        ...state,
        all: [],
      }

    default:
      return state
  }
}
