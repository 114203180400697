import Cookies from 'js-cookie'

const configs = {
  2235: {
    priorityHotels: {
      SISTO298: 1,
      ADSTOCBJ: 1,
      ADSTOCEA: 1,
      ADSTOCCP: 1,
      ADSTOCDJ: 1,
      ARSTOARA: 1,
      ADSTOCEP: 1,
      ADSTO02D: 1,
      ADSTOCAH: 1,
      ADSTOCFD: 1,
      ADSTOCFS: 1,
      ADSTOCBN: 1,
      ADSTOCIX: 1,
      ADSTO01J: 1,
      ADSTOCDC: 1,
      ADSTOCBY: 1,
      ADSTOCFA: 1,
    },
  },
  1830: {
    showDLK: true,
  },
  2238: {
    showDLK: true,
  },
}

export const getVirtualCustomer = () => {
  let cookie = Cookies.get('UnitSettings')
  if (cookie) {
    let parsed = JSON.parse(cookie)
    if (parsed.Id) return parsed.Id
  }
  return null
}

export const getVirtualCustomerConfig = () => {
  //   return configs['solvalla']
  return configs[getVirtualCustomer()] || {}
}
export const isVirtualCustomer = () => {
  return getVirtualCustomer() !== null
}
