import agent from './agent'

// **GET** countries list
export const fetchCountries = async (lng) => {
  const url = `/v2/data/get-countries?lng=${lng}`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    console.log('failed to fetch countries')
    return []
  }
}
/**
 * **POST** get user remarks
 *
 * @param id
 * @returns {Promise<*[]|any>}
 */
export const getUserRemarks = async (id) => {
  const url = `/get-user-remarks`
  try {
    const { data } = await agent.post(url, {id})
    return data
  } catch (error) {
    console.log('failed to fetch user remarks')
    return []
  }
}

/**
 * **POST** get agent companies
 *
 * @param name
 * @returns {Promise<*[]|any>}
 */
export const getAgentCompanies = async (name = '') => {
  const url = `/v2/data/get-agent-companies`
  try {
    const { data } = await agent.post(url, {name})
    return data
  } catch (error) {
    return []
  }
}

// **POST** get google places autocomplete
export const fetchGooglePlacesAutocomplete = async (val, lng) => {
  const url = `/v2/google/places?lng=${lng}`
  const isCoords = !!val && typeof (val) === 'object'
  try {
    const { data } = await agent.post(url, isCoords ? {coords: val} : {query: val})
    return data
  } catch (error) {
    return []
  }
}

// **POST** get google place details
export const fetchGooglePlaceDetails = async (placeId, lng, fields = null) => {
  const url = `/v2/google/place-data?lng=${lng}`
  try {
    const { data } = await agent.post(url, {placeId, fields})
    return data
  } catch (error) {
    return null
  }
}

export const getPassengerData = async (passengerIds) => {
  try {
    const { data } = await agent.post('/v2/passenger-data', {passengerIds})
    return  data
  } catch (error) {
    return []
  }
}
