import React from 'react'
import { Button, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightSemiBold,
    textTransform: 'none',
    marginRight: theme.spacing(1),
    padding: '2px 15px',
    '&:last-child': {
      marginRight: 0,
    },
  },
}))

const GuestAuthBtns = ({ onLogin, onRegister }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box>
      {onLogin && (
        <Button
          onClick={onLogin}
          className={classes.btn}
          color="primary"
          variant="outlined"
        >
          {t('login')}
        </Button>
      )}

      {onRegister && (
        <Button
          onClick={onRegister}
          className={classes.btn}
          color="primary"
          variant="outlined"
        >
          {t('sign up')}
        </Button>
      )}
    </Box>
  )
}

export default GuestAuthBtns
