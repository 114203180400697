import React from 'react'
import ModalHook from './reusable/modalHook'
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {Button, useTheme} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {closeAllModals, openEmployeesUserModal} from "../../store/modals/modalAction";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightSemiBold,
    padding: theme.spacing(1.5, 5.5),
    borderRadius: theme.spacing(3),
    letterSpacing: '1.1px',
    lineHeight: `${theme.spacing(2)}px`,
  },
  saveBtn: {
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1.5, 5.5),
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: theme.spacing(3),
    lineHeight: `${theme.spacing(2)}px`,
    letterSpacing: '1.1px',
    boxShadow: 'none',
  },
  cancelBtnMobile: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    textTransform: 'capitalize',
  },
  saveBtnMobile: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.2, 2.8),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'capitalize',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  heading: {
    fontSize: theme.spacing(4),
    letterSpacing: '1.1px',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  text: {
    fontSize: theme.spacing(2),
    textAlign: 'center'
  },
  relative: {
    position: 'relative'
  },
  close: {
    position: 'absolute',
    cursor: 'pointer',
    top: theme.spacing(3),
    right: theme.spacing(3),
  }
}))

const DeactivateUserModal = ({ modalBoolean, user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  const closeModal = () => {
    dispatch(closeAllModals())
    dispatch(openEmployeesUserModal(user))
  }

  return (
    <ModalHook modalBoolean={modalBoolean}>
      <Box pt={18.5} px={isDesktop ? 1.5 : 30} pb={21.5} className={classes.relative}>
        <CloseIcon color="primary" className={classes.close} onClick={() => dispatch(closeAllModals())}/>
        <Typography className={classes.heading}>
          {t('deactivate')} {user.firstName} {user.lastName}?
        </Typography>
        <Typography className={classes.text}>
          {t('want deactivate')} {user.firstName} {user.lastName}?
        </Typography>
        <Box mt={4} display="flex" flexDirection="row-reverse" justifyContent="center">
          <Button variant="contained" color="primary" className={classes.saveBtn} type="submit">
            {t('deactivate')}
          </Button>
          <Button
            className={classes.cancelBtn}
            color="primary"
            variant="outlined"
            onClick={closeModal}
          >
            {t('cancel btn')}
          </Button>
        </Box>
      </Box>
    </ModalHook>
  )
}

export default DeactivateUserModal
