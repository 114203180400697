import React, { memo } from 'react'
import { Box, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { Button } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles(() => ({
  onLoadmoreTrains: {
    textTransform: 'none',
    fontSize: '14px',
  },
  laterTravelPaper: {
    borderRadius: '0 0 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const PreviousResultsScroller = ({ click, show }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Box>
        <Paper elevation={0} className={classes.laterTravelPaper}>
          {show && (
            <Box m={1}>
              <Button
                onClick={click}
                className={classes.onLoadmoreTrains}
                startIcon={<ExpandLess color="primary" fontSize="large" />}
              >
                {t('earlier trips')}
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  )
}

export default memo(PreviousResultsScroller)
