import React, {
  memo,
  useCallback,
  useMemo,
  useEffect,
  useState,
  Fragment,
  useContext,
} from 'react'
import { NavLink, useLocation, useHistory, Link } from 'react-router-dom'
import {
  closeAllModals,
  openLoginModal,
  openSimpleInfoModal,
  toggleCheckoutItemsDrawer,
} from '../../store/modals/modalAction'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import {
  Toolbar,
  ListItem,
  AppBar,
  Typography,
  Container,
  Grid,
  Box,
  IconButton,
  CardMedia,
  Divider,
  Button,
} from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BookingLinkRow from './bookingLinkRow'
import NavLinksDesktop from './navLinksDesktop'
import NavLinksMobile from './navLinksMobile'
import GuestAuthBtns from '../../components/reusable/guestAuthBtns'
import { RootContext } from '../../containers/route/rootWrapper'

// Icons
import FlightIcon from '@material-ui/icons/Flight'
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway'
import HotelIcon from '@material-ui/icons/Hotel'
import MapIcon from '@material-ui/icons/Map'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { setHeaderHeightAction } from '../../store/other/otherAction'
import { getBrandedUrl } from '../../utils/branding'
import GenericMenuItems from '../../components/header/genericMenuItems'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import BannerRow from './bannerRow'
import ExtendCheckoutBanner from './extendCheckoutBanner'
import isIframed from '../../utils/isIframed'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'
import { disableExtendCheckout } from '../../store/search/searchAction'
import { initiateOidcLogin } from '../../utils/oidc/oidcHandler'
import { isLightUser } from '../../utils/lightUsers'
import useHandleClickOnDisabledLink from '../../hooks/useHandleClickOnDisabledLink'
import { People } from '@material-ui/icons'
import CurrencyChooser from '../../components/reusable/currencyChooser'

const useStyles = makeStyles((theme) => ({
  align: { display: 'flex', alignItems: 'center', height: '100%' },
  menu: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
  },
  cartIcon: {
    height: '32px',
    width: '32px',
  },
  bookingLinkRow: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.yellow,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '33px',
  },
  showLinkModal: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.blueLink,
    padding: '0 12px',
    cursor: 'pointer',
  },
  activeCustom: {
    '&$navLinks': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& $linkContainer': {
      transition: '200ms ease',
      border: '3px solid #08c5b9',
      borderRadius: '9px',
      padding: '3px 18px',
      marginTop: '10px',
      lineHeight: 'normal',
      height: 'auto',
    },
  },
  navLinks: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: !isIframed && '5px solid transparent',
    height: !isIframed && '100%',
    maxHeight: isIframed && '74px',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',

    '@media(max-width: 1021px)': {
      '& $linkContainer': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  active: {
    '&$navLinks': {
      paddingLeft: 0,
      paddingRight: 0,
      whiteSpace: 'nowrap',
    },
    '& $linkContainer': {
      transition: '200ms ease',
      border: '3px solid #08c5b9',
      borderRadius: '9px',
      padding: '3px 18px',
      lineHeight: 'normal',
      height: 'auto',
      minHeight: isIframed && '38px',
      display: isIframed && 'flex',
      justifyContent: isIframed && 'center',
    },
    '@media(max-width: 1021px)': {
      '& $linkContainer': {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
  },
  navLinksCustom: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  fontWeight: {
    fontWeight: 600,
  },
  weight: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  homeMadeDivider: {
    height: '26px',
    width: '1px',
    backgroundColor: theme.palette.common.black,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    height: '40px',
  },
  disabledLink: {
    cursor: 'pointer',
  },
  hamburgerIcon: {
    // marginLeft: '20px',
    color: theme.palette.primary.main,
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    minHeight: isIframed && '44px',
    maxHeight: isIframed && '44px',
  },
  logo: {
    height: '40px',
    width: '120px',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      height: '33px',
      width: '100px',
    },
  },
  logoDivider: {
    color: theme.palette.primary.main,
    width: '1px',
    height: '40px',
    marginLeft: '20px',
    marginRight: '20px',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  companyLogo: {
    height: '40px',
    width: '120px',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      height: '33px',
      width: '100px',
    },
  },
  route: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  iconBlock: {
    width: '30px',
    '& .MuiIconButton-root': {
      padding: '12px 0',
    },
  },
  expandIcon: {
    color: '#08c5b9',
  },
  btnContainerIframe: {
    backgroundColor: 'rgb(255, 255, 255)',
    minWidth: '110px',
    display: 'flex',
  },
  toolbar: {
    minHeight: '60px',
  },
  linkToolbar: {
    minHeight: '60px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  iframeToolbar: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  toCheckoutBtn: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    display: 'inline-flex',
    cursor: 'pointer',
    letterSpacing: '0.7px',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 'normal',
  },
  cartButton: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  extendCheckoutCenterHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '261px',
  },
  checkoutBtnArrow: {
    marginRight: '10px',
  },
}))
const iniPages = ['/trains', '/flights', '/hotels', '/cars']
const Header = ({ isCustomLanding, hideContent }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { routeGroup } = useContext(RootContext)
  const hashArray =
    location?.hash && location.hash !== '' && location.hash !== '#'
      ? location?.hash?.replace('#', '').split('&')
      : []
  const open = hashArray.includes('navmenu')
  const { activeSearchType = 'flights', isIframe } = useSelector(
    (state) => state.customization
  )
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    isAuthenticated,
    user,
    authType,
    showDisabledLinks,
    showCalendarInHeader,
    showGroupTravelInHeader,
    currencies,
    allowCurrencySelection,
    allowRegistration,
  } = useSelector((state) => state.auth)

  const { extendCheckout: flightExtCheckout } = useSelector(
    (state) => state.search.flights
  )

  const { extendCheckout: trainExtCheckout } = useSelector(
    (state) => state.search.train
  )

  const isExtendCheckout =
    (routeGroup === 'flightSearch' && flightExtCheckout) ||
    (routeGroup === 'trainSearch' && trainExtCheckout)

  const [route, setRoute] = useState()
  const history = useHistory()
  const { landingPage, menu: customMenu } = useSelector(
    (state) => state.customization
  )
  const [openCustomMenu, setOpenCustomMenu] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const logoUrl = getBrandedUrl('logo_retina.png')
  const companyLogoUrl = user?.companyLogo
  const hasCustomMenu = customMenu !== null
  const isAgent = !!isAuthenticated && authType === 'agent' // is user auth type - agent
  const { isFlightsEnabled, isCarsEnabled, isHotelsEnabled, isTrainsEnabled } =
    useEnabledBookingTypesHook()

  const { onlyOidc } = useSelector((state) => state.other)

  const handleClickOnDisabledLink = useHandleClickOnDisabledLink()

  const isWidget = window.parent !== window

  const isNavActive = useCallback(
    (match, location, link) => {
      const type = link.replace('/', '')
      if (
        location.pathname.includes('/flights-multicity') &&
        link === '/flights'
      ) {
        return true
      }
      if (isIframe && type !== 'my-trips') {
        return location.pathname.includes(type)
      }
      if (!!match) return true
      if (!isCustomLanding) return false
      return link === `/${activeSearchType || 'flights'}`
    },
    [activeSearchType, isCustomLanding]
  )

  const setOpen = (isOpen) => {
    if (isOpen) {
      const currHashArray = [...(hashArray || []), 'navmenu']
      return history.replace({
        ...location,
        hash: '#' + currHashArray.join('&'),
      })
    } else if (!isOpen && open) {
      const currHashArray = hashArray?.filter((item) => item !== 'navmenu')
      return history.replace({
        ...location,
        hash: '#' + currHashArray.join('&'),
      })
    }
  }

  const isInitialPage = () => {
    const initialPath = location.pathname.split('/')[1]
    return iniPages.includes(`/${initialPath}`)
  }

  useEffect(() => {
    const pathSplited = location.pathname.split('/')
    const isIniPage =
      iniPages.includes(`/${pathSplited?.[1]}`) && !pathSplited?.[2]
    if (!!isIniPage && !isExtendCheckout) {
      dispatch(disableExtendCheckout())
    }
  }, [dispatch, location.pathname, isExtendCheckout])

  const handleClick = () => {
    // Custom landing page

    const lang = (i18n.language?.split('-')[0] || 'sv').toLowerCase()

    if (landingPage && landingPage?.content?.[lang]?.slug && !isAuthenticated) {
      history.push(`/c/${landingPage.content[lang].slug}`)
    } else {
      const initialPath = location.pathname.split('/')[1]
      if (isInitialPage()) {
        if (`/${initialPath}` !== location.pathname)
          history.push(`/${initialPath}`)
      } else {
        history.push(iniPages[0])
      }
    }
  }

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        dispatch(setHeaderHeightAction(node.offsetHeight))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    switch (location.pathname) {
      case '/profile/personal-information':
        setRoute(t('nav personal'))
        break
      case '/profile/id-documents':
        setRoute(t('nav id'))
        break
      case '/profile/policy-document':
        setRoute(t('policy document'))
        break
      case '/profile/my-loyalty-programs':
        setRoute(t('nav programs'))
        break
      case '/my-trips':
        setRoute(t('nav my travels'))
        break
      default:
        setRoute(undefined)
        break
    }
  }, [t, location.pathname])

  const toggleDrawer = () => {
    setOpen((prevState) => !prevState)
  }

  const openLogin = () => {
    if (onlyOidc) {
      initiateOidcLogin('login')
    } else {
      dispatch(openLoginModal())
    }
  }

  const openRegistration = () => {
    history.push('/register')
  }

  const close = () => setOpen(false)

  let links = []

  if (isTrainsEnabled) {
    links.push({
      to: '/trains',
      title: `${t('nav train')}`,
      icon: DirectionsRailwayIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      to: '/trains',
      searchMode: 'trains',
      title: `${t('nav train')}`,
      icon: DirectionsRailwayIcon,
      disabled: true,
    })
  }

  if (isFlightsEnabled) {
    links.push({
      to: '/flights',
      title: `${t('nav flight')}`,
      icon: FlightIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      to: '/flights',
      searchMode: 'flights',
      title: `${t('nav flight')}`,
      icon: FlightIcon,
      disabled: true,
    })
  }

  if (isHotelsEnabled) {
    links.push({ to: '/hotels', title: `${t('nav hotel')}`, icon: HotelIcon })
  } else if (showDisabledLinks) {
    links.push({
      to: '/hotels',
      searchMode: 'hotels',
      title: `${t('nav hotel')}`,
      icon: HotelIcon,
      disabled: true,
    })
  }

  if (isCarsEnabled) {
    links.push({
      to: '/cars',
      title: `${t('cars')}`,
      icon: DirectionsRailwayIcon,
    })
  } else if (showDisabledLinks) {
    links.push({
      to: '/cars',
      searchMode: 'cars',
      title: `${t('cars')}`,
      icon: DirectionsRailwayIcon,
      disabled: true,
    })
  }

  if (
    isAuthenticated &&
    !!user &&
    !user?.isBookingLink &&
    !isAgent &&
    !user?.hideMyTrips
  ) {
    links.push({
      to: '/my-trips',
      title: `${t('nav my travels')}`,
      icon: MapIcon,
    })
  }

  if (isAuthenticated && user?.bookingCalendar && showCalendarInHeader) {
    links.push({
      to: '/company-trips-calendar',
      title: `${t('booking calendar short')}`,
      icon: DateRangeIcon,
    })
  }

  if (!user?.isBookingLink) {
    links.push({
      to: '/checkIn',
      title: `${t('airline checkin')}`,
      icon: FlightIcon,
    })
  }

  let lang = i18n.languages[0] || 'sv'
  let policyField = `hasPolicyDocument${lang.toUpperCase()}`
  if (user?.[policyField]) {
    links.push({
      title: t('policy document'),
      to: `/policy`,
      icon: PictureAsPdfIcon,
    })
  }

  if (isAuthenticated && user?.bookingCalendar && showGroupTravelInHeader) {
    links.push({
      title: t('group travels'),
      to: '/group-travels',
      icon: People,
    })
  }

  const renderMobileBtn = (
    <Fragment>
      {isAuthenticated && !isIframe ? (
        <Grid item>
          <Box height="100%" display="flex" alignItems="center">
            <MenuIcon
              className={classes.hamburgerIcon}
              fontSize="medium"
              style={{ marginRight: 'auto' }}
              onClick={toggleDrawer}
            />
          </Box>
        </Grid>
      ) : (
        <Grid item>
          <Box height="100%" display="flex" alignItems="center">
            {!isIframe && (
              <GuestAuthBtns
                onLogin={openLogin}
                onRegister={allowRegistration && openRegistration}
              />
            )}

            {hasCustomMenu && (
              <MenuIcon
                className={classes.hamburgerIcon}
                fontSize="medium"
                style={{ marginRight: 'auto' }}
                onClick={toggleDrawer}
              />
            )}
          </Box>
        </Grid>
      )}
    </Fragment>
  )

  const renderCustomMenu = customMenu?.map((menu, i) => {
    const isEmpty = menu.length === 0
    const isMultiple = menu.length > 1

    if (isEmpty) return null

    const lang = (i18n.language?.split('-')[0] || 'sv').toLowerCase()

    if (isMultiple) {
      let mainItem = menu[0]
      let popupMenuItems = menu.map((item) => ({
        slug: item[lang].slug,
        title: item[lang].title,
      }))

      const closeCustomMenu = () => {
        setOpenCustomMenu(null)
        setAnchorEl(null)
      }

      const clickCustomMenu = (event, menuIndex) => {
        setAnchorEl(event.currentTarget)
        setOpenCustomMenu(menuIndex)
      }

      return (
        <Grid item key={i}>
          <ListItem
            component={NavLink}
            to="#"
            className={classes.navLinksCustom}
            onClick={(event) => clickCustomMenu(event, i)}
            activeClassName={classes.activeCustom}
            isActive={() =>
              popupMenuItems.find(
                (subItem) => location.pathname === `/c/${subItem.slug}`
              )
            }
          >
            <Box className={classes.linkContainer} p={2}>
              <Typography className={classes.fontWeight}>
                {mainItem[lang].title}
              </Typography>
              <ExpandMoreIcon className={classes.expandIcon} />
            </Box>
          </ListItem>

          {openCustomMenu === i && (
            <GenericMenuItems
              items={popupMenuItems}
              handleClose={closeCustomMenu}
              anchorEl={anchorEl}
            />
          )}
        </Grid>
      )
    } else {
      let mainItem = menu[0]
      let to = `/c/${mainItem[lang].slug}`

      return (
        <Grid item key={i}>
          <ListItem
            component={NavLink}
            to={to}
            className={classes.navLinksCustom}
            onClick={toggleDrawer}
            activeClassName={classes.activeCustom}
            isActive={(match, location) =>
              (mainItem.isStartPage && isInitialPage()) ||
              location.pathname === to
            }
          >
            <Box className={classes.linkContainer} p={2}>
              <Typography className={classes.fontWeight}>
                {mainItem[lang].title}
              </Typography>
            </Box>
          </ListItem>
        </Grid>
      )
    }
  })

  const renderNavLinks = links.map((link, i) => {
    if (link.disabled) {
      return (
        <Grid item key={i} className={isIframed && classes.btnContainerIframe}>
          <ListItem
            className={`${classes.navLinks} ${classes.disabledLink}`}
            onClick={() => handleClickOnDisabledLink(link.searchMode)}
            disabled={true}
          >
            <Box className={classes.linkContainer} p={2}>
              <Typography className={classes.fontWeight}>
                {link.title}
              </Typography>
            </Box>
          </ListItem>
        </Grid>
      )
    } else {
      const isExternal = link.to?.startsWith('//')
      let onClick = isExternal
        ? () => window.open(link.to, '_blank')
        : toggleDrawer

      return (
        <Grid
          item
          key={i}
          className={isIframed ? classes.btnContainerIframe : null}
        >
          <ListItem
            component={isExternal ? null : NavLink}
            to={link.to}
            activeClassName={classes.active}
            isActive={(match, location) =>
              isNavActive(match, location, link.to)
            }
            className={classes.navLinks}
            onClick={onClick}
            target={isExternal ? '_parent' : null}
          >
            <Box
              className={classes.linkContainer}
              p={2}
              style={isExternal ? { cursor: 'pointer' } : null}
            >
              <Typography className={classes.fontWeight}>
                {link.title}
              </Typography>
            </Box>
          </ListItem>
        </Grid>
      )
    }
  })

  const changeLocale = (locale) => {
    return i18n.changeLanguage(locale)
  }

  const hide = (string) => location.pathname.includes(string)

  if (
    (hide('outgoing') && isMobile) ||
    (hide('returning') && isMobile) ||
    (hide('cars/search') && isMobile) ||
    (hide('hotels/search') && isMobile) ||
    hide('details') ||
    hide('middlestep') ||
    hide('checkout') ||
    hide('book-result') ||
    hide('choose-seats')
  ) {
    return null
  }

  const openCartItems = () => {
    dispatch(toggleCheckoutItemsDrawer(true))
  }

  const isExtendChMobile = !!isExtendCheckout && !!isMobile

  const cmpLogoExists = companyLogoUrl && !isWidget && !isExtendChMobile
  const getHeaderLogoBlock = () => (
    <Grid item xs={isMobile && !isExtendChMobile}>
      <Box
        mr={!isExtendCheckout && !isMobile ? 4 : 0}
        className={classes.align}
      >
        <Typography
          variant="h4"
          className={classes.link}
          color="textPrimary"
          onClick={handleClick}
        >
          {!isIframe && !isWidget && (
            <CardMedia
              data-id="header-logo"
              image={logoUrl}
              className={classes.logo}
              display="inline"
            />
          )}

          {!!cmpLogoExists && (
            <>
              <Divider className={classes.logoDivider} />

              <CardMedia
                data-id="company-logo"
                image={companyLogoUrl}
                className={classes.companyLogo}
                display="inline"
                style={{}}
              />
            </>
          )}
        </Typography>
      </Box>
    </Grid>
  )

  const goToCheckout = () => {
    dispatch(disableExtendCheckout())
    history.push('/checkout')
  }

  const getTopInlineStyles = () => ({
    borderBottom: (!isMobile || isIframed) && '1px solid #e6e6e6',
    justifyContent: 'center',
    minHeight: isIframed ? '74px' : '78px',
  })

  const isBigTravel = window.location.hostname.endsWith('bigtravel.se')
  const showCurrencySelection = currencies && allowCurrencySelection

  const renderGeneralHeader = () => {
    return (
      <AppBar
        ref={measuredRef}
        elevation={0}
        color="inherit"
        position="sticky"
        classes={{ root: classes.root }}
        style={getTopInlineStyles()}
      >
        {!hideContent && <BookingLinkRow />}
        {!hideContent && <BannerRow bannerKey="top" />}
        {!hideContent && !isBigTravel && (
          <Toolbar
            variant="dense"
            className={isWidget ? classes.iframeToolbar : classes.toolbar}
          >
            <Container maxWidth="lg" disableGutters>
              <Box height="100%" width="100%">
                <Grid container id="menu-wrapper">
                  {route && isMobile && (
                    <Grid className={classes.iconBlock} item>
                      <Box height="100%" display="flex" alignItems="flex-start">
                        <IconButton onClick={() => history.goBack()}>
                          <ArrowBackIcon color="primary" />
                        </IconButton>
                      </Box>
                    </Grid>
                  )}
                  {!isIframed &&
                    (route && isMobile ? (
                      <Grid className={classes.route} xs={isMobile}>
                        <Typography className={classes.bold}>
                          {route}
                        </Typography>
                      </Grid>
                    ) : (
                      getHeaderLogoBlock()
                    ))}

                  <Fragment>
                    {!isMobile ? (
                      <NavLinksDesktop
                        changeLocale={changeLocale}
                        user={isAuthenticated ? user : null}
                        renderNavLinks={
                          hasCustomMenu && !isAuthenticated && !isIframe
                            ? renderCustomMenu
                            : null
                        }
                      />
                    ) : (
                      ''
                    )}
                    {isMobile ? (
                      <Fragment>
                        <NavLinksMobile
                          open={open}
                          changeLocale={changeLocale}
                          user={isAuthenticated ? user : null}
                          close={close}
                        />
                        {renderMobileBtn}
                      </Fragment>
                    ) : (
                      ''
                    )}
                  </Fragment>
                </Grid>
              </Box>
            </Container>
          </Toolbar>
        )}

        {!isMobile && (
          <Toolbar variant="dense" className={classes.linkToolbar}>
            <Container maxWidth="lg" disableGutters>
              <Box
                height="100%"
                width="100%"
                display="flex"
                alignItems="center"
              >
                <Grid container>{renderNavLinks}</Grid>

                {isBigTravel && showCurrencySelection && <CurrencyChooser />}
              </Box>
            </Container>
          </Toolbar>
        )}
      </AppBar>
    )
  }

  const centeringPadding = !!cmpLogoExists ? '250px' : '89px'
  const renderExtendCheckout = () => {
    return (
      <AppBar
        ref={measuredRef}
        elevation={0}
        color="inherit"
        position="sticky"
        classes={{ root: classes.root }}
        style={getTopInlineStyles()}
      >
        <Toolbar
          variant="dense"
          className={isWidget ? classes.iframeToolbar : classes.toolbar}
        >
          <Container maxWidth="lg" disableGutters>
            <Box height="100%" width="100%">
              <Grid container>
                {!isIframed && getHeaderLogoBlock()}
                <Fragment>
                  <Grid
                    item
                    xs
                    style={{
                      paddingRight: !isExtendChMobile
                        ? centeringPadding
                        : '30px',
                    }}
                    className={classes.extendCheckoutCenterHeader}
                  >
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      onClick={goToCheckout}
                      className={classes.toCheckoutBtn}
                    >
                      {!isExtendChMobile && (
                        <ArrowBackIcon
                          color="primary"
                          className={classes.checkoutBtnArrow}
                        />
                      )}
                      <span>
                        {t(!isExtendChMobile ? 'back to checkout' : 'checkout')}
                      </span>
                    </Button>
                  </Grid>

                  <Grid item>
                    <Box className={classes.cartButton} onClick={openCartItems}>
                      <ShoppingCartIcon
                        className={classes.cartIcon}
                        color="primary"
                      />
                    </Box>
                  </Grid>
                </Fragment>
              </Grid>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    )
  }

  const renderMain = () => {
    if (isExtendCheckout) return renderExtendCheckout()
    return renderGeneralHeader()
  }

  return <Fragment>{renderMain()}</Fragment>
}

export default memo(Header)
