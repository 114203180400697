import React, { memo } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cityText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dateText: {
    color: theme.palette.dimGray,
  },
}))

const HotelType = ({ model }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams()

  if (!model) return null

  return (
    <Box className={classes.root}>
      <Typography align="center" className={classes.cityText}>
        {model.Location}
      </Typography>
      <Box>
        <Typography
          variant="caption"
          align="center"
          className={classes.dateText}
        >
          <Moment format="ddd D MMM">{params.checkIn}</Moment> -{' '}
          <Moment format="ddd D MMM">{params.checkOut}</Moment>,{' '}
          {params.guests}
          {' '}
          {params.guests > 1 ? t('travelers').toLowerCase() : t('traveler').toLowerCase()},
          {' '}
          {params.roomCount} {params.roomCount > 1 ? t('rooms') : t('room')}
        </Typography>
      </Box>
    </Box>
  )
}

export default memo(HotelType)
