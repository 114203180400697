import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { fetchCityByIata } from '../../repositories/flights'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  fetchGooglePlaceDetails,
  fetchGooglePlacesAutocomplete,
} from '../../repositories/data'
import {
  setCarSearchParameters,
  setHotelSearchParameters,
} from '../../store/search/searchAction'
import { carBreadCrumbAction } from '../../store/breadcrumbs/breadcrumbsAction'
import { useHistory } from 'react-router-dom'
import { dmsToDd } from '../../utils/dmsToDd'
import { openTransferModalAction } from '../../store/modals/modalAction'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CloseIcon from '@material-ui/icons/Close'
import { fetchTrainCityByCode } from '../../repositories/trains'
import { hotelCoords } from '../../utils/flightGeneralFunctions'
import AddFlight from './addTrips/addFlight'
import AddTrain from './addTrips/addTrain'

const useStyles = makeStyles((theme) => ({
  headerIcon: {},
  btnBlock: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  card: {
    borderRadius: '12px',
  },
  tripHeader: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.border}`,
    paddingBottom: '16px',
    marginBottom: '16px',
  },
  btn: {
    borderRadius: '12px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(1, '18px'),
    width: '100%',
    height: '48px',
    letterSpacing: '0.7px',
  },
  hotelBlock: {
    borderRadius: '6px',
    backgroundColor: 'white',
    marginBottom: '14px',
  },
  hotelBlockData: {
    padding: '0px 14px',
    borderBottom: '1px solid transparent',
    position: 'relative',
  },
  hotelBlockHeader: {
    display: 'flex',
    padding: '14px 0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hotelBlockHeaderIcon: {
    padding: '0 14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  hotelBlockHeaderText: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '16px',
    textTransform: 'uppercase',
  },
  header: {
    paddingLeft: '14px',
  },
  headerText: {
    paddingRight: '8px',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
  },
  btnRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const chooseMissingTrip = ({ elements, airports, hotelBtnPresets }) => {
  const { t, i18n } = useTranslation()
  const checkout = useSelector((state) => state.checkout)
  const search = useSelector((state) => state.search)
  const history = useHistory()
  const dispatch = useDispatch()
  const { items } = checkout
  const classes = useStyles()
  const [msLoading, setMsLoading] = useState(false)
  const [isCarExpanded, setIsCarExpanded] = useState(false)
  const [isHotelExpanded, setHotelExpanded] = useState(false)
  const [isFlightExpanded, setFlightExpanded] = useState(false)
  const [isTrainExpanded, setTrainExpanded] = useState(false)
  const trainBlockRef = useRef()
  const hotelBlockRef = useRef()
  const carBlockRef = useRef()
  const flightBlockRef = useRef()

  const scrollToElement = (ref) =>
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

  useEffect(() => {
    if (isTrainExpanded) scrollToElement(trainBlockRef)
  }, [isTrainExpanded])

  useEffect(() => {
    if (isHotelExpanded) scrollToElement(hotelBlockRef)
  }, [isHotelExpanded])

  useEffect(() => {
    if (isCarExpanded) scrollToElement(carBlockRef)
  }, [isCarExpanded])

  useEffect(() => {
    if (isFlightExpanded) scrollToElement(flightBlockRef)
  }, [isFlightExpanded])

  const carSearchByFlights = useCallback(async (item) => {
    setMsLoading(true)
    try {
      const { outgoing, returning } = item
      let takeDate = moment(outgoing?.arrival)
      if (!takeDate.isValid()) throw 'invalid take date'
      takeDate = takeDate.add(30, 'minutes')

      let dropDate = null
      if (!!returning) {
        dropDate = moment(returning?.departure)
        if (!dropDate.isValid()) throw 'invalid drop date'
        dropDate = dropDate.subtract(30, 'minutes')
      } else {
        dropDate = takeDate.clone().add(1, 'day')
      }

      const iatafetch = await fetchCityByIata(
        outgoing.to.shortName,
        i18n.language
      )
      if (!iatafetch?.lat || !iatafetch?.lng) throw 'no coords'
      let carSearchData = iatafetch?.carSearchData || null

      if (
        !carSearchData &&
        !!iatafetch?.['Airport ENG'] &&
        !!iatafetch?.['City ENG']
      ) {
        const searchStr = `${iatafetch?.['Airport ENG']}, ${iatafetch?.['City ENG']}`
        const data = await fetchGooglePlacesAutocomplete(searchStr, 'en')
        if (data?.length) {
          let foundPlace = data.find((p) => {
            return (
              !!p?.place_id && !!p?.types?.length && p.types.includes('airport')
            )
          })
          if (!!foundPlace?.place_id)
            carSearchData = { placeId: foundPlace.place_id }
        }
      }

      const coords = { lat: iatafetch?.lat, lng: iatafetch?.lng }

      setCarState(carSearchData || coords, takeDate, dropDate, item)
    } catch (e) {
      console.log('failed to get city by flight iata')
      setMsLoading(false)
    }
  }, [])

  const carSearchByHotel = useCallback(async (item) => {
    setMsLoading(true)
    const { timeSpan } = item.room
    const { parsedHotel: parsed } = item.hotel
    try {
      let takeDate = moment(timeSpan?.Start + ' 10:00')
      if (!takeDate.isValid()) throw 'inavalid take date'

      let dropDate = moment(timeSpan?.End + ' 10:00')
      if (!dropDate.isValid()) throw 'inavalid drop date'

      if (!parsed?.center?.lat || !parsed?.center?.lng) throw 'inavalid coords'

      const coords = { ...parsed.center }
      setCarState(coords, takeDate, dropDate, item)
    } catch (e) {
      console.log('failed to get city by flight iata')
      setMsLoading(false)
    }
  }, [])

  const carSearchByTrains = useCallback(async (item) => {
    setMsLoading(true)
    const { to } = search.train
    try {
      const { outboundTrip, returnTrip } = item
      let takeDate = moment(
        outboundTrip?.train?.scheduleSolution?.railend?.dateTime
      )
      if (!takeDate.isValid()) throw 'inavalid take date'
      takeDate = takeDate.add(30, 'minutes')

      let dropDate = null
      if (!!returnTrip) {
        dropDate = moment(
          returnTrip?.train?.scheduleSolution?.railstart?.dateTime
        )
        if (!dropDate.isValid()) throw 'inavalid drop date'
        dropDate = dropDate.subtract(30, 'minutes')
      } else {
        dropDate = takeDate.clone().add(1, 'day')
      }

      const coords = {
        lat: dmsToDd(to?.lat || ''),
        lng: dmsToDd(to?.lng || ''),
      }
      setCarState(coords, takeDate, dropDate, item)
    } catch (e) {
      setMsLoading(false)
    }
  }, [])

  const setCarState = useCallback(
    async (coords, takeDate, dropDate, item, preparedPlace = null) => {
      try {
        // Fetch Google place by either place_id or coordinates.
        // coordinates will have a "placeId" field if "carSearchData" is set on the airport in database.
        const getCarPlace = async () => {
          if (coords.placeId) {
            const place = await fetchGooglePlaceDetails(
              coords.placeId,
              i18n.language
            )
            return {
              description: place.name,
              place_id: coords.placeId,
              coords: { ...coords, ...(place?.geometry?.location || {}) },
            }
          } else {
            const place = await fetchGooglePlacesAutocomplete(
              coords,
              i18n.language
            )

            if (!place?.description || !place?.place_id)
              throw 'no place has been found'

            return { ...place, coords }
          }
        }

        let resPlace = preparedPlace || (await getCarPlace())

        dispatch(
          setCarSearchParameters(
            resPlace,
            null,
            takeDate,
            dropDate,
            [...item.passengers],
            false
          )
        )

        const cache = Date.now() + '-' + Math.floor(Math.random() * 10000)
        const newPath = `/cars/add-middlestep?cache=${cache}`
        dispatch(carBreadCrumbAction(newPath))
        setMsLoading(false)
        history.push(newPath)
      } catch (e) {
        setMsLoading(false)
      }
    },
    []
  )

  const openTransferModal = () => dispatch(openTransferModalAction())

  const getCheckoutItem = useCallback(
    (exclude = []) => {
      let types = ['Flight', 'Train', 'Hotel', 'Car']
      if (!!exclude?.length) {
        types = types.filter((t) => !exclude?.includes(t))
      }
      let foundInd = false
      let foundItem = null

      items.forEach((itm) => {
        const tmpInd = types.findIndex((t) => itm?.type === t)
        if (tmpInd < 0) return false
        if (foundInd === false || foundInd > tmpInd) {
          foundInd = tmpInd
          foundItem = { ...itm }
        }
      })
      return foundItem
    },
    [items]
  )

  const carBtnClick = async (e) => {
    const checkoutItem = getCheckoutItem(['Car'])
    if (!checkoutItem) return false

    switch (checkoutItem?.type) {
      case 'Train':
        await carSearchByTrains(checkoutItem)
        break

      case 'Flight':
        await carSearchByFlights(checkoutItem)
        break

      case 'Hotel':
        await carSearchByHotel(checkoutItem)
        break

      default:
        console.log('unknown checkout item')
        break
    }
  }

  const renderBasicAddBtn = (txt, action, translate = true, tag) => {
    return (
      <Box mb={2}>
        <Button
          className={classes.btn}
          disabled={!!msLoading}
          disableElevation
          variant="contained"
          color="primary"
          onClick={action}
          key={tag}
        >
          {translate ? t(txt) : txt}
        </Button>
      </Box>
    )
  }

  const handleCarLocationClick = (location) => {
    const { isAirport } = location

    setCarState(null, location.takeDate, location.dropDate, location.item, {
      coords: {
        lat: location.lat,
        lng: location.lng,
        distance: isAirport ? 2 : 10,
      },
      description: location.description,
    })
  }

  const renderCarBlock = useCallback(() => {
    const checkoutItem = getCheckoutItem(['Car'])
    if (!checkoutItem) return false
    if (checkoutItem.type !== 'Flight')
      return renderBasicAddBtn('add car', carBtnClick)

    if (!airports)
      // Old behaviour for all except if we have airports
      return renderBasicAddBtn('add car', carBtnClick)

    if (!isCarExpanded)
      return renderBasicAddBtn('add car', () => setIsCarExpanded(true))

    return (
      <Box className={classes.hotelBlock} ref={carBlockRef}>
        <Box className={classes.hotelBlockHeader}>
          <Box
            className={classes.hotelBlockHeaderIcon}
            onClick={() => setIsCarExpanded(false)}
          >
            <ChevronLeftIcon />
          </Box>
          <Box className={classes.hotelBlockHeaderText}>{t('add car')}</Box>
          <Box
            className={classes.hotelBlockHeaderIcon}
            onClick={() => setIsCarExpanded(false)}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Box className={classes.hotelBlockData}>
          {airports.map((airport) => {
            const { description, isAirport } = airport
            const text = isAirport
              ? t('cars at', { location: description })
              : t('cars in', { city: description })

            return renderBasicAddBtn(text, () =>
              handleCarLocationClick(airport)
            )
          })}
        </Box>
      </Box>
    )
  }, [isCarExpanded])

  const renderHotelBlock = useCallback(() => {
    if (!hotelBtnPresets) return null

    if (!isHotelExpanded)
      return renderBasicAddBtn('add hotel', () => setHotelExpanded(true))

    return (
      <Box className={classes.hotelBlock} ref={hotelBlockRef}>
        <Box className={classes.hotelBlockHeader}>
          <Box
            className={classes.hotelBlockHeaderIcon}
            onClick={() => setHotelExpanded(false)}
          >
            <ChevronLeftIcon />
          </Box>
          <Box className={classes.hotelBlockHeaderText}>
            {t('title add hotel')}
          </Box>
          <Box
            className={classes.hotelBlockHeaderIcon}
            onClick={() => setHotelExpanded(false)}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Box className={classes.hotelBlockData}>
          {hotelBtnPresets.map((pr) => {
            const startD = moment(pr.arrival)
            const endD = pr.departure
              ? moment(pr.departure)
              : moment(pr.arrival).add(1, 'days')
            const startDay = startD.format('D')
            const startMo = startD.format('MMM')
            const endDay = endD.format('D')
            const endMo = endD.format('MMM')

            const locationText =
              pr.type == 'train'
                ? t('hotels at', { location: pr.toCity })
                : t('hotels in', { city: pr.toCity })

            const txt = `${locationText} ${startDay} ${
              startMo !== endMo ? startMo : ''
            } - ${endDay} ${endMo}`
            return renderBasicAddBtn(txt, () => addHotel(pr), false, pr.tag)
          })}
        </Box>
      </Box>
    )
  }, [isHotelExpanded])

  const addHotel = async (opts) => {
    const fetchCity = async () => {
      if (opts.type == 'train') {
        let city = await fetchTrainCityByCode(opts.to)
        city.lat = dmsToDd(city.lat)
        city.lng = dmsToDd(city.lng)
        return city
      } else {
        let city = await fetchCityByIata(opts.to)
        let coords = hotelCoords(city)
        if (coords) {
          city.lat = coords.lat
          city.lng = coords.lng
        }
        return city
      }
    }

    const dest = await fetchCity()
    const arrival = moment(opts.arrival)
    const departure = opts.departure
      ? moment(opts.departure)
      : moment(opts.arrival).add(1, 'days')
    const startD = arrival.format('YYYY-MM-DD')
    const endD = departure.format('YYYY-MM-DD')
    const pCount = opts.passengers.length
    const extraParams =
      opts.type == 'flight' && opts.tripIndex
        ? { tripInd: opts.tripIndex }
        : null

    dispatch(
      setHotelSearchParameters(
        null,
        {
          Location: dest.City,
          Country: dest['Country Code'],
          Coordinates: {
            lat: dest.lat,
            lng: dest.lng,
          },
          City: dest.City,
        },
        startD,
        endD,
        opts.passengers,
        pCount,
        pCount,
        true
      )
    )

    if (opts.type == 'train') {
      const url = `/middlestep/trains/${dest['Country Code']}/${dest.lat}/${dest.lng}/${startD}/${endD}/${pCount}/${pCount}/`
      history.push(url)
    } else {
      const url = `/middlestep/flights/${dest['Country Code']}/${dest.lat}/${dest.lng}/${startD}/${endD}/${pCount}/${pCount}/`
      history.push(url)
    }
  }

  const renderFlightBlock = useCallback(() => {
    if (!isFlightExpanded)
      return renderBasicAddBtn('add flight', () => setFlightExpanded(true))

    return (
      <Box className={classes.hotelBlock} ref={flightBlockRef}>
        <Box className={classes.hotelBlockHeader}>
          <Box
            className={classes.hotelBlockHeaderIcon}
            onClick={() => setFlightExpanded(false)}
          >
            <ChevronLeftIcon />
          </Box>
          <Box className={classes.hotelBlockHeaderText}>{t('add flight')}</Box>
          <Box
            className={classes.hotelBlockHeaderIcon}
            onClick={() => setFlightExpanded(false)}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Box className={classes.hotelBlockData}>
          <AddFlight />
        </Box>
      </Box>
    )
  }, [isFlightExpanded])

  const renderTrainBlock = useCallback(() => {
    if (!isTrainExpanded)
      return renderBasicAddBtn('add rail', () => setTrainExpanded(true))

    return (
      <Box className={classes.hotelBlock} ref={trainBlockRef}>
        <Box className={classes.hotelBlockHeader}>
          <Box
            className={classes.hotelBlockHeaderIcon}
            onClick={() => setTrainExpanded(false)}
          >
            <ChevronLeftIcon />
          </Box>
          <Box className={classes.hotelBlockHeaderText}>{t('add rail')}</Box>
          <Box
            className={classes.hotelBlockHeaderIcon}
            onClick={() => setTrainExpanded(false)}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Box className={classes.hotelBlockData}>
          <AddTrain />
        </Box>
      </Box>
    )
  }, [isTrainExpanded])

  return (
    <Box>
      <Box style={{ textAlign: 'center' }}>
        {!!msLoading && <CircularProgress fontSize={16} />}
      </Box>
      {elements?.includes('flight') && renderFlightBlock()}
      {elements?.includes('train') && renderTrainBlock()}
      {elements?.includes('car') && renderCarBlock()}
      {elements?.includes('transfer') &&
        renderBasicAddBtn('add transfers', openTransferModal)}
      {elements?.includes('hotel') && renderHotelBlock()}
    </Box>
  )
}

export default memo(chooseMissingTrip)
