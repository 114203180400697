import React, { createContext, memo, useRef, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Button,
  Box,
  AppBar,
  Toolbar,
  Grid,
  Container,
  Typography,
} from '@material-ui/core'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import AutoCompleteInput from '../../components/reusable/autoCompleteInput'
import UsersSearch from '../../components/reusable/usersSearch'
import CompaniesSearch from '../../components/reusable/companiesSearch'
import ReusableDatpicker from '../../components/reusable/datePicker/reusableDatePicker_new'
// Hotel
import AutocompleteInutHotel from '../../components/reusable/autoCompleteInputHotel'
import { ReactComponent as Arrows } from '../../assets/images/Arrows-WHT.svg'
import MuiPopover from '@material-ui/core/Popover'
import SelectCounts from '../../components/reusable/selectCounts'
import { useSelector } from 'react-redux'
import DesktopSearchNav from '../../components/search/desktopSearchNav'
import isWidget from '../../utils/isWidget'
import isIframed from '../../utils/isIframed'
import ExtendCheckoutBanner from '../header/extendCheckoutBanner'

const useStyles = makeStyles((theme) => ({
  container: {
    transform: 'translate(0px, -50%)',
  },
  containerIframed: {},
  inputContainer: {
    display: 'flex',
    position: 'relative',
  },
  changeBtn: {
    position: 'absolute',
    top: '40px',
    padding: 0,
    zIndex: 1,
  },
  changeBtnIframe: {
    position: 'absolute',
    top: '40px',
    padding: 0,
    zIndex: 1,
    color: theme.palette.iframeSecondary.main,
  },
  searchBtn: {
    width: '100%',
    fontWeight: 600,
    borderRadius: `0 8px 8px 0`,
    minHeight: '100%',
    padding: 0,
    margin: 0,
    fontSize: '16px',
    transform: 'scaleY(1.04)',
  },
  lowercase: {
    textTransform: 'capitalize',
  },
  border: {
    height: '100%',
  },
  weight: {
    fontWeight: 600,
    letterSpacing: 0.7,
  },
  addTravelerBtn: {
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'none',
  },
  arrows: {
    height: '18px',
    width: '18px',
  },

  popoverText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    fontSize: '12px',
  },

  lockIcon: {
    height: '100%',
    marginTop: '-9px',
    color: theme.palette.primary.main,
  },
}))

const Popover = withStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: '#e02020',
    boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.15)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    marginTop: theme.spacing(1.1),
    overflow: 'visible',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 0,
      height: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderBottom: '15px solid #e02020',
      borderTop: '15px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      marginTop: '-35px',
    },
  },
}))(MuiPopover)

export const RefsContext = createContext([{}, () => {}])

const SearchBar = ({
  /* */
  entityType = null,
  from,
  setFrom,
  to,
  setTo,
  /**/
  departure,
  setDeparture,
  arrival,
  setArrival,
  /* */
  travellers,
  setTravellers,
  setAgentCompany,
  switchDestinations,
  isOneWay,
  onSubmit,
  /* */
  getCities,
  optionLabel,
  array,
  renderOption,
  /**/
  HOTEL,
  setHotel,
  /**/
  setIsPopoverOpenFrom,
  isPopoverOpenFrom,
  setIsPopoverOpenTo,
  isPopoverOpenTo,
  setIsPopoverOpenTraveller,
  isPopoverOpenTraveller,
  counts,
  modifyCounts,
  /**/
  setTicketClass,
  ticketClass,
  ticketClasses,
  TRAIN,
  showSearchModes,
  setSearchMode,
  currentSearchMode,
  earliestDate,
  latestDate,
  isToLocked,
  usersDisabled = false,
  extendCheckoutType,
  disableReturn = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, authType, agentCompany } = useSelector((state) => state.auth)
  const { isIframe } = useSelector((state) => state.customization)

  const handleClose = () => {
    if (HOTEL) {
      setIsPopoverOpenTraveller(false)
      setIsPopoverOpenFrom(false)
    } else {
      setIsPopoverOpenFrom(false)
      setIsPopoverOpenTo(false)
      setIsPopoverOpenTraveller(false)
    }
  }
  const openFrom = Boolean(isPopoverOpenFrom)
  const openTo = Boolean(isPopoverOpenTo)

  const divFromRef = useRef()
  const divToRef = useRef()
  const toRef = useRef(null)
  const btnRef = useRef(null)
  // const [openDatePicker, setOpenDatePicker] = useState(false)
  const [hotelDatePicker, setHotelDatePicker] = useState({
    from: false,
    to: false,
  })
  const [openRooms, setOpenRooms] = useState()

  return (
    <Container
      className={isIframed ? classes.containerIframed : classes.container}
      style={isWidget ? { maxWidth: '100%' } : { maxWidth: '1140px' }}
      disableGutters
    >
      <Box width={'100%'}>
        {!!user && authType === 'agent' && (
          <Box overflow="hidden" mr={2} display={'inline-block'}>
            <CompaniesSearch value={agentCompany} setValue={setAgentCompany} />
          </Box>
        )}
        {!isWidget && (authType !== 'agent' || !!agentCompany?.uniqueId) && (
          <Box overflow="hidden" display={'inline-block'}>
            <UsersSearch
              disabled={usersDisabled}
              entityType={entityType}
              value={travellers}
              setValue={setTravellers}
              isPopoverOpenTraveller={isPopoverOpenTraveller}
              setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
              handleClose={handleClose}
              setTicketClass={setTicketClass}
              ticketClass={ticketClass}
              ticketClasses={ticketClasses}
            />
          </Box>
        )}
      </Box>

      {showSearchModes && isWidget && (
        <DesktopSearchNav
          currentSearchMode={currentSearchMode}
          setSearchMode={setSearchMode}
        />
      )}
      <AppBar
        color="inherit"
        position="static"
        style={
          isWidget
            ? {
                borderRadius: 8,
                borderTopLeftRadius: 0,
                boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
              }
            : {
                borderRadius: 8,
                boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
              }
        }
      >
        <Toolbar disableGutters>
          <Box p={1} width="100%">
            <Grid container>
              <Grid item xs>
                <Grid
                  container
                  style={{
                    border: '1px solid #e6e6e6',
                    borderRadius: '8px',
                    positon: 'relative',
                  }}
                >
                  {HOTEL ? (
                    <Grid item xs sm={HOTEL ? 4 : 3} ref={divFromRef}>
                      <label>
                        <Box pl={2} pt={'13px'}>
                          <Typography
                            className={classes.weight}
                            variant="caption"
                            color="textSecondary"
                          >
                            {t('destination').toUpperCase()}
                          </Typography>
                        </Box>
                        <Box pl={1}>
                          <AutocompleteInutHotel
                            value={from}
                            onChange={setHotel}
                            array={array}
                            fetch={getCities}
                            optionLabel={optionLabel}
                            label={'Destination'}
                            renderOption={renderOption}
                            hotelDatePicker={setHotelDatePicker}
                            inputStyles={{ paddingTop: '3px' }}
                          />
                        </Box>
                      </label>
                      <Popover
                        className={classes.popoverText}
                        id="fromfield"
                        open={openFrom}
                        anchorEl={divFromRef.current}
                        onClose={handleClose}
                        style={{ top: '70px' }}
                      >
                        <Typography className={classes.popoverText}>
                          {t('enter place of departure')}
                        </Typography>
                      </Popover>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={
                        (TRAIN && !isIframe) || (!TRAIN && isIframe)
                          ? 4
                          : isIframe
                          ? 3
                          : 6
                      }
                    >
                      <Grid container style={{ height: '100%' }}>
                        <Grid item xs sm={5}>
                          <Box
                            className={classes.border}
                            position="relative"
                            ref={divFromRef}
                          >
                            <label>
                              <Box pl={2} pt={'13px'}>
                                <Typography
                                  className={classes.weight}
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {t('search from').toUpperCase()}
                                </Typography>
                              </Box>

                              <Box pl={1}>
                                <AutoCompleteInput
                                  renderOption={renderOption}
                                  fetch={getCities}
                                  value={from}
                                  onChange={(_, value) => setFrom(value)}
                                  array={array}
                                  optionLabel={optionLabel}
                                  label={t('search from')}
                                  placeholder={t('search from')}
                                  toRef={toRef}
                                  desktop
                                  isFrom
                                  inputStyles={{ paddingTop: '3px' }}
                                />
                              </Box>
                            </label>
                          </Box>
                          <Popover
                            className={classes.popoverText}
                            id="fromfield"
                            open={openFrom}
                            anchorEl={divFromRef.current}
                            onClose={handleClose}
                            style={{ top: '70px' }}
                          >
                            <Typography className={classes.popoverText}>
                              {t('enter place of departure')}
                            </Typography>
                          </Popover>
                        </Grid>
                        <Grid item xs sm={1}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {!isToLocked && (
                              <IconButton
                                className={
                                  isIframe
                                    ? classes.changeBtnIframe
                                    : classes.changeBtn
                                }
                                onClick={switchDestinations}
                                color="primary"
                                tabIndex="-1"
                              >
                                <SwapHorizIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} ref={divToRef}>
                          <label>
                            <Box pl={2} pt={'13px'}>
                              <Typography
                                className={classes.weight}
                                variant="caption"
                                color="textSecondary"
                              >
                                {t('search to').toUpperCase()}
                              </Typography>
                            </Box>
                            <Box pl={1}>
                              <AutoCompleteInput
                                forwardedRef={toRef}
                                renderOption={renderOption}
                                fetch={getCities}
                                value={to}
                                onChange={(_, value) => setTo(value)}
                                array={array}
                                optionLabel={optionLabel}
                                label={t('search to')}
                                placeholder={t('search to')}
                                inputStyles={{
                                  paddingTop: '3px',
                                }}
                                desktop
                                isTo
                              />
                            </Box>
                          </label>
                          <Popover
                            id="tofield"
                            open={openTo}
                            anchorEl={divToRef.current}
                            onClose={handleClose}
                            style={{ top: '70px' }}
                          >
                            <Typography className={classes.popoverText}>
                              {t('enter destination')}
                            </Typography>
                          </Popover>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm>
                    <Grid container style={{ height: '100%' }}>
                      <Grid item xs={6} sm={TRAIN ? 3 : HOTEL ? 4 : 6}>
                        <Box
                          p={1}
                          pt={0}
                          pb={0}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            borderLeft: '1px solid #e6e6e6',
                            height: '100%',
                          }}
                        >
                          <ReusableDatpicker
                            value={departure}
                            onChange={setDeparture}
                            from={departure}
                            to={arrival}
                            isOutgoing
                            back
                            HOTEL={HOTEL}
                            btnRef={btnRef}
                            minDate={earliestDate}
                            maxDate={latestDate}
                          />
                        </Box>
                      </Grid>
                      {TRAIN && (
                        <Grid item xs={6} sm={3}>
                          <Box
                            p={1}
                            pt={0}
                            pb={0}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              borderLeft: '1px solid #e6e6e6',
                              height: '100%',
                            }}
                          >
                            <ReusableDatpicker
                              value={departure}
                              onChange={setDeparture}
                              placeHolder={t('search time')}
                              title={t('search time')}
                              from={departure}
                              to={arrival}
                              isOutgoing
                              back
                              HOTEL={false}
                              btnRef={btnRef}
                              timeOnly
                              minDate={earliestDate}
                              maxDate={latestDate}
                            />
                          </Box>
                        </Grid>
                      )}

                      {!disableReturn && (
                        <Grid item xs={6} sm={TRAIN ? 3 : HOTEL ? 4 : 6}>
                          <Box
                            p={1}
                            pb={0}
                            pt={0}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              height: '100%',
                              borderLeft: '1px solid #e6e6e6',
                            }}
                          >
                            <ReusableDatpicker
                              value={arrival}
                              onChange={setArrival}
                              isOneWay={isOneWay}
                              HOTEL={HOTEL}
                              from={departure}
                              to={arrival}
                              btnRef={btnRef}
                              hotelDatePicker={hotelDatePicker.to}
                              setHotelDatePicker={setHotelDatePicker}
                              setOpenRooms={setOpenRooms}
                              TRAIN={TRAIN}
                              minDate={earliestDate}
                              maxDate={latestDate}
                            />
                          </Box>
                        </Grid>
                      )}

                      {TRAIN && (
                        <Grid item xs={6} sm={3}>
                          <Box
                            p={1}
                            pt={0}
                            pb={0}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              borderLeft: '1px solid #e6e6e6',
                              height: '100%',
                            }}
                          >
                            <ReusableDatpicker
                              value={arrival}
                              onChange={setArrival}
                              placeHolder={t('search time')}
                              title={t('search time')}
                              from={departure}
                              to={arrival}
                              isOutgoing
                              back
                              HOTEL={false}
                              btnRef={btnRef}
                              timeOnly
                              minDate={earliestDate}
                              maxDate={latestDate}
                            />
                          </Box>
                        </Grid>
                      )}
                      {HOTEL ? (
                        <Grid item xs={12} sm>
                          <Box
                            display={'flex'}
                            borderLeft={1}
                            pt={'6px'}
                            height="100%"
                            borderColor="grey.300"
                          >
                            <SelectCounts
                              isHotel={true}
                              isOpen={openRooms}
                              setIsOpen={setOpenRooms}
                              counts={counts}
                              modifyCounts={modifyCounts}
                              menuOffset={'14px'}
                            />
                          </Box>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  {isWidget &&
                    (authType !== 'agent' || !!agentCompany?.uniqueId) && (
                      <Grid
                        sm={TRAIN || HOTEL ? 2 : 3}
                        style={{ display: 'flex' }}
                      >
                        <Box overflow="hidden" display="flex" width="100%">
                          <UsersSearch
                            disabled={usersDisabled}
                            entityType={entityType}
                            value={travellers}
                            setValue={setTravellers}
                            isPopoverOpenTraveller={isPopoverOpenTraveller}
                            setIsPopoverOpenTraveller={
                              setIsPopoverOpenTraveller
                            }
                            handleClose={handleClose}
                            setTicketClass={setTicketClass}
                            ticketClass={ticketClass}
                            ticketClasses={ticketClasses}
                            withUserModal
                          />
                        </Box>
                      </Grid>
                    )}
                  <Grid item xs={12} sm={2}>
                    <Button
                      ref={btnRef}
                      disableElevation
                      onClick={onSubmit}
                      variant="contained"
                      color="primary"
                      className={classes.searchBtn}
                      endIcon={<Arrows className={classes.arrows} />}
                    >
                      {t('search go')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      {!!extendCheckoutType && (
        <Box mt={2}>
          <ExtendCheckoutBanner type={extendCheckoutType} />
        </Box>
      )}
    </Container>
  )
}

export default memo(SearchBar)

//search go
