/**
 * Specific city city error
 * args {message, type}
 */
export default class CityCityException extends Error {
  constructor(args) {
    const { type, message, subtype } = args
    super(message)
    this.subtype = subtype || null
    this.errorType = type || 'citycity'
  }
}