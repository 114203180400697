import agent from './agent'

//import test from './mock/booking.json'
/* **GET**  costfields */

export default class CheckoutFetch {
  constructor() {
    this.agent = agent
  }

  fetchPaymentData = async (checkoutItems) => {
    let { data } = await this.agent.post(
      '/v2/payment/data',
      checkoutItems.map((i) => ({
        type: i.type,
        totalPrice: i.totalPrice,
        uniqueInd: i.uniqueInd,
      }))
    )

    return data
  }

  async fetchCCFee(flight, card, fop) {
    const { passengers, trip } = flight
    return await this.agent.post('/flights/get-inf-pricing', {
      flights: trip,
      passengers: { adult: passengers.length },
      fop: fop,
      ccInfo: !card
        ? null
        : {
            number: card.number,
            vendorCode: card.ccCode,
          },
    })
  }

  fetchCostFields = async () => {
    const url = '/costfields'
    try {
      const { data } = await this.agent.get(url)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  fetchHotelGuarantee = async (roomData) => {
    if (!roomData || typeof roomData != 'object') return null
    try {
      const { roomGuaranteeCode: guaranteeCode, roomGuarantees: guarantees } =
        roomData

      const { data } = await this.agent.post('/room-guarantee', {
        guaranteeCode,
        guarantees,
      })
      return !data?.isSaved || !data?.value?.number ? null : data
    } catch (error) {
      return null
    }
  }

  /* **POST** Flight get-inf-pricing */
  fetchGetInfPricing = async (payload) => {
    const url = '/flights/get-inf-pricing'
    try {
      const { data } = await this.agent.post(url, payload)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  /* POST Hotel Payment */
  fetchHotelPayment = async ({ guaranteeCode, guarantees }) => {
    const payload = { guaranteeCode, guarantees }

    const url = `/room-guarantee`

    try {
      const { data } = await this.agent.get(url, payload)
      return data
    } catch (e) {
      console.log(e)
    }
  }

  bookingAll = async (payload) => {
    const url = '/v2/booking'
    const { data } = await this.agent.post(url, payload)
    return data
  }

  sendToApprove = async (payload) => {
    const url = '/v2/booking/send-to-approve'
    const { data } = await this.agent.post(url, payload)
    return data
  }
}
