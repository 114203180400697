import React, { memo, useState, useEffect, useReducer } from 'react'
import {
  Box,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Grid,
  IconButton,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import ReserveSeatsHeader from '../../../../components/flights/reserveSeats/reserveSeatsHeader'
import SeatBody from './seatBody'
import { closeAllModals } from '../../../../store/modals/modalAction'
import { setPassengerSeatings } from '../../../../store/flightDetails/flightDetailsActions'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Moment from 'react-moment'
import CloseIcon from '@material-ui/icons/Close'

const initialState = []

function reducer(state, action) {
  const { payload, type } = action
  switch (type) {
    case 'init':
      return payload
    case 'deselect':
      return state.map((x) => {
        if (
          x.flightKey === payload.flightKey &&
          x.seat === payload.seat
        ) {
          x.seat = null
          x.price = null
        }
        return x
      })
    case 'select':
      return state.map((x) => {
        if (
          x.flightKey === payload.flightKey &&
          x.userId === payload.userId
        ) {
          if (x.seat === payload.seat) {
            x.seat = null
            x.price = null
            x.seatInfo = null
          } else {
            x.seat = payload.seat
            x.price = payload.price
            x.seatInfo = payload.seatInfo
          }
        }

        return x
      })
    case 'remove':
      return state.map((x) => {
        if (
          x.flightKey === payload.flightKey &&
          x.userId === payload.userId
        ) {
          x.seat = null
          x.price = null
        }
        return x
      })
    case 'remove-all-flight':
      return state.map((x) => {
        if (x.flightKey === payload.flightKey) {
          x.seat = null
          x.price = null
        }
        return x
      })
    default:
      throw new Error()
  }
}

const useStyles = makeStyles((theme) => ({
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  confirmHeader: {
    textAlign: 'center',
  },
  confirmButtons: {
    paddingBottom: '20px',
    justifyContent: 'center',
  },
  btn: {
    borderRadius: '20px',
    fontWeight: theme.typography.fontWeightBold,
    height: '40px',
    letterSpacing: 1.1,
  },
  fontWeight: {
    fontWeight: theme.typography.fontWeightBold,
  },

  fontHeaderMobile: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
  },

  btnMobile: {
    height: theme.spacing(3),
    padding: 0,
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '20px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  fixedHeaderMobile: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.15)',
  },
}))
const ModalFlightSeatReservation = ({
  seats,
  passengers,
  InitpassengerSeats,
  onSelectSeats,
  onClose
}) => {
  const [currentFlight, setCurrentFlight] = useState(0)
  const [checkModalOpen, setCheckModalOpen] = useState(false)
  const [checkModalFlight, setCheckModalFlight] = useState(-1)
  const classes = useStyles()
  const reduxDispatch = useDispatch()
  const theme = useTheme()
  const fullScreenModal = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const flight = seats[currentFlight]?.flight
  const isLast = !seats[currentFlight + 1]
  const relevantSeats = seats[currentFlight]

  // Passengers
  const [activePassengerId, setActivePassengerId] = useState('')
  const [passengerSeats, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (passengers.length) {
      setActivePassengerId(passengers[0].uniqueId)
    }
  }, [passengers.length, passengers])

  useEffect(() => {
    if (passengers.length && seats.length) {
      const array = []
      seats.forEach((seat) => {
        if (!seat.isFreeExists) {
          return
        }
        passengers.forEach((passenger) => {
          let selectedSeat = null
          if (InitpassengerSeats.length) {
            selectedSeat = InitpassengerSeats.find((passSeat) => {
              return (
                passSeat.flightKey === seat.flightKey &&
                passSeat.userId === passenger.uniqueId
              )
            })
          }

          if (!selectedSeat) {
            array.push({
              userId: passenger.uniqueId,
              seat: null,
              price: null,
              flightKey: seat.flightKey,
            })
          } else {
            array.push({ ...selectedSeat })
          }
        })
      })

      dispatch({ type: 'init', payload: array })
    }
  }, [passengers.length, seats.length, passengers, seats, InitpassengerSeats])

  // set active flight
  const setFlight = (i) => {
    setActivePassengerId(passengers[0].uniqueId)
    setCurrentFlight(i)
  }
  // set active Passenger
  const setActivePassenger = (id) => setActivePassengerId(id)

  //switch to next user in the list
  const switchToNextUser = () => {
    const currInd = _.findIndex(passengers, { uniqueId: activePassengerId })
    if (!passengers || passengers.length <= 0) return setActivePassenger('')
    if (currInd < 0 || currInd >= passengers.length - 1)
      return setActivePassenger(passengers[0].uniqueId)
    return setActivePassenger(passengers[currInd + 1].uniqueId)
  }

  // set active seat
  const selectSeat = (flight) => {
    if (!flight.seat || flight.seat.busy || flight.seat.selected) return
    const payload = {
      userId: activePassengerId,
      seat: flight.seat.seat,
      price: flight.seat.price,
      flightKey: flight.flightKey,
      seatInfo: flight.seat.seatInfo,
    }

    // If Selected, DeSelects
    const isSelected = passengerSeats.find(
      (x) =>
        x.seat === flight.seat.seat && x.flightKey === flight.flightKey
    )
    if (isSelected) {
      return dispatch({ type: 'deselect', payload: payload })
    }

    dispatch({ type: 'select', payload })
    switchToNextUser()
  }

  const removeSeat = (id) => {
    if (!id) return
    const payload = {
      userId: id,
      flightKey: flight.uniqueInd
    }
    dispatch({ type: 'remove', payload })
  }

  const allSeatsFilled = passengerSeats.every(
    (passenger) =>
      passenger.flightKey !== flight?.uniqueInd || passenger.seat
  )

  const routeAction = (ind) => {
    if (ind === -1) {
      onNextFlight()
    } else {
      setFlight(ind)
    }
  }

  const checkSelected = (flightInd = -1) => {
    const currFilledSeats = passengerSeats.filter(
      (y) => y.flightKey === flight.uniqueInd
    )
    const allCurrSeatsFilled = currFilledSeats.every(
      (passenger) => passenger.seat
    )
    const someCurrSeatsFilled = currFilledSeats.some(
      (passenger) => passenger.seat
    )

    if (!allCurrSeatsFilled && someCurrSeatsFilled) {
      setCheckModalOpen(true)
      setCheckModalFlight(flightInd)
    } else {
      routeAction(flightInd)
    }
  }

  const confirmModal = () => {
    dispatch({
      type: 'remove-all-flight',
      payload: { flightKey: flight.uniqueInd },
    })
    setCheckModalOpen(false)
    routeAction(checkModalFlight)
  }

  const onNextFlight = () => {
    const allSeatsFilled = passengerSeats.every((passenger) => passenger.seat)

    if (allSeatsFilled || isLast) {
      // Close Modal
      //Fire Dispatch to Redux
      onSelectSeats(passengerSeats.filter((passenger) => !!passenger.seat))
    }

    if (!isLast) {
      setCurrentFlight(currentFlight + 1)
      return setActivePassengerId(passengers[0].uniqueId)
    }
  }

  const closeModal = () => {
    onClose()
  }

  const allFlightsSelected = passengerSeats.every((passenger) => passenger.seat)
  return (
    <Box>
      {fullScreenModal ? (
        <Box p={1} className={classes.fixedHeaderMobile}>
          <Grid container alignItems="center">
            <Grid style={{ minWidth: '100px' }} item>
              <IconButton onClick={() => closeModal()}>
                <CloseIcon color="primary" fontSize="medium" />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography className={classes.fontHeaderMobile}>
                  {t('select seat')}
                </Typography>
              </Box>
            </Grid>
            <Grid
              style={{
                minWidth: '100px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              item
            >
              <Button
                disableElevation
                onClick={() => checkSelected()}
                className={classes.btnMobile}
                color="primary"
                variant={
                  allFlightsSelected ||
                  (allSeatsFilled && seats[currentFlight]?.isFreeExists)
                    ? 'contained'
                    : 'outlined'
                }
              >
                {allFlightsSelected
                  ? t('clear')
                  : allSeatsFilled && seats[currentFlight]?.isFreeExists
                  ? t('next flight')
                  : t('seats modal skip mobile')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className={classes.spaceBetween} p={3}>
          <Typography className={classes.fontWeight} variant="h4">
            {t('seats modal choice')}
          </Typography>
          <Button
            disableElevation
            onClick={() => checkSelected()}
            className={classes.btn}
            color="primary"
            variant={
              allFlightsSelected ||
              (allSeatsFilled && seats[currentFlight]?.isFreeExists)
                ? 'contained'
                : 'outlined'
            }
          >
            {allFlightsSelected
              ? t('clear')
              : allSeatsFilled && seats[currentFlight]?.isFreeExists
              ? t('next flight')
              : t('seats modal skip')}
          </Button>
        </Box>
      )}

      <Box pt={3} pb={3}>
        <ReserveSeatsHeader
          passengerSeats={passengerSeats}
          seats={seats}
          currentFlight={currentFlight}
          setFlight={checkSelected}
          current={flight}
        />
      </Box>
      <Divider />
      <Box p={3} className={classes.center}>
        <Typography className={classes.fontWeight}>
          {flight && flight.fromCity} ({flight && flight.from}) -{' '}
          {flight && flight.toCity} ({flight && flight.to})
        </Typography>
        <Typography>
          <Moment format="ddd D MMM YYYY HH:mm">
            {flight && flight.departure}
          </Moment>
        </Typography>
      </Box>
      <Divider />
      <Box pt={4}>
        <SeatBody
          flight={relevantSeats}
          passengers={passengers}
          activePassengerId={activePassengerId}
          setActivePassenger={setActivePassenger}
          selectSeat={selectSeat}
          passengerSeats={passengerSeats}
          removeSeat={removeSeat}
          activeFlight={flight}
        />
      </Box>

      <Dialog
        fullScreen={fullScreenModal}
        open={checkModalOpen}
        onClose={() => setCheckModalOpen(false)}
      >
        <DialogTitle className={classes.confirmHeader}>
          {t('are you sure')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('seats confirm text')}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.confirmButtons} pb={2}>
          <Button
            autoFocus
            onClick={() => setCheckModalOpen(false)}
            color="primary"
            variant="outlined"
          >
            {t('back to select seats')}
          </Button>
          <Button
            onClick={confirmModal}
            color="primary"
            autoFocus
            variant="contained"
          >
            {t('yes, skip to next')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default memo(ModalFlightSeatReservation)
