import { Box, IconButton, Typography } from '@material-ui/core'
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  mutedText: {
    fontSize: '14px',
    color: theme.palette.common.black,
    lineHeight: 'normal'
  },
  row: {
    marginBottom: '5px',
    '&:last-child': {
      marginBottom: '0',
    }
  },
  incrBtn: {
    '& .MuiIconButton-label': {
      borderRadius: '24px',
      border: '1px solid ' + theme.palette.primary.main,
    },

    '&.btn-disabled .MuiIconButton-label': {
      border: '1px solid #b6bcbb',
    }
  },
  align: { display: 'flex', alignItems: 'center' },
  medium: {
    fontWeight: 600,
  },
}))

const CountRow = ({countKey, title, count, muted, modifyCounts}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const minimals = {rooms: 1}
  const minimum = minimals?.[countKey] || 0

  return (
    <Box pl={2} pr={2} className={`${classes.align} ${classes.row}`}>
      <Box flex={1}>
        <Typography className={classes.medium}>{t(title, {count: count})}</Typography>
        {muted && <span className={classes.mutedText}>{muted}</span>}
      </Box>

      <Box className={classes.align}>
        <IconButton
          className={`${classes.incrBtn} ${count <= minimum ? 'btn-disabled' : ''}`}
          onClick={() => {
            if (count > minimum) modifyCounts(countKey, 'decrement')
          }}
          color="primary"
        >
          <RemoveIcon color={count <= minimum  ? 'disabled' : 'primary'} />
        </IconButton>
        <Typography style={{minWidth: '40px', textAlign: 'center'}} className={classes.medium}>{count}</Typography>
        <IconButton
          className={classes.incrBtn}
          onClick={() => modifyCounts(countKey, 'increment')}
        >
          <AddIcon color="primary" />
        </IconButton>
      </Box>
    </Box>
  )
}

export default memo(CountRow)
