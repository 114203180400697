import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Dibs = window.Dibs

const useNetsPaymentHook = ({ paymentId, checkoutKey, elementId }) => {
  const [checkout, setCheckout] = useState(null)
  const [isCompleted, setIsCompleted] = useState(false)
  const { transferModal } = useSelector((state) => state.modal)

  //  console.log(paymentId)

  useEffect(() => {
    if (!paymentId || !elementId) return

    let options = {
      checkoutKey,
      paymentId,
      containerId: elementId,
    }

    let checkoutObject = new Dibs.Checkout(options)
    checkoutObject.on('payment-completed', () => {
      setIsCompleted(true)
    })

    console.log('INIT CHECKOUT with payment ' + paymentId)

    setCheckout(checkoutObject)
  }, [paymentId, elementId, checkoutKey])

  useEffect(() => {
    if (!checkout) return

    if (transferModal) {
      checkout.freezeCheckout()
    } else {
      checkout.thawCheckout()
    }
  }, [checkout, transferModal])

  return { checkout, isCompleted }
}

export default useNetsPaymentHook
