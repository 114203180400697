import React, { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import capFirstLetter from '../../../../../utils/capitalizeFirstLetter'
import Button from '@material-ui/core/Button'
import { MiddlestepDrawerContext } from '../../../../../pages/middlestep/middlestep'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  align: { display: 'flex', alignItems: 'center' },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  editBtn: {
    background: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightSemiBold,
    color: 'white',
    height: '24px',
    padding: 0,
    marginLeft: '4px',
    textTransform: 'none',
    fontSize: '14px',
  },
}))

const TrainType = () => {
  const { t } = useTranslation()
  const params = useParams()
  const outgoing = useSelector((state) => state.train.selectedOutGoingTrip)
  const returning = useSelector((state) => state.train.selectedReturnTrip)
  const classes = useStyles()
  const { toggleRightDrawer } = useContext(MiddlestepDrawerContext)

  const destination = outgoing.train.scheduleSolution.railend?.locationName

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box className={classes.align}>
        <Typography className={classes.bold}>
          {capFirstLetter(destination)}
        </Typography>
        <Button className={classes.editBtn} onClick={toggleRightDrawer}>
          {t('edit btn')}
        </Button>
      </Box>
      <Typography variant="caption" color="textSecondary">
        <Moment format="ddd D MMM">{params.checkIn}</Moment>
        {returning ? ' - ' : ''}
        {returning ? <Moment format="ddd D MMM">{params.checkOut}</Moment> : ''}
        , {params.guests}{' '}
        {params.guests > 1
          ? t('travelers').toLowerCase()
          : t('traveler').toLowerCase()}
        , {params.roomCount} {params.roomCount > 1 ? t('rooms') : t('room')}
      </Typography>
    </Box>
  )
}

const FlightType = () => {
  const { t } = useTranslation()
  const params = useParams()
  const classes = useStyles()
  const { outgoing, returning } = useSelector((state) => state.flights)
  const { toggleRightDrawer } = useContext(MiddlestepDrawerContext)

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box className={classes.align}>
        <Typography className={classes.bold}>
          {outgoing ? outgoing.to.city : ''}
        </Typography>
        <Button className={classes.editBtn} onClick={toggleRightDrawer}>
          {t('edit btn')}
        </Button>
      </Box>
      <Typography variant="caption" color="textSecondary">
        <Moment format="ddd D MMM">{params.checkIn}</Moment>
        {returning ? ' - ' : ''}
        {returning ? <Moment format="ddd D MMM">{params.checkOut}</Moment> : ''}
        , {params.guests}{' '}
        {params.guests > 1
          ? t('travelers').toLowerCase()
          : t('traveler').toLowerCase()}
        , {params.roomCount} {params.roomCount > 1 ? t('rooms') : t('room')}
      </Typography>
    </Box>
  )
}

const MiddleStepType = ({ pathname }) => {
  if (pathname.includes('flight')) {
    return <FlightType />
  }
  if (pathname.includes('train')) {
    return <TrainType />
  }
  return <p>i</p>
}

export default memo(MiddleStepType)
