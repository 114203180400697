import React, { useContext, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useCheckBoxFilter from '../../../hooks/useCheckBoxFilterHook'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { setHotelGuestRatingFilter } from '../../../store/hotels/hotelsAction'
import Box from '@material-ui/core/Box'
import { HotelFilterContext } from './hotelFilterDrawer'

const guestRatings = (t) => ([
  { name: t('guest rating1'), slug: 'excellent' },
  { name: t('guest rating2'), slug: 'veryGood' },
  { name: t('guest rating3'), slug: 'good' },
  { name: t('guest rating4'), slug: 'fair' },
  { name: t('guest rating5'), slug: 'no' },
])

const GuestRating = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const reset = useContext(HotelFilterContext)
  const rating = useSelector((state) => state.hotels.filters.rating)
  const { filters, onChange } = useCheckBoxFilter(rating, reset)

  useEffect(() => {
    dispatch(setHotelGuestRatingFilter(filters))
  }, [filters, dispatch])

  return (
    <>
      <Typography gutterBottom>
        {t('hotel results guest rating')}
      </Typography>
      <Box display="flex" flexDirection="column">
        {
          guestRatings(t).map((rating) => (
            <FormControlLabel
              key={rating.slug}
              control={
                <Checkbox
                  defaultValue={false}
                  checked={filters[rating.slug]}
                  onChange={onChange}
                  name={rating.slug}
                  color="primary"
                />
              }
              label={rating.name}
            />
          ))
        }
      </Box>
    </>
  )
}

export default GuestRating
