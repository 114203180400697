import React, { memo } from 'react'
import Chip from '@material-ui/core/Chip'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { getFFBags } from '../../../utils/flightGeneralFunctions'

export const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(2),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },

  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    backgroundColor: `${theme.palette.lightGreen} `,
    borderRadius: theme.spacing(1 / 2),
    '& span': {
      fontSize: '14px',
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
  },
}))

const SelectBagageItem = ({
  outgoing,
  returning,
  fareFamily,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const exe = getFFBags(fareFamily, outgoing, returning)
  const getLabels = (bagsList) => {
    if (!bagsList) return null

    return bagsList.map((bagage, i) => (
      <Box key={i} className={classes.align} mt={2}>
        <Chip label={t('included')} className={classes.label} />
        <Typography>{t(bagage)}</Typography>
      </Box>
    ))
  }

  const renderCombinedBags = (bagsList) => {
    const results = []

    if (bagsList?.outbound?.length > 0) {
      results.push(
        <Box pt={2} pl={0}>
          <Typography className={classes.weight}>
            {t('outbound trip')}
          </Typography>
          <Box>{getLabels(bagsList?.outbound)}</Box>
        </Box>
      )
    }

    if (bagsList?.return?.length > 0) {
      results.push(
        <Box pt={2} pl={0}>
          <Typography className={classes.weight}>
            {t('filter return trip')}
          </Typography>
          <Box>{getLabels(bagsList?.return)}</Box>
        </Box>
      )
    }

    return results
  }
  const renderChips = () => {
    return exe?.outbound?.length > 0
      ? renderCombinedBags(exe)
      : getLabels(exe?.general)
  }

  return (
    <Box>{renderChips()}</Box>
  )
}

export default memo(SelectBagageItem)
