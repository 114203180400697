import React, { memo, useState } from 'react'
import { Paper, Box, Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import { useHistory, useLocation } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Handler from './handler/handler'
import NavLinksMobile from '../navLinksMobile'
import { useDispatch, useSelector } from 'react-redux'
import BookingLinkRow from '../bookingLinkRow'
import isIframed from '../../../utils/isIframed'
import { toggleCheckoutItemsDrawer } from '../../../store/modals/modalAction'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

const useStyles = makeStyles((theme) => ({
  btn: {},

  fixedHeader: {
    borderRadius: 0,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
    zIndex: 100,
  },
}))

const MobileHeader = ({ city, tripPrice = 0 }) => {
  const history = useHistory()
  const classes = useStyles()
  const { pathname } = useLocation()
  const showMap = useSelector((store) => store.hotels.showMap)
  const dispatch = useDispatch()
  const location = useLocation()

  const [open, setOpen] = useState(false)

  const toggleDrawer = () => {
    setOpen((prevState) => !prevState)
  }

  const close = () => setOpen(false)

  const isExtendCheckout = useSelector((state) => state.search)

  const showCartButton =
    isExtendCheckout &&
    [
      '/flights',
      '/flights/outgoing',
      '/flights/returning',
      '/flights/details',
      '/trains',
      '/trains/outgoing',
      '/trains/returning',
      '/trains/choose-seats',
    ].find((path) => location.pathname?.startsWith(path))

  const openCartItems = () => {
    dispatch(toggleCheckoutItemsDrawer(true))
  }

  // eslint-disable-next-line
  return (
    (pathname.includes('/middlestep') && showMap) || (
      <Paper elevation={0} square className={classes.fixedHeader}>
        <BookingLinkRow tripPrice={tripPrice || 0} />
        <Box p={1}>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                className={classes.btn}
                onClick={() => history.goBack()}
              >
                <ArrowBackIcon color="primary" fontSize="medium" />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Handler model={city} />
              </Box>
            </Grid>

            {showCartButton && !isIframed && (
              <Grid item>
                <IconButton onClick={openCartItems} className={classes.btn}>
                  <ShoppingCartIcon color="primary" fontSize="medium" />
                </IconButton>
              </Grid>
            )}

            {!isIframed && !showCartButton && (
              <Grid item>
                <IconButton onClick={toggleDrawer} className={classes.btn}>
                  <MenuIcon color="primary" fontSize="medium" />
                </IconButton>
                <NavLinksMobile open={open} close={close} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    )
  )
}

export default memo(MobileHeader)
