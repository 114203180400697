import React from 'react'
import {makeStyles, useTheme} from "@material-ui/core/styles";
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import {Box, Typography} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  icon: {
    fontSize: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center'
  },
  text: {
    fontSize: theme.spacing(2),
    textAlign: 'center'

  }
}))

const NoData = ({ title, message }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box maxWidth={theme.spacing(45)} display="flex" className={classes.wrapper} mt={isDesktop ? 6 : 9}>
      <LoyaltyIcon className={classes.icon} color="primary"/>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.text}>
        {message}
      </Typography>
    </Box>
  )
}

export default NoData;
