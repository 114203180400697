/**
 *
 * @param schedules
 * @returns {{firstTime: Date, latestTime: Date}}
 */
export const getFirstAndLastTime = (schedules) => {
  let latestTime = schedules
    ? new Date(
      Math.max(
        ...schedules?.map((s) => {
          const scheduleSolution = s?.scheduleSolution || s?.schedule?.scheduleSolution
          return new Date(scheduleSolution.railstart.dateTime).getTime()
        })
      )
    )
    : null
  let firstTime = schedules
    ? new Date(
      Math.min(
        ...schedules?.map((s) => {
            const scheduleSolution = s?.scheduleSolution || s?.schedule?.scheduleSolution
            return new Date(scheduleSolution.railstart.dateTime).getTime()
        })
      )
    )
    : null

  return { firstTime, latestTime }
}