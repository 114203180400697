import React, {memo, useMemo} from 'react'
import Moment from 'react-moment'
import moment from 'moment'
import { Box, Typography, Button, IconButton, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { useTranslation } from 'react-i18next'
import capitalizeOnlyFirstLetter from '../../../../utils/capitalizeOnlyFirstLetter'
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter'
import { useSelector } from 'react-redux'
import SelectTransferPassenger from "../../selectTransferPassenger";

const useStyles = makeStyles((theme) => ({
  outerBlock: {
    display: 'inline-flex',
    minWidth: 0,
    flexWrap: 'wrap'
  },

  pageItem: {
    display: 'inline-flex',
    lineHeight: 'normal',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2px',
    height: '20px',
    width: '20px',
    marginRight: '6px',
    cursor: 'pointer',

    '&:last-child': {
      marginRight: '0'
    }
  },

  addNewBtn: {
    borderRadius: '6px',
    border: `1px solid ${theme.palette.border}`
  },

  activePage: {
    borderRadius: '40px',
    border: `1px solid ${theme.palette.border}`
  },

  hasError: {
    color: '#f44336',
    borderColor: '#f44336'
  }
}))

const Pagination = ({
  currentPage,
  totalItems,
  onChangePage,
  onAddNew = null,
  perPage = 1,
  pagesNear = 7,
  errors = null
}) => {
  const classes = useStyles()
  const pages = [];
  const totalPages = Math.ceil(totalItems/perPage);
  const minShow = (currentPage - pagesNear) < 1 ? 1 : currentPage - perPage;
  const maxShow = (currentPage + pagesNear) > totalPages ? totalPages : currentPage + pagesNear;

  for (let i = minShow; i <= maxShow; i++) {
    const hasError = (errors || []).includes(i)
    pages.push(
      <Box
        key={`page-number-${i}`}
        className={`${classes.pageItem} ${i === currentPage ? classes.activePage : ''} ${hasError ? classes.hasError : ''}`}
        onClick={() => onChangePage(i)}
      >
        {i}
      </Box>
    )
  }

  if (typeof onAddNew === 'function') {
    pages.push(
      <Box key={`add-new-btn`} className={`${classes.pageItem} ${classes.addNewBtn}`} onClick={onAddNew}>+</Box>
    )
  }

  return <Box className={classes.outerBlock}>{pages}</Box>

}

export default memo(Pagination)
