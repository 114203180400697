import constants from './constants'

const initialState = {
  pages: null,
  menu: null,
  css: null,
  isLoading: true,
  landingPage: null,
  headline: null,
  searchBackgroundColor: null,
  backgroundImage: null,
  backgroundColor: null,
  activeSearchType: null,
  title: null,
  favicon: null,
  showSupportPhone: false,
  isIframe: false,
  searchByEvents: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_SEARCH_BY_EVENTS:
      return {
        ...state,
        searchByEvents: action.payload,
      }

    case constants.SET_LANDINGPAGE:
      return {
        ...state,
        landingPage: action.payload,
      }

    case constants.SET_ACTIVE_SEARCH_TYPE:
      return {
        ...state,
        activeSearchType: action.payload,
      }

    case constants.SET_PAGES:
      return {
        ...state,
        pages: action.payload,
      }

    case constants.SET_MENU:
      return {
        ...state,
        menu: action.payload,
      }

    case constants.SET_SETTINGS:
      let {
        backgroundColor,
        backgroundImage,
        headline,
        css,
        searchBackgroundColor,
        title,
        favicon,
        showSupportPhone,
      } = action.payload
      return {
        ...state,
        backgroundImage,
        backgroundColor,
        css,
        headline,
        searchBackgroundColor,
        title,
        favicon,
        showSupportPhone,
      }

    case constants.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case constants.SET_IS_IFRAME:
      return {
        ...state,
        isIframe: action.payload,
      }

    default:
      return state
  }
}
