import constants from './constants'

const initialState = {
  items: [],
  ccFees: {},
  sum: 0,
  costfields: null,
  costFieldValues: {},
  fop: null,
  hotelGuarantee: null,
  guaranteeLoading: false,
  costfieldsLoading: false,
  bookingResult: null,
  publicContacts: { phone: '', email: '' },
  creditCard: {
    number: null,
    expire: null,
    cvv: null,
    ccCode: null,
  },
  netsPaymentId: null,
  netsCheckoutKey: null,
  netsCompleted: false,
  paiwiseCheckoutId: null,
  paiwiseRedirectUrl: null,
  addressDetails: {},
  passengerData: {},
  passengerDataLoading: false,
  checksum: null,
  additionalRecipientEmail: null,
  policyExceededReason: null,
  dlk: null,
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.SET_PAYMENT_DATA: {
      return {
        ...state,
        netsPaymentId: payload.nets?.paymentId,
        netsCheckoutKey: payload.nets?.checkoutKey,
        paiwiseCheckoutId: payload.paiwise?.checkout_id,
        paiwiseRedirectUrl: payload.paiwise?.redirectUrl,
      }
    }

    case constants.SET_NETS_COMPLETED: {
      return {
        ...state,
        netsCompleted: payload,
      }
    }

    case constants.SET_CHECKOUT_ITEMS: {
      if (payload.length <= 0) return { ...state }
      const addItemSum = payload
        .map((item) => item?.totalPrice || 0)
        .reduce((a, b) => a + b, 0)
      return {
        ...state,
        items: payload,
        sum: addItemSum,
      }
    }

    case constants.SET_CHECKOUT_CHECKSUM: {
      return {
        ...state,
        checksum: payload,
      }
    }

    case constants.ADD_ITEM:
      const newItems = state.items

      const index = newItems.findIndex((item) => item.type === payload.type)

      if (index > -1) {
        const uniqueInd = 'CHECKOUT_ITM_' + (index + 1)
        newItems[index] = { ...payload, uniqueInd }
      } else {
        // The above logic seems wrong to me. It will generate non-unique indexes in case of other items being added after the first instance of a given type.
        const uniqueInd = 'CHECKOUT_ITM_' + (newItems.length + 1)
        newItems.push({ ...payload, uniqueInd })
     }
      const addItemSum = newItems
        ? newItems.map((item) => item.totalPrice).reduce((a, b) => a + b, 0)
        : 0

      return {
        ...state,
        items: newItems,
        sum: addItemSum,
      }

    case constants.CORRECT_HOTEL_PRICE: {
      const { prices } = payload
      const newItems = state.items.map((i) => {
        const res = { ...i }
        if (i.type !== 'Hotel') return res

        const { room } = i
        const price = prices?.[room.bookingCode]
        if (price) res['totalPrice'] = price
        return res
      })

      return {
        ...state,
        items: newItems,
      }
    }
    case constants.REMOVE_ITEM:
      const filterOut = state.items.filter((_item, i) => i !== payload)
      const sum = filterOut
        ? filterOut.map((item) => item.totalPrice).reduce((a, b) => a + b, 0)
        : 0
      return {
        ...state,
        items: filterOut,
        sum: sum,
      }

    case constants.SET_COSTFIELDS: {
      const { costfields, defaultVals } = payload
      const currCFVal = JSON.parse(JSON.stringify(state.costFieldValues || {})) //will be filled with new values
      const newCFVal = defaultVals || {}
      for (let uid in newCFVal) {
        if (!newCFVal[uid] || typeof newCFVal[uid] !== 'object') continue
        if (!currCFVal[uid] || typeof currCFVal[uid] !== 'object')
          currCFVal[uid] = newCFVal[uid]
        currCFVal[uid] = { ...currCFVal[uid], ...newCFVal[uid] }
      }

      return {
        ...state,
        costfields: costfields,
        costFieldValues: currCFVal,
      }
    }

    case constants.SET_PUBLIC_CONTACTS: {
      const { email, phone } = payload
      const res = {}
      if (typeof email !== 'undefined') res.email = email
      if (typeof phone !== 'undefined') res.phone = phone
      const currentContacts = state.publicContacts || {
        ...initialState.publicContacts,
      }

      return {
        ...state,
        publicContacts: { ...currentContacts, ...res },
      }
    }
    case constants.SET_ADDRESS_DETAILS: {
      return {
        ...state,
        addressDetails: payload,
      }
    }

    case constants.SET_CC_FEES:
      return {
        ...state,
        ccFees: payload && typeof payload == 'object' ? payload : {},
      }
    case constants.SET_HOTEL_GUARANTEE:
      return {
        ...state,
        hotelGuarantee: payload,
        guaranteeLoading: false,
        fop: !!payload ? 'profileCC' : state.fop,
      }
    case constants.HOTEL_GUARANTEE_LOADING:
      return {
        ...state,
        guaranteeLoading: true,
      }
    case constants.SET_BOOKING_RESULT:
      return {
        ...state,
        bookingResult: payload || null,
      }
    case constants.SET_FOP:
      return {
        ...state,
        fop: payload && payload !== '' ? payload : null,
        creditCard: { number: null, expire: null, cvv: null, ccCode: null },
      }
    case constants.SET_CC_DATA:
      const { number, expire, cvv, ccCode } = payload

      return {
        ...state,
        creditCard: {
          number: number || null,
          expire: expire || null,
          cvv: cvv || null,
          ccCode: ccCode || null,
        },
      }
    case constants.COSTFIELDS_LOADING: {
      return {
        ...state,
        costfieldsLoading: !!payload,
      }
    }

    case constants.SET_COSTFIELDS_VALUE: {
      const { type, value, uid = 'general' } = payload
      if (!type) return state
      const resVal = { ...state.costFieldValues }
      if (!resVal?.[uid]) resVal[uid] = {}
      resVal[uid][type] = value
      return {
        ...state,
        costFieldValues: resVal,
      }
    }

    case constants.REMOVE_ALL_CHECKOUT:
      return {
        ...state,
        items: [],
        sum: 0,
        hotelGuarantee: null,
        guaranteeLoading: false,
        costfieldsLoading: false,
        bookingResult: null,
        ccFees: {},
        costFieldValues: {},
        creditCard: {
          number: null,
          expire: null,
          cvv: null,
          ccCode: null,
        },
        publicContacts: { phone: '', email: '' },
        fop: 'profileCC',
        netsPaymentId: null,
        netsCheckoutKey: null,
        netsCompleted: false,
        paiwiseCheckoutId: null,
        paiwiseRedirectUrl: null,
        checksum: null,
        additionalRecipientEmail: null,
        policyExceededReason: null,
        dlk: {},
      }

    case constants.SET_PASSENGERS_DATA:
      return {
        ...state,
        passengerData: payload,
      }
    case constants.SET_PASSENGERS_DATA_LOADING:
      return {
        ...state,
        passengerDataLoading: payload,
      }

    case constants.SET_ADDITIONAL_RECIPIENT_EMAIL:
      return {
        ...state,
        additionalRecipientEmail: payload,
      }

    case constants.SET_POLICY_EXCEEDED_REASON:
      return {
        ...state,
        policyExceededReason: payload,
      }

    case constants.SET_DLK:
      return {
        ...state,
        dlk: payload,
      }

    default:
      return state
  }
}
