import React, {Fragment, memo, useState} from 'react'
import { Box, Typography, Button, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { closeAllModals } from '../../../store/modals/modalAction'
import { getPhoneNumberFormat, validatePhoneNumber } from '../../../utils/general'

const useStyles = makeStyles((theme) => ({
  between: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  btn: {
    fontWeight: theme.typography.fontWeightSemiBold,
    minWidth: '130px',
    padding: '8px 16px',
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
    },
  },
  fieldRow: {
    marginBottom: theme.spacing(4),

    '&:last-child': {
      marginBottom: 0
    },
  },
  infoBox: {
    padding: '16px',
    borderRadius: '6px',
    backgroundColor: '#EEF8F8',
  },
}))

const AddRequiredInformationStep = ({ users, setPhones, onClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [mobiles, setMobiles] = useState({})
  const [errors, setErrors] = useState({})
  const { countryPhone } = useSelector((state) => state.auth)
  const phoneNumberFormat = getPhoneNumberFormat(countryPhone?.code)

  const cancel = () => {
    if (typeof(onClose) === 'function') {
      onClose()
    } else {
      dispatch(closeAllModals())
    }
  }

  const handleInputChange = (e) => {
    const { value, name } = e.target
    const res = {...mobiles, [name]: value}
    setMobiles(res)
  }

  const saveNewMobile = () => {
    const resErrors = {}
    let hasErrors = false

    for ( let uid in mobiles ) {
      const mobile = mobiles?.[uid] || ''

      if (!validatePhoneNumber(mobile, countryPhone?.code)) {
        resErrors[uid] = 'invalid phone number'
        hasErrors = true
      }
    }

    if (!hasErrors) {
      setPhones(mobiles)
    } else {
      setErrors(resErrors)
    }
  }

  return (
    <Box display="flex" flexDirection="column" p={6}>
      <Typography
        align="center"
        variant="h4"
        className={classes.weight}
        style={{ marginBottom: '16px' }}
      >
        {t('missing phone')}
      </Typography>

      <Box className={classes.infoBox} mb={4}>
        <Typography align="center">
          {t('mobile required for transfer')}
        </Typography>
      </Box>

      {users.map(user => {
        const uid = user.uniqueId
        return (
          <Fragment>
            <Box className={classes.fieldRow}>
              <Box mb={2}>
                <Typography align="left">
                  {t('contact phone1')} ({user.firstName} {user.lastName})
                </Typography>
              </Box>

              <TextField
                mb={2}
                fullWidth
                autoFocus
                margin="none"
                placeholder={phoneNumberFormat}
                variant="outlined"
                name={uid}
                error={!!errors?.[uid]}
                value={mobiles?.[uid] || ''}
                onChange={handleInputChange}
                helperText={!!errors?.[uid] ? (errors?.[uid] === 'invalid phone number' ? t(errors[uid], {phoneNumberFormat}) : t(errors[uid])) : false}
                inputProps={{
                  autoComplete: 'off',
                  spellCheck: 'false',
                  autoCorrect: 'off',
                }}
              />
            </Box>
          </Fragment>
        )
      })}

      <Box mt={4} flex={1} display="flex" justifyContent="center">
        <Box mr={1}>
          <Button
            className={classes.btn}
            style={{ border: '1px solid #08c5b9' }}
            color="primary"
            variant="outlined"
            onClick={() => cancel()}
          >
            <Typography
              className={classes.weight}
              style={{ marginRight: '4px' }}
            >
              {t('cancel')}
            </Typography>
          </Button>
        </Box>

        <Box ml={1}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            style={{
              letterSpacing: '1.1px',
              fontWeight: theme.typography.fontWeightBold,
            }}
            onClick={() => saveNewMobile()}
          >
            <Typography
              className={classes.weight}
              style={{ marginRight: '4px' }}
            >
              {t('add')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(AddRequiredInformationStep)
