import {fetchAllUsers} from '../../repositories/users'
import constants from './constants'
import {isLoading} from "../auth/authAction";
import {fetchSwitchAdmin} from "../../repositories/admin";

export const switchAdminAction = (id, isAdmin) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    return await fetchSwitchAdmin(id, isAdmin)
  } catch (e) {
    throw e
  } finally {
    dispatch(isLoading(false))
  }
}

export const allUsersAction = (showLoading = true) => async (dispatch, getState) => {

  if (showLoading) dispatch(isLoading(true))
  try {
    dispatch(setUsers([]))
    const users = await fetchAllUsers()
    dispatch(setUsers(users))
  } catch (e) {
    throw e
  } finally {
    dispatch(isLoading(false))
  }
}

export const setUsers = (users) => ({
  type: constants.FETCH_USERS,
  payload: users,
})
