import { useEffect, useState } from "react";

const useOneWay = (arrival, isOneWay) => {
  const [isOneWayDest, setIsOneWay] = useState(false)
  const toggleTravelType = () => setIsOneWay((prev) => !prev)

  useEffect(() => {
    if (isOneWay) {
      setIsOneWay(isOneWay === '0')
    }
  }, [isOneWay])

  useEffect(() => {
    if (!arrival) {
      setIsOneWay(true)
    } else {
      setIsOneWay(false)
    }
  }, [arrival, setIsOneWay])

  return { isOneWayDest, setIsOneWay, toggleTravelType }
}

export default useOneWay
