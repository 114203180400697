import sortFlights from './sortFlights'
import setMinPriceLabels from './setMinPriceLabels'
import filterFlights from './filterFlights'
import getDuration from './getDuration'
import moment from 'moment'

const formatDuration = (duration) => {
  if (!duration || duration === 0) {
    return ''
  }

  let dur = ''
  //let zero = ''
  const h = Math.floor(duration / 60)
  if (h > 0 && duration >= 60) {
    dur = `${h}h `
  }

  const m = duration % 60
  if (m > 0) {
    dur += `${m}m`
  }
  return dur
}

const getVias = (flights) => {
  let vias = []

  flights.forEach((t) => {
    vias.push({
      from: t.fromAirport ? t.fromAirport : t.from,
      fromShort: t.from,
      fromTerminal: t.fromTerminal,
      to: t.toAirport ? t.toAirport : t.to,
      toShort: t.to,
      toCity: t.toCity,
      fromCity: t.fromCity,
      toTerminal: t.toTerminal,
      company: t.company || t.airline,
      flightnumber: t.flightnumber,
      operatingCarrier: t.operatingCarrier,
      arrival: t.arrival,
      departure: t.departure,
      departTime: moment(t.departure).format('HH:mm'),
      arriveTime: moment(t.arrival).format('HH:mm'),
      duration: getDuration(t.from, t.to, t.departure, t.arrival),
      equipmentType: t.equipmentType,
      isTrain: ['TRS', 'TRN'].includes(t.equipmentType),
    })
  })
  return vias
}

export default function parseFlightsData(searchRes, filters, dir) {
  let filteredIncomingData = !!searchRes?.length ? [...searchRes] : []

  filteredIncomingData = filteredIncomingData.map((fl) => {
    let arrives = moment(fl.arrival)
    let departs = moment(fl.departure)
    const flights =
      dir === 'outbound'
        ? fl?.original?.trip || []
        : fl?.original?.returnTrip || []

    return {
      ...fl,
      date: departs.format('YYYY-MM-DD'),
      departTime: departs.format('HH:mm'),
      arriveTime: arrives.format('HH:mm'),
      formattedTravelTime: formatDuration(fl.travelTime),
      via: getVias(flights),
    }
  })

  if (filters !== null) {
    filteredIncomingData = sortFlights(filteredIncomingData, filters)
  }
  filteredIncomingData = setMinPriceLabels(filteredIncomingData)
  if (filters !== null) {
    return filterFlights(filters, filteredIncomingData)
  }

  return filteredIncomingData
}