import {
  clearSelectedOutbound,
  resetState,
  setAirportsAction,
  setAirportsDirectlyAction,
  setFlightsPassengers,
} from '../store/flights/searchAction'
import { removeAllCheckoutAction } from '../store/checkout/checkoutActions'
import { clearSelectedBaggage } from '../store/flightDetails/flightDetailsActions'
import { setFlightSearchParameters } from '../store/search/searchAction'
import { toFullDate } from '../utils/formatDates'
import { buildUsersByCount, getPassengerCountsByType } from '../utils/general'
import { useCallback, useMemo, useState } from 'react'
import { setMapCenter } from '../store/hotels/hotelsAction'
import { useSelector } from 'react-redux'

const useFlightsHook = ({
  fromDest,
  toDest,
  history,
  i18n,
  arrival,
  departure,
  dispatch,
  travellers,
  isOutgoing,
  setIsPopoverOpenFrom,
  setIsPopoverOpenTo,
  setIsPopoverOpenTraveller,
  initialTicketClass,
  forceNewSearch = true,
  isExtendingFromCheckout = false,
  maximumAllowedTravelClass = undefined,
}) => {
  const { isAuthenticated, user, enableAnyFlightCabin } = useSelector(
    (state) => state.auth
  )
  const searchData = useSelector((state) => state.search)
  const { flights: flightSearchData } = searchData
  const initCounts = flightSearchData?.travelerCounts || {}

  let tmpIniClass = flightSearchData.ticketClass || 'economy'

  const allowedDestinations = user?.bookingLink?.allowedFlightDestination

  if (!isAuthenticated) {
    tmpIniClass =
      initialTicketClass ?? (flightSearchData?.ticketClass || 'economy')
  }

  const [counts, setCounts] = useState({
    adult: {
      count: initCounts?.adult?.count || 1,
      title: 'adult',
    },
    child: {
      count: initCounts?.child?.count || 0,
      title: 'child',
      muted: '(2-11 yrs)',
    },
  })
  const [ticketClass, setTicketClass] = useState(tmpIniClass)

  const modifyCounts = useCallback(
    (type = 'adults', action = 'increment') => {
      if (!counts?.[type]) return false
      const tmpCounts = { ...counts }
      if (action === 'increment') {
        tmpCounts[type].count++
      } else if (tmpCounts[type].count > 0) {
        tmpCounts[type].count--
      }
      setCounts(tmpCounts)
    },
    [counts]
  )

  const ticketClasses = useMemo(() => {
    const tmpClasses= (() => {
      switch (maximumAllowedTravelClass) {
        case 'economy':
          return {
            economy: 'economy class',
          }

        case 'economyPremium':
          return {
            economy: 'economy class',
            economyPremium: 'economy premium class',
          }

        case 'business':
          return {
            economy: 'economy class',
            economyPremium: 'economy premium class',
            business: 'business class',
          }

        // including 'first' class
        default:
          return {
            economy: 'economy class',
            economyPremium: 'economy premium class',
            business: 'business class',
            first: 'first class',
          }
      }
    })()

    if (enableAnyFlightCabin) tmpClasses.any = 'any class'
    return tmpClasses
  }, [enableAnyFlightCabin])

  const onSubmit = () => {
    if (!fromDest) {
      setIsPopoverOpenFrom(true)
    } else {
      setIsPopoverOpenFrom(false)
    }

    if (!toDest) {
      setIsPopoverOpenTo(true)
    } else {
      setIsPopoverOpenTo(false)
    }

    const tmpTravelers = isAuthenticated
      ? travellers
      : buildUsersByCount(counts)

    if (tmpTravelers.length === 0) {
      setIsPopoverOpenTraveller(true)
    } else {
      setIsPopoverOpenTraveller(false)
    }

    if (!fromDest || !toDest || !departure || tmpTravelers.length === 0) return

    const extendCheckout =
      flightSearchData?.extendCheckout || isExtendingFromCheckout
    if (forceNewSearch || !extendCheckout) {
      dispatch(removeAllCheckoutAction())
    }

    dispatch(clearSelectedBaggage())
    dispatch(setMapCenter(null))

    // Reset state
    if (isOutgoing) {
      dispatch(clearSelectedOutbound())
      dispatch(resetState())
    }

    dispatch(setFlightsPassengers(tmpTravelers))

    dispatch(
      setFlightSearchParameters(
        fromDest,
        toDest,
        departure,
        arrival,
        tmpTravelers,
        counts,
        ticketClass,
        !forceNewSearch && !!extendCheckout
      )
    )

    const passengerCounts = getPassengerCountsByType(tmpTravelers)

    const oneWay = !arrival ? 0 : 1
    const dep = toFullDate(departure)
    const arr = arrival ? toFullDate(arrival) : 0
    const newPath = `/flights/outgoing/${fromDest.IATA}/${toDest.IATA}/${dep}/${arr}/${passengerCounts.adult}/${passengerCounts.child}/${passengerCounts.infant}/${oneWay}/${ticketClass}`

    if (window.parent !== window) {
      setTimeout(() => {
        window.parent.postMessage(
          { type: 'searching', mode: 'flight', path: newPath },
          '*'
        )
      }, 200)
      if (window.location.pathname === '/iframe') return
    }

    if (window.location.pathname === newPath) {
      history.push('/dummy/')
      history.goBack()
    } else {
      history.push(newPath)
    }
  }

  const getCities = useCallback(
    (value, isTo) => {
      if (isTo && allowedDestinations) {
        const destinations = Array.isArray(allowedDestinations)
          ? allowedDestinations.map((d) => d.destination)
          : [allowedDestinations.destination]
        dispatch(setAirportsDirectlyAction(destinations))
      } else {
        dispatch(setAirportsAction(value.split(',')[0], i18n.language))
      }
    },
    [dispatch, i18n, allowedDestinations]
  )

  const preSetTicketClass = useCallback((tktClass) => {
    setTicketClass(tktClass || 'economy')
  }, [])

  return {
    getCities,
    onSubmit,
    modifyCounts,
    counts,
    ticketClass,
    setTicketClass: preSetTicketClass,
    ticketClasses,
  }
}

export default useFlightsHook
