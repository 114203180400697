import React from 'react'
import { useSelector } from 'react-redux'
import ChoosenTripCard from '../../../../components/trains/choosenTripCard'
import { Divider, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ChosenJourney = () => {
  const { i18n } = useTranslation()
  const { selectedOutGoingTrip,  selectedReturnTrip} = useSelector(
    (state) => state.train
  )

  return (
    <Box>
      <ChoosenTripCard title={i18n.t('chosen trip')} trip={selectedOutGoingTrip} />
      {selectedReturnTrip ? (
        <>
          <Box pl={2} pr={2}>
            <Divider />
          </Box>
          <ChoosenTripCard
            home={true}
            title={i18n.t('chosen returntrip')}
            trip={selectedReturnTrip}
          />
        </>
      ) : (
        ''
      )}
    </Box>
  )
}

export default ChosenJourney
