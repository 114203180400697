import { Box } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import React from 'react'
import defaultTimelineStyles from './defaultTimeline.styles'
import { useTranslation } from 'react-i18next'

function TripHeader({ type, onClose }) {
  const { t } = useTranslation()
  const classes = defaultTimelineStyles()

  return (
    <Box className={classes.expandedHeaderBlock}>
      <KeyboardArrowLeft
        color="primary"
        onClick={onClose}
        className={classes.expandedHeaderBackBtn}
      />

      <Box
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        flex={1}
      >
        {t(type)}
      </Box>
    </Box>
  )
}

export default TripHeader
