import i18n from 'i18next'

// Formats minutes to nicely formatted form.
export function formatDuration(duration) {
  if (!duration || duration === 0) {
    return ''
  }

  let dur = ''
  //let zero = ''
  const h = Math.floor(duration / 60)
  if (h > 0 && duration >= 60) {
    dur = `${h}${i18n.t('h')}`
  }

  const m = duration % 60
  if (m > 0) {
    if (dur) {
      dur += ' '
    }
    dur += `${m}${i18n.t('m')}`
  }
  return dur
}

// Format HH:MM to nicely formatted form.
export function formatTrainDuration(preformattedDuration) {
  if (!preformattedDuration || preformattedDuration === 0) {
    return ''
  }

  let [hours, minutes] = preformattedDuration.split(':')
  let totalMinutes = parseInt(hours) * 60 + parseInt(minutes)

  return formatDuration(totalMinutes)
}
