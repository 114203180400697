import React from 'react'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

const SmallSkeletons = ({staticPosition = false}) => (
  <Box
    style={{
      width: '100%',
      height: '120px',
      ...(staticPosition ? {} : {
        position: 'absolute',
        bottom: '20px',
      }),
      zIndex: '100',
      display: 'flex'
    }}
  >
    <Box
      style={{
        flex: 1,
        marginLeft: '16px',
        marginRight: '16px',
        backgroundColor: 'white',
        borderRadius: '12px',
        display: 'flex'
      }}
    >
      <Box hieght="100%" flex={1} p={1}>
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height="100%"
          style={{ borderRadius: '8px' }}
        />
      </Box>
      <Box display="flex" flexDirection="column" flex={2} p={2} pl={0} pr={0}>
        <Skeleton
          animation="wave"
          variant="rect"
          width="40px"
          height="16px"
          style={{ borderRadius: '4px' }}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          width="70%"
          height="16px"
          style={{ borderRadius: '4px', marginTop: '4px' }}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          width="80px"
          height="16px"
          style={{ marginTop: 'auto', borderRadius: '4px' }}
        />
      </Box>
    </Box>
  </Box>
)

export default SmallSkeletons
