import agent from './agent'

export const fetchTransferPhone = async (passengers, allPhones = true) => {
  const url = `/v2/data/transfer-phone`
  try {
    const { data } = await agent.post(url, {passengers, allPhones})
    return data
  } catch (error) {
    console.log(error)
  }
}

/* Search Street  */
//Get
export const fetchTransferLocation = async (string, destinationCode) => {
  if (!string) return
  const url = `/v2/flygtaxi/search-location?search=${string}&sort_code=${destinationCode}`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    console.log(error)
  }
}

/* Service locations (bus and train) */
//Get
export const fetchTransferServiceLocation = async (payload) => {
  const url = `/v2/flygtaxi/service-location`
  try {
    const { data } = await agent.get(url, { params: payload })
    return data
  } catch (error) {
    console.log(error)
  }
}

// endLocationCode
// connectionDate
// startLocationCode
// startStreetName
// startStreetNumber

// GET
export const fetchAvailability = async (params) => {
  const url = `/v2/flygtaxi/availability`
  try {
    const { data } = await agent.get(url, { params: params })
    return data
  } catch (error) {
    console.log(error)
  }
}

export const fetchTransfers = async (payload) => {
  const url = '/v2/flygtaxi/availability-flightbus'

  try {
    const { data } = await agent.get(url, { params: payload })
    return data
  } catch (error) {
    console.log(error)
  }
}

/*
// URL is towards rail, but the data that we get back is only transfer/taxi info.
export const fetchTransfers = async (payload) => {
  const url = '/rail/outbound'

  try {
    const { data } = await agent.post(url, payload)
    return data
  } catch (error) {
    console.log(error)
  }
}
*/
