import constants from './constants'
import { fetchCountries } from '../../repositories/data'
import i18n from '../../utils/i18n'

export const setHeaderHeightAction = (height) => ({
  type: constants.SET_HEADER_HEIGHT,
  payload: height,
})

const setCountriesList = (countries) => ({
  type: constants.SET_COUNTRIES_LIST,
  payload: countries,
})

export const getCountriesList = () => async (dispatch) => {
  try {
    const lng = i18n.language

    const countries = await fetchCountries(lng)
    dispatch(setCountriesList(countries || []))
  } catch (error) {
    dispatch(setCountriesList([]))
  }
}

export const setHideTrain = (hide) => ({
  type: constants.SET_HIDE_TRAIN,
  payload: hide,
})

export const setOnlyOidc = (onlyOidc) => ({
  type: constants.SET_ONLY_OIDC,
  payload: onlyOidc,
})
