import { useIsInViewport } from './useIsInViewPort'

export const useCardRef = (
  node,
  selectedItem,
  setSelected,
  selected,
  firstMount,
  isMobile,
  isHotel = true
) => {
  if (selectedItem !== selected) {
    setSelected(selectedItem)
    if (
      !firstMount &&
      !useIsInViewport(node) &&
      node &&
      selectedItem &&
      (!isHotel || (isHotel && !isMobile))
    ) {
      if (node) {
        let prev = node.previousElementSibling
        if (prev) {
          isMobile && !isHotel
            ? node.scrollIntoView({ behavior: 'smooth' })
            : prev.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }
}
