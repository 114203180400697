import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(10),
  },

  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'none',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 1.5),
  },
}))

const SortBy = ({
  mobile,
  sort,
  anchorEl,
  handleClick,
  handleClose,
  sortTypes,
}) => {
  const classes = useStyles()

  const renderMenuItems = sortTypes.map((item, key) => (
    <MenuItem
      key={key}
      onClick={() => handleClose(item)}
      style={{ width: '100%' }}
    >
      {item.label}
    </MenuItem>
  ))

  const { t } = useTranslation()

  return (
    <>
      {mobile ? (
        <Box width="100%" display="flex" alignItems="center">
          <Typography>{t('sorted by')}</Typography>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={
              <KeyboardArrowDownIcon
                style={{ fontSize: '24px' }}
                color="primary"
              />
            }
            className={classes.btn}
          >
            {sort ? sort.label : ''}
          </Button>
        </Box>
      ) : (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={
            <KeyboardArrowDownIcon
              style={{ fontSize: '24px' }}
              color="primary"
            />
          }
          className={classes.btn}
        >
          {sort ? sort.label : ''}
        </Button>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderMenuItems}
      </Menu>
    </>
  )
}

export default SortBy
