import React, { memo, Fragment, createContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../footer/footer'
import Header from '../header/header'
export const RootContext = createContext({
  routeGroup: null,
})
/**
 * A wrapper for components, in which elements common to all routes can be added (for example, footer)
 *
 * @param componentParams
 * @param routeParams
 * @returns {JSX.Element}
 * @constructor
 */
const RootWrapper = ({ componentParams, routeParams }) => {
  const { publicityLoading, publicity, isAuthenticated, hideFooter } =
    useSelector((state) => state.auth)
  const { routeGroup = null } = routeParams

  const contextValue = useMemo(() => {
    return {
      routeGroup: routeGroup || null,
    }
  }, [routeGroup])
  const Component = routeParams.component
  const showFooterPubl = ['both', 'public']
  const showHeader =
    (isAuthenticated || (publicity !== 'private' && !isAuthenticated)) &&
    !routeParams?.hideHeader
  return (
    <Fragment>
      <RootContext.Provider value={contextValue}>
        {!publicityLoading && showHeader && (
          <Header
            hideContent={!!routeParams.hideHeaderContent}
            isCustomLanding={!!routeParams?.isCustomLanding}
          />
        )}
        <Component {...componentParams} />
        {!publicityLoading &&
          showFooterPubl.includes(publicity) &&
          routeParams?.footer &&
          !hideFooter && <Footer />}
      </RootContext.Provider>
    </Fragment>
  )
}

export default memo(RootWrapper)
