import { compose, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import thunk from 'redux-thunk'
import rootReducer from './rootReducer'

import { getUserAction, setLogInError } from './auth/authAction'
import { getCountriesList } from './other/otherAction'
import { allUsersAction } from './users/userAction'

import storage from 'local-storage-fallback'

export let storeCache = null

export default (initialState) => {
  const storedState = storage.getItem('state')

  initialState = storedState !== null ? JSON.parse(storedState) : initialState

  // Logout functionality, querystring "logout"
  const params = new URLSearchParams(window.location.search)
  const shouldLogout =
    params.get('logout') || storage.getItem('isLoggedIn') === 'False'

  if (shouldLogout) {
    initialState.auth = null
    storage.removeItem('jwtToken')
  }

  const middleware = [thunk]

  const composeEnhancers = composeWithDevTools || compose

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  )

  store.subscribe(() => {
    const state = store.getState()
    const persist = {
      search: state.search,
      auth: state.auth,
      checkout: state.checkout,
      breadcrumbs: state.breadcrumbs,
      flights: state.flights,
      hotels: state.hotels,
      cars: state.cars,
      other: state.other,
    }
    storage.setItem('state', JSON.stringify(persist))
  })

  // We're settings Entire auth state in localstorage, cleaning error workingaorund
  store.dispatch(setLogInError(''))

  if (storage.getItem('jwtToken')) {
    try {
      store.dispatch(getUserAction())
      store.dispatch(allUsersAction())
    } catch (e) {}
  }

  try {
    store.dispatch(getCountriesList())
  } catch (e) {}
  storeCache = store
  return storeCache
}
