import React, { Fragment, memo, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { Box, Typography, Button, IconButton, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SelectTransferPassenger from '../../selectTransferPassenger'
import { Alert } from '@material-ui/lab'
import usePriceFormat from '../../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(1),
  },
  align: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: '10px',
  },
  chooseBtn: {
    borderRadius: theme.spacing(4),
    fontWeight: 600,
    textTransform: 'none',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 3),
  },
  icon: {
    objectFit: 'contain',
    width: '80px',
  },
  dot: {
    height: '4px',
    width: '4px',
    verticalAlign: 'middle',
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '4px',
    marginRight: '4px',
  },
}))

const FlightTaxiCard = ({
  model,
  setRelevantItinerary,
  current,
  handleReset,
  removeable,
  removeTransfer,
  show,
  pagination = null,
  notification = null,
  onPassengerSelect = null,
  goToTransfer = null,
  touched = false,
}) => {
  const classes = useStyles()
  const data = show === undefined ? model.details : model
  const { t } = useTranslation()
  const { currency } = useSelector((state) => state.auth)
  const { formatPrice } = usePriceFormat()

  const trName = (
    model.details?.[0]?.product?.name ||
    model?.product?.name ||
    ''
  ).toLowerCase()
  const isArlandaExpress = trName.includes('arlanda express')

  let count = 1
  if (isArlandaExpress) {
    count =
      typeof model?.passenger === 'object' && !!model?.passenger?.length
        ? model.passenger.length
        : 1
  }

  const myButtons = data.length ? data : []
  const currentData = data.length ? data[0] : data
  let pickUpDate = currentData?.startNode?.time
  if (pickUpDate) pickUpDate = pickUpDate.split('+')?.[0] || pickUpDate

  const buildAddress = (node) => {
    if (!node?.location) return ''

    if (node.location.type === 'LOCATION') {
      return !!node.name
        ? `${node.name || ''} ${node.number || ''}${
            node.letter ? `${node.letter}` : ''
          } (${node.location.community})`
        : node.location.name
    } else {
      return node.location.name
    }
  }

  const renderButtons = myButtons.map((item, key) => {
    const product = item.product.name
    const price = Math.trunc(item.price.includingVat)

    const payload = {
      ...item,
      flightnumber: current.meta?.flightnumber,
      index: current?.meta?.index,
    }

    const handleSelect = () => {
      handleReset()
      setRelevantItinerary(payload)
    }

    return (
      <Grid item xs={4} key={key}>
        <Button
          disableElevation
          onClick={handleSelect}
          fullWidth
          color="primary"
          variant="contained"
          className={classes.chooseBtn}
        >
          {product}
          <br />
          {formatPrice(price, { prependCurrency: true })}
        </Button>
      </Grid>
    )
  })

  /*
  <img
  src={`/assets/companies/${data.product.code === 'FBUS' ? 'FBUS' : 'ARX'}.png`}
  alt={data.product.code}
/>

  const image = 
  */

  const logo = (
    <img
      src={
        isArlandaExpress
          ? '/assets/companies/ARX.png'
          : model.type === 'flightTaxi'
          ? '/assets/companies/flygtaxi.png'
          : '/assets/companies/tagtaxi.png'
      }
      className={classes.icon}
      alt={'taxi'}
    />
  )

  const price = Math.trunc(currentData?.price?.includingVat)

  return (
    <Box className={removeable ? classes.container : ''} width={'100%'} p={2}>
      <Box display="flex" alignItems="center" pb={2}>
        <Box display="flex" flex="1">
          {logo}
          {!!removeable && !!pagination && (
            <Box
              paddingLeft={'5px'}
              display="flex"
              alignItems="center"
              flex="1"
            >
              {pagination}
            </Box>
          )}
        </Box>
        {removeable ? (
          <Box className={classes.forceRight}>
            <IconButton
              onClick={() =>
                removeTransfer({
                  index: currentData.index,
                  uniqueKey: currentData.uniqueKey,
                })
              }
            >
              <CancelIcon fontSize="small" color="disabled" />
            </IconButton>
          </Box>
        ) : (
          ''
        )}
      </Box>
      {!!notification && (
        <Box mb={2}>
          <Alert severity={notification.type}>{t(notification.text)}</Alert>
        </Box>
      )}
      <Box>
        <Box pb={0.5}>
          <Typography display="inline" gutterBottom className={classes.weight}>
            {t('search from')}
          </Typography>
          <Typography display="inline" gutterBottom>
            {buildAddress(currentData?.startNode?.street)}
          </Typography>
        </Box>
        <Box pb={0.5}>
          <Typography display="inline" gutterBottom className={classes.weight}>
            {t('search to')}
          </Typography>
          <Typography display="inline" gutterBottom>
            {buildAddress(currentData?.endNode?.street)}
          </Typography>
        </Box>
        {removeable ? (
          <Box pb={1}>
            <Typography display="inline" gutterBottom>
              <Moment format={isArlandaExpress ? 'D MMM' : 'HH:mm'}>
                {pickUpDate}
              </Moment>{' '}
              <span className={classes.dot} /> {currentData?.product?.name}{' '}
              {count > 1 ? `X${count} ` : ''}
              <span className={classes.dot} />{' '}
              {formatPrice(price * (count || 1), {
                prependCurrency: true,
              })}
            </Typography>
          </Box>
        ) : (
          <Box pb={1}>
            <Typography
              display="inline"
              gutterBottom
              className={classes.weight}
            >
              {t('pick up')}
            </Typography>
            <Typography display="inline" gutterBottom>
              {!!isArlandaExpress ? (
                <Fragment>
                  <Moment format="D MMM">{pickUpDate}</Moment> -{' '}
                  {t('valid any departure')}
                </Fragment>
              ) : (
                <Moment format="HH:mm">{pickUpDate}</Moment>
              )}
            </Typography>
          </Box>
        )}

        {data.co2 && (
          <Box pb={1}>
            <Typography display="inline" gutterBottom>
              <span className={classes.weight}>{data.co2}</span>
              {t('carbon dioxide per passenger')}
            </Typography>
          </Box>
        )}

        {!!onPassengerSelect && !!removeable && (
          <SelectTransferPassenger
            parentModel={current}
            transfer={data}
            onSelect={onPassengerSelect}
            goToTransfer={goToTransfer}
            touched={touched}
          />
        )}
      </Box>
      {removeable ? (
        ''
      ) : (
        <Box mt={2}>
          <Grid container spacing={1}>
            {renderButtons}
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default memo(FlightTaxiCard)
