import React, {memo, useEffect, useMemo, useState} from 'react'
import {Box, Typography, Button, CircularProgress} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FlightBusCard from './transferCards/flightBusCard'
import PreSearchTaxiCard from './transferCards/preSearchTaxiCard'
import PreSearchFBusCard from './transferCards/preSearchFBusCard'
import moment from "moment/moment";
import {cleanTransferSearch, fetchTransferFlightBus} from "../../../store/transfer/transferAction";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  optionBlock: {
    display: 'flex',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.border}`,
    marginBottom: '20px',

    '&:last-child': {
      marginBottom: 0
    }
  },
  flexOuterBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const SelectTransferTypeStep = ({
  title,
  current,
  isFlightbusDisabled = false,
  setRelevantItinerary,
  handleReset,
  onChangeStep,
  onlyType = null
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const {all, isFlygtaxiLoading, isAllLoading} = useSelector((state) => state.transfer)
  const [rqMade, setRqMade] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(cleanTransferSearch())
      // Should clean up all
    }
  }, [dispatch])
  const { t } = useTranslation()
  useEffect(() => {
    if (isFlightbusDisabled) return
    if (current.meta.connection.type === 'TRAIN') {
      setRqMade(true);
      return;
    }

    let params = {}
    // Going From Airport
    if (current && current.meta.from === true) {
      params = {
        startLocationCode: current?.meta?.locationCode,
        connectionDate: moment(current?.meta?.time).format(
          'YYYY-MM-DDTHH:mm:ss'
        ),
        international: current?.meta?.connection?.international,
      }
      // Going to the Airport
    } else {
      params = {
        endLocationCode: current?.meta?.locationCode,
        connectionDate: moment(current?.meta?.time).format(
          'YYYY-MM-DDTHH:mm:ss'
        ),
        international: current?.meta?.connection?.international,
      }
    }

    dispatch(fetchTransferFlightBus(params))
    setRqMade(true);
  }, [dispatch, current, isFlightbusDisabled])

  const isRequesting = isFlygtaxiLoading || isAllLoading || !rqMade

  const options = useMemo(() => {
    const transfers = (typeof (all) === 'object' && !!all?.length) ? all : [];
    const busTypes = transfers.filter(t => t.type = 'flightBus');
    const results = [];

    if (!onlyType || onlyType === 'taxi') {
      results.push(
        <Box key="option-flygtaxi" className={classes.optionBlock}>
          <PreSearchTaxiCard current={current} onClick={() => onChangeStep(2)}/>
        </Box>
      )
    }
    if (!busTypes?.length) return results;
    let fbusOpts = []
    if (onlyType !== 'taxi') {
      busTypes.forEach((t,i) => {
        if (!t.details?.length) return false
        t.details.forEach((detail, dInd) => {
          if (onlyType === 'fbus' && detail?.product?.name !== 'FLYGBUSS') return false

          const tmpOption = (
            <Box key={`option-${i}-${dInd}`} className={classes.optionBlock}>
              <FlightBusCard
                model={{
                  type: 'flightBus',
                  details: [{...detail}]
                }}
                type={'flightBus'}
                setRelevantItinerary={setRelevantItinerary}
                current={current}
                handleReset={handleReset}
              />
            </Box>
          );

          // if showing all types and current route is FLYGBUSS
          if (!onlyType && detail?.product?.name === 'FLYGBUSS') {
            fbusOpts.push(tmpOption)
            return true
          }
          results.push(tmpOption)
        })
      })
    }

    if (!onlyType && fbusOpts.length > 1) {
      // if showing all types and there are more than one FLYGBUSS route, button to "FLYGBUSS routes step" will be shown
      results.push(
        <Box key="option-fbus" className={classes.optionBlock}>
          <PreSearchFBusCard current={current} onClick={() => onChangeStep(1.1)}/>
        </Box>
      )
    } else if (!onlyType && !!fbusOpts.length) {
      results.push(...fbusOpts)
    }
    return results
  }, [all, current, classes, handleReset, setRelevantItinerary, onlyType])

  return (
    <Box key="select-transfer-type-step" className={classes.flexOuterBlock}>
      <Typography
        align="center"
        variant="h4"
        className={classes.weight}
        style={{ marginBottom: '16px' }}
      >
        {isMobile || title}
      </Typography>
      <Typography align="center" style={{ lineHeight: '16px' }}>
        {t('select which transfer you would like')}
      </Typography>

      <Box className={classes.flexOuterBlock} px={isMobile ? 0 : 6} mt="40px">
        { isRequesting ? (
          <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
            <CircularProgress size={30} color={'light'} />
          </Box>
        ) : options}
      </Box>
    </Box>
  )
}

export default memo(SelectTransferTypeStep)
