import React, { memo, useEffect, useState } from 'react'
import { Box, Typography, Button, TextField } from '@material-ui/core'
import ModalHook from './reusable/modalHook'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { closeAllModals } from '../../store/modals/modalAction'
import { getPhoneNumberFormat, validatePhoneNumber } from '../../utils/general'
import { phoneInputHandler } from '../../utils/phoneInputs'
import * as Yup from 'yup'
import { showSnackbarAction } from '../../store/snackbars/snackbarAction'
import { getPassengersData } from '../../store/checkout/checkoutActions'
import { getUserAction, updateUserAction } from '../../store/auth/authAction'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '40px',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  confirmText: {
    padding: '0 15px',
  },
  btns: {
    borderRadius: theme.spacing(3),
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1, 4),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
  },

  phoneFieldInput: {
    marginTop: '20px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      '& input': {
        paddingTop: '13px',
        paddingBottom: '13px',
      },
    },
  },
  editPhoneLabel: {
    fontSize: '24px',
    marginBottom: '24px',
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
}))

const AddMobileModal = ({ modalBoolean }) => {
  const { countryPhone, user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const [phone, setPhone] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [error, setError] = useState(null)

  const phoneNumberFormat = getPhoneNumberFormat(countryPhone?.code)

  const phonePasteHandler = (event) => {
    let text = event.clipboardData.getData('Text')

    text = text.replace(/[^\+0-9]/gm, '')
    setPhone(text)
    event.preventDefault()
  }

  useEffect(() => {
    const validationSchema = Yup.object().shape({
      phone: Yup.string()
        .nullable()
        .required(t('required field'))
        .test(
          'valid-number',
          t('invalid phone number', { phoneNumberFormat }),
          (value) => validatePhoneNumber(value, countryPhone.code)
        ),
    })

    validationSchema
      .validate({ phone })
      .then(() => {
        setIsValid(true)
        setError(null)
      })
      .catch((e) => {
        setIsValid(false)
        setError(e.message)
      })
  }, [phone])

  const handleChange = (event) => {
    setPhone(event.target.value)
  }

  const handleSave = async () => {
    dispatch(closeAllModals())
    try {
      const { isUmbrella } = user

      let data = isUmbrella
        ? {
            ...user.contacts,
            phones: { mobile: phone, phone: user.contacts?.phone },
          }
        : { ...user.contacts, phone }
      await dispatch(
        updateUserAction({
          section: 'contacts',
          updateData: {
            ...data,
          },
        })
      )
      await dispatch(getUserAction())
      await dispatch(getPassengersData([user?.uniqueId]))
      dispatch(showSnackbarAction('success', t('update success')))
    } catch (e) {
      dispatch(showSnackbarAction('error', t('update error')))
    }
  }

  return (
    <ModalHook
      display={'flex'}
      height={400}
      minHeight={400}
      width={550}
      modalBoolean={modalBoolean}
      onClose={() => {}}
    >
      <Box className={classes.root}>
        <Typography
          gutterBottom
          className={classes.weight}
          variant="h4"
          align="center"
        >
          {t('missing mobile title')}
        </Typography>

        <Typography align="center" className={classes.confirmText}>
          {t('missing mobile description')}
        </Typography>

        <TextField
          autoComplete={'off'}
          inputProps={{ autoCorrect: 'off', spellCheck: 'false' }}
          type={'tel'}
          fullWidth
          error={!!error}
          helperText={error}
          InputLabelProps={{ shrink: false }}
          value={phone}
          variant="outlined"
          className={classes.phoneFieldInput}
          onKeyPress={phoneInputHandler}
          InputProps={{ style: { fontWeight: 500 } }}
          onChange={handleChange}
          placeholder={phoneNumberFormat}
          onPaste={phonePasteHandler}
        />

        <Box mt={5}>
          <Button
            disableElevation
            className={classes.btns}
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={!isValid}
          >
            {t('save')}
          </Button>
        </Box>
      </Box>
    </ModalHook>
  )
}

export default memo(AddMobileModal)
