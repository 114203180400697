import React, { useEffect } from 'react'
import { Grid, Paper, Container } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  fetchReturningTrainSearchAction,
  isEarlierTrip,
  isLaterTrip,
} from '../../store/train/trainAction'
import TrainSearchContainer from '../../containers/trains/trainSearchContainer'
import SelectedTrains from '../../containers/trains/trainContainers/selectedTrains/selectedTrains'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NoTripsFound from '../../components/general/noTripsFound'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import TrainSearchPageForm from "../../components/trains/trainSearchPageForm"

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '12px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}))
const ReturnTrainSearch = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { user, authType } = useSelector(
    (state) => state.auth
  )

  const { to, from, dep, arr, isTwoWay, depTimeOutbound, depTimeInbound } =
    params
  const searchTravellers = useSelector((state) => state.search.train.travellers)
  const resultError = useSelector((state) => state.train.resultError)
  const authTypes = ['agent', 'viaAgent']
  const isTopRow = !!user?.isBookingLink || authTypes.includes(authType)
  const { i18n } = useTranslation()

  const { latestTime, firstTime, showPrevious, showNext } = useSelector(
    (state) => state.train
  )


  const clickNext = () => {
    let newDate = moment(latestTime).format('YYYY-MM-DD HH:mm')

    dispatch(isEarlierTrip(false))
    dispatch(isLaterTrip(true))
    getTrips(newDate)
  }

  const clickPrevious = () => {
    const newDate = moment(firstTime)
      .subtract(3, 'hours')
      .format('YYYY-MM-DD HH:mm')
    dispatch(isLaterTrip(false))
    dispatch(isEarlierTrip(true))

    getTrips(newDate)
  }

  const getTrips = (newDate) => {
    const payload = {
      from,
      to,
      dep,
      arr: newDate,
      users: searchTravellers,
      isTwoWay,
      depTimeOutbound,
      depTimeInbound,
    }
    dispatch(fetchReturningTrainSearchAction(payload))
  }

  return (
    <>
      <TrainSearchPageForm searchParams={params} searchType={'return'} />
      {resultError ? (
        <NoTripsFound />
      ) : (
        <Box p={1}>
          <Container maxWidth="lg" disableGutters>
            <Grid
              container
              spacing={isMobile ? 2 : 4}
              direction={isMobile ? 'column-reverse' : 'row'}
            >
              <Grid item xs={12} sm={12} md={9}>
                <Box className={isMobile ? classes.paperMobile : classes.paper}>
                  <TrainSearchContainer
                    clickNext={clickNext}
                    clickPrevious={clickPrevious}
                    showNext={showNext}
                    showPrevious={showPrevious}
                    direction={'return'}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Paper
                  className={classes.paper}
                  elevation={0}
                  style={{
                    position: 'sticky',
                    top: !isTopRow ? '185px' : '240px',
                  }}
                >
                  <SelectedTrains returning />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  )
}

export default ReturnTrainSearch
