import React, { useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Box, Typography, Divider } from '@material-ui/core'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFop } from '../../../store/checkout/checkoutActions'
import { getFops } from '../../../utils/booking'
import HotelNotify from './hotelNotify'
import { DEPOSIT } from '../../../utils/booking/logic/hotel'
import { callbackJoin } from '../../../utils/general'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  normalText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  radioInput: {
    color: theme.palette.primary.main,
  },
  paymentOptBlock: {
    '&:last-child .payment-divider': {
      display: 'none',
    },
  },
  mutedTxt: {
    color: '#757575',
    marginLeft: '4px',
  },
  checkbox: {
    width: '100%',
  },
}))
const Payment = ({ isHotelInvoice, isHotelB2BWallet }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const checkoutState = useSelector((state) => state.checkout)
  const { items, fop, costfields } = checkoutState
  const { user, isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const fops = useMemo(
    () => getFops({ isAuthenticated: isAuthenticated || false, checkoutState }),
    [checkoutState, isAuthenticated]
  )
  const hotel = items.find((i) => i.type === 'Hotel')
  // fops.all = ['agreement', 'cc']

  //do not take into account the hotel when building the header
  // if the hotel is paid using 'invoice'
  let paymentFor = null
  let paysWith = [...(fops.perFop[fop] || [])]
  const isCar = paysWith.find((i) => i === 'Car')
  const isCarNotEvoucher =
    !costfields?.paymentMethods?.carIncludeEvoucher && !!isCar
  if (isHotelInvoice && !hotel?.room?.isLeisure) {
    paysWith = paysWith.filter((pType) => pType !== 'Hotel')
  }
  if (isHotelB2BWallet && hotel?.room?.isLeisure) {
    paysWith = paysWith.filter((pType) => pType !== 'Hotel')
  }

  if (
    fops.itemTypesToPay.length > 1 &&
    paysWith.length > 0 &&
    fops.itemTypesToPay.length > paysWith.length
  ) {
    const translated = paysWith.map((pf) => t(pf.toLowerCase()).toLowerCase())
    paymentFor = callbackJoin((prevInd, nextInd) => {
      return nextInd >= translated.length - 1 ? ' & ' : ', '
    }, translated)
  }
  const FlightUseFop = fops.perFop[fop]
    ? fops.perFop[fop].includes('Flight')
    : false
  const trainUseFop = fops.perFop[fop]
    ? fops.perFop[fop].includes('Train')
    : false

  let noteObject = null
  const isDeposit = hotel?.room?.roomGuaranteeCode == DEPOSIT

  const fopTranslations = {
    invoice: t('with invoice'),
    profileCC: t('with profile cc'),
    cc: t('with cc'),
    agreement: t('with agreed'),
  }
  const fopNoteTranslations = {
    invoice: t('note invoice'),
    profileCC: t('note profile cc'),
    cc: t('note cc'),
    agreement: t('note agreed'),
  }

  const otherFop = fop === 'cc' ? fops.primaryFop : 'cc'
  const otherTypesArray =
    fops?.perFop?.[otherFop]?.filter(
      (ot) => !fops?.perFop?.[fop]?.includes(ot)
    ) || []

  if (
    (FlightUseFop || trainUseFop) &&
    otherTypesArray.length > 0 &&
    isDeposit &&
    otherTypesArray.includes('Hotel')
  ) {
    const otherTypes = otherTypesArray.map((pf) =>
      t(pf.toLowerCase()).toLowerCase()
    )
    const replace = {
      types: paymentFor,
      fop: (fopTranslations[fop] || fop).toLowerCase(),
      rqFop: (fopNoteTranslations[otherFop] || otherFop).toLowerCase(),
      otherTypes: callbackJoin((prevInd, nextInd) => {
        return nextInd >= otherTypes.length - 1 ? ' & ' : ', '
      }, otherTypes),
    }
    noteObject = {
      type: 'hotelPayNotify',
      paymentHeader: null,
      paymentNote: t('flight-hotel-case-2-flight-payment-note', replace),
    }
  }

  if (isCarNotEvoucher) {
    let postfix = 'car pay evoucher alt'
    if (!noteObject) {
      postfix = 'car pay evoucher'
      noteObject = { type: 'paper', paymentHeader: null, paymentNote: '' }
    }
    noteObject.paymentNote += ` ${t(postfix)}`
  }

  const paymentTitle = !paymentFor ? t('payment pays') : t('payment')

  const getPrimaryPayName = useCallback(
    (payName) => {
      const mapping = {
        compDefault: t('as per agreement'),
        separateInvoice: t('book link invoice payment'),
      }

      if (!user?.isBookingLink || !mapping[user?.bookingLink?.payment])
        return payName
      return mapping[user?.bookingLink?.payment]
    },
    [t, user]
  )

  const handleChange = useCallback(
    (e) => {
      console.log(setFop, e.target.value)
      dispatch(setFop(e.target.value))
    },
    [dispatch]
  )

  const profileCCmuted = useMemo(() => {
    const opts = user?.paymentOptions || []
    const card = opts.find((opt) => opt.value === 'profileCC')

    return card ? card.underline || null : null
  }, [user])

  const availableOpts = useMemo(() => {
    const possibleOptions = {
      cc: {
        name: t('payment card'),
        value: 'cc',
      },
      invoice: {
        name: t('invoice'),
        value: 'invoice',
      },
      agreement: {
        name: t('as per agreement'),
        value: 'agreement',
      },
      profileCC: {
        name: (
          <span>
            {t('payment account')}
            <span className={classes.mutedTxt}>{profileCCmuted}</span>
          </span>
        ),
        value: 'profileCC',
      },
    }

    const options = []
    fops.all.forEach((fop) => {
      if (
        possibleOptions[fop] &&
        !_.find(options, { value: possibleOptions[fop].value })
      ) {
        const rePOpt = { ...possibleOptions[fop] }
        if (fop === fops.primaryFop) {
          rePOpt.name = getPrimaryPayName(rePOpt.name)
        }
        options.push(rePOpt)
      }
    })
    return options
  }, [getPrimaryPayName, profileCCmuted, classes, fops, t])

  useEffect(() => {
    const opt = _.find(availableOpts, { value: fop })
    if ((!fop || !opt) && availableOpts.length > 0) {
      // console.log('setFop', availableOpts[0].value)
      dispatch(setFop(availableOpts[0].value))
    } else if (fop && availableOpts.length <= 0) {
      dispatch(setFop(null))
    }
  }, [dispatch, fop, availableOpts])

  // console.log(fop)
  // console.log(availableOpts)

  const renderCostFieldOptions = availableOpts?.map((option, i) => (
    <Box className={classes.paymentOptBlock} key={i}>
      <Box py={1}>
        {option.value === 'cc' && fop === 'cc' && !!hotel?.room && (
          <Box my={2}>
            <HotelNotify fops={fops} />
          </Box>
        )}

        {noteObject && option.value === fop && (
          <Box my={2}>
            <HotelNotify noteObject={noteObject} fops={fops} />
          </Box>
        )}
        <FormControlLabel
          className={classes.checkbox}
          value={option.value}
          control={<Radio className={classes.radioInput} color="primary" />}
          label={option.name}
        />
      </Box>
      <Divider className={'payment-divider'} />
    </Box>
  ))

  return (
    <Box p={2} pb={fop === 'cc' ? 0 : 2}>
      <Box>
        {paymentTitle && (
          <Box mb={2} mt={0}>
            <Typography variant="h5" className={classes.bold}>
              <span>{paymentTitle}</span>
              {!!paymentFor && (
                <span className={classes.normalText}>
                  {' '}
                  {paymentFor.toLowerCase()}
                </span>
              )}
            </Typography>
          </Box>
        )}
        <Divider />

        <Box>
          <FormControl component="fieldset" className={classes.checkbox}>
            <RadioGroup
              value={fop}
              onChange={handleChange}
              className={classes.checkbox}
            >
              {renderCostFieldOptions}
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </Box>
  )
}

export default Payment
