import React, {Fragment, useCallback, useEffect, useState} from 'react'
import AutoCompleteInput from '../../../components/reusable/autoCompleteInput'
import {Box, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {fetchGooglePlacesAutocomplete} from '../../../repositories/data';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    padding: '10px',
    paddingTop: '9px',
    paddingLeft: '12px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
    marginBottom: '8px'
  }
}))

const SelectCity = ({ value, onChange, goBack }) => {
  const { t, i18n } = useTranslation()

  const classes = useStyles()

  const [places, setPlaces] = useState([])
  const getPlaces = useCallback(
    async (value) => {
      const data = await fetchGooglePlacesAutocomplete(value || null, i18n.language)
      setPlaces(data)
    },
    [i18n.language]
  )
  const optionLabel = (option) =>  option?.description || ''
  const renderOption = (option) => {
    return (
      <Fragment>
        <Box mr={1}>
          <LocationOnIcon color="disabled" fontSize="large" />
        </Box>
        <Typography>
          <span>
              {option?.description}
          </span>
        </Typography>
      </Fragment>
    )
  }
  const ACFilterFunc = useCallback((option) =>  option?.description || '', [])

  const handleSubmit = useCallback(async (value) => {
    onChange(value)
    goBack()
  },[goBack, onChange])

  return (
    <Box className={classes.container}>
      <Box className={classes.input}>
        <AutoCompleteInput
          renderOption={renderOption}
          fetch={getPlaces}
          value={value}
          onChange={(_, value) => handleSubmit(value)}
          array={places}
          optionLabel={optionLabel}
          customFilterFunc={ACFilterFunc}
          label={t('drop-off place')}
          placeholder={t('drop-off place')}
          desktop
        />
      </Box>
    </Box>
  )
}

export default SelectCity
