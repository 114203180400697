import React, { memo } from 'react'
import { Paper, Box, Grid, useMediaQuery } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', borderRadius: '0 0 ' },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  grid: {
    paddingLeft: theme.spacing(1),
  },

  border: { borderRadius: '4px' },

  boxHeight: {
    borderBottom: '1px solid #e6e6e6',
    height: '69px',
  },
  boxHeightMobile: {
    borderBottom: '1px solid #e6e6e6',
    height: '90px',
  },

  height: {
    height: 'fit-content',
    margin: 'auto',
    paddingLeft: theme.spacing(1),
  },
}))

const TrainSkeletons = ({ arrayLength = 10, padding }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const filledSkeletons = new Array(arrayLength).fill(null).map((x, i) => (
    <Paper className={classes.root} key={i} elevation={0}>
      <Box className={!isMobile ? classes.boxHeight : classes.boxHeightMobile}>
        <Grid container style={{ height: '100%' }}>
          {/* 3 skeletons */}
          <Grid
            container
            item
            xs={7}
            alignItems="center"
            className={isMobile ? classes.height : ''}
          >
            <Grid item xs={12} sm={3} className={classes.grid}>
              <Box
                pt={isMobile ? 1 : 2}
                pb={isMobile ? 0 : 2}
                display="flex"
                justifyContent={isMobile ? 'flex-start' : 'center'}
                alignItems="center"
                borderColor="#e6e6e6"
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={!isMobile ? 80 : 56}
                  height={!isMobile ? 16 : 8}
                  className={classes.border}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm className={classes.grid}>
              <Box
                pt={isMobile ? 1 : 2}
                pb={isMobile ? 0 : 2}
                pr={1}
                display="flex"
                justifyContent={isMobile ? 'flex-start' : 'center'}
                alignItems="center"
                borderColor="#e6e6e6"
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={!isMobile ? 224 : 98}
                  height={16}
                  className={classes.border}
                />
              </Box>
            </Grid>

            <Grid item xs sm className={classes.grid}>
              <Box
                pt={isMobile ? 1 : 2}
                pb={isMobile ? 1 : 2}
                display="flex"
                justifyContent={isMobile ? 'flex-start' : 'center'}
                alignItems="center"
                borderColor="#e6e6e6"
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={!isMobile ? 48 : 56}
                  height={!isMobile ? 16 : 8}
                  className={classes.border}
                />
              </Box>
            </Grid>
          </Grid>
          {/* end of 3 skeletons container */}

          {/* start of 2 skeletons container*/}
          <Grid item container xs justify="space-between">
            <Grid item xs={6}>
              <Box
                borderLeft={1}
                borderColor="#e6e6e6"
                style={{ height: '100%' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={!isMobile ? 64 : 48}
                  height={!isMobile ? 24 : 16}
                  className={classes.border}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                borderLeft={1}
                borderColor="#e6e6e6"
                style={{ height: '100%' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={!isMobile ? 64 : 48}
                  height={!isMobile ? 24 : 16}
                  className={classes.border}
                />
              </Box>
            </Grid>
          </Grid>
          {/* end of 2 skeletons */}
        </Grid>
      </Box>
    </Paper>
  ))

  return <Box>{filledSkeletons}</Box>
}
export default memo(TrainSkeletons)
