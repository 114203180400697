import React, {memo, useEffect, useMemo, useState} from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ShowTransfersBlock from './showTransfersBlock'
import _ from 'lodash'
import limitChars from '../../../utils/limitChars'

const useStyles = makeStyles((theme) => ({
  between: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  btn: {
    fontSize: theme.spacing(2),
    padding: theme.spacing(2, '93px'),
    paddingLeft: theme.spacing(2),
    textTransform: 'none',
    textAlign: 'left',
    justifyContent: 'flex-start',
    borderRadius: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
    borderColor: theme.palette.border,

    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  addTransferBtn: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 4),
    borderRadius: theme.spacing(3),
    letterSpacing: 1.1,
    fontSize: '18px',
  },

  addButton: {
    position: 'absolute',
    top: '3px',
    bottom: '3px',
    right: '3px',
    width: '88px',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '14px',
    letterSpacing: '0.7',
    fontWeight: theme.typography.fontWeightSemiBold,
    cursor: 'pointer',
  },

  addButtonDisabled: {
    position: 'absolute',
    top: '3px',
    bottom: '3px',
    right: '3px',
    width: '88px',
    borderRadius: theme.spacing(1),
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '14px',
    letterSpacing: '0.7',
    fontWeight: theme.typography.fontWeightRegular,
    cursor: 'none',
  },
}))

const AddTransferStep = ({
  title,
  setCurrentPath,
  transfers,
  removeTransfer,
  addTransferToCheckout,
  setTransferPassenger,
  touched
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))

  const hasSelectedAnyTransfers = transfers.find((t) => {
    const found = (t?.transfer || []).find(singleTr => {
      return !!singleTr && !singleTr?.isAdded
    })
    return !!found
  })

  let prevTitleKey = null;

  const renderOptions = transfers.map((transfer, i) => {
    const { meta } = transfer
    const desti = !!meta.from ? t('search from') : t('search to')
    const currTitleKey = `${meta.type} ${meta.dir}`
    const isEnabled = meta.locationCode && meta.transferEnabled

    const addButton = isEnabled ? (
      <Box position={'relative'}>
        <Box
          onClick={() => setCurrentPath(transfer)}
          className={classes.addButton}
        >
          {t('add')}
        </Box>
        <Button
          onClick={() => setCurrentPath(transfer)}
          className={classes.btn}
          fullWidth
          variant="outlined"
        >
          <Typography className={classes.weight} style={{ marginRight: '4px' }}>
            {desti}
          </Typography>
          {limitChars(_.startCase(meta?.locationName), 30)}
        </Button>
      </Box>
    ) : (
      <Box position={'relative'}>
        <Box className={classes.addButtonDisabled}>{t('unavailable')}</Box>
        <Button
          className={classes.btn}
          fullWidth
          variant="outlined"
          disabled={true}
        >
          <Typography className={classes.weight} style={{ marginRight: '4px' }}>
            {desti}
          </Typography>
          {limitChars(_.startCase(meta?.locationName), 30)}
        </Button>
      </Box>
    )

    const isNewBlock = prevTitleKey !== currTitleKey
    prevTitleKey = currTitleKey
    return (
      <Box
        key={i}
        mt={isNewBlock ? 5 : 0}
        mb={isNewBlock ? 2 : 0}
      >
        {isNewBlock && (
          <Typography className={classes.weight} style={{ marginBottom: '8px' }}>
            {t(currTitleKey)}
          </Typography>
        )}
        <Box display="flex" flexDirection="column">
          {transfer?.transfer?.length ? (
            <ShowTransfersBlock
              index={i}
              addNewTransfer={!isEnabled ? null : () => setCurrentPath(transfer)}
              options={transfer.transfer}
              current={transfer}
              removeTransfer={removeTransfer}
              setTransferPassenger={setTransferPassenger}
              touched={touched}
            />
          ) : (
            <Box>
              {addButton}
            </Box>
          )}

        </Box>
      </Box>
    )
  })

  return (
    <Box display="flex" flexDirection="column" mb={4}>
      <Typography
        align="center"
        variant="h4"
        className={classes.weight}
        style={{ marginBottom: '16px' }}
      >
        {isDesktop || title}
      </Typography>
      <Typography align="center" style={{ lineHeight: '16px' }}>
        {hasSelectedAnyTransfers ? (
          <>
            {t('add more')}
            <span
              style={{
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightBold,
                cursor: 'pointer',
              }}
              onClick={addTransferToCheckout}
            >
              {t('done').toUpperCase()}
            </span>
            {t('if finished')}
          </>
        ) : (
          t('select which transfer you would like')
        )}
      </Typography>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        pl={isDesktop ? 0 : 6}
        pr={isDesktop ? 0 : 6}
      >
        {renderOptions}
      </Box>
    </Box>
  )
}

export default memo(AddTransferStep)
